import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid process-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Checkbox, {
      class: "col sm:col-auto",
      id: "userCompleted",
      additionalClasses: "nofade",
      value: _ctx.reference.referenceRecord.userCompleted,
      disabled: true,
      text: "references.user-completed",
      onChange: _cache[0] || (_cache[0] = () => {})
    }, null, 8, ["value"]),
    (!_ctx.userReference)
      ? (_openBlock(), _createBlock(_component_Checkbox, {
          key: 0,
          class: "col sm:col-auto",
          id: "approved",
          additionalClasses: "nofade",
          value: _ctx.reference.referenceRecord.approved,
          disabled: true,
          text: "references.admin-approved",
          onChange: _cache[1] || (_cache[1] = () => {})
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    (!_ctx.userReference)
      ? (_openBlock(), _createBlock(_component_Checkbox, {
          key: 1,
          class: "col sm:col-auto",
          id: "sent",
          additionalClasses: "nofade",
          value: _ctx.reference.referenceRecord.sent,
          disabled: true,
          text: "references.contact-made",
          onChange: _cache[2] || (_cache[2] = () => {})
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    (!_ctx.userReference)
      ? (_openBlock(), _createBlock(_component_Checkbox, {
          key: 2,
          class: "col sm:col-auto",
          id: "viewed",
          additionalClasses: "nofade",
          value: _ctx.reference.referenceRecord.viewed,
          disabled: true,
          text: "references.viewed",
          onChange: _cache[3] || (_cache[3] = () => {})
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    (!_ctx.userReference)
      ? (_openBlock(), _createBlock(_component_Checkbox, {
          key: 3,
          class: "col sm:col-auto",
          id: "completed",
          additionalClasses: "nofade",
          value: _ctx.reference.referenceRecord.completed,
          disabled: true,
          text: "references.completed",
          onChange: _cache[4] || (_cache[4] = () => {})
        }, null, 8, ["value"]))
      : _createCommentVNode("", true)
  ]))
}