/* eslint-disable @typescript-eslint/no-explicit-any */
import { App, inject } from "vue";
import store from "@/store";

const modalCallback = <T>(
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: any) => void,
  cb?: () => Promise<T>
) => {
  return async () => {
    try {
      let result;
      if (cb) {
        result = await cb();
      }
      store.dispatch("hideGlobalModal");
      resolve(result);
    } catch (err) {
      reject(err);
    }
  };
};

export const modalSymbol = Symbol();
export interface ShowModalFunctionOptions {
  title: string;
  body: string;
  onConfirm?: () => Promise<any>;
  onCancel?: () => Promise<any>;
}
export type ShowModalFunction = <T>(
  options: ShowModalFunctionOptions
) => Promise<T>;

export default {
  install: (app: App): void => {
    const showGlobalModal: ShowModalFunction = async <T = any>(
      options: ShowModalFunctionOptions
    ) => {
      return new Promise<T>((resolve, reject) => {
        const { onConfirm, onCancel } = options;
        options.onConfirm = modalCallback(resolve, reject, onConfirm);
        options.onCancel = modalCallback(resolve, reject, onCancel);
        store.dispatch("showGlobalModal", options);
      });
    };

    app.config.globalProperties.$showGlobalModal = showGlobalModal;
    app.provide(modalSymbol, showGlobalModal);
  },
};

export const useModal = (): ShowModalFunction => {
  const plugin = inject<ShowModalFunction>(modalSymbol);
  if (!plugin) throw new Error("Modal plugin not installed");

  return plugin;
};
