
import { useLanguage } from "@/composables";
import { computed, defineComponent, ref } from "@vue/runtime-core";
import VueResizable from "vue-resizable";

export default defineComponent({
  name: "FormInput",
  components: { VueResizable },
  props: {
    customClass: String,
    id: String,
    name: String,
    label: String,
    value: String,
    dragging: Boolean,
    editMode: Boolean,
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    labelAlwaysActive: {
      type: Boolean,
      default: false,
    },
    hideLabelOnFocus: {
      type: Boolean,
      default: false,
    },
    compact: Boolean,
    placeholder: String,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "enter", "blur", "click-append-icon", "resize", "edit"],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const handleInput = (event: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("change", { name, value });
    };
    const inputDummy = ref<any>(null);

    const isFocussed = ref<boolean>(false);
    const isActive = computed(
      () =>
        isFocussed.value ||
        !!props.value ||
        props.labelAlwaysActive ||
        props.placeholder
    );
    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = (event: Event) => {
      isFocussed.value = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("blur", { name, value });
    };

    const clickAppendIcon = () => emit("click-append-icon");

    const enter = () => emit("enter");

    const handleEditClick = () => emit("edit");

    const placeholderText = computed(() =>
      getValueForString(props.placeholder ?? "")
    );

    const classes = computed(() => {
      const custom = props.customClass;
      return {
        compact: !!props.compact,
        custom: !!custom,
      };
    });

    const resizeEnd = (event) => {
      emit("resize", {
        increase: event.width > inputDummy.value?.clientWidth,
        decrease: event.width < inputDummy.value?.clientWidth,
      });
    };

    return {
      isFocussed,
      classes,
      isActive,
      placeholderText,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      clickAppendIcon,
      resizeEnd,
      inputDummy,
      handleEditClick,
    };
  },
});
