
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useAuthProvider } from "@/plugins/auth";
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  ComputedRef,
  inject,
  watch,
} from "vue";
import { MenuItem, NavItem, SettingsItem } from "@/interfaces/ui";
import { TextItem } from "@/interfaces";
import { addMinutes } from "date-fns";

import navItemsJson from "@/data/navItems.json";
import menuItemsJson from "@/data/menuItems.json";
import settingsItemsJson from "@/data/settingsItems.json";

import Header from "@/components/Header.vue";
import Drawer from "@/components/Drawer.vue";
import NavSide from "@/components/NavSide.vue";
import SettingsMenu from "@/components/SettingsMenu.vue";
import Overlay from "@/components/Overlay.vue";
import { parseISO } from "date-fns";
import { setCookie } from "@/router";
import { useLanguage } from "@/composables";
import { usePermissionProvider } from "@/plugins/permission";

import { Theme } from "@/models/Style";
import {
  getAdminSideMenuItems,
  getMessageContentData,
  getUserSideMenuItems,
} from "@/api/side-menu.api";
import { getMessageContents } from "@/api/message-contents.api";
import { setTourViewed } from "@/api/user.api";

import { httpGetLayout } from "@/api/dashboard.api";

export default defineComponent({
  name: "app",
  components: {
    Header,
    Drawer,
    Overlay,
    NavSide,
    SettingsMenu,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = useAuthProvider();
    const theme = inject<ComputedRef<Theme>>("theme");
    const permissionProvider = usePermissionProvider();
    const widgets = ref([]);

    const background = computed(() => {
      const style: Record<string, string> = {};

      //Only change background on the track
      if (
        theme &&
        ["UserTrack"].includes(router.currentRoute.value.name as string)
      ) {
        if (theme.value.backgroundImage.app) {
          style[
            "background-image"
          ] = `url(${theme.value.backgroundImage.app.url})`;
        }

        if (theme.value.backgroundColour.app?.value) {
          style["background-color"] = theme.value.backgroundColour.app?.value;
        }
      }

      return style;
    });

    const navItems = ref<Array<NavItem>>([]);

    const sideMenuItems = ref<Array<any>>([]);

    const menuItems = ref<Array<MenuItem>>([...menuItemsJson]);
    const settingsItems = ref<Array<SettingsItem>>([...settingsItemsJson]);
    const drawerLeftOpen = ref<boolean>(false);
    const drawerRightOpen = ref<boolean>(false);
    const productTour = ref<any>({});

    const userIsLoggedIn = computed(() => store.getters.userIsLoggedIn);
    const showGlobalLoader = computed(() => store.getters.loaderActive);
    const globalModal = computed(() => store.getters.modal);

    const getWindowWidth = () => window.innerWidth;

    const handleToggleDrawerLeft = () => {
      drawerRightOpen.value = false;
      drawerLeftOpen.value = !drawerLeftOpen.value;
    };
    const closeDrawerRight = () => {
      drawerRightOpen.value = false;
      drawerLeftOpen.value = false;
    };
    const handleToggleDrawerRight = (e: Event) => {
      e.preventDefault();
      drawerLeftOpen.value = false;
      drawerRightOpen.value = !drawerRightOpen.value;
    };

    const timeoutHandle = ref(-1);
    const resetIdle = () => {
      if (userIsLoggedIn.value) {
        clearTimeout(timeoutHandle.value);
        timeoutHandle.value = setTimeout(async () => {
          setCookie("ReturnUrl", router.currentRoute.value.fullPath, 10);
          await store.dispatch("logout");
        }, 1200000);
      }
    };

    const refreshHandle = ref(-1);

    const refreshCheck = async () => {
      const authTimestamp: Date = parseISO(store.getters.authTimestamp);
      if (!authTimestamp || new Date() >= addMinutes(authTimestamp, 5)) {
        await store.dispatch("setThemeUpdateAllowed", true);
        auth.refresh();
      }
      setupRefreshCheck();
    };

    const setupRefreshCheck = () => {
      if (userIsLoggedIn.value) {
        clearTimeout(refreshHandle.value);
        refreshHandle.value = setTimeout(refreshCheck, 60000);
      }
    };

    watch(router.currentRoute, () => {
      refreshCheck();
    });

    // define function to call resetIdle function on mouse move and keypress
    const addIdleResetListeners = () => {
      window.addEventListener("keydown", resetIdle);
      window.addEventListener("mousemove", resetIdle);
      window.addEventListener("scroll", resetIdle);
    };
    // define function to remove event listeners
    const removeIdleResetListeners = () => {
      window.removeEventListener("keydown", resetIdle);
      window.removeEventListener("mousemove", resetIdle);
      window.removeEventListener("scroll", resetIdle);
    };

    const handleClickMenuItem = ({ url }) => {
      router.push({
        path: url,
      });
    };

    const getTextItem = (textItems: Array<TextItem>, purpose = "title") => {
      return textItems.find((ti) => ti.purpose === purpose)?.data ?? "";
    };

    const getSideMenuItems = async () => {
      if (navItems.value.find((ni) => ni.title === "menu.quick-links") ?? false)
        return;

      let sideMenuItemsTemp: any[] = [];
      if (!permissionProvider.userHasPermission("user:sidemenu:admin")) {
        const responseUser = await getUserSideMenuItems();
        sideMenuItemsTemp = responseUser.data.payload.results;
      } else {
        const responseAdmin = await getAdminSideMenuItems();
        sideMenuItemsTemp = responseAdmin.data.payload.results;
      }

      if (sideMenuItemsTemp.length) {
        const quickLinksMenu = {
          id: navItems.value.length,
          title: "menu.quick-links",
          class: "quick-links",
          url: "#",
          icon: {
            fa: ["fas", "circle-info"],
            src: "circle-info.svg",
            alt: "",
          },
          children: [] as Array<any>,
        };

        for (const sideMenuItem of sideMenuItemsTemp) {
          let url = `/sidemenuitem/${sideMenuItem.id}`;
          if (
            sideMenuItem.sideMenuItemType === "support" ||
            sideMenuItem.sideMenuItemType === "redirect" ||
            sideMenuItem.sideMenuItemType === "redirect-internal"
          ) {
            url = await getSideMenuMessageContent(sideMenuItem, url);
          }
          sideMenuItems.value.push({
            id: sideMenuItem.id,
            title:
              sideMenuItem.textItems.length > 0
                ? getTextItem(sideMenuItem.textItems, "side-menu-item")
                : sideMenuItem.documentReference
                ? sideMenuItem.documentReference
                : sideMenuItem.videoReference,
            url,
            externalLink:
              sideMenuItem.sideMenuItemType === "support" ||
              sideMenuItem.sideMenuItemType === "redirect",
          });
          quickLinksMenu.children.push({
            id: sideMenuItem.id,
            title:
              sideMenuItem.textItems.length > 0
                ? getTextItem(sideMenuItem.textItems, "side-menu-item")
                : sideMenuItem.documentReference
                ? sideMenuItem.documentReference
                : sideMenuItem.videoReference,
            url,
            externalLink:
              sideMenuItem.sideMenuItemType === "support" ||
              sideMenuItem.sideMenuItemType === "redirect",
            icon: {
              src: "icon-circle.svg",
              alt: "",
            },
          });
        }
        if (
          navItems.value &&
          !navItems.value.find((x) => x.title === "menu.quick-links")
        ) {
          navItems.value.push(quickLinksMenu);
        }
      }
    };

    async function getSideMenuMessageContent(sideMenuItem: any, url: string) {
      const messageResponse = await getMessageContents([
        sideMenuItem.messageReference,
      ]);
      if (messageResponse.data.payload.results) {
        const currentUser = store.getters.currentUser;
        const languageId = currentUser.preferredLanguageId;
        url = getMessageContentData(
          messageResponse.data.payload.results,
          sideMenuItem.messageReference,
          languageId
        );
        if (
          url.indexOf("://") === -1 &&
          url.indexOf("mailto:") === -1 &&
          sideMenuItem.sideMenuItemType !== "redirect-internal"
        ) {
          url = `http://${url}`;
        } else {
          // temporary fix for v1 internal action redirects
          url = url.replace("/action", "");
          url = url.replace("/form", "");
          url = `/${url}`;
        }
      }
      return url;
    }

    const { setLanguage, setDatabaseLanguageValues, currentLanguage } =
      useLanguage();
    const language = useLanguage();

    onMounted(async () => {
      if (!store.state.user.currentUser?.widgetsChecked) {
        const res = await httpGetLayout();
        widgets.value = JSON.parse(res);
        if (widgets.value && widgets.value.length) {
          store.dispatch("setWidgets", widgets.value);
        }
      }

      productTour.value.ready = false;
      setLanguage(currentLanguage.value);
      await setDatabaseLanguageValues(
        store.getters.currentUser.preferredLanguageId
      );
      if (!store.getters.menuItems.length) {
        navItems.value = [...navItemsJson];
        store.dispatch("setMenuItems", navItems.value);
      } else {
        navItems.value = store.getters.menuItems;
      }

      await store.dispatch("setThemeUpdateAllowed", true);
      setupRefreshCheck();
      setLanguage(currentLanguage.value);
      addIdleResetListeners();
      getSideMenuItems();

      productTour.value = {
        options: {
          useKeyboardNavigation: true,
          labels: {
            buttonSkip: language.getValueForString("user-tour.controls.skip"),
            buttonPrevious: language.getValueForString(
              "user-tour.controls.previous"
            ),
            buttonNext: language.getValueForString("user-tour.controls.next"),
            buttonStop: language.getValueForString("user-tour.controls.finish"),
          },
        },
        steps: [
          {
            target: ".ad-Path",
            header: {
              title: language.getValueForString("user-tour.step-0.title"),
            },
            content: language.getValueForString("user-tour.step-0.detail"),
            params: {
              placement: "top",
            },
          },
          {
            target: ".ad-PointGroup--first",
            header: {
              title: language.getValueForString("user-tour.step-1.title"),
            },
            content: language.getValueForString("user-tour.step-1.detail"),
            params: {
              placement: "top",
            },
          },
          {
            target: ".ad-PointGroup--first",
            header: {
              title: language.getValueForString("user-tour.step-2.title"),
            },
            content: language.getValueForString("user-tour.step-2.detail"),
          },
          {
            target: ".foot-container .progress-container",
            header: {
              title: language.getValueForString("user-tour.step-3.title"),
            },
            content: language.getValueForString("user-tour.step-3.detail"),
            params: {
              placement: "top",
            },
          },
          {
            target: ".ad-PointGroup--first",
            header: {
              title: language.getValueForString("user-tour.step-4.title"),
            },
            content: language.getValueForString("user-tour.step-4.detail"),
            params: {
              placement: "top",
            },
          },
          {
            target: ".quick-links",
            header: {
              title: language.getValueForString("user-tour.step-5.title"),
            },
            content: language.getValueForString("user-tour.step-5.detail"),
            params: {
              placement: "right",
            },
          },
          {
            target: ".ad-PointGroup--first",
            header: {
              title: language.getValueForString("user-tour.step-6.title"),
            },
            content: language.getValueForString("user-tour.step-6.detail"),
          },
        ],
        callbacks: {
          onStart: tourStart,
          onPreviousStep: tourPreviousStep,
          onNextStep: tourNextStep,
          onFinish: tourFinish,
          onSkip: tourSkip,
          onStop: tourSkip,
        },
        ready: true,
      };
    });
    onUnmounted(() => {
      clearInterval(refreshHandle.value);
      clearTimeout(timeoutHandle.value);
      removeIdleResetListeners();
    });

    const hasSettingsPermissions = (items: Array<SettingsItem>) => {
      return items.some((i: SettingsItem) => {
        if (!i.permissions && !i.conditions) return true;

        const matchedPermission = i.permissions
          ? i.permissions.filter((p: string) =>
              permissionProvider.userHasPermission(p)
            )
          : [];
        const matchedCondition = i.conditions
          ? permissionProvider.checkUserConditions(i.conditions)
          : false;
        return matchedPermission.length > 0 || matchedCondition;
      });
    };

    const isMobile = computed(() => store.getters.screenSize < 1024);

    /* Tour Code */
    const hotspotInitStyle = ref<{ classes?: string }>({});
    const tourHotspotElement = ref();
    const tourHotspotCircleElement = ref();
    const tourHotspotImageElement = ref();
    const originalHotspotImage = ref();
    const completedIcon = computed(() => {
      return require("@/assets/img/check-mark.png");
    });

    let hotspotDiameter = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--hotspot-size")
        .replace(/[^0-9.-]/g, "")
    );
    if (isNaN(hotspotDiameter)) {
      hotspotDiameter = 50;
    }

    const setHotspotCompleted = () => {
      tourHotspotElement.value.classList.add("completed");

      if (tourHotspotImageElement.value) {
        const elHotspotX = tourHotspotImageElement.value.getAttribute("x");
        const elHotspotY = tourHotspotImageElement.value.getAttribute("y");
        const elHotspotWidth =
          tourHotspotImageElement.value.getAttribute("width");
        const elHotspotHeight =
          tourHotspotImageElement.value.getAttribute("height");
        const elHotspotHref =
          tourHotspotImageElement.value.getAttribute("href");
        originalHotspotImage.value = {
          x: elHotspotX,
          y: elHotspotY,
          width: elHotspotWidth,
          height: elHotspotHeight,
          href: elHotspotHref,
        };
        tourHotspotImageElement.value.setAttribute("href", completedIcon.value);
      }

      const ogX = tourHotspotCircleElement.value
        ? tourHotspotCircleElement.value.getAttribute("cx")
        : 100;
      const ogY = tourHotspotCircleElement.value
        ? tourHotspotCircleElement.value.getAttribute("cy")
        : 100;

      if (tourHotspotImageElement.value) {
        tourHotspotImageElement.value.setAttribute(
          "width",
          (hotspotDiameter + 5) / 2 + ""
        );
        tourHotspotImageElement.value.setAttribute(
          "height",
          (hotspotDiameter + 5) / 2 + ""
        );
        if (ogX) {
          tourHotspotImageElement.value.setAttribute(
            "x",
            +ogX - hotspotDiameter / 4 + ""
          );
        }
        if (ogY) {
          tourHotspotImageElement.value.setAttribute(
            "y",
            +ogY - hotspotDiameter / 4 + ""
          );
        }
      }
    };

    const resetHotspot = async () => {
      if (tourHotspotImageElement.value) {
        tourHotspotImageElement.value.style.display = "none";
        tourHotspotImageElement.value.setAttribute(
          "href",
          originalHotspotImage.value.href
        );
        tourHotspotImageElement.value.setAttribute(
          "width",
          originalHotspotImage.value.width
        );
        tourHotspotImageElement.value.setAttribute(
          "height",
          originalHotspotImage.value.height
        );
        tourHotspotImageElement.value.setAttribute(
          "x",
          originalHotspotImage.value.x
        );
        tourHotspotImageElement.value.setAttribute(
          "y",
          originalHotspotImage.value.y
        );
        setTimeout(() => {
          tourHotspotImageElement.value.style.display = "initial";
        }, 1);
      }

      if (hotspotInitStyle.value && tourHotspotElement.value) {
        const removeClasses =
          tourHotspotElement.value.classList.value.split(" ");

        for (let i = 0; i < removeClasses.length; i++) {
          tourHotspotElement.value.classList.remove(removeClasses[i]);
        }
        if (hotspotInitStyle.value?.classes) {
          const addClasses = hotspotInitStyle.value.classes.split(" ");

          for (let i = 0; i < addClasses.length; i++) {
            tourHotspotElement.value.classList.add(addClasses[i]);
          }
        }
      }
    };

    const tourNextStep = async (step) => {
      switch (step) {
        case 1: {
          setHotspotCompleted();
          break;
        }
        case 2: {
          resetHotspot();
          break;
        }
        case 3: {
          tourHotspotCircleElement.value.style.display = "initial";
          break;
        }
        case 4: {
          tourHotspotCircleElement.value.style.display = "none";
          break;
        }
        case 5: {
          tourHotspotCircleElement.value.style.display = "initial";
          break;
        }
      }
    };

    const tourPreviousStep = (step) => {
      switch (step) {
        case 2: {
          resetHotspot();
          break;
        }
        case 3: {
          if (tourHotspotCircleElement.value) {
            tourHotspotCircleElement.value.style.display = "none";
          }
          setHotspotCompleted();
          break;
        }
        case 4: {
          if (tourHotspotCircleElement.value) {
            tourHotspotCircleElement.value.style.display = "none";
          }
          break;
        }
        case 5: {
          if (tourHotspotCircleElement.value) {
            tourHotspotCircleElement.value.style.display = "initial";
          }
          break;
        }
        case 6: {
          if (tourHotspotCircleElement.value) {
            tourHotspotCircleElement.value.style.display = "none";
          }
          break;
        }
      }
    };

    const completeTour = async () => {
      const res = await setTourViewed();
      if (res.status === 200) {
        const currentUser = { ...store.getters.currentUser };
        currentUser.tourViewed = true;
        store.dispatch("setUserDetails", currentUser);
        return true;
      }
      return false;
    };

    const tourSkip = async () => {
      await resetHotspot();
      await completeTour();
    };
    const tourStart = async () => {
      await setTourRefs();
      if (tourHotspotCircleElement.value) {
        tourHotspotCircleElement.value.style.display = "none";
      }
    };

    const tourFinish = async () => {
      if (await completeTour()) {
        const firstHotspotId = store.getters.hotspots[0].id;
        router.push(`/track/hotspot/${firstHotspotId}`);
      }
    };

    const setTourRefs = () => {
      tourHotspotElement.value = document.querySelector(
        ".ad-PointGroup--first"
      );
      if (tourHotspotElement.value) {
        hotspotInitStyle.value.classes =
          tourHotspotElement.value.classList.value;
        tourHotspotCircleElement.value = tourHotspotElement.value.querySelector(
          "circle.innerCircle"
        ) as HTMLElement | null;
        tourHotspotImageElement.value =
          tourHotspotElement.value.querySelector("image.ad-Point");

        if (tourHotspotImageElement.value) {
          const elHotspotX = tourHotspotImageElement.value.getAttribute("x");
          const elHotspotY = tourHotspotImageElement.value.getAttribute("y");
          const elHotspotWidth =
            tourHotspotImageElement.value.getAttribute("width");
          const elHotspotHeight =
            tourHotspotImageElement.value.getAttribute("height");
          const elHotspotHref =
            tourHotspotImageElement.value.getAttribute("href");
          originalHotspotImage.value = {
            x: elHotspotX,
            y: elHotspotY,
            width: elHotspotWidth,
            height: elHotspotHeight,
            href: elHotspotHref,
          };
        }
      }
    };

    return {
      sideMenuItems,
      background,
      userIsLoggedIn,
      navItems,
      menuItems,
      settingsItems,
      hasSettingsPermissions,
      drawerLeftOpen,
      drawerRightOpen,
      showGlobalLoader,
      globalModal,
      handleToggleDrawerLeft,
      handleToggleDrawerRight,
      closeDrawerRight,
      handleClickMenuItem,
      getWindowWidth,
      productTour,
      isMobile,
    };
  },
});
