
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

import { NavItem, ChatResult } from "@/interfaces";

import { HttpError } from "@/api/httpClient";
import {
  getChatableUsers,
  createChat,
  getChatMessages,
  createChatMessage,
} from "@/api/chats.api";

import { useLanguage } from "@/composables";

import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import Avatar from "@/components/Avatar.vue";
import ChatMessages from "@/components/ChatMessages.vue";
import Search from "@/components/Search.vue";
import TableList from "@/components/TableList.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "Chat",
  components: {
    AuthenticatedLayout,
    Avatar,
    ChatMessages,
    Search,
    TableList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const language = useLanguage();

    const { inPageNotification } = useNotification();

    store.dispatch("showLoader", true);

    const isMobile = computed(() => store.getters.isMobile);
    const screenSize = computed(() => store.getters.screenSize);
    const currentUser = computed(() => store.getters.currentUser);

    const pageTitle = ref("");
    const pageTitleImage = ref("");
    const pageTitleInitials = ref("");

    const isActive = computed(() => true);

    const chatId = router.currentRoute.value.params.id as string;
    const activeChatUserId = ref<string>(chatId ?? "");
    const activeChatMessages = ref<ChatResult>({
      chatMessages: [],
      created: "",
      id: "",
      lastMessage: "",
      lastSenderId: "",
      lastUpdated: "",
      manager: [],
      managerId: "",
      managerLastRead: "",
      user: [],
      userId: "",
      userLastRead: "",
    });

    if (
      router.currentRoute.value.params &&
      router.currentRoute.value.params.id
    ) {
      activeChatUserId.value = router.currentRoute.value.params.id as string;
    }

    const navItems = ref<Array<NavItem>>([]);
    const searchTerm = ref<string>("");
    const filteredResults = computed(() =>
      navItems.value.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.value.toLowerCase())
      )
    );
    const handleUpdateSearchTerm = (value: string) =>
      (searchTerm.value = value);

    const activeNavItem = computed(
      () =>
        navItems.value.find((i) => i.id === activeChatUserId.value)?.url ??
        "chat"
    );

    const getAvatar = computed(() => {
      return {
        image: pageTitleImage.value ?? null,
        alt: pageTitle.value ?? null,
        text: pageTitleInitials.value ?? null,
        aria: pageTitle.value ? `Profile image for ${pageTitle.value}` : "",
      };
    });

    const requestChatableUsers = async () => {
      try {
        return await getChatableUsers();
      } catch (err) {
        const httpError = err as HttpError;
        inPageNotification("Error!", httpError.message, "error");
      }
    };

    const setChatUsers = async (users) => {
      users.forEach((user) => {
        const chatUserId = user.id as string;
        const firstName = user.firstName;
        const surname = user.surname;
        const imgUrl = user.imageContents.length
          ? user.imageContents[0].url
          : "";
        const position = user.position;
        navItems.value.push({
          id: chatUserId,
          title: `${firstName} ${surname} `,
          subtitle: position,
          imgUrl: imgUrl,
          text: `${firstName[0]}${surname[0]} `,
          url: `/chat/${chatUserId}`,
          action: () => openChat(chatUserId),
        } as NavItem);
      });
      if (screenSize.value > 1023) {
        activeChatUserId.value = navItems.value[0].id as string;
        openChat(activeChatUserId.value);
      }
    };

    const openChat = async (chatUserId) => {
      store.dispatch("showLoader", true);
      try {
        if (chatUserId) {
          activeChatUserId.value = chatUserId;
          const response = await createChat(chatUserId, currentUser.value.id);

          if (response.status === 201 && response.data.payload.result) {
            const chatId = response.data.payload.result.id;
            const chatMessages = await getChatMessages(chatId);
            activeChatMessages.value = chatMessages.data.payload.result;

            if (currentUser.value.id == activeChatMessages.value.managerId) {
              pageTitle.value = `${activeChatMessages.value.user.firstName} ${activeChatMessages.value.user.surname}`;
              pageTitleImage.value =
                activeChatMessages.value.user?.imageContents[0]?.url ?? null;
              pageTitleInitials.value = `${activeChatMessages.value.user.firstName[0]}${activeChatMessages.value.user.surname[0]}`;
            } else {
              pageTitle.value = `${activeChatMessages.value.manager.firstName} ${activeChatMessages.value.manager.surname}`;
              pageTitleImage.value =
                activeChatMessages.value.manager?.imageContents[0]?.url ?? null;
              pageTitleInitials.value = `${activeChatMessages.value.user.firstName[0]}${activeChatMessages.value.user.surname[0]}`;
            }
          }
        }
        store.dispatch("showLoader", false);
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("Error!", httpError.message, "error");
        }
      }
    };
    const closeChat = () => {
      pageTitle.value = "";
      activeChatUserId.value = "";
      pageTitleImage.value = "";
    };

    const addNewMessage = async (message) => {
      try {
        await createChatMessage(activeChatMessages.value.id, message.text);
        activeChatMessages.value.chatMessages.push(message);
      } catch (err) {
        const title = language.getValueForString("common.error");
        const msg = language.getValueForString("chat.could-not-send");
        inPageNotification(title, msg, "error");
      }
    };

    const getUserImage = (user) => {
      if (user?.imgUrl) {
        return user?.imgUrl;
      }
      if (user.imageContents && user.imageContents.length) {
        return user.imageContents[0].url;
      }
      return "";
    };

    const getUserInitials = (user) => {
      if (user.title) {
        var split = user.title.trim().split(" ");
        return `${split[0][0]}${
          split[split.length - 1] ? split[split.length - 1][0] : ""
        }`;
      }
    };

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
      const response = await requestChatableUsers();
      if (response) {
        setChatUsers(response.data.payload.results);
      }
      store.dispatch("showLoader", false);
    });

    return {
      isMobile,
      isMounted,
      isActive,
      filteredResults,
      activeNavItem,
      pageTitle,
      openChat,
      closeChat,
      activeChatUserId,
      activeChatMessages,
      addNewMessage,
      getAvatar,
      handleUpdateSearchTerm,
      getUserInitials,
      getUserImage,
    };
  },
});
