
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  readonly,
} from "vue";
import { useRouter } from "vue-router";
import { TextItem } from "@/interfaces";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import VideoAction from "@/components/hotspot/action/VideoAction.vue";
import DocumentAction from "@/components/hotspot/action/DocumentAction.vue";
import { getSideMenuItem } from "@/api/side-menu.api";

export default defineComponent({
  name: "SideMenu",
  components: {
    AuthenticatedLayout,
    DocumentAction,
    VideoAction,
  },
  setup() {
    const router = useRouter();

    const isLoading = ref(true);
    const itemId = computed(
      () => router.currentRoute.value.params.itemId as string
    );

    const textItems = ref<TextItem[]>([]);
    const item = ref<any>();
    const itemAsAction = computed(() => ({
      ...item.value,
      documentReference: item.value.documentReference ?? "",
      videoReference: item.value.videoReference ?? "",
    }));
    const currentComponent = computed(
      () => itemTypes[item.value.sideMenuItemType]
    );
    const itemTypes = readonly({
      video: "VideoAction",
      document: "DocumentAction",
    });

    const getItem = async () => {
      const response = await getSideMenuItem(itemId.value);
      item.value = response.data.payload.result;
      textItems.value = item.value.textItems;
      isLoading.value = false;
    };

    onMounted(() => getItem());
    watch(itemId, () => getItem());

    const getTextItem = (textItems: Array<TextItem>, purpose = "title") => {
      return textItems.find((ti) => ti.purpose === purpose)?.data ?? "";
    };

    return {
      item,
      isLoading,
      currentComponent,
      textItems,
      itemAsAction,
      getTextItem,
    };
  },
});
