
import { defineComponent, computed, PropType } from "vue";
import { useRouter } from "vue-router";
import Tick from "@/components/ui/Tick.vue";
import Tile from "@/components/Tile.vue";
import { Hotspot } from "@/models";

export default defineComponent({
  components: {
    Tick,
    Tile,
  },
  emits: ["set-active-point"],
  props: {
    hotspots: {
      type: Array as PropType<Hotspot[]>,
      default: () => [],
    },
    activePoint: {
      type: Number,
      default: 0,
    },
    edit: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const hotspotTitles = computed(() =>
      props.hotspots.map(
        (hotspot) =>
          hotspot.textItems.find((item) => item.purpose === "title")?.data
      )
    );
    const setActivePoint = (index) => emit("set-active-point", index);
    const handleTileClick = (index: number) => {
      if (props.edit) {
        setActivePoint(index);
      } else {
        const hotspotId = props.hotspots[index].id;
        router.push(`/track/hotspot/${hotspotId}`);
      }
    };
    return { hotspotTitles, setActivePoint, handleTileClick };
  },
});
