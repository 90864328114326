import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionHeader = _resolveComponent("ActionHeader")!
  const _component_Checklist = _resolveComponent("Checklist")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, {
      hotspotDescription: _ctx.hotspotDescription,
      actionTitle: _ctx.actionTitle,
      actionDescription: _ctx.actionDescription,
      class: "title-no-margin"
    }, null, 8, ["hotspotDescription", "actionTitle", "actionDescription"]),
    _createVNode(_component_Checklist, {
      items: _ctx.checklistItems,
      actionable: false
    }, null, 8, ["items"])
  ]))
}