
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Teleport,
  watch,
} from "vue";
import { Manager, User, UserDetails } from "@/models";
import {
  Gender,
  Language,
  Group,
  Role,
  FormQuestion,
  TextItem,
} from "@/interfaces/domain";
import { getAllGenders } from "@/api/gender.api";
import { getAllGroups } from "@/api/groups.api";
import { getAllLanguages } from "@/api/language.api";
import { getAllRoles } from "@/api/roles.api";
import Button from "@/components/Button.vue";
import { useModal } from "@/plugins/modal";
import { useStore } from "vuex";
import UserForm from "@/components/user/details/UserForm.vue";
import CompanyForm from "@/components/user/details/CompanyForm.vue";
import { useLoading } from "@/plugins/loading";
import { onBeforeRouteLeave } from "vue-router";
import { getUserFeatureConfigs } from "@/api/users.api";

import { useNotification } from "@/composables/useNotification";
import {
  getFormQuestionsByForm,
  submitUserForm,
  uploadFormFile,
} from "@/api/form-questions.api";
import { useFormQuestion } from "@/composables/useFormQuestion";
import { getTenantConfig } from "@/api/tenant-config.api";
import { config } from "chai";

export default defineComponent({
  components: {
    UserForm,
    Button,
    CompanyForm,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    userIsLoading: Boolean,
    isNewUser: Boolean,
    isAuthenticatedUser: Boolean,
    config: Object,
  },
  emits: ["created", "update-page-title", "update-page-subtitle"],
  setup(props, { emit }) {
    const showModal = useModal();
    const store = useStore();
    const showLoading = useLoading();

    const { inPageNotification } = useNotification();
    const { setInitialUserResponses, setDependencies, updateDependencies } =
      useFormQuestion();

    const isLoading = ref(true);
    const isDirty = ref(false);

    const uploadFiles = ref({});
    const userDetails = ref<UserDetails>(new UserDetails());
    const userDetailsOriginal = ref<UserDetails>();
    const errors = ref<any>({});
    const isUserFormValid = ref(false);
    const isCompanyFormValid = ref(false);
    const isValid = computed(
      () => isUserFormValid.value && isCompanyFormValid.value
    );
    const additionalFields = ref<any>({ company: {}, personal: {} });

    const deleteButtonIsBlock = computed(() => store.getters.screenSize < 1024);

    const getUserDetails = async (bypassCache = false) => {
      const dbUserDetails = props?.config?.isMe
        ? await props.user?.getMeDetails(bypassCache)
        : await props.user?.getDetails(bypassCache);
      if (dbUserDetails) {
        userDetails.value = new UserDetails({ ...dbUserDetails });
      }
      setupadditionalFields();
      userDetailsOriginal.value = new UserDetails({ ...userDetails.value });
      isLoading.value = false;
    };

    const handleSave = async () => {
      try {
        if (!isValid.value) {
          const errorsObject = {};
          if (errors.value.length) {
            errors.value.forEach(function (e) {
              errorsObject[e?.$property] = e?.$message;
            });
            errors.value = errorsObject;
          }

          const el = document.getElementsByClassName("error")[0];
          const y = el
            ? el.getBoundingClientRect().top + window.pageYOffset + -70
            : 0;

          window.scrollTo({ top: y, behavior: "smooth" });
          return false;
        }
        if (userDetails.value) {
          showLoading(true);
          userDetails.value.additionalFields = JSON.stringify(
            additionalFields.value
          );
          userDetails.value.groups = userDetails.value.groups.map((g) => ({
            ...g,
            users: [],
          }));
          await userDetails.value.save(props?.config?.isMe);
          if (props?.config?.isMe || props.isAuthenticatedUser) {
            const updatedDetails = { ...store.state.user.currentUser };
            updatedDetails.firstName = userDetails.value.firstName;
            updatedDetails.surname = userDetails.value.surname;
            store.dispatch("setUserDetails", updatedDetails);
          }

          let {
            uploadErrors,
            userChoices,
          }: {
            uploadErrors: number;
            userChoices: {
              formQuestionId: string;
              responseValue: string;
              userId: string;
            }[];
          } = await setupUserDetailsForm();

          const id = userDetails.value.id;
          if (uploadErrors === 0 && userChoices.length) {
            await submitUserForm(
              userChoices,
              userDetails.value.id,
              props?.config?.isMe
            );
          }

          isDirty.value = false;
          showLoading(false);
          inPageNotification(
            `${userDetails.value.firstName} ${userDetails.value.surname}`,
            props?.config?.isMe
              ? "models.user.updated-me"
              : props.isNewUser
              ? "models.user.created"
              : "models.user.updated",
            "success"
          );

          if (!id || id.length === 0 || props.isNewUser) {
            emit("created", userDetails.value.id);
          } else {
            await getUserDetails(true);
            isDirty.value = false;
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        showLoading(false);
        if (err.code && err.message) {
          inPageNotification(
            `${props.user.firstName} ${props.user.surname}`,
            err.message.charAt(0).toUpperCase() + err.message?.slice(1),
            "error"
          );
        } else if (err.response) {
          inPageNotification(
            `${props.user.firstName} ${props.user.surname}`,
            err.response.data.errorInfo.details[0].message,
            "error"
          );
        } else {
          console.log(err);
        }
      }
    };

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };

    const uploadFile = async (
      file: File,
      reference: string,
      type = "image"
    ) => {
      const formData: FormData = new FormData();
      formData.append("file", file);
      formData.append(
        "reference",
        "user-details" + "-" + type + "-" + reference
      );
      formData.append("userId", props.user.id as string);

      try {
        const uploaded: any = await uploadFormFile(formData);
        return uploaded?.data?.payload?.result?.id || "";
      } catch {
        return {
          type: (type === "image" ? "images" : "documents") + ".upload.fail",
          reference,
        };
      }
    };

    const handleDiscardChanges = async () => {
      await showModal({
        title: "prompts.discard-changes.title",
        body: "prompts.discard-changes.message",
        onConfirm: async () => {
          userDetails.value = new UserDetails({ ...userDetailsOriginal.value });
          isDirty.value = false;
        },
      });
    };

    const genders = ref<Gender[]>([]);
    const managers = ref<Array<Manager>>([] as Array<Manager>);
    const groups = ref<Group[]>([]);
    const languages = ref<Language[]>([]);
    const roles = ref<Role[]>([]);

    const getData = async (func, data) => {
      try {
        const response = await func();
        data.value = [...response.data.payload.results];
      } catch (err) {
        //TODO: Handle this error better
        console.log(err);
      }
    };

    const updatePageHeader = () => {
      emit(
        "update-page-title",
        `${userDetails.value.firstName} ${userDetails.value.surname}`
      );
      emit("update-page-subtitle", userDetails.value.position);
    };

    const hiddenUserFields = ref<string[]>([]);
    const requiredUserFields = ref<string[]>([]);

    const addionalRequiredFieldsValid = computed(() => {
      if (!isDirty.value) {
        return false;
      } else if (requiredUserFields.value.length === 0) {
        return true;
      } else {
        const inValidFields = requiredUserFields.value.filter((field) => {
          return typeof userDetails.value[field] === "string"
            ? !userDetails.value[field]
            : userDetails.value[field] && userDetails.value[field].length === 0;
        });
        return inValidFields.length === 0;
      }
    });

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;

      const response = await getTenantConfig("additional-user-fields");
      const tenantConfig = response.data.payload.result;
      if (tenantConfig && tenantConfig.condition?.length > 0) {
        const configObject = JSON.parse(tenantConfig.condition);
        if (configObject?.company) {
          for (const key of configObject?.company) {
            additionalFields.value.company[key] = "";
          }
        }
        if (configObject?.personal) {
          for (const key of configObject?.personal) {
            additionalFields.value.personal[key] = "";
          }
        }
      }

      const hiddenFields =
        store.getters.featureConfigs.find(
          (fc) => fc.feature === "hidden-user-fields"
        )?.condition ?? "";
      hiddenUserFields.value = hiddenFields.split(",");

      const additionalRequiredFields =
        store.getters.featureConfigs.find(
          (fc) => fc.feature === "additional-required-user-fields"
        )?.condition ?? "";
      requiredUserFields.value = additionalRequiredFields.split(",");

      await getData(getAllGenders, genders);
      await getData(getAllLanguages, languages);
      if (!props?.config?.isMe) {
        managers.value = await Manager.getAll();
        await getData(getAllGroups, groups);
        await getData(getAllRoles, roles);
      }
      if (!props.isNewUser) {
        await getUserDetails();
      } else {
        if (languages.value.length === 1) {
          userDetails.value.preferredLanguageId = languages.value[0].id;
        }
      }
      await getFormQuestions();

      watch(
        userDetails,
        () => {
          isDirty.value = true;
          updatePageHeader();
        },
        { deep: true }
      );

      watch(
        errors,
        () => {
          if (!props.isNewUser) {
            checkValidation();
          }
        },
        { deep: true }
      );
    });

    onBeforeRouteLeave(async () => {
      if (isDirty.value) {
        const confirmed = await showModal({
          title: "prompts.unsaved-changes.title",
          body: "prompts.unsaved-changes.message",
          onConfirm: async () => {
            return true;
          },
        });

        if (!confirmed) {
          return false;
        }

        userDetails.value = new UserDetails({ ...userDetailsOriginal.value });
        updatePageHeader();
      }
    });

    const formQuestions = ref<Array<FormQuestion>>([]);
    const userResponses = ref({});

    const getFormQuestions = async () => {
      formQuestions.value = [];
      userResponses.value = {};
      uploadFiles.value = {};
      const userId = userDetails.value?.id as string;

      const response = await getFormQuestionsByForm(userId, "user-details");
      formQuestions.value = response.data.payload
        .results as Array<FormQuestion>;

      for (const formQuestion of formQuestions.value) {
        const dependantQuestions = setDependencies(
          formQuestion,
          formQuestions.value
        );
        setInitialUserResponses(
          formQuestion,
          dependantQuestions,
          userResponses.value,
          userDetails.value
        );
      }
    };

    function setupadditionalFields() {
      if (userDetails.value.additionalFields?.length > 0) {
        const savedFields: any = userDetails.value.additionalFields
          ? JSON.parse(userDetails.value.additionalFields)
          : {};
        if (savedFields["company"]) {
          for (const key of Object.keys(savedFields["company"])) {
            additionalFields.value.company[key] = savedFields["company"][key];
          }
        }
        if (savedFields["personal"]) {
          for (const key of Object.keys(savedFields["personal"])) {
            additionalFields.value.personal[key] = savedFields["personal"][key];
          }
        }
      }
    }

    async function setupUserDetailsForm() {
      const userChoices: {
        formQuestionId: string;
        responseValue: string;
        userId: string;
      }[] = [];
      const uploadError = {};
      let uploadErrors = 0;

      for (const formQuestionId of Object.keys(userResponses.value)) {
        let userResponseValue = userResponses.value[formQuestionId].value;
        const formQuestion: FormQuestion = formQuestions.value.find(
          (fq: FormQuestion) => fq.id == formQuestionId
        ) as FormQuestion;

        if (!userResponses.value[formQuestionId].hide) {
          // Ensure user responses are correctly formatted as readable values.
          if (formQuestion) {
            switch (formQuestion.questionType) {
              case "imageupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id
                  );
                }
                break;
              case "pdfupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id,
                    "pdf"
                  );
                }
                break;
              case "genericupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id,
                    "file"
                  );
                }
                break;
              case "date-of-birth":
              case "date":
                break;
              case "bigtext":
                if (userResponseValue !== null) {
                  userResponseValue = userResponseValue.replace(
                    /(?:\r\n|\r|\n)/g,
                    " "
                  );
                }
                break;
              case "checkboxes":
                userResponseValue = "";
                userResponses.value[formQuestionId].value
                  .split(",")
                  .map((cb: any) => {
                    const formQuestionChoice: any = formQuestion.choices.find(
                      (item: any) => item.id === cb
                    );
                    userResponseValue +=
                      getTextItem(
                        formQuestionChoice.textItems,
                        "form-question-choice"
                      ) + ",";
                  });
                userResponseValue = userResponseValue.substring(
                  0,
                  userResponseValue.length - 1
                );
                break;
              case "select":
              case "statements": {
                const formQuestionChoice: any = formQuestion.choices.find(
                  (item: any) => item.id === userResponseValue
                );
                if (formQuestionChoice) {
                  userResponseValue =
                    getTextItem(
                      formQuestionChoice.textItems,
                      "form-question-choice"
                    ) + ",";
                  userResponseValue = userResponseValue.substring(
                    0,
                    userResponseValue.length - 1
                  );
                } else {
                  userResponseValue = "";
                }

                break;
              }
            }
          }

          if (userResponseValue && userResponseValue.type) {
            uploadErrors++;
            uploadError[formQuestionId] = userResponseValue.type;
            inPageNotification(
              "form.error-upload-title",
              "form.error-upload",
              "error"
            );
          }

          if (userResponseValue !== "" && userResponseValue !== null) {
            userChoices.push({
              formQuestionId,
              responseValue: userResponseValue,
              userId: props.user.id
                ? (props.user.id as string)
                : (userDetails.value.id as string),
            });
          }
        }
      }
      return { uploadErrors, userChoices };
    }
    const handleDoChange = ({ name, value }) => {
      if (name) {
        if (!Object.keys(errors.value).length) {
          isUserFormValid.value = true;
        }
        userResponses.value[name].changed = true;
        isDirty.value = true;
        userResponses.value[name].value = value;
        const formQuestion = formQuestions.value.find((fq) => fq.id === name);

        if (formQuestion?.fieldReference !== undefined) {
          userDetails.value[formQuestion?.fieldReference] = value;
        }
      }
    };

    const handleChoiceChange = (event) => {
      const { name, value } = event.target;
      if (!isDirty.value) isDirty.value = true;
      updateDependencies(name, value, userResponses.value);
    };

    const handleSelectChange = ({ name, value }) => {
      if (!isDirty.value) isDirty.value = true;
      updateDependencies(name, value, userResponses.value);
    };

    const handleUpdateAdditionalField = ({ name, value, type }) => {
      if (!isDirty.value) isDirty.value = true;
      checkValidation();
      if (additionalFields.value[type]) {
        additionalFields.value[type][name] = value;
      }
    };

    const checkValidation = () => {
      if (!Object.keys(errors.value).length) {
        isCompanyFormValid.value = true;
        isUserFormValid.value = true;
      }
    };

    return {
      isMounted,
      genders,
      managers,
      languages,
      groups,
      roles,
      isValid,
      isUserFormValid,
      isCompanyFormValid,
      isDirty,
      userDetails,
      errors,
      handleDiscardChanges,
      handleSave,
      deleteButtonIsBlock,
      hiddenUserFields,
      requiredUserFields,
      addionalRequiredFieldsValid,
      formQuestions,
      userResponses,
      additionalFields,
      handleDoChange,
      handleSelectChange,
      handleChoiceChange,
      handleUpdateAdditionalField,
    };
  },
});
