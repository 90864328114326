
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useLanguage } from "@/composables";
import { getReviewUsersPaginated } from "@/api/users.api";
import { getAllGroups } from "@/api/groups.api";
import { getReviewTemplates } from "@/api/reviews.api";
import { UserTableData as IUserTableData } from "@/interfaces/UserTableData.interface";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { Chip as IChip } from "@/interfaces/ui/Chip.interface";
import Button from "@/components/Button.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import FilterMenu from "@/components/FilterMenu.vue";
import ListMenu from "@/components/ListMenu.vue";
import MobileSideMenu from "@/components/MobileSideMenu.vue";
import Modal from "@/components/Modal.vue";
import Search from "@/components/Search.vue";
import TableCell from "@/components/TableCell.vue";
import TableDropdown from "@/components/TableDropdown.vue";
import TableList from "@/components/TableList.vue";

export default defineComponent({
  name: "Performance",
  components: {
    AuthenticatedLayout,
    Button,
    ButtonBar,
    FilterMenu,
    ListMenu,
    Modal,
    MobileSideMenu,
    Search,
    TableCell,
    TableDropdown,
    TableList,
  },
  emits: ["close-warning", "change-page"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const { translateText } = useLanguage();
    const currentPage = ref<number>(1);
    const rowCount = ref<number>(store.getters.pageSize);
    const userData = ref<IUserTableData[]>([]);
    const totalUsers = ref<number>(0);
    const groups = ref<{ id: number; active: boolean; name: string }[]>([]);
    const filterTitle = ref<string>("Filter");
    const totalSelectedUsers = ref<number>(0);
    const tableCustomClass = "users-table";
    const globalModal = computed(() => store.getters.globalModal);
    const activeSortIndex = ref<number>(1);
    const currentSort = ref<string>("name");
    const sortOrder = ref<string>("ASC");
    const search = ref<string>("");
    const filteredData = ref<IUserTableData[]>([]);
    const inSubmenu = ref<boolean>(false);
    const activeFilterIndex = ref<number | null>(null);
    const selectedUserCheckboxes = ref<string[]>([]);
    const deselectedUserCheckboxes = ref<string[]>([]);
    const selectAllUsers = ref<boolean>(false);
    const colsFeatured = [0, 1, 5];
    const rowActiveUser = ref<number>(-1);
    const sortActiveIndex = ref<number>(0);
    const userActionsChip = ref<IChip>({
      id: 0,
      items: [
        {
          active: false,
          id: "activate",
          title: "Activate",
        },
        {
          active: false,
          id: "deactivate",
          title: "Deactivate",
        },
      ],
      name: "With Selected",
      selectAll: false,
      show: false,
      dropdownType: "action-list",
    });

    const buttonBarData = ref({
      sortOpen: false,
      filterOpen: false,
      searchOpen: false,
    });
    const filters = ref<any[]>([
      {
        id: 0,
        name: "form.groups",
        items: [
          {
            id: "eb66ddc5-5242-4fe6-b20f-5bc3ae7c0b64",
            active: false,
            name: "User",
          },
          {
            id: "9b82e3d6-3f6c-4ca5-b444-f49ab1cb4c8a",
            active: false,
            name: "hr",
          },
          {
            id: "73deecf8-d552-48a5-a39d-f7c6bfe173c2",
            active: false,
            name: "admin",
          },
          {
            id: "a5a6f2bc-f14e-4661-8d32-b5067f42e6b1",
            active: false,
            name: "manager",
          },
        ],
        show: false,
        selectAll: false,
        dropdownType: "checklist",
      },
      {
        id: 1,
        name: "form.cohort",
        items: groups.value,
        show: false,
        selectAll: false,
        dropdownType: "checklist",
      },
    ]);

    const filtersSelected = ref<{ name: string; values: string[] }[]>([
      {
        name: "Roles",
        values: [],
      },
      {
        name: "Cohort",
        values: [],
      },
    ]);
    const sideMenuItems = ref<any[]>(filters.value);
    const headings = ref<any>([]);
    const headingsInitial = [
      {
        id: 0,
        title: "Ascending",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 1,
        title: "Descending",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 2,
        title: "Last Updated",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 3,
        title: "",
        sortOrder: "",
        active: false,
        desktop: true,
        mobileSortable: true,
        mobileFeatured: false,
      },
      {
        id: 4,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        desktop: true,
        notShownInMobile: true,
        mobileFeatured: true,
      },
      {
        id: 5,
        title: "Name",
        sortOrder: "asc",
        sortable: true,
        mobileSortable: true,
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
    ];
    const mobileSortItems = ref<any>([]);

    const desktopHeadings = ref<any>([]);

    /* Placeholder data for developing Warning component */
    const warning = ref({
      icon: {
        img: require("@/assets/img/warning.svg"),
        alt: "Warning",
      },
      title: "prompts.user-limit.title",
      subtitle: "980 / 1000",
      text: "prompts.user-limit.message",
      active: false,
    });

    let headingsData = ref({
      filterOpen: false,
      colsFeatured: [0, 2, 6],
      rowActiveUser: -1,
      sortActiveIndex: 0,
    });

    const requestPaginatedUsers = async (
      name = "",
      sortCol?: string,
      order?: string
    ) => {
      return await getReviewUsersPaginated(
        currentPage.value,
        rowCount.value,
        name,
        sortCol,
        order,
        filtersSelected.value
      );
    };

    const getRoleName = (role) => {
      const roleName = `${role.name.charAt(0).toUpperCase()}${role.name.slice(
        1
      )}`;
      const translatedName = translateText(`role.name.${role.name}`);
      return translatedName != `role.name.${role.name}`
        ? translatedName
        : roleName;
    };

    const setAllUsers = async (users) => {
      const reviewTemplates = await getReviewTemplates();
      const reviewCount = reviewTemplates.data.payload.result.length;
      headings.value = [...headingsInitial];

      for (let i = 0; i < reviewCount; i++) {
        headings.value.push({
          id: i + 6,
          title: "Review " + (i + 1),
          sortOrder: "",
          sortable: false,
          mobileSortable: false,
          active: false,
          desktop: true,
        } as any);
      }

      mobileSortItems.value = headings.value.filter(
        (heading) => heading.mobileSortable
      );

      desktopHeadings.value = headings.value.filter(
        (heading) => heading.desktop
      );

      userData.value = [];
      users.forEach((user, index: number) => {
        const id = user.id as string;
        const firstName = user.firstName;
        const surname = user.surname;
        const roles: string[] = [];

        user.roles?.forEach((role) => {
          roles.push(getRoleName(role));
        });

        const cohorts: string[] = [];
        // For each cohort, push the name of the cohort to the cohorts array
        user.groups.forEach((group) => {
          group.textItems.forEach((ti) => {
            const cohort = ti.data;
            cohorts.push(cohort);
          });
        });
        const deactivated = user.isDeactivated ? true : false;
        const userRow = {
          rowId: index,
          id,
          deactivated: deactivated,
          cols: [
            {
              id: 0,
              colId: 3,
              dataType: "",
              data: {
                selected: false,
              },
            },
            {
              id: 1,
              colId: 4,
              dataType: "avatar",
              data: {
                src: "avatar.svg",
                alt: `Portrait photo of ${firstName} ${surname}`,
                status: true,
              },
            },
            {
              id: 2,
              colId: 5,
              dataType: "user",
              data: {
                firstName: firstName,
                lastName: surname,
              },
            },
          ],
        };
        user.reviews.sort(
          (review1, review2) => review1.reviewNumber - review2.reviewNumber
        );
        for (let i = 0; i < reviewCount; i++) {
          userRow.cols.push({
            id: i + 3,
            colId: i + 6,
            dataType: "tick",
            data: {
              selected: user.reviews[i].completed,
            },
          });
        }

        // Push each row to rows array on each iteration
        userData.value.push(userRow);
      });
    };

    // Change number of rows per page
    const changeRowCount = async (newRowCount: number) => {
      store.dispatch("setPageSize", newRowCount);
      rowCount.value = newRowCount;
      currentPage.value = 1;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
    };

    // Change page number
    const changePage = async (targetPage: number) => {
      currentPage.value = targetPage;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);

      if (selectAllUsers.value) {
        userData.value.forEach((user) => {
          selectedUserCheckboxes.value.push(user.id);
        });
      }
    };

    // Searching
    const openSearch = () => {
      buttonBarData.value.searchOpen = true;
      const input = document.getElementById("search-bar");
      if (input) {
        input.focus();
      }
    };

    const filterSearch = async (searchTerm: string) => {
      search.value = searchTerm;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    const closeSearch = () => {
      buttonBarData.value.searchOpen = false;
    };

    // Sorting
    const handleClickSort = async (sort: { title: string; id: number }) => {
      const previousActiveColIndex = headings.value.findIndex(
        (val) => val.active
      );
      const clickedSortColIndex = headings.value.findIndex(
        (val) => val.id === sort.id
      );

      if (clickedSortColIndex >= 0) {
        headings.value[clickedSortColIndex].active = true;

        // Determine ascending or descending sort order
        if (sort.title.toLowerCase() === "ascending") {
          currentSort.value = "name";
          headings.value[clickedSortColIndex].sortOrder = "asc";
        } else if (sort.title.toLowerCase() === "descending") {
          currentSort.value = "name";
          headings.value[clickedSortColIndex].sortOrder = "desc";
        } else {
          if (
            sort.title.toLowerCase() === currentSort.value.toLowerCase() &&
            headings.value[clickedSortColIndex].sortOrder === "asc"
          ) {
            headings.value[clickedSortColIndex].sortOrder = "desc";
          } else if (
            sort.title.toLowerCase() === currentSort.value.toLowerCase() &&
            headings.value[clickedSortColIndex].sortOrder === "desc"
          ) {
            headings.value[clickedSortColIndex].sortOrder = "asc";
          } else {
            headings.value[clickedSortColIndex].sortOrder = "asc";
          }
          currentSort.value = sort.title.toLowerCase();
        }
        if (
          previousActiveColIndex >= 0 &&
          clickedSortColIndex !== previousActiveColIndex
        ) {
          headings.value[previousActiveColIndex].active = false;
          headings.value[previousActiveColIndex].sortOrder = "";
        }
      }

      sortOrder.value = headings.value[clickedSortColIndex].sortOrder;
      activeSortIndex.value = sort.id;

      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);

      buttonBarData.value.sortOpen = false;
    };

    const toggleSort = () => {
      buttonBarData.value.sortOpen = !buttonBarData.value.sortOpen;
    };

    const toggleFilter = () => {
      buttonBarData.value.filterOpen = !buttonBarData.value.filterOpen;
    };

    const toggleChip = (index: number) => {
      sideMenuItems.value.map((item) => {
        if (item.id === index) {
          item.show = !item.show;
          if (item.show) {
            activeFilterIndex.value = index;
          } else {
            activeFilterIndex.value = null;
          }
        } else {
          item.show = false;
        }
      });
    };

    const clickFilterItem = (filter: {
      filterIndex: number;
      itemId: string;
    }) => {
      sideMenuItems.value.some((f) => {
        if (filter.itemId === f.id) {
          f.active = !f.active;
          filterTitle.value = f.name;
          activeFilterIndex.value = filter.filterIndex;
          inSubmenu.value = !inSubmenu.value;
          return;
        }
      });
    };

    // select all toggle for filters
    const selectAll = (filterId: number) => {
      filters.value?.map((filter) => {
        if (filter.id === filterId) {
          if (!filter.selectAll) {
            filter.items.forEach(
              (item: { id: number; active: boolean; name: string }) => {
                item.active = true;
              }
            );
            filter.selectAll = true;
          } else {
            filter.items.forEach(
              (item: { id: number; active: boolean; name: string }) => {
                item.active = false;
              }
            );
            filter.selectAll = false;
          }
        }
      });
      buildFilterQuery();
    };

    const goBack = () => {
      sideMenuItems.value.map((item) => {
        item.active = false;
      });
      inSubmenu.value = !inSubmenu.value;
      filterTitle.value = "Filter";
    };

    const selectFilter = (filter: { filterIndex: number; itemId: string }) => {
      filters.value?.map((f) => {
        if (f.id === activeFilterIndex.value) {
          f.items.map((item) => {
            if (item.id === filter.itemId) {
              item.active = !item.active;
              if (item.active) {
                if (activeFilterIndex.value !== null) {
                  filtersSelected.value[activeFilterIndex.value].values.push(
                    item.id
                  );
                }
              } else {
                if (activeFilterIndex.value !== null) {
                  const index = filtersSelected.value[
                    activeFilterIndex.value
                  ].values.indexOf(item.id);

                  filtersSelected.value[activeFilterIndex.value].values.splice(
                    index,
                    1
                  );
                }
              }
              return;
            } else {
              return;
            }
          });
        } else {
          return;
        }
      });
      if (activeFilterIndex.value !== null) {
        if (
          filtersSelected.value[activeFilterIndex.value].values.length <
          filters.value[activeFilterIndex.value].items.length
        )
          filters.value[activeFilterIndex.value].selectAll = false;
      }
      buildFilterQuery();
    };

    const buildFilterQuery = async () => {
      const filterQuery: { name: string; values: string[] }[] = [];
      filters.value?.map((f) => {
        let filterName: string = f.name;
        let filterVal = () => {
          const itemsArr: string[] = [];
          f.items.map((item) => {
            if (item.active) {
              itemsArr.push(item.id);
            }
          });
          return itemsArr;
        };
        filterQuery.push({ name: filterName, values: filterVal() });
      });
      filterQuery.forEach((filter, index) => {
        filtersSelected.value[index].values = filter.values;
      });
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    const clearTableSelection = () => {
      selectedUserCheckboxes.value = [];
      deselectedUserCheckboxes.value = [];
      selectAllUsers.value = false;
    };

    const toggleActionsChip = () =>
      (userActionsChip.value.show = !userActionsChip.value.show);

    const navigateToUser = (userId: string) => {
      router.push(`/reviews/${userId}/review1`);
    };

    const handleClickUser = (e: Event, id: number) => {
      rowActiveUser.value = id;
    };
    const closeWarning = () => {
      warning.value.active = false;
    };

    const clearAllFilters = async () => {
      filtersSelected.value.forEach((filter) => {
        filter.values.splice(0);
      });
      filters.value.forEach((filter) => {
        filter.items.forEach((item) => {
          item.active = false;
        });
      });

      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    // determine whether a number is odd
    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    const handleClickRow = function (id: number) {
      if (headingsData.value.rowActiveUser !== id) {
        headingsData.value.rowActiveUser = id;
      } else {
        headingsData.value.rowActiveUser = -1;
      }
    };

    const handleClickCell = (id: string, rowId: number) => {
      if (window.innerWidth >= 768) {
        navigateToUser(id);
      } else {
        handleClickRow(rowId);
      }
    };

    onMounted(async () => {
      const response = await requestPaginatedUsers();
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;

      const groupRes = await getAllGroups();
      groupRes.data.payload.results.forEach((group: any) => {
        groups.value.push({
          id: group.id,
          active: false,
          name: group.textItems[0].data,
        });
      });
    });

    return {
      buttonBarData,
      warning,
      userData,
      currentPage,
      changePage,
      changeRowCount,
      totalUsers,
      rowCount,
      openSearch,
      closeSearch,
      filterSearch,
      filteredData,
      handleClickSort,
      toggleSort,
      toggleFilter,
      desktopHeadings,
      mobileSortItems,
      tableCustomClass,
      filters,
      groups,
      toggleChip,
      selectFilter,
      clickFilterItem,
      sideMenuItems,
      selectAll,
      goBack,
      filterTitle,
      globalModal,
      totalSelectedUsers,
      inSubmenu,
      activeFilterIndex,
      selectedUserCheckboxes,
      deselectedUserCheckboxes,
      selectAllUsers,
      clearTableSelection,
      navigateToUser,
      colsFeatured,
      rowActiveUser,
      sortActiveIndex,
      handleClickUser,
      closeWarning,
      clearAllFilters,
      userActionsChip,
      toggleActionsChip,
      isOdd,
      headingsData,
      handleClickCell,
    };
  },
});
