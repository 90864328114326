<template>
  <div class="border">
    <p>{{ title }}</p>
    <div class="image-container">
      <div class="preview" :style="previewStyle" @click="handleClick"></div>
      <img class="editIcon" :src="require('@/assets/img/icon-edit.svg')" />
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: "ImageSelector",
  props: {
    type: {
      type: String,
    },
    placeholderImage: {
      type: String,
    },
    previewStyle: {
      type: Object,
    },
    title: {
      type: String,
      default: "Image",
    },
  },
  setup(props, { emit }) {
    const previewUrl = ref(props.backgroundImage);
    const previewColour = ref(props.colour);
    watch(props, () => {
      if (props.backgroundImage) {
        previewUrl.value = props.backgroundImage;
      }
    });

    const handleClick = () => {
      return selectImage();
    };

    const selectImage = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.addEventListener("change", () => {
        emit("change", {
          name: "placeholder",
          value: input.files[0],
        });
        setImagePreview(input.files[0]);
      });
      input.click();
    };

    const setImagePreview = (source) => {
      if (source instanceof File) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          previewUrl.value = reader.result;
        });
        reader.readAsDataURL(source);
        return;
      }
      if (typeof source == "string") {
        previewUrl.value = source;
      }
    };

    return {
      previewColour,
      previewUrl,
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.border {
  width: 100%;
  margin: 1em 0;
  border: 1px solid #ccc;
  border-radius: 0.33em;
  position: relative;
  padding: 20px 10px 10px 10px;
  p {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: -14px;
    background: white;
  }

  .preview {
    height: 522px;
    cursor: pointer;
    transition: 200ms background;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .editIcon {
    position: absolute;
    pointer-events: none;
    left: 50%;
    top: 50%;
    width: 1.5em;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    color: #fff;
    transition: 150ms all;
    mix-blend-mode: exclusion;
  }

  &:hover .editIcon {
    opacity: 1;
    width: 1.6em;
  }
}
</style>
