
import { useLanguage } from "@/composables";
import { computed, defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "TextArea",
  props: {
    customClass: String,
    id: String,
    name: String,
    label: String,
    value: String,
    dragging: Boolean,
    editMode: Boolean,
    rows: Number,
    required: Boolean,
    disabled: Boolean,
    error: {
      type: String,
      required: false,
    },
    labelAlwaysActive: Boolean,
    hideLabelOnFocus: Boolean,
    compact: Boolean,
    placeholder: String,
    resizable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "enter", "blur", "click-append-icon", "edit"],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const handleInput = (event: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("change", { name, value });
    };

    const isFocussed = ref<boolean>(false);
    const isActive = computed(
      () =>
        isFocussed.value ||
        !!props.value ||
        props.labelAlwaysActive ||
        props.placeholder
    );
    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = (event: Event) => {
      isFocussed.value = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("blur", { name, value });
    };

    const clickAppendIcon = () => emit("click-append-icon");

    const enter = () => emit("enter");

    const handleEditClick = () => emit("edit");

    const placeholderText = computed(() =>
      getValueForString(props.placeholder ?? "")
    );

    const classes = computed(() => {
      const custom = props.customClass;
      return {
        compact: !!props.compact,
        custom: !!custom,
      };
    });

    return {
      isFocussed,
      classes,
      isActive,
      placeholderText,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      clickAppendIcon,
      handleEditClick,
    };
  },
});
