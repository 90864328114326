
import { useYoutubePlayer } from "@/composables";
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "YoutubePlayer",
  emits: ["onReady", "onStateChange"],
  props: {
    playerHeight: {
      type: [String, Number],
      default: "100%",
    },
    playerWidth: {
      type: [String, Number],
      default: "100%",
    },
    playerVars: {
      type: Object,
      default: () => ({ autoplay: 0, time: 0 }),
    },
    videoSource: {
      type: String,
      required: true,
    },
    host: {
      type: String,
      default: "https://www.youtube.com",
    },
    playerId: {
      type: Number,
      required: true,
    },
    isPlaying: Boolean,
    isFullScreen: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const playerWidth = computed(() => props.playerWidth);
    const playerHeight = computed(() => props.playerHeight);
    const videoId = computed(() => getEmbedId(props.videoSource));

    const { inPageNotification } = useNotification();

    const getEmbedId = (url: string) => {
      const regExp = new RegExp(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      );
      const match = url.match(regExp);
      if (match && match[2].length == 11) {
        return match[2];
      } else {
        inPageNotification(
          "Whoops!",
          "We could not find the video you were looking for",
          "error"
        );
        return undefined;
      }
    };

    const { player } = useYoutubePlayer("player", {
      width: playerWidth.value,
      height: playerHeight.value,
      videoId: videoId.value,
      playerVars: {
        // eslint-disable-next-line prettier/prettier
        "playsinline": 1,
        controls: 0,
      },
      events: {
        // eslint-disable-next-line prettier/prettier
        "onReady": (event) => emit("onReady", event),
        // eslint-disable-next-line prettier/prettier
        "onStateChange": (event) => {
          emit("onStateChange", event);
        },
      },
    });

    watch(
      () => props.isPlaying,
      (newVal) => {
        if (newVal && player !== undefined) player.value?.playVideo();
        else player.value?.pauseVideo();
      }
    );
  },
});
