
import { PropType, defineComponent } from "vue";

export interface TimelineItem {
  time: string;
  text: string;
  notificationText: string;
  subText: string;
  icon: string;
  hasAction?: {
    action: string;
    actionText: string;
  };
}
export interface TimelineGroup {
  title: string;
  items: Array<TimelineItem>;
}
export default defineComponent({
  props: {
    groups: Array as PropType<Array<TimelineGroup>>,
  },
  emits: ["action-click"],
  setup(props, { emit }) {
    const handleActionClick = (actionType?: string) => {
      emit("action-click", actionType);
    };
    return {
      handleActionClick,
    };
  },
});
