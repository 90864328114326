import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46f93a51"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "value"]
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("select", {
    id: _ctx.id,
    name: _ctx.name,
    value: _ctx.value,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("option", {
        key: item.value,
        value: item.value,
        selected: item.value === _ctx.value
      }, [
        _createVNode(_component_TranslatedText, {
          path: item.text
        }, null, 8, ["path"])
      ], 8, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1))
}