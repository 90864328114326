
import { PaginationDetails } from "@/interfaces/Pagination";
import { defineComponent, computed, onMounted, ref } from "vue";
import MenuBar from "@/components/MenuBar.vue";
import BackLink from "@/components/BackLink.vue";
import Pagination from "@/components/Pagination.vue";
import Table from "@/components/Table.vue";
import { useStore } from "vuex";
import { getHappySadFeedbackForReport } from "@/api/reports.api";

export default defineComponent({
  name: "PulseReport",
  components: { MenuBar, BackLink, Pagination, Table },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const reportData = ref<any>([]);

    const data = computed(() => {
      if (!reportData.value) {
        return [];
      }
      return reportData.value.data;
    });

    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    const paginationDetails = computed(() => reportData.value?.page);

    const handleChangePage = async (pageNumber: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber,
      });
      await getReportData(pagination);
    };
    const handleChangeRowCount = async (pageSize: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
        pageSize,
      });
      await getReportData(pagination);
    };

    const searchText = ref();
    const handleSearchChange = async (value: string) => {
      searchText.value = value;
      await getReportData();
    };

    onMounted(() => {
      getReportData();
    });

    const getReportData = async (pagination?: PaginationDetails) => {
      isLoading.value = true;
      if (!pagination) {
        const pageSize = store.getters.pageSize;
        pagination = new PaginationDetails({ pageSize });
      }
      if (reportData.value) {
        reportData.value.data = [];
      }
      const response = await getHappySadFeedbackForReport(
        pagination,
        searchText.value
      );
      reportData.value.data = response.data.payload.paginatedResults.rows;
      reportData.value.page = {
        pageNumber: response.data.payload.paginatedResults.details.pageNumber,
        total: response.data.payload.paginatedResults.details.total,
      };
      updateScores();
    };
    const feedbackScores = ref<number[]>([]);

    const updateScores = () => {
      // Clear feedback scores.
      feedbackScores.value = [];

      // Loop through managers.
      for (const manager of reportData.value.data) {
        // Calculate score for manager.
        let total = 0;
        let positive = 0;
        for (const user of manager.managed) {
          for (const feedbackItem of user.feedback) {
            total++;
            if (!feedbackItem.isNegative) {
              positive++;
            }
          }
        }
        const score = total === 0 ? -1 : Math.round((positive / total) * 100);

        // Store the score
        feedbackScores.value[manager.id] = score;
      }
    };

    return {
      handleSearchChange,
      searchText,
      data,
      paginationDetails,
      handleChangePage,
      handleChangeRowCount,
      feedbackScores,
      isOdd,
    };
  },
});
