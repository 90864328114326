import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a08b2e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-menu" }
const _hoisted_2 = { class: "setting-title" }
const _hoisted_3 = { class: "setting-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSettingsItems, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createVNode(_component_router_link, {
            to: item.url,
            onClick: _ctx.closeDrawer
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_TranslatedText, {
                  path: item.name
                }, null, 8, ["path"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_TranslatedText, {
                  path: item.description
                }, null, 8, ["path"])
              ])
            ]),
            _: 2
          }, 1032, ["to", "onClick"])
        ]))
      }), 128))
    ])
  ]))
}