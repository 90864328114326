
import { defineComponent, PropType } from "vue";
import { EmergencyContact } from "@/models";
import { ValidationArgs } from "@vuelidate/core";
import { Schemas, useSchema } from "@/plugins/schema";
import Card from "@/components/Card.vue";
import DynamicList from "@/components/DynamicList.vue";
import EmergencyContactForm from "@/components/user/shared/EmergencyContactForm.vue";
import { useLanguage } from "@/composables";

export default defineComponent({
  components: {
    Card,
    DynamicList,
    EmergencyContactForm,
  },
  props: {
    contacts: {
      type: Array as PropType<Array<EmergencyContact>>,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "delete"],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const schema: ValidationArgs = useSchema(Schemas.EmergencyContact);
    const getTitle = (index: number, isPrimary: boolean) =>
      isPrimary
        ? "models.emergency-contact.primary"
        : `${getValueForString("models.emergency-contact.singular")} ${
            index + 1
          }`;

    const handleSave = (contact: EmergencyContact) =>
      emit("save", new EmergencyContact(contact));
    const handleDelete = (contact: EmergencyContact) =>
      emit("delete", new EmergencyContact(contact));

    const createNewEmergencyContact = () => new EmergencyContact();

    return {
      schema,
      handleSave,
      handleDelete,
      getTitle,
      createNewEmergencyContact,
    };
  },
});
