
import {
  defineComponent,
  watch,
  PropType,
  ref,
  computed,
  onMounted,
  toRef,
} from "vue";
import { User, Checklist } from "@/models";
import { usePermissionProvider } from "@/plugins/permission";
import { useStore } from "vuex";
import Accordion from "@/components/Accordion.vue";
import ChecklistVue from "@/components/Checklist.vue";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isAuthenticatedUser: Boolean,
  },
  components: {
    Accordion,
    ChecklistVue,
  },
  setup(props) {
    const isLoading = ref(true);
    const permissionProvider = usePermissionProvider();
    const store = useStore();

    const user = toRef(props, "user");

    const currentUserChecklist = computed(
      () =>
        store.getters.currentUser &&
        store.getters.currentUser.id === user.value.id
    );

    const checklists = ref<Array<Checklist>>();
    const getChecklists = async () => {
      isLoading.value = true;
      checklists.value = await user.value.getChecklists();
      if (checklists.value) {
        checklists.value.map((c) => {
          if (c.items) {
            c.items.map((i) => {
              i.additionalDescription = i.adminDescription ?? "";
            });
          }
        });
      }
      isLoading.value = false;
    };

    const handleChecklistClick = async (itemId: string) => {
      if (
        !permissionProvider.userHasPermission("user:checklists:update") ||
        !checklists.value ||
        currentUserChecklist.value
      ) {
        return;
      }

      const checklistIndex = checklists.value.findIndex((c) =>
        c.items.some((i) => i.id === itemId)
      );

      const checklist = checklists.value[checklistIndex];
      const item = checklist.items.find((i) => i.id === itemId);

      if (item) {
        const originalValue = item.completed;
        item.completed = !item.completed;
        try {
          await checklist.updateItem(item);
        } catch (err) {
          item.completed = originalValue;
          console.log(err);
        }
      }
    };

    onMounted(() => {
      if (user.value.id) {
        getChecklists();
      }
    });
    watch(user, () => getChecklists());

    return {
      isLoading,
      checklists,
      handleChecklistClick,
    };
  },
});
