import httpClient, { ApiResponse } from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getTracks = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/tracks?cache=${+ new Date}`);

export const getAllTracks = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/tracks/all`);

export const saveHotspotAppearanceChanges = async (
  hotspotAppearance: any
): Promise<AxiosResponse<any>> =>
  await httpClient.put(`/hotspots/update-hotspot-appearances`, {
    hotspotAppearance,
  });

export const httpDeleteTrack = async (trackId: string) =>
  await httpClient.delete(`/tracks/delete/${trackId}`);

export const createTrack = async (body: {
  name: string;
  description: string;
}): Promise<ApiResponse<any>> => httpClient.post(`/tracks/create`, body);

export const updateTrack = async (body: {
  id: string | null;
  name: string;
  description: string;
}): Promise<ApiResponse<any>> => httpClient.put(`/tracks/edit`, body);
