import httpClient, { ApiResponse } from "@/api/httpClient";

export const httpGetLayout = async (): Promise<string> => {
  const response = await httpClient.get("/dashboard/layout");
  return response.data.payload.results;
};

export const httpGetUserProgress = async (): Promise<ApiResponse<any>> => {
  const response = await httpClient.get("/dashboard/progress");
  return response.data.payload.result;
};

export const httpGetUserLimitation = async (): Promise<ApiResponse<any>> => {
  const response = await httpClient.get("/dashboard/user-limit");
  return response.data.payload.result;
};

export const httpGetMyTeam = async (): Promise<ApiResponse<any>> => {
  const response = await httpClient.get("/dashboard/myteam");
  return response.data.payload.results;
};

export const httpGetUserWelcomeEmails = async (): Promise<ApiResponse<any>> => {
  const response = await httpClient.get("/dashboard/user-welcome");
  return response.data.payload.result;
};

export const httpGetGenericUserDetails = async (
  body: any = {}
): Promise<ApiResponse<any>> => {
  const response = await httpClient.post("/dashboard/users-generic", body);
  return response.data.payload.result;
};

export const httpGetGroupsSummary = async (): Promise<ApiResponse<any>> => {
  const response = await httpClient.get("/dashboard/groups-summary");
  return response.data.payload.result;
};
