
import { defineComponent, ref, onMounted, watch, toRef, PropType } from "vue";
import { useStore } from "vuex";
import {
  getMessageContents,
  postMessageContents,
} from "@/api/message-contents.api";
import { Action } from "@/models";
import FormInput from "@/components/form/FormInput.vue";

import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";

export default defineComponent({
  components: {
    FormInput,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.InfoContent);
    const action = toRef(props, "action");

    const messageReference = ref("");
    messageReference.value = action.value.messageReference ?? "";
    const updateActive = toRef(props, "updateActive");

    const currentUser = store.getters.currentUser;

    const users = ref<any[]>([]);
    const selectedUser = toRef(props, "selectedUser");
    const selectedGroup = toRef(props, "selectedGroup");
    const preferredLanguageId = ref<string>("");

    const model = ref<any>({
      link: null,
    });
    const v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      link: "",
    });

    const getLink = async () => {
      try {
        model.value.link = "";
        model.value.signature = props.action.signatureRequired ? "Yes" : "No";
        preferredLanguageId.value = currentUser.preferredLanguageId;

        const response = await getMessageContents(
          [messageReference.value],
          props.action.level === "group" ? selectedGroup.value ?? "" : "",
          props.action.level === "user" ? selectedUser.value ?? "" : ""
        );
        const results = response.data.payload.results;
        if (results && results.length > 0) {
          model.value.link = results.find(
            (res) => res.languageId === preferredLanguageId.value
          )?.data as string;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      isValid.value = !v$.value.$error;
    };

    const handleDataChange = async ({ value }) => {
      model.value.link = value;
      validateField("link");
      emit("modifyModel", { name: "link", value: model.value.link });
    };

    onMounted(async () => {
      await getLink();
      emit("isLoaded", true);
    });

    watch(action, async () => {
      await getLink();
    });

    watch(updateActive, async () => {
      try {
        if (updateActive.value) {
          await postMessageContents({
            languageId: preferredLanguageId.value,
            reference: messageReference.value,
            userId:
              props.selectedUser && props.selectedUser.length > 0
                ? props.selectedUser
                : null,
            groupId:
              props.selectedGroup && props.selectedGroup.length > 0
                ? props.selectedGroup
                : null,
            data: model.value.link,
          });
          emit("updated", true);
        }
      } catch (err) {
        console.log(err);
        emit("updated", false);
      }
    });

    const isValid = ref<boolean>(false);

    return {
      isValid,
      schema,
      model,
      handleDataChange,
    };
  },
});
