import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-180e7473"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col md:col-6" }
const _hoisted_3 = { class: "col md:col-6" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = {
  key: 0,
  class: "col padded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_AddressForm = _resolveComponent("AddressForm")!
  const _component_TelephoneInput = _resolveComponent("TelephoneInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormInput, {
        value: _ctx.contact.name,
        error: _ctx.errors['name'],
        onChange: _ctx.handleUpdate,
        label: "form.full-name",
        required: true,
        name: "name",
        id: "name"
      }, null, 8, ["value", "error", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Select, {
        items: _ctx.relationshipItems,
        value: _ctx.contact.relationship,
        error: _ctx.errors['relationship'],
        onChange: _ctx.handleUpdate,
        label: "form.relationship",
        name: "relationship",
        id: "relationship"
      }, null, 8, ["items", "value", "error", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AddressForm, {
        address: _ctx.address,
        hidePrimary: true,
        onUpdate: _ctx.handleUpdateAddress
      }, null, 8, ["address", "onUpdate"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_TelephoneInput, {
        id: "phone",
        name: "phone",
        label: "form.telephone-number",
        value: _ctx.contactNumber,
        error: _ctx.errors['telNumber'],
        onChange: _ctx.handleContactNumberUpdate
      }, null, 8, ["value", "error", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_FormInput, {
        value: _ctx.contact.notes,
        error: _ctx.errors['notes'],
        onChange: _ctx.handleUpdate,
        label: "form.additional-comments",
        name: "notes",
        id: "notes"
      }, null, 8, ["value", "error", "onChange"])
    ]),
    (_ctx.hidePrimary)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_Checkbox, {
            id: "primary",
            value: _ctx.contact.isPrimary,
            disabled: _ctx.disablePrimaryCheckbox,
            text: "models.emergency-contact.primary",
            onChange: _ctx.setPrimary
          }, null, 8, ["value", "disabled", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}