import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dd6a2c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "has-badge" }
const _hoisted_4 = { class: "list-parent" }
const _hoisted_5 = ["onMouseover", "onMouseleave"]
const _hoisted_6 = ["data-badge"]
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "link__title help-title" }
const _hoisted_9 = {
  key: 0,
  class: "arrow"
}
const _hoisted_10 = {
  key: 0,
  class: "list-child"
}
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "link__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav-side", { open: !!_ctx.open }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["branding", { 'full-logo': _ctx.fullMiniLogo }])
    }, [
      _createVNode(_component_router_link, {
        class: "logo logo--lg",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "icon",
            src: _ctx.logoUrl,
            alt: ""
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "logo logo--sm hide-sm",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "icon",
            src: _ctx.smallLogoUrl,
            alt: ""
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavItems, (parent, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: parent.id,
            onMouseover: _withModifiers(($event: any) => (_ctx.handleHoverParent($event, index)), ["prevent"]),
            onMouseleave: _withModifiers(($event: any) => (_ctx.handleHoverParent($event, index)), ["prevent"]),
            class: _normalizeClass(parent?.class)
          }, [
            _createVNode(_component_router_link, {
              to: parent.url,
              class: _normalizeClass(["link", {
              hover: index === _ctx.hoverParent,
              active: index === _ctx.activeParent,
            }]),
              onClick: _ctx.toggleNav
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  "data-badge": parent.icon.badge ?? null
                }, [
                  (parent.icon.fa)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: parent.icon.fa
                      }, null, 8, ["icon"]))
                    : (parent.icon.src)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "icon",
                          src: require(`../assets/img/${parent.icon.src}`),
                          alt: parent.icon.alt
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true)
                ], 8, _hoisted_6),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_TranslatedText, {
                    path: parent.title
                  }, null, 8, ["path"])
                ]),
                (
                parent.children && _ctx.getNavItemChildren(parent.children).length
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, ">"))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["to", "class", "onClick"]),
            (parent.children && _ctx.getNavItemChildren(parent.children).length)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavItemChildren(parent.children), (child) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: child.id
                    }, [
                      _createVNode(_component_router_link, {
                        to: child.url,
                        class: "link"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            (child.icon.fa)
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 0,
                                  icon: child.icon.fa
                                }, null, 8, ["icon"]))
                              : (child.icon.src)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    class: "icon",
                                    src: require(`../assets/img/${child.icon.src}`),
                                    alt: child.icon.alt
                                  }, null, 8, _hoisted_11))
                                : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("span", _hoisted_12, [
                            _createVNode(_component_TranslatedText, {
                              path: child.title
                            }, null, 8, ["path"])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 42, _hoisted_5))
        }), 128))
      ])
    ])
  ], 2))
}