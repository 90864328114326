<template>
  <div ref="root" tabindex="0">
    <div v-if="open" class="breaker" @mousedown="$emit('close')" />

    <div
      class="widget-menu"
      :class="{ open }"
      @mousedown="$event.stopPropagation()"
    >
      <div class="point" />
      <ul>
        <li v-for="(item, idx) in items" :key="idx">
          <button
            :disabled="item.enabled !== undefined && !item.enabled"
            @click="runItem(item)"
          >
            {{ item.caption }}
          </button>
        </li>
        <li :class="{ hr: items.length > 0 }">
          <button @click="emitAndClose('clone')">Clone</button>
        </li>
        <li><button @click="emitAndClose('delete')">Delete</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "WidgetMenu",
  props: {
    open: Boolean,
    items: {
      default: [],
    },
  },
  setup(_, { emit }) {
    const root = ref(null);
    const emitAndClose = (eventName, eventData) => {
      emit("close");
      emit(eventName, eventData);
    };

    const focus = () => root.value.focus();

    const runItem = (item) => {
      emit("close");
      item.action();
    };

    return { root, emitAndClose, focus, runItem };
  },
});
</script>

<style scoped lang="scss">
.widget-menu {
  font-size: 0.9rem;
  &.open {
    opacity: 1;
    pointer-events: auto;
    top: 2.7rem;
    opacity: 1;
  }
  opacity: 0;
  top: 4rem;
  transition: 200ms top, 200ms opacity;
  padding: 0;
  .point {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 1.9rem;
    width: 1.1em;
    height: 1.1em;
    border: 1px #ccc solid;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 0 1px;
    transform: rotate(45deg) translateY(-75%);
    box-shadow: -0.1em -0.1em 0.1em #0003;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      transform: rotate(-45deg) translateY(-80%) scale(1.6);
      background: #fff;
    }
  }
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  right: -1em;
  white-space: nowrap;
  list-style: none;
  border-radius: 0.03em;
  border: 1px #ccc solid;
  background: #fff;
  box-shadow: 0.1em 0.1em 0.4em #3334, 0 0 50vmax #000;
  min-width: 7em;
  button {
    width: 100%;
    font-size: 0.9em;
    font-family: inherit;
    padding: 0.75em 1.5em;
    color: #444;
    white-space: pre;
    cursor: pointer;
    border: none;
    background: #fff0;
    text-align: left;
    &:hover {
      background: #f0f0f0;
      box-shadow: inset 0.5em 0 #ed6a5e;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

li.hr {
  border-top: 1px #eee solid;
}
.breaker {
  position: fixed;
  inset: -100vmax;
  background: #0000;
  z-index: 1;
}
</style>
