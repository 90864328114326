
import { defineComponent, ref, onMounted } from "vue";
import UserView from "@/components/user/UserView.vue";
import { User } from "@/models";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { useRouter } from "vue-router";
import { usePermissionProvider } from "@/plugins/permission";

export default defineComponent({
  components: {
    AuthenticatedLayout,
    UserView,
  },
  setup() {
    const user = ref<User>(new User());
    const router = useRouter();
    const { userHasPermission } = usePermissionProvider();

    const navItemConfig = [
      {
        url: "details",
        title: "models.user.tabs.details",
        component: "UserDetails",
        permission: "user:details",
      },
    ];

    onMounted(async () => {
      if (!userHasPermission("user:create")) {
        router.push(`/users`);
      }
    });

    return {
      user,
      navItemConfig,
    };
  },
});
