
import { defineComponent, PropType } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { Schemas, useSchema } from "@/plugins/schema";
import { ContactNumber } from "@/models";
import Card from "@/components/Card.vue";
import DynamicList from "@/components/DynamicList.vue";
import ContactNumberForm from "@/components/user/contact/ContactNumberForm.vue";

export default defineComponent({
  components: {
    Card,
    DynamicList,
    ContactNumberForm,
  },
  props: {
    contactNumbers: {
      type: Array as PropType<Array<ContactNumber>>,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "delete"],
  setup(props, { emit }) {
    const schema: ValidationArgs = useSchema(Schemas.PhoneNumber);
    const getTitle = (isPrimary: boolean) =>
      isPrimary ? "models.contact-number.primary" : "";

    const handleSave = (contact: ContactNumber) =>
      emit("save", new ContactNumber(contact));
    const handleDelete = (contact: ContactNumber) =>
      emit("delete", new ContactNumber(contact));

    const createNewUserContactNumber = () => new ContactNumber();

    return {
      schema,
      handleSave,
      handleDelete,
      getTitle,
      createNewUserContactNumber,
    };
  },
});
