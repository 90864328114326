
import { defineComponent, PropType, computed, watch } from "vue";
import { helpers, required } from "@vuelidate/validators";
import { Manager, UserDetails } from "@/models";
import { Group, Role } from "@/interfaces/domain";
import DatePicker from "@/components/form/DatePicker.vue";
import FormInput from "@/components/form/FormInput.vue";
import MultiSelect from "@/components/form/MultiSelect.vue";
import Form from "@/components/Form.vue";
import { useLanguage } from "@/composables";
import { Schemas, useSchema } from "@/plugins/schema";

export default defineComponent({
  components: {
    FormInput,
    DatePicker,
    MultiSelect,
    Form,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserDetails>,
      required: true,
    },
    managers: {
      type: Array as PropType<Array<Manager>>,
      required: true,
    },
    groups: {
      type: Array as PropType<Array<Group>>,
      required: true,
    },
    roles: {
      type: Array as PropType<Array<Role>>,
      required: true,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
    },
    isNewUser: Boolean,
    hiddenUserFields: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    requiredUserFields: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    additionalFields: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    config: Object,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "update:modelValue",
    "update:errors",
    "update:isValid",
    "updateAdditionalField",
  ],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    const errorModel = computed({
      get: () => props.errors,
      set: (value) => emit("update:errors", value),
    });
    const valid = computed({
      get: () => props.isValid,
      set: (value) => emit("update:isValid", value),
    });

    const { translateText } = useLanguage();

    const schema = useSchema(Schemas.CompanyDetails) as any;

    const groupSelectOptions = computed(() =>
      props.groups
        .map((group) => ({ ...group, name: group.textItems[0].data }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
    const selectedGroups = computed(() =>
      props.modelValue.groups.map((group) => ({
        ...group,
        name: group.textItems[0].data,
      }))
    );

    const managerSelectOptions = computed(() =>
      props.managers.map((manager) => ({
        ...manager,
        name: `${manager.firstName} ${manager.surname}`,
      }))
    );
    const selectedManagers = computed(() =>
      props.modelValue.managers.map((manager) => ({
        ...manager,
        name: `${manager.firstName} ${manager.surname}`,
      }))
    );
    const getRoleName = (role) => {
      const roleName = `${role.name.charAt(0).toUpperCase()}${role.name.slice(
        1
      )}`;
      const translatedName = translateText(`role.name.${role.name}`);
      return translatedName != `role.name.${role.name}`
        ? translatedName
        : roleName;
    };

    const roleSelectOptions = computed(() =>
      props.roles.map((role) => ({
        ...role,
        name: getRoleName(role),
      }))
    );
    const selectedRoles = computed(() =>
      props.modelValue.roles.map((role) => ({
        ...role,
        name: getRoleName(role),
      }))
    );

    const customClass = (fieldName) => {
      if (props.requiredUserFields.includes(fieldName)) {
        return "required";
      }
      return "";
    };

    const handleAdditionalFieldChange = ({ name, value }) => {
      emit("updateAdditionalField", { type: "company", name, value });
    };

    watch(props, async (newProps, oldProps) => {
      if (newProps.requiredUserFields) {
        for (let k in newProps.requiredUserFields) {
          const f = newProps.requiredUserFields[k];
          if (!(f in model.value)) {
            continue;
          }
          if (schema[f]) {
            schema[f].required = helpers.withMessage(
              "validation.generic.missing",
              required
            );
          } else {
            schema[f] = {
              required: helpers.withMessage(
                "validation.generic.missing",
                required
              ),
            };
          }
        }
      }
    });

    return {
      model,
      errorModel,
      valid,
      schema,
      managerSelectOptions,
      selectedManagers,
      groupSelectOptions,
      selectedGroups,
      roleSelectOptions,
      selectedRoles,
      customClass,
      handleAdditionalFieldChange,
    };
  },
});
