import httpClient, { ApiResponse } from "@/api/httpClient";
import { Address } from "./Address";
import { Model } from "./Model";
export class Reference extends Model<Reference> {
  private static readonly path: string = "/references";

  public userId: string;
  public name: string;
  public telephoneNumber?: string;
  public order: string;
  public email: string;
  public contactInfo: string;
  public dateFrom: string;
  public dateTo: string;
  public dayFrom: string;
  public dayTo: string;
  public monthFrom: string;
  public monthTo: string;
  public yearFrom: string;
  public yearTo: string;
  public employerReference: EmployerReference;
  public characterReference: CharacterReference;
  public referenceRecord: ReferenceRecord;
  public presentDay: boolean;

  constructor({
    id = "",
    userId = "",
    name = "",
    telephoneNumber = "",
    order = "",
    email = "",
    contactInfo = "",
    dateFrom = "",
    dateTo = "",
    dayFrom = "",
    dayTo = "",
    monthFrom = "",
    monthTo = "",
    yearFrom = "",
    yearTo = "",
    presentDay = false,
    employerReference = new EmployerReference(),
    characterReference = new CharacterReference(),
    referenceRecord = new ReferenceRecord(),
  } = {}) {
    super(id, Reference, Reference.path);
    this.userId = userId;
    this.name = name;
    this.telephoneNumber = telephoneNumber;
    this.order = order;
    this.email = email;
    this.contactInfo = contactInfo;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.dayFrom = dayFrom;
    this.dayTo = dayTo;
    this.monthFrom = monthFrom;
    this.monthTo = monthTo;
    this.yearFrom = yearFrom;
    this.yearTo = yearTo;
    this.presentDay = presentDay;
    this.employerReference = employerReference;
    this.characterReference = characterReference;
    this.referenceRecord = referenceRecord;
  }

  public static async get(userId = ""): Promise<Array<Reference>> {
    if (userId) {
      return await Model.getAllForUser<Reference>(
        userId,
        Reference.path,
        Reference
      );
    } else {
      const response: ApiResponse<Reference> = await httpClient.get(
        `${this.path}/me`
      );

      const { results } = response.data.payload;
      return (results || []).map((r) => new Reference(r));
    }
  }

  public async save(action = "", userId = ""): Promise<Reference> {
    const method = this.id ? "PUT" : "POST";
    const { referenceRecord, ...ref } = this;
    const response: ApiResponse<Reference> = await httpClient.request({
      method,
      url:
        action === "save"
          ? this.id
            ? userId //PUT (update only)
              ? `${this.path}/${this.id}/user/${userId}`
              : `${this.path}/${this.id}`
            : userId // POST
            ? `${this.path}/user/${userId}`
            : `${this.path}`
          : `${this.path}/${action}/${this.id}/record/${this.referenceRecord.id}`, // PUT (with reference status change)
      data: ref,
    });

    const { result } = response.data.payload;
    if (result) {
      return new this.type(response.data.payload.result);
    }

    throw new Error();
  }

  public async delete(userId = ""): Promise<void> {
    const url = userId.length
      ? `${this.path}/${this.id}/user/${userId}`
      : `${this.path}/${this.id}`;
    await httpClient.delete(url);
  }
}
export class ReferenceRecord {
  public id: string;
  public userCompleted: boolean;
  public completed: boolean;
  public sent: boolean;
  public approved: boolean;
  public viewed: boolean;
  public dayFrom: number | null;
  public monthFrom: number | null;
  public yearFrom: number | null;
  public dayTo: number | null;
  public monthTo: number | null;
  public yearTo: number | null;
  public characterReferenceRecord: { confirmed: boolean } | null;
  public employerReferenceRecord: { userPosition: string } | null;

  constructor({
    id = "",
    userCompleted = false,
    completed = false,
    sent = false,
    viewed = false,
    approved = false,
    characterReferenceRecord = null,
    employerReferenceRecord = null,
    dayFrom = null,
    monthFrom = null,
    yearFrom = null,
    dayTo = null,
    monthTo = null,
    yearTo = null,
  } = {}) {
    this.id = id;
    this.userCompleted = userCompleted;
    this.completed = completed;
    this.sent = sent;
    this.viewed = viewed;
    this.approved = approved;
    this.characterReferenceRecord = characterReferenceRecord;
    this.employerReferenceRecord = employerReferenceRecord;
    this.dayFrom = dayFrom;
    this.monthFrom = monthFrom;
    this.yearFrom = yearFrom;
    this.dayTo = dayTo;
    this.monthTo = monthTo;
    this.yearTo = yearTo;
  }
}

export class EmployerReference {
  public id: string;
  public address: Address;
  public addressId: string;
  public company: string;
  public userPosition: string;
  public referencePosition: string;
  public referenceId: string;

  constructor({
    id = "",
    address = new Address(),
    addressId = "",
    company = "",
    userPosition = "",
    referencePosition = "",
    referenceId = "",
  } = {}) {
    this.id = id;
    this.address = address;
    this.addressId = addressId;
    this.company = company;
    this.userPosition = userPosition;
    this.referencePosition = referencePosition;
    this.referenceId = referenceId;
  }
}

export class CharacterReference {
  public id: string;
  public howKnown: string;
  public referenceId: string;

  constructor({ id = "", howKnown = "", referenceId = "" } = {}) {
    this.id = id;
    this.howKnown = howKnown;
    this.referenceId = referenceId;
  }
}
