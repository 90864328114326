
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonBar",
  setup(_, context) {
    const toggleSort = () => context.emit('toggle-sort')
    const toggleFilter = () => context.emit("toggle-filter");
    const openSearch = () => context.emit("open-search");
    return {
      toggleSort,
      toggleFilter,
      openSearch,
    }
  },
  props: ["buttons"], // Loop buttons passed
});
