
import { defineComponent, ref, computed, shallowRef } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { User } from "@/models";
import Avatar from "@/components/Avatar.vue";
import Billing from "@/components/account/Billing.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

export default defineComponent({
  components: { AuthenticatedLayout, Avatar },
  setup() {
    const router = useRouter();
    const store = useStore();

    const user = ref<User>(store.getters.currentUser);
    const navItems = shallowRef([
      {
        url: "billing",
        title: "models.account.billing",
        component: Billing,
        action: () => changeTab("billing"),
      },
    ]);

    const username = computed<string>(
      () => `${user.value.firstName} ${user.value.surname}`
    );
    const avatarFallbackText = computed(() => {
      const firstName = user.value.firstName[0] ?? "";
      const surname = user.value.surname[0] ?? "";
      return `${firstName}${surname}`;
    });

    const changeTab = (url: string) => router.push({ path: `/account/${url}` });

    const currentNavItem = computed(() => {
      const currentRoute = router.currentRoute.value;
      const routeVal = (
        currentRoute.params && currentRoute.params.section
          ? currentRoute.params.section
          : currentRoute.query["tab"]
      ) as string;
      return (
        navItems.value.find((item) => item.url === routeVal) ||
        navItems.value[0]
      );
    });

    return { user, navItems, username, avatarFallbackText, currentNavItem };
  },
});
