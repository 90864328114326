
import { defineComponent, ref } from "vue";
import Overlay from "@/components/Overlay.vue";
import Button from "@/components/Button.vue";

import { useLanguage } from "../composables";

export default defineComponent({
  components: {
    Button,
    Overlay,
  },
  emits: ["close-language-selector", "save-language-preference"],
  setup() {
    const { setLanguage, currentLanguage, getLanguages } = useLanguage();
    const languages = getLanguages();
    const languageSelectorActive = ref<boolean>(false);
    const activeLanguage = ref(
      getLanguages().find((lang) => lang.key === currentLanguage.value)
    );

    const langChanged = ref<boolean>(false);

    const selectLanguage = (lang: string) => {
      setLanguage(lang);
      if (window.innerWidth >= 768) {
        save();
      }
    };

    const cancel = () => {
      setLanguage(activeLanguage.value.key);
      languageSelectorActive.value = false;
    };

    const save = () => {
      activeLanguage.value = getLanguages().find(
        (lang) => lang.key === currentLanguage.value
      );
      languageSelectorActive.value = false;
      langChanged.value = false;
    };

    const toggleLanguageSelector = () =>
      (languageSelectorActive.value = !languageSelectorActive.value);

    return {
      activeLanguage,
      cancel,
      currentLanguage,
      languageSelectorActive,
      languages,
      save,
      setLanguage,
      selectLanguage,
      toggleLanguageSelector,
      showTranslations: false,
    };
  },
});
