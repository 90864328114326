import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setEditMode = "setEditMode",
}

export interface TrackState {
  editMode: boolean;
}

const state: TrackState = {
  editMode: false,
};

const getters: GetterTree<TrackState, null> = {
  track: (state: TrackState) => ({ ...state }),
};

const actions: ActionTree<TrackState, null> = {
  setEditMode({ commit }, edit: boolean) {
    commit(MutationKeys.setEditMode, edit);
  },
};

const mutations: MutationTree<TrackState> = {
  [MutationKeys.setEditMode]: (state: TrackState, edit: boolean) => {
    state.editMode = edit;
  },
};

const track: Module<TrackState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { track };
