
import { defineComponent, onMounted, onUnmounted, computed } from "vue";

export default defineComponent({
  props: {
    clickToClose: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    onMounted(() => document.body.classList.add("no-scroll"));
    onUnmounted(() => document.body.classList.remove("no-scroll"));

    const getBackgroundColour = computed(() => {
      return {
        "background-color":
          getComputedStyle(document.documentElement).getPropertyValue(
            "--base-15"
          ) + "E6", // E6 is 90% transparent
      };
    });

    const close = () => {
      if (props.clickToClose) {
        emit("close");
      }
    };

    return {
      close,
      getBackgroundColour,
    };
  },
});
