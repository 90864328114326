import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38647a25"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "table-row"
}
const _hoisted_2 = {
  class: "no-records",
  colspan: "100%"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("colgroup", null, [
      _renderSlot(_ctx.$slots, "colgroup", {}, undefined, true)
    ]),
    _createElementVNode("thead", null, [
      _renderSlot(_ctx.$slots, "head", {}, undefined, true)
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
        return _renderSlot(_ctx.$slots, "default", {
          key: _ctx.keyProp ? row[_ctx.keyProp] : index,
          row: row,
          index: index
        }, undefined, true)
      }), 128)),
      (_ctx.noRows)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
            _createElementVNode("td", _hoisted_2, [
              _createVNode(_component_TranslatedText, { path: "common.table.no-records" })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}