
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useBreakpoints } from "@/composables";

import { Report } from "@/models/Report";

import {
  Group,
  NavItem,
  ReportData,
  ReportDataSection,
  ReportDataFilterValue,
} from "@/interfaces";
import { PaginationDetails } from "@/interfaces/Pagination";

import BackLink from "@/components/BackLink.vue";
import Button from "@/components/Button.vue";
import { FilterGroup } from "@/components/MenuBar.vue";
import TitleSelect from "@/components/form/TitleSelect.vue";
import UserProgress from "@/components/reporting/reports/sections/UserProgress.vue";
import { UserProgressReportRow } from "@/components/reporting/reports/UserProgressRow.vue";

export default defineComponent({
  name: "UserProgressReport",
  components: {
    BackLink,
    Button,
    TitleSelect,
    UserProgress,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(true);
    const { currentBreakpoint } = useBreakpoints();
    const isSmallScreen = computed(
      () => currentBreakpoint.value === "sm" || currentBreakpoint.value === "md"
    );

    const statusFilter = ref();

    const reports = computed(() => store.getters.reports);
    const currentReport = computed(
      () => router.currentRoute.value.params.id as string
    );
    const reportName = computed(
      () => reports.value.find((r) => r.id === currentReport.value)?.name
    );
    const reportDescription = computed(
      () => reports.value.find((r) => r.id === currentReport.value)?.description
    );
    const reportData = ref<ReportData<UserProgressReportRow>>();
    const reportDataUnfiltered = ref<ReportData<UserProgressReportRow>>();

    const currentHotspot = ref<ReportDataSection>();
    const hotspots = computed<Array<ReportDataSection>>(() => {
      if (!reportData.value) {
        return [];
      }

      return reportData.value.sections;
    });

    const currentTrackId = ref<string>();
    const tracks = computed<Array<ReportDataFilterValue>>(() => {
      if (!reportData.value) {
        return [];
      }

      const trackFilter = reportData.value.filters.find(
        (filter) => filter.name === "Track"
      );

      if (trackFilter) {
        const allTracks = { id: "", name: "All Workflows" };
        return [
          allTracks,
          ...(trackFilter.values as Array<ReportDataFilterValue>),
        ];
      }

      return [];
    });

    const handleTitleNavChange = async (e: any) => {
      if (isSmallScreen.value) {
        currentTrackId.value = "";
        currentHotspot.value = hotspots.value.find((h) => h.id === e.value);
      } else {
        currentHotspot.value = undefined;
        currentTrackId.value = e.value;
      }
      await getReportData();
    };

    const availableTracks = ref<{ value: string; text: string }[]>([]);

    const titleSelectValue = computed(() =>
      isSmallScreen.value ? currentHotspot.value?.id : currentTrackId.value
    );
    const titleSelectItems = computed(() => {
      if (isSmallScreen.value) {
        return hotspots.value.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      }

      return availableTracks.value;
    });

    const tabNavItems = computed<Array<NavItem>>(() => {
      if (isSmallScreen.value) {
        return availableTracks.value.map(
          (item) =>
            ({
              id: item.value,
              title: item.text,
              url: "",
              active: currentTrackId.value === item.value,
              action: () => {
                currentTrackId.value = item.value;
                getReportData();
              },
            } as NavItem)
        );
      }

      return hotspots.value.map(
        (item) =>
          ({
            ...item,
            id: item.id,
            title: item.name,
            url: "",
            active: currentHotspot.value?.id === item.id,
            action: () => {
              currentHotspot.value = item;
              getReportData();
            },
          } as NavItem)
      );
    });

    const getTracks = async () => {
      const groups = store.getters.currentUser.groups;
      //Do we have groups?
      if (groups.length) {
        availableTracks.value = groups.map((group: Group) => {
          return {
            value: group.id,
            text:
              group.textItems.length > 0
                ? group.textItems[0].data
                : group.shortName,
          };
        });

        handleTitleNavChange({ value: availableTracks.value[0].value });
      } else {
        // No groups, default to universal
        // Todo: change to not have track side menu
        availableTracks.value.unshift({
          value: "universal",
          text: "Universal",
        });
      }

      // If the current track id isn't present in the available tracks, deafult it to the first track
      if (!availableTracks.value.find((t) => t.value == currentTrackId.value)) {
        if (availableTracks.value.length) {
          currentTrackId.value = availableTracks.value[0].value;
        } else {
          currentTrackId.value = "universal";
        }
      }
    };

    const selectedAll = ref(false);
    const selectedIds = ref(new Set<string>());
    const excludedIds = ref(new Set<string>());
    const handleSelectAllChange = ({ value }) => {
      selectedAll.value = value;
      excludedIds.value.clear();
    };
    const handleItemSelectChange = ({
      value,
      selected,
    }: {
      value: string;
      selected: boolean;
    }) => {
      if (selected) {
        selectedIds.value.add(value);
        excludedIds.value.delete(value);
      } else {
        if (selectedAll.value) {
          if (!selected) {
            excludedIds.value.add(value);
          }
        }
      }
    };

    const data = computed(() => {
      if (!reportData.value) {
        return {};
      }

      return reportData.value.data.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {});
    });
    const getReportData = async (pagination?: PaginationDetails) => {
      isLoading.value = true;
      if (!pagination) {
        const pageSize = store.getters.pageSize;
        pagination = new PaginationDetails({ pageSize });
      }
      if (reportData.value) {
        reportData.value.data = [];
      }
      selectedIds.value.clear();
      excludedIds.value.clear();
      selectedAll.value = false;

      const trackId =
        currentTrackId.value !== "all"
          ? currentTrackId.value
          : currentHotspot.value
          ? (currentHotspot.value as any).track
          : undefined;

      reportData.value = trackId
        ? await Report.getData<ReportData<UserProgressReportRow>>(
            currentReport.value,
            {
              ...pagination,
              hotspotId: currentHotspot.value?.id,
              trackId: trackId,
              sortCol: currentSortColumn.value,
              sortOrder: currentSortOrder.value,
              search: searchText.value,
              status: statusFilter.value,
              filter: statusFilter.value,
            }
          )
        : reportData.value;

      reportDataUnfiltered.value = {
        ...reportData.value,
      } as ReportData<UserProgressReportRow>;
      if (!currentHotspot.value && hotspots.value.length) {
        currentHotspot.value = hotspots.value[0];
      }
      if (!currentTrackId.value && tracks.value.length) {
        currentTrackId.value = tracks.value[0].id;
      }
      isLoading.value = false;
      if (reportData.value?.data) {
        reportData.value.data = reportData.value.data.filter((d) => {
          return d;
        });
      }
    };

    const paginationDetails = computed(() => reportData.value?.page);
    const handleChangePage = async (pageNumber: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber,
      });
      await getReportData(pagination);
    };
    const handleChangeRowCount = async (pageSize: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
        pageSize,
      });
      await getReportData(pagination);
    };

    const sortItems = computed(() => [
      {
        label: "form.first-name",
        value: "firstName",
      },
      {
        label: "form.surname",
        value: "surname",
      },
      {
        label: "progress.overall",
        value: "progress",
      },
    ]);
    const currentSortColumn = ref("firstName");
    const currentSortOrder = ref("asc");
    const handleSort = async (value: string) => {
      if (currentSortColumn.value === value) {
        currentSortOrder.value =
          currentSortOrder.value === "asc" ? "desc" : "asc";
      } else {
        currentSortColumn.value = value;
        currentSortOrder.value = "desc";
      }

      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
      });
      await getReportData(pagination);
    };

    const searchText = ref();
    const handleSearchChange = async (value: string) => {
      searchText.value = value;
      await getReportData();
    };

    const statusTypes = [
      {
        value: "*",
        text: "common.all",
      },
      {
        value: "late",
        text: "common.late",
      },
      {
        value: "overdue",
        text: "progress.overdue",
      },
      {
        value: "complete",
        text: "progress.complete",
      },
      {
        value: "inprogress",
        text: "progress.inprogress",
      },
    ];
    const filterOptions = computed<FilterGroup>(() => ({
      type: {
        label: "common.status",
        options: [
          {
            name: "status",
            label: "common.status",
            component: "Select",
            value: statusFilter.value,
            props: {
              items: statusTypes,
            },
          },
        ],
      },
    }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFilterChange = (e: any) => {
      const { value } = e;
      statusFilter.value = value ?? undefined;
    };
    const handleFilterReset = async () => {
      statusFilter.value = undefined;
      searchText.value = undefined;
      await handleFilterApply();
    };
    const handleFilterApply = async () => {
      await getReportData();
    };

    const totalCandidatesCount = computed(
      () =>
        totalCompleteCount.value + inProgressCount.value + overdueCount.value
    );
    const totalCompleteCount = computed(
      () => completeCount.value + lateCount.value
    );
    const inProgressCount = computed(
      () => reportData.value?.stats?.inprogress ?? 0
    );
    const overdueCount = computed(() => reportData.value?.stats?.overdue ?? 0);
    const completeCount = computed(
      () => reportData.value?.stats?.complete ?? 0
    );
    const lateCount = computed(() => reportData.value?.stats?.late ?? 0);

    const handleDownloadClick = () => {
      console.log("Todo... Download");
    };

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
      getReportData();
      getTracks();
    });

    watch(statusFilter, () => {
      if (reportData.value?.data) {
        reportData.value.data = reportDataUnfiltered?.value
          ? reportDataUnfiltered.value.data.filter((d) => {
              if (!statusFilter.value) {
                return d;
              } else if (statusFilter.value === "inprogress") {
                return d.status === statusFilter.value || d.status === "";
              } else if (statusFilter.value != "*") {
                return d.status === statusFilter.value;
              }
            })
          : [];
      }
    });

    return {
      currentHotspot,
      handleTitleNavChange,
      tabNavItems,
      titleSelectItems,
      titleSelectValue,
      data,
      paginationDetails,
      handleChangePage,
      handleChangeRowCount,
      sortItems,
      currentSortColumn,
      currentSortOrder,
      handleSort,
      searchText,
      handleSearchChange,
      filterOptions,
      handleFilterChange,
      handleFilterReset,
      handleFilterApply,
      selectedAll,
      handleSelectAllChange,
      handleItemSelectChange,
      selectedIds,
      excludedIds,
      totalCandidatesCount,
      totalCompleteCount,
      inProgressCount,
      overdueCount,
      isMounted,
      isSmallScreen,
      availableTracks,
      currentTrackId,
      reportData,
      reportName,
      reportDescription,
      handleDownloadClick,
      getReportData,
    };
  },
});
