import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b5b8afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({ tooltip: _ctx.tooltip }),
    "data-text": _ctx.tooltip
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}