import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88dd9ed6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "mock-input-container" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["name", "id", "disabled"]
const _hoisted_6 = {
  key: 0,
  class: "uploaded-files text-h5 ml-1"
}
const _hoisted_7 = {
  key: 1,
  class: "text-sm pt-1"
}
const _hoisted_8 = {
  key: 2,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options?.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.options?.id,
          class: _normalizeClass({
        active: _ctx.options?.active,
        'has-icon': !!_ctx.$slots.prepend,
      })
        }, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.label
          }, null, 8, ["path"]),
          (_ctx.options?.required)
            ? (_openBlock(), _createBlock(_component_TranslatedText, {
                key: 0,
                path: "form.required.asterix",
                class: "required-asterix"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", {
        class: "upload-input-label btn",
        for: _ctx.options?.id,
        role: "button"
      }, [
        _createVNode(_component_TranslatedText, { path: "form.upload" })
      ], 8, _hoisted_4),
      _createElementVNode("input", {
        class: "upload-input",
        name: _ctx.options?.id,
        id: _ctx.options?.id,
        disabled: _ctx.options?.disabled,
        type: "file",
        accept: "image/png,image/jpeg,application/pdf,application/msword,image/gif,image/svg+xml,.docx,.doc",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChange($event)))
      }, null, 40, _hoisted_5),
      (_ctx.options?.source)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.options?.source.name), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.options?.fileSizeLimit)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.translateText("form.file-sizes", [
        {
          placeholder: "value",
          value: _ctx.options?.fileSizeLimit,
        },
      ])), 1))
      : _createCommentVNode("", true),
    (_ctx.options?.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.error
          }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ]))
}