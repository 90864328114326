import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-664f7890"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "styled-content user-warning"
}
const _hoisted_2 = { class: "page-description styled-content" }
const _hoisted_3 = { class: "grid progress-grid styled-content" }
const _hoisted_4 = { class: "col sm:col-auto" }
const _hoisted_5 = { class: "col col-progress" }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.userIsLoading })
  }, [
    (_ctx.passwordChangeForced)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_TranslatedText, { path: "form.password.change-forced" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TranslatedText, { path: "form.password.change-description" })
    ]),
    _createVNode(_component_Form, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": [
        _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event)),
        _ctx.formUpdated
      ],
      valid: _ctx.isValid,
      "onUpdate:valid": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isValid) = $event)),
      errors: _ctx.errors,
      "onUpdate:errors": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.errors) = $event)),
      schema: _ctx.rules
    }, {
      default: _withCtx(({ handleInputChange }) => [
        _createVNode(_component_FormInput, {
          id: "currentPassword",
          name: "currentPassword",
          label: "form.password.current",
          type: _ctx.passwordType('currentPassword'),
          value: _ctx.model.currentPassword,
          error: _ctx.errors['currentPassword'],
          onChange: handleInputChange,
          onEnter: _ctx.handleSubmit,
          onClickAppendIcon: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePasswordVisibility('currentPassword'))),
          required: ""
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "lock" })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.passwordIcon('currentPassword')
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1032, ["type", "value", "error", "onChange", "onEnter"]),
        _createVNode(_component_FormInput, {
          id: "newPassword",
          name: "newPassword",
          label: "form.password.new",
          type: _ctx.passwordType('newPassword'),
          value: _ctx.model.newPassword,
          error: _ctx.errors['newPassword'],
          onChange: handleInputChange,
          onEnter: _ctx.handleSubmit,
          onClickAppendIcon: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePasswordVisibility('newPassword'))),
          required: ""
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "lock" })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.passwordIcon('newPassword')
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1032, ["type", "value", "error", "onChange", "onEnter"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("strong", null, [
              _createVNode(_component_TranslatedText, { path: "form.password.strength" })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ProgressBar, {
              class: _normalizeClass(_ctx.passwordStrengthClass),
              size: "xl",
              value: _ctx.passwordStrength,
              displayPercentage: false
            }, null, 8, ["class", "value"])
          ])
        ]),
        _createVNode(_component_FormInput, {
          id: "confirmNewPassword",
          name: "confirmNewPassword",
          label: "form.password.confirm",
          type: _ctx.passwordType('confirmNewPassword'),
          value: _ctx.model.confirmNewPassword,
          error: _ctx.errors['confirmNewPassword'],
          onChange: handleInputChange,
          onEnter: _ctx.handleSubmit,
          onClickAppendIcon: _cache[2] || (_cache[2] = ($event: any) => (_ctx.togglePasswordVisibility('confirmNewPassword'))),
          required: ""
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "lock" })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.passwordIcon('confirmNewPassword')
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1032, ["type", "value", "error", "onChange", "onEnter"])
      ]),
      _: 1
    }, 8, ["modelValue", "valid", "errors", "onUpdate:modelValue", "schema"]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Button, {
          text: "common.change-password",
          size: "sm",
          disabled: !_ctx.isValid,
          onClick: _ctx.handleSubmit
        }, null, 8, ["disabled", "onClick"])
      ])
    ])
  ], 2))
}