
import { computed, defineComponent, PropType } from "vue";
import { Hotspot } from "@/models";
import FormInput from "@/components/form/FormInput.vue";
import Select from "@/components/form/Select.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import Form from "@/components/Form.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { FormInput, Select, Form, Checkbox },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<{
        title: string;
        type: string;
        level: string;
        signatureRequired?: boolean;
      }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const levelSelectOptions = computed(() =>
      store.getters.allAudienceOptions.filter(
        (ao) =>
          !ao?.config ||
          !ao.config?.exclude ||
          !ao.config.exclude.includes(model.value.type)
      )
    );
    const typeSelectOptions = computed(() =>
      store.getters.allActionTypes.filter(
        (at) => !at?.config || at.config.create !== false
      )
    );

    const activeType = computed(() =>
      typeSelectOptions.value.find((so) => so.value === model.value.type)
    );

    const getLevelSelectOptions = computed(() => {
      if (activeType.value?.config?.audience) {
        const audienceOptions = levelSelectOptions.value.filter((so) =>
          activeType.value.config.audience.includes(so.value)
        );

        return audienceOptions;
      } else {
        return levelSelectOptions.value;
      }
    });

    const showSignature = computed(() => {
      return (
        !activeType.value?.config ||
        activeType.value.config?.signature !== false
      );
    });

    const handleInputChange = ({ name, value }) => {
      model.value[name] = value;
      if (name === "type" && getLevelSelectOptions.value.length === 1) {
        model.value.level = getLevelSelectOptions.value[0].value;
      }
    };

    return {
      model,
      typeSelectOptions,
      levelSelectOptions,
      getLevelSelectOptions,
      handleInputChange,
      showSignature,
    };
  },
});
