import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "layout-container full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Notification),
    _createVNode(_component_Loading, { show: _ctx.showGlobalLoader }, null, 8, ["show"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.globalModal.modalActive,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.globalModal.modalActive) = $event)),
      title: _ctx.globalModal.title
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.globalModal.body
          }, null, 8, ["path"])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          text: "Cancel",
          onClick: _ctx.globalModal.onCancel,
          outline: ""
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          text: "Confirm",
          onClick: _ctx.globalModal.onConfirm
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}