
import { defineComponent, PropType, computed } from "vue";
import Form from "@/components/Form.vue";
import FileInput from "@/components/form/FileInput.vue";

export default defineComponent({
  components: {
    Form,
    FileInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleAddFile = (file) => {
      model.value.file = file;
    };

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return {
      model,
      handleAddFile,
    };
  },
});
