
import { defineComponent, PropType } from "vue";
import { TextItem } from "@/interfaces";
import Boolean from "@/components/Boolean.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import Chip from "@/components/Chip.vue";

export default defineComponent({
  components: {
    Boolean,
    Button,
    Checkbox,
    Chip,
  },
  props: {
    preparedDocs: {
      type: Object as PropType<any>,
      required: true,
    },
    documents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    documentContents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    selectedDocuments: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deselectedDocuments: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    actionsChip: {
      type: Object as PropType<any>,
    },
    selectAll: Boolean,
  },
  setup(props, { emit }) {
    const handleToggleAll = () => emit("toggle-all-rows");
    const getDocumentContentId = (reference: string) =>
      props.documentContents.find(
        (document) => document.reference === reference
      )?.id;
    const handleToggleCheckbox = (reference: string) => {
      const id = getDocumentContentId(reference);
      emit("toggle-checkbox", id);
    };
    const toggleActionsChip = (id: number) => emit("toggle-actions-chip", id);
    const clickActionItem = (menuItem: { title: string; id: number }) => {
      if (menuItem.title === "Download") emit("bulk-download");
      else if (menuItem.title === "Delete") emit("bulk-download");
    };

    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;
    const getSignOffStatus = (reference: string) => {
      const documentContents = props.documentContents.find(
        (doc) => doc.reference === reference
      );
      if (documentContents) return documentContents.signOffEnabled;
      return false;
    };
    const viewDocument = (
      reference: string,
      textItems: TextItem[],
      id: string
    ) => {
      const name = findTextItem("title", textItems);
      const description = findTextItem("description", textItems);
      const sideMenuText = findTextItem("side-menu", textItems);
      emit("view-document", { reference, name, description, sideMenuText, id });
    };

    return {
      handleToggleAll,
      handleToggleCheckbox,
      toggleActionsChip,
      clickActionItem,
      findTextItem,
      viewDocument,
      getSignOffStatus,
      getDocumentContentId,
    };
  },
});
