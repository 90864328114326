<template>
  <widget-layout
    title="User Limits"
    class="widget-user-limit"
    :maxHeight="1"
    :minHeight="1"
    :maxWidth="4"
    :menu="menuItems"
    :loading="!data"
    :icon="icon"
    :severity="severity"
  >
    <template v-slot:title>
      <div class="custom-title" :class="'severity-' + severity">
        <div class="content">
          <div class="title-main">
            <h4>{{ titleMain }}</h4>
            <h5 :class="{ fade: width <= 1 }">{{ titleSub }}</h5>
          </div>
          <div v-if="data" class="count" :class="{ fade: width <= 1 }">
            {{ count }}/{{ limit }} ({{ Math.floor((count / limit) * 100) }}%)
          </div>
        </div>
      </div>
    </template>
    <div
      :class="{
        container: true,
        compact: width === 1,
        ['severity-' + severity]: true,
      }"
    >
      <div class="bar-outer">
        <div class="bar-inner" :style="{ width: barWidth }" />
      </div>
      <p :class="{ fade: width > 1 }">
        {{ titleSub }}
        <strong v-if="data"
          >{{ count }}/{{ limit }} ({{
            Math.floor((count / limit) * 100)
          }}%)</strong
        >
      </p>
    </div>
  </widget-layout>
</template>

<script>
import WidgetLayout from "../WidgetLayout.vue";
import { defineComponent, computed } from "vue";

export default defineComponent({
  components: { WidgetLayout },
  name: "DashUserLimit",
  props: ["width", "data", "userData", "titleIcon"],
  setup(props, { emit }) {
    const trigger = computed(() =>
      props.userData.trigger === undefined ? 0.667 : props.userData.trigger
    );
    const count = computed(() =>
      !props.data || !props.data.total ? 0 : props.data.total
    );
    const limit = computed(() =>
      !props.data || !props.data.limit ? 0 : props.data.limit
    );
    const menuItems = computed(() => [
      {
        caption:
          "Set warning trigger (" + Math.floor(trigger.value * 100) + "%)",
        action: () => {
          const input = prompt(
            "(temporary pending proper modal) Please enter desired warning trigger %",
            trigger.value * 100
          );
          if (input === null) return;
          emit("userdata", {
            trigger: Number(input / 100),
          });
        },
      },
    ]);

    const severity = computed(() => {
      const usage = count.value / limit.value;
      if (usage >= 1) return "danger";
      if (usage >= trigger.value) return "warn";
      return "ok";
    });

    const barWidth = computed(() => {
      const barPercentage = isNaN(count.value / limit.value)
        ? 0
        : (count.value / limit.value) * 100;
      return (barPercentage <= 100 ? barPercentage : 100) + "%";
    });

    const icon = computed(() =>
      severity.value === "ok" ? "check" : "exclamation-triangle"
    );

    const titleMain = computed(() => {
      if (!props.data) return "UserLimit";
      return severity.value === "ok" ? "No issues!" : "Warning!";
    });

    const titleSub = computed(() => {
      if (!props.data) return "";
      else if (severity.value === "ok") return "No issues with user limit";
      else if (severity.value === "warn") return "Approaching user limit";
      return "User limit reached";
    });

    return {
      trigger,
      count,
      limit,
      menuItems,
      severity,
      barWidth,
      icon,
      titleMain,
      titleSub,
    };
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";

.custom-title {
  display: flex;
  font-family: "Poppins";
  overflow: visible;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 12px;
    .title-main {
      display: flex;
      flex-direction: column;
      @include min-width("sm") {
        flex-direction: row;
      }
      h4 {
        text-transform: uppercase;
        vertical-align: baseline;
        font-family: inherit;
        font-size: 14px;
        line-height: 18px;
      }
      h5 {
        font-size: 0.8em;
        line-height: 18px;
        transition: 200ms 200ms opacity, 200ms 200ms transform;
        &.fade {
          transform: translate(-5em, 100%) scale(0.9);
        }
        @include min-width("sm") {
          margin-left: 8px;
        }
      }
    }
    .count {
      font-size: 0.82rem;
      font-weight: 400;
      transition: 200ms 200ms all;
      &.fade {
        top: 1em;
        right: 5em;
        transform: scale(0.7);
      }
    }
  }
}

p {
  position: absolute;
  top: 28%;
  left: 50%;
  color: #000;
  padding: 0;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: 200ms 200ms all;
  font-size: 0.6rem;
  letter-spacing: initial;
  font-weight: 300;
  &.fade {
    top: 5%;
    left: 60%;
  }
  strong {
    font-weight: 600;
    display: inline-block;
    margin-left: 1em;
  }
}

.container {
  min-height: 70px;
}

.severity-danger {
  --bg: #f3d9de;
  --icon-bg: #b00020;
}
.severity-warn {
  --bg: #fcf1cc;
  --icon-bg: #efb700;
}
.severity-ok {
  --bg: #d9f3de;
  --icon-bg: #00b020;
}

.bar-outer {
  position: absolute;
  top: 50%;
  left: 1em;
  right: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #00000009;
  transition: 200ms top;
  transform: translateY(-50%);
  transform-origin: 0 0;
  .bar-inner {
    border-radius: 0.5em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--icon-bg);
    transition: 300ms 100ms width ease-out;
  }
}

.fade {
  opacity: 0;
  // always speedy on fade out, roughly halfway through a resize transition
  transition: 300ms all !important;
  pointer-events: none;
}

.compact .bar-outer {
  top: 67%;
}
</style>
