import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-072e7422"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "input-icon prepend"
}
const _hoisted_3 = ["id", "name", "value", "rows", "disabled", "placeholder"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 5,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container", [_ctx.classes, _ctx.customClass, _ctx.required && 'required']])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass({
        active: _ctx.isActive,
        'has-icon': !!_ctx.$slots.prepend,
        'hide-on-focus': _ctx.hideLabelOnFocus,
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      })
        }, [
          _createVNode(_component_TranslatedText, { path: _ctx.label }, null, 8, ["path"])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.prepend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "prepend", { value: _ctx.value }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          id: _ctx.id,
          name: _ctx.name,
          value: _ctx.value,
          rows: _ctx.rows,
          disabled: _ctx.disabled,
          class: _normalizeClass({ error: !!_ctx.error }),
          placeholder: _ctx.placeholderText,
          style: _normalizeStyle({ resize: _ctx.resizable ? '' : 'none' }),
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"]))
        }, null, 46, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["input-dummy", {
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      }])
        }, [
          _createElementVNode("img", {
            class: "editIcon",
            src: require('@/assets/img/icon-edit.svg'),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleEditClick && _ctx.handleEditClick(...args)))
          }, null, 8, _hoisted_4)
        ], 2))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "input-icon append",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, [
          _renderSlot(_ctx.$slots, "append", { value: _ctx.value }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_TranslatedText, { path: _ctx.error }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}