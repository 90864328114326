import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc7d0644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_AddressForm = _resolveComponent("AddressForm")!
  const _component_DynamicList = _resolveComponent("DynamicList")!

  return (_ctx.bankAddresses.length || !_ctx.disabled)
    ? (_openBlock(), _createBlock(_component_DynamicList, {
        key: 0,
        items: _ctx.bankAddresses,
        buttonText: "models.address.add",
        modalTitle: "models.address.singular",
        newFunc: _ctx.createNewUserAddress,
        limit: _ctx.limit,
        allowInline: !_ctx.disabled,
        readonly: _ctx.disabled,
        schema: _ctx.addressSchema,
        onSave: _ctx.handleSave,
        onDelete: _ctx.handleDelete
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(_component_Card, {
            title: _ctx.getAddressTitle(item.isPrimary)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(item.line1), 1),
              (item.line2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(item.line2), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, _toDisplayString(item.city), 1),
              (item.county)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.county), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, _toDisplayString(item.postcode) + ", " + _toDisplayString(item.country), 1)
            ]),
            _: 2
          }, 1032, ["title"])
        ]),
        modalBody: _withCtx(({ update, formItem, errors }) => [
          _createElementVNode("p", _hoisted_3, [
            (_ctx.limit !== 1)
              ? (_openBlock(), _createBlock(_component_TranslatedText, {
                  key: 0,
                  path: "models.address.description"
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_AddressForm, {
            address: formItem,
            hidePrimary: _ctx.hidePrimary,
            errors: errors,
            required: _ctx.required,
            onUpdate: update
          }, null, 8, ["address", "hidePrimary", "errors", "required", "onUpdate"])
        ]),
        _: 1
      }, 8, ["items", "newFunc", "limit", "allowInline", "readonly", "schema", "onSave", "onDelete"]))
    : _createCommentVNode("", true)
}