import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f5d7123"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clear-all" }
const _hoisted_2 = { class: "options pt-0" }
const _hoisted_3 = {
  key: 0,
  class: "filter-group grid"
}
const _hoisted_4 = {
  key: 0,
  class: "form-group sm:col-12 grid"
}
const _hoisted_5 = {
  key: 1,
  class: "form-group sm:col-12 grid"
}
const _hoisted_6 = { class: "filter-list grid" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "filter-group filters" }
const _hoisted_9 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearAllFilters && _ctx.clearAllFilters(...args)))
      }, "Clear all")
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.datePickers.length || _ctx.dateFilterButtons.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.datePickers.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datePickers, (dp) => {
                    return (_openBlock(), _createBlock(_component_DatePicker, {
                      key: dp.id,
                      customClass: dp.customClass,
                      id: dp.id,
                      name: dp.name,
                      label: dp.label,
                      value: dp.value,
                      error: dp.error,
                      onChange: _ctx.changeDate,
                      class: "col sm:col-6"
                    }, null, 8, ["customClass", "id", "name", "label", "value", "error", "onChange"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.dateFilterButtons.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateFilterButtons, (btn) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: btn.id,
                        class: "col sm-col-3"
                      }, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.filterDateByButton(btn.name)),
                          class: _normalizeClass({ active: btn.active })
                        }, [
                          _createVNode(_component_TranslatedText, {
                            path: btn.name
                          }, null, 8, ["path"])
                        ], 10, _hoisted_7)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "filter",
              key: filter
            }, [
              _createVNode(_component_Chip, {
                chip: filter,
                onSelectAll: _ctx.selectAll,
                onToggleChip: _ctx.toggleChip,
                onSelectChipItem: _ctx.selectFilter
              }, null, 8, ["chip", "onSelectAll", "onToggleChip", "onSelectChipItem"])
            ]))
          }), 128))
        ])
      ])
    ])
  ], 64))
}