
import { defineComponent, computed, PropType, ref, watch } from "vue";

import { useNotification } from "@/composables";

import { usePermissionProvider } from "@/plugins/permission";
import { useModal } from "@/plugins/modal";
import { useLoading } from "@/plugins/loading";

import Button from "@/components/Button.vue";
import EditBox from "@/components/form/EditBox.vue";
import FormInput from "@/components/form/FormInput.vue";
import Modal from "@/components/Modal.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";

import { createTrack, updateTrack, httpDeleteTrack } from "@/api/track.api";

export default defineComponent({
  name: "TrackManageModal",
  emits: ["close", "updated"],
  props: {
    tracks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    EditBox,
    FormInput,
    Modal,
    Wysiwyg,
  },
  setup(props, { emit }) {
    const { userHasPermission } = usePermissionProvider();
    const showModal = useModal();
    const showLoader = useLoading();

    const { inPageNotification } = useNotification();

    const trackCreateManage = ref<boolean>(false);
    const newTrack = ref<string>("");
    const showManage = ref<boolean>(props.show);
    const selectedTrack = ref<string>("");
    const deletedIds = ref<Array<string>>([]);

    const model = ref({ id: null, name: "", description: "" });
    const handleInputChange = (event) => {
      model.value.name = event.target.value;
    };

    const handleQuillInputChange = ({ value }) => {
      model.value.description = value; // Update the description directly
    };

    const getTrackOptions = computed<any>(() => {
      return props.tracks
        .filter((t) => !deletedIds.value.includes(t.id))
        .map((t) => ({
          value: t.id,
          text:
            t.textItems.find((ti) => ti.purpose === "track-name")?.data ??
            "Unknown",
        }));
    });

    const getSelectedTrack = computed(() => {
      const group = props.tracks.find(
        (track) => track.id === selectedTrack.value
      );
      return group;
    });

    const handleEditTrack = (item) => {
      selectedTrack.value = item.value;

      const t = getSelectedTrack.value;

      model.value = {
        id: t.id,
        name:
          t.textItems.find((ti) => ti.purpose === "track-name")?.data ??
          "Unknown",
        description:
          t.textItems.find((ti) => ti.purpose === "track-description")?.data ??
          "",
      };

      trackCreateManage.value = true;
    };

    const handleTrackCreate = () => {
      model.value = {
        id: null,
        name: "",
        description: "",
      };
      trackCreateManage.value = true;
    };

    const handleTrackManageChange = ({ value }) => {
      newTrack.value = value;

      selectedTrack.value =
        props.tracks.find((tg) => tg.id === value)?.id ?? "";
    };

    const handleTrackMoveUp = () => {
      const index = getTrackOptions.value.findIndex(
        (group) => selectedTrack.value === group.value
      );
      selectedTrack.value =
        getTrackOptions.value[index - 1]?.value ?? selectedTrack.value;
    };

    const handleTrackMoveDown = () => {
      const index = getTrackOptions.value.findIndex(
        (group) => selectedTrack.value === group.value
      );
      selectedTrack.value =
        getTrackOptions.value[index + 1]?.value ?? selectedTrack.value;
    };

    const getModalTitle = computed<string>(() => {
      return trackCreateManage.value
        ? !model.value.id
          ? "track.manage.create.heading"
          : "track.manage.edit.heading"
        : "track.manage.heading";
    });

    const getModalDescription = computed<string>(() => {
      return trackCreateManage.value
        ? !model.value.id
          ? "track.manage.create.description"
          : "track.manage.edit.description"
        : "track.manage.description";
    });

    const closeModal = () => {
      emit("close");
    };

    const handleDoSaveManage = async () => {
      const isNew = !model.value?.id;
      showManage.value = false;
      showLoader(true);

      try {
        if (model.value?.name) {
          const response = isNew
            ? await createTrack({ ...model.value })
            : await updateTrack({ ...model.value });

          if (response.status === 200 || response.status === 201) {
            emit("updated");
            inPageNotification(
              `track.manage.${isNew ? "create" : "edit"}.success.title`,
              `track.manage.${isNew ? "create" : "edit"}.success.description`,
              "success"
            );
            trackCreateManage.value = false;
            showManage.value = true;
          } else {
            inPageNotification(
              `track.manage.${isNew ? "create" : "edit"}.error.title`,
              `track.manage.${isNew ? "create" : "edit"}.error.description`,
              "error"
            );
            showManage.value = true;
          }
        }
      } catch (err) {
        inPageNotification(
          `track.manage.${isNew ? "create" : "edit"}.error.title`,
          `track.manage.${isNew ? "create" : "edit"}.error.description`,
          "error"
        );
      }

      showLoader(false);
    };

    const handleDoDeleteTrack = (trackId: string) => {
      showManage.value = false;
      showModal({
        title: "track.manage.delete.warning.title",
        body: "track.manage.delete.warning.content",
        onConfirm: async () => {
          showLoader(true);

          try {
            const response = await httpDeleteTrack(trackId);

            if (response.status === 200) {
              deletedIds.value.push(trackId);
              emit("updated");
              inPageNotification(
                "track.manage.delete.success.title",
                "track.manage.delete.success.description",
                "success"
              );
              trackCreateManage.value = false;
              showManage.value = true;
            } else {
              inPageNotification(
                "track.manage.delete.error.title",
                "track.manage.delete.error.description",
                "error"
              );
              showManage.value = true;
            }
          } catch (err) {
            inPageNotification(
              "track.manage.delete.error.title",
              "track.manage.delete.error.description",
              "error"
            );
          }

          showLoader(false);
        },
        onCancel: async () => {
          showManage.value = true;

          showLoader(false);
        },
      });
    };

    watch(props, () => {
      if (props.show) {
        showManage.value = props.show;
      }
    });

    return {
      userHasPermission,
      selectedTrack,
      getTrackOptions,
      showManage,
      trackCreateManage,
      getModalTitle,
      getModalDescription,
      model,
      handleEditTrack,
      handleTrackManageChange,
      handleTrackMoveUp,
      handleTrackMoveDown,
      handleInputChange,
      handleQuillInputChange,
      handleTrackCreate,
      closeModal,
      handleDoDeleteTrack,
      handleDoSaveManage,
    };
  },
});
