
import { defineComponent, computed, ref, onMounted } from "vue";

import TranslateMenu from "@/components/TranslateMenu.vue";

import { useTheme } from "@/composables/useTheme";
import { useStore } from "vuex";

export default defineComponent({
  name: "unathenticatedLayout",
  components: {
    TranslateMenu,
  },
  setup() {
    const store = useStore();
    const { updateTheme } = useTheme();

    const themeRef = ref<any>();
    const getTheme = async () => {
      await store.dispatch("setThemeUpdateAllowed", true);
      await updateTheme();
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const background = computed(() => {
      const style: Record<string, string> = {};
      if (themeRef.value) {
        //Only change background on the track
        if (themeRef.value.backgroundImage.signin) {
          style[
            "background-image"
          ] = `url(${themeRef.value.backgroundImage.signin.url})`;
        }

        if (themeRef.value.backgroundColour.signin?.value) {
          style["background-color"] =
            themeRef.value.backgroundColour.signin?.value;
        }
      }

      return style;
    });

    const getClasses = computed(() => {
      if (themeRef.value) {
        const fullBackground = (themeRef.value?.config ?? []).find(
          (style) => style.name === "sign-in-container-header-background"
        );

        return fullBackground?.value === "true" ? "full-background" : "";
      }
      return "";
    });

    onMounted(() => {
      getTheme();
    });

    return {
      background,
      getClasses,
    };
  },
});
