
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  watch,
  ref,
  computed,
} from "vue";
import Notification from "@/components/Notification.vue";
import VideoControls from "@/components/VideoControls.vue";
import YoutubePlayer from "@/components/YoutubePlayer.vue";

const videoTypes = ["upload", "embed"];

export default defineComponent({
  components: {
    Notification,
    VideoControls,
    YoutubePlayer,
  },
  props: {
    format: {
      type: String,
    },
    placeholderUrl: {
      type: String,
    },
    videoSource: {
      type: String,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
      validator: (val: string) => videoTypes.some((type) => val === type),
    },
    reference: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref<boolean>(true);
    const videoIsPlaying = ref<boolean>(false);
    const showPlayPause = ref<boolean>(false);
    const isFullScreen = ref<boolean>(false);
    const hovering = ref<boolean>(false);

    const showVideoControls = computed(
      () => !videoIsPlaying.value || hovering.value
    );

    const toggleVideoPlayback = (id: string) => {
      const video = document.getElementById(id) as HTMLVideoElement;
      if (video && videoIsPlaying.value) {
        video.pause();
        videoIsPlaying.value = false;
        showPlayPause.value = true;
        setTimeout(() => (showPlayPause.value = false), 300);
      } else if (video && !videoIsPlaying.value) {
        video.play();
        videoIsPlaying.value = true;
        showPlayPause.value = true;
        setTimeout(() => (showPlayPause.value = false), 300);
      }
    };

    const stopVideoPlayback = (id: string) => {
      const video = document.getElementById(id) as HTMLVideoElement;
      if (video && videoIsPlaying.value) {
        video.pause();
        videoIsPlaying.value = false;
        showPlayPause.value = true;
        setTimeout(() => (showPlayPause.value = false), 300);
      }
    };

    const handleYtTogglePlayback = () =>
      (videoIsPlaying.value = !videoIsPlaying.value);

    const toggleFullScreen = () => {
      const enterFullscreenFunctions = document.getElementById(
        "video-container"
      ) as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
      };

      if (isFullScreen.value) {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document["webkitExitFullscreen"])
          document["webkitExitFullscreen"]();
        else if (document["mozCancelFullScreen"])
          document["mozCancelFullScreen"]();
        else if (document["msExitFullscreen"]) document["msExitFullscreen"]();
      } else {
        if (enterFullscreenFunctions?.requestFullscreen)
          enterFullscreenFunctions?.requestFullscreen();
        else if (enterFullscreenFunctions?.webkitRequestFullscreen)
          enterFullscreenFunctions?.webkitRequestFullscreen();
        else if (enterFullscreenFunctions?.mozRequestFullScreen)
          enterFullscreenFunctions?.mozRequestFullScreen();
        else if (enterFullscreenFunctions?.msRequestFullscreen)
          enterFullscreenFunctions?.msRequestFullscreen();
      }
      isFullScreen.value = !isFullScreen.value;
    };

    const setHovering = (val: boolean) => (hovering.value = val);

    const videoStateIcon = computed(() =>
      videoIsPlaying.value ? "pause" : "play"
    );

    const handleOnStateChange = (event) => {
      if (event.data === 0) handleVideoEnded();
      else if (event.data === 1) videoIsPlaying.value = true;
      else if (event.data === 2) videoIsPlaying.value = false;
    };

    const handleVideoEnded = () => emit("ended");

    onMounted(() => {
      isLoading.value = false;
    });
    onBeforeUnmount(() => {
      stopVideoPlayback(`video-${props.reference}`);
    });

    watch(
      () => props.reference,
      async (newRef, oldRef) => {
        stopVideoPlayback(`video-${oldRef}`);
      }
    );
    return {
      handleOnStateChange,
      handleVideoEnded,
      handleYtTogglePlayback,
      showVideoControls,
      setHovering,
      isFullScreen,
      isLoading,
      showPlayPause,
      videoIsPlaying,
      toggleFullScreen,
      toggleVideoPlayback,
      videoStateIcon,
    };
  },
});
