import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Page = _resolveComponent("Page")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        title: _ctx.username,
        navItems: _ctx.navItems,
        activeItem: _ctx.currentNavItem?.url
      }, {
        header: _withCtx(() => [
          _createVNode(_component_Avatar, {
            image: _ctx.user?.profileImage,
            text: _ctx.avatarFallbackText
          }, null, 8, ["image", "text"])
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentNavItem.component)))
        ]),
        _: 1
      }, 8, ["title", "navItems", "activeItem"])
    ]),
    _: 1
  }))
}