
import { defineComponent, PropType, ref, computed } from "vue";
import ECharts from "vue-echarts";
import { use } from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  PieChart,
]);

export interface ProgressChartData {
  name: string;
  value: number;
}

export default defineComponent({
  components: {
    "v-chart": ECharts,
  },
  props: {
    overall: Number,
    trackProgress: {
      type: Array as PropType<Array<ProgressChartData>>,
      default: () => [],
    },
  },
  setup(props) {
    const chartContainer = ref();
    const titleCharacterLimit = 28;
    const option = ref({
      title: [
        {
          text: `${props.overall}%`,
          textAlign: "center",
          left: "50%",
          top: 90,
          textStyle: {
            fontSize: 35,
          },
        },
        {
          text: "COMPLETED",
          textAlign: "center",
          left: "50%",
          top: 130,
          textStyle: {
            fontSize: 10,
            fontWeight: "normal",
            color: "#a0a0a0",
          },
        },
      ],
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: 260,
        orient: "vertical",
        data: props.trackProgress.map((item) =>
          item.name.length > titleCharacterLimit
            ? item.name.substring(0, titleCharacterLimit)
            : item.name
        ),
        type: "scroll",
      },
      series: [
        {
          name: "Hotspot Progress",
          type: "pie",
          radius: [80, 100],
          center: ["50%", 120],
          startAngle: 90,
          color: [
            getComputedStyle(document.documentElement).getPropertyValue(
              "--theme-hotspots-complete"
            ),
            getComputedStyle(document.documentElement).getPropertyValue(
              "--theme-hotspots-complete"
            ) + "80",
            getComputedStyle(document.documentElement).getPropertyValue(
              "--theme-hotspots-complete"
            ) + "50",
            getComputedStyle(document.documentElement).getPropertyValue(
              "--theme-hotspots-complete"
            ) + "90",
            getComputedStyle(document.documentElement).getPropertyValue(
              "--theme-hotspots-complete"
            ) + "30",
          ],
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: props.trackProgress.map((item) => {
            item.name =
              item.name.length > titleCharacterLimit
                ? item.name.substring(0, titleCharacterLimit) + "..."
                : item.name;
            return {
              ...item,
              tooltip: {
                formatter: "{a}<br />{b}: {c}%",
                position: ["50%", "50%"],
              },
            };
          }),
        },
        {
          name: "Overall Progress",
          type: "pie",
          startAngle: 90,
          radius: [100, 120],
          center: ["50%", 120],
          tooltip: {
            formatter: "{a}<br />{b}: {c}%",
            position: ["50%", "50%"],
          },
          label: {
            show: false,
          },
          data: [
            {
              value: props.overall,
              name: "Complete",
              itemStyle: {
                color: getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--theme-hotspots-complete"),
              },
            },
            {
              value: 100 - (props.overall || 0),
              name: "Remaining",
              itemStyle: {
                color: "#e5e5e5",
              },
            },
          ],
        },
      ],
      media: [
        {
          query: {
            minWidth: 560,
          },
          option: {
            legend: {
              orient: "horizontal",
              type: "plain",
              formatter: "{name}      ",
              top: 260,
              width: 560,
              data: props.trackProgress.map((item) =>
                item.name.length > titleCharacterLimit
                  ? item.name.substring(0, titleCharacterLimit) + "..."
                  : item.name
              ),
              textStyle: {
                width: 240,
                overflow: "break",
              },
            },
          },
        },
      ],
    });

    return {
      option,
      // height,
      chartContainer,
    };
  },
});
