import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-437b6619"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_ctx.state.body)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["notification-container", { active: _ctx.state.active }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["notification", [_ctx.state.type, { active: _ctx.state.active }]])
        }, [
          _createVNode(_component_Alert, {
            variant: _ctx.state.type,
            title: _ctx.state.title ?? 'common.error',
            icon: _ctx.icon
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                _createElementVNode("li", _hoisted_1, [
                  _createVNode(_component_TranslatedText, {
                    path: _ctx.state.body
                  }, null, 8, ["path"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["variant", "title", "icon"])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}