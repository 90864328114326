
import { defineComponent, onMounted, ref } from "vue";
import ProgressBar from "@/components/ui/ProgressBar.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "BaseHotspot",
  components: {
    ProgressBar,
    Button,
  },
  props: {
    hotspotProgress: {
      type: Number,
      default: 0,
    },
    showNext: Boolean,
    showPrevious: Boolean,
    editMode: Boolean,
    hideMenu: Boolean,
  },
  emits: ["next-click", "previous-click", "back-click"],
  setup(props, { emit }) {
    const handleNextClick = () => emit("next-click");
    const handlePreviousClick = () => emit("previous-click");
    const handleBackClick = () => emit("back-click");

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
    });

    return {
      isMounted,
      handleNextClick,
      handlePreviousClick,
      handleBackClick,
    };
  },
});
