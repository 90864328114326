
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    flush: Boolean,
  },
});
