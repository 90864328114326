import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31299b79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "maintenance-container signin-container" }
const _hoisted_3 = {
  key: 0,
  class: "branding"
}
const _hoisted_4 = { class: "logo logo--lg" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "form-fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_ShadowContainer = _resolveComponent("ShadowContainer")!
  const _component_unauthenticated_layout = _resolveComponent("unauthenticated-layout")!

  return (_openBlock(), _createBlock(_component_unauthenticated_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.logoUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("img", {
                    class: "icon",
                    src: _ctx.logoUrl,
                    alt: ""
                  }, null, 8, _hoisted_5)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ShadowContainer, null, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, [
                _createVNode(_component_TranslatedText, { path: "maintenance.in-maintenance-title" })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_TranslatedText, { path: "maintenance.in-maintenance-description" })
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}