
import { computed, defineComponent, PropType } from "vue";
import FormInput from "@/components/form/FormInput.vue";
import { useLanguage } from "@/composables";

export default defineComponent({
  name: "UserView",
  components: {
    FormInput,
  },
  props: {
    userIsLoading: Boolean,
    readOnly: Boolean,
    currentReview: {
      type: Object,
    },
    answers: {
      type: Array as PropType<any[]>,
      required: true,
    },
    savedReviews: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const handleUpdate = (
      { name, value },
      id = "",
      actionObjective: any = null
    ) => {
      emit("update", {
        questionId: name,
        content: value,
        id,
        [name.includes("actionObjective") ? "actionObjective" : name]:
          name.includes("actionObjective")
            ? { ...actionObjective, content: value }
            : value,
      });
    };

    const getAnswer = (review, question) => {
      const answer = props.answers.find(
        (answer) => answer.questionId === question.id
      );
      return answer?.content ?? "";
    };

    const { translateText } = useLanguage();

    const disabled = computed(
      () =>
        props.readOnly ||
        (props.currentReview &&
          (props.savedReviews.includes(props.currentReview.id) ||
            props.currentReview.completedManager))
    );

    return {
      handleUpdate,
      getAnswer,
      translateText,
      disabled,
    };
  },
});
