
import { defineComponent, PropType } from "vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import Chip from "@/components/Chip.vue";

import { Chip as IChip } from "@/interfaces/ui/Chip.interface";

export default defineComponent({
  props: {
    tableName: {
      type: String,
      required: true,
    },
    selectedRows: {
      type: Array as PropType<string[]>,
    },
    deselectedRows: {
      type: Array as PropType<string[]>,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    selectAllActive: {
      type: Boolean,
    },
    actions: {
      type: Object as PropType<IChip>,
    },
  },
  emits: [
    "toggle-all",
    "change-activation-status",
    "clear-selection",
    "toggle-actions-chip",
    "click-menu-item",
  ],
  setup(_, context) {
    const buttonSize = window.innerWidth < 768 ? "" : "sm";

    const centerChipText = buttonSize === "sm" ? "" : "center";

    const toggleAll = () => context.emit("toggle-all");

    const changeActivationStatus = (action: string) =>
      context.emit("change-activation-status", action);

    const clearSelection = () => context.emit("clear-selection");

    const toggleActionsChip = () => context.emit("toggle-actions-chip");

    const clickMenuItem = (menuItem: { title: string; id: number }) =>
      context.emit("click-menu-item", menuItem.title);

    return {
      buttonSize,
      centerChipText,
      toggleAll,
      changeActivationStatus,
      clearSelection,
      toggleActionsChip,
      clickMenuItem,
    };
  },
  components: {
    Button,
    Checkbox,
    Chip,
  },
});
