
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useLoading } from "@/plugins/loading";
import ReviewView from "@/views/ReviewView.vue";
import { Review, User } from "@/models";
import { getReviewFeatureConfigs } from "@/api/reviews.api";
import { useStore } from "vuex";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    ReviewView,
  },
  setup() {
    const router = useRouter();
    const showLoader = useLoading();

    const { inPageNotification } = useNotification();

    const { id } = router.currentRoute.value.params;

    const reviews = ref<Array<Review>>();
    const reviewsOriginal = ref<Array<Review>>();
    const user = ref<User>();
    const answers = ref<any[]>([]);
    const answersOriginal = ref<any[]>([]);
    const userIsLoading = ref<boolean>(true);
    const userNotFound = ref<boolean>(false);
    const probationEnabled = ref<boolean>(false);
    const firstProbationReview = ref<number>(0);
    const probationExtensionsEnabled = ref<boolean>(false);
    const passedProbation = ref<boolean>(false);
    const passedProbationOriginal = ref<boolean>(false);

    const navItemConfig = ref<any[]>([]);

    const changeTab = (url: string) => {
      const userId = router.currentRoute.value.params.id as string;
      router.push({
        path: `/reviews/${userId}/${url}`,
      });
    };

    const getReviews = async (bypassCache = false) => {
      try {
        const reviewFeatureCongigs = await getReviewFeatureConfigs();
        reviewFeatureCongigs.data.payload.result.forEach((featureConfig) => {
          switch (featureConfig.feature) {
            case "probation":
              probationEnabled.value = featureConfig.config;
              break;
            case "first-probation-review":
              firstProbationReview.value = +featureConfig.condition;
              break;
            case "probation-extensions":
              probationExtensionsEnabled.value = featureConfig.config;
              break;
          }
        });

        userIsLoading.value = true;
        if (!reviews.value || bypassCache) {
          reviews.value = await User.getReviews(id as string);
          let num = 1;
          navItemConfig.value = [];
          reviews.value = reviews.value.map((review) => {
            let title = "Review " + num++;
            if (review.extensionReview) {
              title += " (Extension)";
            }

            navItemConfig.value.push({
              url: review.id,
              title,
              component: "Review",
              permission: "user:reviews",
              action: () => changeTab(review.id),
            });

            review.actionObjectives.sort(
              (ao1, ao2) => ao1.actionNumber - ao2.actionNumber
            );

            return review;
          });
          reviewsOriginal.value = [...reviews.value];
          if (reviews.value && reviews.value[0]) {
            answers.value = reviews.value[0].user.answers;
            answersOriginal.value = [...answers.value];
          }
          user.value = await User.get(id as string);
          passedProbation.value = user.value.passedProbation;
          passedProbationOriginal.value = !!user.value.passedProbation;
        }
      } catch (err) {
        userNotFound.value = false;
        inPageNotification("reviews.error-title", "reviews.error-load", "error");
        router.push("/");
      } finally {
        userIsLoading.value = false;
      }
    };

    const updatedReviews = ref<string[]>([]);
    const savedReviews = ref<string[]>([]);
    const handleUpdate = ({ questionId, content, id, ...rest }) => {
      !updatedReviews.value.includes(id) && updatedReviews.value.push(id);
      if (
        reviews.value &&
        (rest.developmentAreas ||
          rest.myLearningProgress ||
          rest.onboardingProgress ||
          rest.actionObjective)
      ) {
        reviews.value = reviews.value.map((review: Review) => {
          if (review.id === id && rest.actionObjective) {
            let updated = false;
            review.actionObjectives = review.actionObjectives.map((ao: any) => {
              if (ao.actionNumber === rest.actionObjective.actionNumber) {
                updated = true;
                return { ...ao, content };
              } else {
                return ao;
              }
            });
            if (!updated) {
              review.actionObjectives = [
                ...review.actionObjectives,
                {
                  reviewId: review.id,
                  content,
                  userId: user.value?.id,
                  actionNumber: ++review.actionObjectives.length,
                },
              ];
            }
            return review;
          } else if (review.id === id) {
            review = { ...review, ...rest } as Review;
          }
          return review;
        });
      } else {
        let updated = false;
        answers.value = answers.value?.map((answer: any) => {
          if (answer.questionId === questionId) {
            updated = true;
            return { ...answer, content };
          } else {
            return answer;
          }
        });
        if (!updated) {
          answers.value = [
            ...answers.value,
            {
              questionId,
              content,
              userId: user.value?.id,
            },
          ];
        }
      }
    };

    const handleCancel = (cancelledReview: Review) => {
      reviews.value = reviews.value?.map((review: Review) => {
        if (review.id === cancelledReview.id) {
          const originalReview = reviewsOriginal.value?.find(
            (rev: Review) => (rev.id = cancelledReview.id)
          );
          if (originalReview) {
            originalReview.actionObjectives = [
              ...originalReview.actionObjectives,
            ];
          }
          return originalReview as Review;
        }
        return review;
      });
      passedProbation.value = !!passedProbationOriginal.value;
      answers.value = [...answersOriginal.value];
      resetUpdatedReviews(cancelledReview);
    };

    const handleSave = async (review: Review) => {
      review.answers = answers.value;
      try {
        await User.putReview(review, passedProbation.value);
        !savedReviews.value.includes(review.id) &&
          savedReviews.value.push(review.id);
        resetUpdatedReviews(review);
        inPageNotification("reviews.saved-title", "reviews.saved", "success");
      } catch (error) {
        inPageNotification("reviews.error-title", "-save", "error");
      }
    };

    const handleProbationChange = async (review: Review) => {
      !updatedReviews.value.includes(review.id) &&
        updatedReviews.value.push(review.id);
      passedProbation.value = !passedProbation.value;
    };

    const handleAddExtensionReview = async () => {
      try {
        const review: Review = await User.addExtensionReview(
          user.value?.id as string
        );
        showLoader(true);
        await getReviews(true);
        showLoader(false);
        router.push({
          path: `/reviews/${user.value?.id}/${review.id}`,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const handleAddActionObjective = async (updatedReview: Review) => {
      !updatedReviews.value.includes(updatedReview.id) &&
        updatedReviews.value.push(updatedReview.id);
      reviews.value = reviews.value?.map((review: Review) => {
        if (review.id === updatedReview.id) {
          review.actionObjectives.push({
            reviewId: review.id,
            content: "",
            actionNumber: review.actionObjectives.length + 1,
            userId: user.value?.id,
          });
        }
        return review;
      });
    };
    const store = useStore();

    onMounted(async () => {
      showLoader(true);
      await getReviews(true);
      showLoader(false);
    });

    return {
      reviews,
      user,
      answers,
      userIsLoading,
      navItemConfig,
      handleUpdate,
      handleSave,
      updatedReviews,
      probationEnabled,
      probationExtensionsEnabled,
      firstProbationReview,
      passedProbation,
      handleProbationChange,
      handleCancel,
      handleAddExtensionReview,
      handleAddActionObjective,
      savedReviews,
    };

    function resetUpdatedReviews(review: Review) {
      updatedReviews.value = [
        ...updatedReviews.value.slice(
          0,
          updatedReviews.value.indexOf(review.id)
        ),
        ...updatedReviews.value.slice(
          updatedReviews.value.indexOf(review.id) + 1,
          updatedReviews.value.length
        ),
      ];
    }
  },
});
