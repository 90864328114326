
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  PropType,
} from "vue";
import { Action, Hotspot, Tick, ActionProgress } from "@/models";
import { useStore } from "vuex";
import Checklist from "@/components/Checklist.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";

export default defineComponent({
  components: {
    ActionHeader,
    Checklist,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const ticks = ref<Array<Tick>>();
    const getTicksWithProgress = async () => {
      ticks.value = await Tick.getForActionAndUser(
        props.action.id,
        store.getters.currentUser.id
      );
    };

    const orderedTicks = computed(() =>
      (ticks.value ?? []).sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      )
    );

    const checklistItems = computed(() => {
      return orderedTicks.value.map((tick) => {
        const title = tick.textItems.find((ti) => ti.purpose === "title");
        const description = tick.textItems.find((ti) => ti.purpose === "description");
        const adminDescription = tick.textItems.find((ti) => ti.purpose === "adminDescription");
        const progress = (tick.tickProgressObjects ?? [])[0];
        return {
          id: tick.id,
          name: title?.data ?? "",
          description: description?.data ?? "",
          adminDescription: adminDescription?.data ?? "",
          completed: progress && progress.completed,
        };
      });
    });

    onMounted(() => getTicksWithProgress());
    watch(props, () => getTicksWithProgress());

    return {
      ticks,
      orderedTicks,
      checklistItems,
    };
  },
});
