import httpClient from "@/api/httpClient";
import { Model } from "./Model";

export class Video extends Model<Video> {
  // public userId: string;
  // public groupId: string;
  public languageId: string;
  public fileName: string;
  public storageKey: string;
  public url: string;
  public reference: string;
  public signOffEnabled: boolean;

  constructor({
    id = "",
    // userId = "",
    // groupId = "",
    languageId = "",
    fileName = "",
    storageKey = "",
    url = "",
    reference = "",
    signOffEnabled = false,
  } = {}) {
    super(id, Video, "");
    // this.userId = userId;
    // this.groupId = groupId;
    this.languageId = languageId;
    this.fileName = fileName;
    this.storageKey = storageKey;
    this.url = url;
    this.reference = reference;
    this.signOffEnabled = signOffEnabled;
  }

  public static async getForReferences(
    references: Array<string>
  ): Promise<any> {
    return await Model.getAll<Document>(
      `/videocontents/content/${JSON.stringify(references)}/user/null/null`,
      Document
    );
  }

  public async delete(): Promise<any> {
    return await httpClient.delete(`/videocontents/${this.id}`);
  }
}
