
import { computed, defineComponent, PropType, ref } from "vue";
import { useLanguage } from "@/composables";
import { NavItem } from "@/interfaces";
import Avatar from "@/components/Avatar.vue";
import Tick from "@/components/ui/Tick.vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";

export default defineComponent({
  components: {
    Avatar,
    Tick,
    VueDraggableNext,
  },
  props: {
    items: {
      type: Array as PropType<Array<NavItem>>,
      required: true,
    },
    activeItem: {
      type: String,
    },
    actionNav: {
      type: Boolean,
      default: false,
    },
    showIconRight: Boolean,
    showAvatar: Boolean,
    showSubtitle: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const editMode = computed(() => store.getters.track.editMode);
    const language = useLanguage();

    const handleClick = (e: Event, item: NavItem) => {
      if (!editMode.value && item?.type === "link" && item?.externalLink) {
        e.preventDefault();
        window && window.open(item.externalLink, "_blank")?.focus();
      } else if (item.action) {
        e.preventDefault();
        item.action();
      }
    };

    const dragging = ref(false);

    const startDragging = (event) => {
      if (props.items[event.oldIndex].id !== "contacts") {
        dragging.value = true;
        emit("draggingStarted", event);
      }
    };

    const endDragging = (event) => {
      if (props.items[event.newIndex].id !== "contacts") {
        dragging.value = false;
        emit("changeOrder", event);
      }
    };

    const handleCreateAction = () => emit("createAction");
    const getSubtitle = (value: string) => {
      const translatedValue = language.getValueForString(value);
      if (translatedValue && translatedValue.length > 21) {
        const endPart = translatedValue.slice(19);
        return translatedValue.replace(endPart, "...");
      } else return translatedValue;
    };

    return {
      dragging,
      editMode,
      handleClick,
      handleCreateAction,
      getSubtitle,
      endDragging,
      startDragging,
    };
  },
});
