
import { defineComponent } from "vue";

const alertVariants = ["", "success", "error"];

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: () => "",
      validator: (value: string) => alertVariants.some((v) => v === value),
    },
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
});
