import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setPageSize = "setPageSize",
}

export interface PaginationState {
  pageSize: number;
}

const state: PaginationState = {
  pageSize: 15,
};

const getters: GetterTree<PaginationState, null> = {
  pageSize: (state: PaginationState) => state.pageSize,
};

const actions: ActionTree<PaginationState, null> = {
  setPageSize({ commit }, pageSize: number) {
    commit(MutationKeys.setPageSize, pageSize);
  },
};

const mutations: MutationTree<PaginationState> = {
  [MutationKeys.setPageSize]: (state: PaginationState, pageSize: number) => {
    state.pageSize = pageSize;
  },
};

const pagination: Module<PaginationState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { pagination };
