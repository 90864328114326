
import {
  defineComponent,
  getCurrentInstance,
  inject,
  ComputedRef,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { usePermissionProvider } from "@/plugins/permission";
import { useNotification } from "@/composables/useNotification";

import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

import { Hotspot, Track } from "@/models";
import { Theme } from "@/models/Style";

import { getTracks, saveHotspotAppearanceChanges } from "@/api/track.api";
import { getHotspot, httpPostCreateHotspot } from "@/api/hotspot.api";

import MobileTrack from "@/components/track/MobileTrack.vue";
import TrackManageModal from "@/components/track/manager/TrackManageModal.vue";
import ProgressBar from "@/components/ui/ProgressBar.vue";
import Select from "@/components/form/Select.vue";
import TrackDisplay from "@/components/track/manager/TrackDisplay.vue";
import TrackEditButton from "@/components/TrackEditButton.vue";

export default defineComponent({
  name: "UserTrack",
  components: {
    AuthenticatedLayout,
    MobileTrack,
    ProgressBar,
    Select,
    TrackDisplay,
    TrackEditButton,
    TrackManageModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { inPageNotification } = useNotification();
    const theme = inject<ComputedRef<Theme>>("theme");
    const tracks = ref<any[]>([]);
    const hotspots = ref<any[]>([]);
    const curvedLine = ref<boolean>(false);
    const editMode = computed(() => store.getters.track.editMode);
    const showManageModal = ref<boolean>(false);

    const activePoint = ref<number>(0);
    const draggedPoint = ref<boolean>(false);
    const draggedCubic = ref<boolean>(false);
    const trackIsDirty = ref<boolean>(false);
    const currentNavId = ref<string>("");

    const { userHasPermission, isGodMode } = usePermissionProvider();

    const calcMaxWidth = computed(() => {
      if (availableTracks.value) {
        const maxStringLength = availableTracks.value.reduce(
          (max, track) => (track?.text.length > max ? track.text.length : max),
          availableTracks.value[0]?.text.length
        );

        return `${maxStringLength * 11 + 35}px`;
      }
      return "250px";
    });

    const mobileBackground = computed(() => {
      const style: Record<string, string> = {};
      if (theme) {
        if (theme.value.backgroundImage.app) {
          style[
            "background-image"
          ] = `url(${theme.value.backgroundImage.app.url})`;
        }

        if (theme.value.backgroundColour.app?.value) {
          style["background-color"] = theme.value.backgroundColour.app?.value;
        }
      }

      return style;
    });

    const username = computed(() =>
      store.getters.currentUser
        ? `${store.getters.currentUser.firstName} ${store.getters.currentUser.surname}`
        : ""
    );

    const canEdit = computed(() => isGodMode());

    const currentTrack = ref<any>();
    const universalTrack = ref<any>();

    const sortedTrack = computed(() => {
      const unsorted = currentTrack.value?.hotspotAppearances?.filter((track) =>
        hotspots.value.map((hotspot) => hotspot.id).includes(track.hotspotId)
      );
      const sorted = unsorted?.sort((a, b) => a.order - b.order);
      return sorted;
    });

    const navItems = computed(() => {
      const hotspotAppearances = currentTrack.value?.hotspotAppearances;
      if (!hotspotAppearances.length) {
        return [];
      }
      return hotspotAppearances
        ?.map((hs) => {
          const hotspot = hotspots.value?.find(
            (hotspot) => hotspot.id === hs.hotspotId
          );
          return {
            url: hotspot?.id,
            active: false,
            title: hotspot?.textItems.find((ti) => ti.purpose === "title")
              ?.data,
            component: "Page",
            children:
              hotspot?.actions && hotspot.actions.length
                ? hotspot.actions.map((action) => {
                    return {
                      title: action.textItems.find(
                        (ti) => ti.purpose === "title"
                      )?.data,
                      url: "#",
                      component: "Page",
                    };
                  })
                : [],
            action: () =>
              clickTab({
                id: hotspots.value?.find(
                  (hotspot) => hotspot.id === hs.hotspotId
                )?.id,
              }),
          };
        })
        .filter((hs) => hs?.url);
    });

    const currentNavItem = computed(() => {
      if (navItems.value?.length) {
        const found = navItems.value?.find(
          (item) => item.url === currentNavId.value
        );
        return found ? found : navItems.value[0];
      } else return { url: "" };
    });

    const activeHotspot = computed(() => {
      let activeIndex: null | number = null;
      hotspots.value.map((hs, idx) => {
        if (
          activeIndex === null &&
          ((hs?.hotspotProgressObjects &&
            hs.hotspotProgressObjects[0]?.progressPercentage < 100) ||
            editMode.value)
        ) {
          activeIndex = idx;
        }
      });
      return activeIndex ?? -1;
    });

    const points = ref<any>([]);

    const totalProgress = computed(() =>
      store.getters.currentUser && store.getters.currentUser.progressPercentage
        ? store.getters.currentUser.progressPercentage
        : 0
    );

    const tourViewed = computed(() =>
      store.getters.currentUser && store.getters.currentUser.tourViewed
        ? store.getters.currentUser.tourViewed
        : false
    );
    const isMobile = computed(() => store.getters.screenSize < 1024);

    const getCurrentTrackByName = (val) => {
      if (availableTracks.value) {
        return availableTracks.value.find((track) => track.value === val)
          ?.text as string;
      }
      return "";
    };

    const handleSelectChange = async ({ value }) => {
      hotspots.value = await Hotspot.getAllHotspots(value);
      let val = getCurrentTrackByName(value);
      if (val.length > 0) {
        const selectedTrack = getTrackByName(val);
        currentTrack.value = { ...selectedTrack };
        updateUniversalTrack();
        if (!currentTrack.value) {
          if (universalTrack.value) {
            currentTrack.value = { ...universalTrack.value };
          } else {
            currentTrack.value = { ...tracks.value[0] };
          }
        }
        if (
          val.toLowerCase() !== "universal" &&
          universalTrack.value?.hotspotAppearances &&
          currentTrack.value?.hotspotAppearances
        ) {
          currentTrack.value.hotspotAppearances = [
            ...universalTrack.value.hotspotAppearances.filter(
              (universal) =>
                !currentTrack.value.hotspotAppearances
                  .map((ct) => ct.hotspotId)
                  .includes(universal.hotspotId)
            ),
            ...currentTrack.value.hotspotAppearances,
          ];
        }
      } else {
        currentTrack.value = { ...universalTrack.value };
      }
      sortHotspots();
      store.dispatch("setCurrentTrack", value);
      store.dispatch("setHotspots", hotspots.value);
    };

    const handleHotspotClick = (index: number) => {
      if (editMode.value) {
        activePoint.value = index;
        draggedPoint.value = false;
      } else {
        const hotspotId = hotspots.value[index].id;
        router.push(`/track/hotspot/${hotspotId}`);
      }
    };
    const availableTracks = ref<{ value: string; text: string }[]>([]);
    const currentTrackId = ref<string>(store.getters.currentTrack);
    const currentTrackSelect = ref<{ value: string; text: string }[]>([
      {
        value: currentTrackId.value,
        text: getCurrentTrackByName(currentTrackId.value),
      },
    ]);

    const setTracks = () => {
      if (tracks.value.length) {
        availableTracks.value = tracks.value.map((track: Track) => {
          return {
            value:
              track && track?.groups && track.groups[0]?.id
                ? track.groups[0].id
                : track?.id,
            text:
              track.textItems.find((g) => g.purpose === "track-name")?.data ??
              "unknown",
          };
        });
      }

      // If the current track id isn't present in the available tracks, deafult it to the first track
      if (!availableTracks.value.find((t) => t.value == currentTrackId.value)) {
        if (availableTracks.value.length) {
          currentTrackId.value = availableTracks.value[0].value;
        }
        store.dispatch("setCurrentTrack", currentTrackId.value);
        store.dispatch("setHotspots", hotspots.value);
      }
      availableTracks.value.sort((a, b) => a.text.localeCompare(b.text));
      currentTrackSelect.value = [
        {
          value: currentTrackId.value,
          text: getCurrentTrackByName(currentTrackId.value),
        },
      ];
      handleSelectChange({ value: currentTrackId.value });
    };

    const updateTrack = async () => {
      const res = await getTracks();
      tracks.value = res.data.payload.results;
      let val = getCurrentTrackByName(currentTrackId.value);
      if (val && val.length > 0) {
        currentTrack.value = getTrackByName(val);
        updateUniversalTrack();
        if (!currentTrack.value) {
          currentTrack.value = { ...universalTrack.value };
        }
        handleSelectChange({ value: currentTrackId.value });
      }
      setTracks();
    };

    const getTrackByName = (val) =>
      tracks.value.find(
        (track) =>
          val.toLowerCase() ===
          track.textItems
            ?.find((ti) => ti.purpose === "track-name")
            ?.data.toLowerCase()
      );

    function updateUniversalTrack() {
      if (!universalTrack.value) {
        universalTrack.value = tracks.value.find(
          (track) =>
            "universal" ===
            track.textItems
              ?.find((ti) => ti.purpose === "track-name")
              ?.data.toLowerCase()
        );
      }
    }

    function sortHotspots() {
      hotspots.value = hotspots.value.sort((h1, h2) => {
        if (
          h1.hotspotAppearances.length > 0 &&
          h2.hotspotAppearances.length > 0
        ) {
          let trackHotspot1 = h1.hotspotAppearances.find(
            (h1appearance) => h1appearance.trackId === currentTrack.value.id
          );
          let trackHotspot2 = h2.hotspotAppearances.find(
            (h2appearance) => h2appearance.trackId === currentTrack.value.id
          );
          if (!trackHotspot1) {
            trackHotspot1 = h1.hotspotAppearances[0];
          }
          if (!trackHotspot2) {
            trackHotspot2 = h2.hotspotAppearances[0];
          }
          return trackHotspot1.order - trackHotspot2.order;
        }
        return 0;
      });
    }

    onMounted(async () => {
      updateTrack();

      if (!isMobile.value && !tourViewed.value) {
        const internalInstance = getCurrentInstance();

        if (internalInstance) {
          const $tours =
            internalInstance.appContext.config.globalProperties.$tours;
          if ($tours && $tours["userTrackTour"]) {
            setTimeout(() => $tours["userTrackTour"].start(), 1000);
          }
        }
      }
    });

    watch(showManageModal, () => {
      if (!showManageModal.value) {
        updateTrack();
      }
    });

    watch(sortedTrack, () => {
      if (sortedTrack.value) {
        points.value = sortedTrack.value?.map((ha) => JSON.parse(ha.coords));
        curvedLine.value = points.value.filter((p) => p?.c).length > 0;
      }
    });

    const handleModalUpdated = () => {
      updateTrack();
    };

    const setPointType = (e) => {
      trackIsDirty.value = true;
      let v = e.target.value;
      curvedLine.value = v === "c";
      points.value.forEach((active, index) => {
        if (index !== 0) {
          switch (v) {
            case "l":
              delete points.value[index].c;
              break;
            case "c":
              points.value[index] = {
                x: active.x,
                y: active.y,
                c: [
                  {
                    x: (active.x + points.value[index - 1].x - 50) / 2,
                    y: (active.y + points.value[index - 1].y) / 2,
                  },
                  {
                    x: (active.x + points.value[index - 1].x + 50) / 2,
                    y: (active.y + points.value[index - 1].y) / 2,
                  },
                ],
              };
              break;
          }
        }
      });
    };

    const setPointCoords = (coords) => {
      points.value[activePoint.value].x = coords.x;
      points.value[activePoint.value].y = coords.y;

      trackIsDirty.value = true;
    };

    const setCubicCoords = ({ coords, anchor }) => {
      points.value[activePoint.value].c[anchor].x = coords.x;
      points.value[activePoint.value].c[anchor].y = coords.y;

      trackIsDirty.value = true;
    };

    const addPoint = async (coords) => {
      points.value.push(coords);
      /* *
      const createHotspot = { data: { code: 400, payload: { result: {} } } };
      /* */
      const createHotspot = await httpPostCreateHotspot(
        "New Hotspot",
        "",
        "action",
        currentTrack.value &&
          currentTrack.value?.groups &&
          currentTrack.value.groups[0]?.id
          ? currentTrack.value.groups[0].id
          : currentTrack.value?.id,
        currentTrack.value?.id,
        coords.x,
        coords.y,
        "bottom",
        false
      );
      /* */

      if (createHotspot?.data?.code === 201) {
        const newHotspot = createHotspot?.data?.payload?.result ?? {};
        const hotspotData = await getHotspot(newHotspot?.id);
        if (hotspotData?.data?.code === 200) {
          const hotspot = hotspotData?.data?.payload?.result?.hotspot;

          hotspots.value.push(hotspot);
          if (hotspot?.hotspotAppearances) {
            currentTrack.value = hotspot.hotspotAppearances;
          }
          activePoint.value = points.value.length - 1;
        }
      }
      /* */
    };

    const removeActivePoint = () => {
      if (points.value.length > 1 && activePoint.value !== 0) {
        const endPoints = [...points.value];
        points.value = [
          ...points.value.splice(0, activePoint.value),
          ...endPoints.splice(activePoint.value + 1),
        ];
        activePoint.value = points.value.length - 1;
      }
    };

    const setDraggedPoint = ({ index, draggedPointValue }) => {
      activePoint.value = index;
      draggedPoint.value = draggedPointValue;
      if (navItems.value) {
        const item = navItems.value[index];
        clickTab({ id: item.url });
      }
    };

    const clickTab = (data: { id: string | undefined }) => {
      if (data.id !== currentNavItem.value.url) {
        data.id ? (currentNavId.value = data.id) : "";
        const index = navItems.value?.findIndex((item) => item.url === data.id);
        index ? (activePoint.value = index) : (activePoint.value = 0);
      } else currentNavId.value = "";
    };

    const setDraggedCubic = ({ index, anchor }) => {
      activePoint.value = index;
      draggedCubic.value = anchor;
    };

    const cancelDragging = () => {
      draggedCubic.value = false;
      draggedPoint.value = false;
    };

    const handleDoubleClick = (index: number) => {
      const hotspotId = hotspots.value[index].id;
      router.push(`/track/hotspot/${hotspotId}`);
    };

    const handleTrackSaved = async () => {
      const newHotspotAppearance = sortedTrack.value?.map((hs, index) => {
        const hotspot = currentTrack.value?.hotspotAppearances?.find(
          (hotspot) => hs.id === hotspot.id
        );
        if (hotspot) {
          hotspot.coords = JSON.stringify(points.value[index]);
          hotspot.posX = points.value[index].x / 8;
          hotspot.posY = points.value[index].y / 6;
        }
        return hotspot;
      });
      if (newHotspotAppearance) {
        const response = await saveHotspotAppearanceChanges(
          newHotspotAppearance
        );
        if (response.status === 201) {
          inPageNotification("Success!", "Track updated", "success");
        } else {
          inPageNotification("Error!", "Could not update track", "error");
        }
      }

      trackIsDirty.value = false;
    };

    return {
      tracks,
      hotspots,
      activeHotspot,
      points,
      curvedLine,
      calcMaxWidth,
      handleHotspotClick,
      currentTrack,
      username,
      totalProgress,
      mobileBackground,
      availableTracks,
      handleSelectChange,
      userHasPermission,
      handleModalUpdated,
      showManageModal,
      currentTrackId,
      currentTrackSelect,
      editMode,
      tourViewed,
      isMobile,
      setPointType,
      setPointCoords,
      setCubicCoords,
      addPoint,
      removeActivePoint,
      setDraggedPoint,
      setDraggedCubic,
      cancelDragging,
      handleDoubleClick,
      handleTrackSaved,
      trackIsDirty,
      draggedPoint,
      draggedCubic,
      canEdit,
    };
  },
});
