
import { defineComponent } from "vue";

export default defineComponent({
  name: "Choices",
  emits: ["onChange"],
  props: {
    id: {
      type: String,
    },
    choices: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const onChange = (e) => emit("onChange", e);

    return {
      onChange,
    };
  },
});
