
import { defineComponent, watch, PropType, ref } from "vue";
import TabList from "@/components/TabList.vue";
import NavList from "@/components/NavList.vue";
import BackLink from "@/components/BackLink.vue";
import Avatar from "@/components/Avatar.vue";
import { NavItem } from "@/interfaces";

export default defineComponent({
  name: "Page",
  components: {
    TabList,
    BackLink,
    NavList,
    Avatar,
  },
  props: {
    title: String,
    subtitle: String,
    navItems: {
      type: Array as PropType<Array<NavItem>>,
      default: () => [],
    },
    activeItem: {
      type: String,
      default: "",
    },
    customDetailsClass: {
      type: String,
      default: "",
    },
    displaySideFooterBottom: Boolean,
    hideHeaderForLarge: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showTabList: {
      type: Boolean,
      default: true,
    },
    showNavItemIconRight: {
      type: Boolean,
      default: true,
    },
    showNavItemAvatar: Boolean,
    showNavItemSubtitle: Boolean,
  },
  setup(props) {
    const avatarData = ref({});

    watch(props, () => {
      if (props.avatar) {
        avatarData.value = props.avatar;
      }
    });
    return {
      avatarData,
    };
  },
});
