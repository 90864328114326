import { Model } from "./Model";
export class Address extends Model<Address> {
  private static readonly path: string = "/user-address";

  public userId: string;
  public line1: string;
  public line2?: string;
  public city: string;
  public county: string;
  public postcode: string;
  public country: string;
  public isPrimary: boolean;

  constructor({
    id = "",
    userId = "",
    line1 = "",
    line2,
    city = "",
    county = "",
    postcode = "",
    country = "",
    isPrimary = false,
  }: Partial<Address> = {}) {
    super(id, Address, Address.path);
    this.userId = userId;
    this.line1 = line1;
    this.line2 = line2;
    this.city = city;
    this.county = county;
    this.postcode = postcode;
    this.country = country;
    this.isPrimary = isPrimary;
  }

  public static async get(userId: string): Promise<Array<Address>> {
    return await Model.getAllForUser<Address>(userId, Address.path, Address);
  }
}
