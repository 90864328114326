
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["change", "changeFull"],
  props: {
    id: String,
    name: String,
    dragging: Boolean,
    editMode: Boolean,
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleValueChange = (e) => {
      emit("change", props.value);
      emit("changeFull", e);
    };

    return { handleValueChange };
  },
});
