import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79f0c1d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.translatedText)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        innerHTML: _ctx.translatedText
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}