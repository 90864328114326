import httpClient, { ApiResponse } from "./httpClient";
/*import { Contact } from "@/interfaces";*/

export const getContacts = async (currentUserId: string): Promise<ApiResponse<any>> =>
  httpClient.get(`/contacts/user/${currentUserId}`);

export const getContactsForUser = async (currentUserId: string): Promise<ApiResponse<any>> =>
  httpClient.get(`/contacts/user/admin/${currentUserId}`);

export const getAllContacts = async (): Promise<ApiResponse<any>> =>
  httpClient.get(`/contacts/all`);
