import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6598eef4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "type-subtitle-02" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ListGroup = _resolveComponent("ListGroup")!

  return (_openBlock(), _createBlock(_component_ListGroup, {
    items: _ctx.items,
    flush: _ctx.flush,
    style: _normalizeStyle({ '--item-cursor': _ctx.getItemCursor })
  }, {
    item: _withCtx(({ item }) => [
      _createElementVNode("div", {
        class: "list-item",
        onClick: ($event: any) => (_ctx.handleItemClick(item.id))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["check-icon", { checked: item.completed }])
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "check" })
        ], 2),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1),
          (item.description)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: item.description
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (item.additionalDescription)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: "type-subtitle-04",
                innerHTML: item.additionalDescription
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        (_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["remove-icon flex-right", { checked: item.completed }])
            }, [
              _createElementVNode("span", {
                onClick: _withModifiers(($event: any) => (_ctx.handleItemDelete(item.id)), ["stop"])
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "times" })
              ], 8, _hoisted_5)
            ], 2))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["items", "flush", "style"]))
}