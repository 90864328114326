
import { defineComponent, computed, PropType } from "vue";
import { widgetComponents } from "./registry.js";
import { Widget } from "@/interfaces/Widget.interface";

export default defineComponent({
  props: {
    isLoading: Boolean,
    widgets: {
      type: Array as PropType<Widget[]>,
      default: () => [],
    },
    liveData: {
      type: Object,
    },
  },
  setup(props) {
    const components = widgetComponents;

    const widgetStyles = computed(() => {
      let styles = {};
      props.widgets.map((widget) => {
        if (!widget?.id) {
          widget.id = widget.type;
        }
        styles[widget.id] = {
          gridColumn: `${widget.x + 1}/${widget.x + 1 + widget.width}`,
          gridRow: `${widget.y + 1}/${widget.y + 1 + widget.height}`,
        };
      });
      return styles;
    });

    const sortedWidgets = computed(() => {
      const unsorted = props.widgets;
      return unsorted
        .sort((a, b) => a.x + a.y - (b.x, b.y))
        .filter((w) => components[w.type]);
    });

    const getCellHeight = (index: number) => {
      return { height: sortedWidgets.value[index].height * 140 + "px" };
    };

    const numberOfRows = computed(() => {
      const copy = sortedWidgets.value;
      const sortedByY = copy.sort((a, b) => a.y - b.y);
      let all: any = [];
      sortedByY.map((sw) => {
        if (sw.y === sortedByY[sortedByY.length - 1].y) all.push(sw);
      });
      const sortedByHeight = all.sort((a, b) => a.height - b.height);
      const lastItem = sortedByHeight[sortedByHeight.length - 1];
      return lastItem.y + lastItem.height;
    });

    return {
      components,
      widgetStyles,
      sortedWidgets,
      getCellHeight,
      numberOfRows,
    };
  },
});
