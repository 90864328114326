import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withKeys as _withKeys, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-718275b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "input-icon prepend"
}
const _hoisted_3 = ["id", "name", "value", "disabled", "type", "placeholder", "autocomplete", "data-lpignore", "data-form-type"]
const _hoisted_4 = {
  key: 4,
  class: "input-dummy",
  ref: "inputDummy"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 6,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_vue_resizable = _resolveComponent("vue-resizable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container", [
      _ctx.classes,
      _ctx.customClass,
      _ctx.required && 'required',
      { 'input-radio': _ctx.type === 'radio' },
    ]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass({
        active: _ctx.isActive,
        'has-icon': !!_ctx.$slots.prepend,
        'hide-on-focus': _ctx.hideLabelOnFocus,
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      })
        }, [
          _createVNode(_component_TranslatedText, { path: _ctx.label }, null, 8, ["path"])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.prepend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "prepend", { value: _ctx.value }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock("input", {
          key: 2,
          id: _ctx.id,
          name: _ctx.name,
          value: _ctx.value,
          disabled: _ctx.disabled,
          class: _normalizeClass({
        error: !!_ctx.error,
        'message-visible': _ctx.error && !_ctx.isFocussed,
      }),
          type: _ctx.type,
          placeholder: _ctx.placeholderText,
          autocomplete: _ctx.autocomplete ? 'on' : 'off',
          "data-lpignore": !_ctx.autocomplete,
          "data-form-type": !_ctx.autocomplete ? 'other' : '',
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"]))
        }, null, 42, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createBlock(_component_vue_resizable, {
          key: 3,
          disableAttributes: ['l', 't', 'h'],
          active: ['r'],
          "onResize:end": _ctx.resizeEnd
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["resizable-input", {
          'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
          'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
        }])
            }, null, 2)
          ]),
          _: 1
        }, 8, ["onResize:end"]))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "editIcon",
            src: require('@/assets/img/icon-edit.svg'),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleEditClick && _ctx.handleEditClick(...args)))
          }, null, 8, _hoisted_5)
        ], 512))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "input-icon append",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, [
          _renderSlot(_ctx.$slots, "append", { value: _ctx.value }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.error && !_ctx.isFocussed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_TranslatedText, { path: _ctx.error }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}