import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = {
  key: 0,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.options?.label), 1),
    (_ctx.options?.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.error
          }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ]))
}