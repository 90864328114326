import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f33a264"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio" }
const _hoisted_2 = ["id", "name", "value", "disabled", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.value,
      disabled: _ctx.disabled || _ctx.editMode,
      checked: _ctx.value === _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleValueChange && _ctx.handleValueChange(...args))),
      class: _normalizeClass({
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      })
    }, null, 42, _hoisted_2),
    _createElementVNode("label", { for: _ctx.id }, [
      _createVNode(_component_TranslatedText, { path: _ctx.text }, null, 8, ["path"])
    ], 8, _hoisted_3)
  ]))
}