import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72cd4830"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar__inner" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 1,
  class: "fallback"
}
const _hoisted_4 = {
  key: 1,
  class: "avatar__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_StatusIndicator = _resolveComponent("StatusIndicator")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", [_ctx.size, _ctx.variant]])
  }, [
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "avatar__edit",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectProfileImage && _ctx.selectProfileImage(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "pen" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.avatarImage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "image",
            style: _normalizeStyle(_ctx.getAvatarImage),
            role: "img",
            "aria-label": _ctx.alt
          }, null, 12, _hoisted_2))
        : (_ctx.text)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true)
    ]),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, { icon: _ctx.icon }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status)
      ? (_openBlock(), _createBlock(_component_StatusIndicator, {
          key: 2,
          status: true
        }))
      : _createCommentVNode("", true)
  ], 2))
}