import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserView = _resolveComponent("UserView")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_UserView, {
            key: 0,
            user: _ctx.user,
            userIsLoading: _ctx.userIsLoading,
            navItemConfig: _ctx.navItemConfig,
            onRefreshUser: _ctx.getUser
          }, null, 8, ["user", "userIsLoading", "navItemConfig", "onRefreshUser"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}