import httpClient, { ApiResponse } from "./httpClient";

export const getVideoContents = async (references: string[], userId: string, currentRole = 'user'): Promise<ApiResponse<any>> =>
  httpClient.get(`/videocontents/content/${JSON.stringify(references.map(encodeURIComponent))}/${currentRole}/null/${userId}`);

export const postVideoContents = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/videocontents`, body);

export const postVideoUrl = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/videocontents/youtube`, body);

export const postVideoSignOff = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/videocontents/signoff`, body);
