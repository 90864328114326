
import { computed, defineComponent, PropType, ref } from "vue";
import { Address, Reference } from "@/models";
import FormInput from "@/components/form/FormInput.vue";
import Radio from "@/components/form/Radio.vue";
import AddressList from "@/components/user/shared/AddressList.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import { usePermissionProvider } from "@/plugins/permission";

export default defineComponent({
  components: {
    FormInput,
    AddressList,
    DatePicker,
    Radio,
    Checkbox,
  },
  props: {
    reference: {
      type: Object as PropType<Reference>,
    },
    referenceType: {
      type: String,
      required: true,
    },
    mandatoryFields: {
      type: String,
      default: "",
    },
    userReference: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add", "update"],
  setup(props, { emit }) {
    const permissionProvider = usePermissionProvider();

    const handleUpdate = ({ name, value }, subName: string) => {
      emit("update", { name, value, subName });
      if (name === "email") {
        emit("update", { name: "contactInfo", value: "" });
      } else if (name === "contactInfo") {
        emit("update", { name: "email", value: "" });
      }
    };
    const referenceAddress = computed(() =>
      props.reference?.employerReference?.address
        ? [props.reference?.employerReference?.address]
        : []
    );
    const handleUpdateAddress = (address: Address) => {
      emit("update", {
        name: "address",
        value: address,
        subName: "employerReference",
      });
    };
    const handleRemoveAddress = () => {
      emit("update", {
        name: "address",
        value: null,
        subName: "employerReference",
      });
      emit("update", {
        name: "addressId",
        value: null,
        subName: "employerReference",
      });
    };
    const required = (field: string) =>
      props.mandatoryFields?.length === 0 ||
      props.mandatoryFields?.includes(field);
    const disabled = computed(
      () =>
        (!props.userReference &&
          props.reference?.referenceRecord.userCompleted &&
          props.reference?.referenceRecord.approved) ||
        props.reference?.referenceRecord.sent ||
        (!permissionProvider.userHasPermission("users:references:update") &&
          !!permissionProvider.userHasPermission("users:references:create"))
    );

    const handleDateUpdate = ({ name, value }) => {
      emit("update", { name, value: new Date(value).toISOString() });
    };
    const isEmailKnown = ref(
      props.reference?.email.length ||
        (!props.reference?.email && !props.reference?.contactInfo)
        ? "true"
        : "false"
    );

    return {
      required,
      disabled,
      handleUpdate,
      isEmailKnown,
      referenceAddress,
      handleUpdateAddress,
      handleRemoveAddress,
      handleDateUpdate,
    };
  },
});
