<template>
  <div>
    <label :class="{ required }">
      <TranslatedText :path="label" />
    </label>
    <QuillEditor
      class="wysiwyg"
      :class="getClasses"
      :ref="ref"
      :theme="theme"
      @textChange="handleInputChange"
      :content="wysiwygContent"
      :toolbar="toolbarOptions"
      contentType="html"
    />
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const validThemes = ["snow", "bubble"];

export default defineComponent({
  components: { QuillEditor },
  emits: ["inputChange"],
  props: {
    ref: {
      type: String,
      default: "quill",
    },
    label: String,
    theme: {
      type: String,
      default: "snow",
      validator: (val) => validThemes.some((theme) => val === theme),
    },
    name: String,
    value: String,
    required: Boolean,
    disabled: Boolean,
    classes: String,
  },
  setup(props, { emit }) {
    const quill = ref("");
    const handleInputChange = () => {
      let quillValue = quill.value.getContents(0);
      if (quillValue === "<p><br></p>") {
        quillValue = "";
      }
      emit("inputChange", {
        name: props.name,
        value: quillValue,
      });
    };

    const wysiwygContent = ref("");

    const getClasses = computed(() => props.classes ?? "");
    const toolbarOptions = computed(() => [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block"],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["link", "image"],
      ["clean"], // remove formatting button
    ]);

    onMounted(async () => {
      quill.value.setHTML(wysiwygContent.value);
      wysiwygContent.value = props.value;
    });

    watch(props, (newValue) => {
      if (newValue.value !== undefined) {
        const existingContent = quill.value.getHTML();
        wysiwygContent.value = newValue.value;
        if (existingContent !== newValue.value) {
          quill.value.setHTML(wysiwygContent.value);
        }
      }
    });

    return {
      quill,
      wysiwygContent,
      getClasses,
      toolbarOptions,
      handleInputChange,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/_utilities.scss";

label {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 12px;

  @include min-width("xl") {
    font-size: 0.7vw;
  }

  &.has-icon {
    left: 2.5rem;
  }

  &.active {
    top: -9px;
    transition: 0.2s;
    color: var(--base-20);
    background: var(--base-01);
  }

  &.hide-on-focus {
    &.active {
      @include hide-visually;
    }
  }
}
.wysiwyg strong {
  font-weight: bold;
}
.wysiwyg em {
  font-style: italic;
}
</style>
<style lang="scss">
.mini {
  .ql-editor {
    min-height: 100px;
  }
}
.ql-editor {
  min-height: 200px;
}
</style>
