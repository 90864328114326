<template>
  <widget-layout
    title="Your Progress"
    class="dash-progress"
    :minHeight="2"
    :maxHeight="2"
    :maxWidth="4"
    :__menu="menuItems"
    :loading="!data"
    :style="{ '--width': width, '--height': height }"
  >
    <div class="widget-main">
      <div class="content">
        <div class="bar-container">
          <table
            cellpadding="0"
            cellspacing="0"
            v-if="data"
            class="bar-charts"
            :class="{ visible: chartType == 'bar' }"
            :style="{ '--numEntries': Object.keys(data).length }"
          >
            <tr>
              <td class="label"></td>
              <td><h6>Individual Hotspot Completion</h6></td>
            </tr>
            <tr v-for="(hsName, idx) in Object.keys(data)" :key="idx">
              <td class="label">{{ hsName }}</td>
              <td>
                <div class="bar" :style="getBarStyle(idx)" />
                <span class="percent" v-if="typeof data[hsName] === 'number'"
                  >{{ Math.floor(data[hsName]) }}%</span
                >
              </td>
            </tr>
          </table>
        </div>
        <div v-if="data" class="radial-container">
          <chart
            class="radial"
            :valuesOuter="chartValuesOuter"
            :valuesInner="chartValuesInner"
          >
            <div class="percent">{{ Math.floor(total) }}%</div>
            <p>completed</p>
          </chart>
        </div>
      </div>

      <div class="link">
        <p class="trackLink">
          <router-link to="/track">View your track &gt;</router-link>
        </p>
      </div>
    </div>
  </widget-layout>
</template>
<script>
import Chart from "../Chart.vue";
import WidgetLayout from "../WidgetLayout.vue";
import { ref, computed, defineComponent } from "vue";

export default defineComponent({
  name: "DashProgress",
  components: {
    Chart,
    WidgetLayout,
  },
  props: {
    width: Number,
    height: Number,
    userData: {
      default: {},
    },
    data: Object,
  },
  setup(props, { emit }) {
    const types = ref({
      bar: "Bars",
      legend: "Legend",
    });

    const total = computed(() => props.data.progressPercentage);

    const chartType = computed(() => props.userData.type || "bar");

    const menuItems = computed(() =>
      Object.keys(types.value).map((type) => {
        const check = (cond) => (cond ? "☒" : "☐");
        return {
          caption: check(chartType.value === type) + "   " + types.value[type],
          action: () => emit("userdata", { type }),
        };
      })
    );

    const chartValuesOuter = computed(() => {
      return [
        {
          size: total.value,
        },
      ];
    });

    const chartValuesInner = computed(() => {
      const hotspots = props.data.hotspots;
      const hotspotKeys = Object.keys(hotspots);
      return Object.keys(hotspots).map((k, i) => {
        const progress = hotspots[k];
        return {
          size: 1 / hotspotKeys.length,
          opacity: `${progress}`,
        };
      });
    });

    const getBarStyle = (idx) => {
      const keys = Object.keys(props.data.hotspots);
      const key = keys[idx];
      const progress = props.data.hotspots[key];
      return {
        opacity: `${progress}`,
        width: `calc(${progress * 100 + "%"} - 2rem)`,
      };
    };

    return {
      types,
      total,
      chartType,
      menuItems,
      chartValuesOuter,
      chartValuesInner,
      getBarStyle,
    };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";
.dash-progress {
  font-size: 0.8em;
  overflow: hidden;
  .widget-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px 12px 0 12px;
    .content {
      display: flex;
      height: 100%;
      .radial-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @include min-width("md") {
          width: 60%;
        }
        transition: 200ms 70ms all ease-in-out;
        --size: 24em;
        .radial {
          position: relative;

          animation: appearRotate 300ms 1 linear;
          .percent {
            font-size: 4em;
            letter-spacing: initial;
            font-weight: 600;
            line-height: 30px;
            @include min-width("lg") {
              font-size: 2em;
            }
            @include min-width("xl") {
              font-size: 3em;
              line-height: 34px;
            }
          }
          p {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.3em;
            line-height: 1.8em;
            font-family: "Poppins";
            padding: 0;
            margin: 0;
            @include min-width("lg") {
              font-size: 0.8em;
            }
            @include min-width("xl") {
              font-size: 1em;
              line-height: 2em;
            }
          }
        }
        @include min-width("lg") {
          --size: 12em;
        }
        @include min-width("xl") {
          --size: 16em;
        }
      }
    }
    .link {
      display: flex;
      justify-content: flex-end;
      padding: 4px 0;
      a {
        text-decoration: underline;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
.bar-outer {
  width: 100%;
  margin: 0.25rem auto;
  height: 1rem;
}
.bar-inner {
  height: 100%;
}

.bar-container {
  @include hide-visually();
  @include min-width("md") {
    @include show-visually();
    position: relative;
    height: 100%;
    width: 100%;
    padding-right: 10%;
    border-right: 1px solid #ccc;
    display: flex;
    transition: 200ms 70ms all ease-in-out;
    h6 {
      font-weight: 400;
      font-size: 8px;
      line-height: 1rem;
      margin: 1em 0 0.5em 0;
    }
    overflow: hidden;
  }
}

table.bar-charts {
  width: 100%;
  line-height: 1em;
  transition: 200ms 70ms all ease-in-out;
  tr {
    &:first-child td:first-child {
      border: none;
    }
    &:last-child td:last-child {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.25em;
    }
    td:first-child {
      text-align: right;
      font-size: 9px;
      font-weight: 100;
      padding-right: 0.5em;
      border-right: 1px #ccc solid;
    }
    td {
      position: relative;
      padding: 0;
    }
  }
  .bar {
    display: inline-block;
    height: 1.3em;
    margin-left: 0.2em;
    transform: translateY(25%);
  }
  .percent {
    vertical-align: middle;
    display: inline-block;
    font-size: 8px;
    margin-left: 0.5em;
    font-weight: initial;
  }
}
</style>
