
import {
  defineComponent,
  inject,
  ComputedRef,
  ref,
  reactive,
  computed,
} from "vue";
import { useStore } from "vuex";

import Button from "@/components/Button.vue";
import FormInput from "@/components/form/FormInput.vue";
import ShadowContainer from "@/components/ui/ShadowContainer.vue";

import { ValidationArgs } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

import { sendPasswordReset } from "@/api/auth.api";
import Form from "@/components/Form.vue";
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import { Theme } from "@/models/Style";

export default defineComponent({
  components: {
    UnauthenticatedLayout,
    Button,
    FormInput,
    ShadowContainer,
    Form,
  },
  setup() {
    const store = useStore();
    const theme = inject<ComputedRef<Theme>>("theme");

    const getVariant = computed(() => {
      return theme ? "" : "unbranded";
    });

    const model = reactive({
      email: "",
    });
    const errors = reactive<{ [K: string]: string }>({});
    const buttonDisabled = ref(false);

    const rules: ValidationArgs = {
      email: {
        required: helpers.withMessage(
          "Please enter a valid email address",
          required
        ),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const logoUrl = computed(() => {
      if (theme) {
        if (theme.value.logo.signin) {
          return theme.value.logo.signin.url;
        } else if (theme.value.logo.app) {
          return theme.value.logo.app.url;
        }
      }
      return null;
    });

    const handleSubmit = () => {
      buttonDisabled.value = true;
      sendPasswordReset(model.email, store.getters.currentTenant.name);
      store.dispatch("setNotificationType", "success");
      store.dispatch("setNotificationContent", {
        title: "",
        body: "If your email address exists in our database, you'll shortly receive a password reset e-mail",
      });
      store.dispatch("showNotification");
      setTimeout(() => store.dispatch("hideNotification"), 8000);
    };

    return {
      model,
      errors,
      rules,
      logoUrl,
      buttonDisabled,
      handleSubmit,
      getVariant,
    };
  },
});
