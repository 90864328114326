
import { helpers, required } from "@vuelidate/validators";
import { defineComponent, computed, readonly, PropType, ref, watch } from "vue";
import { useStore } from "vuex";
import { UserDetails } from "@/models";
import { FormQuestion, Gender, Language } from "@/interfaces/domain";
import DatePicker from "@/components/form/DatePicker.vue";
import FormInput from "@/components/form/FormInput.vue";
import Form from "@/components/Form.vue";
import Select from "@/components/form/Select.vue";
import FormQuestions from "@/components/form/FormQuestions.vue";
import Tags from "@/components/user/tags/Tags.vue";
import { Schemas, useSchema } from "@/plugins/schema";
import { useLanguage } from "@/composables";
import FormQuestionText from "@/components/form/FormQuestionText.vue";

export default defineComponent({
  components: {
    FormInput,
    DatePicker,
    Select,
    Form,
    Tags,
    FormQuestions,
    FormQuestionText,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserDetails>,
      required: true,
    },
    genders: {
      type: Array as PropType<Array<Gender>>,
      required: true,
    },
    languages: {
      type: Array as PropType<Array<Language>>,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    isValid: {
      type: Boolean,
    },
    submitCheck: {
      type: Boolean,
    },
    formQuestions: {
      type: Array as PropType<Array<FormQuestion>>,
      default: () => [],
    },
    userResponses: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    readonly: Boolean,
    hiddenUserFields: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    additionalFields: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    formSchemaChanges: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    config: Object,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "update:modelValue",
    "update:errors",
    "update:isValid",
    "update:submitCheck",
    "doChange",
    "selectChange",
    "choiceChange",
    "updateAdditionalField",
  ],
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.UserDetails) as any;
    const ignoredFormFields = ["firstName", "surname", "middleName"];
    const maxLineLength = 35;

    const formSchemaChanges = ref(props.formSchemaChanges);
    const allFormQuestions = ref();

    const checkChanges = (changes, fieldId) => {
      if (Array.isArray(changes) && changes.length) {
        changes.forEach((c: string) => {
          switch (c) {
            case "required":
              if (schema[fieldId]) {
                schema[fieldId].required = helpers.withMessage(
                  "validation.generic.missing",
                  required
                );
              } else {
                schema[fieldId] = {
                  required: helpers.withMessage(
                    "validation.generic.missing",
                    required
                  ),
                };
              }
              break;
          }
        });
      }
    };

    if (formSchemaChanges.value?.UserDetails) {
      for (let f in formSchemaChanges.value.UserDetails) {
        const changes: string[] = formSchemaChanges.value.UserDetails[f];
        checkChanges(changes, f);
      }
    }

    watch(props, () => {
      // Only add in this validation if we're not an admin!
      if (props?.formQuestions && !props.config?.admin) {
        allFormQuestions.value = props.formQuestions;

        const filteredQuestions = allFormQuestions.value.filter(
          (fq) => !ignoredFormFields.includes(fq.fieldReference as string)
        );
        filteredQuestions.forEach((q) => {
          if (q?.validators.length) {
            const changes = q.validators
              .filter((v) => v.validatorType === "required")
              .map((v) => v.validatorType);
            checkChanges(changes, q.id);
          }
        });
      }
    });

    const dobNotRequired =
      store.getters.featureConfigs.find((fc) => fc.feature === "dobNotRequired")
        ?.config ?? false;
    if (dobNotRequired) {
      delete schema.dob;
    }

    const handleTagsChanged = (tags) => {
      model.value.tags = tags;
      valid.value = Object.keys(errorModel.value).length === 0;
    };

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    const errorModel = computed({
      get: () => props.errors,
      set: (value) => {
        if (props.formQuestions && props.formQuestions.length) {
          props.formQuestions.forEach((q) => {
            if (typeof value === "object" && value.length) {
              const hasError = value.find((e) => {
                return q?.fieldReference && q.fieldReference === e.$property;
              });
              if (hasError && hasError?.$message) {
                errorsRef.value[q.id] = translateText(hasError.$message);
              }
            }
          });
        }
        return emit("update:errors", value);
      },
    });
    const valid = computed({
      get: () => props.isValid,
      set: (value) => emit("update:isValid", value),
    });
    const reValidate = computed({
      get: () => props.submitCheck,
      set: (value) => emit("update:submitCheck", value),
    });

    const titles = readonly([
      "Mr",
      "Mrs",
      "Miss",
      "Ms",
      "Mx",
      "Sir",
      "Dr",
      "Cllr",
      "Lord",
      "Lady",
    ]);
    const titleSelectOptions = computed(() =>
      titles.map((title) => ({ value: title, text: title }))
    );
    const genderSelectOptions = computed(() =>
      props.genders.map((gender) => ({
        value: gender.id,
        text: "models.user.gender-" + translateText(gender.name.toLowerCase()),
      }))
    );
    const languageSelectOptions = computed(() =>
      props.languages.map((language) => ({
        value: language.id,
        text: language.name,
      }))
    );

    const nationalities = readonly([
      "British",
      "Afghan",
      "Albanian",
      "Algerian",
      "American",
      "Andorran",
      "Angolan",
      "Antiguans",
      "Argentinean",
      "Armenian",
      "Australian",
      "Austrian",
      "Azerbaijani",
      "Bahamian",
      "Bahraini",
      "Bangladeshi",
      "Barbadian",
      "Barbudans",
      "Batswana",
      "Belarusian",
      "Belgian",
      "Belizean",
      "Beninese",
      "Bhutanese",
      "Bolivian",
      "Bosnian",
      "Brazilian",
      "British",
      "Bruneian",
      "Bulgarian",
      "Burkinabe",
      "Burmese",
      "Burundian",
      "Cambodian",
      "Cameroonian",
      "Canadian",
      "Cape Verdean",
      "Central African",
      "Chadian",
      "Chilean",
      "Chinese",
      "Colombian",
      "Comoran",
      "Congolese",
      "Costa Rican",
      "Croatian",
      "Cuban",
      "Cypriot",
      "Czech",
      "Danish",
      "Djibouti",
      "Dominican",
      "Dutch",
      "East Timorese",
      "Ecuadorean",
      "Egyptian",
      "Emirian",
      "Equatorial Guinean",
      "Eritrean",
      "Estonian",
      "Ethiopian",
      "Fijian",
      "Filipino",
      "Finnish",
      "French",
      "Gabonese",
      "Gambian",
      "Georgian",
      "German",
      "Ghanaian",
      "Greek",
      "Grenadian",
      "Guatemalan",
      "Guinea-Bissauan",
      "Guinean",
      "Guyanese",
      "Haitian",
      "Herzegovinian",
      "Honduran",
      "Hungarian",
      "Icelander",
      "Indian",
      "Indonesian",
      "Iranian",
      "Iraqi",
      "Irish",
      "Israeli",
      "Italian",
      "Ivorian",
      "Jamaican",
      "Japanese",
      "Jordanian",
      "Kazakhstani",
      "Kenyan",
      "Kittian and Nevisian",
      "Kuwaiti",
      "Kyrgyz",
      "Laotian",
      "Latvian",
      "Lebanese",
      "Liberian",
      "Libyan",
      "Liechtensteiner",
      "Lithuanian",
      "Luxembourger",
      "Macedonian",
      "Malagasy",
      "Malawian",
      "Malaysian",
      "Maldivan",
      "Malian",
      "Maltese",
      "Marshallese",
      "Mauritanian",
      "Mauritian",
      "Mexican",
      "Micronesian",
      "Moldovan",
      "Monacan",
      "Mongolian",
      "Moroccan",
      "Mosotho",
      "Motswana",
      "Mozambican",
      "Namibian",
      "Nauruan",
      "Nepalese",
      "New Zealander",
      "Ni-Vanuatu",
      "Nicaraguan",
      "Nigerien",
      "Nigerian",
      "North Korean",
      "Northern Irish",
      "Norwegian",
      "Omani",
      "Pakistani",
      "Palauan",
      "Panamanian",
      "Papua New Guinean",
      "Paraguayan",
      "Peruvian",
      "Polish",
      "Portuguese",
      "Qatari",
      "Romanian",
      "Russian",
      "Rwandan",
      "Saint Lucian",
      "Salvadoran",
      "Samoan",
      "San Marinese",
      "Sao Tomean",
      "Saudi",
      "Scottish",
      "Senegalese",
      "Serbian",
      "Seychellois",
      "Sierra Leonean",
      "Singaporean",
      "Slovakian",
      "Slovenian",
      "Solomon Islander",
      "Somali",
      "South African",
      "South Korean",
      "Spanish",
      "Sri Lankan",
      "Sudanese",
      "Surinamer",
      "Swazi",
      "Swedish",
      "Swiss",
      "Syrian",
      "Taiwanese",
      "Tajik",
      "Tanzanian",
      "Thai",
      "Togolese",
      "Tongan",
      "Trinidadian or Tobagonian",
      "Tunisian",
      "Turkish",
      "Tuvaluan",
      "Ugandan",
      "Ukrainian",
      "Uruguayan",
      "Uzbekistani",
      "Venezuelan",
      "Vietnamese",
      "Welsh",
      "Yemenite",
      "Zambian",
      "Zimbabwean",
    ]);
    const nationalityOptions = computed(() =>
      nationalities.map((nationality) => ({
        value: nationality,
        text: nationality,
      }))
    );

    const customClass = (fieldName) => {
      if (
        schema[fieldName] &&
        schema[fieldName].required &&
        (!props?.config?.isMe || fieldName != "email")
      ) {
        return "required";
      }
      return "";
    };

    const errorsRef = ref<any>({});

    const { translateText } = useLanguage();

    const getFormQuestion = (reference: string) =>
      props.formQuestions.find((fq) => fq.fieldReference === reference);

    const handleDoChange = ({ name, value }) => {
      model.value[name] = value;
      emit("doChange", { name, value });
    };

    const handleAdditionalFieldChange = ({ name, value }) => {
      emit("updateAdditionalField", { type: "personal", name, value });
    };

    const handleSelectChange = (event) => {
      emit("selectChange", event);
    };

    const handleChoiceChange = (event) => {
      const name = event["target"].name;
      const value = event["target"].value;
      model.value[name] = value;

      emit("choiceChange", event);
    };

    return {
      errorsRef,
      model,
      schema,
      valid,
      reValidate,
      errorModel,
      titleSelectOptions,
      genderSelectOptions,
      languageSelectOptions,
      nationalityOptions,
      maxLineLength,
      ignoredFormFields,
      customClass,
      handleTagsChanged,
      getFormQuestion,
      handleDoChange,
      handleSelectChange,
      handleChoiceChange,
      handleAdditionalFieldChange,
    };
  },
});
