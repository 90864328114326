import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserView = _resolveComponent("UserView")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_UserView, {
        user: _ctx.user,
        navItemConfig: _ctx.navItemConfig,
        isNewUser: ""
      }, null, 8, ["user", "navItemConfig"])
    ]),
    _: 1
  }))
}