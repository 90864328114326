
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  watch,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { Action, Hotspot } from "@/models";
import UserReferenceList from "@/components/user/shared/UserReferenceList.vue";
import { Reference } from "@/models";
import Checkbox from "@/components/form/Checkbox.vue";
import Button from "@/components/Button.vue";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";
import {
  signAuthorityToDisclose,
  getAuthorityToDisclose,
} from "@/api/user.api";
import { HttpError } from "@/api/httpClient";
import TranslatedText from "@/components/TranslatedText.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import { getFormFeatureConfigs } from "@/api/form-questions.api";
import Modal from "@/components/Modal.vue";
import { useBreakpoints, useNotification, useLanguage } from "@/composables";
import Signature from "@/components/Signature.vue";
import { getActionProgressForUser } from "@/api/actions.api";

export default defineComponent({
  components: {
    UserReferenceList,
    Checkbox,
    Button,
    TranslatedText,
    ActionHeader,
    Modal,
    Declaration,
    Signature,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    let tenant = store.getters.currentTenant;
    const { isSmallScreen } = useBreakpoints();

    const { createDateString } = useLanguage();

    const { inPageNotification } = useNotification();

    const currentUser = computed(() => store.getters.currentUser);
    const declarationWet = reactive<{ signature: string }>({ signature: "" });
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const isValid = ref(false);
    const model = ref<Array<Reference>>([]);
    const authoritytoDiscloseCompleted = ref(false);
    const actionCompleted = ref(false);

    const getReferences = async () => {
      const actionResponse = await getActionProgressForUser(props.action.id);
      actionCompleted.value = actionResponse?.data?.payload?.result?.completed;
      const response = await getAuthorityToDisclose();
      const user = response.data.payload.result;
      authoritytoDiscloseCompleted.value = user?.authorityToDisclose || false;
      isMounted.value = true;
      if (model.value && currentUser.value && currentUser.value.id) {
        model.value = await Reference.get();
        model.value = model.value.map((reference: any) => {
          return {
            ...reference,
            ...{
              dateFrom: reference.dateFrom
                ? reference.dateFrom
                : createDateString(
                    reference.yearFrom,
                    reference.monthFrom,
                    reference.dayFrom
                  ),
              dateTo: reference.presentDay
                ? new Date()
                : reference.dateTo
                ? reference.dateTo
                : createDateString(
                    reference.yearTo,
                    reference.monthTo,
                    reference.dayTo
                  ),
            },
          };
        });
      }
    };

    const handleDeleteReference = async (reference) => {
      if (reference) {
        await reference.delete();
        getReferences();
      }
    };

    const handleSaveReference = async ({ reference }) => {
      if (reference.address) {
        reference.employerReference.address = reference.address;
      }
      if (reference.company) {
        reference.employerReference.company = reference.company;
      }
      if (!reference.id) {
        if (reference.employerReference) {
          reference.order = employerReferences.value.length;
        } else {
          reference.order = characterReferences.value.length;
        }
      }
      await reference.save("save");
      const actionResponse = await getActionProgressForUser(props.action.id);
      if (actionResponse?.data?.payload?.result?.completed) {
        emit("action-complete", {
          progressObj: {
            actionProgress: actionResponse?.data?.payload?.result,
          },
          manualNav: true,
        });
      }
      getReferences();
    };

    const employerReferences = computed(() =>
      model.value.filter((reference: any) => reference.employerReference)
    );
    const characterReferences = computed(() =>
      model.value.filter((reference: any) => reference.characterReference)
    );

    const authorityToDisclose = ref(false);
    const signAuthorityToDiscloseHandler = async () => {
      try {
        await signAuthorityToDisclose();
        getReferences();
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification(
            "references.authority-to-disclose-sign.error",
            httpError.message
          );
        }
      }
    };

    onMounted(() => {
      getReferences();
      checkSignatureRequirements();
    });
    watch(props, () => getReferences());

    const wetSignature = ref(false);

    const checkSignatureRequirements = async () => {
      const formFeatureConfigs = await getFormFeatureConfigs();
      formFeatureConfigs.data.payload.result.forEach((featureConfig) => {
        switch (featureConfig.feature) {
          case "signature-type":
            wetSignature.value = featureConfig.condition === "wet";
            break;
        }
      });
    };

    const showSign = ref(false);
    const handleSignClick = () => (showSign.value = !showSign.value);

    const showModal = computed(() => showSign.value);
    const handleModalHide = () => {
      declarationWet.signature = "";
      declaration.consent = false;

      declaration.dob = "";

      showSign.value = false;
    };
    const isMounted = ref(false);

    return {
      isValid,
      model,
      showModal,
      handleModalHide,
      employerReferences,
      declaration,
      declarationWet,
      characterReferences,
      handleSaveReference,
      handleSignClick,
      authoritytoDiscloseCompleted,
      authorityToDisclose,
      signAuthorityToDiscloseHandler,
      tenant,
      wetSignature,
      isMounted,
      handleDeleteReference,
      actionCompleted,
    };
  },
});
