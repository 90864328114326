
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "DropdownTableRow",
  props: {
    isOpen: Boolean,
    hasError: Boolean,
    isDeactivated: Boolean,
    isActive: Boolean,
    isSelected: Boolean,
    disableDropdown: Boolean,
  },
  emits: ["click"],
  setup(props, { emit }) {
    const handleClick = () => {
      emit("click");
    };

    const rowIcon = computed(() => {
      if (props.isOpen) {
        return "chevron-down";
      }

      return "chevron-right";
    });

    return {
      rowIcon,
      handleClick,
    };
  },
});
