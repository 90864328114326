
import { defineComponent, ref, onMounted, watch, PropType } from "vue";
import { Action, Hotspot, ActionProgress } from "@/models";
import { InfoContent } from "@/models/InfoContent";
import { useStore } from "vuex";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";

export default defineComponent({
  components: {
    ActionHeader,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const content = ref<InfoContent>();
    const getInfoContent = async () => {
      const contents = await InfoContent.getForReferences([
        props.action.infoReference as string,
      ]);
      content.value = contents[0];
      if (!props.actionProgress?.completed) {
        const currentUser = store.getters.currentUser;
        const progressObj = await props.action.completeAction(currentUser.id);
        emit("action-complete", { progressObj, manualNav: true });
      }
    };

    onMounted(() => getInfoContent());
    watch(props, () => getInfoContent());

    return {
      content,
    };
  },
});
