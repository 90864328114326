import { PaginatedResult, PaginationDetails } from "@/interfaces/Pagination";
import { Model } from "./Model";

export class Activity extends Model<Activity> {
  private static readonly path: string = "/activity";

  public userId: string;
  public activityTypeId: string;
  public notificationId: string;
  public notification: { notificationTypeId: string } | null;
  public actionedAt: string;
  public actionedBy: string;

  constructor({
    id = "",
    userId = "",
    activityTypeId = "",
    notificationId = "",
    notification = null,
    actionedAt = "",
    actionedBy = "",
  } = {}) {
    super(id, Activity, Activity.path);
    this.userId = userId;
    this.activityTypeId = activityTypeId;
    this.notificationId = notificationId;
    this.notification = notification;
    this.actionedAt = actionedAt;
    this.actionedBy = actionedBy;
  }

  public static async get(
    userId: string,
    pagination: PaginationDetails,
    sortOrder: string,
    filters?: ActivityFilters
  ): Promise<PaginatedResult<Activity>> {
    return await Model.getPaginated<Activity, ActivityFilters>(
      userId,
      Activity.path,
      Activity,
      pagination.pageNumber,
      pagination.pageSize,
      "actionedAt",
      sortOrder,
      filters
    );
  }
}

export interface ActivityFilters {
  from?: string;
  to?: string;
}
