import httpClient from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getReviewFeatureConfigs = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/reviews/feature-configs`);

export const getReviewTemplates = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/reviews/templates`);

export const postReviewFeatureConfigs = async (body: {
  frequency: string;
  probationDuration: string;
  probation: string;
  numberOfReviews: string;
}): Promise<AxiosResponse<any>> =>
  await httpClient.post(`/reviews/feature-configs`, body);