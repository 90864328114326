
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { useLoading } from "@/plugins/loading";
import { Action, Hotspot, ActionProgress } from "@/models";
import { format, parseISO } from "date-fns";

import InfoAction from "@/components/hotspot/action/view/Info.vue";
import TicksAction from "@/components/hotspot/action/view/Ticks.vue";
import LinkAction from "@/components/hotspot/action/view/Link.vue";
import DocumentAction from "@/components/hotspot/action/view/Document.vue";
import VideoAction from "@/components/hotspot/action/view/Video.vue";
import FormAction from "@/components/hotspot/action/view/Form.vue";
import NewstarterAction from "@/components/hotspot/action/view/NewStarterForm.vue";
import ReferencesAction from "@/components/hotspot/action/view/References.vue";
import ContactGroup from "@/components/hotspot/action/view/ContactGroup.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/form/Select.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import Modal from "@/components/Modal.vue";
import Signature from "@/components/Signature.vue";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";

import { getUsersBasic } from "@/api/users.api";
import { getAllGroups } from "@/api/groups.api";
import { HttpError } from "@/api/httpClient";
import { getFormFeatureConfigs } from "@/api/form-questions.api";

import ActionHeader from "@/components/hotspot/action/components/Header.vue";

import { Schemas, useSchema } from "@/plugins/schema";
import { useModal } from "@/plugins/modal";
import useValidate from "@vuelidate/core";
import { useNotification, useLanguage } from "@/composables";
import { TextItem } from "@/interfaces";

export default defineComponent({
  components: {
    ActionHeader,
    Select,
    Button,
    Form,
    FormInput,
    Wysiwyg,
    Declaration,
    Modal,
    Signature,
    InfoAction,
    LinkAction,
    TicksAction,
    DocumentAction,
    VideoAction,
    FormAction,
    NewstarterAction,
    ReferencesAction,
    ContactGroup,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.InfoContent);
    const showLoader = useLoading();
    const action = toRef(props, "action");
    const isLoading = ref<boolean>(false);
    const showDetails = ref<boolean>(false);
    const showContent = ref<boolean>(true);
    const signatureEnabled = ref(true);
    const dobSignatureRequired = ref(false);
    const wetSignature = ref(true);
    const readyToSign = ref(true);
    const placeholderUrl = ref("");
    const showSign = ref(false);
    const showSignModal = ref(false);
    const declarationIsValid = ref(false);

    const actions = computed(() => props.hotspot?.actions ?? []);
    const showModal = useModal();

    const { translateText } = useLanguage();

    const editMode = computed(() => store.getters.track.editMode);

    const actionHotspotHeading = ref<string>(
      translateText("action.edit.title")
    );
    const actionHotspotDescription = ref<string>(
      translateText("action.edit.description")
    );

    const { inPageNotification } = useNotification();

    const levelSelectOptions = computed(() => store.getters.allAudienceOptions);
    const typeSelectOptions = computed(() => store.getters.allActionTypes);

    const model = ref<any>({
      title: "",
      description: "",
      sideMenuText: "",
      signature: "",
      content: null,
    });
    const v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      signature: "",
      content: "",
    });

    const selectPlaceholder = ref({
      text: "form.placeholder.select",
      value: "",
    });

    const signatureItems = ref([
      { text: "references.yes", value: "yes" },
      { text: "references.no", value: "no" },
    ]);

    const hotspotDescription = (type) => {
      const textItems = props.hotspot.textItems;
      return textItems.find((ti) => ti.purpose === type)?.data ?? "";
    };

    const handleSignClick = () => {
      if (wetSignature.value) {
        showSignModal.value = !showSignModal.value;
      } else {
        showSign.value = !showSign.value;
      }
    };

    const handleSignSubmit = () => {
      completeActive.value = true;
    };

    const handleModalHide = () => {
      showSignModal.value = false;
    };

    const handleComponentMounted = (loaded = true) => {
      componentMounted.value = loaded;
    };

    const getComponentKey = computed(() => {
      return `${action.value.id}`;
    });

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      isValid.value = !v$.value.$error;
    };

    const currentUser = store.getters.currentUser;

    const users = ref<any[]>([]);
    const groups = ref<any[]>([]);

    const selectedUser = ref<string>("");
    const selectedGroup = ref<string>("");
    const completeActive = ref<boolean>(false);
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const handleComponentComplete = async (success) => {
      try {
        if (success) {
          let manualNav = false;
          switch (props.action.actionType) {
            case "video":
            case "document":
            case "info":
            case "references":
            case "link":
              manualNav = true;
              break;
          }
          let response;
          if (props?.actionProgress && !props.actionProgress?.completed) {
            if (!signatureEnabled.value) {
              response = await props.action.completeAction(currentUser.id);
            } else if (!wetSignature.value) {
              response = await props.action.completeAction(
                currentUser.id,
                declaration?.dob,
                declaration?.consent
              );
            } else {
              response = await props.action.completeActionWet(
                currentUser.id,
                declarationWet.signature
              );
            }
          }
          emit("action-complete", {
            progressObj: response,
            manualNav: manualNav,
          });
        }
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("declaration.sign.error", httpError.message);
        }
      }
    };

    const handleComponentUpdated = async (obj) => {
      if (obj["readyToSign"] !== undefined) {
        readyToSign.value = obj?.readyToSign;
        if (readyToSign.value && !signatureEnabled.value) {
          await handleComponentComplete(true);
        }
      }
      if (obj["signatureEnabled"] !== undefined) {
        signatureEnabled.value = obj?.signatureEnabled;
      }
    };

    const showNewModal = ref<boolean>(false);

    const completionDateString = computed(() =>
      props.actionProgress?.completionDate
        ? format(
            parseISO(props.actionProgress.completionDate),
            "dd/MM/yyyy HH:mm:SS"
          )
        : ""
    );

    const currentComponent = computed(() => {
      var at = props.action.actionType;
      at = at
        .toLowerCase()
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        })
        .replace(/([-_])*/g, "");
      return `${at}Action`;
    });
    const textItems = ref<any[]>([]);

    const toggleJaccordion = (t) => {
      if (t === "details") {
        showDetails.value = !showDetails.value;
      } else if (t === "content") {
        showContent.value = !showContent.value;
      }
    };

    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;

    const populateActionData = () => {
      textItems.value = props.action.textItems;
      model.value.title = findTextItem("title", textItems.value);
      model.value.sideMenuText = findTextItem("side-menu", textItems.value);
      model.value.description = findTextItem("description", textItems.value);

      model.value.signature = props.action.signatureRequired ? "Yes" : "No";
    };

    const getGroups = async () => {
      const response = await getAllGroups();
      groups.value = response?.data?.payload?.results
        ? response.data.payload.results.map((group) => ({
            value: group.id,
            text: group.textItems.length
              ? findTextItem("group-name", group.textItems)
              : group.shortName,
          }))
        : [];
    };

    const getAllUsers = async () => {
      const response = await getUsersBasic();
      users.value = response?.data?.payload?.results
        ? response.data.payload.results.map((user) => ({
            value: user.id,
            text: user.firstName + " " + user.surname,
            preferredLanguageId: user.preferredLanguageId,
          }))
        : [];
    };

    const getFeatureConfigs = async () => {
      if (!props.viewOnly) {
        const formFeatureConfigs = await getFormFeatureConfigs();
        formFeatureConfigs.data.payload.result.forEach((featureConfig) => {
          switch (featureConfig.feature) {
            case "sign-off":
              signatureEnabled.value = featureConfig.config;
              if (
                signatureEnabled.value &&
                !props.action.signatureRequired //&&
                //!videoData.value?.signOffEnabled
              ) {
                signatureEnabled.value = false;
              }
              break;
            case "signature-type":
              dobSignatureRequired.value =
                featureConfig.condition === "date-of-birth";
              wetSignature.value = featureConfig.condition === "wet";
              break;
          }
        });
      }
    };

    const initData = async () => {
      if (props.action.level === "user") {
        await getAllUsers();
        if (selectedUser.value === "") {
          selectedUser.value = users.value.length ? users.value[0].value : "";
        }
      } else if (props.action.level === "group") {
        await getGroups();
        if (selectedGroup.value === "") {
          selectedGroup.value = groups.value.length
            ? groups.value[0].value
            : "";
        }
      }
    };

    const actionProgressItems = ref<Array<ActionProgress>>([]);
    const currentActionProgress = computed(() =>
      actionProgressItems.value.find((p) => p.actionId === action.value?.id)
    );

    const isMounted = ref(false);
    const componentMounted = ref(false);
    onMounted(async () => {
      await getFeatureConfigs();
      initData();
      populateActionData();
      isMounted.value = true;
    });

    watch(action, async () => {
      componentMounted.value = false;
      isValid.value = false;
      initData();
      populateActionData();
    });

    const isValid = ref<boolean>(false);

    const declarationWet = reactive<{ signature: string }>({
      signature: "",
    });

    return {
      currentComponent,
      isValid,
      schema,
      errors,
      declaration,
      declarationIsValid,
      selectedUser,
      selectedGroup,
      users,
      groups,
      isLoading,
      typeSelectOptions,
      levelSelectOptions,
      signatureItems,
      selectPlaceholder,
      model,
      actionHotspotHeading,
      actionHotspotDescription,
      showNewModal,
      completeActive,
      showDetails,
      showContent,
      isMounted,
      componentMounted,
      showSign,
      showSignModal,
      declarationWet,
      getComponentKey,
      handleComponentMounted,
      toggleJaccordion,
      handleComponentComplete,
      handleComponentUpdated,
      handleSignClick,
      handleSignSubmit,
      handleModalHide,
      translateText,
      completionDateString,
      currentActionProgress,
      signatureEnabled,
      dobSignatureRequired,
      wetSignature,
      readyToSign,
      placeholderUrl,
      hotspotDescription,
    };
  },
});
