
import { useLanguage } from "@/composables";
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
} from "@vue/runtime-core";

import { TextItem } from "@/interfaces/domain";

export default defineComponent({
  name: "Text",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    "change",
    "enter",
    "blur",
    "click-append-icon",
    "edit",
    "choiceChange",
  ],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const handleInput = (event: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("change", { name, value });
    };

    const optionChoices = ref<Array<any>>([]);
    const isFocussed = ref<boolean>(false);

    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = (event: Event) => {
      isFocussed.value = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("blur", { name, value });
    };

    const clickAppendIcon = () => emit("click-append-icon");

    const enter = () => emit("enter");
    const handleChoiceChange = (event) => emit("choiceChange", event);

    const placeholderText = computed(() =>
      getValueForString(props.options?.placeholder ?? "")
    );

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };

    watch(props, () => {
      if (props.options?.choices) {
        optionChoices.value = props.options?.choices;
      }
    });

    onMounted(async () => {
      if (props.options?.choices) {
        optionChoices.value = props.options?.choices;
      }
    });

    return {
      isFocussed,
      placeholderText,
      optionChoices,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      handleChoiceChange,
      clickAppendIcon,
      getTextItem,
    };
  },
});
