
import {
  defineComponent,
  PropType,
  ref,
  computed,
  readonly,
} from "@vue/runtime-core";
import countryCodeData from "@/data/callingCodes.json";

interface DropdownItem {
  value: string;
  text: string;
  image: string;
}

export interface TelephoneNumber {
  countryCode: string;
  number: string;
}

export default defineComponent({
  name: "TelephoneInput",
  props: {
    id: String,
    name: String,
    label: String,
    value: {
      type: Object as PropType<TelephoneNumber>,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const open = ref(false);

    const selectedItem = computed(() => {
      return (
        dropdownItems.value.find(
          (item) => item.value === props.value.countryCode
        ) || null
      );
    });

    const flagMap = readonly({
      GB: "uk",
      US: "usa",
      FR: "france",
      DE: "germany",
      SE: "sweden",
      IT: "italy",
      GR: "greece",
      CN: "china",
    });

    const dropdownItems = ref<Array<DropdownItem>>(
      Object.keys(countryCodeData).map((key) => {
        return {
          value: countryCodeData[key],
          text: `+${countryCodeData[key]}`,
          image: require(`@/assets/img/flags/${flagMap[key]}.svg`),
        };
      })
    );

    const filterValue = ref("");
    const filteredDropdownItems = computed(() => {
      if (!filterValue.value) {
        return dropdownItems.value;
      }
      return dropdownItems.value.filter((i) =>
        i.value.startsWith(filterValue.value)
      );
    });

    const handleChange = (item: DropdownItem) => {
      const countryCode = item.value;
      open.value = false;
      filterValue.value = "";
      emitValue({ ...props.value, countryCode });
    };

    const handleInput = (e: Event) => {
      const input: HTMLInputElement = e.currentTarget as HTMLInputElement;
      const number = input.value.startsWith("0")
        ? input.value.slice(1)
        : input.value;
      emitValue({ ...props.value, number });
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (open.value) {
        if (e.key === "Escape") {
          filterValue.value = "";
        } else {
          filterValue.value += e.key;
        }
      }
    };

    const emitValue = (value: TelephoneNumber) => emit("change", value);

    return {
      open,
      selectedItem,
      filteredDropdownItems,
      handleChange,
      handleInput,
      handleKeyUp,
    };
  },
});
