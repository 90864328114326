import httpClient, { ApiResponse } from "./httpClient";

export const getInfoContents = async (
  references: string[],
): Promise<ApiResponse<any>> =>
  httpClient.get(
    `/infocontents/content/${JSON.stringify(
      references.map(encodeURIComponent)
    )}`
  );

export const postInfoContents = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/infocontents`, body);
