
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  readonly,
  PropType,
  watch,
  reactive,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { NavItem } from "@/interfaces";
import {
  Action,
  ActionProgress,
  ActionProgressResponse,
  Hotspot,
  Document,
} from "@/models";
import NavList from "@/components/NavList.vue";
import TabList from "@/components/TabList.vue";
import VideoAction from "@/components/hotspot/action/VideoAction.vue";
import DocumentAction from "@/components/hotspot/action/DocumentAction.vue";
import ActionView from "@/components/hotspot/action/ActionView.vue";
import TicksAction from "@/components/hotspot/action/TicksAction.vue";
import NewAction from "@/components/hotspot/edit/NewAction.vue";
import ActionEdit from "@/components/hotspot/action/ActionEdit.vue";
import BaseHotspot from "@/components/hotspot/_BaseHotspot.vue";
import NewStarterFormAction from "./action/NewStarterFormAction.vue";
import ReferencesAction from "./action/ReferencesAction.vue";
import FormAction from "@/components/hotspot/action/FormAction.vue";
import InfoContentAction from "./action/InfoContentAction.vue";
import ContactGroupAction from "./action/ContactGroupAction.vue";
import TextItem from "@/services/TextItem";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import { createAction, deleteAction } from "@/api/actions.api";
import { deleteHotspot } from "@/api/hotspot.api";
import { getMessageContents } from "@/api/message-contents.api";
import { useNotification } from "@/composables";

export default defineComponent({
  name: "ActionHotspot",
  components: {
    NavList,
    ActionView,
    VideoAction,
    DocumentAction,
    TicksAction,
    NewStarterFormAction,
    BaseHotspot,
    TabList,
    ReferencesAction,
    FormAction,
    ActionEdit,
    InfoContentAction,
    NewAction,
    Modal,
    Button,
    ContactGroupAction,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    hotspotProgress: {
      type: Number,
      default: 0,
    },
    showNext: Boolean,
    showPrevious: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const { inPageNotification } = useNotification();

    const editMode = computed(() => store.getters.track.editMode);

    const textItemService = new TextItem();

    const hotspot = ref<any>();
    const actions = computed(() => hotspot.value?.actions ?? []);
    const contactGroupsAvailable = computed(() => {
      return hotspot.value?.contactGroups?.length > 0;
    });

    let newAction = reactive<{
      title: string;
      type: string;
      level: string;
      signatureRequired?: boolean;
    }>({
      title: "",
      type: "document",
      level: "global",
      signatureRequired: false,
    });

    const currentAction = ref<any>();
    const currentActionTitle = ref<string>("");
    const currentActionDescription = ref<string>("");

    const getCurrentAction = async () => {
      let { actionId } = router.currentRoute.value.params;

      if (
        actionId == "contacts" ||
        (!actionId && contactGroupsAvailable.value)
      ) {
        currentAction.value = {
          id: "contacts",
          actionType: "contact-group",
          textItems: [],
          viewAction: () => true,
        };
        currentActionTitle.value = getTextItemText("title");
        currentActionDescription.value = getTextItemText("description");
      } else {
        if (!actionId) {
          let defaultAction = actions.value.find(
            (a) =>
              !a?.actionType ||
              a.actionType !== "document" ||
              !a.hideIfNoContent ||
              documentActionContent.value
                .map((d) => d.reference)
                .includes(a.documentReference)
          );
          if (!defaultAction) {
            defaultAction = actions.value[0];
          }
          currentAction.value = defaultAction;
          currentActionTitle.value = getTextItemText("title");
          currentActionDescription.value = getTextItemText("description");
        } else {
          const action = actions.value.find(
            (a) => a.id === (actionId as string)
          );
          if (documentActionContent.value.length === 0) {
            await getDocumentActionContent();
          }
          if (linkActionContent.value.length === 0) {
            await getLinkMessageContent();
          }

          if (
            !action?.actionType ||
            action.actionType !== "document" ||
            !action.hideIfNoContent ||
            documentActionContent.value
              .map((d) => d.reference)
              .includes(action.documentReference)
          ) {
            currentAction.value = action;
            currentActionTitle.value = getTextItemText("title");
            currentActionDescription.value = getTextItemText("description");
          } else {
            const realActionIndex = actions.value.findIndex(
              (a) => a.id === actionId
            );
            if (realActionIndex < actions.value.length - 1) {
              handleNextClick(realActionIndex + 1);
            } else {
              emit("next");
            }
          }
        }
      }
    };

    const getHotspotTextItemText = (type) => {
      const textItems = props.hotspot.textItems;
      return textItems.find((ti) => ti.purpose === type)?.data ?? "";
    };
    const getTextItemText = (type) => {
      let action = currentAction.value;

      const textItemText = action?.textItems.find((ti) => ti.purpose === type);
      const itemText = textItemText ? textItemText.data : "";
      const currentUser = store.getters.currentUser;
      return textItemService.replacePlaceHolders(itemText, currentUser);
    };
    const handleActionComplete = ({
      progressObj,
      manualNav,
    }: {
      progressObj: ActionProgressResponse;
      manualNav?: boolean;
    }) => {
      try {
        const { actionProgress } = progressObj;
        const index = actionProgressItems.value.findIndex(
          (ap) => ap.actionId === actionProgress.actionId
        );
        if (index > -1) {
          actionProgressItems.value.splice(index, 1, actionProgress);
        } else {
          actionProgressItems.value.push(actionProgress);
        }
        emit("update-progress");
        if (!manualNav) {
          handleNextClick();
        }
      } catch (e) {
        // Do nothing
      }
    };

    const handleActionRemoved = async (actionId: string) => {
      try {
        await deleteAction(actionId);
        inPageNotification("Success!", "Action deleted", "success");

        let hotspotValue = hotspot.value;
        if (hotspotValue?.actions.length) {
          hotspotValue.actions = hotspotValue.actions.filter(function (a) {
            return a.id !== actionId;
          });
          router.push({
            path: `/track/hotspot/${hotspotValue?.id}`,
          });
        }

        hotspot.value = hotspotValue;
      } catch (err) {
        inPageNotification("Error!", "Unable to delete action", "error");
      }
    };

    const handleHotspotRemoved = async (hotspotId: string) => {
      try {
        await deleteHotspot(hotspotId);
        inPageNotification("Success!", "Hotspot deleted", "success");

        router.push("/track");
      } catch (err) {
        inPageNotification("Error!", "Unable to delete action", "error");
      }
    };

    const documentActionContent = ref<Array<Document>>([]);
    const documentActions = computed(() =>
      actions.value.filter((action) => action.actionType === "document")
    );
    const getDocumentActionContent = async () => {
      const refs = documentActions.value
        .filter((da) => !!da.documentReference)
        .map((da) => da.documentReference) as string[];

      documentActionContent.value = [
        ...documentActionContent.value,
        ...(await Document.getForReferences(refs)),
      ];
    };

    const linkActionContent = ref<Array<Document>>([]);
    const linkActions = computed(() =>
      actions.value.filter((action) => action.actionType === "link")
    );
    const getLinkMessageContent = async () => {
      linkActionContent.value = [];
      const refs = linkActions.value
        .filter((la) => !!la.messageReference)
        .map((la) => la.messageReference) as string[];

      const response = await getMessageContents(
        refs,
        "",
        store.getters.currentUser.id
      );
      const results = response.data.payload.results;

      linkActionContent.value = [
        ...linkActionContent.value,
        ...(results ?? []),
      ];
    };

    const navItems = computed<Array<NavItem>>(() => {
      if (hotspot.value) {
        const hotspotId = hotspot.value.id;

        const allNavItems = actions.value
          .filter((action) => {
            if (action.actionType === "link") {
              const linkContent = linkActionContent.value.find(
                (content) => content.reference === action.messageReference
              );

              return linkContent || !action.hideIfNoContent;
            } else if (action.actionType === "document") {
              const documentContent = documentActionContent.value.find(
                (content) => content.reference === action.documentReference
              );

              return documentContent || !action.hideIfNoContent;
            }

            return true;
          })
          .map((action) => {
            const navItem = {
              id: action.id,
              type: action.actionType,
              url: `/track/hotspot/${hotspotId}/${action.id}`,
              title: action.textItems.length
                ? action.textItems.find((ti) => ti.purpose === "side-menu")
                    ?.data
                  ? (action.textItems.find((ti) => ti.purpose === "side-menu")
                      ?.data as string)
                  : action.textItems.find((ti) => ti.purpose === "title")
                      ?.data ?? ""
                : "",
              completed: (actionProgressItems.value || []).find(
                (a) => a.actionId === action.id
              )?.completed,
            } as NavItem;

            if (action.actionType === "link") {
              const linkContent: any = linkActionContent.value.find(
                (content) => content.reference === action.messageReference
              );
              if (linkContent && linkContent?.data) {
                navItem.externalLink = linkContent.data;
              }
            }

            return navItem;
          });
        return allNavItems;
      }
      return [];
    });

    const handleChangeOrder = () => {
      const newNavItems = [...navItems.value];
      emit("changeOrder", {
        newNavItems,
      });
    };

    const activeNavItem = computed(() => {
      if (!currentAction.value) {
        return navItems.value[0]?.url;
      }

      return (
        navItems.value.find((i) => i.id === currentAction.value?.id)?.url ?? ""
      );
    });

    const currentActionType = computed(() => {
      if (!currentAction.value) {
        return "";
      }
      if (editMode.value) {
        return `${currentAction.value.actionType}Edit`;
      }
      return currentAction.value.actionType;
    });
    const actionTypes = readonly({
      video: "VideoAction",
      document: "DocumentAction",
      ticks: "TicksAction",
      newstarter: "NewStarterFormAction",
      references: "ReferencesAction",
      form: "FormAction",
      info: "InfoContentAction",
      link: "ActionView",
      new: "NewAction",
      "contact-group": "ContactGroupAction",
    });
    const currentComponent = computed(() =>
      editMode.value ? "ActionEdit" : actionTypes[currentActionType.value]
    );

    const actionProgressItems = ref<Array<ActionProgress>>([]);
    const currentActionProgress = computed(() =>
      actionProgressItems.value.find(
        (p) => p.actionId === currentAction.value?.id
      )
    );
    const getActionProgress = async () => {
      actionProgressItems.value = await Action.getUserProgressForAll(
        actions.value.map((a) => a.id),
        store.getters.currentUser.id
      );
    };
    const currentActionIndex = computed(() =>
      actions.value.findIndex((a) => a.id === currentAction.value?.id)
    );
    const hasNextAction = computed(
      () => currentActionIndex.value < actions.value.length - 1
    );
    const handleNextClick = (actionIndex?: string) => {
      if (hasNextAction.value) {
        const nextAction =
          actions.value[actionIndex ?? currentActionIndex.value + 1];
        router.push(`/track/hotspot/${hotspot.value.id}/${nextAction.id}`);
      } else {
        emit("next");
      }
    };

    const handleBackClick = () => {
      if (!hotspot.value?.isolatedActions || editMode.value) {
        router.push(`/track`);
      } else {
        router.go(-1);
      }
    };

    const hasPreviousAction = computed(() => currentActionIndex.value > 0);
    const handlePreviousClick = () => {
      if (hasPreviousAction.value) {
        const previousAction = actions.value[currentActionIndex.value - 1];
        router.push(`/track/hotspot/${hotspot.value.id}/${previousAction.id}`);
      } else {
        emit("previous");
      }
    };

    const viewAction = () =>
      currentAction.value?.viewAction(store.getters.currentUser.id);

    const handleCreateAction = () => (showModal.value = true);

    const handleSaveNewAction = async () => {
      try {
        showModal.value = false;
        const response = await createAction({
          ...newAction,
          hotspotId: props.hotspot.id,
        });
        inPageNotification("action.saved-title", "action.saved", "success");
        const action = response.data.payload.result;
        resetNewAction();
        router.push(`/track/hotspot/${props.hotspot.id}/${action.id}`);
        emit("updateHotspot");
      } catch (error) {
        console.log(error);
        inPageNotification("action.error-title", "action.error-save", "error");
      }
    };

    const showModal = ref<boolean>(false);
    const handleCancel = () => {
      resetNewAction();
      showModal.value = false;
    };

    const resetNewAction = () =>
      (newAction = Object.assign(newAction, {
        title: "",
        type: "document",
        level: "global",
        signatureRequired: false,
      }));

    const updateActions = () => {
      hotspot.value = props.hotspot;
      getActionProgress();
      getDocumentActionContent();
      getLinkMessageContent();
      viewAction();
    };

    watch(props, () => {
      if (props.hotspot) {
        updateActions();
        getCurrentAction();
      }
    });

    watch(editMode, async () => {
      await getLinkMessageContent();
    });

    const routerHook = router.afterEach((to) => getCurrentAction());

    onMounted(() => {
      updateActions();
      getCurrentAction();
    });

    onUnmounted(() => {
      // stop listening for the afterEach
      routerHook();
    });

    return {
      editMode,
      newAction,
      showModal,
      currentAction,
      currentActionTitle,
      currentActionDescription,
      getHotspotTextItemText,
      navItems,
      activeNavItem,
      hasNextAction,
      currentComponent,
      currentActionType,
      currentActionProgress,
      handleActionComplete,
      handleActionRemoved,
      handleHotspotRemoved,
      handleNextClick,
      handlePreviousClick,
      handleBackClick,
      handleCreateAction,
      handleCancel,
      handleSaveNewAction,
      handleChangeOrder,
    };
  },
});
