import { Model } from "./Model";

export class Review extends Model<Review> {
  private static readonly path: string = "/reviews";

  public userId: string;
  public reviewNumber: number;
  public reviewTemplate: any;
  public user: any;
  public answers: any[];
  public actionObjectives: any[];
  public extensionReview: boolean;
  public completedManager: boolean;
  public completed: boolean;

  constructor({
    id = "",
    userId = "",
    reviewNumber = 1,
    reviewTemplate = null,
    user = null,
    answers = [],
    actionObjectives = [],
    extensionReview = false,
    completedManager = false,
    completed = false,
  } = {}) {
    super(id, Review, Review.path);
    this.userId = userId;
    this.reviewNumber = reviewNumber;
    this.reviewTemplate = reviewTemplate;
    this.user = user;
    this.answers = answers;
    this.actionObjectives = actionObjectives;
    this.extensionReview = extensionReview;
    this.completedManager = completedManager;
    this.completed = completed;
  }
}
