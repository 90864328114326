import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  showGlobalModal = "showModal",
  hideGlobalModal = "hideModal",
}

export interface ModalState {
  title: string;
  body: string;
  modalActive: boolean;
  onConfirm: (() => Promise<void>) | null;
  onCancel: (() => Promise<void>) | null;
}

export interface ModalOptions {
  title: string;
  body: string;
  onConfirm: (() => Promise<void>) | null;
  onCancel: (() => Promise<void>) | null;
}

const state: ModalState = {
  title: "",
  body: "",
  modalActive: false,
  onConfirm: null,
  onCancel: null,
};

const getters: GetterTree<ModalState, null> = {
  modal: (state: ModalState) => state,
};

const actions: ActionTree<ModalState, null> = {
  showGlobalModal({ commit }, options: ModalState) {
    commit(MutationKeys.showGlobalModal, options);
  },
  hideGlobalModal({ commit }) {
    commit(MutationKeys.hideGlobalModal);
  },
};

const mutations: MutationTree<ModalState> = {
  [MutationKeys.showGlobalModal]: (
    state: ModalState,
    options: ModalOptions
  ) => {
    state.title = options.title;
    state.body = options.body;
    state.onConfirm = options.onConfirm;
    state.onCancel = options.onCancel;
    state.modalActive = true;
  },
  [MutationKeys.hideGlobalModal]: (state: ModalState) => {
    state.modalActive = false;
  },
};

const modal: Module<ModalState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { modal };
