import httpClient from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getUserSideMenuItems = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/sidemenuitem/track-user`);

export const getAdminSideMenuItems = async (): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/sidemenuitem/admin-user`);

export const getSideMenuItem = async (id: string): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/sidemenuitem/get-one/${id}`);

export const getMessageContents = async (references: string[],
  groupId = 'null',
  userId = 'null'): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/messagecontents/content/${JSON.stringify(references.map(encodeURIComponent))}/user/${groupId}/${userId}`);

export const getMessageContentData = (messageContents: any[], reference: string, languageId: string): string => {
  const filteredContent: any[] = messageContents.filter(
    (content: any) => {
      return (
        content.languageId === languageId &&
        content.reference === reference
      );
    }
  );
  return filteredContent[0]?.data ?? '';
}