import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e90d1aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-container"
}
const _hoisted_2 = ["id", "type", "poster"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "iframe-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_YoutubePlayer = _resolveComponent("YoutubePlayer")!
  const _component_VideoControls = _resolveComponent("VideoControls")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "video",
        id: "video-container",
        onMouseenter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setHovering(true))),
        onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setHovering(false)))
      }, [
        _createVNode(_component_Notification),
        (_ctx.videoType === 'upload')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["play-pause-container", { active: _ctx.showPlayPause || !_ctx.videoIsPlaying }])
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: _ctx.videoStateIcon,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleVideoPlayback(`video-${_ctx.reference}`)))
                }, null, 8, ["icon"])
              ], 2),
              (_openBlock(), _createElementBlock("video", {
                id: `video-${_ctx.reference}`,
                type: _ctx.format,
                key: _ctx.videoSource,
                poster: _ctx.placeholderUrl,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleVideoPlayback(`video-${_ctx.reference}`))),
                onEnded: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleVideoEnded && _ctx.handleVideoEnded(...args)))
              }, [
                _createElementVNode("source", { src: _ctx.videoSource }, null, 8, _hoisted_3)
              ], 40, _hoisted_2))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", {
                id: "player",
                class: _normalizeClass(`video-${_ctx.reference}`)
              }, [
                _createVNode(_component_YoutubePlayer, {
                  videoSource: _ctx.videoSource,
                  isPlaying: _ctx.videoIsPlaying,
                  isFullScreen: _ctx.isFullScreen,
                  playerId: 0,
                  onOnStateChange: _ctx.handleOnStateChange
                }, null, 8, ["videoSource", "isPlaying", "isFullScreen", "onOnStateChange"])
              ], 2)
            ])),
        _createElementVNode("div", {
          class: _normalizeClass(["video-controls", { show: _ctx.showVideoControls }])
        }, [
          _createVNode(_component_VideoControls, {
            isPlaying: _ctx.videoIsPlaying,
            isFullScreen: _ctx.isFullScreen,
            videoType: _ctx.videoType,
            onToggleFullscreen: _ctx.toggleFullScreen,
            onTogglePlayback: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleVideoPlayback(`video-${_ctx.reference}`))),
            onToggleYTPlayback: _ctx.handleYtTogglePlayback
          }, null, 8, ["isPlaying", "isFullScreen", "videoType", "onToggleFullscreen", "onToggleYTPlayback"])
        ], 2)
      ], 32))
    : _createCommentVNode("", true)
}