
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  props: {
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isOpening = ref(false);
    const openItems = ref<Array<number>>([]);
    const handleItemClick = (index: number) => {
      if (isOpen(index)) {
        openItems.value = openItems.value.filter((item) => item !== index);
        return;
      }

      isOpening.value = true;
      setTimeout(() => (isOpening.value = false), 500);
      if (props.allowMultiple) {
        openItems.value.push(index);
      } else {
        openItems.value = [index];
      }
    };

    const isOpen = (index: number) => openItems.value.some((i) => i === index);

    return {
      isOpening,
      handleItemClick,
      isOpen,
    };
  },
});
