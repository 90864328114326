import httpClient, { ApiResponse } from "@/api/httpClient";
import { AxiosResponse } from "axios";

// Get all users for user list
export const getUsersPaginated = async (
  pageNumber: number,
  pageSize: number,
  name?: string,
  sortCol?: string,
  order?: string,
  filters: { name: string; values: string[] }[] = [
    {
      name: "roles",
      values: [],
    },
    {
      name: "cohorts",
      values: [],
    },
  ],
  dateFilters: { from: string | null; to: string | null } = { from: "", to: "" }
): Promise<AxiosResponse> => {
  const response = await httpClient.get(`/users/paginated`, {
    params: {
      pageNumber,
      pageSize,
      name,
      sortCol,
      order,
      filters,
      dateFilters,
    },
  });
  return response;
};

// Get all users for user list
export const getReviewUsersPaginated = async (
  pageNumber: number,
  pageSize: number,
  name?: string,
  sortCol?: string,
  order?: string,
  filters: { name: string; values: string[] }[] = [
    {
      name: "roles",
      values: [],
    },
    {
      name: "cohorts",
      values: [],
    },
  ],
  dateFilters: { from: string | null; to: string | null } = { from: "", to: "" }
): Promise<AxiosResponse> => {
  const response = await httpClient.get(`/reviews/paginated`, {
    params: {
      pageNumber,
      pageSize,
      name,
      sortCol,
      order,
      filters,
      dateFilters,
    },
  });
  return response;
};

export const getUsersBasic = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/users/basic`);

export const getUserFeatureConfigs = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/users/feature-configs`);

export const toggleUserDeactivationStatus = async (
  userIds: string[],
  name: string,
  currentUserId: string,
  action: string,
  selectAll: boolean,
  deselectedUsers: string[],
  filters: { name: string; values: string[] }[] = [
    {
      name: "roles",
      values: [],
    },
    {
      name: "cohorts",
      values: [],
    },
  ]
): Promise<AxiosResponse | void> => {
  let activate: boolean;
  if (action === "activate") {
    activate = true;
  } else {
    activate = false;
  }

  const response = await httpClient.put(`/users/deactivate-users`, {
    activate,
    deselectedUsers,
    filters,
    name,
    selectAll,
    users: userIds,
  });
  return response;
};

export const postCSVFile = async (
  body: any
): Promise<ApiResponse<any>> => httpClient.post(`/users/csv`, body);

export const getUserManager = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/users/manager-for-user`);

export default getUsersPaginated;
