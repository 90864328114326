
import WidgetLayout from "../WidgetLayout.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "UserActivity",
  components: {
    WidgetLayout,
  },
  props: {
    width: Number,
    height: Number,
    userData: {
      default: {} as any,
    },
    data: Object,
  },
  setup(props) {
    const router = useRouter();

    const allGroups = ref<any[]>([]);

    const maxUsers = computed(() =>
      Math.max(...allGroups.value.map((o) => o.users))
    );

    const groupsData = computed(() => {
      return allGroups.value
        .map((g) => {
          g.height =
            g.users > 0 ? Math.floor((g.users / maxUsers.value) * 100) : 0;
          return g;
        })
        .sort((a: any, b: any) => b.users - a.users);
    });

    const usersData = computed({
      get: () => props.data,
      set: (value) => console.log(value),
    });

    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    const handleClickCell = (id: string, rowId: number) => {
      navigateToUser(id);
    };

    const navigateToUser = (userId: string) => {
      router.push(`/users/${userId}`);
    };

    const setGroupsData = async (groups) => {
      allGroups.value = [];
      for (const g in groups) {
        const group = groups[g];
        const userRow = {
          name: group.groupName,
          users: group.users,
        };

        // Push each row to rows array on each iteration
        allGroups.value.push(userRow);
      }
    };

    const handleClickRow = function (id: number) {
      return false;
    };

    onMounted(async () => {
      await setGroupsData(usersData.value);
    });

    watch(props, async () => {
      if (props.userData) {
        await setGroupsData(usersData.value);
      }
    });

    return {
      usersData,
      handleClickCell,
      handleClickRow,
      isOdd,
      groupsData,
    };
  },
});
