
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  shallowRef,
  toRef,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Schemas, useSchema } from "@/plugins/schema";
import { getUserRequired } from "@/api/actions.api";
import { Action, User } from "@/models";
import Button from "@/components/Button.vue";
import VideosGrid from "@/components/user/videos/ui/VideosGrid.vue";
import VideosTable from "@/components/user/videos/ui/VideosTable.vue";
import VideoEdit from "@/components/user/videos/ui/VideoEdit.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import MobileBottomBar from "@/components/MobileBottomBar.vue";
import Modal from "@/components/Modal.vue";
import Search from "@/components/Search.vue";
import Select from "@/components/form/Select.vue";
import { TextItem } from "@/interfaces";

import { useNotification } from "@/composables/useNotification";
import {
  getVideoContents,
  postVideoContents,
  postVideoSignOff,
  postVideoUrl,
} from "@/api/video-contents.api";

import { Video } from "@/models/Video";
import { getImageContents, postImageContents } from "@/api/image-contents.api";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isAuthenticatedUser: Boolean,
  },
  components: {
    Button,
    FileDisplay,
    FileInput,
    Form,
    FormInput,
    MobileBottomBar,
    Modal,
    Search,
    Select,
    VideoEdit,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const schema = useSchema(Schemas.UploadDocument);
    const user = toRef(props, "user");
    const { id } = router.currentRoute.value.params;

    const { inPageNotification } = useNotification();

    const isFetching = ref<boolean>(true);
    const showModal = ref<boolean>(false);
    const actions = ref<Action[]>([]);
    const preparedVideos = ref<any>({});
    const videoContents = ref<any>([]);
    const selectedUiType = ref<string>("table");
    const searchTerm = ref<string>("");
    const sortOrder = ref<string>("asc");
    const activeModalVideo = ref<any>({});
    const uiTypes = shallowRef([
      {
        name: "grid",
        component: VideosGrid,
      },
      {
        name: "table",
        component: VideosTable,
      },
    ]);
    const tableHeadings = ref([
      {
        id: 0,
        title: "Name",
        sortOrder: "asc",
        sortable: true,
        mobileSortable: true,
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
      {
        id: 1,
        title: "Viewed",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        active: false,
        desktop: true,
        mobileFeatured: false,
        center: true,
      },
      {
        id: 2,
        title: "Signed",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        active: false,
        desktop: true,
        mobileFeatured: false,
        center: true,
      },
      {
        id: 3,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        mobileFeatured: true,
        desktop: true,
      },
    ]);
    let headingsData = ref({
      filterOpen: false,
      colsFeatured: [0, 2, 6],
      rowActiveVideo: -1,
      sortActiveIndex: 0,
    });

    const model = ref<any>({
      title: "",
      description: "",
      signature: "",
      file: null,
      useFileUrl: false,
      placeholder: null,
      placeHolderUrl: "",
      fileUrl: "",
      fileIsLoading: false,
      videoContents: null,
    });

    const isValid = ref<boolean>(false);
    const handleValidUpdate = (valid: boolean) => {
      isValid.value = valid;
    };

    const totalRows = computed(() => allVideos.value.length);

    const currentComponent = computed(() => {
      const type = uiTypes.value.find((ui) => ui.name === selectedUiType.value);
      if (type) return type.component;
      else return VideosTable;
    });

    const requiredVideos = computed(() =>
      actions.value.filter(
        (action) =>
          action.actionType === "video" && action.userRequired === true
      )
    );

    const additionalVideos = computed(() =>
      actions.value.filter(
        (action) =>
          action.actionType === "video" && action.userRequired === false
      )
    );

    // A computed property that filters and sorts videos both required and additional
    const allVideos = computed(() => {
      // get docs from both required and additional arrays
      const videos = [...requiredVideos.value, ...additionalVideos.value];
      // sort videos based on sort order
      const sortedVideos = videos.sort((a: any, b: any): number => {
        const textItemA = a.textItems.find((ti) => ti.purpose === "title");
        const textItemB = b.textItems.find((ti) => ti.purpose === "title");
        if (sortOrder.value === "asc") {
          if (textItemA && textItemA.data && textItemB && textItemB.data) {
            if (textItemA.data < textItemB.data) return -1;
            else return 1;
          } else return 0;
        } else {
          if (textItemA && textItemA.data && textItemB && textItemB.data) {
            if (textItemA.data > textItemB.data) return -1;
            else return 1;
          } else return 0;
        }
      });

      // filter based on search term
      const filtered = sortedVideos.filter((vid) =>
        vid.textItems
          .find((ti) => ti.purpose === "title")
          ?.data.toLowerCase()
          .includes(searchTerm.value.toLowerCase())
      );
      return filtered;
    });

    const handleClickSort = () => {
      if (sortOrder.value === "asc") sortOrder.value = "desc";
      else sortOrder.value = "asc";
    };

    const inputSearchTerm = (val: string) => (searchTerm.value = val);

    const toggleUi = () => {
      if (selectedUiType.value === "grid") selectedUiType.value = "table";
      else selectedUiType.value = "grid";
    };

    const handleClickRow = (id: number) => {
      if (headingsData.value.rowActiveVideo !== id) {
        headingsData.value.rowActiveVideo = id;
      } else {
        headingsData.value.rowActiveVideo = -1;
      }
    };

    const resetForm = () => {
      model.value.title = "";
      model.value.description = "";
      model.value.signature = "";
      model.value.file = null;
      model.value.videoContents = null;
      model.value.useFileUrl = false;
      model.value.fileUrl = "";
      model.value.fileIsLoading = false;
    };

    const getVideos = async () => {
      const requiredVideosResponse = await getUserRequired(id as string);
      actions.value = requiredVideosResponse.data.payload.result.actions;
      preparedVideos.value =
        requiredVideosResponse.data.payload.result.prepared;
      const videoReferences = allVideos.value.map((vid) => vid.videoReference);
      if (videoReferences && videoReferences.length) {
        const videoContentsResponse = await getVideoContents(
          videoReferences as string[],
          id as string,
          "admin"
        );
        videoContents.value = videoContentsResponse.data.payload.results;
      }
      isFetching.value = false;
      if (model.value.fileIsLoading) {
        const video = videoContents.value.find(
          (vid) => vid.reference === activeModalVideo.value.videoReference
        );
        if (video?.transcoding) {
          model.value.fileIsLoading = true;
        } else {
          model.value.fileIsLoading = false;
          clearInterval(refreshSubscription);
        }
      }
    };

    const viewVideo = async (data: {
      reference: string;
      name: string;
      description: string;
      sideMenuText: string;
      id: string;
      signOffEnabled: boolean;
    }) => {
      activeModalVideo.value = allVideos.value.find(
        (doc) => doc.id === data.id
      );

      if (preparedVideos.value[`video-${data.reference}`]) {
        const video = videoContents.value.find(
          (vid) => vid.reference === data.reference
        );
        if (video) {
          model.value.videoContents = new Video(video);
        } else {
          model.value.videoContents = null;
        }
        if (video.signOffEnabled) {
          model.value.signature = "Yes";
        } else {
          model.value.signature = "No";
        }
        model.value.useFileUrl = false;
        model.value.fileUrl = "";
        model.value.placeholderUrl = "";
        model.value.placeholder = null;
        if (video.youtubeUrl) {
          model.value.fileUrl = video.youtubeUrl;
          model.value.useFileUrl = true;
        }
        if (video.transcoding) {
          model.value.fileIsLoading = true;
        } else {
          model.value.fileIsLoading = false;
          clearInterval(refreshSubscription);
        }
        const imageResponse = await getImageContents(
          [data.reference as string],
          "null",
          "admin"
        );
        if (imageResponse.data?.payload?.results) {
          const imageData = imageResponse.data?.payload?.results[0];
          model.value.placeholderUrl = imageData?.url ?? "";
        }
        model.value.title = data.name;
        model.value.sideMenuText = data.sideMenuText;
        model.value.description = data.description
          ? data.description.replace(
              "[username]",
              `${user.value.firstName} ${user.value.surname}`
            )
          : "";
        showModal.value = true;
      } else {
        resetForm();
        await getVideos();
        if (data.signOffEnabled) {
          model.value.signature = "Yes";
        } else {
          model.value.signature = "No";
        }
        model.value.title = data.name;
        model.value.sideMenuText = data.sideMenuText;
        model.value.description = data.description
          ? data.description.replace(
              "[username]",
              `${user.value.firstName} ${user.value.surname}`
            )
          : "";
        showModal.value = true;
      }
    };

    const cancelVideoChanges = () => {
      resetForm();
      showModal.value = false;
    };

    const handleNewFile = () => {
      model.value.newFile = true;
    };

    const handleSave = async () => {
      try {
        if (model.value.placeholder instanceof Blob) {
          await uploadImage(
            model.value.placeholder,
            user.value.preferredLanguageId,
            "placeholder",
            activeModalVideo.value.videoReference,
            "true"
          );
        }
        if (model.value.useFileUrl) {
          const formData: any = {
            signOffEnabled: model.value.signature ?? false,
            reference: activeModalVideo.value.videoReference,
            languageId: user.value.preferredLanguageId,
            youTubeUrl: model.value.fileUrl,
            userId: user.value.id,
          };
          await postVideoUrl(formData);
          inPageNotification(
            "Success!",
            "Video information has been successfully updated",
            "success"
          );
          await getVideos();
          showModal.value = false;
        } else if (model.value.newFile) {
          model.value.fileIsLoading = true;
          await uploadVideo(
            model.value.file,
            user.value.preferredLanguageId,
            activeModalVideo.value.videoReference
          );
          model.value.newFile = false;
          refreshSubscription = setInterval(getVideos, 10000);
        } else {
          const formData: any = {
            signOffEnabled: model.value.signature ?? false,
            reference: activeModalVideo.value.videoReference,
            languageId: user.value.preferredLanguageId,
            userId: user.value.id,
          };
          await postVideoSignOff(formData);
          inPageNotification(
            "Success!",
            "Video information has been successfully updated",
            "success"
          );
          await getVideos();
          showModal.value = false;
        }
      } catch (err) {
        console.log(err);
        inPageNotification(
          "Error!",
          "Could not update video information",
          "error"
        );
      }
    };

    let refreshSubscription;

    const uploadImage = async (
      file: any,
      language: any,
      size: string,
      reference: string,
      blockDelete: string
    ) => {
      const imageFiles = {};
      imageFiles[language] = file;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        imageFiles[language],
        "" + imageFiles[language].name
      );
      formData.append("imageSize", size);
      formData.append("languageId", language);
      formData.append("reference", reference);
      formData.append("blockDelete", blockDelete);
      await postImageContents(formData);
    };

    const uploadVideo = async (file: any, language: any, reference: string) => {
      const videoFiles = {};
      videoFiles[language] = file;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        videoFiles[language],
        "" + videoFiles[language].name
      );

      const userId = user.value.id as string;
      formData.append("languageId", language);
      formData.append("reference", reference);
      formData.append("userId", userId);
      await postVideoContents(formData);
    };

    const handleClickCell = (data: {
      id: string;
      index: number;
      textItems: TextItem[];
    }) => handleClickRow(data.index);

    onMounted(async () => {
      await getVideos();
    });

    return {
      isFetching,
      showModal,
      tableHeadings,
      allVideos,
      sortOrder,
      totalRows,
      handleClickSort,
      headingsData,
      handleClickCell,
      inputSearchTerm,
      requiredVideos,
      preparedVideos,
      viewVideo,
      selectedUiType,
      currentComponent,
      toggleUi,
      model,
      schema,
      isValid,
      cancelVideoChanges,
      handleSave,
      videoContents,
      handleValidUpdate,
      handleNewFile,
    };
  },
});
