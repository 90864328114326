<template>
  <div
    ref="root"
    class="widget-layout"
    :class="{ menuOpen, 'is-link': link }"
    @click="handleClick"
  >
    <div
      class="titlebar"
      :class="[severity, { hasIcon: icon }]"
      @mousedown="!fixed && handleMousedown"
      :style="fixed && { pointerEvents: 'none' }"
    >
      <div class="title-icon" v-if="icon" :class="severity">
        <font-awesome-icon
          class="icon"
          :icon="icon"
          size="2x"
        ></font-awesome-icon>
      </div>
      <slot name="title">
        <h4 v-if="title">
          {{ title }}
        </h4>
        <p v-if="subtitle" class="subtitle">
          {{ subtitle }}
        </p>
      </slot>
    </div>
    <div class="client">
      <slot />
    </div>
    <button
      v-if="!fixed"
      class="menuButton"
      @click="showMenu"
      :style="{ color: menuColour }"
    >
      ⁝
    </button>
    <widget-menu
      ref="menu"
      :items="menu"
      :open="menuOpen"
      @close="closeMenu"
      @clone="emitMessage('clone')"
      @delete="emitMessage('delete')"
    />
    <div class="loader" :class="{ show: loading }" />
    <div
      v-if="(maxWidth !== minWidth || maxHeight !== minHeight) && !fixed"
      class="resizer"
      @mousedown="startResize($event)"
    >
      ◢
    </div>
  </div>
</template>
<script>
import WidgetMenu from "./internal/WidgetMenu.vue";
import { useRouter } from "vue-router";
import { defineComponent, ref } from "vue";

const sev = ["", "ok", "warn", "danger"];

export default defineComponent({
  components: { WidgetMenu },
  name: "WidgetLayout",
  props: {
    icon: {
      type: String,
      default: "",
    },
    severity: {
      type: String,
      default: "",
      validator: (val) => sev.some((s) => s === val),
    },
    loading: Boolean,
    menuColour: { default: "#000" },
    require: { default: [] },
    fixed: Boolean,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
      required: false,
    },
    touchdragtime: {
      type: Number,
      default: 800,
    },
    maxWidth: {
      type: Number,
      default: Infinity,
    },
    maxHeight: {
      type: Number,
      default: Infinity,
    },
    minWidth: {
      type: Number,
      default: 1,
    },
    minHeight: {
      type: Number,
      default: 2,
    },
    menu: {
      default: [],
    },
    link: {
      default: "",
    },
  },
  setup(props, { emit }) {
    const root = ref(null);
    const menuOpen = ref(false);
    const touchTimeout = ref(null);
    const router = useRouter();

    const showMenu = () => {
      menuOpen.value = true;
      emit("message", { _event: "openmenu" });
      root.value.$nextTick(() => this.$refs.menu.focus());
    };
    const closeMenu = () => (menuOpen.value = false);
    const emitMessage = (msg) => emit("message", { _event: msg });

    const handleClick = () => {
      if (props.link) {
        router.push(props.link);
      }
      return;
    };

    const handleMousedown = (event) =>
      emit("message", {
        _event: "grab",
        clientX: event.clientX,
        clientY: event.clientY,
        element: root,
      });

    const cancelTouch = () => {
      if (touchTimeout.value !== null) {
        clearTimeout(touchTimeout.value);
        touchTimeout.value = null;
      }
    };

    const startResize = (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      emit("message", {
        _event: "resize",
        clientX: ev.clientX,
        clientY: ev.clientY,
        element: this.$refs.inner,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        minWidth: props.minWidth,
        minHeight: props.minHeight,
      });
    };

    const touchstartHandler = (event) => {
      if (event.touches.length == 1) {
        event.preventDefault();
        touchTimeout.value = setTimeout(() => {
          touchTimeout.value = null;
          window.navigator.vibrate(100);
          emit("message", {
            _event: "grab",
            clientX: event.touches[0].clientX,
            clientY: event.touches[0].clientY,
            element: root,
          });
        }, props.touchdragtime);
      } else {
        cancelTouch();
      }
    };

    const touchendHandler = (event) => {
      cancelTouch();
      if (this.dragging) {
        emit("message", { _event: "drop", ...event });
      }
    };

    return {
      root,
      menuOpen,
      closeMenu,
      emitMessage,
      showMenu,
      handleClick,
      handleMousedown,
      cancelTouch,
      startResize,
      touchstartHandler,
      touchendHandler,
    };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";

.widget-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  &.is-link {
    cursor: pointer;
  }
}
.titlebar {
  position: relative;
  font-size: 0.72rem;
  padding: 12px;
  white-space: nowrap;
  border-bottom: 1px #ccc solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  user-select: none;
  letter-spacing: initial;
  cursor: move;
  .title-icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 12px;
    .icon {
      color: var(--base-01);
    }
  }
  &.ok {
    background: rgba(0, 176, 32, 0.15);
    border-bottom: var(--base-success) solid 1px;
    .title-icon {
      background: var(--base-success);
    }
  }
  &.warn {
    background: rgba(239, 183, 0, 0.2);
    border-bottom: var(--base-warn) solid 1px;

    .title-icon {
      background: var(--base-warn);
    }
  }
  &.danger {
    background: rgba(176, 0, 32, 0.15);
    border-bottom: var(--base-error) solid 1px;

    .title-icon {
      background: var(--base-error);
    }
  }
  &.hasIcon {
    padding-left: 52px;
  }
  h4 {
    line-height: var(--titlebarHeight);
    letter-spacing: initial;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    color: #252526;
  }
  .subtitle {
    line-height: 22px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--base-09);
  }
  &:hover:after {
    content: "";
    position: absolute;
    inset: 0;
    box-shadow: inset 100vmax 0 0 #ccc4;
  }
}
.client {
  position: relative;
  height: 100%;
  width: 100%;
  animation: appear 400ms 1 ease-out;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

.menuButton {
  position: absolute;
  top: calc((var(--titlebarHeight) / 2));
  transform: translateY(-50%);
  font-size: 1.2rem;
  right: 0;
  background: #fff0;
  opacity: 0.5;
  padding: 0 12px;
  transition: 110ms all ease-in-out;
  border: none;
  cursor: pointer;
  will-change: transform, opacity;
  &:hover {
    opacity: 1;
  }
}
.menuOpen .menuButton {
  pointer-events: none;
}

.inner:hover .resizer {
  opacity: 0.4;
}

.resizer {
  position: absolute;
  right: 0.2rem;
  bottom: 0;
  transition: 100ms all;
  opacity: 0;
  color: #888;
  font-size: 1rem;
  cursor: se-resize;
  will-change: opacity, transform;
}
.inner .resizer:hover {
  opacity: 0.7;
}

.loader {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: inset -3px 0 5px #bbb8, 3px 0 5px #bbb8;
  animation: loadSpin 700ms infinite linear;
  opacity: 0;
  transition: 900ms opacity;
  pointer-events: none;
  &.show {
    box-shadow: inset -3px 0 2px #bbb8, 3px 0 2px #bbb8;
    opacity: 1;
  }
}
</style>
