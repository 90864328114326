import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd4770c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col padded" }
const _hoisted_4 = {
  key: 0,
  class: "col padded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TelephoneInput = _resolveComponent("TelephoneInput")!
  const _component_Radio = _resolveComponent("Radio")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TelephoneInput, {
        id: "phone",
        name: "phone",
        label: "form.telephone-number",
        value: _ctx.contactNumber,
        error: _ctx.errors['number'],
        onChange: _ctx.handleContactNumberUpdate
      }, null, 8, ["value", "error", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Radio, {
        id: "public",
        value: "true",
        "model-value": _ctx.isPublicStringVal,
        text: "common.public",
        onChange: _ctx.setPublic
      }, null, 8, ["model-value", "onChange"]),
      _createVNode(_component_Radio, {
        id: "private",
        value: "false",
        "model-value": _ctx.isPublicStringVal,
        text: "common.private",
        onChange: _ctx.setPublic
      }, null, 8, ["model-value", "onChange"])
    ]),
    (!_ctx.hidePrimary)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Checkbox, {
            id: "primary",
            value: _ctx.contactNumber.isPrimary,
            disabled: _ctx.disablePrimaryCheckbox,
            text: "models.contact-number.primary",
            onChange: _ctx.setPrimary
          }, null, 8, ["value", "disabled", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}