import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_AddressList = _resolveComponent("AddressList")!
  const _component_ContactNumberList = _resolveComponent("ContactNumberList")!
  const _component_EmergencyContactList = _resolveComponent("EmergencyContactList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.isLoading })
  }, [
    _createElementVNode("section", null, [
      _createElementVNode("h5", null, [
        _createVNode(_component_TranslatedText, { path: "models.addresses.plural" })
      ]),
      _createVNode(_component_AddressList, {
        addresses: _ctx.model.addresses,
        onSave: _ctx.handleSaveAddress,
        onDelete: _ctx.handleDeleteAddress
      }, null, 8, ["addresses", "onSave", "onDelete"])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h5", null, [
        _createVNode(_component_TranslatedText, { path: "models.contact-number.plural" })
      ]),
      _createVNode(_component_ContactNumberList, {
        contactNumbers: _ctx.model.contactNumbers,
        onSave: _ctx.handleSaveNumber,
        onDelete: _ctx.handleDeleteNumber
      }, null, 8, ["contactNumbers", "onSave", "onDelete"])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h5", null, [
        _createVNode(_component_TranslatedText, { path: "models.emergency-contact.plural" })
      ]),
      _createVNode(_component_EmergencyContactList, {
        contacts: _ctx.model.emergencyContacts,
        onSave: _ctx.handleSaveContact,
        onDelete: _ctx.handleDeleteContact
      }, null, 8, ["contacts", "onSave", "onDelete"])
    ])
  ], 2))
}