
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useAuthProvider } from "@/plugins/auth";
import { usePermissionProvider } from "@/plugins/permission";
import { ValidationArgs } from "@vuelidate/core";
import {
  defineComponent,
  inject,
  ComputedRef,
  ref,
  reactive,
  computed,
  onMounted,
} from "vue";
import { required, email, minLength, helpers } from "@vuelidate/validators";

import FormInput from "@/components/form/FormInput.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import Button from "@/components/Button.vue";
import ShadowContainer from "@/components/ui/ShadowContainer.vue";
import { getCookie, setCookie } from "@/router";
import Form from "@/components/Form.vue";
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import { Theme } from "@/models/Style";
import { useTheme } from "@/composables/useTheme";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "SignIn",
  components: {
    UnauthenticatedLayout,
    Button,
    Checkbox,
    FormInput,
    ShadowContainer,
    Form,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = useAuthProvider();
    const permission = usePermissionProvider();
    const theme = inject<ComputedRef<Theme>>("theme");

    const { inPageNotification } = useNotification();

    const getVariant = computed(() => {
      let quaternaryContainer = false;
      if (themeRef.value) {
        const quaternary = (themeRef.value?.config ?? []).find(
          (style) => style.name === "quaternary-shadow-container"
        );

        quaternaryContainer = quaternary?.value === "true";
      }

      return quaternaryContainer ? "quaternary" : theme ? "" : "unbranded";
    });

    const logoUrl = computed(() => {
      if (theme) {
        if (theme.value.logo.signin) {
          return theme.value.logo.signin.url;
        } else if (theme.value.logo.app) {
          return theme.value.logo.app.url;
        }
      }
      return null;
    });

    const model = ref({
      email: "",
      password: "",
      remember: false,
    });

    const attemptingSignIn = ref(false);
    const errors = reactive<{ [K: string]: string }>({});
    const rules: ValidationArgs = {
      email: {
        required: helpers.withMessage(
          "Please enter a valid email address",
          required
        ),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      password: {
        required: helpers.withMessage(
          "Please enter a valid password",
          required
        ),
        minLength: helpers.withMessage(
          "Please enter a valid password",
          minLength(2)
        ),
      },
    };

    const passwordVisible = ref(false);
    const passwordIcon = computed(() =>
      passwordVisible.value ? "eye" : "eye-slash"
    );
    const passwordType = computed(() =>
      passwordVisible.value ? "text" : "password"
    );
    const togglePasswordVisibility = () =>
      (passwordVisible.value = !passwordVisible.value);

    const handleSubmit = async () => {
      try {
        attemptingSignIn.value = true;

        //define regex for escaping special characters
        const escapeChars = new RegExp(/(<([^>]+)>)/gi);

        // remove characters from input and set the state
        const { email, password } = model.value;
        const escapedEmail = email.replace(escapeChars, "");
        const escapedPassword = password.replace(escapeChars, "");
        await auth.login(escapedEmail, escapedPassword, tenant.value.name);
        await permission.setFeatureConfigs();
        await store.dispatch("setThemeUpdateAllowed", true);
        store.dispatch("setEditMode", false);

        const returnUrl = getCookie("ReturnUrl");
        setCookie("ReturnUrl", "", -1);

        if (returnUrl && !returnUrl.includes("signin")) {
          router.push(decodeURIComponent(returnUrl));
        } else {
          router.push("/");
        }
      } catch (err: any) {
        const { response } = err;

        if (response || err?.message.length > 0) {
          let body = "errors.unexpected";
          if (err?.message.length > 0) {
            switch (err.message) {
              case "SequelizeDatabaseError":
                body = "auth.error.unexpected";
                break;
              default:
                body = err.message;
                break;
            }
          }
          if (response?.status === 401) {
            body = "auth.invalid";
          } else if (response?.status === 429) {
            body = "auth.locked";
          }

          inPageNotification("auth.unable-to-sign-in", body);
        }
        model.value.password = "";
        attemptingSignIn.value = false;
      }
    };

    const themeRef = ref<any>();
    const getTheme = async () => {
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const { currentTenant } = store.getters;
    const tenant = ref();
    onMounted(async () => {
      getTheme();

      tenant.value = { name: currentTenant.name };
      store.dispatch("clearAuth");
    });

    return {
      model,
      logoUrl,
      errors,
      rules,
      passwordIcon,
      passwordType,
      passwordVisible,
      attemptingSignIn,
      togglePasswordVisibility,
      handleSubmit,
      getVariant,
    };
  },
});
