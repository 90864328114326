
import { defineComponent } from "vue";

const buttonSizes = ["", "lg", "sm"];
const buttonVariants = [
  "",
  "secondary",
  "tertiary",
  "transparent",
  "danger",
  "error",
  "error-link",
  "link",
  "unbranded",
  "disabled",
  "gradient-02",
];

export default defineComponent({
  emits: ["click"],
  props: {
    size: {
      type: String,
      default: () => "",
      validator: (value: string) => buttonSizes.some((v) => v === value),
    },
    variant: {
      type: String,
      default: () => "",
      validator: (value: string) => buttonVariants.some((v) => v === value),
    },
    text: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: String,
  },
  setup(props, { emit }) {
    const handleClick = () => emit("click");

    return { handleClick };
  },
});
