import { onMounted, onBeforeUnmount, ref } from "vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useYoutubePlayer = (
  elementId: string,
  options: YT.PlayerOptions
) => {
  const player = ref<YT.Player>();
  const events = ref<Record<string, string>>({});
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/player_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).onYouTubeIframeAPIReady = () => {
      const { PlayerState } = YT;

      events.value[PlayerState.ENDED] = "ended";
      events.value[PlayerState.PLAYING] = "playing";
      events.value[PlayerState.PAUSED] = "paused";
      events.value[PlayerState.BUFFERING] = "buffering";
      events.value[PlayerState.CUED] = "cued";
    };
  }

  const createPlayer = () => {
    if (typeof YT !== "undefined") {
      player.value = new YT.Player(elementId, options);
    } else {
      setTimeout(createPlayer, 500);
    }
  };

  onMounted(() => createPlayer());

  onBeforeUnmount(() => {
    if (player.value && player.value.destroy) {
      player.value.destroy();
    }
    delete player.value;
  });

  return { player, events };
};
