import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-894dd760"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "wrapper", {}, () => [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigate', _ctx.row.id)))
    }, "View User")
  ], true)
}