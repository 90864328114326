
import { computed, defineComponent, ref, onMounted } from "vue";
import { tenantBilling, tenantBillingCancel } from "@/api/tenant-config.api";
import { useModal } from "@/plugins/modal";
import { useLoading } from "@/plugins/loading";

interface dateFormatOptions {
  weekday?: "long" | "short" | "narrow" | undefined;
  day: "numeric" | "2-digit" | undefined;
  month: "long" | "numeric";
  year: "numeric";
}

export default defineComponent({
  // props: [
  //   "currentPlan",
  //   "currencySymbol",
  //   "userCount",
  //   "paymentDate",
  //   "userNameFull",
  //   "paymentMethods",
  //   "addPaymentMethodUrl",
  //   "billingHistory",
  // ],
  setup() {
    const showModal = useModal();
    const showLoader = useLoading();
    const currentPlan = ref({
      name: "",
      pricePerUserPerMonth: 0,
      pricePerMonth: 0,
    });
    const subscriptionActive = ref<boolean>(false);
    const currencySymbol = ref<string>("£");
    const subscriptionstatus = ref<string>("");
    const subscriptionDescription = ref<string>("");
    const userCount = ref<number>(35);
    const nextPaymentTs = ref<number>(1630537199);
    const nextPaymentDate = ref<Date>(new Date(nextPaymentTs.value));
    const acceptedMethods = [
      "visa",
      "mastercard",
      "discover",
      "jcb",
      "union_pay",
    ];
    const methods: any[] = [];
    const paymentMethods = ref(methods);

    const invoices: any[] = [];
    const billingHistory = ref(invoices);
    const billingLoading = ref(true);

    const dateOptions: dateFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const paymentDate = computed<string>(() =>
      nextPaymentDate.value.toLocaleDateString("en-GB", dateOptions)
    );

    onMounted(async () => {
      const response = await tenantBilling();

      billingLoading.value = false;

      const billingData = response.data.payload.result;

      
      if (Object.keys(billingData).length > 0) {
        if (billingData.subscriptionStatus == "canceled") {
          cancelTenant();
        } else {
          nextPaymentDate.value = new Date(billingData.nextBillDate * 1000);
          currentPlan.value.name = billingData.product.name;
          currentPlan.value.pricePerMonth = billingData.pricePerMonth;
          subscriptionDescription.value = billingData.planDescription;
          subscriptionActive.value = true;
        }

        paymentMethods.value.push({
          type: billingData.paymentMethod.brand,
          code: `..... ..... ${billingData.paymentMethod.last4}`,
          default: true,
          expires: `${billingData.paymentMethod.exp_month
            .toString()
            .padStart(2, "0")}/${billingData.paymentMethod.exp_year}`,
        });

        for (const [k, h] of Object.entries(billingData.billingHistory)) {
          if (billingData.billingHistory[k].paid) {
            const billingDate = new Date(
              billingData.billingHistory[k].created * 1000
            );
            billingHistory.value.push({
              date: billingDate.toLocaleDateString("en-GB", dateOptions),
              amount:
                billingData.billingHistory[k].amount_paid > 0
                  ? billingData.billingHistory[k].amount_paid / 100
                  : billingData.billingHistory[k].amount_paid,
              plan: billingData.billingHistory[k].number,
              invoiceUrl: billingData.billingHistory[k].hosted_invoice_url,
            });
          }
        }
      }
    });

    const cancelTenant = async () => {
      currentPlan.value.name = "Cancelled";
      currentPlan.value.pricePerUserPerMonth = 0;
      currentPlan.value.pricePerMonth = 0;
      subscriptionDescription.value = "";
      subscriptionActive.value = false;
    };

    const cancelPlan = async () => {
      showModal({
        title: "Cancel Plan?",
        body: "Are you sure you want to cancel your subscription plan? You will continue to have access until your next payment due date.",
        onConfirm: async () => {
          showLoader(true);

          const response = await tenantBillingCancel();

          if (response.status === 200) {
            cancelTenant();
          }

          showLoader(false);
        },
      });
    };

    return {
      subscriptionActive,
      currentPlan,
      currencySymbol,
      subscriptionstatus,
      subscriptionDescription,
      userCount,
      acceptedMethods,
      paymentMethods,
      billingHistory,
      paymentDate,
      cancelTenant,
      cancelPlan,
      falseHide: false,
      billingLoading,
    };
  },
});
