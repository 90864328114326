import httpClient, { ApiResponse } from "./httpClient";

const END_POINT = "/globalunits";

const getAllGlobalUnits = (): Promise<any[]> => httpClient.get(END_POINT);

export const tenantSetupComplete = async (): Promise<ApiResponse<any>> =>
  httpClient.post(`${END_POINT}/setup-complete`, {});

export { getAllGlobalUnits };
