import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aa88b4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-wrapper__inner" }
const _hoisted_2 = { class: "data-container" }
const _hoisted_3 = { class: "page-content" }
const _hoisted_4 = { class: "page-content__inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["page-wrapper full-height", { loading: _ctx.isLoading }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.item)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
                      key: 0,
                      class: "hotspot-action--container",
                      action: _ctx.itemAsAction,
                      currentActionId: _ctx.item.id,
                      actionTitle: _ctx.getTextItem(_ctx.textItems, 'side-menu-item'),
                      actionDescription: 
                  _ctx.getTextItem(_ctx.textItems, 'side-menu-item-description')
                ,
                      viewOnly: true
                    }, null, 8, ["action", "currentActionId", "actionTitle", "actionDescription"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ], 2)
    ]),
    _: 1
  }))
}