
import { useRouter } from "vue-router";
import {
  computed,
  defineComponent,
  ref,
  watch,
  PropType,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { usePermissionProvider } from "@/plugins/permission";
import { Manager, User } from "@/models";
import Avatar from "@/components/Avatar.vue";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Switch from "@/components/Switch.vue";
import BackLink from "@/components/BackLink.vue";
import UserDetails from "@/components/user/details/UserDetails.vue";
import UserReferences from "@/components/user/references/UserReferences.vue";
import UserOverview from "@/components/user/overview/UserOverview.vue";
import UserContact from "@/components/user/contact/UserContact.vue";
import UserContacts from "@/components/user/contacts/UserContacts.vue";
import UserPayroll from "@/components/user/payroll/UserPayroll.vue";
import UserDocuments from "@/components/user/documents/UserDocuments.vue";
import UserVideos from "@/components/user/videos/UserVideos.vue";
import UserActivity from "@/components/user/activity/UserActivity.vue";
import UserChecklist from "@/components/user/checklist/UserChecklist.vue";
import UserChangePassword from "@/components/user/password/UserChangePassword.vue";
import { useLoading } from "@/plugins/loading";
import { useModal } from "@/plugins/modal";
import { UserNavItem } from "@/interfaces/UserNavItem.interface";
import { archiveUser, completeUser } from "@/api/user.api";
import { getTenantConfig } from "@/api/tenant-config.api";
import Select from "@/components/form/Select.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "UserView",
  components: {
    Avatar,
    Button,
    Switch,
    UserDetails,
    UserReferences,
    UserOverview,
    UserContact,
    UserPayroll,
    UserDocuments,
    UserVideos,
    UserContacts,
    UserActivity,
    UserChecklist,
    UserChangePassword,
    Modal,
    BackLink,
    Select,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    userIsLoading: Boolean,
    navItemConfig: {
      type: Array as PropType<UserNavItem[]>,
      default: () => [],
    },
    isNewUser: Boolean,
    actionButton: {
      type: String,
      default: "",
    },
  },
  emits: ["refresh-user"],
  setup(props, { emit }) {
    const showModal = useModal();
    const router = useRouter();
    const showLoader = useLoading();
    const { userHasPermission } = usePermissionProvider();
    const store = useStore();

    const { inPageNotification } = useNotification();

    const authenticatedUserId = store.getters.currentUser.id;
    const isAuthenticatedUser = computed(
      () => authenticatedUserId && authenticatedUserId == props.user.id
    );

    const pageTitle = ref(
      props.user ? `${props.user.firstName} ${props.user.surname}` : ""
    );
    const pageSubtitle = ref(props.user ? props.user.position : "");

    const isActive = computed(() => !props.user.isDeactivated);
    const handleStatusChange = async (value: boolean) => {
      await props.user.updateActiveStatus(!value);
      emit("refresh-user");
    };
    const avatarFallbackText = computed(() => {
      const firstName = props.user.firstName[0] ?? "";
      const surname = props.user.surname[0] ?? "";
      return `${firstName}${surname}`;
    });
    const avatarVariant = computed(() =>
      props.user.isLockedOut ? "danger" : ""
    );
    const avatarIcon = computed(() =>
      props.user && props.user.isLockedOut ? "lock" : ""
    );

    const deleteButtonIsBlock = computed(() => store.getters.screenSize < 1024);
    const managerSelectOptions = ref<any[]>([]);
    const selectedManager = ref<any>(null);

    const showDeleteModal = ref<boolean>(false);

    const handleManagerChange = async ({ value }) => {
      selectedManager.value = value;
    };

    const handleDeleteClick = async () => {
      const managers = await Manager.getAll();
      managerSelectOptions.value = managers
        .filter((manager) => manager.id !== props.user.id)
        .map((manager) => ({
          value: manager.id,
          text: `${manager.firstName} ${manager.surname}`,
        }));
      showDeleteModal.value = true;
    };

    const handleDeleteCancel = () => (showDeleteModal.value = false);
    const handleConfirmDelete = async () => {
      if (!props.isNewUser) {
        try {
          showLoader(true);
          await props.user.delete(selectedManager.value);
          managerSelectOptions.value = [];
          selectedManager.value = null;
          router.push("/users");
        } catch (error: any) {
          inPageNotification(
            "common.error",
            "models.user.delete.error" +
              (error["code"] ? "." + error["code"] : ""),
            "error"
          );
        } finally {
          showLoader(false);
        }
      }
    };

    const handleArchiveClick = async () => {
      store.dispatch("showLoader", true);
      if (props.user.id) {
        try {
          const response = await archiveUser(props.user.id);
          inPageNotification(
            "common.success",
            "models.user.archive.success",
            "success"
          );
          const data = response.data;
          const newBlob: Blob = new Blob([data], { type: "application/zip" });
          const url = window.URL.createObjectURL(newBlob);
          const fileLink = document.createElement("a");
          fileLink.href = url;
          fileLink.setAttribute(
            "download",
            `${props.user.firstName}_${props.user.surname}_archive`
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        } catch (err) {
          inPageNotification(
            "common.error",
            "models.user.archive.error",
            "error"
          );
        }
      } else {
        inPageNotification(
          "common.error",
          "models.user.archive.error",
          "error"
        );
      }
      store.dispatch("showLoader", false);
    };

    const isComplete = computed(
      () => props.user.progress.overallPercentage === 100
    );

    const doMarkComplete = async () => {
      if (props.user.id) {
        await props.user.markComplete();
        const response = await completeUser(props.user.id);
        inPageNotification(
          "common.success",
          "models.user.mark-complete.success",
          "success"
        );
      } else {
        inPageNotification(
          "common.error",
          "models.user.archive.error",
          "error"
        );
      }
    };

    const handleMarkCompleteClick = async () => {
      store.dispatch("showLoader", true);

      showModal({
        title: "Mark Complete?",
        body: "Are you sure you wish to mark this user as 100%?",
        onConfirm: async () => {
          await doMarkComplete();
          store.dispatch("showLoader", false);
        },
      });

      store.dispatch("showLoader", false);
    };

    const navItems = computed(() =>
      props.navItemConfig.filter((item) => userHasPermission(item.permission))
    );
    const currentNavItem = computed(() => {
      const currentRoute = router.currentRoute.value;
      const routeVal = (
        currentRoute.params && currentRoute.params.section
          ? currentRoute.params.section
          : currentRoute.query["tab"]
      ) as string;
      return (
        navItems.value.find((item) => item.url === routeVal) ||
        navItems.value[0]
      );
    });

    const managers = computed(() =>
      props.user.managers.map((manager) => manager.id)
    );
    const groups = computed(() => props.user.groups.map((group) => group.id));

    const handleUpdatePageTitle = async (value: string) => {
      pageTitle.value = value;
    };
    const handleUpdatePageSubtitle = async (value: string) => {
      pageSubtitle.value = value;
    };
    const handleUserDetailsCreated = (id: string) =>
      router.push(`/users/${id}`);

    const handleAvatarUpdate = (image: any) => {
      if (isAuthenticatedUser.value) {
        const userDetails = { ...store.state.user.currentUser };
        userDetails.profileImage = image;
        store.dispatch("setUserDetails", userDetails);
      }
    };

    const isMounted = ref(false);
    const allowManualComplete = ref(false);
    onMounted(async () => {
      isMounted.value = true;
      const response = await getTenantConfig("allow-user-manual-complete");
      const tenantConfig = response.data.payload.result;
      if (tenantConfig) {
        allowManualComplete.value = true;
      }
    });

    watch(props, () => {
      pageTitle.value = props.user
        ? `${props.user.firstName} ${props.user.surname}`
        : "";
      pageSubtitle.value = props.user ? props.user.position : "";
    });

    const profileImageData = computed(() => {
      return {
        size: "contact",
        language: "22d4d69d-3ec0-47be-ac7c-1029a1a8ed4d",
        reference: "profile-picture",
        userId: props.user?.id ? props.user?.id : "",
        blockDelete: "false",
      };
    });

    return {
      isMounted,
      pageTitle,
      pageSubtitle,
      isActive,
      avatarFallbackText,
      avatarVariant,
      avatarIcon,
      deleteButtonIsBlock,
      navItems,
      managers,
      groups,
      currentNavItem,
      showDeleteModal,
      isComplete,
      handleDeleteClick,
      handleArchiveClick,
      handleMarkCompleteClick,
      handleConfirmDelete,
      handleDeleteCancel,
      handleStatusChange,
      handleUpdatePageTitle,
      handleUpdatePageSubtitle,
      handleUserDetailsCreated,
      handleAvatarUpdate,
      allowManualComplete,
      isAuthenticatedUser,
      profileImageData,
      managerSelectOptions,
      handleManagerChange,
      selectedManager,
      userHasPermission,
    };
  },
});
