import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c96bd4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_ChipDropdown = _resolveComponent("ChipDropdown")!
  const _component_ListMenu = _resolveComponent("ListMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.customClass)
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["chip", [_ctx.size, { active: _ctx.chip.show }]]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChip(_ctx.chip.id)))
    }, [
      _createVNode(_component_TranslatedText, {
        path: _ctx.chip.name
      }, null, 8, ["path"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-container", _ctx.size])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.chip.dropdownType === 'checklist')
          ? (_openBlock(), _createBlock(_component_ChipDropdown, {
              key: 0,
              chip: _ctx.chip,
              chipSize: _ctx.size,
              onSelectAll: _ctx.selectAll,
              onToggleChip: _ctx.toggleChip,
              onSelectChipItem: _ctx.selectChipItem
            }, null, 8, ["chip", "chipSize", "onSelectAll", "onToggleChip", "onSelectChipItem"]))
          : (_openBlock(), _createBlock(_component_ListMenu, {
              key: 1,
              menuItems: _ctx.chip.items,
              show: _ctx.chip.show,
              customClass: "mobile-full-width",
              onToggleMenu: _ctx.toggleChip,
              onClickMenuItem: _ctx.clickMenuItem
            }, null, 8, ["menuItems", "show", "onToggleMenu", "onClickMenuItem"]))
      ])
    ], 2)
  ], 2))
}