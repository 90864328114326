
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useLanguage } from "@/composables";
import {
  getReferenceRecord,
  putViewedReferenceRecord,
  putReferenceRecord,
} from "@/api/references.api";
import ExternalLayout from "@/layouts/ExternalLayout.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import TextArea from "@/components/form/TextArea.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/form/Checkbox.vue";

import { useNotification } from "@/composables/useNotification";
import { Schemas, useSchema } from "@/plugins/schema";
import { helpers, required } from "@vuelidate/validators";

export default defineComponent({
  name: "Referee",
  components: {
    ExternalLayout,
    Form,
    FormInput,
    TextArea,
    Button,
    DatePicker,
    Checkbox,
  },
  setup() {
    const router = useRouter();
    const { translateText, createDateString } = useLanguage();
    const { referenceId, referenceRecordId, authId } =
      router.currentRoute.value.params;
    const store = useStore();
    const tenant = store.getters.currentTenant.name;
    const referenceRecord = ref();
    const confirmed = ref("");
    const justCompleted = ref(false);
    const charCount = (v, k, limit = 255) => {
      if (v[k]) {
        if (v[k].length > limit) {
          v[k] = v[k].substring(0, limit);
        }
        return translateText("common.character-limit", [
          {
            placeholder: "limit",
            value: limit,
          },
          {
            placeholder: "remaining",
            value: limit - v[k].length,
          },
        ]);
      }
      return "";
    };

    const { inPageNotification } = useNotification();

    onMounted(async () => {
      const response = await getReferenceRecord(
        referenceId as string,
        referenceRecordId as string,
        authId as string,
        tenant
      );
      const refRecord = response.data.payload.result;

      if (refRecord.sent) {
        referenceRecord.value = response.data.payload.result;
        if (referenceRecord.value.reference.characterReference) {
          const refDateFrom = new Date(
            referenceRecord.value.yearFrom +
              "-" +
              referenceRecord.value.monthFrom +
              "-" +
              referenceRecord.value.dayFrom
          );
          const refDateTo = new Date(
            referenceRecord.value.yearTo +
              "-" +
              referenceRecord.value.monthTo +
              "-" +
              referenceRecord.value.dayTo
          );
          referenceRecord.value = {
            ...referenceRecord.value,
            ...{
              dateFrom: refDateFrom.toISOString().split("T")[0],
              dateTo: refDateTo.toISOString().split("T")[0],
            },
          };
        }

        if (!refRecord.viewed) {
          await putViewedReferenceRecord(
            referenceId as string,
            referenceRecordId as string,
            authId as string,
            tenant
          );
        }
      }

      if (Object.keys(schemaCharacterReference).length <= 0) {
        characterReferenceValid.value = true;
      }
    });

    const handleConfirmChange = (value) => {
      confirmed.value = value;
    };

    const isSubmissionValid = () => {
      return (
        (characterReferenceRecordModel.value !== null ||
          referenceValid.value) &&
        (characterReferenceRecordModel.value !== null ||
          employerReferenceValid.value) &&
        (referenceRecord.value.reference.employerReference !== null ||
          characterReferenceValid.value)
      );
    };

    const submitReferenceRecord = async () => {
      try {
        submitCheckReference.value = true;
        submitCheckEmployerReference.value = true;
        submitCheckCharacterReference.value = true;

        if (!isSubmissionValid()) {
          console.log("Not Valid");
          console.log(
            "referenceValid",
            characterReferenceRecordModel.value !== null || referenceValid.value
          );
          console.log(
            "employerReferenceValid",
            characterReferenceRecordModel.value !== null ||
              employerReferenceValid.value
          );
          console.log(
            "characterReferenceValid",
            referenceRecord.value.reference.employerReference !== null ||
              characterReferenceValid.value
          );
          console.log("errorModelReference", errorModelReference.value);
          console.log(
            "errorModelEmployerReference",
            errorModelEmployerReference.value
          );
          console.log(
            "errorModelCharacterReference",
            errorModelCharacterReference.value
          );
          return false;
        }

        let savedRefRecord;
        if (referenceRecord.value.reference.characterReference) {
          referenceRecord.value.characterReferenceRecord = {
            confirmed: confirmed.value,
          };
          const { ...refRecord } = referenceRecord.value;
          savedRefRecord = refRecord;
        } else {
          savedRefRecord = { ...referenceRecord.value };
        }

        await putReferenceRecord(
          referenceId as string,
          referenceRecordId as string,
          authId as string,
          tenant,
          savedRefRecord
        );

        justCompleted.value = true;
        referenceRecord.value.completed = true;
        inPageNotification(
          "references.saved-title",
          "references.saved",
          "success"
        );
      } catch (error) {
        inPageNotification(
          "references.error-title",
          "references.error-save",
          "error"
        );
      }
    };

    const referenceRecordModel = computed({
      get: () => referenceRecord.value,
      set: (value) => (referenceRecord.value = value),
    });

    const employerReferenceRecordModel = computed({
      get: () => referenceRecord.value.employerReferenceRecord,
      set: (value) => (referenceRecord.value.employerReferenceRecord = value),
    });

    const characterReferenceRecordModel = computed({
      get: () => referenceRecord.value.reference.characterReference,
      set: (value) =>
        (referenceRecord.value.reference.characterReference = value),
    });
    const referenceErrors = ref<{ [K: string]: string }>({});
    const employerReferenceErrors = ref<{ [K: string]: string }>({});
    const characterReferenceErrors = ref<{ [K: string]: string }>({});

    const errorModelReference = computed({
      get: () => referenceErrors.value,
      set: (value) => (referenceErrors.value = value),
    });

    const errorModelEmployerReference = computed({
      get: () => employerReferenceErrors.value,
      set: (value) => (employerReferenceErrors.value = value),
    });

    const errorModelCharacterReference = computed({
      get: () => characterReferenceErrors.value,
      set: (value) => (characterReferenceErrors.value = value),
    });

    const submitCheckReference = ref(false);
    const submitCheckEmployerReference = ref(false);
    const submitCheckCharacterReference = ref(false);
    const submitCheckReferenceRef = computed({
      get: () => submitCheckReference.value,
      set: (value) => (submitCheckReference.value = value),
    });
    const submitCheckEmployerReferenceRef = computed({
      get: () => submitCheckEmployerReference.value,
      set: (value) => (submitCheckEmployerReference.value = value),
    });
    const submitCheckCharacterReferenceRef = computed({
      get: () => submitCheckCharacterReference.value,
      set: (value) => (submitCheckCharacterReference.value = value),
    });
    const referenceValid = ref(false);
    const employerReferenceValid = ref(false);
    const characterReferenceValid = ref(false);

    const schemaReference = computed(() => {
      const schema = { ...(useSchema(Schemas.RefereeReference) as any) };
      if (referenceRecordModel.value?.presentDay) {
        delete schema.dateTo;
      }
      return schema;
    });

    const schemaEmployerReference = useSchema(
      Schemas.RefereeEmployerReference
    ) as any;
    const schemaCharacterReference = useSchema(
      Schemas.RefereeCharacterReference
    ) as any;

    let assesQuestions: string[] = [];

    if (tenant === "north") {
      schemaEmployerReference["capacity"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["summary"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["additional-information"] = {
        required: helpers.withMessage("This field is required", required),
      };
    } else if (tenant === "mww") {
      assesQuestions = [
        "competence",
        "withoutSupervision",
        "underPressure",
        "planning",
        "manageOthers",
        "leadership",
        "reliability",
        "punctuality",
        "attendance",
        "honesty",
        "efficiency",
        "communications",
        "relationships",
      ];
      for (const assesQuestion of assesQuestions) {
        schemaEmployerReference[assesQuestion] = {
          required: helpers.withMessage("This field is required", required),
        };
      }

      schemaEmployerReference["reasonForLeaving"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["reemploy"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["summary"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["name"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["title"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["companyName"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["telephone"] = {
        required: helpers.withMessage("This field is required", required),
      };
      schemaEmployerReference["telephoneEnquiry"] = {
        required: helpers.withMessage("This field is required", required),
      };
    }

    const formatDatePickerDate = (date) => {
      const formattedDate = new Date(date);

      return formattedDate.toLocaleDateString("en-us", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    return {
      translateText,
      createDateString,
      referenceRecord,
      justCompleted,
      confirmed,
      submitReferenceRecord,
      handleConfirmChange,
      tenant,
      referenceRecordModel,
      employerReferenceRecordModel,
      characterReferenceRecordModel,
      errorModelReference,
      errorModelEmployerReference,
      errorModelCharacterReference,
      referenceValid,
      employerReferenceValid,
      characterReferenceValid,
      schemaReference,
      schemaEmployerReference,
      schemaCharacterReference,
      charCount,
      submitCheckReferenceRef,
      submitCheckEmployerReferenceRef,
      submitCheckCharacterReferenceRef,
      assesQuestions,
      formatDatePickerDate,
    };
  },
});
