import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06d0c78c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value", "id", "name", "max", "required", "disabled"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 3,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container", [_ctx.customClass, { required: _ctx.required }]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass(["icon", {
        active: _ctx.isActive,
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      }])
        }, [
          _createVNode(_component_TranslatedText, { path: _ctx.label }, null, 8, ["path"])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "date",
          class: _normalizeClass(["icon", {
        active: _ctx.isActive,
        error: !!_ctx.error,
        'message-visible': _ctx.error && !_ctx.isFocussed,
      }]),
          value: _ctx.inputDisplay,
          id: _ctx.id,
          name: _ctx.id,
          max: _ctx.maxDate,
          required: _ctx.required,
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"])),
          autocomplete: "off",
          "data-lpignore": "true",
          "data-form-type": "other"
        }, null, 42, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["input-dummy", {
        'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
        'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
      }])
        }, [
          _createElementVNode("img", {
            class: "editIcon",
            src: require('@/assets/img/icon-edit.svg'),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleEditClick && _ctx.handleEditClick(...args)))
          }, null, 8, _hoisted_3)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.error && !_ctx.isFocussed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_TranslatedText, { path: _ctx.error }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}