
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle-all", "toggle-checkbox"],
  setup(props, context) {
    const toggle = (e: Event) => {
      e.stopPropagation();
      if (props.selectAll) {
        context.emit("toggle-all");
      } else {
        context.emit("toggle-checkbox");
      }
    };

    return {
      toggle,
    };
  },
});
