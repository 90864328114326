
import { defineComponent, computed, ref } from "vue";
import PdfDocument from "@/components/PdfDocument.vue";

export default defineComponent({
  components: {
    PdfDocument,
  },
  props: {
    src: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const currentPage = ref(1);
    const pageCount = ref(1);
    const scale = ref(1.5);

    const errorDetail = ref<{ title?: string; description?: string }>({});
    const documentRendered = ref(false);
    const showDocumentLoader = computed(
      () => props.src && !documentRendered.value
    );

    const handlePdfLoaded = (pages) => {
      pageCount.value = pages.length;
      emit("documentLoaded");
    };

    const handleDocumentRendered = (isRendered) => {
      if (documentRendered.value != isRendered) {
        documentRendered.value = isRendered;
      }
    };

    const handleDocumentError = (detail) => {
      errorDetail.value.title = detail?.title ?? "documents.error.title";
      errorDetail.value.description =
        detail?.description ?? "documents.error.description";
      documentRendered.value = true;
    };

    const handleZoomIn = () => {
      if (scale.value < 2.0) {
        scale.value += 0.5;
      }
    };

    const handleZoomOut = () => {
      if (scale.value > 0.5) {
        scale.value -= 0.5;
      }
    };

    return {
      currentPage,
      pageCount,
      scale,
      showDocumentLoader,
      documentRendered,
      errorDetail,
      handleDocumentRendered,
      handleDocumentError,
      handlePdfLoaded,
      handleZoomIn,
      handleZoomOut,
    };
  },
});
