
import { defineComponent } from "vue";
import Overlay from "@/components/Overlay.vue";

export default defineComponent({
  emits: ["confirm", "cancel", "dismiss", "remove"],
  components: {
    Overlay,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      default: "",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const handleConfirm = async () => emit("confirm");
    const handleDelete = async () => emit("remove");
    const handleDismiss = () => emit("dismiss");
    const handleCancel = () => emit("cancel");
    const handleCancelButton = () => emit("cancel", true);

    return {
      handleConfirm,
      handleDelete,
      handleDismiss,
      handleCancel,
      handleCancelButton,
    };
  },
});
