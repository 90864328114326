import httpClient, { ApiResponse } from "@/api/httpClient";
import { Group, Gender, Role } from "@/interfaces/domain";
import { Address } from "./Address";
import { EmergencyContact } from "./EmergencyContact";
import { UserContactModel } from "./UserContact";
import { Payroll } from "./Payroll";
import { Activity, ActivityFilters } from "./Activity";
import { PaginatedResult, PaginationDetails } from "@/interfaces/Pagination";
import { Checklist } from "./Checklist";
import { ContactNumber, Review } from ".";

export interface UserProgress {
  overallPercentage: number;
  hotspots: Array<UserProgressHotspot>;
}

export interface UserProgressHotspot {
  id: string;
  title: string;
  percentageComplete: number;
}

export class UserDetails {
  public id?: string;
  public title: string;
  public firstName: string;
  public middleName?: string;
  public surname: string;
  public preferredName?: string;
  public email: string;
  public gender?: Gender;
  public genderId: string;
  public maritalStatus: string;
  public dob: string;
  public placeOfBirth: string;
  public nationality: string;
  public preferredLanguageId: string;
  public secondaryEmail: string;
  public startDate?: string;
  public endDate?: string;
  public position: string;
  public roles: Role[];
  public managers: Manager[];
  public groups: Group[];
  public employeeNumber?: string;
  public tags: any[];
  public additionalFields?: any;

  public get groupIds(): Array<string> {
    return this.groups.map(({ id }) => id);
  }

  public get managerIds(): Array<string> {
    return this.managers.map(({ id }) => id);
  }

  constructor({
    id,
    title = "",
    firstName = "",
    middleName,
    surname = "",
    preferredName,
    email = "",
    gender,
    genderId = "",
    maritalStatus = "",
    dob = "",
    placeOfBirth = "",
    nationality = "",
    preferredLanguageId = "",
    secondaryEmail = "",
    startDate = "",
    endDate,
    position = "",
    roles = [],
    managers = [],
    groups = [],
    employeeNumber,
    tags = [],
    additionalFields = "",
  }: Partial<UserDetails> = {}) {
    this.id = id;
    this.title = title;
    this.firstName = firstName;
    this.middleName = middleName;
    this.surname = surname;
    this.preferredName = preferredName;
    this.email = email;
    this.genderId = genderId;
    this.gender = gender;
    this.maritalStatus = maritalStatus;
    this.dob = dob;
    this.placeOfBirth = placeOfBirth;
    this.nationality = nationality;
    this.preferredLanguageId = preferredLanguageId;
    this.secondaryEmail = secondaryEmail;
    this.startDate = startDate;
    this.endDate = endDate;
    this.position = position;
    this.roles = roles;
    this.managers = managers;
    this.groups = groups;
    this.employeeNumber = employeeNumber;
    this.tags = tags;
    this.additionalFields = additionalFields;
  }

  public static async get(userId: string): Promise<UserDetails> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: ApiResponse<any> = await httpClient.get(
      `/users/${userId}/details`
    );
    if (response.status === 200 && response.data.payload.result) {
      const details = response.data.payload.result;
      return new UserDetails(details);
    }

    throw new Error();
  }

  public static async getMe(): Promise<UserDetails> {
    const response: ApiResponse<any> = await httpClient.get(`/users/me`);

    if (response.status === 200 && response.data.payload.result) {
      const details = response.data.payload.result;
      return new UserDetails(details);
    }

    throw new Error();
  }

  public async save(isMe: boolean): Promise<void> {
    if (!this.gender || this.gender.id !== this.genderId) {
      this.gender = { id: this.genderId, name: "" };
    }
    if (!this.startDate) {
      this.startDate = new Date().toISOString();
    }

    const method = this.id ? "PUT" : "POST";
    const url = isMe ? `/users/me` : this.id ? `/users/${this.id}` : `/users`;
    const response = await httpClient.request({
      method,
      url,
      data: {
        ...this,
        groupIds: [...this.groupIds],
        managerIds: [...this.managerIds],
      },
    });

    if (response.status === 201 && response.data.payload.result) {
      this.id = response.data.payload.result.id;
    }
  }
}

export class User {
  public id?: string;
  public firstName: string;
  public surname: string;
  public email: string;
  public secondaryEmail: string;
  public dob: string;
  public genderId: string;
  public phoneNumber?: string;
  public mobileNumber?: string;
  public profileImage: string;
  public preferredLanguageId: string;
  public startDate: string;
  public endDate: string;
  public department: string;
  public position: string;
  public isDeactivated: boolean;
  public isLockedOut: boolean;
  public welcomeEmailSent: boolean;
  public groups: Group[];
  public groupIds: string[];
  public groupNames: Array<string>;
  public managers: User[];
  public managerIds: string[];
  public roles: string[];
  public progress: UserProgress;
  public progressCompleted: number;
  public addresses: Array<Address>;
  public emergencyContacts: Array<EmergencyContact>;
  public contactNumbers: Array<ContactNumber>;

  private userDetails?: UserDetails;
  private contact?: UserContactModel;
  private payroll?: Payroll;
  private activity?: PaginatedResult<Activity>;
  private checklists?: Array<Checklist>;
  public authorityToDisclose: boolean;
  public passedProbation: boolean;

  constructor({
    id,
    firstName = "",
    surname = "",
    email = "",
    secondaryEmail = "",
    dob = "",
    genderId = "",
    phoneNumber,
    mobileNumber,
    profileImage = "",
    preferredLanguageId = "",
    startDate = "",
    endDate = "",
    department = "",
    position = "",
    isDeactivated = false,
    isLockedOut = false,
    welcomeEmailSent = false,
    groups = [],
    groupNames = [] as Array<string>,
    groupIds = [] as string[],
    managers = [] as User[],
    managerIds = [] as string[],
    roles = [],
    progress = {
      overallPercentage: 0,
      hotspots: [] as Array<UserProgressHotspot>,
    },
    progressCompleted = 0,
    addresses = [] as Array<Address>,
    emergencyContacts = [] as Array<EmergencyContact>,
    contactNumbers = [] as Array<ContactNumber>,
    authorityToDisclose = false,
    passedProbation = false,
  }: Partial<User> = {}) {
    this.id = id;
    this.firstName = firstName;
    this.surname = surname;
    this.email = email;
    this.secondaryEmail = secondaryEmail;
    this.dob = dob;
    this.genderId = genderId;
    this.phoneNumber = phoneNumber;
    this.mobileNumber = mobileNumber;
    this.profileImage = profileImage;
    this.preferredLanguageId = preferredLanguageId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.department = department;
    this.position = position;
    this.isDeactivated = isDeactivated;
    this.isLockedOut = isLockedOut;
    this.welcomeEmailSent = welcomeEmailSent;
    this.groups = groups;
    this.groupNames = groupNames;
    this.groupIds = groupIds;
    this.managers = managers;
    this.managerIds = managerIds;
    this.roles = roles;
    this.progress = progress;
    this.progressCompleted = progressCompleted;
    this.addresses = addresses;
    this.emergencyContacts = emergencyContacts;
    this.contactNumbers = contactNumbers;
    this.authorityToDisclose = authorityToDisclose;
    this.passedProbation = passedProbation;
  }

  public static async get(userId: string): Promise<User> {
    const response: ApiResponse<User> = await httpClient.get(
      `/users/${userId}/summary`
    );
    if (response.status === 200 && response.data.payload.result) {
      return new User(response.data.payload.result);
    }

    throw new Error();
  }

  public async getMeDetails(
    bypassCache = false
  ): Promise<UserDetails | undefined> {
    if (this.id) {
      if (this.userDetails && !bypassCache) return this.userDetails;

      this.userDetails = await UserDetails.getMe();
      return this.userDetails;
    }
  }

  public async getDetails(
    bypassCache = false
  ): Promise<UserDetails | undefined> {
    if (this.id) {
      if (this.userDetails && !bypassCache) return this.userDetails;

      this.userDetails = await UserDetails.get(this.id);
      return this.userDetails;
    }
  }

  public async getContact(
    bypassCache = false
  ): Promise<UserContactModel | undefined> {
    if (this.id) {
      if (this.contact && !bypassCache) return this.contact;
      this.contact = await UserContactModel.get(this.id);
      return this.contact;
    }
  }

  public async getPayroll(bypassCache = false): Promise<Payroll | undefined> {
    if (this.id) {
      if (this.payroll && !bypassCache) return this.payroll;

      this.payroll = await Payroll.get(this.id);
      return this.payroll;
    }
  }

  public static async getReviews(userId: string): Promise<Review[]> {
    const response: ApiResponse<Review> = await httpClient.get(
      `/reviews/${userId}`
    );
    if (response.status === 200 && response.data.payload.results) {
      return response.data.payload.results;
    }

    throw new Error();
  }

  public static async getReviewsByHotspotAndUser(
    hotspotId: string
  ): Promise<Review[]> {
    const response: ApiResponse<Review> = await httpClient.get(
      `/reviews/me/hotspot/${hotspotId}`
    );
    if (response.status === 200 && response.data.payload.results) {
      return response.data.payload.results;
    }

    throw new Error();
  }

  public static async putMyReview(reviewId: string): Promise<Review> {
    const response: ApiResponse<Review> = await httpClient.put(
      `/reviews/me/${reviewId}`,
      {}
    );
    if (response.data.payload.result) {
      return response.data.payload.result as Review;
    }

    throw new Error();
  }

  public static async putReview(
    review: Review,
    passedProbation = false
  ): Promise<Review> {
    const response: ApiResponse<Review> = await httpClient.put(`/reviews`, {
      review,
      passedProbation,
    });
    if (response.data.payload.result) {
      return response.data.payload.result as Review;
    }

    throw new Error();
  }

  public static async addExtensionReview(userId: string): Promise<Review> {
    const response: ApiResponse<Review> = await httpClient.post(
      `/reviews/${userId}`,
      {}
    );
    if (response.data.payload.result) {
      return response.data.payload.result as Review;
    }

    throw new Error();
  }

  public async getActivity(
    pagination: PaginationDetails,
    sortOrder: string,
    filters: ActivityFilters,
    bypassCache = false
  ): Promise<PaginatedResult<Activity> | undefined> {
    if (this.id) {
      if (this.activity && !bypassCache) return this.activity;

      this.activity = await Activity.get(
        this.id,
        pagination,
        sortOrder,
        filters
      );
      return this.activity;
    }
  }

  public async getChecklists(
    bypassCache = false
  ): Promise<Array<Checklist> | undefined> {
    if (this.checklists && !bypassCache) return this.checklists;

    this.checklists = await Checklist.get(this.id as string);
    return this.checklists;
  }

  public async updateActiveStatus(isDeactivated: boolean): Promise<void> {
    await httpClient.put(`/users/deactivate/${this.id}`, {
      isDeactivated,
    });
  }

  public async unlock(): Promise<void> {
    return await httpClient.put(`/user-status/unlock`, { userId: this.id });
  }

  public async sendWelcomeEmail(): Promise<void> {
    return await httpClient.post(`/users/${this.id}/welcome`);
  }

  public async markComplete(): Promise<boolean | void> {
    this.progress.hotspots.forEach((h) => (h.percentageComplete = 100));
    this.progress.overallPercentage = 100;
  }

  public async delete(managerId = null): Promise<void> {
    let params = "";
    if (managerId) {
      params = `?m_id=${managerId}`;
    }
    await httpClient.delete(`/users/${this.id}${params}`);
  }
}

export class Manager {
  public id: string;
  public firstName: string;
  public surname: string;
  public email: string;
  public roles: Array<Role>;

  constructor({
    id = "",
    firstName = "",
    surname = "",
    email = "",
    roles = [] as Array<Role>,
  }: Partial<Manager> = {}) {
    this.id = id;
    this.firstName = firstName;
    this.surname = surname;
    this.email = email;
    this.roles = roles;
  }

  public static async getAll(): Promise<Array<Manager>> {
    const response: ApiResponse<Manager> = await httpClient.get(
      `/users/managers`
    );
    if (response.status === 200 && response.data.payload.results) {
      const { results } = response.data.payload;
      return (results || []).map((r) => new Manager(r));
    }

    throw new Error();
  }
}
