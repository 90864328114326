import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e728140"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "search-bar" }
const _hoisted_3 = { class: "select-all" }
const _hoisted_4 = { class: "chip-dropdown-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip-dropdown", { show: _ctx.chip.show }]),
    id: `dropdown-${_ctx.chip.id}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Search, {
        id: `filter-search-${_ctx.chip.name}`,
        searchOpen: "",
        onFilterSearch: _ctx.filterChipItems
      }, null, 8, ["id", "onFilterSearch"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: _normalizeClass(["checkbox", { active: _ctx.chip.selectAll }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectAll(_ctx.chip.id)))
      }, [
        _createVNode(_component_TranslatedText, { path: "common.select-all" })
      ], 2)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chip.items, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
          (item.name.toLowerCase().includes(_ctx.searchTerm.toLowerCase()))
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: _normalizeClass(["chip-dropdown-item", { active: item.active }]),
                onClick: ($event: any) => (_ctx.selectChipItem(_ctx.chip.id, item.id))
              }, [
                _createVNode(_component_Checkbox, {
                  id: `chip-dropdown-checkbox-${item.name}`,
                  value: item.active,
                  theme: 'white',
                  onChange: ($event: any) => (_ctx.selectChipItem(_ctx.chip.id, item.id))
                }, null, 8, ["id", "value", "onChange"]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_TranslatedText, {
                    path: item.name
                  }, null, 8, ["path"])
                ])
              ], 10, _hoisted_5))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ], 10, _hoisted_1))
}