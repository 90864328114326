
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    menuItems: {
      type: Array,
    },
    show: {
      type: Boolean,
    },
    customClass: {
      type: String,
    },
  },
  emits: ["click-menu-item", "toggle-menu"],
  setup(_, context) {
    const handleMenuItemClick = (title: string, id: number) =>
      context.emit("click-menu-item", { title, id });

    const toggleMenu = () => context.emit("toggle-menu");

    return {
      handleMenuItemClick,
      toggleMenu,
    };
  },
});
