import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-310839c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "checked", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['checkbox ', _ctx.theme, _ctx.additionalClasses])
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.forcedId,
      checked: _ctx.value,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleValueChange && _ctx.handleValueChange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.forcedId,
      class: _normalizeClass(_ctx.forcedId)
    }, [
      _createVNode(_component_TranslatedText, { path: _ctx.text }, null, 8, ["path"])
    ], 10, _hoisted_2)
  ], 2))
}