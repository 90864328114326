
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { Action } from "@/models";
import { getTextItemFeatureConfigs } from "@/api/text-items.api";
import {
  deleteFormQuestion,
  createFormQuestion,
  updateFormQuestion,
  getFormQuestionsByActionOnly,
  reorderFormQuestion,
  resizeFormQuestion,
} from "@/api/form-questions.api";
import { FormQuestion } from "@/interfaces";
import { useFormQuestion } from "@/composables";
import FormQuestions from "@/components/form/FormQuestions.vue";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import NewFormElement from "@/components/form/NewFormElement.vue";
import * as v4 from "uuid/v4";
import { useModal } from "@/plugins/modal";

export default defineComponent({
  components: { FormQuestions, Button, Modal, NewFormElement },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const gridContainer = ref<any>(null);
    const showConfirmation = useModal();
    const store = useStore();
    let tenant = store.getters.currentTenant;

    const typeSelectOptions = computed(() => [
      {
        value: "document",
        text: "action.type.document",
      },
      {
        value: "video",
        text: "action.type.video",
      },
      {
        value: "link",
        text: "action.type.link",
      },
      {
        value: "form",
        text: "action.type.form",
      },
    ]);

    const levelSelectOptions = computed(() => [
      {
        value: "global",
        text: "Global",
      },
      {
        value: "group",
        text: "Group",
      },
      {
        value: "user",
        text: "User Specific",
      },
    ]);

    const users = ref<any[]>([]);
    const safeHTML = ref("");
    const safeUrls = ref([]);
    const userResponses = ref({});
    const formQuestions = ref<Array<FormQuestion>>([]);
    const currentUser = computed(() => store.getters.currentUser);
    const { setImages, validationTypes } = useFormQuestion();

    const getFormQuestions = async () => {
      formQuestions.value = [];
      userResponses.value = {};
      const response = await getFormQuestionsByActionOnly(props.action.id);
      formQuestions.value = response.data.payload
        .results as Array<FormQuestion>;
      formQuestions.value = formQuestions.value.sort(
        (fq1, fq2) => fq1.position - fq2.position
      );
      const imageReferences: string[] = [];
      for (const formQuestion of formQuestions.value) {
        if (formQuestion.questionType === "image") {
          imageReferences.push(formQuestion.imageReference);
        }
      }
      await setImages(imageReferences, currentUser.value.id, safeUrls.value);
    };

    let newFormQuestion = ref<{
      id?: string;
      text: string;
      questionType: string;
      validationNeeded: string;
      selectedValidation?: any[];
      choices?: any[];
      image?: null;
      imageUrl?: string;
      textItems?: any[];
    }>({
      text: "",
      questionType: "text",
      validationNeeded: "no",
    });

    const showModal = ref<boolean>(false);
    const editingFormQuestion = ref<boolean>(false);
    const handleCancel = () => {
      showModal.value = false;
      editingFormQuestion.value = false;
    };

    const resetNewFormQuestion = (full = true) => {
      if (full) {
        newFormQuestion.value.text = "";
        newFormQuestion.value.questionType = "text";
      }
      newFormQuestion.value.validationNeeded = "no";
      delete newFormQuestion.value.selectedValidation;
      delete newFormQuestion.value.choices;
      delete newFormQuestion.value.image;
      delete newFormQuestion.value.imageUrl;
    };

    const handleQuickDelete = async ({ formQuestionId }) => {
      if (formQuestionId) {
        showConfirmation({
          title: "Delete Question?",
          body: "Are you sure you want to delete this question? This action cannot be undone and may cause issues for any users that have previously completed this form.",
          onConfirm: async () => {
            await deleteFormQuestion(formQuestionId);
            await getFormQuestions();
          },
        });
      }
    };

    const handleDeleteFormQuestion = async () => {
      showModal.value = false;
      showConfirmation({
        title: "Delete Question?",
        body: "Are you sure you want to delete this question? This action cannot be undone and may cause issues for any users that have previously completed this form.",
        onConfirm: async () => {
          if (newFormQuestion.value?.id) {
            await deleteFormQuestion(newFormQuestion.value.id);
          }
          await getFormQuestions();
          editingFormQuestion.value = false;
        },
        onCancel: async () => {
          showModal.value = true;
        },
      });
    };
    const handleSaveFormQuestion = async () => {
      if (editingFormQuestion.value) {
        const textItem =
          newFormQuestion?.value?.textItems &&
          newFormQuestion.value.textItems.find(
            (ti) => ti.purpose === "form-question"
          );

        newFormQuestion.value = setValidation(newFormQuestion.value);
        newFormQuestion.value = setChoices(newFormQuestion.value);

        if (textItem?.data) {
          textItem.data = newFormQuestion.value?.text;
        }

        await updateFormQuestion(
          newFormQuestion.value?.id ?? "",
          newFormQuestion.value
        );
      } else {
        let formQuestion: any = createFormQuestionObject();
        formQuestion = setValidation(formQuestion);
        formQuestion = setChoices(formQuestion);
        await createFormQuestion(formQuestion);
      }
      await getFormQuestions();
      showModal.value = false;
      editingFormQuestion.value = false;
    };

    function createFormQuestionObject(): any {
      return {
        id: v4(),
        actionId: props.action.id,
        position: Math.max(...formQuestions.value.map((fq) => fq.position)) + 1,
        questionType: newFormQuestion.value.questionType,
        textItems: [
          {
            id: v4(),
            languageId: store.getters.currentUser.preferredLanguageId,
            data: newFormQuestion.value.text,
            purpose: "form-question",
          },
        ],
        validators: null,
        choices: null,
        dependencies: null,
      };
    }

    function setValidation(formQuestion: any) {
      if (
        newFormQuestion.value.validationNeeded === "yes" &&
        newFormQuestion.value.selectedValidation
      ) {
        formQuestion = {
          ...formQuestion,
          validators: newFormQuestion.value.selectedValidation.map((sv) => ({
            id: v4(),
            formQuestionId: formQuestion.id,
            validatorType: sv.id,
          })),
        };
      }
      return formQuestion;
    }

    function setChoices(formQuestion: any) {
      if (newFormQuestion.value.choices) {
        const languageId = store.getters.currentUser.preferredLanguageId;
        formQuestion = {
          ...formQuestion,
          choices: newFormQuestion.value.choices.map((choice) => ({
            ...choice,
            formQuestionId: formQuestion.id,
            textItems: [
              {
                id: v4(),
                languageId,
                data: choice.data,
                purpose: "form-question-choice",
              },
            ],
          })),
        };
      }
      return formQuestion;
    }

    const handleAddNewChoice = () => {
      const position = newFormQuestion.value.choices?.length ?? 0;
      if (position === 0) {
        newFormQuestion.value.choices = [];
      }
      newFormQuestion.value.choices = [
        ...(newFormQuestion.value.choices as Array<any>),
        {
          id: v4(),
          formQuestionId: "",
          position,
          data: "",
        },
      ];
    };

    const handleChangeQuestionType = ({ type }) => {
      newFormQuestion.value.questionType = type;
      resetNewFormQuestion(false);
    };

    const handleChangeOrder = async ({ id, oldPosition, newPosition }) => {
      if (oldPosition !== newPosition) {
        await reorderFormQuestion(id, { oldPosition, newPosition });
        await getFormQuestions();
      }
    };

    const handleResize = async ({ formQuestionId, fieldWidth }) => {
      if (
        fieldWidth >= gridContainer.value?.clientWidth ||
        fieldWidth >= gridContainer.value?.clientWidth / 2
      ) {
        await resizeFormQuestion(formQuestionId, 12);
      } else if (fieldWidth < gridContainer.value?.clientWidth / 3) {
        await resizeFormQuestion(formQuestionId, 4);
      } else if (fieldWidth < gridContainer.value?.clientWidth / 2) {
        await resizeFormQuestion(formQuestionId, 6);
      }
      await getFormQuestions();
    };

    const handleEdit = async ({ formQuestionId }) => {
      const formQuestion = formQuestions.value.find(
        (fq) => fq.id === formQuestionId
      );
      newFormQuestion.value = formQuestion as unknown as {
        text: string;
        questionType: string;
        validationNeeded: string;
        selectedValidation?: any[];
        choices?: any[];
        image?: null;
        imageUrl?: string;
      };
      newFormQuestion.value.text = formQuestion?.textItems[0].data ?? "";
      newFormQuestion.value.validationNeeded = formQuestion?.validators[0]
        ? "yes"
        : "no";
      newFormQuestion.value.selectedValidation = formQuestion?.validators.map(
        (v) =>
          validationTypes[formQuestion.questionType]?.find(
            (vt) => vt.id === v.validatorType
          )
      );
      newFormQuestion.value.choices = formQuestion?.choices.map((c) => ({
        ...c,
        data: c.textItems[0].data,
      }));
      editingFormQuestion.value = true;
      showModal.value = true;
    };

    onMounted(async () => {
      const featureConfigs = await getTextItemFeatureConfigs();
      featureConfigs.data.payload.result.forEach((featureConfig) => {
        switch (featureConfig.feature) {
          case "allowed-html-tags":
            safeHTML.value = featureConfig.condition;
            break;
        }
      });
      await getFormQuestions();
    });
    watch(props, () => getFormQuestions());

    return {
      tenant,
      users,
      typeSelectOptions,
      levelSelectOptions,
      formQuestions,
      handleCancel,
      resetNewFormQuestion,
      handleSaveFormQuestion,
      handleDeleteFormQuestion,
      handleQuickDelete,
      showModal,
      editingFormQuestion,
      newFormQuestion,
      handleAddNewChoice,
      handleChangeQuestionType,
      handleChangeOrder,
      handleResize,
      handleEdit,
      gridContainer,
    };
  },
});
