
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  defineComponent,
  inject,
  ComputedRef,
  ref,
  computed,
  onMounted,
} from "vue";

import ShadowContainer from "@/components/ui/ShadowContainer.vue";
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import { Theme } from "@/models/Style";
import { useTheme } from "@/composables/useTheme";

export default defineComponent({
  name: "SignIn",
  components: {
    UnauthenticatedLayout,
    ShadowContainer,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const theme = inject<ComputedRef<Theme>>("theme");

    const logoUrl = computed(() => {
      if (theme) {
        if (theme.value.logo.signin) {
          return theme.value.logo.signin.url;
        } else if (theme.value.logo.app) {
          return theme.value.logo.app.url;
        }
      }
      return null;
    });

    const themeRef = ref<any>();
    const getTheme = async () => {
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const { currentTenant } = store.getters;
    const tenant = ref();
    onMounted(async () => {
      if (!store.getters.isMaintenanceMode) {
        router.push("/");
      }
      getTheme();

      tenant.value = { name: currentTenant.name };
      store.dispatch("clearAuth");
    });

    store.watch(
      (state, getters) => getters.isMaintenanceMode,
      () => {
        if (!store.getters.isMaintenanceMode) {
          router.push("/");
        }
      }
    );

    return {
      logoUrl,
    };
  },
});
