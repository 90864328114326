import httpClient, { ApiResponse } from "./httpClient";

export const getMessageContents = async (references: string[],
  groupId = 'null',
  userId = 'null'): Promise<ApiResponse<any>> =>
  await httpClient.get(`/messagecontents/content/${JSON.stringify(references.map(encodeURIComponent))}/user/${groupId.length > 0 ? groupId : 'null'}/${userId.length > 0 ? userId : 'null'}`);

export const postMessageContents = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/messagecontents`, body);

export const postCopyMessageContents = async (
  body: any
): Promise<ApiResponse<any>> => httpClient.post(`/messagecontents/copy`, body);

export const putWelcomeMessage = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.put(`/hotspots/welcome-message`, body);
