import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bbf4be8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "no-items"
}
const _hoisted_2 = { class: "pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.isLoading })
  }, [
    _createVNode(_component_MenuBar, {
      "sort-order": _ctx.currentSortOrder,
      filters: _ctx.filterOptions,
      "hide-search": true,
      onSort: _ctx.handleSort,
      onUpdateFilter: _ctx.handleFilterChange,
      onApplyFilter: _ctx.handleFilterApply,
      onResetFilter: _ctx.handleFilterReset
    }, {
      item: _withCtx(({ item }) => [
        (item === 'date')
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList([
              { key: 'common.today', count: 0, unit: 'days' },
              { key: 'common.past-3-days', count: 2, unit: 'days' },
              { key: 'common.past-month', count: 1, unit: 'months' },
              { key: 'common.past-3-months', count: 3, unit: 'months' },
            ], ({ key, count, unit }, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "date-shortcut"
              }, [
                _createVNode(_component_Button, {
                  text: key,
                  size: "sm",
                  onClick: ($event: any) => (_ctx.handleShortcutClick(count, unit)),
                  variant: "secondary",
                  block: ""
                }, null, 8, ["text", "onClick"])
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["sort-order", "filters", "onSort", "onUpdateFilter", "onApplyFilter", "onResetFilter"]),
    (_ctx.timelineGroups.length)
      ? (_openBlock(), _createBlock(_component_Timeline, {
          key: 0,
          groups: _ctx.timelineGroups,
          onActionClick: _ctx.handleActionClick
        }, null, 8, ["groups", "onActionClick"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h5", null, [
            _createVNode(_component_TranslatedText, { path: "common.no-data" })
          ])
        ])),
    _createElementVNode("section", _hoisted_2, [
      (_ctx.paginationDetails)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 0,
            "current-page": _ctx.paginationDetails.pageNumber,
            "page-size": _ctx.paginationDetails.pageSize,
            "total-rows": _ctx.paginationDetails.total,
            onChangeRowCount: _ctx.handleChangeRowCount,
            onChangePage: _ctx.handleChangePage
          }, null, 8, ["current-page", "page-size", "total-rows", "onChangeRowCount", "onChangePage"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}