
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useLoading } from "@/plugins/loading";
import { Hotspot, Track } from "@/models";
import {
  getAllTracks,
  saveHotspotAppearanceChanges,
  httpDeleteTrack,
} from "@/api/track.api";
import Avatar from "@/components/Avatar.vue";
import Button from "@/components/Button.vue";
import MobileBottomBar from "@/components/MobileBottomBar.vue";
import MobileTrack from "@/components/track/MobileTrack.vue";
import Modal from "@/components/Modal.vue";
import TrackDisplay from "@/components/track/manager/TrackDisplay.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "TrackManager",
  components: {
    AuthenticatedLayout,
    Avatar,
    Button,
    MobileBottomBar,
    MobileTrack,
    Modal,
    TrackDisplay,
  },
  setup() {
    const router = useRouter();
    const showLoader = useLoading();
    const tracks = ref<Track[]>([]);
    const hotspots = ref<Hotspot[]>([]);
    const points = ref<any>([]);
    const activePoint = ref<number>(0);
    const showModal = ref<boolean>(false);
    const showDeleteModal = ref<boolean>(false);
    const draggedPoint = ref<boolean>(false);
    const draggedCubic = ref<boolean>(false);
    const trackIsDirty = ref<boolean>(false);
    const currentNavId = ref<string>("");
    const trackSelector = ref<string>("universal");

    const { inPageNotification } = useNotification();

    const getTracks = async () => {
      const response = await getAllTracks();
      tracks.value = response.data.payload.results;
      points.value = getCoords();
    };

    const getHotspots = async () =>
      (hotspots.value = await Hotspot.getAllHotspots());

    const currentTrack = computed(() =>
      tracks.value.find(
        (track) =>
          trackSelector.value.toLowerCase() ===
          track.textItems
            ?.find((ti) => ti.purpose === "track-name")
            ?.data.toLowerCase()
      )
    );

    const sortedTrack = computed(() => {
      const unsorted = currentTrack.value;
      return unsorted?.hotspotAppearances?.sort((a, b) => a.order - b.order);
    });

    const pageTitle = computed(
      () =>
        currentTrack.value?.textItems?.find((ti) => ti.purpose === "track-name")
          ?.data
    );

    const avatarText = computed(() => {
      const words = pageTitle.value?.split(" ");
      if (words && words.length > 1) {
        const one = words[0];
        const two = words[words.length - 1];
        const newArr = [one[0], two[0]];
        return newArr.join(" ");
      } else if (words && words.length === 1) return words[0][0];
      return "";
    });

    const navItems = computed(() => {
      const hotspotAppearances = currentTrack.value?.hotspotAppearances;
      return hotspotAppearances?.map((hs) => {
        return {
          url: hotspots.value?.find((hotspot) => hotspot.id === hs.hotspotId)
            ?.id,
          active: false,
          title: hotspots.value
            ?.find((hotspot) => hotspot.id === hs.hotspotId)
            ?.textItems.find((ti) => ti.purpose === "title")?.data,
          component: "Page",
          children: hotspots.value
            ?.find((hotspot) => hotspot.id === hs.hotspotId)
            ?.actions.map((action) => {
              return {
                title: action.textItems.find((ti) => ti.purpose === "title")
                  ?.data,
                url: "#",
                component: "Page",
              };
            }),
          action: () =>
            clickTab({
              id: hotspots.value?.find((hotspot) => hotspot.id === hs.hotspotId)
                ?.id,
            }),
        };
      });
    });

    const clickTab = (data: { id: string | undefined }) => {
      if (data.id !== currentNavItem.value.url) {
        data.id ? (currentNavId.value = data.id) : "";
        const index = navItems.value?.findIndex((item) => item.url === data.id);
        index ? (activePoint.value = index) : (activePoint.value = 0);
      } else currentNavId.value = "";
    };

    const currentNavItem = computed(() => {
      if (navItems.value?.length) {
        const found = navItems.value?.find(
          (item) => item.url === currentNavId.value
        );
        return found ? found : navItems.value[0];
      } else return { url: "" };
    });

    const curvedLine = computed(
      () => points.value.some((val) => val.c && val.c.length).length > 0
    );

    const getCoords = () =>
      sortedTrack.value?.map((ha) => {
        const coords = JSON.parse(ha.coords);
        coords.x = Math.round(coords.x / 50) * 50;
        coords.y = Math.round(coords.y / 50) * 50;
        return coords;
      });

    const setPointType = (e) => {
      trackIsDirty.value = true;
      let v = e.target.value;
      points.value.forEach((active, index) => {
        if (index !== 0) {
          switch (v) {
            case "l":
              delete points.value[index].c;
              break;
            case "c":
              points.value[index] = {
                x: active.x,
                y: active.y,
                c: [
                  {
                    x: (active.x + points.value[index - 1].x - 50) / 2,
                    y: (active.y + points.value[index - 1].y) / 2,
                  },
                  {
                    x: (active.x + points.value[index - 1].x + 50) / 2,
                    y: (active.y + points.value[index - 1].y) / 2,
                  },
                ],
              };
              break;
          }
        }
      });
    };

    const setPointCoords = (coords) => {
      const el = document.getElementById("app");
      const domRect = el?.getBoundingClientRect();
      if (domRect) {
        coords.y = Math.round((coords.y + domRect.top) / 50) * 50;
      }

      trackIsDirty.value = true;
      const track = tracks.value.find((tr) => tr.id === currentTrack.value?.id);
      if (track?.hotspotAppearances) {
        track.hotspotAppearances[0].coords = JSON.stringify(coords);
      }

      points.value[activePoint.value].x = coords.x;
      points.value[activePoint.value].y = coords.y;
    };

    const setCubicCoords = ({ coords, anchor }) => {
      const el = document.getElementById("app");
      const domRect = el?.getBoundingClientRect();
      if (domRect) {
        coords.y = Math.round((coords.y + domRect.top) / 50) * 50;
      }

      points.value[activePoint.value].c[anchor].x = coords.x;
      points.value[activePoint.value].c[anchor].y = coords.y;
      trackIsDirty.value = true;
      const track = tracks.value.find((tr) => tr.id === currentTrack.value?.id);
      if (track?.hotspotAppearances) {
        track.hotspotAppearances[0].coords = JSON.stringify(coords);
      }
    };

    const addPoint = (coords) => {
      points.value.push(coords);
      activePoint.value = points.value.length - 1;
      showModal.value = true;
    };

    const removeActivePoint = () => {
      if (points.value.length > 1 && activePoint.value !== 0) {
        const endPoints = [...points.value];
        points.value = [
          ...points.value.splice(0, activePoint.value),
          ...endPoints.splice(activePoint.value + 1),
        ];
        activePoint.value = points.value.length - 1;
      }
    };

    const setActivePoint = (index: number) => (activePoint.value = index);

    const setDraggedPoint = ({ index, draggedPointValue }) => {
      setActivePoint(index);
      draggedPoint.value = draggedPointValue;
      if (navItems.value) {
        const item = navItems.value[index];
        clickTab({ id: item.url });
      }
    };

    const handleHotspotClick = (index) => {
      activePoint.value = index;
      draggedPoint.value = false;
    };

    const setDraggedCubic = ({ index, anchor }) => {
      activePoint.value = index;
      draggedCubic.value = anchor;
    };

    const cancelDragging = () => {
      draggedCubic.value = false;
      draggedPoint.value = false;
    };

    const handleDoubleClick = () => {
      showModal.value = true;
    };

    const handleDeleteClick = async () => (showModal.value = true);
    const handleCancel = () => (showModal.value = false);
    const handleConfirmDelete = async () => {
      showLoader(true);
      try {
        console.log("");
      } finally {
        showLoader(false);
      }
    };

    const saveChanges = async () => {
      const newHotspotAppearance = sortedTrack.value?.map((hs, index) => {
        const hotspot = currentTrack.value?.hotspotAppearances?.find(
          (hotspot) => hs.id === hotspot.id
        );
        if (hotspot) {
          hotspot.coords = JSON.stringify(points.value[index]);
          hotspot.posX = points.value[index].x / 8;
          hotspot.posY = points.value[index].y / 6;
        }
        return hotspot;
      });
      if (newHotspotAppearance) {
        const response = await saveHotspotAppearanceChanges(
          newHotspotAppearance
        );
        if (response.status === 201) {
          inPageNotification("Success!", "Track updated", "success");
        } else {
          inPageNotification("Error!", "Could not update track", "error");
        }
      }
      trackIsDirty.value = false;
    };

    const toggleDeleteModal = () =>
      (showDeleteModal.value = !showDeleteModal.value);

    const deleteTrack = async (trackId: string) => {
      const response = await httpDeleteTrack(trackId);
      if (response.status === 200) {
        inPageNotification("Success", "Track has been deleted", "success");
        toggleDeleteModal();
        router.push("/");
        /* 
        For track create story - 
        * Maybe one to discuss how this should be handled.
        * if there are other tracks, find the next track and render it, if not redirect to user create
        */
      } else {
        inPageNotification("Error", "Could not delete track", "error");
      }
    };

    onMounted(async () => {
      await getTracks();
      await getHotspots();
      if (!currentTrack.value) {
        inPageNotification(
          "Error",
          "There are no tracks available to show.",
          "error"
        );
        router.push("/");
      }
    });

    return {
      showModal,
      showDeleteModal,
      handleDeleteClick,
      handleCancel,
      handleConfirmDelete,
      points,
      activePoint,
      curvedLine,
      setPointCoords,
      setCubicCoords,
      addPoint,
      removeActivePoint,
      setPointType,
      setDraggedPoint,
      handleHotspotClick,
      setDraggedCubic,
      draggedPoint,
      draggedCubic,
      cancelDragging,
      handleDoubleClick,
      saveChanges,
      toggleDeleteModal,
      deleteTrack,
      trackIsDirty,
      navItems,
      hotspots,
      currentNavItem,
      clickTab,
      pageTitle,
      avatarText,
      setActivePoint,
      currentTrack,
    };
  },
});
