
import { defineComponent, ref, computed, PropType } from "vue";
import Button from "@/components/Button.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import Select from "@/components/form/Select.vue";
import Accordion from "@/components/Accordion.vue";
import Overlay from "@/components/Overlay.vue";
import FormInput from "@/components/form/FormInput.vue";
import Search from "@/components/Search.vue";
import { useBreakpoints } from "@/composables";
import debounce from "lodash/debounce";

export interface FilterOption {
  name: string;
  label: string;
  component: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
}

export interface Filter {
  label: string;
  options: Array<FilterOption>;
}

export type FilterGroup = { [key: string]: Filter };

export type SortItem = {
  label: string;
  value: string;
};

export default defineComponent({
  props: {
    hideSort: Boolean,
    hideSearch: Boolean,
    searchText: String,
    sortColumn: String,
    sortOrder: String,
    hideFilters: Boolean,
    filters: {
      type: Object as PropType<FilterGroup>,
      default: () => ({}),
    },
    sortItems: {
      type: Array as PropType<Array<SortItem>>,
      default: () => [
        {
          label: "filter.ascending",
          value: "asc",
        },
        {
          label: "filter.descending",
          value: "desc",
        },
      ],
    },
  },
  components: {
    Button,
    DatePicker,
    Accordion,
    Overlay,
    Select,
    FormInput,
    Search,
  },
  emits: [
    "sort",
    "update-filter",
    "reset-filter",
    "apply-filter",
    "update-search",
  ],
  setup(props, { emit }) {
    const { currentBreakpoint } = useBreakpoints();
    const showSortMenu = ref(false);
    const handleUpdateSort = async (value: string) => {
      showSortMenu.value = false;
      emit("sort", value);
    };
    const toggleSort = () => {
      showFilterMenu.value = false;
      showSortMenu.value = !showSortMenu.value;
    };

    const showFilterMenu = ref(false);
    const handleFilterChange = (filterValue: unknown) =>
      emit("update-filter", filterValue);
    const handleFilterReset = async () => {
      emit("reset-filter");
    };
    const handleFilterApply = async () => {
      emit("apply-filter");
    };
    const toggleFilter = () => {
      showSortMenu.value = false;
      showFilterMenu.value = !showFilterMenu.value;
    };

    const isSmallScreen = computed(
      () => currentBreakpoint.value === "sm" || currentBreakpoint.value === "md"
    );

    const showSearchBar = ref(false);
    const toggleSearch = () => (showSearchBar.value = !showSearchBar.value);
    const debouncedSearchEmit = debounce(({ value }) => {
      emit("update-search", value);
    }, 600);
    const handleSearchUpdate = ({ value }) => {
      debouncedSearchEmit(value);
    };
    const handleSearchReset = () => emit("update-search", "");

    return {
      showSortMenu,
      handleUpdateSort,
      toggleSort,
      showFilterMenu,
      handleFilterChange,
      handleFilterReset,
      handleFilterApply,
      toggleFilter,
      isSmallScreen,
      showSearchBar,
      toggleSearch,
      handleSearchUpdate,
      handleSearchReset,
    };
  },
});
