import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col md:col-12" }
const _hoisted_2 = { class: "col md:col-12" }
const _hoisted_3 = { class: "col md:col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.userIsLoading })
  }, [
    (_ctx.currentReview)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentReview?.reviewTemplate.questions, (question, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "col md:col-12"
            }, [
              _createVNode(_component_FormInput, {
                value: _ctx.getAnswer(_ctx.currentReview, question),
                error: '',
                onChange: _cache[0] || (_cache[0] = ($event) => _ctx.handleUpdate($event, _ctx.currentReview.id)),
                label: question.textItems[0]?.data,
                name: question.id,
                id: question.id,
                customClass: "breakpoint-input",
                disabled: _ctx.disabled
              }, null, 8, ["value", "label", "name", "id", "disabled"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_FormInput, {
              value: _ctx.currentReview?.developmentAreas,
              error: '',
              onChange: _cache[1] || (_cache[1] = ($event) => _ctx.handleUpdate($event, _ctx.currentReview.id)),
              label: _ctx.translateText('reviews.development-areas'),
              name: "developmentAreas",
              id: "developmentAreas",
              customClass: "breakpoint-input",
              disabled: _ctx.disabled
            }, null, 8, ["value", "label", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormInput, {
              value: _ctx.currentReview?.myLearningProgress,
              error: '',
              onChange: _cache[2] || (_cache[2] = ($event) => _ctx.handleUpdate($event, _ctx.currentReview.id)),
              label: _ctx.translateText('reviews.my-learning-progress'),
              name: "myLearningProgress",
              id: "myLearningProgress",
              customClass: "breakpoint-input",
              disabled: _ctx.disabled
            }, null, 8, ["value", "label", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormInput, {
              value: _ctx.currentReview?.onboardingProgress,
              error: '',
              onChange: _cache[3] || (_cache[3] = ($event) => _ctx.handleUpdate($event, _ctx.currentReview.id)),
              label: _ctx.translateText('reviews.onboarding-progress'),
              name: "onboardingProgress",
              id: "onboardingProgress",
              customClass: "breakpoint-input",
              disabled: _ctx.disabled
            }, null, 8, ["value", "label", "disabled"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentReview?.actionObjectives, (actionObjective, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "col md:col-12"
            }, [
              _createVNode(_component_FormInput, {
                value: actionObjective.content,
                error: '',
                onChange: 
              ($event) =>
                _ctx.handleUpdate($event, _ctx.currentReview.id, { ...actionObjective })
            ,
                label: 
              _ctx.translateText('reviews.action-objective', [
                {
                  placeholder: 'actionNumber',
                  value: actionObjective.actionNumber,
                },
              ])
            ,
                name: 'actionObjective' + actionObjective.actionNumber,
                id: 'actionObjective' + actionObjective.actionNumber,
                customClass: "breakpoint-input",
                disabled: _ctx.disabled
              }, null, 8, ["value", "onChange", "label", "name", "id", "disabled"])
            ]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}