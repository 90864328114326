import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65eaf687"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drawers__fill full-height-w-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavSide = _resolveComponent("NavSide")!
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_Header = _resolveComponent("Header")!
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_v_tour = _resolveComponent("v-tour")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.userIsLoggedIn)
      ? (_openBlock(), _createBlock(_component_Drawer, {
          key: 0,
          open: _ctx.drawerLeftOpen,
          customClass: "left"
        }, {
          default: _withCtx(() => [
            (_ctx.getWindowWidth() <= 768 && _ctx.drawerLeftOpen)
              ? (_openBlock(), _createBlock(_component_Overlay, {
                  key: 0,
                  onClose: _ctx.handleToggleDrawerLeft
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NavSide, {
                      open: _ctx.drawerLeftOpen,
                      navItems: _ctx.navItems,
                      sideMenuItems: _ctx.sideMenuItems,
                      onToggleNav: _ctx.handleToggleDrawerLeft
                    }, null, 8, ["open", "navItems", "sideMenuItems", "onToggleNav"])
                  ]),
                  _: 1
                }, 8, ["onClose"]))
              : (_openBlock(), _createBlock(_component_NavSide, {
                  key: 1,
                  open: _ctx.drawerLeftOpen,
                  navItems: _ctx.navItems,
                  sideMenuItems: _ctx.sideMenuItems
                }, null, 8, ["open", "navItems", "sideMenuItems"]))
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["main", { noSideBar: !_ctx.userIsLoggedIn }]),
      style: _normalizeStyle(_ctx.background)
    }, [
      (_ctx.userIsLoggedIn)
        ? (_openBlock(), _createBlock(_component_Header, {
            key: 0,
            navOpen: _ctx.drawerLeftOpen,
            menuItems: _ctx.menuItems,
            handleClickToggleNav: _ctx.handleToggleDrawerLeft,
            handleClickToggleSettings: _ctx.handleToggleDrawerRight,
            hasSettings: _ctx.hasSettingsPermissions(_ctx.settingsItems),
            onClickMenuItem: _ctx.handleClickMenuItem
          }, null, 8, ["navOpen", "menuItems", "handleClickToggleNav", "handleClickToggleSettings", "hasSettings", "onClickMenuItem"]))
        : _createCommentVNode("", true),
      _createElementVNode("main", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 6),
    (_ctx.userIsLoggedIn && _ctx.hasSettingsPermissions(_ctx.settingsItems))
      ? (_openBlock(), _createBlock(_component_Drawer, {
          key: 1,
          open: _ctx.drawerRightOpen,
          customClass: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SettingsMenu, {
              settingsItems: _ctx.settingsItems,
              closeDrawer: _ctx.closeDrawerRight
            }, null, 8, ["settingsItems", "closeDrawer"])
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true),
    (!_ctx.isMobile && _ctx.productTour.ready)
      ? (_openBlock(), _createBlock(_component_v_tour, {
          key: 2,
          name: "userTrackTour",
          steps: _ctx.productTour.steps,
          options: _ctx.productTour.options,
          callbacks: _ctx.productTour.callbacks
        }, null, 8, ["steps", "options", "callbacks"]))
      : _createCommentVNode("", true)
  ]))
}