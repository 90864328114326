import { Model } from "./Model";
import { Address } from "./Address";

export class Payroll extends Model<Payroll> {
  private static readonly path: string = "/user-payroll";

  public userId: string;
  public sortCode?: string;
  public accountNumber?: string;
  public accountName?: string;
  public notes?: string;
  public bankName?: string;
  public bankAddress?: Address;

  constructor({
    id = "",
    userId = "",
    sortCode = "",
    accountNumber = "",
    accountName = "",
    notes = "",
    bankName = "",
    bankAddress,
  }: Partial<Payroll> = {}) {
    super(id, Payroll, Payroll.path);
    this.userId = userId;
    this.sortCode = sortCode;
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.notes = notes;
    this.bankName = bankName;
    this.bankAddress = bankAddress;
  }

  public static async get(userId: string): Promise<Payroll | undefined> {
    return await Model.getOne<Payroll>(`/users/${userId}/payroll`, Payroll);
  }
}
