import { App, inject } from "vue";
import store from "@/store";
import { getAllFeatureConfigs } from "@/api/feature-config.api";

export const permissionSymbol = Symbol();
export interface PermissionProvider {
  userHasPermission: (key: string) => boolean;
  checkUserConditions: (conditions: Array<any>) => boolean;
  setFeatureConfigs: () => Promise<void>;
  isGodMode: () => boolean;
}

export default {
  install: (app: App): void => {
    const userHasPermission = (key: string) => {
      if (!key) return true;
      const permissions = store.getters.token?.decoded?.permissions;
      const splitKey = key.split(":");
      return (
        permissions &&
        (permissions.some((p: string) => p === "admin:all") ||
          permissions.some(
            (p: string) =>
              p === key ||
              p
                .split(":")
                .every(
                  (part: string, idx: number) =>
                    !splitKey[idx] ||
                    splitKey[idx] === "*" ||
                    splitKey[idx] === part
                )
          ))
      );
    };

    const checkUserConditions = (conditions: Array<any>) => {
      if (conditions.length) {
        const currentUser = store.getters.currentUser;
        const matched = conditions.filter((c: string) => {
          if (c.indexOf("feature:") == 0) {
            const feature = c.split(":")[1];
            return isFeatureEnabled(feature);
          }
          switch (c) {
            case "hasWidgets": {
              return currentUser?.widgets?.length > 0;
            }
            case "startDate": {
              // Todo: Change this permission check to something more meaningful
              return (
                store.getters.hasUserStarted || userHasPermission("dashboard")
              );
            }
            case "demo-reset": {
              return store.getters.currentTenant.name == "demo";
            }
            case "hasTrack": {
              return store.getters.currentUser?.groups.length;
            }
            case "canChat": {
              return store.getters.currentUser?.hasChat;
            }
            default: {
              return true;
            }
          }
        });
        return matched.length === conditions.length;
      }

      return true;
    };

    const setFeatureConfigs = async () => {
      const featureConfigs = await getAllFeatureConfigs();
      store.dispatch("setFeatureConfigs", featureConfigs);
    };

    const isFeatureEnabled = (feature: string) => {
      const featureConfig = store.getters.featureConfigs.find(
        (fc) => fc.feature === feature
      );
      return featureConfig ? featureConfig.config : true; //Todo: review always on status when config not present
    };

    const isGodMode = () =>
      store.getters.currentUser &&
      store.getters.currentUser?.email &&
      store.getters.currentUser.email.includes("@jolahr.co.uk");

    const permissionProvider: PermissionProvider = {
      userHasPermission,
      checkUserConditions,
      setFeatureConfigs,
      isGodMode,
    };

    app.config.globalProperties.$permissionProvider = permissionProvider;
    app.provide(permissionSymbol, permissionProvider);
  },
};

export const usePermissionProvider = (): PermissionProvider => {
  const plugin = inject<PermissionProvider>(permissionSymbol);
  if (!plugin) throw new Error("Permission plugin not installed");

  return plugin;
};
