import { GetterTree, Module, ActionTree, MutationTree } from "vuex";
import { NavItem } from "@/interfaces/ui";

enum MutationKeys {
  setIsMobile = "setIsMobile",
  setScreenSize = "setScreenSize",
  setMenuItems = "setMenuItems",
}

export interface MiscState {
  isMobile: boolean;
  screenSize: number;
  menuItems: Array<NavItem>;
}

const currentScreenSize = window.innerWidth;

const state: MiscState = {
  isMobile: window.innerWidth > 767 ? false : true,
  screenSize: currentScreenSize,
  menuItems: [],
};

const getters: GetterTree<MiscState, null> = {
  isMobile: (state: MiscState) => state.isMobile,
  screenSize: (state: MiscState) => state.screenSize,
  menuItems: (state: MiscState) => state.menuItems,
};

const actions: ActionTree<MiscState, null> = {
  setIsMobile({ commit }, newVal: boolean) {
    commit(MutationKeys.setIsMobile, newVal);
  },
  setScreenSize({ commit }, size: number) {
    commit(MutationKeys.setScreenSize, size);
  },
  setMenuItems({ commit }, menuItems: Array<NavItem>) {
    commit(MutationKeys.setMenuItems, menuItems);
  },
};

const mutations: MutationTree<MiscState> = {
  [MutationKeys.setIsMobile]: (state: MiscState, newVal: boolean) =>
    (state.isMobile = newVal),
  [MutationKeys.setScreenSize]: (state: MiscState, size: number) =>
    (state.screenSize = size),
  [MutationKeys.setMenuItems]: (state: MiscState, menuItems: Array<NavItem>) =>
    (state.menuItems = menuItems),
};

const misc: Module<MiscState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { misc };
