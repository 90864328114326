<template>
  <widget-layout
    :title="data.report.name"
    :subtitle="data.report.description"
    class="report-widget"
    :minHeight="2"
    :maxHeight="2"
    :maxWidth="4"
    :link="`/reporting/${data.report.id}`"
    :style="{ '--width': width, '--height': height }"
  >
    <font-awesome-icon class="report-card__image" :icon="data.report.image" />
  </widget-layout>
</template>
<script>
import WidgetLayout from "../WidgetLayout.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportWidget",
  components: {
    WidgetLayout,
  },
  props: {
    width: Number,
    height: Number,
    userData: {
      default: {},
    },
    data: Object,
  },
  setup(props) {
    return {};
  },
});
</script>

<style lang="scss">
@import "~@/assets/scss/_utilities.scss";
.report-widget {
  .titlebar {
    border-bottom: 0 !important;
  }
  .report-card {
    position: relative;

    &__icon {
      color: var(--base-06);
      position: absolute;
      right: 0;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      text-align: center;
      padding: 0.25rem;
      border-radius: 1rem;
    }

    &__image {
      display: block;
      margin: 0 auto;
      color: var(--theme-menu-indicator);
      font-size: 10rem;
      padding: 30px 0 40px;
    }
  }
}
</style>
