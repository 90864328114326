
import useValidate, { ValidationArgs } from "@vuelidate/core";
import { defineComponent, ref, PropType, toRef, watch } from "vue";

export default defineComponent({
  name: "Form",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object as PropType<ValidationArgs>,
      required: true,
    },
    errors: Object,
    valid: Boolean,
    submitCheck: Boolean,
  },
  setup(props, { emit }) {
    const model = toRef(props, "modelValue");

    const schema = ref({});

    let v$ = useValidate(schema.value, model);
    const isValid = ref(false);

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        const errors = { ...props.errors };
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors[name] = error;
        } else {
          delete errors[name];
        }
        emit("update:errors", errors);
      }
    };

    const handleInputChange = ({ name, value }) => {
      handleChange(name, value);
    };
    const handleDateUpdate = ({ name, value }) => {
      handleChange(name, new Date(value).toISOString());
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = async (name: string, value: any) => {
      model.value[name] = value;
      emit("update:modelValue", model.value);
      validateField(name);
      isValid.value = await v$.value.$validate();
      emit("update:valid", isValid.value);
      emit("update:errors", v$.value.$errors);
    };

    watch(props, async () => {
      if (props.schema) {
        schema.value = props.schema;
        v$ = useValidate(schema.value, model);
        isValid.value = await v$.value.$validate();
      }
      if (props.submitCheck) {
        for await (const [name] of Object.entries(props.schema)) {
          await validateField(name);
        }
        isValid.value = await v$.value.$validate();
        emit("update:submitCheck", false);
        emit("update:valid", isValid.value);
      }
    });

    return {
      model,
      isValid,
      handleInputChange,
      handleDateUpdate,
    };
  },
});
