
import { defineComponent, PropType, computed } from "vue";
import { ChecklistItem } from "@/models";
import ListGroup from "@/components/ListGroup.vue";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<ChecklistItem>>,
    },
    flush: Boolean,
    actionable: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListGroup,
  },
  setup(props, { emit }) {
    const handleItemClick = async (itemId: string) => {
      emit("click-item", itemId);
    };
    const handleItemDelete = async (itemId: string) => {
      emit("remove-item", itemId);
    };

    const getItemCursor = computed(() =>
      props.actionable ? "pointer" : "hand"
    );

    return {
      handleItemClick,
      handleItemDelete,
      getItemCursor,
    };
  },
});
