
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import UserReferenceList from "@/components/user/shared/UserReferenceList.vue";
import { Reference, User } from "@/models";
import { useModal } from "@/plugins/modal";
import { useLoading } from "@/plugins/loading";
import { useLanguage } from "@/composables";

export default defineComponent({
  components: { UserReferenceList },
  props: {
    user: {
      type: Object as PropType<User>,
    },
    isAuthenticatedUser: Boolean,
  },
  emits: ["refresh", "delete"],
  setup(props, { emit }) {
    const showModal = useModal();
    const showLoader = useLoading();
    const { createDateString } = useLanguage();
    const model = ref<Array<Reference>>([]);

    const getReferences = async () => {
      if (model.value && props.user && props.user.id) {
        model.value = await Reference.get(props.user.id);
        model.value = model.value.map((reference: any) => {
          return {
            ...reference,
            ...{
              dateFrom: reference.dateFrom
                ? reference.dateFrom
                : createDateString(
                    reference.yearFrom,
                    reference.monthFrom,
                    reference.dayFrom
                  ),
              dateTo: reference.dateTo
                ? reference.dateTo
                : createDateString(
                    reference.yearTo,
                    reference.monthTo,
                    reference.dayTo
                  ),
            },
          };
        });
      }
    };

    const employerReferences = computed(() =>
      model.value.filter((reference: any) => reference.employerReference)
    );
    const characterReferences = computed(() =>
      model.value.filter((reference: any) => reference.characterReference)
    );

    const errors = reactive<{ [K: string]: string }>({});

    const handleSaveReference = async ({ reference, saveType }) => {
      if (reference.address) {
        reference.employerReference.address = reference.address;
      }
      if (reference.company) {
        reference.employerReference.company = reference.company;
      }
      if (!reference.id) {
        if (reference.employerReference) {
          reference.order = employerReferences.value.length;
        } else {
          reference.order = characterReferences.value.length;
        }
      }
      switch (saveType) {
        case "Approve":
          await reference.save("approve", (props.user as User).id);
          break;
        case "Approve and Send":
          await reference.save("approve-and-send", (props.user as User).id);
          break;
        case "Send":
          await reference.save("send", (props.user as User).id);
          break;
        case "Resend":
          await reference.save("resend", (props.user as User).id);
          break;
        case "Complete":
          await reference.save("complete", (props.user as User).id);
          break;
        default:
          await reference.save("save", (props.user as User).id);
      }
      getReferences();
    };

    const handleDeleteReference = async (reference: Reference) => {
      showModal({
        title: "Delete Reference?",
        body: "Are you sure you want to delete this reference?",
        onConfirm: async () => {
          showLoader(true);
          await reference.delete((props.user as User).id);
          getReferences();
          showLoader(false);
        },
      });
    };

    onMounted(async () => getReferences());
    watch(props, () => getReferences());

    return {
      errors,
      model,
      employerReferences,
      characterReferences,
      handleSaveReference,
      handleDeleteReference,
    };
  },
});
