
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    textKey: {
      type: String,
      default: "common.back",
    },
    url: {
      type: String,
      required: true,
    },
    useHistory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const handleClick = (e: Event) => {
      if (props.useHistory) {
        e.preventDefault();
        router.go(-1);
      }
    };

    return {
      handleClick,
    };
  },
});
