
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: String,
    tooltip: {
      type: String,
      default: () => "",
    },
  },
});
