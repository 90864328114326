
import { defineComponent } from "@vue/runtime-core";
import { useTextItem } from "@/composables";

export default defineComponent({
  name: "Text",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [],
  setup(props) {
    const { sanitizeAndReplaceHTML } = useTextItem();
    const sanitizeTextItem = (string) => {
      const str = sanitizeAndReplaceHTML(string, props.options?.safeHTML);
      return str;
    };
    return {
      sanitizeTextItem,
    };
  },
});
