
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useLoading } from "@/plugins/loading";
import { useModal } from "@/plugins/modal";
import UserView from "@/components/user/UserView.vue";
import { User } from "@/models";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { getMe } from "@/api/auth.api";

export default defineComponent({
  components: {
    AuthenticatedLayout,
    UserView,
  },
  setup() {
    const router = useRouter();
    const showLoader = useLoading();
    const showModal = useModal();

    const user = ref<User>(new User());
    const userOld = ref<User>(new User());
    const userIsLoading = ref<boolean>(true);
    const userNotFound = ref<boolean>(false);

    const navItemConfig = [
      {
        url: "details",
        title: "models.user.tabs.my-details",
        component: "UserDetails",
        config: {
          isMe: true,
        },
        action: () => changeTab("details"),
      },
      {
        url: "password",
        title: "models.user.tabs.password",
        component: "UserChangePassword",
        action: () => changeTab("password"),
      },
    ];

    const changeTab = (url: string) => {
      router.push({
        path: `/me/${url}`,
      });
    };

    const getUser = async (bypassCache = false) => {
      try {
        userIsLoading.value = true;
        if (!user.value || bypassCache) {
          const userRequest = await getMe();
          if (userRequest.data.payload.result) {
            const userResponse = userRequest.data.payload.result;

            Object.keys(userResponse).forEach((key) => {
              user.value[key] = userResponse[key];
            });
          }
        }
      } catch (err) {
        //TODO: Handle this error better HBL-1886
        console.log(err);
        userNotFound.value = false;
        router.push("/");
      } finally {
        userOld.value = user.value as User;
        userIsLoading.value = false;
      }
    };

    let nav = false;
    // same as beforeRouteUpdate option with no access to `this`
    onBeforeRouteUpdate(async (to) => {
      if (
        Object.keys(user.value).find(
          (key: string) => user.value[key] !== (userOld.value as User)[key]
        )
      ) {
        showModal({
          title: "prompts.unsaved-changes.title",
          body: "prompts.unsaved-changes.message",
          onConfirm: async () => {
            nav = true;
            return router.push(to.fullPath);
          },
        });
        return nav;
      } else {
        return true;
      }
    });

    onMounted(async () => {
      showLoader(true);
      await getUser(true);
      showLoader(false);
      userOld.value = user.value;
    });

    return {
      user,
      userIsLoading,
      navItemConfig,
      getUser,
    };
  },
});
