
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  watch,
  PropType,
} from "vue";
import { Action, Hotspot, ActionProgress } from "@/models";
import { useStore } from "vuex";
import { useLanguage } from "@/composables";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import ContactCard from "@/components/ui/ContactCard.vue";
import { getImageContents } from "@/api/image-contents.api";
import { ContactGroup } from "@/interfaces/domain/ContactGroup.interface";

import { getContacts } from "@/api/contacts.api";
import { getUserManager } from "@/api/users.api";

export default defineComponent({
  components: {
    ActionHeader,
    ContactCard,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
  },
  setup(props) {
    const store = useStore();
    const { translateText } = useLanguage();

    const contactGroups = ref([]) as any;

    const sortedData = (data) => {
      data.sort((a, b) => {
        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
      });

      return data;
    };

    const hotspot = computed(() => props.hotspot);

    const convertTel = (tel) => {
      return tel.replace(/[^0-9]/g, "").replace(/^0/, "+44");
    };

    const contactGroupTextItem = (group, purpose) => {
      const ti = group.textItems.find((t) => t.purpose === purpose);
      return ti?.data ?? false;
    };

    const updateContacts = async () => {
      contactGroups.value = [];
      const managerHotspots: any = [];
      if (hotspot.value.contactGroups.length) {
        hotspot.value.contactGroups.forEach(
          async (contactGroup: ContactGroup) => {
            if (contactGroup.isManagerContacts) {
              managerHotspots.push(contactGroup.id);
            }
            contactGroups.value.push({
              id: contactGroup.id,
              name: contactGroupTextItem(contactGroup, "contact-group-name"),
              order: contactGroup.HotspotHasContactGroup.order ?? 100,
              translationKeyName: `contact-groups.${
                contactGroup.isManagerContacts ? "manager" : "group"
              }.title`,
              description: contactGroupTextItem(
                contactGroup,
                "contact-group-description"
              ),
              translationKeyDescription: `contact-groups.${
                contactGroup.isManagerContacts ? "manager" : "group"
              }.description`,
              contacts: [],
            });
            return "";
          }
        );
      }

      const managersResponse = await getUserManager();
      if (managersResponse.data.payload.results) {
        await managersResponse.data.payload.results.forEach(async (manager) => {
          const contactCard = {
            userId: manager.id,
            name: `${manager.firstName} ${manager.surname}`,
            position: manager.position,
            image: "",
            telephone: "",
            email: "",
            links: [],
            order: manager?.order ?? 100,
          };

          managerHotspots.forEach(async (managerHP) => {
            var mIdx = contactGroups.value.findIndex(
              (cg) => cg.id == managerHP
            );
            if (contactGroups.value[mIdx]) {
              if (manager.imageContents.length) {
                contactCard.image = manager.imageContents[0].url;
              }

              contactGroups.value[mIdx].contacts.push(contactCard);
            }
          });
        });
      }

      const contactsResponse = await getContacts(
        store.state.user.currentUser.id
      );
      if (contactsResponse.data.payload.results) {
        for (const contact of contactsResponse.data.payload.results) {
          const contactCard = {
            userId: null,
            name: contact.name,
            position: contact.role,
            image: "",
            telephone: contact.contactNumber,
            description: contact.description,
            bio: contact.bio,
            email: "",
            links: [],
            order: contact?.order ?? 100,
          };

          const contactValid = contact.users.find(
            (c) => c.UserHasContact.userId == store.state.user.currentUser.id
          );
          contact.contactGroups.forEach(async (contactGroup) => {
            var cgIdx = contactGroups.value.findIndex(
              (cg) => cg.id == contactGroup.id
            );
            if (contactGroups.value[cgIdx]) {
              contactCard.order = contactGroup.ContactHasContactGroup.order;
              if (
                contactGroup.alwaysShow ||
                (contactValid &&
                  (!contact.userHasContactFilters.length ||
                    contact.userHasContactFilters.find(
                      (uhbf) =>
                        uhbf.contactGroupId === contactGroup.id &&
                        uhbf.userId === store.state.user.currentUser.id
                    )))
              ) {
                const imageResponse = await getImageContents(
                  [contact.imageReference],
                  store.state.user.currentUser.id
                );
                if (imageResponse.data.payload.results) {
                  contactCard.image = imageResponse.data.payload.results.length
                    ? imageResponse.data.payload.results[0].url
                    : "";
                }
                if (
                  !contactGroups.value[cgIdx].contacts.find(
                    (contact) =>
                      contact.name === contactCard.name &&
                      contact.position === contactCard.position
                  )
                )
                  contactGroups.value[cgIdx].contacts.push(contactCard);
              }
            }
          });
        }
      }
    };

    onMounted(async () => {
      await updateContacts();
    });

    watch(
      () => props.hotspot,
      async (B, A) => {
        await updateContacts();
      }
    );

    return {
      contactGroups,
      sortedData,
      convertTel,
      translateText,
    };
  },
});
