import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileInput = _resolveComponent("FileInput")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    schema: "{}"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_FileInput, {
          name: "file-upload",
          acceptString: ".csv",
          existingFile: null,
          onAddFile: _ctx.handleAddFile
        }, null, 8, ["onAddFile"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}