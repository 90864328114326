import { App } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCalendarAlt,
  faLock,
  faExclamationTriangle,
  faSpinner,
  faPen,
  faEnvelope,
  faCloudDownload,
  faEye,
  faEyeSlash,
  faUser,
  faUsers,
  faUsersLine,
  faPeopleGroup,
  faRoute,
  faCaretDown,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCommentDots,
  faDotCircle,
  faExternalLinkAlt,
  faTimes,
  faTrash,
  faSortAmountDown,
  faSortAmountUp,
  faFilter,
  faChartLine,
  faChartPie,
  faTasks,
  faSmile,
  faEllipsisV,
  faSearch,
  faMinus,
  faPlus,
  faPlusCircle,
  faPlay,
  faPause,
  faParagraph,
  faImage,
  faCheck,
  faAlignLeft,
  faHeader,
  faFont,
  faGlobe,
  faSortDown,
  faVideo,
  faFile,
  faFileImage,
  faFilePdf,
  faCopy,
  faExpand,
  faCompress,
  faSquare,
  faSquareCheck,
  faThLarge,
  faListUl,
  faDownload,
  faRefresh,
  faMousePointer,
  faPaperPlane,
  faGauge,
  faStar,
  faUpDown,
  faUpload,
  faCircleInfo,
  faPhone,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default {
  install: (app: App): void => {
    library.add(
      faCalendarAlt,
      faLock,
      faExclamationTriangle,
      faSpinner,
      faPen,
      faEnvelope,
      faCloudDownload,
      faEye,
      faEyeSlash,
      faUser,
      faUsers,
      faUsersLine,
      faPeopleGroup,
      faRoute,
      faCaretDown,
      faChevronDown,
      faChevronUp,
      faChevronRight,
      faChevronLeft,
      faCommentDots,
      faDotCircle,
      faExternalLinkAlt,
      faTimes,
      faTrash,
      faSortAmountDown,
      faSortAmountUp,
      faFilter,
      faChartLine,
      faChartPie,
      faTasks,
      faSmile,
      faEllipsisV,
      faSearch,
      faMinus,
      faPlus,
      faPlusCircle,
      faPlay,
      faPause,
      faParagraph,
      faImage,
      faCheck,
      faAlignLeft,
      faHeader,
      faFont,
      faGlobe,
      faSortDown,
      faCircle,
      faCircleInfo,
      faVideo,
      faFile,
      faFileImage,
      faFilePdf,
      faCopy,
      faExpand,
      faCompress,
      faMousePointer,
      faPaperPlane,
      faSquare,
      faSquareCheck,
      faThLarge,
      faListUl,
      faDownload,
      faRefresh,
      faMousePointer,
      faGauge,
      faStar,
      faUpDown,
      faUpload,
      faTwitter,
      faFacebook,
      faInstagram,
      faLinkedin,
      faPhone,
      faLink
    );
    app.component("font-awesome-icon", FontAwesomeIcon);
  },
};
