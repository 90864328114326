import httpClient, { ApiResponse } from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getActionsProgressByIds = async (
  actionIds: string,
  userId: string
): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/actions/actionProgress/${actionIds}/${userId}`);

export const getUserRequired = async (
  userId: string
): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/actions/userrequired/${userId}`);

export const getUserActions = async (
  userId: string,
): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/actions/useractions/${userId}`);

export const postAction = async (
  hotspotType: string,
  body: { title: string; level: string; description: string }
): Promise<ApiResponse<any>> =>
  httpClient.post(`/actions/setup-create/${hotspotType}`, body);

export const createAction = async (body: {
  hotspotId: string;
  title: string;
  type: string;
  level: string;
  expectedCompletionTime?: string;
  signatureRequired?: boolean;
}): Promise<ApiResponse<any>> => httpClient.post(`/actions/create`, body);

export const deleteAction = async (
  actionId: string
): Promise<ApiResponse<any>> =>
  httpClient.delete(`/actions/destroy/${actionId}`);

export const getActionProgressForUser = async (
  actionId: string
): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/actions/action-progress/${actionId}`);
