
import { computed, defineComponent } from "vue";
import Button from "@/components/Button.vue";
import { useStore } from "vuex";
import { usePermissionProvider } from "@/plugins/permission";

export default defineComponent({
  emits: ["confirm", "cancel", "dismiss"],
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const permissionProvider = usePermissionProvider();
    const trackEditEnabled = store.getters.featureConfigs.find(
      (fc) => fc.feature === "enable-track-edit"
    );

    const hasPermission = computed(
      () =>
        permissionProvider.userHasPermission("track:edit") &&
        trackEditEnabled?.config
    );

    const editMode = computed(() => store.getters.track.editMode);

    const editText = computed(() =>
      editMode.value ? "Exit Editer Mode" : "Enter Editer Mode"
    );

    const setEditMode = () => {
      store.dispatch("setEditMode", !editMode.value);
    };

    return {
      editMode,
      editText,
      hasPermission,
      setEditMode,
    };
  },
});
