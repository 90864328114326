<template>
  <authenticated-layout>
    <div class="wrapper dashboard-container">
      <h4>Your Dashboard</h4>
      <Dashboard
        v-if="widgets && widgets.length"
        :widgets="widgets"
        :liveData="liveData"
        :isLoading="!!isLoading"
      />
      <h2 class="no-dash" v-else-if="noDash">No Dashboard available...</h2>
    </div>
  </authenticated-layout>
</template>
<script>
import Dashboard from "@/components/dashboard/DashboardTwo.vue";
import { defineComponent, ref, onMounted } from "vue";
import {
  httpGetLayout,
  httpGetUserProgress,
  httpGetUserLimitation,
  httpGetUserWelcomeEmails,
  httpGetGenericUserDetails,
  httpGetGroupsSummary,
  httpGetMyTeam,
} from "@/api/dashboard.api";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

import { useRouter } from "vue-router";
import navItems from "@/data/navItems.json";
import { usePermissionProvider } from "@/plugins/permission";
import { useStore } from "vuex";

const saveDebounceTime = 2000;

export default defineComponent({
  components: {
    AuthenticatedLayout,
    Dashboard,
  },
  name: "Home",
  setup() {
    const store = useStore();
    const router = useRouter();
    const permissionProvider = usePermissionProvider();

    const saveTimer = ref(null);
    const widgets = ref(null);
    const liveData = ref({});
    const noDash = ref(false);

    /**
     * fetch data as required by widgets
     */
    const handleRequire = (event) => {
      if (window.location.hostname === "localhost")
        console.log(
          "Dashboard emitted a require for data categories:",
          event.names
        );
      event.names.forEach((name) => {
        getData(name).then((data) => event.provide(name, data));
      });
    };

    const getData = async (name, userData = "") => {
      if (name === "progress") {
        const progress = await httpGetUserProgress();
        return {
          hotspots: JSON.parse(progress.hotspots),
          progressPercentage: progress.progressPercentage,
        };
      } else if (name === "myTeam") {
        let team = {};
        team.members = await httpGetMyTeam();
        return team;
      } else if (name === "userCounts") {
        const userLimitData = await httpGetUserLimitation();
        return userLimitData;
      } else if (name === "userWelcome") {
        const userWelcomeData = await httpGetUserWelcomeEmails();
        return userWelcomeData;
      } else if (name === "groupsSummary") {
        const userWelcomeData = await httpGetGroupsSummary();
        return userWelcomeData;
      } else if (name === "genericUsers") {
        const timeFilter = {};
        if (userData?.timeFrames) {
          timeFilter["timeFrames"] = userData.timeFrames;
        }
        if (userData?.unusualStartDate) {
          timeFilter["unusualStartDate"] = userData.unusualStartDate;
        }
        if (userData?.userBirthdays) {
          timeFilter["userBirthdays"] = userData.userBirthdays;
        }
        const userWelcomeData = await httpGetGenericUserDetails(timeFilter);
        return userWelcomeData;
      }
      return;
    };

    const startSave = (event) => {
      if (saveTimer.value !== null) clearTimeout(saveTimer.value);
      saveTimer.value = setTimeout(() => {
        saveTimer.value = null;
        localStorage["--local-dashboard"] = JSON.stringify(event);
      }, saveDebounceTime);
    };

    const isLoading = ref(true);

    onMounted(async () => {
      widgets.value = store.state.user.currentUser?.widgets ?? [];
      if (!widgets.value.length) {
        noDash.value = true;
      } else {
        store.dispatch("setWidgets", widgets.value);
        while (!widgets.value.some((w) => w.y == 0)) {
          widgets.value.forEach((w) => w.y--);
        }
        while (!widgets.value.some((w) => w.x == 0)) {
          widgets.value.forEach((w) => w.x--);
        }
        const userLimitDataFetched = ref(false);
        for (const [i, widget] of widgets.value.entries()) {
          if (widget.type === "userLimit" || widget.type === "userActivity") {
            if (!userLimitDataFetched.value) {
              const response = await getData("userCounts");
              liveData.value["userCounts"] = response;
              userLimitDataFetched.value = true;
            } else {
              isLoading.value = false;
            }
          } else if (widget.type === "progress") {
            const response = await getData(widget.type);
            if (Object.keys(response.hotspots).length) {
              liveData.value[widget.id] = response;
            } else {
              widgets.value = widgets.value
                .slice(0, i)
                .concat(widgets.value.slice(i + 1, widgets.value.length));
            }
          } else {
            const response = await getData(widget.type, widget.userData);
            liveData.value[widget.id] = response;
          }
        }
      }
      isLoading.value = false;
      if (!widgets.value.length) {
        noDash.value = true;
      }

      // Do this after mouted so we know if we have widgets!
      navItems.forEach((item) => {
        if (item.url === "/" && item.conditions) {
          if (!permissionProvider.checkUserConditions(item.conditions)) {
            router.push(`/track`);
            return false;
          }
        }
      });
    });

    return { widgets, liveData, noDash, handleRequire, startSave, isLoading };
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";

.dashboard-container {
  padding: 1.4rem;
  overflow: hidden;
  min-height: calc(
    100vh - 66px
  ); // scrollbar flicker was noticeable and grating
  @include min-width("md") {
    padding: 2rem 4rem;
  }
  .no-dash {
    text-align: center;
    margin-top: 40px;
  }
}
.dashboard-main,
h4 {
  margin: 0 auto;
}

h4 {
  border-bottom: var(--base-06) 1px solid;
  padding: 0.75em 0;
  margin-bottom: 1em;
}
</style>
