
import { defineComponent, computed, ref, onMounted } from "vue";
import { useTheme } from "@/composables/useTheme";

export default defineComponent({
  name: "ShadowContainer",
  setup() {
    const themeRef = ref<any>();
    const getTheme = async () => {
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const quaternaryContainer = computed(() => {
      if (themeRef.value) {
        const quaternary = (themeRef.value?.config ?? []).find(
          (style) => style.name === "quaternary-shadow-container"
        );

        return quaternary?.value === "true";
      }
      return false;
    });

    onMounted(() => {
      getTheme();
    });

    return {
      quaternaryContainer,
    };
  },
});
