import httpClient, { ApiResponse } from "@/api/httpClient";
import { PaginatedResult } from "@/interfaces/Pagination";

export class Model<T> {
  public id: string;

  protected readonly type: new (...args) => T;
  protected readonly path: string;

  constructor(id: string, type: new (...args) => T, path: string) {
    this.id = id;
    this.type = type;
    this.path = path;
  }

  protected static async getAllForUser<T>(
    userId: string,
    path: string,
    type: new (...args) => T
  ): Promise<Array<T>> {
    const response: ApiResponse<T> = await httpClient.get(`${path}/${userId}`);

    const { results } = response.data.payload;
    return (results || []).map((r) => new type(r));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected static async getAll<T, F = any>(
    path: string,
    type: new (...args) => T,
    sortCol?: string,
    sortOrder?: string,
    filters?: F
  ): Promise<Array<T>> {
    const response: ApiResponse<T> = await httpClient.get(`${path}`, {
      params: { sortCol, sortOrder, ...filters },
    });

    const { results } = response.data.payload;
    return (results || []).map((r) => new type(r));
  }

  protected static async getPaginated<T, F>(
    userId: string,
    path: string,
    type: new (...args) => T,
    pageNumber: number,
    pageSize: number,
    sortCol: string,
    sortOrder: string,
    filters?: F
  ): Promise<PaginatedResult<T>> {
    const response: ApiResponse<T> = await httpClient.get(`${path}/${userId}`, {
      params: { pageNumber, pageSize, sortCol, sortOrder, ...filters },
    });
    const { paginatedResults } = response.data.payload;
    paginatedResults.rows = (paginatedResults.rows || []).map(
      (r) => new type(r)
    );

    return paginatedResults;
  }

  protected static async getOne<T>(
    path: string,
    type: new (...args) => T
  ): Promise<T | undefined> {
    const response: ApiResponse<T> = await httpClient.get(`${path}`);

    const { result } = response.data.payload;
    if (result) {
      return new type(result);
    }
  }

  public async save(): Promise<T> {
    const method = this.id ? "PUT" : "POST";
    const response: ApiResponse<T> = await httpClient.request({
      method,
      url: this.path,
      data: this,
    });

    const { result } = response.data.payload;
    if (result) {
      return new this.type(response.data.payload.result);
    }

    throw new Error();
  }

  public async delete(): Promise<void> {
    await httpClient.delete(`${this.path}/${this.id}`);
  }
}
