
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ValidationArgs } from "@vuelidate/core";
import {
  defineComponent,
  inject,
  ComputedRef,
  ref,
  reactive,
  computed,
  onMounted,
} from "vue";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";

import FormInput from "@/components/form/FormInput.vue";
import Button from "@/components/Button.vue";
import ShadowContainer from "@/components/ui/ShadowContainer.vue";
import { getCookie, setCookie } from "@/router";
import Form from "@/components/Form.vue";
import { resetPassword, getResetUser } from "@/api/auth.api";
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import { Theme } from "@/models/Style";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "ChangePassword",
  components: {
    UnauthenticatedLayout,
    Button,
    FormInput,
    ShadowContainer,
    Form,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const theme = inject<ComputedRef<Theme>>("theme");

    const { inPageNotification } = useNotification();

    const getVariant = computed(() => {
      return theme ? "" : "unbranded";
    });

    const logoUrl = computed(() => {
      if (theme) {
        if (theme.value.logo.signin) {
          return theme.value.logo.signin.url;
        } else if (theme.value.logo.app) {
          return theme.value.logo.app.url;
        }
      }
      return null;
    });

    const model = ref({
      newPassword: "",
      confirmNewPassword: "",
    });

    const errors = reactive<{ [K: string]: string }>({});
    const passwordValidation = helpers.regex(
      /^(?=(?:.*[a-z]){2})(?=(?:.*[!@#$%^&*-A-Z.*\d]){2}).{8,30}$/
    );
    const rules: ValidationArgs = {
      newPassword: {
        required: helpers.withMessage(
          "Please enter a valid password",
          required
        ),
        minLength: helpers.withMessage(
          "Please enter a valid password",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          "Please enter a valid password",
          maxLength(30)
        ),
        password: helpers.withMessage(
          "Please enter a valid password",
          passwordValidation
        ),
      },
      confirmNewPassword: {
        required: helpers.withMessage(
          "Please enter a valid password",
          required
        ),
        minLength: helpers.withMessage(
          "Please enter a valid password",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          "Please enter a valid password",
          maxLength(30)
        ),
        password: helpers.withMessage(
          "Please enter a valid password",
          passwordValidation
        ),
      },
    };

    const passwordVisible = ref({
      newPassword: false,
      confirmNewPassword: false,
    });
    const passwordIcon = (type: string) =>
      passwordVisible.value[type] ? "eye" : "eye-slash";

    const passwordType = (type: string) =>
      passwordVisible.value[type] ? "text" : "password";

    const togglePasswordVisibility = (type: string) =>
      (passwordVisible.value[type] = !passwordVisible.value[type]);

    const handleSubmit = async () => {
      try {
        const { newPassword, confirmNewPassword } = model.value;

        await resetPassword({
          newPassword: {
            password: newPassword,
            passwordConfirm: confirmNewPassword,
          },
          email: email.value,
          tenant: tenant.value,
          resetToken: tokenRef.value,
        });
        store.dispatch("setUserPasswordChange", false);
        const returnUrl = getCookie("ReturnUrl");
        setCookie("ReturnUrl", "", -1);

        let body = "auth.password-changed";

        inPageNotification("auth.password-changed-title", body, "success");

        if (returnUrl && !returnUrl.includes("signin")) {
          router.push(decodeURIComponent(returnUrl));
        } else {
          router.push("/");
        }
      } catch (err: any) {
        let body = "errors.unexpected";

        if (err?.message.length > 0) {
          body = err?.message;

          inPageNotification("auth.error-changing-password", body);
        }
      }
    };

    const email = ref("");
    const tenant = ref("");
    const tokenRef = ref("");
    onMounted(async () => {
      let { userId, tenantReset, token } = router.currentRoute.value.params;
      tenant.value = tenantReset as string;
      tokenRef.value = token as string;

      const response = await getResetUser(userId as string, tenant.value);
      const user: any = response.data.payload.result;
      email.value = user.email;
    });

    return {
      model,
      logoUrl,
      errors,
      rules,
      passwordIcon,
      passwordType,
      passwordVisible,
      togglePasswordVisibility,
      handleSubmit,
      getVariant,
    };
  },
});
