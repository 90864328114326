/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setLanguage = "setLanguage",
  setLanguageValues = "setLanguageValues",
  setDatabaseLanguageValues = "setDatabaseLanguageValues",
}

interface AvailableLanguage {
  key: string;
  name: string;
  img?: string;
}

export interface LanguageState {
  current: string;
  languageValues: any;
  databaseLanguageValues: any;
  availableLanguages: AvailableLanguage[];
}

const state: LanguageState = {
  current: "en",
  languageValues: {},
  databaseLanguageValues: {},
  availableLanguages: [
    {
      key: "en",
      name: "English (UK)",
      img: require("@/assets/img/flags/uk.svg"),
    },
    {
      key: "us",
      name: "English (US)",
      img: require("@/assets/img/flags/usa.svg"),
    },
    {
      key: "fr",
      name: "Français",
      img: require("@/assets/img/flags/france.svg"),
    },
    {
      key: "de",
      name: "Deutsche",
      img: require("@/assets/img/flags/germany.svg"),
    },
    {
      key: "se",
      name: "Sverige",
      img: require("@/assets/img/flags/sweden.svg"),
    },
    {
      key: "it",
      name: "Italiano",
      img: require("@/assets/img/flags/italy.svg"),
    },
    {
      key: "gr",
      name: "Ελληνικά",
      img: require("@/assets/img/flags/greece.svg"),
    },
    {
      key: "cn",
      name: "普通話",
      img: require("@/assets/img/flags/china.svg"),
    },
  ],
};

const getters: GetterTree<LanguageState, null> = {
  language: (state: LanguageState) => state.current,
  languageValues: (state: LanguageState) => state.languageValues,
  databaseLanguageValues: (state: LanguageState) =>
    state.databaseLanguageValues,
  availableLanguages: (state: LanguageState) => state.availableLanguages,
};

const actions: ActionTree<LanguageState, null> = {
  setLanguage({ commit }, language: string) {
    commit(MutationKeys.setLanguage, language);
  },
  setLanguageValues({ commit }, values: any) {
    commit(MutationKeys.setLanguageValues, values);
  },
  setDatabaseLanguageValues({ commit }, values: any) {
    commit(MutationKeys.setDatabaseLanguageValues, values);
  },
};

const mutations: MutationTree<LanguageState> = {
  [MutationKeys.setLanguage]: (state: LanguageState, language: string) => {
    state.current = language;
  },
  [MutationKeys.setLanguageValues]: (state: LanguageState, values: any) => {
    state.languageValues = values;
  },
  [MutationKeys.setDatabaseLanguageValues]: (
    state: LanguageState,
    values: any
  ) => {
    state.databaseLanguageValues = values;
  },
};

const language: Module<LanguageState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { language };
