import { renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94c68b44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-container" }
const _hoisted_2 = {
  key: 1,
  class: "page-details"
}
const _hoisted_3 = { class: "visible-lg" }
const _hoisted_4 = {
  key: 0,
  class: "progress-wrapper"
}
const _hoisted_5 = { class: "page-content" }
const _hoisted_6 = {
  key: 0,
  class: "button-container border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#mobile-nav"
        }, [
          _renderSlot(_ctx.$slots, "mobile-nav", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hideMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "nav", {}, undefined, true),
            (!_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_ProgressBar, { value: _ctx.hotspotProgress }, null, 8, ["value"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["page-content__inner", _ctx.hideMenu ? 'no-menu' : ''])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (!_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "buttons", {}, () => [
                (_ctx.showNext && !_ctx.hideMenu)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      text: "pagination.step.next",
                      onClick: _ctx.handleNextClick
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.showPrevious && !_ctx.hideMenu)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      text: "pagination.step.previous",
                      variant: "secondary",
                      onClick: _ctx.handlePreviousClick
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.showPrevious || !_ctx.showNext)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 2,
                      text: !_ctx.hideMenu ? 'hotspot.back' : 'common.back',
                      variant: "secondary",
                      onClick: _ctx.handleBackClick
                    }, null, 8, ["text", "onClick"]))
                  : _createCommentVNode("", true)
              ], true)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}