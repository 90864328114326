
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { useLoading } from "@/plugins/loading";
import { Action, Tick } from "@/models";
import Button from "@/components/Button.vue";
import Checklist from "@/components/Checklist.vue";
import Modal from "@/components/Modal.vue";
import { createTick, deleteTick } from "@/api/tick.api";
import NewChecklist from "@/components/hotspot/edit/NewChecklist.vue";

import { useModal } from "@/plugins/modal";
import { useNotification, useLanguage } from "@/composables";

export default defineComponent({
  components: {
    Button,
    Checklist,
    Modal,
    NewChecklist,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const showLoader = useLoading();
    const action = toRef(props, "action");
    const updateActive = toRef(props, "updateActive");

    const isLoading = ref<boolean>(false);
    const showModal = useModal();

    const { translateText } = useLanguage();
    const { inPageNotification } = useNotification();

    const showNewModal = ref<boolean>(false);
    const handleAddNewChecklist = async () => (showNewModal.value = true);

    const handleSaveChecklist = async () => {
      try {
        showNewModal.value = false;
        await createTick({
          ...newChecklist,
          actionId: props.action.id,
        });
        await resetForm();
        await getTicksWithProgress();
        inPageNotification("action.saved-title", "action.saved", "success");
      } catch (error) {
        console.log(error);
        inPageNotification("action.error-title", "action.error-save", "error");
      }
    };

    const handleRemoveTick = async (tickId) => {
      try {
        showModal({
          title: "action.ticks.delete.title",
          body: "action.ticks.delete.description",
          onConfirm: async () => {
            showLoader(true);
            await deleteTick(tickId);
            await getTicksWithProgress();
            showLoader(false);
            inPageNotification(
              "action.saved-title",
              "action.ticks.edit.removed",
              "success"
            );
            emit("updateHotspot");
          },
        });
      } catch (error) {
        console.log(error);
        inPageNotification("action.error-title", "action.error-save", "error");
      }
    };

    const handleCancel = () => {
      // resetNewAction();
      showNewModal.value = false;
    };

    const ticks = ref<Array<Tick>>();
    const getTicksWithProgress = async () => {
      ticks.value = await Tick.getForActionAndUser(
        props.action.id,
        store.getters.currentUser.id
      );
    };

    const orderedTicks = computed(() =>
      (ticks.value ?? []).sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      )
    );

    const checklistItems = computed(() => {
      return orderedTicks.value.map((tick) => {
        const title = tick.textItems.find((ti) => ti.purpose === "title");
        const description = tick.textItems.find(
          (ti) => ti.purpose === "description"
        );
        const adminDescription = tick.textItems.find(
          (ti) => ti.purpose === "adminDescription"
        );
        const progress = (tick.tickProgressObjects ?? [])[0];
        return {
          id: tick.id,
          name: title?.data ?? "",
          description: description?.data ?? "",
          adminDescription: adminDescription?.data ?? "",
          completed: progress && progress.completed,
        };
      });
    });

    onMounted(async () => {
      await getTicksWithProgress();
      emit("isLoaded", true);
    });

    watch(action, async () => {
      await getTicksWithProgress();
    });

    watch(updateActive, async () => {
      try {
        if (updateActive.value) {
          emit("updated", true);
        }
      } catch (err) {
        console.log(err);
        emit("updated", false);
      }
    });

    const isValid = ref<boolean>(false);

    const initialState = {
      title: "",
      description: "",
      adminDescription: "",
      type: "knowledge",
      level: "global",
      signatureRequired: false,
    };

    let newChecklist = reactive<{
      title: string;
      description: string;
      adminDescription: string;
      type: string;
      level: string;
      signatureRequired?: boolean;
    }>({ ...initialState });

    const resetForm = async () => {
      Object.assign(newChecklist, initialState);
    };

    return {
      isValid,
      isLoading,
      ticks,
      orderedTicks,
      checklistItems,
      showNewModal,
      newChecklist,
      handleSaveChecklist,
      handleRemoveTick,
      handleCancel,
      handleAddNewChecklist,
      translateText,
    };
  },
});
