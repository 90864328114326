import httpClient from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getReferenceRecord = async (referenceId: string, referenceRecordId: string, authId: string, tenant: string): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/references/reference-record/${referenceId}/record/${referenceRecordId}/id/${authId}/tenant/${tenant}`);

export const putViewedReferenceRecord = async (referenceId: string, referenceRecordId: string, authId: string, tenant: string): Promise<AxiosResponse<any>> =>
  await httpClient.put(`/references/viewed/${referenceId}/record/${referenceRecordId}/id/${authId}/tenant/${tenant}`, null);

export const putReferenceRecord = async (referenceId: string, referenceRecordId: string, authId: string, tenant: string, referenceRecord: any): Promise<AxiosResponse<any>> =>
  await httpClient.put(`/references/reference-record/${referenceId}/record/${referenceRecordId}/id/${authId}/tenant/${tenant}`, referenceRecord);