
import { defineComponent, computed, onMounted, PropType, ref } from "vue";
import { useRouter } from "vue-router";
import { NavItem } from "@/interfaces";
import { Hotspot, Review, User } from "@/models";
import NavList from "@/components/NavList.vue";
import TabList from "@/components/TabList.vue";
import BaseHotspot from "@/components/hotspot/_BaseHotspot.vue";
import ReviewsAction from "@/components/hotspot/action/ReviewsAction.vue";

export default defineComponent({
  name: "ReviewsHotspot",
  components: {
    NavList,
    BaseHotspot,
    TabList,
    ReviewsAction,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    hotspotProgress: {
      type: Number,
      default: 0,
    },
    showNext: Boolean,
    showPrevious: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();

    const hotspot = computed(() => props.hotspot);
    const reviews = ref<Array<Review>>([]);

    const currentReview = computed(() => {
      let { actionId } = router.currentRoute.value.params;
      if (!actionId) {
        return reviews.value[0];
      }

      return reviews.value.find((a) => a.id === (actionId as string));
    });

    const getReviews = async () => {
      reviews.value = await User.getReviewsByHotspotAndUser(hotspot.value.id);
      reviews.value.sort(
        (review1, review2) => review1.reviewNumber - review2.reviewNumber
      );
    };

    const navItems = computed<Array<NavItem>>(() => {
      const hotspotId = hotspot.value.id;
      return reviews.value.map((review: any) => ({
        id: review.id,
        url: `/track/hotspot/${hotspotId}/${review.id}`,
        title: "Review " + review.reviewNumber,
        completed: review.completed,
      }));
    });
    const activeNavItem = computed(() => {
      if (!currentReview.value) {
        return navItems.value[0]?.url;
      }

      return (
        navItems.value.find((i) => i.id === currentReview.value?.id)?.url ?? ""
      );
    });

    const hotspotTitle = computed(() => {
      const ti = hotspot.value.textItems.find((t) => t.purpose === "title");
      return ti?.data ?? "";
    });

    const hotspotDescription = computed(() => {
      const ti = hotspot.value.textItems.find(
        (t) => t.purpose === "description"
      );
      return ti?.data ?? "";
    });

    const handleReviewComplete = () => {
      emit("update-progress");
      getReviews();
    };

    const currentReviewIndex = computed(() =>
      reviews.value.findIndex((a) => a.id === currentReview.value?.id)
    );

    const handleNextClick = () => {
      emit("next");
    };

    const handleBackClick = () => {
      router.push(`/track`);
    };

    const handlePreviousClick = () => {
      emit("previous");
    };

    onMounted(() => {
      getReviews();
    });

    return {
      currentReview,
      navItems,
      activeNavItem,
      currentReviewIndex,
      hotspotTitle,
      hotspotDescription,
      handleReviewComplete,
      handleNextClick,
      handlePreviousClick,
      handleBackClick,
    };
  },
});
