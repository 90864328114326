
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRef,
} from "vue";
import {
  formatDistanceToNowStrict,
  parseISO,
  format,
  isBefore,
} from "date-fns";
import { ContactNumber, EmergencyContact, Address, User } from "@/models";
import Card from "@/components/Card.vue";
import Alert from "@/components/Alert.vue";
import AddressList from "@/components/user/shared/AddressList.vue";
import EmergencyContactList from "@/components/user/shared/EmergencyContactList.vue";
import ProgressChart from "@/components/user/overview/ProgressChart.vue";
import { getUserFeatureConfigs } from "@/api/users.api";

export default defineComponent({
  components: {
    Card,
    ProgressChart,
    Alert,
    AddressList,
    EmergencyContactList,
  },
  props: {
    user: {
      type: Object as PropType<User>,
    },
    isAuthenticatedUser: Boolean,
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const model = toRef(props, "user");
    const groupNames = computed(() => model.value?.groupNames.join(", ") || "");
    const startDateAsDate = computed(() =>
      model.value && model.value.startDate
        ? parseISO(model.value.startDate)
        : new Date()
    );
    const startDate = computed(() =>
      format(startDateAsDate.value, "dd-MM-yyyy")
    );
    const endDateAsDate = computed(() =>
      model.value && model.value.endDate ? parseISO(model.value.endDate) : null
    );
    const endDate = computed(() =>
      endDateAsDate.value ? format(endDateAsDate.value, "dd-MM-yyyy") : ""
    );

    const employmentLength = computed(() =>
      isBefore(startDateAsDate.value, new Date())
        ? formatDistanceToNowStrict(startDateAsDate.value)
        : null
    );
    const overallPercentage = computed(() => {
      return props.user ? props.user.progress.overallPercentage : 0;
    });
    const hotspotProgress = computed(() => {
      if (!model.value) {
        return;
      }
      let overall = 0;
      model.value.progress.hotspots.forEach(
        (h) => (overall += h.percentageComplete)
      );

      return model.value.progress.hotspots.map((item) => ({
        name: item.title,
        value: item.percentageComplete,
      }));
    });
    const userAddresses = computed(() => {
      return model.value ? (model.value.addresses as Array<Address>) : [];
    });
    const emergencyContacts = computed(() =>
      model.value
        ? (model.value.emergencyContacts as Array<EmergencyContact>)
        : []
    );
    const contactNumber = computed(() => {
      let contactNumber: ContactNumber;
      if (!model.value || model.value.contactNumbers.length === 0) {
        return "";
      } else {
        contactNumber = model.value.contactNumbers.find(
          (contactNum) => contactNum.isPrimary
        ) as ContactNumber;
        if (!contactNumber) {
          contactNumber = model.value.contactNumbers[0];
        }
      }
      return contactNumber?.number ?? "";
    });

    const showAlerts = computed(
      () =>
        model.value &&
        (model.value.isLockedOut || !model.value.welcomeEmailSent)
    );
    const handleUnlockUser = async () => {
      await model.value?.unlock();
      if (model.value) {
        model.value.isLockedOut = false;
      }
      emit("refresh");
    };
    const handleSendWelcomeEmail = async () => {
      await model.value?.sendWelcomeEmail();
      if (model.value) {
        model.value.welcomeEmailSent = true;
      }
      emit("refresh");
    };
    const hiddenUserFields = ref<string[]>([]);

    onMounted(async () => {
      const usersFeatureConfigs = await getUserFeatureConfigs();
      usersFeatureConfigs.data.payload.result.forEach((featureConfig) => {
        switch (featureConfig.feature) {
          case "hidden-user-fields":
            hiddenUserFields.value = featureConfig.condition.split(",");
            break;
        }
      });
    });

    return {
      model,
      showAlerts,
      groupNames,
      userAddresses,
      emergencyContacts,
      contactNumber,
      startDate,
      endDate,
      employmentLength,
      hotspotProgress,
      handleUnlockUser,
      handleSendWelcomeEmail,
      overallPercentage,
      hiddenUserFields,
    };
  },
});
