
import { defineComponent } from "vue";

const themes = ["green", "white", "red", "purple"];
let idIncrement = 0;

export default defineComponent({
  emits: ["change"],
  props: {
    id: String,
    name: String,
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
    additionalClasses: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "green",
      validator: (value: string) => themes.some((v) => v === value),
    },
  },
  computed: {
    forcedId() {
      return this.id === undefined ? this.generatedId : this.id;
    },
  },
  data() {
    return {
      generatedId: "L-checkbox-" + idIncrement++,
    };
  },
  setup(props, { emit }) {
    const handleValueChange = (e: Event) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      emit("change", { name: props.name, value: (e.target as any).checked });

    return { handleValueChange };
  },
});
