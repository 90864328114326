import { computed, onMounted, onUnmounted, ref } from "vue";

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const currentBreakpoint = computed(() => {
    if (windowWidth.value < 640) return "sm";
    if (windowWidth.value >= 640 && windowWidth.value < 1024) return "md";
    if (windowWidth.value >= 1024 && windowWidth.value < 1280) return "lg";
    return "xl";
  });

  const screenWidth = computed(() => windowWidth.value);
  const isSmallScreen = computed(() => windowWidth.value < 1024);

  return { screenWidth, currentBreakpoint, isSmallScreen };
};
