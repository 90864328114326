
import { defineComponent } from "@vue/runtime-core";
import { useLanguage } from "@/composables";

export default defineComponent({
  name: "Text",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["updateFiles"],
  setup(props, { emit }) {
    const { translateText } = useLanguage();

    const handleChange = (event: Event) => {
      emit("updateFiles", event);
    };

    return {
      handleChange,
      translateText,
    };
  },
});
