import httpClient, { HttpResponse } from "@/api/httpClient";
import { ActionProgressResponse } from ".";
import { Model } from "./Model";

export class InfoContent extends Model<InfoContent> {
  public data: string;
  public languageId: string;
  public reference: string;

  constructor({
    id = "",
    data = "",
    languageId = "",
    reference = "",
  }: Partial<InfoContent> = {}) {
    super(id, InfoContent, "");
    this.data = data;
    this.languageId = languageId;
    this.reference = reference;
  }

  public static async getForReferences(
    references: Array<string>
  ): Promise<Array<InfoContent>> {
    return await Model.getAll<InfoContent>(
      `/infocontents/content/${JSON.stringify(references)}`,
      InfoContent
    );
  }

  public async completeAction(userId: string): Promise<ActionProgressResponse> {
    const response = await httpClient.post<
      HttpResponse<ActionProgressResponse>
    >(`/actions/actioncompleted`, {
      actionId: this.id,
      userId: userId,
    });

    return response.data.payload.result as ActionProgressResponse;
  }
}
