
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "ActionHotspotHeader",
  components: {
    Button,
  },
  props: {
    hotspotDescription: {
      type: String || Boolean,
      required: false,
    },
    actionTitle: {
      type: String || Boolean,
      required: false,
    },
    actionDescription: {
      type: String || Boolean,
      required: false,
    },
    buttonData: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const handleButtonClick = async () => {
      emit("handleButtonClick");
    };
    const contentBox = ref();

    const widthRef = ref(0);
    const heightRef = ref(0);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        widthRef.value = entry.contentRect.width;
        heightRef.value = entry.contentRect.height;
      }
    });
    onMounted(() => {
      if (props.hotspotDescription) {
        return resizeObserver.observe(contentBox.value);
      }
    });
    onBeforeUnmount(() => {
      if (props.hotspotDescription) {
        return resizeObserver.unobserve(contentBox.value);
      }
    });

    const contentCollapsed = ref(false);

    const hasReadMore = computed(() => {
      return contentCollapsed.value ? true : heightRef.value > 26;
    });

    const toggleRead = () => {
      if (hasReadMore.value) {
        contentCollapsed.value = !contentCollapsed.value;
      }
    };
    return {
      handleButtonClick,
      toggleRead,
      hasReadMore,
      contentBox,
      contentCollapsed,
    };
  },
});
