import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e020d4ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-header" }
const _hoisted_2 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ChecklistVue = _resolveComponent("ChecklistVue")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.isLoading })
  }, [
    (_ctx.checklists)
      ? (_openBlock(), _createBlock(_component_Accordion, {
          key: 0,
          items: _ctx.checklists,
          allowMultiple: ""
        }, {
          title: _withCtx(({ item, open }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_TranslatedText, {
                  path: item.name
                }, null, 8, ["path"])
              ]),
              _createVNode(_component_font_awesome_icon, {
                icon: open ? 'chevron-down' : 'chevron-right'
              }, null, 8, ["icon"])
            ])
          ]),
          body: _withCtx(({ item }) => [
            _createVNode(_component_ChecklistVue, {
              items: item.items,
              onClickItem: _ctx.handleChecklistClick,
              flush: ""
            }, null, 8, ["items", "onClickItem"])
          ]),
          _: 1
        }, 8, ["items"]))
      : _createCommentVNode("", true)
  ], 2))
}