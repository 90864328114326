
import { onMounted, ref, defineComponent, PropType } from "vue";
import FormInput from "@/components/form/FormInput.vue";
import Form from "@/components/Form.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";

export default defineComponent({
  components: { Wysiwyg, FormInput, Form },
  emits: ["inputChange"],
  props: {
    modelValue: {
      type: Object as PropType<{
        title: string;
        description: string;
      }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = ref<any>({
      title: "",
      description: "",
    });

    const handleInputChange = ({ name, value }) => {
      model.value[name] = value;
      emit("inputChange", {
        name: name,
        value: value,
      });
    };

    onMounted(async () => {
      model.value.title = props.modelValue.title;
      model.value.description = props.modelValue.description;
    });

    return {
      model,
      handleInputChange,
    };
  },
});
