import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26e561b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subtitle" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 0,
  class: "col md:col-6"
}
const _hoisted_4 = {
  key: 1,
  class: "col md:col-6"
}
const _hoisted_5 = {
  key: 2,
  class: "col md:col-12"
}
const _hoisted_6 = {
  key: 3,
  class: "col md:col-12"
}
const _hoisted_7 = { class: "col md:col-12" }
const _hoisted_8 = {
  key: 4,
  class: "col md:col-12"
}
const _hoisted_9 = {
  key: 5,
  class: "col md:col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, [
      _createVNode(_component_TranslatedText, { path: "common.company-details" })
    ]),
    _createVNode(_component_Form, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      errors: _ctx.errorModel,
      "onUpdate:errors": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errorModel) = $event)),
      valid: _ctx.valid,
      "onUpdate:valid": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
      schema: _ctx.schema
    }, {
      default: _withCtx(({ handleInputChange, handleDateUpdate }) => [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.hiddenUserFields.includes('startDate'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_DatePicker, {
                  value: _ctx.model.startDate,
                  error: _ctx.errors['startDate'],
                  disabled: _ctx.config?.isMe,
                  onChange: handleDateUpdate,
                  label: "form.start-date",
                  name: "startDate",
                  id: "startDate"
                }, null, 8, ["value", "error", "disabled", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hiddenUserFields.includes('endDate'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_DatePicker, {
                  value: _ctx.model.endDate,
                  error: _ctx.errors['endDate'],
                  disabled: _ctx.config?.isMe,
                  onChange: handleDateUpdate,
                  label: "form.end-date",
                  name: "endDate",
                  id: "endDate"
                }, null, 8, ["value", "error", "disabled", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hiddenUserFields.includes('position'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_FormInput, {
                  value: _ctx.model.position,
                  error: _ctx.errors['position'],
                  disabled: _ctx.config?.isMe,
                  autocomplete: _ctx.autocomplete,
                  onChange: handleInputChange,
                  label: "form.job-title",
                  name: "position",
                  id: "position",
                  customClass: _ctx.customClass('position')
                }, null, 8, ["value", "error", "disabled", "autocomplete", "onChange", "customClass"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.config?.isMe)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_MultiSelect, {
                  value: _ctx.selectedRoles,
                  error: _ctx.errors['roles'],
                  disabled: _ctx.config?.isMe,
                  autocomplete: _ctx.autocomplete,
                  onChange: handleInputChange,
                  label: "form.roles",
                  name: "roles",
                  id: "roles",
                  reset: _ctx.reset,
                  items: _ctx.roleSelectOptions,
                  customClass: _ctx.customClass('roles')
                }, null, 8, ["value", "error", "disabled", "autocomplete", "onChange", "reset", "items", "customClass"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MultiSelect, {
              value: _ctx.selectedManagers,
              error: _ctx.errors['managers'],
              disabled: _ctx.config?.isMe,
              autocomplete: _ctx.autocomplete,
              onChange: handleInputChange,
              label: "form.managers",
              name: "managers",
              id: "managers",
              reset: _ctx.reset,
              items: _ctx.managerSelectOptions,
              customClass: _ctx.customClass('managers')
            }, null, 8, ["value", "error", "disabled", "autocomplete", "onChange", "reset", "items", "customClass"])
          ]),
          (!_ctx.config?.isMe)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_MultiSelect, {
                  value: _ctx.selectedGroups,
                  error: _ctx.errors['groups'],
                  disabled: _ctx.config?.isMe,
                  autocomplete: _ctx.autocomplete,
                  onChange: handleInputChange,
                  label: "form.cohorts",
                  name: "groups",
                  id: "groups",
                  reset: _ctx.reset,
                  items: _ctx.groupSelectOptions,
                  customClass: _ctx.customClass('groups')
                }, null, 8, ["value", "error", "disabled", "autocomplete", "onChange", "reset", "items", "customClass"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hiddenUserFields.includes('employeeNumber'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_FormInput, {
                  value: _ctx.model.employeeNumber,
                  error: _ctx.errors['employeeNumber'],
                  disabled: _ctx.config?.isMe,
                  autocomplete: _ctx.autocomplete,
                  onChange: handleInputChange,
                  label: "form.employee-number",
                  name: "employeeNumber",
                  id: "empoyeeNumber"
                }, null, 8, ["value", "error", "disabled", "autocomplete", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.additionalFields?.company)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 6 }, _renderList(Object.keys(
                _ctx.additionalFields?.company
              ), (additionalField, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col md:col-6",
                  key: index
                }, [
                  _createVNode(_component_FormInput, {
                    value: _ctx.additionalFields.company[additionalField],
                    error: '',
                    disabled: _ctx.config?.isMe,
                    autocomplete: _ctx.autocomplete,
                    onChange: _ctx.handleAdditionalFieldChange,
                    label: 
                  additionalField.charAt(0).toUpperCase() +
                  additionalField.slice(1)
                ,
                    name: additionalField,
                    id: additionalField,
                    customClass: _ctx.customClass(additionalField)
                  }, null, 8, ["value", "disabled", "autocomplete", "onChange", "label", "name", "id", "customClass"])
                ]))
              }), 128))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "errors", "valid", "schema"])
  ]))
}