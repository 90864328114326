import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-519072cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card__header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'no-border': _ctx.noBorder, 'no-padding': _ctx.noPadding }])
  }, [
    (!!_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h6", null, [
            _createVNode(_component_TranslatedText, { path: _ctx.title }, null, 8, ["path"])
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}