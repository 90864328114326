import httpClient, { ApiResponse } from "@/api/httpClient";
import { StyleValue, ImageContent } from "./Style";

export class TenantStyle {
  public config: Array<StyleValue>;
  public background: Array<StyleValue>;
  public values: Array<StyleValue>;
  public themeImages: Array<ImageContent>;
  public language?: any;
  public inMaintenance?: boolean;

  constructor({
    config = [] as Array<StyleValue>,
    background = [] as Array<StyleValue>,
    values = [] as Array<StyleValue>,
    themeImages = [] as Array<ImageContent>,
    language = {},
    inMaintenance = false,
  } = {}) {
    this.config = config;
    this.background = background;
    this.values = values;
    this.themeImages = themeImages;
    this.language = language;
    this.inMaintenance = inMaintenance;
  }
}

export class Tenant {
  public name: string;
  public style: TenantStyle;
  public tenant?: string;
  public inMaintenance?: boolean;

  constructor(
    { name = "", style = new TenantStyle() } = {},
    tenant: string,
    inMaintenance = false
  ) {
    this.name = name;
    this.style = style;
    this.tenant = tenant;
    this.inMaintenance = inMaintenance;
  }

  public static async get(name: string): Promise<Tenant> {
    const response: ApiResponse<TenantStyle> = await httpClient.get(
      `/theme?tenant=${name}`
    );

    const { result } = response.data.payload;
    return {
      name,
      style: result as TenantStyle,
      inMaintenance: result?.inMaintenance ?? false,
    };
  }

  public static async getByURL(url: string): Promise<Tenant> {
    const response: ApiResponse<Tenant> = await httpClient.get(
      `/globalunits/url/${url}`
    );

    const { result } = response.data.payload;
    return result as Tenant;
  }
}
