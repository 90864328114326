
import { computed, defineComponent, ref } from "@vue/runtime-core";
import { format, parseISO } from "date-fns";

export default defineComponent({
  components: {},
  name: "FormInput",
  props: {
    customClass: String,
    id: String,
    name: String,
    label: String,
    value: {
      type: String,
    },
    maxDate: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    dragging: Boolean,
    editMode: Boolean,
  },
  emits: ["change", "enter", "blur", "edit"],
  setup(props, { emit }) {
    const handleInput = (event: any) => {
      if (
        event?.target?.value &&
        +event?.target?.value.substring(0, 4) > 1900
      ) {
        emit("change", { name: props.id, value: event?.target?.value });
      }
    };

    const isFocussed = ref<boolean>(false);
    const isActive = computed(() => true); //Label is always shwon for date picker
    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = () => (isFocussed.value = false);
    const enter = () => emit("enter");

    const displayDate = (date) => {
      const parsedDate = parseISO(date);

      return !Number.isNaN(new Date(parsedDate).getTime())
        ? format(parsedDate, "yyyy-MM-dd")
        : date;
    };

    const inputDisplay = computed(() => {
      return props.value ? displayDate(props.value) : "";
    });

    const handleEditClick = () => emit("edit");

    return {
      isFocussed,
      isActive,
      inputDisplay,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      handleEditClick,
    };
  },
});
