import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabList = _resolveComponent("TabList")!
  const _component_NavList = _resolveComponent("NavList")!
  const _component_BaseHotspot = _resolveComponent("BaseHotspot")!
  const _component_NewAction = _resolveComponent("NewAction")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hotspot)
      ? (_openBlock(), _createBlock(_component_BaseHotspot, {
          key: 0,
          hotspotProgress: _ctx.hotspotProgress,
          showNext: _ctx.showNext || _ctx.hasNextAction,
          showPrevious: _ctx.showPrevious,
          editMode: _ctx.editMode,
          hideMenu: _ctx.hotspot?.isolatedActions && !_ctx.editMode,
          onNextClick: _ctx.handleNextClick,
          onPreviousClick: _ctx.handlePreviousClick,
          onBackClick: _ctx.handleBackClick
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
              class: "hotspot-action--container",
              hotspot: _ctx.hotspot,
              action: _ctx.currentAction,
              hotspotDescription: _ctx.getHotspotTextItemText('description'),
              actionTitle: _ctx.currentActionTitle,
              actionDescription: _ctx.currentActionDescription,
              currentActionId: _ctx.currentAction?.id ?? '',
              actionProgress: _ctx.currentActionProgress,
              onActionComplete: _ctx.handleActionComplete,
              onActionRemoved: _ctx.handleActionRemoved,
              onHotspotRemoved: _ctx.handleHotspotRemoved
            }, null, 8, ["hotspot", "action", "hotspotDescription", "actionTitle", "actionDescription", "currentActionId", "actionProgress", "onActionComplete", "onActionRemoved", "onHotspotRemoved"]))
          ]),
          _: 2
        }, [
          (!_ctx.hotspot?.isolatedActions || _ctx.editMode)
            ? {
                name: "mobile-nav",
                fn: _withCtx(() => [
                  _createVNode(_component_TabList, {
                    id: "page-mobile-nav",
                    items: _ctx.navItems,
                    activeItem: _ctx.activeNavItem
                  }, null, 8, ["items", "activeItem"])
                ])
              }
            : undefined,
          (!_ctx.hotspot?.isolatedActions || _ctx.editMode)
            ? {
                name: "nav",
                fn: _withCtx(() => [
                  _createVNode(_component_NavList, {
                    items: _ctx.navItems,
                    activeItem: _ctx.activeNavItem,
                    actionNav: true,
                    showIconRight: true,
                    onCreateAction: _ctx.handleCreateAction,
                    onChangeOrder: _ctx.handleChangeOrder
                  }, null, 8, ["items", "activeItem", "onCreateAction", "onChangeOrder"])
                ])
              }
            : undefined
        ]), 1032, ["hotspotProgress", "showNext", "showPrevious", "editMode", "hideMenu", "onNextClick", "onPreviousClick", "onBackClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
      title: "action.new",
      onCancel: _ctx.handleCancel,
      onConfirm: _ctx.handleSaveNewAction,
      onDismiss: _ctx.handleCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NewAction, {
          hotspot: _ctx.hotspot,
          modelValue: _ctx.newAction,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAction) = $event))
        }, null, 8, ["hotspot", "modelValue"])
      ]),
      footer: _withCtx(({ confirm, cancel }) => [
        _createVNode(_component_Button, {
          text: "common.cancel",
          onClick: cancel
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          text: "common.save",
          onClick: confirm,
          disabled: _ctx.newAction.title.length === 0
        }, null, 8, ["onClick", "disabled"])
      ]),
      _: 1
    }, 8, ["modelValue", "onCancel", "onConfirm", "onDismiss"])
  ]))
}