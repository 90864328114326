/* eslint-disable @typescript-eslint/no-explicit-any */
import { Report } from "@/models/Report";
import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setReports = "setReports",
}

export interface ReportState {
  reports: Array<Report>;
}

const state: ReportState = {
  reports: [],
};

const getters: GetterTree<ReportState, null> = {
  reports: (state: ReportState) => state.reports,
};

const actions: ActionTree<ReportState, null> = {
  async syncReports({ commit }) {
    const reports = await Report.get();
    commit(MutationKeys.setReports, reports);
  },
};

const mutations: MutationTree<ReportState> = {
  [MutationKeys.setReports]: (state: ReportState, reports: Array<Report>) => {
    state.reports = reports;
  },
};

const report: Module<ReportState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { report };
