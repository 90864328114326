import httpClient, { ApiResponse } from "@/api/httpClient";
import { Model } from "./Model";

export enum ReportType {
  System = 1,
  Custom = 2,
}

export class Report extends Model<Report> {
  private static readonly path: string = "/report";

  public reportTypeId: ReportType;
  public name: string;
  public description: string;
  public image: string;

  constructor({
    id = "",
    reportTypeId = ReportType.System,
    name = "",
    description = "",
    image = "chart-line",
  } = {}) {
    super(id, Report, Report.path);
    this.reportTypeId = reportTypeId;
    this.name = name;
    this.description = description;
    this.image = image;
  }

  public static async get(
    sortOrder?: string,
    filters?: ReportFilters
  ): Promise<Array<Report>> {
    return await Model.getAll<Report, ReportFilters>(
      Report.path,
      Report,
      "name",
      sortOrder,
      filters
    );
  }

  public static async getById(id: string): Promise<Report | undefined> {
    return Model.getOne(`${this.path}/${id}`, Report);
  }

  public static async getData<T>(reportId: string, params?: any): Promise<T> {
    const response: ApiResponse<T> = await httpClient.get(
      `${Report.path}/data/${reportId}`,
      { params }
    );
    return response.data.payload.result as T;
  }
}

export interface ReportFilters {
  type?: number;
  searchText?: string;
}
