
import { defineComponent, PropType, computed } from "vue";
import { ContactCard as IContact } from "@/interfaces/ui/ContactCard.interface";
import Button from "@/components/Button.vue";
import MultiSelect from "@/components/form/MultiSelect.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Button,
    MultiSelect,
  },
  props: {
    mode: {
      type: String,
      default: "view",
    },
    contact: {
      type: Object as PropType<IContact>,
      required: true,
    },
  },
  data() {
    return {
      noImage: require("@/assets/img/user-no-image.png"),
    };
  },
  setup(props, { emit }) {
    const router = useRouter();
    const sendEmail = (email) => {
      var link = "mailto:" + email;

      window.location.href = link;
    };
    const sendChat = (userId) => {
      var link = "/chat/" + userId;

      router.push({
        path: link,
      });
    };
    const convertTel = (tel) => {
      return tel.replace(/[^0-9]/g, "").replace(/^0/, "+44");
    };
    const getFullLink = (link) => {
      switch (link.type) {
        case "twitter":
          return `https://twitter.com/${link.link}`;
        case "instagram":
          return `https://www.instagram.com/${link.link}`;
        case "linkedin":
          return `https://www.linkedin.com/company/${link.link}`;
        case "facebook":
          return `https://www.facebook.com/${link.link}`;
        default:
          return link.link;
      }
    };
    const getFAIcon = (link) => {
      switch (link.type) {
        case "twitter":
          return ["fab", "twitter"];
        case "instagram":
          return ["fab", "instagram"];
        case "linkedin":
          return ["fab", "linkedin"];
        case "facebook":
          return ["fab", "facebook"];
        default:
          return "external-link-alt";
      }
    };

    const handleRemove = () => {
      emit("removeContact", props.contact.id);
    };
    const groupOptions = computed(() =>
      props.contact.groups
        ? props.contact.groups.map((g) => ({
            ...g,
            name: g?.textItems
              ? g.textItems.find((ti) => ti.purpose === "contact-group-name")
                  ?.data
              : "",
          }))
        : []
    );
    const selectedGroups = computed(() =>
      props.contact.filteredGroups && props.contact.groups
        ? props.contact.filteredGroups.map((fg) => {
            const groupData = props.contact.groups?.find(
              (g) => g.id === fg.contactGroupId
            );
            return {
              ...groupData,
              name: groupData?.textItems
                ? groupData.textItems.find(
                    (ti) => ti.purpose === "contact-group-name"
                  )?.data
                : "",
            };
          })
        : []
    );

    const handleGroupChange = (newGroups) => {
      emit("updateFilters", props.contact.id, newGroups);
    };

    return {
      sendEmail,
      sendChat,
      convertTel,
      getFullLink,
      getFAIcon,
      handleRemove,
      groupOptions,
      selectedGroups,
      handleGroupChange,
    };
  },
});
