
import { useRouter } from "vue-router";
import { defineComponent, onMounted } from "vue";
import { resetDemo } from "@/api/demo.api";

import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { useModal } from "@/plugins/modal";
import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "ResetDemo",
  components: {
    AuthenticatedLayout,
  },
  setup() {
    const router = useRouter();
    const showModal = useModal();
    const { inPageNotification } = useNotification();

    onMounted(async () => {
      const confirmed = await showModal({
        title: "prompts.demo-reset.title",
        body: "prompts.demo-reset.message",
        onConfirm: async () => {
          return true;
        },
      });
      if (!confirmed) {
        router.push({
          path: "/",
        });

        return false;
      }
      const res = await resetDemo();
      if (res.status === 200) {
        router.push({
          path: "/",
        });

        inPageNotification(
          "prompts.demo-reset.title",
          "prompts.demo-reset.success.message",
          "success"
        );
      } else {
        inPageNotification("common.error", "errors.unexpected", "error");
      }
    });
    return {};
  },
});
