import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["box-container", _ctx.val])
  }, [
    _createVNode(_component_font_awesome_icon, {
      class: "icon",
      icon: _ctx.icon
    }, null, 8, ["icon"])
  ], 2))
}