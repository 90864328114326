
import { computed, defineComponent, PropType, ref } from "vue";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import Select from "@/components/form/Select.vue";
import { DocumentsForm as IDocumentsForm } from "@/interfaces/DocumentsForm.interface";
import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    FileDisplay,
    FileInput,
    Form,
    FormInput,
    Select,
  },
  props: {
    modelValue: {
      type: Object as PropType<IDocumentsForm>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const schema = useSchema(Schemas.UploadDocument);

    const { inPageNotification } = useNotification();

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const v$ = useValidate(schema, ref(model.value));

    const regeneratable = computed(() => model.value?.isGenerated ?? false);

    const signatureItems = ref([
      { text: "common.yes", value: "yes" },
      { text: "common.no", value: "no" },
    ]);

    const errors = ref({
      signature: "",
      file: "",
    });

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      emit("validUpdate", !v$.value.$error);
    };

    const handleSelectChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
    };

    const handleChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
    };

    const handleAddFile = (file: File) => {
      model.value.file = file;
      validateField("file");
    };

    const handleDownloadFile = async () => {
      if (model.value.documentContents) {
        try {
          model.value.documentContents.download();
        } catch (err) {
          inPageNotification("Error!", "Unable to download file", "error");
        }
      }
      return;
    };
    const handleRegenerateFile = async () => {
      if (model.value.documentContents) {
        try {
          await model.value.documentContents.regenerate();
          inPageNotification("Success!", "The document has been regenerated. Please wait a moment while the file is prepared.", "success");
          emit("closeModel");
        } catch (err) {
          inPageNotification("Error!", "Unable to regenerate file", "error");
        }
      }
      return;
    };

    const handleEditFile = (file: File) => (model.value.file = file);

    const getFileSize = (sizeInBytes: number) => {
      if (sizeInBytes < 1000) return `${sizeInBytes} bytes`;
      else if (sizeInBytes < 1000000)
        return `${Math.round(sizeInBytes / 1024)}Kb`;
      else return `${Math.round(sizeInBytes / 1024 / 1024)}Mb`;
    };

    const toggleDeleteModal = () => {
      emit("toggleDeleteModal");
    };

    return {
      signatureItems,
      model,
      schema,
      errors,
      toggleDeleteModal,
      handleSelectChange,
      handleChange,
      handleAddFile,
      handleDownloadFile,
      handleEditFile,
      handleRegenerateFile,
      getFileSize,
      regeneratable,
    };
  },
});
