
import { defineComponent } from "vue";
import ChipDropdown from "@/components/ChipDropdown.vue";
import ListMenu from "@/components/ListMenu.vue";

const sizes = ["sm", "", "lg"];

export default defineComponent({
  props: {
    chip: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "",
      validator: (value: string) => sizes.some((val) => val === value),
    },
    customClass: {
      type: String,
    },
  },
  components: {
    ChipDropdown,
    ListMenu,
  },
  emits: ["select-all", "toggle-chip", "select-chip-item", "click-menu-item"],
  setup(_, context) {
    const toggleChip = (index: number) => context.emit("toggle-chip", index);
    const selectAll = (chipId: number) => context.emit("select-all", chipId);
    const selectChipItem = (chip: { chipIndex: number; chipId: number }) =>
      context.emit("select-chip-item", chip);
    const clickMenuItem = (menuItem: { title: string; id: number }) =>
      context.emit("click-menu-item", menuItem);

    return {
      selectAll,
      selectChipItem,
      toggleChip,
      clickMenuItem,
    };
  },
});
