
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentItem = computed(() => props.items[props.currentIndex]);
    const handleNextClick = () => emit("update", props.currentIndex + 1);
    const handlePreviousClick = () => emit("update", props.currentIndex - 1);
    const handleEditItem = () => emit("edit", props.currentIndex - 1);

    const showPrevious = computed(() => props.currentIndex > 0);
    const showNext = computed(
      () => props.currentIndex < props.items.length - 1
    );

    return {
      currentItem,
      showPrevious,
      showNext,
      handleNextClick,
      handlePreviousClick,
      handleEditItem,
    };
  },
});
