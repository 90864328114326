import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2afa360"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col md:col-6" }
const _hoisted_6 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_FileInput = _resolveComponent("FileInput")!
  const _component_FileDisplay = _resolveComponent("FileDisplay")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
    schema: _ctx.schema
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormInput, {
            value: _ctx.model.title,
            id: "edit-document-title",
            name: "title",
            label: "Title",
            disabled: !_ctx.editable,
            onChange: _ctx.handleChange
          }, null, 8, ["value", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormInput, {
            value: _ctx.model.description,
            id: "edit-document-description",
            name: "description",
            label: "Description",
            disabled: !_ctx.editable,
            onChange: _ctx.handleChange
          }, null, 8, ["value", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_FormInput, {
            value: _ctx.model.sideMenuText,
            id: "edit-document-side-menu-text",
            name: "sideMenuText",
            label: "Side Menu Text",
            disabled: !_ctx.editable,
            onChange: _ctx.handleChange
          }, null, 8, ["value", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Select, {
            id: "edit-document-signature-required",
            name: "signature",
            label: "documents.signature-required",
            placeholder: "form.placeholder.select",
            value: _ctx.model.signature?.toLowerCase(),
            items: _ctx.signatureItems,
            error: _ctx.errors.signature,
            required: "",
            onChange: _ctx.handleSelectChange
          }, null, 8, ["value", "items", "error", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.model.file && !_ctx.model.documentContents?.fileName)
            ? (_openBlock(), _createBlock(_component_FileInput, {
                key: 0,
                name: "file-upload",
                acceptString: "application/pdf",
                existingFile: null,
                onAddFile: _ctx.handleAddFile
              }, null, 8, ["onAddFile"]))
            : (_openBlock(), _createBlock(_component_FileDisplay, {
                key: 1,
                fileName: 
            _ctx.model.file ? _ctx.model.file.name : _ctx.model.documentContents.fileName
          ,
                fileType: "pdf",
                editAcceptString: "application/pdf",
                downloadable: "",
                regeneratable: _ctx.regeneratable,
                disabled: !_ctx.editable,
                deletable: "",
                onDownload: _ctx.handleDownloadFile,
                onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDeleteModal())),
                onRegenerate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleRegenerateFile())),
                onEdit: _ctx.handleEditFile
              }, _createSlots({ _: 2 }, [
                (_ctx.model.file)
                  ? {
                      name: "secondaryText",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFileSize(_ctx.model.file.size)), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["fileName", "regeneratable", "disabled", "onDownload", "onEdit"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "schema"]))
}