import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

export interface ActionTypeConfig {
  signature?: boolean;
  audience?: Array<string>;
  create?: boolean;
}

export interface AudienceTypeConfig {
  exclude?: Array<string>;
}

export interface ActionType {
  value: string;
  text: string;
  config?: ActionTypeConfig;
}

export interface AudienceOption {
  value: string;
  text: string;
  config?: AudienceTypeConfig;
}

export interface ActionState {
  actionTypes: Array<ActionType>;
  audienceOptions: Array<AudienceOption>;
}

const state: ActionState = {
  actionTypes: [
    {
      value: "document",
      text: "action.type.document",
    },
    {
      value: "video",
      text: "action.type.video",
      config: {
        signature: false,
      },
    },
    {
      value: "newstarter",
      text: "action.type.newstarter-form",
    },
    {
      value: "form",
      text: "action.type.form",
    },
    {
      value: "info",
      text: "action.type.infocontent",
      config: {
        audience: ["global"],
        signature: false,
      },
    },
    {
      value: "ticks",
      text: "action.type.ticks",
      config: {
        audience: ["global"],
        signature: false,
      },
    },
    {
      value: "link",
      text: "action.type.link",
      config: {
        audience: ["global"],
        signature: false,
      },
    },
    {
      value: "contact-group",
      text: "action.type.contact-group",
    },
  ],
  audienceOptions: [
    {
      value: "global",
      text: "action.level.global",
    },
    {
      value: "tag",
      text: "action.level.tag",
      config: {
        exclude: ["contact-group", "link", "ticks", "info", "form", "newstarter", "video"],
      },
    },
    {
      value: "group",
      text: "action.level.group",
    },
    {
      value: "user",
      text: "action.level.user",
    },
  ],
};

const getters: GetterTree<ActionState, null> = {
  allActionTypes: (state: ActionState) => state.actionTypes,
  allAudienceOptions: (state: ActionState) => state.audienceOptions,
};

const actions: ActionTree<ActionState, null> = {};

const mutations: MutationTree<ActionState> = {};

const action: Module<ActionState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { action };
