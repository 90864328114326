
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  emits: ["change-row-count", "change-page"],
  setup(props, { emit }) {
    const store = useStore();

    const rowCountValue = ref<number>(parseInt(store.getters.pageSize));
    const pageStart = computed(
      () => (props.currentPage - 1) * rowCountValue.value + 1
    );
    const pageEnd = computed(() => {
      const end = pageStart.value + (rowCountValue.value - 1);
      return end <= props.totalRows ? end : props.totalRows;
    });
    const pageCount = computed(() =>
      Math.ceil(props.totalRows / rowCountValue.value)
    );
    const isFirstPage = computed(() => props.currentPage === 1);
    const isLastPage = computed(
      () => !pageEnd.value || props.currentPage === pageCount.value
    );

    const handleChangePage = (action: string) => {
      let targetPage = props.currentPage;
      if (action === "next") {
        if (props.currentPage + 1 <= pageCount.value) {
          targetPage++;
        }
      } else if (action === "prev") {
        if (props.currentPage - 1 >= 1) {
          targetPage--;
        }
      } else if (action === "first") {
        targetPage = 1;
      } else {
        targetPage = pageCount.value;
      }
      emit("change-page", targetPage);
    };

    const handleChangeRowCount = (e: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newValue = parseInt((e.target as any).value);
      store.dispatch("setPageSize", newValue);
      emit("change-row-count", newValue);
    };

    return {
      rowCountValue,
      pageStart,
      pageEnd,
      isFirstPage,
      isLastPage,
      handleChangePage,
      handleChangeRowCount,
    };
  },
  name: "Pagination",
});
