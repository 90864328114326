import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "input-icon prepend"
}
const _hoisted_4 = ["id", "name", "value", "rows", "disabled", "placeholder"]
const _hoisted_5 = {
  key: 3,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options?.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.options?.id,
          class: _normalizeClass({
        active: _ctx.options?.active,
        'has-icon': !!_ctx.$slots.prepend,
      })
        }, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.label
          }, null, 8, ["path"]),
          (_ctx.options?.required)
            ? (_openBlock(), _createBlock(_component_TranslatedText, {
                key: 0,
                path: "form.required.asterix",
                class: "required-asterix"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.prepend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "prepend", {
            value: _ctx.options?.value
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      id: _ctx.options?.id,
      name: _ctx.options?.name,
      value: _ctx.options?.value,
      rows: _ctx.options?.rows ?? 3,
      disabled: _ctx.options?.disabled,
      class: _normalizeClass({
        error: !!_ctx.options?.error,
        'message-visible': _ctx.options?.error && !_ctx.isFocussed,
      }),
      placeholder: _ctx.options?.placeholder,
      style: _normalizeStyle({ resize: _ctx.options?.resizable ? '' : 'none' }),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"]))
    }, null, 46, _hoisted_4),
    (!!_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "input-icon append",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, [
          _renderSlot(_ctx.$slots, "append", {
            value: _ctx.options?.value
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.options?.error && !_ctx.isFocussed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.error
          }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ]))
}