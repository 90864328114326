import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64f75d43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }
const _hoisted_2 = { class: "play-pause" }
const _hoisted_3 = { class: "fullscreen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isPlaying)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "icon",
            icon: "pause",
            size: "lg",
            onClick: _ctx.togglePlayback
          }, null, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            class: "icon",
            icon: "play",
            size: "lg",
            onClick: _ctx.togglePlayback
          }, null, 8, ["onClick"]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isFullScreen)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "icon",
            icon: "expand",
            size: "lg",
            onClick: _ctx.toggleFullScreen
          }, null, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            class: "icon",
            icon: "compress",
            size: "lg",
            onClick: _ctx.toggleFullScreen
          }, null, 8, ["onClick"]))
    ])
  ]))
}