import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c870467"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["d"]
const _hoisted_3 = { class: "ad-Points" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Point = _resolveComponent("Point")!
  const _component_Cubic = _resolveComponent("Cubic")!

  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["ad-SVG", { edit: _ctx.edit }]),
    width: _ctx.w,
    height: _ctx.h,
    viewBox: _ctx.viewBox,
    onClick: _cache[0] || (_cache[0] = (e) => _ctx.addPoint(e)),
    onMousemove: _cache[1] || (_cache[1] = (e) => _ctx.handleMouseMove(e)),
    style: _normalizeStyle(_ctx.background)
  }, [
    (_ctx.showGrid)
      ? (_openBlock(), _createBlock(_component_Grid, {
          key: 0,
          w: _ctx.w,
          h: _ctx.h,
          grid: _ctx.grid,
          size: _ctx.size
        }, null, 8, ["w", "h", "grid", "size"]))
      : _createCommentVNode("", true),
    _createElementVNode("path", {
      class: "ad-Path",
      d: _ctx.path
    }, null, 8, _hoisted_2),
    _createElementVNode("g", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.points, (point, index) => {
        return (_openBlock(), _createElementBlock("g", {
          key: index,
          class: _normalizeClass(_ctx.getClass(index, point))
        }, [
          (_ctx.hotspots[index])
            ? (_openBlock(), _createBlock(_component_Point, {
                key: 0,
                item: _ctx.hotspots[index],
                index: index,
                x: point.x,
                y: point.y,
                scale: _ctx.scale,
                edit: _ctx.edit,
                isActive: _ctx.activePoint === index,
                completed: 
            _ctx.hotspots[index].hotspotProgressObjects.length
              ? _ctx.hotspots[index].hotspotProgressObjects[0].progressPercentage ===
                100
              : false
          ,
                onSetDraggedPoint: _ctx.setDraggedPoint,
                onDoubleClick: ($event: any) => (_ctx.handleDoubleClick(index))
              }, null, 8, ["item", "index", "x", "y", "scale", "edit", "isActive", "completed", "onSetDraggedPoint", "onDoubleClick"]))
            : _createCommentVNode("", true),
          (point.c && _ctx.showGrid)
            ? (_openBlock(), _createBlock(_component_Cubic, {
                key: 1,
                index: index,
                p1x: _ctx.points[index - 1].x,
                p1y: _ctx.points[index - 1].y,
                p2x: point.x,
                p2y: point.y,
                x1: point.c[0].x,
                y1: point.c[0].y,
                x2: point.c[1].x,
                y2: point.c[1].y,
                onSetDraggedCubic: _ctx.setDraggedCubic
              }, null, 8, ["index", "p1x", "p1y", "p2x", "p2y", "x1", "y1", "x2", "y2", "onSetDraggedCubic"]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ], 46, _hoisted_1))
}