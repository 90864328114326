import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe80d256"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["search type-body", { show: _ctx.searchOpen }])
    }, [
      _createElementVNode("label", {
        for: "search",
        class: _normalizeClass({ hide: _ctx.searchTerm }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
      }, [
        _createVNode(_component_TranslatedText, { path: "common.search" })
      ], 2),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.id,
        name: "search",
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateSearchTerm && _ctx.updateSearchTerm(...args))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeSearch && _ctx.closeSearch(...args)))
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.searchTerm]
      ])
    ], 2)
  ]))
}