
import { defineComponent, computed, ref, onMounted } from "vue";

import TranslateMenu from "@/components/TranslateMenu.vue";

import { useTheme } from "@/composables/useTheme";
import { useStore } from "vuex";

export default defineComponent({
  name: "externalLayout",
  components: {
    TranslateMenu,
  },
  setup() {
    const store = useStore();
    const { updateTheme } = useTheme();

    const themeRef = ref<any>();
    const getTheme = async () => {
      await store.dispatch("setThemeUpdateAllowed", true);
      await updateTheme();
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const background = computed(() => {
      const style: Record<string, string> = {};
      if (themeRef.value) {
        if (themeRef.value.backgroundColour.app?.value) {
          style["background-color"] =
            themeRef.value.backgroundColour.app?.value;
        }
      }

      return style;
    });

    onMounted(() => {
      getTheme();
    });

    return {
      background,
    };
  },
});
