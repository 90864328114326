import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ec3c70a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "accordion__item"
        }, [
          _createElementVNode("div", {
            class: "accordion__item__title",
            onClick: ($event: any) => (_ctx.handleItemClick(index))
          }, [
            (!_ctx.$slots.title)
              ? (_openBlock(), _createBlock(_component_TranslatedText, {
                  key: 0,
                  path: item
                }, null, 8, ["path"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "title", {
              item: item,
              open: _ctx.isOpen(index)
            }, undefined, true)
          ], 8, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(["accordion__item__body", { open: _ctx.isOpen(index), opening: _ctx.isOpening }])
          }, [
            _renderSlot(_ctx.$slots, "body", { item: item }, undefined, true)
          ], 2)
        ]))
      }), 128))
    ])
  ]))
}