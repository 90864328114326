import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "multiselect__tag" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_VueMultiselect = _resolveComponent("VueMultiselect")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"margin-bottom":"1rem"},
    class: _normalizeClass(_ctx.customClass)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass({ active: _ctx.isActive })
        }, [
          _createVNode(_component_TranslatedText, { path: _ctx.label }, null, 8, ["path"])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_VueMultiselect, _mergeProps({
      ref: "multiSelect",
      placeholder: _ctx.getPlaceholder,
      modelValue: _ctx.valueArray,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueArray) = $event)),
      options: _ctx.items,
      multiple: true,
      disabled: _ctx.disabled,
      "close-on-select": true,
      label: _ctx.labelField,
      "track-by": _ctx.valueField,
      maxHeight: 200,
      searchable: _ctx.searchable
    }, _ctx.additionalAtrributes, {
      class: {
        error: !!_ctx.error,
      },
      onRemove: _ctx.handleRemove,
      onSelect: _ctx.handleChange,
      onSearchChange: _ctx.handleSearchChange
    }), {
      tag: _withCtx(({ option, remove }) => [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(option.group ? option.group + ": " : "") + _toDisplayString(option.name), 1),
          _createElementVNode("i", {
            tabindex: "1",
            class: "multiselect__tag-icon",
            onClick: ($event: any) => (remove(option))
          }, null, 8, _hoisted_3)
        ])
      ]),
      _: 1
    }, 16, ["placeholder", "modelValue", "options", "disabled", "label", "track-by", "searchable", "class", "onRemove", "onSelect", "onSearchChange"]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_TranslatedText, { path: _ctx.error }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}