
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  PropType,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Hotspot, Tick } from "@/models";
import Checklist from "../Checklist.vue";
import BaseHotspot from "@/components/hotspot/_BaseHotspot.vue";

export default defineComponent({
  name: "TickHotspot",
  components: {
    Checklist,
    BaseHotspot,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    hotspotProgress: {
      type: Number,
      default: 0,
    },
    showNext: Boolean,
    showPrevious: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const hotspot = computed(() => props.hotspot);
    const hotspotId = ref(hotspot.value.id);
    const ticks = ref<Array<Tick>>();
    const getTicksWithProgress = async () => {
      ticks.value = await Tick.getForHotspotAndUser(
        hotspot.value.id,
        store.getters.currentUser.id
      );
    };

    const orderedTicks = computed(() =>
      (ticks.value ?? []).sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      )
    );

    const checklistItems = computed(() => {
      return orderedTicks.value.map((tick) => {
        const title = tick.textItems.find((ti) => ti.purpose === "title");
        const progress = (tick.tickProgressObjects ?? [])[0];
        return {
          id: tick.id,
          name: title?.data ?? "",
          completed: progress && progress.completed,
        };
      });
    });

    const hotspotTitle = computed(() => {
      const ti = hotspot.value.textItems.find((t) => t.purpose === "title");
      return ti?.data ?? "";
    });

    const hotspotDescription = computed(() => {
      const ti = hotspot.value.textItems.find(
        (t) => t.purpose === "description"
      );
      return ti?.data ?? "";
    });

    const handleNextClick = () => {
      emit("next");
    };

    const handleBackClick = () => {
      router.push(`/track`);
    };

    const handlePreviousClick = () => {
      emit("previous");
    };

    onMounted(() => getTicksWithProgress());
    watch(hotspot, () => getTicksWithProgress());

    return {
      hotspotId,
      ticks,
      orderedTicks,
      checklistItems,
      hotspotTitle,
      hotspotDescription,
      handleNextClick,
      handlePreviousClick,
      handleBackClick,
    };
  },
});
