import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleSelect = _resolveComponent("TitleSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.isMounted)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 0,
            to: "#headerSlot"
          }, [
            _createVNode(_component_TitleSelect, {
              name: "track",
              class: "text-h5",
              items: _ctx.reports.map((r) => ({ value: r.id, text: r.name })),
              value: _ctx.currentReport,
              onChange: _ctx.handleReportChange
            }, null, 8, ["items", "value", "onChange"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName)))
  ], 64))
}