import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ad-Anchor" }
const _hoisted_2 = ["x1", "y1", "x2", "y2"]
const _hoisted_3 = ["x1", "y1", "x2", "y2"]
const _hoisted_4 = ["cx", "cy"]
const _hoisted_5 = ["cx", "cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", _hoisted_1, [
    _createElementVNode("line", {
      class: "ad-Anchor-line",
      x1: _ctx.p1x,
      y1: _ctx.p1y,
      x2: _ctx.x1,
      y2: _ctx.y1
    }, null, 8, _hoisted_2),
    _createElementVNode("line", {
      class: "ad-Anchor-line",
      x1: _ctx.p2x,
      y1: _ctx.p2y,
      x2: _ctx.x2,
      y2: _ctx.y2
    }, null, 8, _hoisted_3),
    _createElementVNode("circle", {
      class: "ad-Anchor-point",
      onMousedown: _cache[0] || (_cache[0] = (e) => _ctx.setDraggedCubic(_ctx.index, 0)),
      cx: _ctx.x1,
      cy: _ctx.y1,
      r: 6
    }, null, 40, _hoisted_4),
    _createElementVNode("circle", {
      class: "ad-Anchor-point",
      onMousedown: _cache[1] || (_cache[1] = (e) => _ctx.setDraggedCubic(_ctx.index, 1)),
      cx: _ctx.x2,
      cy: _ctx.y2,
      r: 6
    }, null, 40, _hoisted_5)
  ]))
}