
import { defineComponent } from "vue";
import Choices from "@/components/track/manager/track-components/Choices.vue";

export default defineComponent({
  components: {
    Choices,
  },
  name: "Control",
  emits: ["onChange"],
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    value: {
      type: [Number, String, Array, Boolean, Object],
    },
    type: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    choices: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const onChange = (e) => emit("onChange", e);

    return {
      onChange,
    };
  },
});
