import httpClient, { ApiResponse } from "./httpClient";

export const getAllTagCollections = async (): Promise<ApiResponse<any>> =>
  httpClient.get("/tags/collections");

export const createTag = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/tags/create-tag`, body);

export const updateTag = async (
  id: string,
  body: any
): Promise<ApiResponse<any>> => httpClient.put(`/tags/update-tag/${id}`, body);

export const createTagCollection = async (
  body: any
): Promise<ApiResponse<any>> =>
  httpClient.post(`/tags/create-collection`, body);

export const updateTagCollection = async (
  id: string,
  body: any
): Promise<ApiResponse<any>> =>
  httpClient.put(`/tags/update-collection/${id}`, body);

export const deleteTagCollection = async (
  id: string
): Promise<ApiResponse<any>> => httpClient.delete(`/tags/tag-collection/${id}`);

export const deleteTag = async (id: string): Promise<ApiResponse<any>> =>
  httpClient.delete(`/tags/tag/${id}`);
