
import { defineComponent, PropType } from "vue";
import { FormQuestion, TextItem } from "@/interfaces/domain";
import FormInput from "@/components/form/FormInput.vue";

export default defineComponent({
  components: {
    FormInput,
  },
  props: {
    formQuestion: {
      type: Object as PropType<FormQuestion>,
      required: true,
    },
    userResponse: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    errorsRef: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    readonly: Boolean,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["doChange"],
  setup(props, { emit }) {
    const maxLineLength = 35;

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };

    let changeTimer;
    const handleChange = (changeEvent) => {
      const { name, value, target } = changeEvent;
      clearTimeout(changeTimer);
      changeTimer = setTimeout(() => {
        handleDoChange({ name: name ?? target.name, value });
      }, 500);
    };
    const handleBlur = (changeEvent) => {
      clearTimeout(changeTimer);
      const { name, value, target } = changeEvent;
      handleDoChange({ name: name ?? target.name, value });
    };

    const handleDoChange = ({ name, value }) => {
      emit("doChange", { name, value });
    };

    return {
      maxLineLength,
      getTextItem,
      handleChange,
      handleBlur,
    };
  },
});
