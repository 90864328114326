import httpClient, { ApiResponse } from "./httpClient";

export const postDocumentContents = async (
  body: any
): Promise<ApiResponse<any>> => httpClient.post(`/documentcontents`, body);

export const postDocumentSignOffRequirements = async (
  body: any
): Promise<ApiResponse<any>> => httpClient.post(`/documentcontents/signoff`, body);

export const getDocumentContents = async (
  references: string[],
  currentRole: string,
  userId: string
): Promise<ApiResponse<any>> => httpClient.get(
  `documentcontents/contents/${JSON.stringify(
    references
  )}/${currentRole}/null/${userId}/null`
);

export const deleteDocuments = (
  documentIds: string[]
): Promise<any> => httpClient.delete(
  `documentcontents/bulkdelete/${JSON.stringify(documentIds)}`
);

export const getDocumentContentsByReference = (
  references: Array<string>,
  groupId = 'null',
  userId: string,
  currentRole = 'user',
  tagIds = 'null',
): Promise<ApiResponse<any>> => httpClient.get(
  `/documentcontents/contents/${JSON.stringify(references)}/${currentRole}/${groupId.length > 0 ? groupId : 'null'}/${userId.length > 0 ? userId : 'null'}/${tagIds.length > 0 ? tagIds : 'null'}`
);
