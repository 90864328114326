
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  reactive,
  PropType,
  toRef,
} from "vue";
import { useStore } from "vuex";
import { HttpError } from "@/api/httpClient";
import { Action, Document, ActionProgress } from "@/models";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";
import { format, parseISO } from "date-fns";
import Tick from "@/components/ui/Tick.vue";
import Signature from "@/components/Signature.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import { getFormFeatureConfigs } from "@/api/form-questions.api";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";
import { useBreakpoints, useNotification, useLanguage } from "@/composables";

export default defineComponent({
  components: {
    DocumentViewer,
    Button,
    Modal,
    Tick,
    Signature,
    ActionHeader,
    Declaration,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { isSmallScreen } = useBreakpoints();
    const { translateText } = useLanguage();

    const { inPageNotification } = useNotification();

    const currentUser = computed(() => store.getters.currentUser);
    const getDocumentDeclaration = computed(() => {
      const basicKey = "declaration.main.document";
      const translationBasic = translateText(basicKey);

      const key = `declaration.reference.${props.action.documentReference}`;
      const translation = translateText(key);

      return translation !== key
        ? translation
        : translationBasic !== basicKey
        ? translationBasic
        : "declaration.main";
    });
    const action = toRef(props, "action");

    const isValid = ref(false);

    const declarationWet = reactive<{ signature: string }>({ signature: "" });
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const tabButtonText = computed(() =>
      showSign.value ? "common.close-window" : "declaration.sign"
    );

    const showSign = ref(false);
    const handleSignClick = () => (showSign.value = !showSign.value);

    const handleSignSubmit = async () => {
      try {
        let response;
        if (!wetSignature.value) {
          response = await props.action.completeAction(
            currentUser.value.id,
            declaration.dob,
            declaration.consent
          );
        } else {
          response = await props.action.completeActionWet(
            currentUser.value.id,
            declarationWet.signature
          );
        }

        inPageNotification(
          "action.document-signed-title",
          "action.document-signed",
          "success"
        );
        emit("action-complete", { progressObj: response, manualNav: true });
        showSign.value = false;
        isValid.value = false;
        declarationWet.signature = "";
        declaration.consent = false;
        declaration.dob = "";
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("declaration.sign.error", httpError.message);
        }
      }
    };
    const signedDate = computed(() => {
      if (props.actionProgress && props.actionProgress.completionDate) {
        return format(
          parseISO(props.actionProgress.completionDate),
          "do MMMM yyyy"
        );
      }
      return null;
    });

    const showModal = computed(
      () => (isSmallScreen.value || wetSignature.value) && showSign.value
    );
    const handleModalHide = () => {
      declarationWet.signature = "";
      declaration.consent = false;

      declaration.dob = "";

      showSign.value = false;
    };

    const documentContent = ref<Document>();
    const getDocument = async () => {
      handleModalHide();
      const documents = await Document.getForReferences(
        [props.action.documentReference as string],
        currentUser.value?.tags
          ? currentUser.value?.tags.map((t) => t.id).join(",")
          : ""
      );
      documentContent.value = documents[0];
    };
    const handleDownloadClick = async () => {
      if (documentContent.value) {
        await documentContent.value.download();
      }
    };

    const handleDocumentLoaded = async () => {
      if (
        !props.viewOnly &&
        props.action.documentReference === documentContent.value?.reference
      ) {
        props.action.viewAction(currentUser.value.id);
        if (
          !documentContent.value?.signOffEnabled &&
          !props.actionProgress?.completed
        ) {
          const response = await props.action.completeAction(
            currentUser.value.id
          );

          emit("action-complete", { progressObj: response, manualNav: true });
        }
      }
    };

    const dobSignatureRequired = ref(false);
    const wetSignature = ref(true);

    const checkSignatureRequirements = async () => {
      if (!props.viewOnly) {
        const formFeatureConfigs = await getFormFeatureConfigs();
        formFeatureConfigs.data.payload.result.forEach((featureConfig) => {
          switch (featureConfig.feature) {
            case "signature-type":
              dobSignatureRequired.value =
                featureConfig.condition === "date-of-birth";
              wetSignature.value = featureConfig.condition === "wet";
              break;
          }
        });
      }
    };

    onMounted(() => {
      checkSignatureRequirements();
      getDocument();
    });
    watch(
      () => action.value,
      () => getDocument()
    );

    const buttonData = computed(() => {
      return {
        text: "common.download-file",
        size: "sm",
        class: "visible-lg",
        outline: true,
        disabled: !documentContent.value,
      };
    });

    return {
      getDocumentDeclaration,
      isValid,
      buttonData,
      documentContent,
      showSign,
      showModal,
      signedDate,
      declarationWet,
      declaration,
      dobSignatureRequired,
      wetSignature,
      tabButtonText,
      handleSignClick,
      handleSignSubmit,
      handleModalHide,
      handleDownloadClick,
      handleDocumentLoaded,
    };
  },
});
