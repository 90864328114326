import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ec2d448"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-list" }
const _hoisted_2 = { ref: "list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(`list-item-${index}`)
        }, [
          (item.url)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: _normalizeClass({ active: item.id === _ctx.activeItem || item.active }),
                to: item.url,
                onClick: ($event: any) => (_ctx.handleClick($event, item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TranslatedText, {
                    path: item.title
                  }, null, 8, ["path"])
                ]),
                _: 2
              }, 1032, ["class", "to", "onClick"]))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: _normalizeClass({ active: item.id === _ctx.activeItem || item.active }),
                onClick: ($event: any) => (_ctx.handleClick($event, item))
              }, [
                _createVNode(_component_TranslatedText, {
                  path: item.title
                }, null, 8, ["path"])
              ], 10, _hoisted_3))
        ], 2))
      }), 128))
    ], 512)
  ]))
}