import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", null, [
    _renderSlot(_ctx.$slots, "default", {
      handleInputChange: _ctx.handleInputChange,
      handleDateUpdate: _ctx.handleDateUpdate,
      isValid: _ctx.isValid
    }, undefined, true)
  ]))
}