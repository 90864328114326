
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import {
  getDocumentContentsByReference,
  postDocumentContents,
  postDocumentSignOffRequirements,
} from "@/api/document-contents.api";
import { Action, Document } from "@/models";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/form/Select.vue";

import { DocumentsForm as IDocumentsForm } from "@/interfaces/DocumentsForm.interface";

import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";
import { useNotification, useLanguage } from "@/composables";

export default defineComponent({
  components: {
    FileDisplay,
    FileInput,
    Modal,
    Button,
    Select,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
    selectedTags: {
      type: Array as PropType<any>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.InfoContent);
    const action = toRef(props, "action");
    const updateActive = toRef(props, "updateActive");

    const { translateText } = useLanguage();
    const { inPageNotification } = useNotification();

    const currentUser = store.getters.currentUser;

    const users = ref<any[]>([]);
    const selectedUser = toRef(props, "selectedUser");
    const selectedGroup = toRef(props, "selectedGroup");
    const selectedTags = toRef(props, "selectedTags");
    const preferredLanguageId = ref<string>("");

    const showDeleteModal = ref<boolean>(false);

    const model = ref<IDocumentsForm>({
      title: "",
      description: "",
      sideMenuText: "",
      signature: "",
      file: null,
      documentContents: null,
    });

    const v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      signature: "",
      file: "",
    });

    const getDocument = async () => {
      model.value.documentContents = null;
      model.value.signature = props.action.signatureRequired ? "Yes" : "No";
      preferredLanguageId.value = currentUser.preferredLanguageId;

      if (
        props.selectedUser ||
        props.selectedGroup ||
        props.selectedTags ||
        (props.action.level !== "user" &&
          props.action.level !== "group" &&
          props.action.level !== "tag")
      ) {
        let response;
        response = await getDocumentContentsByReference(
          [props.action.documentReference as string],
          props.action.level === "group" ? props.selectedGroup ?? "" : "",
          props.action.level === "user" ? props.selectedUser ?? "" : "",
          "admin",
          props.selectedTags.length
            ? props.selectedTags.map((st) => st.id).join(",")
            : ""
        );
        const documents: any[] = response.data.payload.results as any[];
        if (documents.length) {
          const document = documents[0];

          if (document.signOffEnabled) {
            model.value.signature = "Yes";
          } else {
            model.value.signature = "No";
          }
          emit("modifyModel", {
            name: "signature",
            value: model.value.signature,
          });
          model.value.documentContents = new Document(document);
        }
      }
    };

    const handleEditFile = (file: File) => (model.value.file = file);

    const resetForm = () => {
      model.value.title = "";
      model.value.description = "";
      model.value.signature = "";
      model.value.sideMenuText = "";
      model.value.file = null;
      model.value.documentContents = null;
    };

    const getPlaceholder = computed(() =>
      translateText("form.placeholder.select")
    );

    const signatureItems = ref([
      { text: "references.yes", value: "yes" },
      { text: "references.no", value: "no" },
    ]);

    const isValid = ref<boolean>(false);

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      emit("validUpdate", !v$.value.$error);
    };

    const handleSelectChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
      emit("modifyModel", { name: name, value: model.value[name] });
    };

    const handleAddFile = (file: File) => {
      model.value.file = file;
      validateField("file");
      emit("modifyModel", { name: "file", value: model.value.file });
    };

    const handleDownloadFile = async () => {
      if (model.value.documentContents) {
        try {
          model.value.documentContents.download();
        } catch (err) {
          inPageNotification("Error!", "Unable to download file", "error");
        }
      }
      return;
    };

    const getFileSize = (sizeInBytes: number) => {
      if (sizeInBytes < 1000) return `${sizeInBytes} bytes`;
      else if (sizeInBytes < 1000000)
        return `${Math.round(sizeInBytes / 1024)}Kb`;
      else return `${Math.round(sizeInBytes / 1024 / 1024)}Mb`;
    };

    const handleToggleDeleteModal = () => {
      showDeleteModal.value = !showDeleteModal.value;
    };

    const handleDeleteFile = async () => {
      model.value.file = null;
      if (model.value.documentContents) {
        try {
          await model.value.documentContents.delete();
          model.value.documentContents = null;
          inPageNotification("Success!", "File deleted", "success");
          showDeleteModal.value = false;
          await getDocument();
        } catch (err) {
          inPageNotification("Error!", "Unable to delete file", "error");
        }
      }
    };

    onMounted(async () => {
      await getDocument();
      emit("isLoaded", true);
    });

    watch(action, async () => {
      await getDocument();
    });

    watch(selectedUser, async () => {
      await getDocument();
    });

    watch(selectedGroup, async () => {
      await getDocument();
    });

    watch(selectedTags, async () => {
      await getDocument();
    });

    watch(updateActive, async () => {
      try {
        if (updateActive.value) {
          const files = {};

          if (model.value.file) {
            const file = model.value.file;

            files["file"] = file;
            const formData: FormData = new FormData();
            formData.append("file", files["file"], files["file"].name ?? "");
            formData.append(
              "reference",
              props.action.documentReference as string
            );
            if (props.selectedUser) {
              formData.append("userId", props.selectedUser);
            }
            if (props.selectedGroup) {
              formData.append("groupId", props.selectedGroup);
            }
            if (props.selectedTags) {
              formData.append(
                "tagIds",
                props.selectedTags.map((t) => t.id).join(",")
              );
            }
            formData.append("languageId", preferredLanguageId.value);
            if (model.value.signature === "yes") {
              formData.append("signOffRequired", model.value.signature);
            }
            await postDocumentContents(formData);
            resetForm();
            await getDocument();
          } else {
            const formData: any = {
              reference: props.action.documentReference,
              languageIds: [preferredLanguageId.value],
              userId:
                props.selectedUser && props.selectedUser.length > 0
                  ? props.selectedUser
                  : null,
              groupId:
                props.selectedGroup && props.selectedGroup.length > 0
                  ? props.selectedGroup
                  : null,
              tagIds:
                props.selectedTags && props.selectedTags.length > 0
                  ? props.selectedTags.map((t) => t.id).join(",")
                  : null,
            };
            formData["signOffEnabled"] = model.value.signature === "yes";
            await postDocumentSignOffRequirements(formData);
            resetForm();
            await getDocument();
          }

          emit("updated", true);
        }
      } catch (err) {
        console.log(err);
        emit("updated", false);
      }
    });

    return {
      isValid,
      schema,
      model,
      errors,
      translateText,
      showDeleteModal,
      getPlaceholder,
      signatureItems,
      handleSelectChange,
      handleAddFile,
      handleDownloadFile,
      getFileSize,
      handleToggleDeleteModal,
      handleDeleteFile,
      handleEditFile,
    };
  },
});
