
import { useLanguage } from "@/composables";
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
} from "@vue/runtime-core";
import Select from "@/components/form/Select.vue";
import { TextItem } from "@/interfaces/domain";

export default defineComponent({
  name: "Text",
  components: {
    Select,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["change", "enter", "blur", "click-append-icon", "edit"],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const handleInput = (event: any) => {
      if (event?.currentTarget) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { name, value } = event.currentTarget as any;
        emit("change", { name, value });
      } else if (event?.name) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { name, value } = event as any;
        emit("change", { name, value });
      }
    };

    const isFocussed = ref<boolean>(false);
    const optionChoices = ref<Array<any>>([]);

    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = (event: Event) => {
      isFocussed.value = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("blur", { name, value });
    };

    const clickAppendIcon = () => emit("click-append-icon");

    const enter = () => emit("enter");

    const placeholderText = computed(() =>
      getValueForString(props.options?.placeholder ?? "")
    );

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };

    const getSelectOptions = () =>
      optionChoices?.value
        ? optionChoices.value
            .sort((c1, c2) => c1.position - c2.position)
            .map((choice) => {
              return {
                value: choice.id,
                text: getTextItem(choice.textItems, "form-question-choice"),
              };
            })
        : [];

    watch(props, () => {
      if (props.options?.choices) {
        optionChoices.value = props.options?.choices;
      }
    });

    onMounted(async () => {
      if (props.options?.choices) {
        optionChoices.value = props.options?.choices;
      }
    });

    return {
      isFocussed,
      placeholderText,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      getSelectOptions,
      clickAppendIcon,
    };
  },
});
