import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-alt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabList = _resolveComponent("TabList")!
  const _component_NavList = _resolveComponent("NavList")!
  const _component_ReviewsAction = _resolveComponent("ReviewsAction")!
  const _component_BaseHotspot = _resolveComponent("BaseHotspot")!

  return (_openBlock(), _createBlock(_component_BaseHotspot, {
    hotspotProgress: _ctx.hotspotProgress,
    showNext: false,
    showPrevious: false,
    onNextClick: _ctx.handleNextClick,
    onPreviousClick: _ctx.handlePreviousClick,
    onBackClick: _ctx.handleBackClick
  }, {
    "mobile-nav": _withCtx(() => [
      _createVNode(_component_TabList, {
        id: "page-mobile-nav",
        items: _ctx.navItems,
        activeItem: _ctx.activeNavItem
      }, null, 8, ["items", "activeItem"])
    ]),
    nav: _withCtx(() => [
      _createVNode(_component_NavList, {
        items: _ctx.navItems,
        activeItem: _ctx.activeNavItem
      }, null, 8, ["items", "activeItem"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString(_ctx.hotspotTitle), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.hotspotDescription), 1),
      _createVNode(_component_ReviewsAction, {
        hotspot: _ctx.hotspot,
        currentReviewId: _ctx.currentReview?.id ?? '',
        onReviewComplete: _ctx.handleReviewComplete
      }, null, 8, ["hotspot", "currentReviewId", "onReviewComplete"])
    ]),
    _: 1
  }, 8, ["hotspotProgress", "onNextClick", "onPreviousClick", "onBackClick"]))
}