import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import permission from "./plugins/permission";
import icons from "./plugins/icons";
import loading from "./plugins/loading";
import modal from "./plugins/modal";
import schema from "./plugins/schema";
import auth from "./plugins/auth";
import VueTour from 'v3-tour'
import * as Sentry from "@sentry/vue";

import Page from "./components/Page.vue";
import TranslatedText from "./components/TranslatedText.vue";
import { useTheme } from "@/composables/useTheme";

store.dispatch('initTenant');

const { theme, updateTheme } = useTheme();
updateTheme();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(permission)
  .use(icons)
  .use(loading)
  .use(modal)
  .use(schema)
  .use(auth)
  .use(VueTour)
  .component("Page", Page)
  .component("TranslatedText", TranslatedText)
  .provide('theme', theme);

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      app,
      dsn: "https://435ce1820e4c6ad72604e18e7582b8f6@o4506293357117440.ingest.sentry.io/4506293358166016",
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/[a-zA-Z0-9\-.]*\.jolahr\.com/, /^\/api/],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    Sentry.setTag("Jola.User", store?.getters?.currentUser?.email ?? "unknown");
  }

app.mount("#app");
