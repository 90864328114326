import { Model } from "./Model";

export class ContactNumber extends Model<ContactNumber> {
  private static readonly path: string = "/user-contact-number";

  public userId: string;
  public countryCode: string;
  public number: string;
  public isPublic: boolean;
  public isPrimary: boolean;

  constructor({
    id = "",
    userId = "",
    countryCode = "",
    number = "",
    isPublic = false,
    isPrimary = false,
  }: Partial<ContactNumber> = {}) {
    super(id, ContactNumber, ContactNumber.path);
    this.userId = userId;
    this.countryCode = countryCode;
    this.number = number;
    this.isPublic = isPublic;
    this.isPrimary = isPrimary;
  }

  public static async get(userId: string): Promise<Array<ContactNumber>> {
    return await Model.getAllForUser<ContactNumber>(
      userId,
      ContactNumber.path,
      ContactNumber
    );
  }
}
