
import WidgetLayout from "../WidgetLayout.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { UserTableData as IUserTableData } from "@/interfaces/UserTableData.interface";
import TableList from "@/components/TableList.vue";
import TableCell from "@/components/TableCell.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "UserActivity",
  components: {
    WidgetLayout,
    TableList,
    TableCell,
  },
  props: {
    width: Number,
    height: Number,
    userData: {
      default: {} as any,
    },
    data: Object,
  },
  setup(props) {
    const router = useRouter();

    const tableData = ref<IUserTableData[]>([]);
    const currentPage = ref<number>(1);

    const usersData = computed({
      get: () => props.data,
      set: (value) => console.log(value),
    });

    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    const handleClickCell = (id: string, rowId: number) => {
      navigateToUser(id);
    };

    const navigateToUser = (userId: string) => {
      router.push(`/users/${userId}`);
    };

    const setAllUsers = (users) => {
      tableData.value = [];
      if (!users || !users.length) {
        return;
      }
      users.forEach((user, index: number) => {
        if (user?.email && user.email.includes("uber-admin@")) {
          return;
        }
        const id = user.id as string;
        const firstName = user.firstName;
        const surname = user.surname;
        const jobTitle = user.position;
        const startDate = user.startDate;
        const avatar = user?.imageContents ? user?.imageContents[0]?.url : "";

        const deactivated = false;

        const userRow = {
          rowId: index,
          id,
          deactivated: deactivated,
          cols: [
            {
              id: 0,
              colId: 1,
              dataType: "avatar",
              data: {
                image: avatar,
                alt: `Portrait photo of ${firstName} ${surname}`,
                avatarText: `${firstName[0]}${surname[0]}`,
                status: true,
              },
            },
            {
              id: 1,
              colId: 2,
              dataType: "user",
              data: {
                firstName: firstName,
                lastName: surname,
                jobTitle,
              },
            },
            {
              id: 2,
              colId: 3,
              dataType: "date",
              data: {
                value: startDate,
              },
            },
          ],
        };

        // Push each row to rows array on each iteration
        tableData.value.push(userRow);
      });
    };

    const handleClickRow = function (id: number) {
      return false;
    };

    onMounted(async () => {
      setAllUsers(usersData.value);
    });

    watch(props, () => {
      if (props.userData) {
        setAllUsers(usersData.value);
      }
    });

    return {
      tableData,
      usersData,
      currentPage,
      handleClickCell,
      handleClickRow,
      isOdd,
    };
  },
});
