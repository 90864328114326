
import { useLanguage } from "@/composables";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    path: {
      type: String,
      default: "",
    },
    replacements: {
      type: Array as PropType<Array<{ placeholder: string; value: string }>>,
      default: () => [],
    },
  },
  setup(props) {
    const { translateText } = useLanguage();
    const translatedText = computed(() =>
      translateText(props.path, props.replacements)
    );

    return {
      translatedText,
    };
  },
});
