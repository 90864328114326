
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { Address, Payroll } from "@/models";
import { Schemas, useSchema } from "@/plugins/schema";
import FormInput from "@/components/form/FormInput.vue";
import Form from "@/components/Form.vue";
import AddressList from "@/components/user/shared/AddressList.vue";

export default defineComponent({
  components: {
    FormInput,
    AddressList,
    Form,
  },
  props: {
    modelValue: {
      type: Object as PropType<Partial<Payroll>>,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
    },
    bankAddressDisplayed: {
      type: Boolean,
    },
    readonly: Boolean,
  },
  emits: ["update:payroll", "update:errors", "update:isValid"],
  setup(props, { emit }) {
    const schema = useSchema(Schemas.Payroll);
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:payroll", value),
    });
    const errorModel = computed({
      get: () => props.errors,
      set: (value) => emit("update:errors", value),
    });
    const valid = computed({
      get: () => props.isValid,
      set: (value) => emit("update:isValid", value),
    });

    const handleUpdateAddress = (address: Address) => {
      model.value.bankAddress = address;
      emit("update:payroll", model.value);
    };

    const handleRemoveAddress = () => {
      model.value.bankAddress = undefined;
      emit("update:payroll", model.value);
    };

    const bankAddress = ref<any>([]);

    const disabled = ref(false);

    watch(props, () => {
      bankAddress.value = model.value?.bankAddress
        ? [...bankAddress.value, { ...model.value.bankAddress }]
        : [];
      disabled.value = props.readonly;
    });

    return {
      disabled,
      schema,
      model,
      valid,
      errorModel,
      bankAddress,
      handleUpdateAddress,
      handleRemoveAddress,
    };
  },
});
