
import { defineComponent, computed, ref, PropType } from "vue";
import Checkbox from "@/components/form/Checkbox.vue";
import Button from "@/components/Button.vue";
import DropdownTableRow from "@/components/DropdownTableRow.vue";
import { Report } from "@/models/Report";
import { useLanguage } from "@/composables";

interface UserProgressReportRowData {
  name: string;
  completed: boolean;
  overdue?: boolean;
}

export interface UserProgressReportRow {
  id: string;
  firstName: string;
  surname: string;
  viewed?: boolean;
  progress?: number;
  status?: string;
}

export default defineComponent({
  name: "UserProgressRow",
  components: {
    Checkbox,
    DropdownTableRow,
    Button,
  },
  props: {
    data: {
      type: Object as PropType<UserProgressReportRow>,
      required: true,
    },
    hotspotId: String,
    selected: Boolean,
  },
  emits: ["select"],
  setup(props, { emit }) {
    const { translateText } = useLanguage();
    const isLoading = ref(true);

    const isOpen = ref(false);

    const rowData = ref<Array<UserProgressReportRowData>>();
    const getRowData = async () => {
      isLoading.value = true;
      rowData.value = await Report.getData<Array<UserProgressReportRowData>>(
        "user-progress",
        {
          hotspotId: props.hotspotId,
          userId: props.data.id,
        }
      );
      isLoading.value = false;
    };
    const noData = computed(() => props.data.status === null);

    const handleRowClick = async () => {
      if (noData.value) return;

      isOpen.value = !isOpen.value;
      if (isOpen.value && !rowData.value) {
        await getRowData();
      }
    };

    const handleSelectedChange = (checked: boolean) => {
      emit("select", { value: props.data.id, selected: checked });
    };

    const hasError = computed(() => props.data.status === "overdue");
    const getItemCheckboxProps = (item: UserProgressReportRowData) => {
      const value = item.completed ?? false;
      const icon = value ? "check" : "times";
      const theme = item.completed ? "green" : "red";

      return {
        value,
        icon,
        theme,
      };
    };

    return {
      isOpen,
      isLoading,
      rowData,
      noData,
      handleRowClick,
      handleSelectedChange,
      hasError,
      getItemCheckboxProps,
      translateText,
    };
  },
});
