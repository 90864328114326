<template>
  <div class="border">
    <p>Background {{ { colour: "Colour", image: "Image" }[type] }}</p>
    <div class="colour-container" v-if="type === 'colour'">
      <div class="colour-picker">
        <input
          type="color"
          :value="previewColour"
          @change="handleColourUpdate($event)"
        />
        <img class="editIcon" :src="require('@/assets/img/icon-edit.svg')" />
      </div>
    </div>
    <div class="image-container" v-else>
      <div class="preview" :style="previewStyle" @click="handleClick"></div>
      <img class="editIcon" :src="require('@/assets/img/icon-edit.svg')" />
    </div>
  </div>
</template>
<script>
// ** emits change event with hard-coded names for SetUpCustomise.vue **
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: "BackgroundSelector",
  props: {
    type: {
      type: String,
    },
    colour: {
      type: String,
    },
    backgroundImage: {
      type: String,
    },
    previewStyle: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const previewUrl = ref(props.backgroundImage);
    const previewColour = ref(props.colour);
    watch(props, () => {
      if (props.backgroundImage) {
        previewUrl.value = props.backgroundImage;
      }
      if (props.colour) {
        previewColour.value = props.colour;
      }
    });

    const handleColourUpdate = (e) => {
      emit("change", {
        name: "backgroundColour",
        value: e.target.value,
      });
    };

    const handleClick = () => {
      if (props.type !== "colour") {
        return selectImage();
      }
    };

    const selectImage = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.addEventListener("change", () => {
        emit("change", {
          name: "backgroundImage",
          value: input.files[0],
        });
        setImagePreview(input.files[0]);
      });
      input.click();
    };

    const setImagePreview = (source) => {
      if (source instanceof File) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          previewUrl.value = reader.result;
        });
        reader.readAsDataURL(source);
        return;
      }
      if (typeof source == "string") {
        previewUrl.value = source;
      }
    };

    return {
      previewColour,
      previewUrl,
      handleClick,
      handleColourUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.border {
  width: 100%;
  margin: 1em 0;
  border: 1px solid #ccc;
  border-radius: 0.33em;
  position: relative;
  padding: 20px 10px 10px 10px;
  p {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: -14px;
    background: white;
  }

  .preview {
    height: 522px;
    width: 100%;
    cursor: pointer;
    transition: 200ms background;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .colour-container {
    max-width: 200px;
    .colour-picker {
      padding-top: 100%;
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .editIcon {
    position: absolute;
    pointer-events: none;
    left: 50%;
    top: 50%;
    width: 1.5em;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    color: #fff;
    transition: 150ms all;
    mix-blend-mode: exclusion;
  }

  &:hover .editIcon {
    opacity: 1;
    width: 1.6em;
  }
}
</style>
