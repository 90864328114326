
import { defineComponent, PropType, toRef, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Action } from "@/models";

import { getMessageContents } from "@/api/message-contents.api";

export default defineComponent({
  components: {},
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: false,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const action = toRef(props, "action");
    const selectedUser = toRef(props, "selectedUser");
    const selectedGroup = toRef(props, "selectedGroup");
    const preferredLanguageId = ref<string>("");

    const messageReference = ref("");
    messageReference.value = action.value.messageReference ?? "";

    const currentUser = store.getters.currentUser;

    const actionLink = ref("");

    const getLink = async () => {
      try {
        preferredLanguageId.value = currentUser.preferredLanguageId;

        const response = await getMessageContents(
          [messageReference.value],
          props.action.level === "group" ? selectedGroup.value ?? "" : "",
          props.action.level === "user" ? selectedUser.value ?? "" : ""
        );
        const results = response.data.payload.results;
        if (results && results.length > 0) {
          actionLink.value = results.find(
            (res) => res.languageId === preferredLanguageId.value
          )?.data as string;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const autoCompleteAction = async () => {
      await getLink();
      window.open(actionLink.value, "_blank");
      emit("completed", true);
    };

    onMounted(async () => {
      await autoCompleteAction();
    });

    return {
      actionLink,
    };
  },
});
