import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b958596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-document" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfPageComponent = _resolveComponent("PdfPageComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createBlock(_component_PdfPageComponent, {
        key: page.pageNumber,
        page: page,
        scale: _ctx.scale,
        onRendered: _ctx.handleDocumentRendered
      }, null, 8, ["page", "scale", "onRendered"]))
    }), 128))
  ]))
}