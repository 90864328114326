
import {
  computed,
  defineComponent,
  PropType,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import { ValidationArgs } from "@vuelidate/core";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Form from "@/components/Form.vue";

export default defineComponent({
  components: {
    Button,
    Modal,
    Form,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<any>>,
      required: true,
    },
    schema: {
      type: Object as PropType<ValidationArgs>,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    newFunc: {
      type: Function,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
    },
    allowInline: {
      type: Boolean,
      default: false,
    },
    saveButtonTextUpdate: {
      type: Function,
      default: () => "Save",
    },
    saveButtonText: {
      type: String,
      default: "common.save",
    },
    canEditItem: {
      type: Function,
      default: () => true,
    },
    overrideDisableSaveButton: {
      type: Function,
      default: () => false,
    },
    allowDismiss: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["save", "delete", "update"],
  setup(props, { emit }) {
    const reachedLimit = computed(
      () => !!props.limit && props.items.length >= props.limit
    );
    const formItem = ref<{ id: string; isPrimary: boolean }>(props.newFunc());

    const isValid = ref(false);
    const errors = reactive<{ [K: string]: string }>({});

    const showModal = ref(false);
    const showDeleteButton = ref(false);
    const disableDeleteButton = ref(false);
    const disableSaveButton = computed(
      () => !isValid.value && !props.overrideDisableSaveButton(formItem.value)
    );
    const closeModal = () => {
      showDeleteButton.value = false;
      showModal.value = false;
    };

    const orderedItems = computed(() => {
      const items = [...props.items];
      return items.sort((a) => (a.isPrimary ? -1 : 0));
    });

    const handleEditClick = (
      item: { id: string; isPrimary: boolean },
      useModel = true
    ) => {
      const itemCopy = { ...item };
      formItem.value = itemCopy;
      props.saveButtonTextUpdate(itemCopy);
      showDeleteButton.value = true;
      disableDeleteButton.value = itemCopy.isPrimary;
      if (useModel) {
        showModal.value = true;
      }
    };
    const handleAddClick = () => {
      formItem.value = props.newFunc();
      showModal.value = true;
    };
    const handleModalConfirm = () => {
      emit("save", formItem.value);
      closeModal();
    };
    const handleModalDismiss = () => {
      emit("delete", formItem.value);
      closeModal();
    };
    const handleModalCancel = (forceClose) => {
      if (props.allowDismiss || forceClose) {
        closeModal();
      }
    };
    const formUpdated = () => emit("save", formItem.value);
    const hasData = ref(false);

    onMounted(async () => {
      if (props.items.length) {
        hasData.value = true
      }
      if (props.allowInline && props.limit && props.limit === 1) {
        handleEditClick(props.items[0]);
      }
    });

    watch(props, () => {
      if (
        props.allowInline &&
        props.limit && 
        props.limit === 1 && 
        props.items.length && 
        !hasData.value
      ) {
        handleEditClick(props.items[0]);
        hasData.value = true
      }
    });
    
    return {
      reachedLimit,
      isValid,
      errors,
      formItem,
      showModal,
      showDeleteButton,
      orderedItems,
      disableSaveButton,
      disableDeleteButton,
      handleEditClick,
      handleAddClick,
      handleModalConfirm,
      handleModalCancel,
      handleModalDismiss,
      formUpdated,
    };
  },
});
