
import { defineComponent, ref, computed, watch } from "vue";
import StatusIndicator from "@/components/ui/StatusIndicator.vue";
import { postImageContents } from "@/api/image-contents.api";
import { useNotification } from "@/composables/useNotification";

export type AvatarSize = "lg" | "md" | "sm";
const sizes = ["", "lg", "md", "sm"];
const variants = ["", "error"];

export default defineComponent({
  emits: ["image-updated"],
  components: {
    StatusIndicator,
  },
  props: {
    size: {
      type: String,
      default: () => "",
      validator: (value: string) => sizes.some((v) => v === value),
    },
    variant: {
      type: String,
      default: () => "",
      validator: (value: string) => variants.some((v) => v === value),
    },
    image: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      default: "",
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    imageData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const { inPageNotification } = useNotification();

    const profileImage = ref<any>(props.image ?? "");
    const avatarImage = computed(() => profileImage.value ?? props.image ?? "");
    const getAvatarImage = computed(
      () =>
        true && {
          "--backgroundImage": `url(${avatarImage.value})`,
        }
    );

    const selectProfileImage = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.addEventListener("change", async () => {
        if (input.files && props.imageData.userId) {
          const imageFiles = {};
          imageFiles[props.imageData.language] = input.files[0];
          const formData: FormData = new FormData();
          formData.append(
            "file",
            imageFiles[props.imageData.language],
            "" + imageFiles[props.imageData.language].name
          );
          formData.append("imageSize", props.imageData.size);
          formData.append("languageId", props.imageData.language);
          formData.append("reference", props.imageData.reference);
          formData.append("userId", props.imageData.userId);
          formData.append("blockDelete", props.imageData.blockDelete);
          const imageSave = await postImageContents(formData);
          if (imageSave.status == 201) {
            setImagePreview(input.files[0]);

            inPageNotification(
              "common.success",
              "models.user.profile-image-updated",
              "success"
            );
          }
        }
      });
      input.click();
    };

    const setImagePreview = (source) => {
      if (source instanceof File) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          profileImage.value = reader.result;
          emit("image-updated", profileImage.value);
        });
        reader.readAsDataURL(source);
        return;
      }
      if (typeof source == "string") {
        profileImage.value = source;
        emit("image-updated", source);
      }
    };

    watch(props, () => {
      profileImage.value = props.image ?? "";
    });

    return {
      avatarImage,
      getAvatarImage,
      selectProfileImage,
    };
  },
});
