/* eslint-disable @typescript-eslint/no-explicit-any */
import { readonly, computed, ref } from "vue";
import store from "@/store";
import { Theme } from "@/models/Style";

export const useTheme = (): any => {
  const style = ref();

  const references = readonly({
    AppBackground: "website-background",
    AppBackgroundColour: "background-colour-track",
    AppLogo: "website-logo",
    AppLogoSmall: "website-logo-small",
    SignInBackgroundColour: "background-colour",
    SignInBackground: "login-background",
    SignInLogo: "login-logo",
    TrackBackground: "track-svg-background",
  });

  const updateTheme = async () => {
    const themeUpdateAllowed = store.getters.isThemeUpdateAllowed;
    const tenant = store.getters.currentTenant;
    if (tenant) {
      if (themeUpdateAllowed) {
        await store.dispatch("calcTenant");
        await store.dispatch("setThemeUpdateAllowed", false);
        setTimeout(async () => {
          await store.dispatch("setThemeUpdateAllowed", true);
        }, 600000);
      }
      style.value = store.getters.currentTenant?.style;
      for (const [k, s] of Object.entries(style.value.values) as any) {
        if (k >= 0 && s.type === "style") {
          document.documentElement.style.setProperty(s.name, s.value);
        }
      }
    }
  };

  const getByReference = (reference: string) =>
    (style.value?.themeImages ?? []).find(
      (image) => image.reference === reference
    );
  const getByName = (name: string) =>
    (style.value?.background ?? []).find(
      (background) => background.name === name
    );

  const theme = computed<Theme>(() => {
    style.value = store.getters.currentTenant?.style;
    return {
      logo: {
        app: getByReference(references.AppLogo) ?? {
          url: require("@/assets/img/logo-jolahr-dark.svg"),
        },
        appSmall: getByReference(references.AppLogoSmall) ?? {
          url: require("@/assets/img/logo-jolahr-small.svg"),
        },
        signin: getByReference(references.SignInLogo) ?? {
          url: require("@/assets/img/logo-jolahr.svg"),
        },
      },
      backgroundImage: {
        track: getByReference(references.TrackBackground) ?? "",
        app: getByReference(references.AppBackground) ?? {
          url: require("@/assets/img/sign-in.png"),
        },
        signin: getByReference(references.SignInBackground) ?? {
          url: require("@/assets/img/sign-in.png"),
        },
      },
      backgroundColour: {
        app: getByName(references.AppBackgroundColour) ?? { value: "#fdfefe" },
        signin: getByName(references.SignInBackgroundColour) ?? {
          value: "#fdfefe",
        },
      },
      config: style.value?.config ?? [],
      styles: style.value?.values ?? [],
      css: {},
    };
  });

  return {
    theme,
    updateTheme,
  };
};
