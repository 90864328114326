
import { defineComponent, PropType, computed } from "vue";
import { usePermissionProvider } from "@/plugins/permission";
import { SettingsItem } from "@/interfaces/ui";

export default defineComponent({
  name: "SettingsMenu",
  props: {
    settingsItems: {
      type: Array as PropType<Array<SettingsItem>>,
      required: true,
    },
    closeDrawer: {
      type: Function as PropType<() => void>,
      required: false,
    },
  },
  setup(props) {
    const permissionProvider = usePermissionProvider();

    const getSettingsItems = computed(() =>
      props.settingsItems.filter(
        (p) => checkPermissions(p) && checkConditions(p)
      )
    );

    const checkPermissions = (item: SettingsItem) => {
      if (item.permissions) {
        const matched = item.permissions.filter((p: string) =>
          permissionProvider.userHasPermission(p)
        );
        return matched.length > 0;
      }

      return true;
    };

    const checkConditions = (item: SettingsItem) => {
      if (item.conditions) {
        return permissionProvider.checkUserConditions(item.conditions);
      }

      return true;
    };

    return {
      getSettingsItems,
    };
  },
});
