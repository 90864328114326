import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MaintenanceWindow from "@/views/MaintenanceWindow.vue";
import SignIn from "@/views/SignIn.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Home from "@/views/Home.vue";
import UserTrack from "@/views/UserTrack.vue";
import TrackManager from "@/views/TrackManager.vue";
import Users from "@/views/Users.vue";
import UsersList from "@/components/users/UsersList.vue";
import store from "@/store";
import Chat from "@/views/Chat.vue";
import Reporting from "@/views/Reporting.vue";
import Performance from "@/views/Performance.vue";
import Hotspot from "@/views/Hotspot.vue";
import SideMenu from "@/views/SideMenu.vue";
import Reviews from "@/components/reviews/AdminReviews.vue";
import UserMe from "@/views/UserMe.vue";
import UserEdit from "@/views/UserEdit.vue";
import Referee from "@/views/Referee.vue";
import UserCreate from "@/views/UserCreate.vue";
import ReportList from "@/components/reporting/ReportList.vue";
import Report from "@/components/reporting/Report.vue";
import NotFound from "@/views/NotFound.vue";
import AccountSettings from "@/views/AccountSettings.vue";
import Customise from "@/views/Customise.vue";
import DemoReset from "@/views/DemoReset.vue";

const routePath =
  window.location.hostname.indexOf("localhost") === 0 ? "/:tenant" : "";

const routes: Array<RouteRecordRaw> = [
  {
    path: `${routePath}/maintenance`,
    name: "MaintenanceWindow",
    component: MaintenanceWindow,
    meta: {
      title: "Maintenance",
    },
  },
  {
    path: `${routePath}/`,
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/signin`,
    name: "Sign in",
    component: SignIn,
    meta: {
      title: "Sign in",
    },
    alias: `${routePath}/sign-in`,
  },
  {
    path: `${routePath}/track-manager`,
    name: "TrackManager",
    component: TrackManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/signin/forgotten-password`,
    name: "Forgotten password",
    component: ForgotPassword,
    meta: {
      title: "Forgotten password",
    },
  },
  {
    path: `${routePath}/sign-in/reset-password/:userId/:tenantReset/:token`,
    name: "Reset password",
    component: ResetPassword,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: `${routePath}/track`,
    name: "UserTrack",
    component: UserTrack,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/track/hotspot/:hotspotId?/:actionId?`,
    name: "Hotspot",
    component: Hotspot,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/sidemenuitem/:itemId?`,
    name: "SideMenu",
    component: SideMenu,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: `${routePath}/me`,
    name: "UsersMe",
    component: Users,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ``,
        name: "My Details",
        component: UserMe,
      },
      {
        path: `:section?`,
        name: "My Details",
        component: UserMe,
      },
    ],
  },
  {
    path: `${routePath}/users`,
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ``,
        name: "List Users",
        component: UsersList,
      },
      {
        path: `create`,
        name: "Create User",
        component: UserCreate,
      },
      {
        path: `:id/:section?`,
        name: "UserView",
        component: UserEdit,
      },
    ],
  },
  {
    path: `${routePath}/chat/:id?`,
    name: "Chat",
    component: Chat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/reporting`,
    name: "Reporting",
    component: Reporting,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ``,
        name: "Reports",
        component: ReportList,
      },
      {
        path: `:id`,
        name: "Report",
        component: Report,
      },
    ],
  },
  {
    path: `${routePath}/performance`,
    name: "Performance",
    component: Performance,
    meta: {
      requiresAuth: true,
      featureCheck: "module-reviews",
    },
  },
  {
    path: `${routePath}/user/:id/:section?`,
    redirect: { name: "UserView" },
  },
  {
    path: `${routePath}/reviews/:id/:section?`,
    name: "Reviews",
    component: Reviews,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${routePath}/reference/:referenceId/:referenceRecordId/:authId`,
    name: "Referee",
    component: Referee,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: `${routePath}/404`,
    name: "404",
    component: NotFound,
  },
  {
    path: `${routePath}/account/:section`,
    name: "Account Settings",
    component: AccountSettings,
  },
  {
    path: `${routePath}/customise`,
    name: "Customise",
    component: Customise,
    children: [
      {
        path: `${routePath}/customise/:section`,
        name: "CustomiseBranding",
        component: Customise,
      },
    ],
  },
  {
    path: `${routePath}/reset-demo`,
    name: "Reset Demo",
    component: DemoReset,
    meta: {
      title: "Reset Demo",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const {
    userIsLoggedIn,
    useSubdomain,
    isUrlTenant,
    currentTenant,
    changePassword,
    setupCompleted,
    featureConfigs,
    isMaintenanceMode,
  } = store.getters;
  if (!currentTenant) {
    await store.dispatch("resetTenant");
    await store.dispatch("calcTenant");
    window.location.href = "" + window.location.href;
  }

  if (isMaintenanceMode && !to.path.endsWith("/maintenance")) {
    return next({
      path: `${
        !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
      }/maintenance`,
    });
  }

  const hasFeatureCheck = to.matched.find((route) => route.meta.featureCheck);
  if (hasFeatureCheck) {
    const featureConfig = featureConfigs.find(
      (fc) => fc.feature === hasFeatureCheck.meta.featureCheck
    );

    if (featureConfig && !featureConfig.config) {
      return next({
        path: `${
          !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
        }/`,
      });
    }
  }

  // Specific Checks when a user is logged in
  if (userIsLoggedIn) {
    // Redirect signin page to the root
    if (to.path.endsWith("/signin")) {
      return next({
        path: `${
          !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
        }/`,
      });
    }

    // User is required to change their password
    if (changePassword) {
      //Force the user to the change password screen if they aren't already there
      const passwordUrl = `${
        !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
      }/me/password`;
      if (!to.path.endsWith(passwordUrl)) {
        return next({
          path: passwordUrl,
        });
      }
      // Check the user has completed the platform setup
    } else if (!setupCompleted) {
      //Force the user to the customisation screen if they aren't already there
      if (!to.path.endsWith("/customise")) {
        return next({
          path: `${
            !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
          }/customise`,
        });
      }
    }
  } else {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      if (!to.path.endsWith("/signin")) {
        const returnUrl = encodeURIComponent(to.fullPath);
        setCookie("ReturnUrl", returnUrl, 10);
      }
      return next({
        path: `${
          !useSubdomain && !isUrlTenant ? "/" + currentTenant.name : ""
        }/signin`,
      });
    }
  }

  const matchedRouteName = to.matched.find((m) => m.name)?.name;
  const matchedTenantRouteName: string = router
    .resolve({ path: `/tenant${to.fullPath}` })
    .name?.toString() as string;

  if (!useSubdomain && !isUrlTenant) {
    if (
      currentTenant &&
      currentTenant.name &&
      matchedTenantRouteName &&
      ["Not found"].indexOf(matchedTenantRouteName as string) < 0 &&
      matchedRouteName &&
      ["Not found", "Home"].indexOf(matchedRouteName as string) >= 0
    ) {
      // We've found a tenant url route
      return next({
        path: `/${currentTenant.name}${to.fullPath}`,
      });
    }

    if (
      currentTenant &&
      currentTenant.name &&
      to.params.tenant &&
      currentTenant.name != to.params.tenant
    ) {
      // User is trying to change tenant...
      if (userIsLoggedIn) {
        // If they are logged in, don't allow it
        return next({
          path: `/${currentTenant.name}`,
        });
      } else {
        await store.dispatch("resetTenant");
        await store.dispatch("calcTenant");
        location.reload();
      }
    }
  }

  let title = "Jola HR";
  if (to.matched.some((route) => route.meta.title)) {
    title += ` | ${to.meta.title}`;
  }
  document.title = title;

  next();
});

export const setCookie = (
  key: string,
  value: string,
  expiresMins: number
): void => {
  const now = new Date();
  now.setTime(now.getTime() + expiresMins * 60 * 1000);
  const expires = now.toUTCString();
  document.cookie = `${key}=${value};expires=${expires};path=/`;
};

export const getCookie = (key: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};

export default router;
