
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { usePermissionProvider } from "@/plugins/permission";
import { Review } from "@/models";
import Avatar from "@/components/Avatar.vue";
import BackLink from "@/components/BackLink.vue";
import ReviewForm from "@/components/reviews/ReviewForm.vue";
import { UserNavItem } from "@/interfaces/UserNavItem.interface";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import { useLanguage } from "@/composables";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

export default defineComponent({
  name: "UserView",
  components: {
    AuthenticatedLayout,
    Avatar,
    BackLink,
    ReviewForm,
    Button,
    Checkbox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userIsLoading: Boolean,
    navItemConfig: {
      type: Array as PropType<UserNavItem[]>,
      default: () => [],
    },
    isNewUser: Boolean,
    reviews: {
      type: Array as PropType<Review[]>,
      required: true,
    },
    answers: {
      type: Array as PropType<any[]>,
      required: true,
    },
    updatedReviews: {
      type: Array as PropType<string[]>,
      required: true,
    },
    probationEnabled: {
      type: Boolean,
      default: false,
    },
    firstProbationReview: {
      type: Number,
      default: 1,
    },
    probationExtensionsEnabled: {
      type: Boolean,
      default: false,
    },
    passedProbation: {
      type: Boolean,
      default: false,
    },
    completedReviewCount: {
      type: Number,
      default: 0,
    },
    reviewId: {
      type: String,
      required: false,
    },
    savedReviews: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: [
    "update",
    "save",
    "cancel",
    "probationChange",
    "addExtensionReview",
    "addActionObjective",
  ],
  setup(props, { emit }) {
    const router = useRouter();
    const permissionProvider = usePermissionProvider();

    const handleUpdate = (event: any) => {
      emit("update", event);
    };
    const save = () => {
      emit("save", currentReview.value);
    };

    const cancel = () => {
      emit("cancel", currentReview.value);
    };

    const probationChange = () => {
      emit("probationChange", currentReview.value);
    };

    const addExtensionReview = () => {
      emit("addExtensionReview");
    };

    const addActionObjective = () => {
      emit("addActionObjective", currentReview.value);
    };

    const completedReviewsCount = computed(() =>
      props.reviews.reduce(
        (total, currReview) => (total += currReview.completedManager ? 1 : 0),
        0
      )
    );

    const disableSaveButton = computed(
      () =>
        !currentReview.value ||
        currentReview.value.completedManager ||
        !props.updatedReviews.includes(currentReview.value.id)
    );
    const fullName = computed(() =>
      props.user ? `${props.user.firstName} ${props.user.surname}` : ""
    );
    const pageTitle = computed(() =>
      !fullName.value.trim().length && props.isNewUser
        ? "New User"
        : fullName.value
    );

    const currentReview = computed(() =>
      props.reviews.find((review) =>
        props.reviewId
          ? review.id === props.reviewId
          : review.id === currentNavItem.value?.url
      )
    );

    const getAnswer = (review, question) => {
      const answer = props.answers.find(
        (answer) => answer.questionId === question.id
      );
      return answer?.content ?? "";
    };

    const isActive = computed(() => !props.user.isDeactivated);
    const avatarFallbackText = computed(() => {
      const firstName = props.user.firstName[0] ?? "";
      const surname = props.user.surname[0] ?? "";
      return `${firstName}${surname}`;
    });
    const avatarVariant = computed(() =>
      props.user.isLockedOut ? "danger" : ""
    );
    const avatarIcon = computed(() =>
      props.user && props.user.isLockedOut ? "lock" : ""
    );

    const navItems = computed(() =>
      props.navItemConfig.filter((item) =>
        permissionProvider.userHasPermission(item.permission)
      )
    );
    const currentNavItem = computed(() => {
      const currentRoute = router.currentRoute.value;
      const routeVal = (
        currentRoute.params && currentRoute.params.section
          ? currentRoute.params.section
          : currentRoute.query["tab"]
      ) as string;

      props.reviews
        .find((review) => review.id === routeVal)
        ?.reviewTemplate.questions.sort(
          (q1, q2) => q1.questionNumber - q2.questionNumber
        );

      return (
        navItems.value.find((item) => item?.url === routeVal) ||
        navItems.value[0]
      );
    });

    const { translateText } = useLanguage();

    const disabled = computed(
      () =>
        currentReview.value &&
        (props.savedReviews.includes(currentReview.value.id) ||
          currentReview.value?.completedManager)
    );

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
    });

    return {
      isMounted,
      fullName,
      isActive,
      avatarFallbackText,
      avatarVariant,
      avatarIcon,
      navItems,
      currentNavItem,
      pageTitle,
      currentReview,
      handleUpdate,
      getAnswer,
      save,
      cancel,
      disableSaveButton,
      probationChange,
      completedReviewsCount,
      addExtensionReview,
      addActionObjective,
      translateText,
      disabled,
    };
  },
});
