
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  Ref,
  toRefs,
} from "vue";
import SignaturePad from "vue3-signature-pad";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Signature",
  components: {
    SignaturePad,
    Button,
  },
  props: {
    modelValue: {
      type: Object as PropType<{ signature: string }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const state = reactive({
      customStyle: { background: "#efefef", width: "100%" },
      saveType: "image/svg+xml",
      saveOutput: "data_url",
    });

    const signatureDataURL: Ref<string | null> = ref(null);
    const signaturePad: Ref<any> = ref(null);

    function getSignaturePad(): any {
      if (!signaturePad.value) {
        throw new Error("No signature pad ref could be found");
      }
      return signaturePad.value;
    }

    function clearSignature(): void {
      getSignaturePad().clearSignature();
    }

    function onInput(value: string) {
      if (!value) {
        signatureDataURL.value = null;
        model.value.signature = "";
      } else {
        signatureDataURL.value = value;
        model.value.signature = value;
      }
    }
    const handleConfirm = async () => emit("confirm");
    const handleCancel = () => emit("cancel");

    return {
      ...toRefs(state),
      signaturePad,
      signatureDataURL,
      clearSignature,
      onInput,
      model,
      handleConfirm,
      handleCancel,
    };
  },
});
