
import {
  onMounted,
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from "vue";
import { useModal } from "@/plugins/modal";
import { User, Payroll } from "@/models";
import PayrollForm from "@/components/user/payroll/PayrollForm.vue";
import Button from "@/components/Button.vue";
import { useLoading } from "@/plugins/loading";
import { useStore } from "vuex";
import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    PayrollForm,
    Button,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isAuthenticatedUser: Boolean,
  },
  setup(props) {
    const showModal = useModal();
    const showLoading = useLoading();
    const isLoading = ref(true);
    const store = useStore();
    const schema = useSchema(Schemas.Payroll);
    const addressSchema = useSchema(Schemas.Address);

    const { inPageNotification } = useNotification();

    const errors = ref<any>({});
    const isDirty = ref(false);
    const isValid = ref(false);
    const payroll = ref<Payroll>(new Payroll({ userId: props.user?.id }));
    const bankAddressDisplayed = !store.getters.featureConfigs.find(
      (fc) => fc.feature === "new-starter-hide-bank-address"
    );

    const handleUpdate = async () => {
      if (payroll.value.bankAddress) {
        let vAddress$ = useValidate(addressSchema, payroll.value.bankAddress);
        let v$ = useValidate(schema, payroll.value);
        isValid.value =
          (await v$.value.$validate()) &&
          (!bankAddressDisplayed || (await vAddress$.value.$validate()));
      } else {
        isValid.value = false;
      }
    };

    const getPayrollDetails = async (bypassCache = false) => {
      const dbPayroll = await props.user?.getPayroll(bypassCache);
      if (dbPayroll) {
        payroll.value = dbPayroll;
      }
      isLoading.value = false;
    };
    const handleSavePayroll = async () => {
      if (!isValid.value) {
        const errorsObject = {};
        if (errors.value.length) {
          errors.value.forEach(function (e) {
            errorsObject[e?.$property] = e?.$message;
          });
        }
        errors.value = errorsObject;
        return false;
      }

      if (payroll.value) {
        isDirty.value = false;
        payroll.value.userId = props.user?.id ?? "";
        showLoading(true);
        await payroll.value.save();
        inPageNotification(
          `${props.user?.firstName} ${props.user?.surname}`,
          "models.user.updated",
          "success"
        );
        await getPayrollDetails();
        showLoading(false);
      }
    };

    const handleDiscardChanges = async () => {
      await showModal({
        title: "prompts.discard-changes.title",
        body: "prompts.discard-changes.message",
        onConfirm: async () => {
          isDirty.value = false;
          showLoading(true);
          await getPayrollDetails(true);
          showLoading(false);
        },
      });
    };

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
      await getPayrollDetails(true);

      watch(
        payroll,
        () => {
          isDirty.value = true;
          if (payroll.value.bankAddress) isValid.value = true;
        },
        { deep: true }
      );
    });
    watch(props, () => getPayrollDetails());

    return {
      isMounted,
      payroll,
      errors,
      isValid,
      isDirty,
      isLoading,
      bankAddressDisplayed,
      handleSavePayroll,
      handleDiscardChanges,
      handleUpdate,
    };
  },
});
