import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _component_Foot = _resolveComponent("Foot")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ad-Container", _ctx.customClass]),
    onMouseup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancelDragging && _ctx.cancelDragging(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass('ad-Container-main ' + (_ctx.edit ? '' : 'full-size')),
      tabindex: "0",
      onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDown && _ctx.handleKeyDown(...args))),
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleKeyUp && _ctx.handleKeyUp(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["ad-Container-svg", { edit: _ctx.edit }])
      }, [
        _createVNode(_component_SVG, {
          id: "svg",
          path: _ctx.generatePath(),
          points: _ctx.pointsByRatio,
          grid: _ctx.grid,
          w: _ctx.width,
          h: _ctx.height,
          scale: _ctx.scale,
          size: _ctx.size,
          activePoint: _ctx.activePoint,
          showGrid: _ctx.edit,
          edit: _ctx.edit,
          hotspots: _ctx.hotspots,
          viewBox: _ctx.viewBox,
          onAddPoint: _ctx.addPoint,
          onDoubleClick: _ctx.handleDoubleClick,
          onSetDraggedPoint: _ctx.setDraggedPoint,
          onSetDraggedCubic: _ctx.setDraggedCubic,
          onHandleMouseMove: _ctx.handleMouseMove
        }, null, 8, ["path", "points", "grid", "w", "h", "scale", "size", "activePoint", "showGrid", "edit", "hotspots", "viewBox", "onAddPoint", "onDoubleClick", "onSetDraggedPoint", "onSetDraggedCubic", "onHandleMouseMove"])
      ], 2)
    ], 34),
    (_ctx.edit)
      ? (_openBlock(), _createBlock(_component_Foot, {
          key: 0,
          points: _ctx.pointsByRatio,
          size: _ctx.size,
          activePoint: _ctx.activePoint,
          curvedLine: _ctx.curvedLine,
          trackIsDirty: _ctx.trackIsDirty,
          onSetPointType: _ctx.setPointType,
          onSave: _ctx.handleSaveTrack
        }, null, 8, ["points", "size", "activePoint", "curvedLine", "trackIsDirty", "onSetPointType", "onSave"]))
      : _createCommentVNode("", true)
  ], 34))
}