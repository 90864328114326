import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e81a49a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid contacts-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionHeader = _resolveComponent("ActionHeader")!
  const _component_ContactCard = _resolveComponent("ContactCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, {
      hotspotDescription: _ctx.hotspotDescription,
      actionTitle: _ctx.actionTitle,
      actionDescription: _ctx.actionDescription
    }, null, 8, ["hotspotDescription", "actionTitle", "actionDescription"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedData(_ctx.contactGroups), (group, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
        (group.contacts.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ActionHeader, {
                actionTitle: 
            group.name !== false
              ? group.name
              : _ctx.translateText(group.translationKeyName)
          ,
                actionDescription: 
            group.description !== false
              ? group.description
              : _ctx.translateText(group.translationKeyDescription)
          ,
                class: "col col-12"
              }, null, 8, ["actionTitle", "actionDescription"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedData(group.contacts), (contact, cidx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col sm:col-6 md:col-4 lg:col-6 xl:col-4",
                  key: cidx
                }, [
                  _createVNode(_component_ContactCard, { contact: contact }, null, 8, ["contact"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}