
import { defineComponent } from "vue";

const variants = ["", "secondary"];

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: () => "",
      validator: (value: string) => variants.some((val) => val === value),
    },
  },
});
