import httpClient, { ApiResponse } from "./httpClient";
import { FormQuestion } from "@/interfaces/domain";

export const getFormQuestionsByAction = async (
  actionId: string,
  userId: string
): Promise<ApiResponse<FormQuestion>> =>
  httpClient.get(`/formquestion/all/${actionId}/${userId}`);

export const getFormQuestionsByActionOnly = async (
  actionId: string
): Promise<ApiResponse<FormQuestion>> =>
  httpClient.get(`/formquestion/all/${actionId}`);

export const getFormQuestionsByForm = async (
  userId: string,
  formReference: string
): Promise<ApiResponse<FormQuestion>> =>
  httpClient.get(`/formquestion/form-all/${userId}/${formReference}`);

export const getFormQuestionsUserResponsesByActionAndUser = async (
  actionId: string,
  userId: string
): Promise<ApiResponse<FormQuestion>> =>
  httpClient.get(`/formquestion/userresponses/${actionId}/${userId}`);

export const getFormFeatureConfigs = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/formquestion/feature-configs`);

export const signForm = async (
  actionId: string,
  userResponses: {
    formQuestionId: string;
    responseValue: string;
    userId: string;
  }[],
  signOff: any
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/formquestion/userresponse`, {
    actionId,
    userResponses,
    signOff,
  });
};

export const submitUserForm = async (
  userResponses: {
    formQuestionId: string;
    responseValue: string;
    userId: string;
  }[],
  userId = "",
  isMe = false
): Promise<ApiResponse<void>> => {
  if (!isMe) {
    return await httpClient.post(`/users/userform/${userId}`, {
      userResponses,
    });
  } else {
    return await httpClient.post(`/users/my-userform`, {
      userResponses,
    });
  }
};

export const uploadFormFile = async (body: any): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/userdocument`, body);
};

export const createFormQuestion = async (
  body: any
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/formquestion`, body);
};

export const updateFormQuestion = async (
  id: string,
  body: any
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/formquestion/${id}`, body);
};

export const reorderFormQuestion = async (
  id: string,
  body: { oldPosition: number; newPosition: number }
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/formquestion/reorder-v2/${id}`, body);
};

export const resizeFormQuestion = async (
  id: string,
  colSize: number
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/formquestion/resize/${id}`, { colSize });
};

export const deleteFormQuestion = async (
  id: string
): Promise<ApiResponse<void>> => {
  return await httpClient.delete(`/formquestion/destroy/${id}`);
};
