<template>
  <div class="chart outer" :style="outerColour && { '--bg': outerColour }">
    <svg width="100%" height="100%">
      <circle
        v-for="(item, idx) in valuesOuter"
        :key="idx"
        r="50%"
        cx="50%"
        cy="50%"
        class="pie"
        :style="getSliceStyle(idx, false)"
        :stroke="item.colour"
        stroke-width="50%"
        fill="#0000"
      />
    </svg>
    <div class="chart inner" :style="innerColour && { '--bg': innerColour }">
      <svg width="100%" height="100%">
        <circle
          v-for="(item, idx) in valuesInner"
          :key="idx"
          r="50%"
          cx="50%"
          cy="50%"
          class="pie"
          :style="getSliceStyle(idx, true)"
          :stroke="item.colour"
          :stroke-width="innerWidth"
          fill="#0000"
        />
      </svg>
      <div class="core" :style="coreStyle">
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Chart",
  props: [
    "className",
    "valuesOuter",
    "valuesInner",
    "outerColour",
    "innerColour",
    "coreColour",
    "innerThickness",
  ],
  setup(props) {
    const innerWidth = computed(() =>
      props.innerThickness === undefined
        ? "50%"
        : props.innerThickness * 10 + "%"
    );

    const coreStyle = computed(() => {
      const style = {
        "--innerThickness": "2",
      };
      if (props.innerThickness !== undefined) {
        style["--innerThickness"] = props.innerThickness;
      }
      return style;
    });

    const getSliceStyle = (idx, useInnerValues) => {
      // we need idx rather than the item to calculate start angle
      let startAngle = 0;
      for (let i = 0; i < idx; i++) {
        startAngle += useInnerValues
          ? props.valuesInner[i].size
          : props.valuesOuter[i].size;
      }
      const item = useInnerValues
        ? props.valuesInner[idx]
        : props.valuesOuter[idx];
      const endAngle = item.size;
      return {
        transformOrigin: "center center",
        opacity: `${props.valuesInner[idx].opacity ?? 1}`,
        transform: `rotate(${startAngle - 0.25}turn)`,
        strokeDasharray: `${endAngle * Math.PI * 100}% ${Math.PI * 100}%`,
      };
    };

    return { innerWidth, coreStyle, getSliceStyle };
  },
});
</script>

<style scoped lang="scss">
.chart {
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
  transition: 400ms all ease-in-out, 0s margin-left;
  &.off-left,
  &.off-right {
    transition: 400ms all ease-in-out;
  }
}
.chart.outer {
  width: var(--size);
  height: var(--size);
}
.chart.inner {
  inset: 9%;
}
.core {
  position: absolute;
  font-family: Poppins;
  border-radius: 100%;
  text-align: center;
  inset: calc(5% * var(--innerThickness));
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 76%;
    transform: translate(-50%, -50%);
  }
  h1 {
    font-family: inherit;
    font-size: 90px;
    font-weight: 600;
    .percent {
      font-size: 50px;
    }
  }
  p {
    line-height: 24px;
    font-size: 16px;
    &.font-weight-bold {
      font-weight: 600 !important;
    }
  }
  h3 {
    text-decoration: underline;
    font-style: italic;
    font-size: 18px;
    line-height: 1.5rem;
  }
}
</style>
