import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0006e47a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selection" }
const _hoisted_2 = { class: "rows-selected" }
const _hoisted_3 = {
  key: 0,
  class: "actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-selection", { hidden: !_ctx.selectedRows.length }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Checkbox, {
        selectAll: true,
        active: _ctx.selectAllActive,
        onToggleAll: _ctx.toggleAll
      }, null, 8, ["active", "onToggleAll"]),
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.selectedRows.length - _ctx.deselectedRows.length) + " ", 1),
        _createVNode(_component_TranslatedText, {
          path: `${_ctx.tableName}.selected`
        }, null, 8, ["path"])
      ])
    ]),
    (_ctx.actions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Chip, {
            chip: _ctx.actions,
            size: _ctx.buttonSize,
            customClass: _ctx.centerChipText + ' chip-full-width',
            onToggleChip: _ctx.toggleActionsChip,
            onClickMenuItem: _ctx.clickMenuItem
          }, null, 8, ["chip", "size", "customClass", "onToggleChip", "onClickMenuItem"]),
          _createVNode(_component_Button, {
            size: _ctx.buttonSize,
            text: "Clear all",
            onClick: _ctx.clearSelection
          }, null, 8, ["size", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}