
import {
  defineComponent,
  onMounted,
  ref,
  PropType,
  computed,
  watch,
  onUnmounted,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { MenuItem } from "@/interfaces/ui";
import Avatar from "@/components/Avatar.vue";
import { User } from "@/models";
import { getMe } from "@/api/auth.api";

export default defineComponent({
  name: "Header",
  components: {
    Avatar,
  },
  props: {
    navOpen: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array as PropType<Array<MenuItem>>,
      required: true,
    },
    handleClickToggleNav: {
      type: Function as PropType<(e: Event) => void>,
      required: true,
    },
    handleClickToggleSettings: {
      type: Function as PropType<(e: Event) => void>,
      required: true,
    },
    hasSettings: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const menuOpen = ref<boolean>(false);
    const handleClickToggleMenu = (e: Event) => {
      e.preventDefault();
      menuOpen.value = !menuOpen.value;
    };

    const handleHoverToggleMenu = (e: Event) => {
      menuOpen.value = e.type === "mouseover";
    };

    const handleClickMenuItem = (
      e: Event,
      index: number,
      menuItem: MenuItem
    ) => {
      e.preventDefault();
      const { url, action } = menuItem;
      if (action) {
        store.dispatch(action);
      }

      if (url) {
        emit("clickMenuItem", { index, url });
      }
    };

    const logout = async () => {
      await store.dispatch("logout");
    };
    const loggedInUser = computed(() => store.state.user.currentUser);

    const user = ref<User>(new User());
    const userIsLoading = ref<boolean>(true);
    const userNotFound = ref<boolean>(false);

    const getUser = async (bypassCache = false) => {
      try {
        userIsLoading.value = true;
        if (!user.value || bypassCache) {
          if (store.getters.currentUser) {
            Object.keys(store.getters.currentUser).forEach((key, index) => {
              user.value[key] = store.getters.currentUser[key];
            });
          }
        }
      } catch (err) {
        //TODO: Handle this error better HBL-1886
        console.log(err);
        userNotFound.value = false;
        router.push("/");
      } finally {
        userIsLoading.value = false;
      }
      /* */
    };

    onMounted(async () => {
      await getUser(true);
      updateUserDetails();
    });

    const routerHook = router.afterEach(async () => {
      updateUserDetails();
    });

    const updateUserDetails = async () => {
      const avatarUpdateAllowed = store.getters.isAvatarUpdateAllowed;
      if (avatarUpdateAllowed) {
        const meResponse = await getMe();
        if (meResponse.status === 200) {
          await store.dispatch(
            "setUserDetails",
            meResponse.data.payload.result
          );
        }
        await store.dispatch("setAvatarUpdateAllowed", false);
        setTimeout(async () => {
          await store.dispatch("setAvatarUpdateAllowed", true);
        }, 600000);
        user.value.profileImage = store.getters.currentUser?.profileImage ?? "";
        user.value.firstName = store.getters.currentUser?.firstName ?? "";
        user.value.surname = store.getters.currentUser?.surname ?? "";
      }
    };

    watch(loggedInUser, () => {
      user.value.profileImage = store.getters.currentUser?.profileImage ?? "";
      user.value.firstName = store.getters.currentUser?.firstName ?? "";
      user.value.surname = store.getters.currentUser?.surname ?? "";
    });

    onUnmounted(() => {
      // stop listening for the afterEach
      routerHook();
    });

    return {
      menuOpen,
      handleClickToggleMenu,
      handleHoverToggleMenu,
      handleClickMenuItem,
      logout,
      loggedInUser,
      user,
    };
  },
});
