
import { defineComponent, PropType, ref, computed } from "vue";
import { useStore } from "vuex";
import Form from "@/components/Form.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import Button from "@/components/Button.vue";
import { ValidationArgs } from "@vuelidate/core";
import { helpers, not, required } from "@vuelidate/validators";
import { date } from "@/plugins/schema/schemas";
import { format } from "date-fns";
import { useLanguage } from "@/composables";

export interface DeclarationModel {
  dob: string;
  consent: boolean;
}

export default defineComponent({
  components: {
    Form,
    Checkbox,
    DatePicker,
    Button,
  },
  props: {
    modelValue: {
      type: Object as PropType<DeclarationModel>,
      required: true,
    },
    line1: {
      type: String,
      default: "declaration.main",
    },
    consentText: {
      type: String,
      default: "declaration.consent",
    },
    line2: String,
    isValid: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { getValueForString } = useLanguage();

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    const errors = computed({
      get: () => err.value,
      set: (value) => {
        err.value = value;
      },
    });
    const err = ref<{ [K: string]: string }>({});

    const currentUser = computed(() => store.getters.currentUser);
    const signatureType =
      store.getters.featureConfigs.find((fc) => fc.feature === "signature-type")
        ?.condition ?? null;

    const birthDateMatches = (value) =>
      value === format(new Date(currentUser.value.dob), "yyyy-MM-dd");

    const handleSignClick = () => {
      emit("confirm");
    };

    const rules: ValidationArgs = {};
    switch (signatureType) {
      case "date-of-birth": {
        rules["dob"] = {
          required: helpers.withMessage(
            getValueForString("validation.date-of-birth.missing"),
            required
          ),
          birthDateMatches: helpers.withMessage(
            getValueForString("validation.date-of-birth.incorrect"),
            birthDateMatches
          ),
        };
        rules["consent"] = {
          not: helpers.withMessage<boolean>(
            getValueForString("validation.consent.missing"),
            not<boolean>({ $validator: (v: boolean) => !v })
          ),
        };
        break;
      }
      case "basic":
      default:
        emit("update:isValid", true);
        break;
    }
    const valid = computed({
      get: () => props.isValid,
      set: (value) => emit("update:isValid", value),
    });

    return {
      model,
      valid,
      errors,
      rules,
      signatureType,
      handleSignClick,
    };
  },
});
