import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fe456b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "type-subtitle-02 title-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_Button = _resolveComponent("Button")!
  const _component_NewChecklist = _resolveComponent("NewChecklist")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.translateText("action.edit.ticks.title")), 1),
    _createVNode(_component_Checklist, {
      onRemoveItem: _ctx.handleRemoveTick,
      items: _ctx.checklistItems,
      actionable: false,
      editMode: true,
      class: "pb-1"
    }, null, 8, ["onRemoveItem", "items"]),
    _createVNode(_component_Button, {
      class: "w-100",
      text: "action.type.ticks.add-new",
      onClick: _ctx.handleAddNewChecklist
    }, null, 8, ["onClick"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.showNewModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewModal) = $event)),
      title: "action.ticks.new.heading",
      onCancel: _ctx.handleCancel,
      onConfirm: _ctx.handleSaveChecklist,
      onDismiss: _ctx.handleCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NewChecklist, {
          hotspot: _ctx.hotspot,
          modelValue: _ctx.newChecklist,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newChecklist) = $event))
        }, null, 8, ["hotspot", "modelValue"])
      ]),
      footer: _withCtx(({ confirm, cancel }) => [
        _createVNode(_component_Button, {
          text: "common.cancel",
          onClick: cancel
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          text: "common.save",
          onClick: confirm,
          disabled: _ctx.newChecklist.title.length === 0
        }, null, 8, ["onClick", "disabled"])
      ]),
      _: 1
    }, 8, ["modelValue", "onCancel", "onConfirm", "onDismiss"])
  ]))
}