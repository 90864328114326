
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { useLoading } from "@/plugins/loading";
import { Action, Hotspot, Tick } from "@/models";
import InfoAction from "@/components/hotspot/action/edit/Info.vue";
import TicksAction from "@/components/hotspot/action/edit/Ticks.vue";
import LinkAction from "@/components/hotspot/action/edit/Link.vue";
import DocumentAction from "@/components/hotspot/action/edit/Document.vue";
import VideoAction from "@/components/hotspot/action/edit/Video.vue";
import FormAction from "@/components/hotspot/action/edit/Form.vue";
import NewstarterAction from "@/components/hotspot/action/edit/NewStarterForm.vue";
import ReferencesAction from "@/components/hotspot/action/edit/References.vue";
import ContactGroup from "@/components/hotspot/action/edit/ContactGroup.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/form/Select.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import Modal from "@/components/Modal.vue";
import Tags from "@/components/user/tags/Tags.vue";

import { updateTextItems } from "@/api/text-items.api";
import { getUsersBasic } from "@/api/users.api";
import { getAllGroups } from "@/api/groups.api";

import ActionHeader from "@/components/hotspot/action/components/Header.vue";

import schema, { Schemas, useSchema } from "@/plugins/schema";
import { useModal } from "@/plugins/modal";
import useValidate from "@vuelidate/core";
import { useNotification, useLanguage } from "@/composables";
import { TextItem } from "@/interfaces";

export default defineComponent({
  components: {
    ActionHeader,
    Select,
    Button,
    Form,
    FormInput,
    Wysiwyg,
    Modal,
    InfoAction,
    LinkAction,
    TicksAction,
    DocumentAction,
    VideoAction,
    FormAction,
    Tags,
    NewstarterAction,
    ReferencesAction,
    ContactGroup,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.ActionContent);
    const showLoader = useLoading();
    const showModal = useModal();
    const action = toRef(props, "action");
    const isLoading = ref<boolean>(false);
    const showDetails = ref<boolean>(false);
    const showContent = ref<boolean>(true);

    const isValid = ref<boolean>(false);
    const isDirty = ref<boolean>(false);

    const { translateText } = useLanguage();

    const editMode = computed(() => store.getters.track.editMode);

    const actionHotspotHeading = ref<string>(
      translateText("action.edit.title")
    );
    const actionHotspotDescription = ref<string>(
      translateText("action.edit.description")
    );

    const { inPageNotification } = useNotification();

    const levelSelectOptions = computed(() => store.getters.allAudienceOptions);
    const typeSelectOptions = computed(() => store.getters.allActionTypes);

    const model = ref<any>({
      title: "",
      description: "",
      sideMenuText: "",
      signature: "",
      content: null,
    });
    var v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      signature: "",
      content: "",
    });

    const selectPlaceholder = ref({
      text: "form.placeholder.select",
      value: "",
    });

    const signatureItems = ref([
      { text: "references.yes", value: "yes" },
      { text: "references.no", value: "no" },
    ]);

    const handleSelectChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
      !isDirty.value && (isDirty.value = true);
    };

    const handleChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
      !isDirty.value && (isDirty.value = true);
    };

    const handleUpdateSchema = (s) => {
      const newSchema = { ...schema, ...s };
      v$ = useValidate(newSchema, ref(model.value));
      for (const f of Object.keys(newSchema)) {
        validateField(f);
      }
    };

    const handleComponentMounted = (loaded = true) => {
      componentMounted.value = loaded;
    };

    const getComponentKey = computed(() => {
      return `${action.value?.id ?? ""}`;
    });

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      isValid.value = !v$.value.$error;
    };

    const currentUser = store.getters.currentUser;

    const handleDataChange = async ({ value }) => {
      model.value.description = value;
      validateField("description");
      !isDirty.value && (isDirty.value = true);
    };

    const users = ref<any[]>([]);
    const groups = ref<any[]>([]);

    const selectedUser = ref<string>("");
    const selectedGroup = ref<string>("");
    const selectedTags = ref<string[]>([]);
    const updateActive = ref<boolean>(false);

    const handleComponentSave = async (success) => {
      try {
        if (success) {
          textItems.value = await updateTextItems(textItems.value, model.value);
          inPageNotification(
            "Success!",
            "Information has been successfully updated",
            "success"
          );
          isDirty.value = false;
          showLoader(false);
        } else {
          showLoader(false);
          throw "error";
        }
        updateActive.value = false;
      } catch (err) {
        inPageNotification("Error!", "Could not updated information", "error");
      }
    };
    const handleSave = async () => {
      showLoader(true);
      updateActive.value = true;
    };

    const handleDelete = async () => {
      showModal({
        title: "Delete Action?",
        body: "Are you sure you want to delete this action? This process cannot be undone.",
        onConfirm: async () => {
          emit("action-removed", action.value.id);
        },
      });
    };

    const handleHotspotDelete = async () => {
      if (props.hotspot?.id) {
        showModal({
          title: "Delete Hotspot?",
          body: "Are you sure you want to delete this hotspot? This process cannot be undone.",
          onConfirm: async () => {
            emit("hotspot-removed", props.hotspot.id);
          },
        });
      }
    };

    const showNewModal = ref<boolean>(false);

    const changeUser = async ({ name, value }) => {
      selectedGroup.value = "";
      selectedTags.value = [];
      selectedUser.value = value;
      validateField("user");
      !isDirty.value && (isDirty.value = true);
    };

    const changeGroup = async ({ name, value }) => {
      selectedUser.value = "";
      selectedTags.value = [];
      selectedGroup.value = value;
      validateField("group");
      !isDirty.value && (isDirty.value = true);
    };

    const handleTagsChanged = (tags) => {
      selectedUser.value = "";
      selectedGroup.value = "";
      selectedTags.value = tags;
      validateField("tag");
      !isDirty.value && (isDirty.value = true);
    };

    const currentComponent = computed(() => {
      var at = action.value?.actionType ?? "";
      at = at
        .toLowerCase()
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        })
        .replace(/([-_])*/g, "");
      return `${at}Action`;
    });
    const textItems = ref<any[]>([]);

    const toggleJaccordion = (t) => {
      if (t === "details") {
        showDetails.value = !showDetails.value;
      } else if (t === "content") {
        showContent.value = !showContent.value;
      }
    };

    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;

    const populateActionData = () => {
      textItems.value = action.value.textItems;
      model.value.title = findTextItem("title", textItems.value);
      model.value.sideMenuText = findTextItem("side-menu", textItems.value);
      model.value.description =
        findTextItem("description", textItems.value) ?? "";

      model.value.signature = action.value.signatureRequired ? "Yes" : "No";
    };

    const getGroups = async () => {
      const response = await getAllGroups();
      groups.value = response?.data?.payload?.results
        ? response.data.payload.results.map((group) => ({
            value: group.id,
            text: group.textItems.length
              ? findTextItem("group-name", group.textItems)
              : group.shortName,
          }))
        : [];
    };

    const getAllUsers = async () => {
      const response = await getUsersBasic();
      users.value = response?.data?.payload?.results
        ? response.data.payload.results.map((user) => ({
            value: user.id,
            text: user.firstName + " " + user.surname,
            preferredLanguageId: user.preferredLanguageId,
          }))
        : [];
    };

    const initData = async () => {
      if (action.value.level === "user") {
        await getAllUsers();
        if (selectedUser.value === "") {
          selectedUser.value = users.value.length ? users.value[0].value : "";
        }
      } else if (action.value.level === "group") {
        await getGroups();
        if (selectedGroup.value === "") {
          selectedGroup.value = groups.value.length
            ? groups.value[0].value
            : "";
        }
      }
    };

    const isMounted = ref(false);
    const componentMounted = ref(false);
    onMounted(async () => {
      if (action.value?.id) {
        initData();
        populateActionData();

        isMounted.value = true;
      }
    });

    watch(action, async () => {
      if (action.value?.id) {
        componentMounted.value = false;
        isValid.value = false;
        isDirty.value = false;
        initData();
        populateActionData();
      }
    });

    return {
      currentComponent,
      isValid,
      isDirty,
      schema,
      errors,
      selectedUser,
      selectedGroup,
      selectedTags,
      users,
      groups,
      isLoading,
      typeSelectOptions,
      levelSelectOptions,
      signatureItems,
      selectPlaceholder,
      model,
      actionHotspotHeading,
      actionHotspotDescription,
      showNewModal,
      updateActive,
      showDetails,
      showContent,
      isMounted,
      componentMounted,
      getComponentKey,
      handleComponentMounted,
      toggleJaccordion,
      handleSave,
      handleComponentSave,
      handleDelete,
      handleHotspotDelete,
      changeUser,
      changeGroup,
      handleTagsChanged,
      handleSelectChange,
      handleChange,
      handleUpdateSchema,
      handleDataChange,
      translateText,
    };
  },
});
