import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02cf73da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signature-actions" }
const _hoisted_2 = {
  key: 0,
  class: "tab-button visible-lg"
}
const _hoisted_3 = {
  key: 0,
  class: "tab"
}
const _hoisted_4 = { class: "tab__icon" }
const _hoisted_5 = { class: "tab__content" }
const _hoisted_6 = { class: "text-body" }
const _hoisted_7 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_8 = {
  key: 2,
  class: "tab-button"
}
const _hoisted_9 = { class: "signed-box" }
const _hoisted_10 = { class: "check" }
const _hoisted_11 = { class: "information" }
const _hoisted_12 = { class: "sub-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionHeader = _resolveComponent("ActionHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Declaration = _resolveComponent("Declaration")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, {
      hotspotDescription: _ctx.hotspotDescription('description'),
      actionTitle: _ctx.actionTitle,
      actionDescription: _ctx.actionDescription
    }, null, 8, ["hotspotDescription", "actionTitle", "actionDescription"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
      key: _ctx.getComponentKey,
      class: "action-content",
      action: _ctx.action,
      actionProgress: _ctx.currentActionProgress,
      completeActive: _ctx.completeActive,
      selectedUser: _ctx.selectedUser,
      selectedGroup: _ctx.selectedGroup,
      viewOnly: _ctx.viewOnly,
      onUpdated: _ctx.handleComponentUpdated,
      onCompleted: _ctx.handleComponentComplete,
      onIsLoaded: _ctx.handleComponentMounted
    }, {
      declaration: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.completionDateString && _ctx.signatureEnabled && !_ctx.viewOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.currentActionProgress?.completed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_font_awesome_icon, { icon: "check" })
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_TranslatedText, { path: "declaration.sign.complete" })
                        ]),
                        (_ctx.completionDateString)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_TranslatedText, { path: "common.on" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.completionDateString), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      text: 'declaration.sign',
                      disabled: !_ctx.readyToSign,
                      size: "sm",
                      onClick: _ctx.handleSignClick
                    }, {
                      labelAfter: _withCtx(() => [
                        (!_ctx.wetSignature)
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 0,
                              icon: _ctx.showSign ? 'chevron-down' : 'chevron-up'
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["text", "disabled", "onClick"]))
              ]))
            : _createCommentVNode("", true),
          (
              !_ctx.completionDateString &&
              !_ctx.wetSignature &&
              _ctx.signatureEnabled &&
              _ctx.showSign &&
              !_ctx.viewOnly
            )
            ? (_openBlock(), _createBlock(_component_Declaration, {
                key: 1,
                modelValue: _ctx.declaration,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.declaration) = $event)),
                isValid: _ctx.declarationIsValid,
                "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.declarationIsValid) = $event)),
                line1: 'declaration.main',
                consentText: 'declaration.consent',
                onConfirm: _ctx.handleSignSubmit
              }, null, 8, ["modelValue", "isValid", "line1", "consentText", "onConfirm"]))
            : _createCommentVNode("", true),
          (
              _ctx.completionDateString && _ctx.actionProgress?.completed && !_ctx.viewOnly
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "check",
                      class: "ico"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_TranslatedText, {
                      path: _ctx.signatureEnabled ? 'action.signed' : 'action.viewed'
                    }, null, 8, ["path"]),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.completionDateString), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["action", "actionProgress", "completeActive", "selectedUser", "selectedGroup", "viewOnly", "onUpdated", "onCompleted", "onIsLoaded"])),
    _createVNode(_component_Modal, {
      modelValue: _ctx.showSignModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSignModal) = $event)),
      title: "signature.title",
      subTitle: "signature.description",
      showFooter: false,
      onCancel: _ctx.handleModalHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          modelValue: _ctx.declarationWet,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.declarationWet) = $event)),
          onConfirm: _ctx.handleSignSubmit,
          onCancel: _ctx.handleModalHide
        }, null, 8, ["modelValue", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue", "onCancel"])
  ]))
}