
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { usePermissionProvider } from "@/plugins/permission";
import { NavItem } from "@/interfaces/ui";
import { useTheme } from "@/composables/useTheme";

export default defineComponent({
  name: "NavSide",
  props: {
    navItems: {
      type: Array as PropType<Array<NavItem>>,
      required: true,
    },
    sideMenuItems: {
      type: Array as PropType<Array<NavItem>>,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle-nav"],
  setup(props, { emit }) {
    const permissionProvider = usePermissionProvider();
    const route = useRoute();
    const store = useStore();

    const themeRef = ref<any>();
    const getTheme = async () => {
      const { theme } = useTheme();
      themeRef.value = theme.value;
    };

    const logoUrl = computed(() => {
      if (themeRef.value && themeRef.value.logo.app) {
        return themeRef.value.logo.app.url;
      }
      return require("@/assets/img/logo-jolahr-dark.svg");
    });

    const smallLogoUrl = computed(() => {
      if (themeRef.value && themeRef.value.logo.appSmall) {
        return themeRef.value.logo.appSmall.url;
      }
      return require("@/assets/img/logo-jolahr-small.svg");
    });

    const hoverParent = ref<number>(-1);
    const handleHoverParent = (e: Event, index: number) => {
      hoverParent.value = e.type === "mouseover" ? index : -1;
    };

    const hoverSideParent = ref<number>(-1);
    const handleHoverSideParent = (e: Event, index: number) => {
      hoverSideParent.value = e.type === "mouseover" ? index : -1;
    };

    const getNavItems = computed(() =>
      props.navItems.filter(
        (p) => checkPermissions(p) && checkConditions(p) && checkBadgeCounts(p)
      )
    );

    const fullMiniLogo = computed(() => {
      const styleValue = (themeRef.value?.config ?? []).find(
        (style) => style.name === "full-mini-logo"
      );

      return styleValue?.value === "true";
    });

    const getNavItemChildren = (children) =>
      children.filter((c) => checkPermissions(c) && checkConditions(c));

    const activePath = route.path;
    const activeParent = ref<number>(0);

    const toggleNav = () => emit("toggle-nav");

    const checkPermissions = (item: NavItem) => {
      if (item.permissions) {
        const matched = item.permissions.filter((p: string) =>
          permissionProvider.userHasPermission(p)
        );
        return matched.length > 0;
      }

      return true;
    };

    const checkConditions = (item: NavItem) => {
      if (item.conditions) {
        return permissionProvider.checkUserConditions(item.conditions);
      }

      return true;
    };

    const checkBadgeCounts = (item: NavItem) => {
      if (item.badgeCheck) {
        if (item.icon) {
          switch (item.badgeCheck) {
            case "unreadMessages":
              item.icon.badge =
                store.state.user.currentUser?.unreadMessages ?? null;
              // Set to null if not set so it doesn't show a value at all
              break;
          }
        }
      }

      return true;
    };

    watch(props, () => {
      getNavItems.value.forEach((item: NavItem, idx: number) => {
        if (activePath.indexOf(item.url as string) >= 0) {
          activeParent.value = idx;
          return;
        }
      });
    });

    onMounted(() => {
      getNavItems.value.forEach((item: NavItem, idx: number) => {
        if (activePath.indexOf(item.url as string) >= 0) {
          activeParent.value = idx;
          return;
        }
      });
      getTheme();
    });

    return {
      logoUrl,
      smallLogoUrl,
      hoverParent,
      handleHoverParent,
      hoverSideParent,
      handleHoverSideParent,
      activeParent,
      toggleNav,
      checkPermissions,
      checkConditions,
      checkBadgeCounts,
      getNavItems,
      getNavItemChildren,
      fullMiniLogo,
    };
  },
});
