import httpClient, { HttpResponse } from "@/api/httpClient";
import { TextItem } from "@/interfaces/domain";

export interface Progress {
  progressCompleted: number;
  progressPercentage: number;
  progressTotal: number;
}

export interface ActionProgress {
  actionId: string;
  userId: string;
  completed: boolean;
  fail: boolean;
  expectedCompletionDate?: Date;
  startDate?: Date;
  completionDate?: string;
  ip?: string;
}

export interface ActionProgressResponse {
  actionProgress: ActionProgress;
  progress: Progress;
}

export class Action {
  public actionType: string;
  public avgCompletionTimeFrameInDays?: number;
  public documentReference?: string;
  public infoReference?: string;
  public id: string;
  public inArchive: boolean;
  public level: string;
  public messageReference?: string;
  public position: number;
  public signatureRequired?: boolean;
  public textItems: TextItem[];
  public userRequired: boolean;
  public hideIfNoContent: boolean;
  public videoReference?: string;

  constructor({
    actionType = "",
    avgCompletionTimeFrameInDays = 0,
    documentReference = "",
    id = "",
    inArchive = false,
    level = "",
    messageReference = "",
    infoReference,
    position = 0,
    signatureRequired = false,
    textItems = [] as Array<TextItem>,
    userRequired = false,
    videoReference = "",
    hideIfNoContent = false,
  }: Partial<Action> = {}) {
    this.actionType = actionType;
    this.avgCompletionTimeFrameInDays = avgCompletionTimeFrameInDays;
    this.documentReference = documentReference;
    this.id = id;
    this.inArchive = inArchive;
    this.level = level;
    this.messageReference = messageReference;
    this.position = position;
    this.signatureRequired = signatureRequired;
    this.textItems = textItems;
    this.userRequired = userRequired;
    this.videoReference = videoReference;
    this.infoReference = infoReference;
    this.hideIfNoContent = hideIfNoContent;
  }

  public static async getUserProgressForAll(
    actionIds: Array<string>,
    userId: string
  ): Promise<Array<ActionProgress>> {
    const actionIdStr = JSON.stringify(actionIds);
    const response = await httpClient.get<HttpResponse<ActionProgress>>(
      `/actions/actionprogress/${actionIdStr}/${userId}`
    );
    return response.data.payload.results as Array<ActionProgress>;
  }

  public async viewAction(userId: string): Promise<ActionProgress> {
    return await httpClient.post(
      `/actions/actionprogress/view/${this.id}/${userId}`
    );
  }

  public async completeActionWet(
    userId: string,
    signature: string | null = null
  ): Promise<ActionProgressResponse> {
    const response = await httpClient.post<
      HttpResponse<ActionProgressResponse>
    >(`/actions/actioncompleted`, {
      actionId: this.id,
      userId: userId,
      signature,
    });

    return response.data.payload.result as ActionProgressResponse;
  }

  public async completeAction(
    userId: string,
    dob = "",
    signed?: boolean
  ): Promise<ActionProgressResponse> {
    const dobParts = dob.split("-");
    const dobYear = dobParts.length ? dobParts[0] : null;
    const dobMonth = dobParts.length > 1 ? dobParts[1] : null;
    const dobDay = dobParts.length > 2 ? dobParts[2] : null;
    const response = await httpClient.post<
      HttpResponse<ActionProgressResponse>
    >(`/actions/actioncompleted`, {
      actionId: this.id,
      userId: userId,
      dobDay,
      dobMonth,
      dobYear,
      sign: signed,
    });

    return response.data.payload.result as ActionProgressResponse;
  }
}
