import httpClient, { HttpResponse } from "@/api/httpClient";
import { TextItem } from "@/interfaces/domain";

export interface TickProgress {
  tickId: string;
  userId: string;
  completed: boolean;
  fail: boolean;
  expectedCompletionDate?: Date;
  startDate?: Date;
  completionDate?: string;
}

// export interface ActionProgressResponse {
//   actionProgress: ActionProgress;
//   progress: Progress;
// }

export class Tick {
  public id: string;
  public type: string;
  public level: string;
  public contentType: string;
  public order: number;
  public reminderNeeded: boolean;
  public contentReference: string;
  public textItems: Array<TextItem>;
  public tickProgressObjects: Array<TickProgress>;
  public avgCompletionTimeFrameInDays?: number;

  constructor({
    id = "",
    type = "",
    level = "",
    contentType = "",
    order = 0,
    reminderNeeded = false,
    contentReference = "",
    avgCompletionTimeFrameInDays = undefined as number | undefined,
    textItems = [] as Array<TextItem>,
    tickProgressObjects = [] as Array<TickProgress>,
  } = {}) {
    this.id = id;
    this.type = type;
    this.level = level;
    this.contentType = contentType;
    this.order = order;
    this.reminderNeeded = reminderNeeded;
    this.contentReference = contentReference;
    this.avgCompletionTimeFrameInDays = avgCompletionTimeFrameInDays;
    this.tickProgressObjects = tickProgressObjects;
    this.textItems = textItems;
  }

  public static async getForHotspotAndUser(
    hotspotId: string,
    userId: string
  ): Promise<Array<Tick>> {
    const response = await httpClient.get<HttpResponse<Tick>>(
      `/ticks/hotspot/${hotspotId}/user/${userId}`
    );
    return response.data.payload.results as Array<Tick>;
  }

  public static async getForActionAndUser(
    actionId: string,
    userId: string
  ): Promise<Array<Tick>> {
    const response = await httpClient.get<HttpResponse<Tick>>(
      `/ticks/action/${actionId}/user/${userId}`
    );
    return response.data.payload.results as Array<Tick>;
  }
}
