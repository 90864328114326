import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f69c63aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }
const _hoisted_2 = ["max", "value"]
const _hoisted_3 = {
  key: 0,
  class: "progress-text type-subtitle-01"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("progress", {
      class: _normalizeClass(_ctx.size),
      max: _ctx.max,
      value: _ctx.value
    }, null, 10, _hoisted_2),
    (_ctx.displayPercentage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.value) + "% ", 1),
          _createVNode(_component_TranslatedText, { path: "progress.complete" })
        ]))
      : _createCommentVNode("", true)
  ]))
}