
import {
  ref,
  defineComponent,
  PropType,
  onMounted,
  computed,
  watch,
} from "vue";
import { User, UserContactModel } from "@/models";
import { useModal } from "@/plugins/modal";
import { useLoading } from "@/plugins/loading";
import { useStore } from "vuex";

import { useNotification } from "@/composables/useNotification";
import { useLanguage } from "@/composables";

import Button from "@/components/Button.vue";
import Search from "@/components/Search.vue";
import ContactCard from "@/components/ui/ContactCard.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import Modal from "@/components/Modal.vue";
import TableList from "@/components/TableList.vue";
import Avatar from "@/components/Avatar.vue";

import { getContactsForUser, getAllContacts } from "@/api/contacts.api";
import {
  assignContact,
  unassignContact,
  updateContactFilters,
} from "@/api/user.api";
import { getImageContents } from "@/api/image-contents.api";

export default defineComponent({
  components: {
    Button,
    Search,
    ContactCard,
    ActionHeader,
    Modal,
    TableList,
    Avatar,
  },
  props: {
    user: {
      type: Object as PropType<User>,
    },
    isAuthenticatedUser: Boolean,
  },
  emits: ["refresh", "delete"],
  setup(props) {
    const { translateText } = useLanguage();

    const isLoading = ref(true);
    const model = ref<UserContactModel>(new UserContactModel());
    const allContacts = ref([]) as any;
    const usersContacts = ref([]) as any;
    const searchTerm = ref<string>("");

    const getContactData = async () => {
      if (props.user?.id) {
        const allRes = await getContactsForUser(props.user.id);
        usersContacts.value = allRes.data.payload.results;
        usersContacts.value = usersContacts.value.filter((c) => {
          return c?.users.filter((cu) => cu.id === props.user?.id).length;
        });
      }

      const allContactsResponse = await getAllContacts();
      allContacts.value = allContactsResponse.data.payload.results;

      allContacts.value.forEach(async (c, i) => {
        var assignedUserIndex = usersContacts.value.findIndex(
          (x) => x.id == c.id
        );
        //Update contactGroups
        if (usersContacts.value[assignedUserIndex]) {
          usersContacts.value[assignedUserIndex].contactGroups =
            c.contactGroups;

          if (c.imageReference && props.user?.id) {
            const imageResponse = await getImageContents(
              [c.imageReference],
              props.user?.id
            );
            if (imageResponse.data.payload.results && c) {
              allContacts.value[i].image =
                imageResponse.data.payload.results[0].url;
              // Update the assigned users image
              usersContacts.value[assignedUserIndex].image =
                imageResponse.data.payload.results[0].url;
            }
          }
        }
      });

      isLoading.value = false;
    };

    const getUsersContacts = computed(() =>
      usersContacts.value.map((c) => ({
        id: c.id,
        userId: "",
        name: c.name ?? "",
        position: c.role ?? "",
        image: c.image ?? "",
        telephone: c.contactNumber ?? "",
        email: "",
        description: c.description ?? "",
        bio: c.bio ?? "",
        groups: c.contactGroups.filter((cg) => !cg.alwaysShow),
        filteredGroups: c.userHasContactFilters
          ? c.userHasContactFilters.filter((cf) => cf.userId === props.user?.id)
          : [],
        links: [],
      }))
    );

    const getTableContacts = computed(() => {
      const addedContactsRemoved = allContacts.value.filter(
        (c) => !usersContacts.value.find((x) => x.id === c.id)
      );

      const filtered = addedContactsRemoved.filter((c) =>
        c.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );

      return filtered.map((c) => ({
        id: c.id,
        userId: "",
        name: c.name ?? "",
        position: c.role ?? "",
        image: c.image ?? "",
        telephone: c.contactNumber ?? "",
        email: "",
        description: c.description ?? "",
        bio: c.bio ?? "",
        groups: c.contactGroups ?? [],
        filteredGroups: c.userHasContactFilters
          ? c.userHasContactFilters.filter((cf) => cf.userId === props.user?.id)
          : [],
        links: [],
      }));
    });

    const handleContactRemove = async (contactId) => {
      if (props.user?.id) {
        await unassignContact(props.user?.id, { contactIds: [contactId] });
        usersContacts.value = usersContacts.value.filter(
          (c) => c.id !== contactId
        );
      }
    };

    const handleFiltersUpdate = async (contactId, newFilters) => {
      if (props.user?.id) {
        const contactGroupIds: Array<string> = [];

        newFilters.value.forEach(async (g) => {
          contactGroupIds.push(g.id);
        });
        await updateContactFilters(props.user?.id, {
          contactId,
          contactGroupIds,
        });
      }
    };

    const addContacts = () => {
      isModalVisible.value = true;
      searchTerm.value = "";
    };

    const addContact = async (contactId) => {
      if (props.user?.id) {
        await assignContact(props.user.id, { contactIds: [contactId] });
        usersContacts.value.push(
          allContacts.value.find((c) => c.id === contactId)
        );
      }
    };
    const getContactGroups = (contact) => {
      const allGroups: any[] = [];
      if (contact?.groups) {
        contact.groups.forEach(async (cg, k) => {
          allGroups.push({
            name: cg.textItems.find((ti) => ti.purpose === "contact-group-name")
              ?.data,
          });
        });
      }

      return allGroups;
    };

    const inputSearchTerm = (val: string) => (searchTerm.value = val);
    const searchContacts = (val: string) => (searchTerm.value = val);

    const isModalVisible = ref(false);

    const hideAddModal = () => {
      isModalVisible.value = false;
    };
    const handleContactAdd = () => {
      isModalVisible.value = false;
    };

    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    onMounted(async () => getContactData());
    watch(props, () => getContactData());

    return {
      model,
      isLoading,
      getTableContacts,
      getUsersContacts,
      addContacts,
      addContact,
      getContactGroups,
      handleContactRemove,
      handleFiltersUpdate,
      inputSearchTerm,
      isModalVisible,
      hideAddModal,
      handleContactAdd,
      translateText,
      isOdd,
      searchContacts,
    };
  },
});
