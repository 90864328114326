import { GetterTree, Module, ActionTree, MutationTree } from "vuex";
import { Hotspot } from "@/models";

enum MutationKeys {
  setHotspots = "setHotspots",
  setCurrentTrack = "setCurrentTrack",
}

interface HotspotState {
  hotspots: Array<Hotspot>;
  currentTrack: string;
}

const state: HotspotState = {
  hotspots: [],
  currentTrack: "",
};

const getters: GetterTree<HotspotState, null> = {
  hotspots: (state: HotspotState) => state.hotspots,
  currentTrack: (state: HotspotState) => state.currentTrack,
};

const actions: ActionTree<HotspotState, null> = {
  async syncHotspots({ commit }) {
    const hotspots = await Hotspot.getAllHotspots(state.currentTrack);
    commit(
      MutationKeys.setHotspots,
      hotspots.sort((a, b) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0
      )
    );
  },
  setCurrentTrack({ commit }, value: string) {
    commit(MutationKeys.setCurrentTrack, value);
  },
  setHotspots({ commit }, value: Array<Hotspot>) {
    commit(MutationKeys.setHotspots, value);
  },
};

const mutations: MutationTree<HotspotState> = {
  [MutationKeys.setHotspots]: (
    state: HotspotState,
    hotspots: Array<Hotspot>
  ) => {
    state.hotspots = hotspots;
  },
  [MutationKeys.setCurrentTrack]: (state: HotspotState, trackId: string) => {
    state.currentTrack = trackId;
  },
};

const hotspot: Module<HotspotState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { hotspot };
