import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66dee7f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "table-list__controls"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableSelection = _resolveComponent("TableSelection")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-list", _ctx.customClass])
  }, [
    (_ctx.showTableSelection)
      ? (_openBlock(), _createBlock(_component_TableSelection, {
          key: 0,
          tableName: _ctx.tableName,
          selectedRows: _ctx.selectedRows,
          deselectedRows: _ctx.deselectedRows,
          totalRows: _ctx.totalRows,
          selectAllActive: _ctx.selectAllActive,
          checkboxActive: _ctx.selectAllActive,
          actions: _ctx.actionsChip,
          onToggleAll: _ctx.toggleAll,
          onChangeActivationStatus: _ctx.changeActivationStatus,
          onClearSelection: _ctx.clearSelection,
          onToggleActionsChip: _ctx.toggleActionsChip,
          onClickMenuItem: _ctx.clickActionItem
        }, null, 8, ["tableName", "selectedRows", "deselectedRows", "totalRows", "selectAllActive", "checkboxActive", "actions", "onToggleAll", "onChangeActivationStatus", "onClearSelection", "onToggleActionsChip", "onClickMenuItem"]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _renderSlot(_ctx.$slots, "colspan", {}, undefined, true),
      _renderSlot(_ctx.$slots, "head", {}, undefined, true),
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ]),
    (_ctx.paginated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Pagination, {
            totalRows: _ctx.totalRows,
            currentPage: _ctx.currentPage,
            onChangeRowCount: _ctx.changeRowCount,
            onChangePage: _ctx.changePage
          }, null, 8, ["totalRows", "currentPage", "onChangeRowCount", "onChangePage"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}