
import { defineComponent, PropType } from "vue";
import { TextItem } from "@/interfaces";
import Boolean from "@/components/Boolean.vue";
import TableList from "@/components/TableList.vue";
import TableDropdown from "@/components/TableDropdown.vue";

export default defineComponent({
  emits: ["handle-click-sort", "click-cell", "view-video"],
  components: {
    Boolean,
    TableList,
    TableDropdown,
  },
  props: {
    tableHeadings: {
      type: Object as PropType<any>,
      required: true,
    },
    preparedVideos: {
      type: Object as PropType<any>,
      required: true,
    },
    videos: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    videoContents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    selectedVideos: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deselectedVideos: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    headingsData: {
      type: Object as PropType<any>,
      required: true,
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
    selectAll: Boolean,
  },
  setup(props, { emit }) {
    const isOdd = (num: number) => Math.abs(num % 2) == 1;
    const getVideoContentId = (reference: string) =>
      props.videoContents.find((video) => video.reference === reference)?.id;
    const viewVideo = (
      reference: string,
      textItems: TextItem[],
      id: string
    ) => {
      const name = findTextItem("title", textItems);
      const description = findTextItem("description", textItems);
      const sideMenuText = findTextItem("side-menu", textItems);
      emit("view-video", { reference, name, description, sideMenuText, id });
    };

    const handleClickCell = (
      id: string,
      index: number,
      videoReference: string,
      textItems: TextItem[]
    ) => {
      if (window.innerWidth >= 768) {
        viewVideo(videoReference, textItems, id);
      } else {
        emit("click-cell", { id, index });
      }
    };
    const handleClickSort = (title: string, id: string) =>
      emit("handle-click-sort", { title, id });
    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;
    const getSignOffStatus = (reference: string) => {
      const videoContents = props.videoContents.find(
        (vid) => vid.reference === reference
      );
      if (videoContents) return videoContents.signOffEnabled;
      return false;
    };

    return {
      isOdd,
      handleClickCell,
      viewVideo,
      handleClickSort,
      findTextItem,
      getSignOffStatus,
      getVideoContentId,
    };
  },
});
