import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b638c752"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "wysiwyg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Wysiwyg = _resolveComponent("Wysiwyg")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    schema: {}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormInput, {
            value: _ctx.model.title,
            error: "",
            onChange: _ctx.handleInputChange,
            label: "form.title",
            name: "title",
            id: "title",
            customClass: "required"
          }, null, 8, ["value", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Wysiwyg, {
              label: "setup.welcome.subtitle",
              value: _ctx.model.description,
              name: "description",
              onInputChange: _ctx.handleInputChange,
              ref: "quill-hotspot"
            }, null, 8, ["value", "onInputChange"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}