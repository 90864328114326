import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c3d3759"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col md:col-6" }
const _hoisted_3 = { class: "col md:col-6" }
const _hoisted_4 = { class: "col md:col-6" }
const _hoisted_5 = { class: "col md:col-6" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = {
  key: 0,
  class: "col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_AddressList = _resolveComponent("AddressList")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    errors: _ctx.errorModel,
    "onUpdate:errors": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errorModel) = $event)),
    schema: _ctx.schema
  }, {
    default: _withCtx(({ handleInputChange }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormInput, {
            value: _ctx.model?.bankName,
            error: _ctx.errorModel['bankName'],
            disabled: _ctx.readonly,
            onChange: handleInputChange,
            label: "form.bank-name",
            name: "bankName",
            id: "bankName",
            customClass: "required"
          }, null, 8, ["value", "error", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormInput, {
            value: _ctx.model?.sortCode,
            error: _ctx.errorModel['sortCode'],
            disabled: _ctx.readonly,
            onChange: handleInputChange,
            label: "form.sort-code",
            name: "sortCode",
            id: "sortCode",
            customClass: "required"
          }, null, 8, ["value", "error", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_FormInput, {
            value: _ctx.model?.accountNumber,
            error: _ctx.errorModel['accountNumber'],
            disabled: _ctx.readonly,
            onChange: handleInputChange,
            label: "form.account-number",
            name: "accountNumber",
            id: "accountNumber",
            customClass: "required"
          }, null, 8, ["value", "error", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FormInput, {
            value: _ctx.model?.accountName,
            error: _ctx.errorModel['accountName'],
            disabled: _ctx.readonly,
            onChange: handleInputChange,
            label: "form.account-name",
            name: "accountName",
            id: "accountName",
            customClass: "required"
          }, null, 8, ["value", "error", "disabled", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_FormInput, {
            value: _ctx.model?.notes,
            error: _ctx.errorModel['notes'],
            disabled: _ctx.readonly,
            onChange: handleInputChange,
            label: "form.other-account-information",
            name: "notes",
            id: "notes"
          }, null, 8, ["value", "error", "disabled", "onChange"])
        ]),
        (_ctx.bankAddressDisplayed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("label", null, [
                _createVNode(_component_TranslatedText, { path: "form.bank-address" })
              ]),
              _createVNode(_component_AddressList, {
                addresses: _ctx.bankAddress,
                limit: 1,
                hidePrimary: true,
                readonly: _ctx.readonly || _ctx.disabled,
                required: true,
                onSave: _ctx.handleUpdateAddress,
                onDelete: _ctx.handleRemoveAddress
              }, null, 8, ["addresses", "readonly", "onSave", "onDelete"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "errors", "schema"]))
}