
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
} from "@vue/runtime-core";
import VueMultiselect from "vue-multiselect";
import { useLanguage } from "@/composables";

export default defineComponent({
  name: "MultiSelect",
  props: {
    id: String,
    name: String,
    label: String,
    customClass: String,
    value: {
      default: () => [],
    },
    reset: Boolean,
    items: {
      type: Array as PropType<Array<{ value: string; text: string }>>,
      default: () => [],
    },
    labelField: {
      type: String,
      default: "text",
    },
    valueField: {
      type: String,
      default: "value",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    groupValues: {
      type: String,
      required: false,
    },
    groupLabel: {
      type: String,
      required: false,
    },
    groupSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    icon: {
      type: String,
      default: "",
    },
    labelAlwaysActive: Boolean,
  },
  components: {
    VueMultiselect,
  },
  emits: ["edit", "change", "searchChange"],
  setup(props, { emit }) {
    const { translateText } = useLanguage();

    const valueArray = ref(
      typeof props.value === "string" ? [{ value: "", text: "" }] : props.value
    );
    const isFocussed = ref<boolean>(false);
    const isActive = computed(
      () => isFocussed.value || !!props.value || !!props.labelAlwaysActive
    );
    const handleFocus = () => (isFocussed.value = true);

    const selectOptions = computed(() =>
      props.items.map((i: { value: string; text: string }) => ({
        value: i?.value,
        text: translateText(i?.text ?? ""),
      }))
    );

    const handleChange = (e) => {
      isFocussed.value = false;

      emit("change", { name: props.name, value: e.value });
    };
    const handleRemove = (e) => {
      const values = [...valueArray.value];
      const index = values.indexOf(e);
      values.splice(index, 1);
      emit("change", {
        name: props.name,
        value: values,
      });
    };

    const additionalAtrributes = computed(() => {
      if (props.groupValues && props.groupLabel) {
        return {
          groupValues: props.groupValues,
          groupLabel: props.groupLabel,
          groupSelect: props.groupSelect,
        };
      }
      return {};
    });

    const handleSearchChange = (event) => {
      emit("searchChange", { value: event });
    };

    const setValue = async () => {
      const propVal: any = props.value;
      const valueCheck =
        typeof propVal === "string" ? propVal : propVal[0]?.value ?? "";

      const itemsCheck = [...selectOptions.value];
      const findItem: any = await itemsCheck.find(
        (i: any) => i.value == valueCheck
      );
      if (findItem?.text) {
        valueArray.value = [findItem];
      }
    };

    const getPlaceholder = computed(() => translateText(props.placeholder));

    onMounted(async () => {
      if (typeof props.value === "string") {
        await setValue();
      }
    });

    watch(props, async () => {
      valueArray.value = [{ value: "", text: "" }];
      if (props.value) {
        await setValue();
      }
      return true;
    });

    const handleEditClick = () => emit("edit");

    return {
      additionalAtrributes,
      isFocussed,
      isActive,
      handleChange,
      handleRemove,
      handleFocus,
      handleSearchChange,
      getPlaceholder,
      selectOptions,
      valueArray,
    };
  },
});
