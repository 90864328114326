import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  tenant,
  user,
  notification,
  loader,
  modal,
  pagination,
  language,
  report,
  hotspot,
  action,
  track,
  misc,
} from "@/store/modules";

export default createStore({
  plugins: [
    createPersistedState({
      paths: [
        "tenant",
        "tenant.featureConfigs",
        "user.currentUser",
        "user.loggedIn",
        "user.token",
        "user.authTimestamp",
        "user.changePassword",
        "pagination",
        "language.current",
        "hotspot.currentTrack",
        "hotspot.hotspots",
        "action.allActionTypes",
        "action.actionTypes",
      ],
    }),
  ],
  modules: {
    tenant,
    user,
    notification,
    loader,
    modal,
    pagination,
    language,
    report,
    hotspot,
    action,
    track,
    misc,
  },
});
