
import { computed, defineComponent, PropType } from "vue";
import { useFormQuestion } from "@/composables";
import Form from "@/components/Form.vue";
import Card from "@/components/Card.vue";
import FormInput from "@/components/form/FormInput.vue";
import Radio from "@/components/form/Radio.vue";
import { FormQuestion } from "@/interfaces/domain";
import MultiSelect from "@/components/form/MultiSelect.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default defineComponent({
  emits: ["update:modelValue", "addChoice", "changeQuestionType"],
  components: {
    Card,
    FormInput,
    Form,
    Radio,
    MultiSelect,
    ImageSelector,
  },
  props: {
    editing: Boolean,
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
    formQuestions: {
      type: Array as PropType<Array<FormQuestion>>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { formQuestionTypes, formQuestionPreFilled, validationTypes } =
      useFormQuestion();
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const setImage = (event) => {
      model.value.image = event.value;
      readImageFile(event.value).then((url) => (model.value.imageUrl = url));
    };

    const getIcon = (type) => {
      switch (type) {
        case "bigtext":
          return "paragraph";
        case "statements":
          return "dot-circle";
        case "title":
          return "header";
        case "prompt":
          return "align-left";
        case "image":
          return "image";
        case "imageupload":
          return "file-image";
        case "checkboxes":
          return "square-check";
        case "pdfupload":
          return "file-pdf";
        case "select":
          return "chevron-down";
        case "genericupload":
          return "upload";
        case "date":
          return "calendar-alt";
        case "text":
        default:
          return "font";
      }
    };

    const readImageFile = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(file);
      });

    const addChoice = () => emit("addChoice");

    const changeQuestionType = (type: string) =>
      emit("changeQuestionType", { type });

    return {
      formQuestionTypes,
      formQuestionPreFilled,
      model,
      validationTypes,
      addChoice,
      setImage,
      getIcon,
      changeQuestionType,
    };
  },
});
