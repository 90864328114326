
import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import VueMultiselect from "vue-multiselect";
import { useNotification, useLanguage } from "@/composables";

export default defineComponent({
  name: "MultiSelect",
  props: {
    id: String,
    name: String,
    label: String,
    customClass: String,
    value: {
      type: Array,
      default: () => [],
    },
    reset: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    labelField: {
      type: String,
      default: "name",
    },
    valueField: {
      type: String,
      default: "id",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    groupValues: {
      type: String,
      required: false,
    },
    groupLabel: {
      type: String,
      required: false,
    },
    groupSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    VueMultiselect,
  },
  emits: ["change", "searchChange"],
  setup(props, { emit }) {
    const { translateText } = useLanguage();
    const valueArray = ref(props.value);
    const isFocussed = ref<boolean>(false);
    const isActive = computed(() => isFocussed.value || !!valueArray.value);
    const handleFocus = () => (isFocussed.value = true);

    const handleChange = (e) => {
      isFocussed.value = false;
      const values = [...valueArray.value, e];
      emit("change", {
        name: props.name,
        value: values,
      });
    };
    const handleRemove = (e) => {
      const values = [...valueArray.value];
      const index = values.indexOf(e);
      values.splice(index, 1);
      emit("change", {
        name: props.name,
        value: values,
      });
    };

    const additionalAtrributes = computed(() => {
      if (props.groupValues && props.groupLabel) {
        return {
          groupValues: props.groupValues,
          groupLabel: props.groupLabel,
          groupSelect: props.groupSelect,
        };
      }
      return {};
    });

    const handleSearchChange = (event) => {
      emit("searchChange", { value: event });
    };

    const getPlaceholder = computed(() =>
      translateText("form.placeholder.select")
    );

    watch(props, () => (valueArray.value = props.value));

    return {
      additionalAtrributes,
      isFocussed,
      isActive,
      handleChange,
      handleRemove,
      handleFocus,
      handleSearchChange,
      getPlaceholder,
      valueArray,
    };
  },
});
