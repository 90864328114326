
import { defineComponent } from "vue";

export default defineComponent({
  name: "Cubic",
  emits: ["setDraggedCubic"],
  props: {
    index: {
      type: Number,
    },
    p1x: {
      type: Number,
    },
    p1y: {
      type: Number,
    },
    p2x: {
      type: Number,
    },
    p2y: {
      type: Number,
    },
    x1: {
      type: Number,
    },
    y1: {
      type: Number,
    },
    x2: {
      type: Number,
    },
    y2: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const setDraggedCubic = (index, anchor) => {
      emit("setDraggedCubic", { index, anchor });
    };

    return {
      setDraggedCubic,
    };
  },
});
