
import { defineComponent } from "vue";

const videoTypes = ["upload", "embed"];

export default defineComponent({
  emits: ["toggle-playback", "toggle-YT-playback", "toggle-fullscreen"],
  props: {
    isPlaying: Boolean,
    isFullScreen: Boolean,
    videoType: {
      type: String,
      default: "upload",
      validator: (val: string) => videoTypes.some((type) => val === type),
    },
  },
  setup(props, { emit }) {
    const togglePlayback = () => {
      if (props.videoType === "upload") emit("toggle-playback");
      else emit("toggle-YT-playback");
    };
    const toggleFullScreen = () => emit("toggle-fullscreen");
    return { toggleFullScreen, togglePlayback };
  },
});
