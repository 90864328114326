import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5317ae1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "public-icon"
}
const _hoisted_2 = { class: "description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_ContactNumberForm = _resolveComponent("ContactNumberForm")!
  const _component_DynamicList = _resolveComponent("DynamicList")!

  return (_openBlock(), _createBlock(_component_DynamicList, {
    items: _ctx.contactNumbers,
    schema: _ctx.schema,
    buttonText: "models.contact-number.add",
    modalTitle: "models.contact-number.singular",
    newFunc: _ctx.createNewUserContactNumber,
    limit: _ctx.limit,
    readonly: _ctx.readonly,
    onSave: _ctx.handleSave,
    onDelete: _ctx.handleDelete
  }, {
    icon: _withCtx(({ item }) => [
      (item.isPublic)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, { icon: "eye" })
          ]))
        : _createCommentVNode("", true)
    ]),
    item: _withCtx(({ item }) => [
      _createVNode(_component_Card, {
        title: _ctx.getTitle(item.isPrimary)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, "+" + _toDisplayString(item.countryCode) + " " + _toDisplayString(item.number), 1)
        ]),
        _: 2
      }, 1032, ["title"])
    ]),
    modalBody: _withCtx(({ update, formItem, errors }) => [
      _createElementVNode("p", _hoisted_2, [
        _createVNode(_component_TranslatedText, { path: "models.contact-number.description" })
      ]),
      _createVNode(_component_ContactNumberForm, {
        contactNumber: formItem,
        errors: errors,
        hidePrimary: _ctx.hidePrimary,
        onUpdate: update
      }, null, 8, ["contactNumber", "errors", "hidePrimary", "onUpdate"])
    ]),
    _: 1
  }, 8, ["items", "schema", "newFunc", "limit", "readonly", "onSave", "onDelete"]))
}