
import { defineComponent } from "vue";
import Avatar from "@/components/Avatar.vue";
import Checkbox from "@/components/Checkbox.vue";
import Tooltip from "@/components/Tooltip.vue";
import { format, parseISO } from "date-fns";

export default defineComponent({
  name: "TableCell",
  props: ["dataType", "data", "dataInfo", "rowActive"],
  emits: ["click-cell", "toggle-checkbox"],
  setup(_, context) {
    const clickCell = (type: string) => {
      if (type === "checkbox") return;
      context.emit("click-cell");
    };
    const getTooltip = (tooltip) => (tooltip ? tooltip.join(", ") : "");

    const toggleCheckbox = () => {
      context.emit("toggle-checkbox");
    };

    const formatDate = (d) => format(parseISO(d), "dd-MM-yyyy");

    return {
      clickCell,
      toggleCheckbox,
      getTooltip,
      formatDate,
    };
  },
  components: {
    Avatar,
    Checkbox,
    Tooltip,
  },
  methods: {
    classProgress: function (progress: number) {
      if (progress >= 20) {
        return "green";
      } else {
        return "red";
      }
    },
  },
});
