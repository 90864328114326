import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslateMenu = _resolveComponent("TranslateMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "main",
    style: _normalizeStyle(_ctx.background)
  }, [
    _createElementVNode("main", {
      class: _normalizeClass(["drawers__fill", _ctx.getClasses])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_TranslateMenu)
    ], 2)
  ], 4))
}