<template>
  <div class="image-selecter">
    <p class="heading">{{ title }}</p>
    <div class="content">
      <div class="selecter">
        <div
          class="selecter--edit-container"
          :style="previewStyle"
          @click="selectImage"
        >
          <img
            class="selecter--edit"
            :src="require('@/assets/img/icon-edit.svg')"
          />
        </div>
      </div>
      <div class="preview">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: "LogoSelector",
  props: ["imageUrl", "title"],
  setup(props, { emit }) {
    const previewUrl = ref(props.imageUrl);
    watch(props, () => {
      if (props.imageUrl) {
        previewUrl.value = props.imageUrl;
      }
    });

    const previewStyle = computed(() => {
      return (
        previewUrl.value && {
          backgroundImage: `url('${previewUrl.value}')`,
        }
      );
    });

    const setImagePreview = (source) => {
      if (source instanceof File) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          previewUrl.value = reader.result;
        });
        reader.readAsDataURL(source);
        return;
      }
      if (typeof source == "string") {
        previewUrl.value = source;
      }
    };

    const selectImage = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.addEventListener("change", () => {
        emit("change", input.files[0]);
        setImagePreview(input.files[0]);
      });
      input.click();
    };

    return {
      previewUrl,
      previewStyle,
      setImagePreview,
      selectImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_utilities.scss";

.image-selecter {
  border: 1px solid #ccc;
  border-radius: 0.33em;

  padding: 0;
  margin: 1rem 0 2rem;
  position: relative;

  p.heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 0.5em;
    position: absolute;
    top: -0.9em;
    left: 1em;
    background: white;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0;
    background: #f9f9f9;

    .selecter {
      flex: 1;
      padding: 2em;
      background: #ffffff;

      &--edit {
        position: absolute;
        top: 50%;

        pointer-events: none;
        color: #fff;
        text-shadow: 1px 1px 0.1em #000;
        transition: 80ms all;

        align-self: center;
      }

      &--edit-container {
        width: 100%;
        padding-top: 100%;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        border-radius: 50%;
        background: #e7e7e7 center center no-repeat;
        background-size: 60%;
        border: 2px solid #a0a0a0;

        &:hover {
          .selecter--edit {
            opacity: 0.5;
            color: #000;
          }
        }
      }
    }

    .preview {
      flex: 7;
      padding: 0 3em;
    }
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: "lg")) {
    .content {
      flex-wrap: wrap;
      .selecter {
        flex: 0 0 100%;

        &--edit-container {
          width: 15em;
          padding-top: 15em;
          margin: 0 auto;
        }
      }

      .preview {
        flex: 0 0 100%;
        padding: 3em;
      }
    }
  }

  @media screen and (min-width: map-get($map: $grid-breakpoints, $key: "lg")) {
  }
}
</style>
