
import { defineComponent, PropType } from "vue";
import { TextItem } from "@/interfaces";
import Boolean from "@/components/Boolean.vue";

export default defineComponent({
  components: {
    Boolean,
  },
  props: {
    preparedVideos: {
      type: Object as PropType<any>,
      required: true,
    },
    videos: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    videoContents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const handleToggleAll = () => emit("toggle-all-rows");
    const getVideoContentId = (reference: string) =>
      props.videoContents.find((video) => video.reference === reference)?.id;

    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;
    const getSignOffStatus = (reference: string) => {
      const videoContents = props.videoContents.find(
        (vid) => vid.reference === reference
      );
      if (videoContents) return videoContents.signOffEnabled;
      return false;
    };
    const viewVideo = (
      reference: string,
      textItems: TextItem[],
      id: string
    ) => {
      const name = findTextItem("title", textItems);
      const description = findTextItem("description", textItems);
      const sideMenuText = findTextItem("side-menu", textItems);
      emit("view-video", { reference, name, description, sideMenuText, id });
    };

    return {
      handleToggleAll,
      findTextItem,
      viewVideo,
      getSignOffStatus,
      getVideoContentId,
    };
  },
});
