import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "ad-Choices" }
const _hoisted_2 = ["value", "checked", "name"]
const _hoisted_3 = { class: "ad-Choice-fake" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choices, (c, index) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "ad-Choice",
        key: index
      }, [
        _createElementVNode("input", {
          class: "ad-Choice-input",
          type: "radio",
          value: c.value,
          checked: c.checked,
          name: _ctx.id,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(c.name), 1)
      ]))
    }), 128))
  ]))
}