
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  computed,
  watch,
} from "vue";
import { UserDetails } from "@/models";
import {
  getAllTagCollections,
  createTag,
  createTagCollection,
  updateTag,
  updateTagCollection,
  deleteTagCollection,
  deleteTag,
} from "@/api/tags.api";
import MultiSelect from "@/components/form/MultiSelect.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import FormInput from "@/components/form/FormInput.vue";
import Select from "@/components/form/Select.vue";
import EditBox from "@/components/form/EditBox.vue";
import { TagCollection } from "@/interfaces/domain";
import { useLanguage } from "@/composables/useLanguage";
import { useNotification } from "@/composables/useNotification";
import { usePermissionProvider } from "@/plugins/permission";

export default defineComponent({
  emits: ["tagsChanged"],
  props: {
    user: {
      type: Object as PropType<UserDetails>,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: () => [],
    },
  },
  components: { MultiSelect, Modal, Button, FormInput, Select, EditBox },
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const { inPageNotification } = useNotification();
    const { userHasPermission } = usePermissionProvider();
    const tagGroups = ref<Array<TagCollection>>([]);

    const selectedTags = ref(
      typeof props.value === "string"
        ? [{ id: "", name: "", group: "" }]
        : props.value
    );
    const newGroupName = ref<string>("");
    const newTagName = ref<string>("");
    const newTagGroup = ref<string>("");
    const selectedGroup = ref<string>("");
    const selectedTag = ref<string>("");
    const showDeleteGroupModal = ref(false);
    const showDeleteTagModal = ref(false);

    const tagSelectOptions = ref<
      Array<{
        tagGroup: string;
        tags: { id: string; name: string; group: string }[];
      }>
    >([]);

    const handleTagGroupManageChange = ({ name, value }) => {
      selectedGroup.value = value;
      newTagGroup.value = selectedGroup.value;

      const activeGroup = tagGroups.value.find((tg) => tg.id === value);

      selectedTag.value = activeGroup?.tags ? activeGroup?.tags[0]?.id : "";
    };

    const handleTagManageChange = ({ name, value }) => {
      selectedTag.value = value;
    };

    const getTagGroupOptions = computed<any>(() => {
      return tagGroups.value.map(function (tg) {
        return {
          value: tg.id,
          text: tg.name,
        };
      });
    });

    const getTagOptions = computed<any>(() => {
      const activeGroup = tagGroups.value.find(
        (tg) => tg.id === selectedGroup.value
      );
      return activeGroup?.tags
        ? activeGroup.tags.map(function (t) {
            return {
              value: t.id,
              text: t.value,
            };
          })
        : [];
    });

    const handleTagChange = async ({ value }) => {
      selectedTags.value = [];
      for (const valueTag of value) {
        // user has selected a temporarily created tag so create it properly now
        if (valueTag.id.substring(0, 4) === "temp") {
          await createNewTag(valueTag);
        }
        selectedTags.value.push(valueTag);
      }
      updateUserTags();
    };

    async function createNewTag(valueTag: any) {
      const response = await createTag({
        value: valueTag.name,
        tagCollectionId: valueTag.id.substring(4),
      });
      const newTag = response.data.payload.result;
      valueTag.id = newTag.id;
    }

    function updateUserTags() {
      const userTags = selectedTags.value.map((tag) => ({
        id: tag.id,
        value: tag.name,
      }));
      emit("tagsChanged", userTags);
    }

    const handleTagSearchChange = ({ value }) => {
      const tagSelectOptionsTemp = [...tagSelectOptions.value];
      tagSelectOptions.value = [];
      tagGroups.value.forEach((tg) => {
        const existingTagGroup = tagSelectOptionsTemp.find(
          (option) => option.tagGroup === tg.name
        );
        const existingTag = existingTagGroup?.tags.find(
          (tag) => tag.name === value
        );
        let newTag;

        // if the tag doesn't match any existing tags, create a new temp id for the new tag
        if (!existingTag && value?.length > 0) {
          newTag = { id: "temp" + tg.id, name: value, group: tg.name };
        }
        const tags = tg.tags
          ? tg.tags
              .filter((tag) => tag.id.substring(0, 4) !== "temp")
              .map((t) => ({
                id: t.id,
                name: t.value,
                group: tg.name,
              }))
          : [];

        // if the user created a new tag add it to the group
        if (newTag) {
          tags.push(newTag);
        }

        tagSelectOptions.value.push({
          tagGroup: tg.name,
          tags,
        });
      });
    };

    const showManageModal = ref<boolean>(false);
    const showManageTagModal = ref<boolean>(false);
    const showManageGroupModal = ref<boolean>(false);
    const editMode = ref<boolean>(false);
    const handleModalHide = () => {
      showManageModal.value = false;
      newGroupName.value = "";
      newTagName.value = "";
      editMode.value = false;
    };
    const handleTagModalHide = () => {
      showManageTagModal.value = false;
      showManageModal.value = true;
      showDeleteTagModal.value = false;
      newGroupName.value = "";
      editMode.value = false;
    };
    const handleGroupModalHide = () => {
      showManageGroupModal.value = false;
      showManageModal.value = true;
      showDeleteGroupModal.value = false;
      newTagName.value = "";
      editMode.value = false;
    };
    const handleDeleteModalHide = () => {
      showDeleteGroupModal.value = false;
      showDeleteTagModal.value = false;
    };

    const getSelectedGroup = computed(() => {
      const group = getTagGroupOptions.value.find(
        (group) => group.value === selectedGroup.value
      );
      return group;
    });

    const getSelectedTag = computed(() =>
      getTagOptions.value.find((tag) => tag.value === selectedTag.value)
    );

    const updateNewGroupName = ({ value }) => (newGroupName.value = value);
    const updateNewTagGroup = ({ value }) => {
      newTagGroup.value = value;
      selectedGroup.value = value;
      return true;
    };
    const updateNewTagName = ({ value }) => (newTagName.value = value);

    const handleAddTagGroup = async () => {
      const newGroup = await createTagCollection({
        name: newGroupName.value,
        type: "text",
      });
      newGroupName.value = "";
      if (newGroup?.data?.payload?.result?.id) {
        selectedGroup.value = newGroup.data.payload.result.id;
      }
      getTagGroups();
      setupUserTags();
      handleGroupModalHide();

      inPageNotification(
        "tags.manage.heading.group",
        "tags.manage.action.group.create.success",
        "success"
      );
    };

    const handleAddTag = async () => {
      const newTag = await createTag({
        value: newTagName.value,
        tagCollectionId: newTagGroup.value,
      });
      newTagName.value = "";
      if (newTag?.data?.payload?.result?.id) {
        selectedTag.value = newTag.data.payload.result.id;
      }
      getTagGroups();
      updateUserTags();

      inPageNotification(
        "tags.manage.heading.tag",
        "tags.manage.action.tag.create.success",
        "success"
      );
    };

    const getTagGroups = async () => {
      tagSelectOptions.value = [];
      const response = await getAllTagCollections();
      tagGroups.value = response.data.payload.results ?? [];
      selectedGroup.value = selectedGroup.value.length
        ? selectedGroup.value
        : tagGroups.value[0]?.id;
      selectedTag.value = tagGroups.value[0]?.tags
        ? tagGroups.value[0]?.tags[0]?.id ?? ""
        : "";
      if (tagGroups.value?.length > 0) {
        tagGroups.value.forEach((tg) => {
          tagSelectOptions.value.push({
            tagGroup: tg.name,
            tags: tg.tags
              ? tg.tags.map((t) => ({
                  id: t.id,
                  name: t.value,
                  group: tg.name,
                }))
              : [],
          });
        });
      }
    };

    const setupUserTags = () => {
      if (props.user?.tags) {
        selectedTags.value = [];
        props.user.tags.forEach(async (tag) => {
          let tagFound = false;
          let group = "";
          while (!tagFound) {
            await tagGroups.value.forEach((tg) => {
              if (tg.tags && tg.tags.find((t) => t.value === tag.value)) {
                group = tg.name;
                tagFound = true;
              }
              return group;
            });
            tagFound = true;
          }
          selectedTags.value.push({
            id: tag.id,
            name: tag.value,
            group,
          });
        });
      }
    };

    const handleDeleteGroup = async () => {
      await deleteTagCollection(selectedGroup.value);
      newGroupName.value = "";
      getTagGroups();
      setupUserTags();
      handleGroupModalHide();

      inPageNotification(
        "tags.manage.heading.group",
        "tags.manage.action.group.delete.success",
        "success"
      );
    };
    const handleDeleteTag = async () => {
      await deleteTag(selectedTag.value);
      newTagName.value = "";
      getTagGroups();
      updateUserTags();
      handleTagModalHide();

      inPageNotification(
        "tags.manage.heading.tag",
        "tags.manage.action.tag.delete.success",
        "success"
      );
    };

    const handleEditGroup = (item) => {
      selectedGroup.value = item.value;
      newGroupName.value = getSelectedGroup.value.text;
      showManageTagModal.value = false;
      showManageGroupModal.value = true;
      editMode.value = true;
    };
    const handleEditTag = (item) => {
      selectedTag.value = item.value;
      newTagName.value = getSelectedTag.value.text;
      showManageTagModal.value = true;
      showManageGroupModal.value = false;
      editMode.value = true;
    };

    const handleEditGroupSubmit = async () => {
      await updateTagCollection(selectedGroup.value, {
        name: newGroupName.value,
        type: "text",
      });
      newGroupName.value = "";
      getTagGroups();
      setupUserTags();
      handleGroupModalHide();

      inPageNotification(
        "tags.manage.heading.group",
        "tags.manage.action.group.update.success",
        "success"
      );
    };

    const handleEditTagSubmit = async () => {
      await updateTag(selectedTag.value, {
        value: newTagName.value,
        tagCollectionId: selectedGroup.value,
      });
      newTagName.value = "";
      getTagGroups();
      updateUserTags();
      handleTagModalHide();

      inPageNotification(
        "tags.manage.heading.tag",
        "tags.manage.action.tag.update.success",
        "success"
      );
    };

    const handleTagGroupMoveUp = () => {
      const index = getTagGroupOptions.value.findIndex(
        (group) => selectedGroup.value === group.value
      );
      selectedGroup.value =
        getTagGroupOptions.value[index - 1]?.value ?? selectedGroup.value;
    };
    const handleTagGroupMoveDown = () => {
      const index = getTagGroupOptions.value.findIndex(
        (group) => selectedGroup.value === group.value
      );
      selectedGroup.value =
        getTagGroupOptions.value[index + 1]?.value ?? selectedGroup.value;
    };
    const handleTagMoveDown = () => {
      const index = getTagOptions.value.findIndex(
        (tag) => selectedTag.value === tag.value
      );
      selectedTag.value =
        getTagOptions.value[index + 1]?.value ?? selectedTag.value;
    };
    const handleTagMoveUp = () => {
      const index = getTagOptions.value.findIndex(
        (tag) => selectedTag.value === tag.value
      );
      selectedTag.value =
        getTagOptions.value[index - 1]?.value ?? selectedTag.value;
    };

    onMounted(async () => {
      await getTagGroups();
      setupUserTags();
    });

    watch(props, () => {
      setupUserTags();
    });

    return {
      tagSelectOptions,
      selectedTags,
      showManageModal,
      showManageTagModal,
      showManageGroupModal,
      newGroupName,
      newTagName,
      newTagGroup,
      selectedGroup,
      selectedTag,
      handleTagChange,
      handleTagSearchChange,
      handleAddTagGroup,
      handleAddTag,
      handleModalHide,
      handleTagModalHide,
      handleGroupModalHide,
      updateNewGroupName,
      updateNewTagGroup,
      updateNewTagName,
      getValueForString,
      getTagOptions,
      getTagGroupOptions,
      handleTagGroupManageChange,
      handleTagManageChange,
      userHasPermission,
      handleDeleteGroup,
      handleDeleteTag,
      showDeleteGroupModal,
      showDeleteTagModal,
      handleDeleteModalHide,
      handleTagGroupMoveUp,
      handleTagGroupMoveDown,
      handleTagMoveUp,
      handleTagMoveDown,
      editMode,
      getSelectedGroup,
      getSelectedTag,
      handleEditGroup,
      handleEditTag,
      handleEditGroupSubmit,
      handleEditTagSubmit,
    };
  },
});
