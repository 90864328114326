import { PaginatedResult } from "@/interfaces/Pagination";
import { useLanguage } from "@/composables";
import store from "@/store";
import router from "@/router";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export interface HttpResponse<T> {
  code: number;
  id: string;
  messages: Array<string>;
  payload: {
    result?: T;
    results?: Array<T>;
    paginatedResults: PaginatedResult<T>;
  };
  status: string;
  timestamp: Date;
}

export interface HttpError {
  statusCode: number;
  error: string;
  code: string;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiResponse<T = any> = AxiosResponse<HttpResponse<T>>;

const httpClient: AxiosInstance = axios.create({
  baseURL: "/api/1.0/",
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthToken = () => store.getters.token?.value;

const authInterceptor = (config: AxiosRequestConfig) => {
  const auth = getAuthToken();
  if (auth) {
    config.headers["Authorization"] = `Bearer ${auth}`;
  }
  return config;
};

const errorInterceptor = async (error: AxiosError) => {
  if (!error.response) {
    return Promise.reject(error);
  }
  const { response } = error;
  switch (error.response.status) {

    case 401:
      await store.dispatch("logout");
      break;

    case 403:
      if (!store.getters.token || ((+new Date() - new Date(0).setUTCSeconds(store.getters.token.decoded.iat)) / 60 / 1000) > 15) {
        await store.dispatch("logout");
      }
      if (response.config && response.config.url === "/auth/refresh") {
        await store.dispatch("logout");
      } else if (response.config && response.config.url !== "/auth/reset-password/auth") {
        router.push({
          path: "/",
        });
      }

    // eslint-disable-next-line no-fallthrough
    default: {
      const { translateText } = useLanguage();

      let errorCode, message = "";
      if (response.data?.errorInfo) {
        const { details } = response.data?.errorInfo;
        const obj = details[0];
        errorCode = obj.errorCode;
        message = obj.message
      } else if (response.data) {
        errorCode = response.data.name;
        message = response.data.message;
      }

      switch (errorCode) {
        case "ERRAUTH000": {
          message = translateText(
            `sign-in.attempt.lockout`
          );
          break;
        }
        case "ERRAUTH014": {
          message = translateText(
            `sign-in.attempt.deactivated`
          );
          break;
        }
      }

      const err: HttpError = {
        statusCode: response.status,
        error: response.data?.errorString ? response.data?.errorString : message,
        code: errorCode,
        message,
      };
      return Promise.reject(err);
    }
  }
};

const responseInterceptor = (response: AxiosResponse) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }

  return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

httpClient.interceptors.request.use(authInterceptor);

export default httpClient;
