import { Model } from "./Model";
import { Address } from "./Address";

export class EmergencyContact extends Model<EmergencyContact> {
  private static readonly path: string = "/emergency-contact";

  public userId: string;
  public name: string;
  public relationship: string;
  public address: Address;
  public telCountryCode: string;
  public telNumber: string;
  public notes: string;
  public isPrimary: boolean;

  constructor({
    id = "",
    userId = "",
    name = "",
    relationship = "",
    address = new Address(),
    telCountryCode = "",
    telNumber = "",
    notes = "",
    isPrimary = false,
  }: Partial<EmergencyContact> = {}) {
    super(id, EmergencyContact, EmergencyContact.path);
    this.userId = userId;
    this.name = name;
    this.relationship = relationship;
    this.address = address;
    this.telCountryCode = telCountryCode;
    this.telNumber = telNumber;
    this.notes = notes;
    this.isPrimary = isPrimary;
  }

  public static async get(userId: string): Promise<Array<EmergencyContact>> {
    return await Model.getAllForUser<EmergencyContact>(
      userId,
      EmergencyContact.path,
      EmergencyContact
    );
  }
}
