
import { defineComponent } from "vue";
export default defineComponent({
  name: "drawer",
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
    customClass: {
      type: String,
      default: "",
      required: false,
    },
  },
});
