
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  emits: ["add-file"],
  props: {
    acceptString: {
      type: String,
    },
    name: {
      type: String,
      default: () => "upload",
    },
    disabled: Boolean,
    existingFile: Object as PropType<any>,
  },
  setup(_, { emit }) {
    const selectedFile = ref<File | null>(null);

    const onFileSelect = (event: Event) => {
      const el = event.target as HTMLInputElement;
      if (el.files && el.files.length) {
        selectedFile.value = el.files[0];
        emit("add-file", selectedFile.value);
      }
    };
    return { selectedFile, onFileSelect };
  },
});
