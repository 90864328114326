import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7903018c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: _normalizeClass(["table-row", {
      open: _ctx.isOpen,
      'has-error': _ctx.hasError,
      deactivated: _ctx.isDeactivated,
      active: _ctx.isActive,
      selected: _ctx.isSelected,
    }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _renderSlot(_ctx.$slots, "parent", {}, undefined, true),
      (!_ctx.disableDropdown)
        ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, {
              class: "row-icon",
              icon: _ctx.rowIcon
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("tr", {
      class: _normalizeClass(["table-row-dropdown", { open: _ctx.isOpen, 'has-error': _ctx.hasError }])
    }, [
      _renderSlot(_ctx.$slots, "child", {}, undefined, true)
    ], 2)
  ], 64))
}