
import {
  defineComponent,
  PropType,
  computed,
  ComputedRef,
  inject,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import { Hotspot } from "@/models";
import Grid from "@/components/track/manager/track-components/Grid.vue";
import Cubic from "@/components/track/manager/track-components/Cubic.vue";
import Point from "@/components/track/manager/track-components/Point.vue";

import { Theme } from "@/models/Style";

export default defineComponent({
  components: {
    Grid,
    Cubic,
    Point,
  },
  name: "SVG",
  emits: [
    "setDraggedPoint",
    "setDraggedQuadratic",
    "setDraggedCubic",
    "handleMouseMove",
    "addPoint",
    "doubleClick",
  ],
  props: {
    hotspots: {
      type: Array as PropType<Hotspot[]>,
      default: () => [],
    },
    path: {
      type: String,
    },
    w: {
      type: Number,
    },
    h: {
      type: Number,
    },
    scale: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
    },
    grid: {
      type: Object,
    },
    points: {
      type: Array,
    },
    showGrid: {
      type: Boolean,
      default: true,
    },
    activePoint: {
      type: Number,
    },
    viewBox: {
      type: String,
    },
    edit: Boolean,
    completed: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const theme = inject<ComputedRef<Theme>>("theme");

    const background = computed(() => {
      const style: Record<string, string> = {};

      //Only change background on the track
      if (theme) {
        if (theme.value.backgroundImage.track) {
          style[
            "background-image"
          ] = `url(${theme.value.backgroundImage.track.url})`;
        }
      }

      return style;
    });

    const setDraggedPoint = (index) => {
      emit("setDraggedPoint", index);
    };

    const setDraggedQuadratic = (index) => {
      emit("setDraggedQuadratic", index);
    };

    const setDraggedCubic = ({ index, anchor }) => {
      emit("setDraggedCubic", { index, anchor });
    };

    const handleMouseMove = (e) => {
      emit("handleMouseMove", e);
    };

    const addPoint = (e) => {
      emit("addPoint", e);
    };

    const handleDoubleClick = (index) => {
      emit("doubleClick", index);
    };

    const getClass = (index, point) => {
      const hs = props.hotspots[index]?.hotspotProgressObjects.length
        ? props.hotspots[index]?.hotspotProgressObjects[0].progressPercentage ??
          0
        : 0;
      return (
        "ad-PointGroup" +
        (index === 0 ? " ad-PointGroup--first" : "") +
        (props.activePoint === index ? " is-active" : "") +
        (props.edit ? " edit" : "") +
        (hs === 100 ? " completed" : "")
      );
    };

    return {
      background,
      setDraggedPoint,
      setDraggedQuadratic,
      setDraggedCubic,
      handleMouseMove,
      addPoint,
      getClass,
      handleDoubleClick,
    };
  },
});
