
import { defineComponent, ref, watch } from "vue";
import debounce from "lodash/debounce";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    searchOpen: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const searchTerm = ref("");

    const updateSearchTerm = debounce(() => {
      context.emit("filter-search", searchTerm.value);
    }, 600);

    const closeSearch = () => context.emit("close-search");

    const focusInput = () => document.getElementById(props.id)?.focus();

    watch(props, () => {
      if (props?.searchText !== undefined) {
        searchTerm.value = props.searchText;
      }
    });

    return {
      searchTerm,
      updateSearchTerm,
      closeSearch,
      focusInput,
    };
  },
});
