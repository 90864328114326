
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { User } from "@/models";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ValidationArgs } from "@vuelidate/core";
import { required, minLength, sameAs, helpers } from "@vuelidate/validators";
import ProgressBar from "@/components/ui/ProgressBar.vue";

import { useNotification } from "@/composables/useNotification";

import FormInput from "@/components/form/FormInput.vue";
import Button from "@/components/Button.vue";
import { getCookie, setCookie } from "@/router";
import Form from "@/components/Form.vue";
import { changePassword } from "@/api/auth.api";

export default defineComponent({
  components: {
    ProgressBar,
    Button,
    FormInput,
    Form,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    userIsLoading: Boolean,
    isNewUser: Boolean,
    isAuthenticatedUser: Boolean,
    config: Object,
  },
  emits: ["created", "update-page-title", "update-page-subtitle", "delete"],

  setup() {
    const store = useStore();
    const router = useRouter();

    const { inPageNotification } = useNotification();

    const tenant = ref();

    const isValid = ref(false);

    const model = ref({
      newPassword: "",
      currentPassword: "",
      confirmNewPassword: "",
    });

    const passwordVisible = ref({
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });

    const passwordStrength = ref(0);

    const passwordStrengthClass = computed(() => {
      if (passwordStrength.value == 100) {
        return "secure";
      } else if (passwordStrength.value < 100 && passwordStrength.value >= 80) {
        return "good";
      } else if (passwordStrength.value < 80 && passwordStrength.value >= 50) {
        return "average";
      }
      return "insecure";
    });

    const formUpdated = (form) => {
      const totalChecks = 5;
      const strengthSegment = Math.round(100 / totalChecks);
      let strength = 0;
      if (form.newPassword && form.newPassword.length >= 10) {
        strength += strengthSegment;
        strength += form.newPassword.match(/[\d]/) ? strengthSegment : 0;
        strength += form.newPassword.match(/[a-z]/) ? strengthSegment : 0;
        strength += form.newPassword.match(/[A-Z]/) ? strengthSegment : 0;
        strength += form.newPassword.match(/[!@#$%^&*-]/)
          ? 100 - strengthSegment * totalChecks + strengthSegment
          : 0;
      }
      passwordStrength.value = strength;
      rules.value["confirmNewPassword"]["sameAsNewPassword"] =
        helpers.withMessage(
          "Passwords do not match",
          sameAs(model.value.newPassword)
        );
    };

    const errors = reactive<{ [K: string]: string }>({});
    const rules = ref<ValidationArgs>({
      currentPassword: {
        required: helpers.withMessage(
          "Please enter a valid password",
          required
        ),
      },
      newPassword: {
        required: helpers.withMessage(
          "Please enter a valid password",
          required
        ),
        minLength: helpers.withMessage(
          "Your password must be at least 10 characters long.",
          minLength(10)
        ),
      },
      confirmNewPassword: {
        sameAsNewPassword: helpers.withMessage(
          "Passwords do not match",
          sameAs(model.value.newPassword)
        ),
      },
    });
    const passwordIcon = (type: string) =>
      passwordVisible.value[type] ? "eye" : "eye-slash";

    const passwordType = (type: string) =>
      passwordVisible.value[type] ? "text" : "password";

    const togglePasswordVisibility = (type: string) =>
      (passwordVisible.value[type] = !passwordVisible.value[type]);

    const resetForm = () => {
      model.value = {
        newPassword: "",
        currentPassword: "",
        confirmNewPassword: "",
      };
      passwordVisible.value = {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      };
      passwordStrength.value = 0;
    };

    const passwordChangeForced = computed(() => store.getters.changePassword);
    const handleSubmit = async () => {
      try {
        const { newPassword, currentPassword, confirmNewPassword } =
          model.value;

        await changePassword({
          oldPassword: currentPassword,
          newPassword: {
            password: newPassword,
            passwordConfirm: confirmNewPassword,
          },
        });
        inPageNotification(
          "auth.password-changed-title",
          "auth.password-changed",
          "success"
        );
        resetForm();
        if (passwordChangeForced.value) {
          store.dispatch("setUserPasswordChange", false);
          const returnUrl = getCookie("ReturnUrl");
          setCookie("ReturnUrl", "", -1);

          if (returnUrl && !returnUrl.includes("signin")) {
            router.push(decodeURIComponent(returnUrl));
          } else {
            router.push("/");
          }
        }
      } catch (err: any) {
        let body = "errors.unexpected";

        if (err?.message.length > 0) {
          body = err?.message;

          inPageNotification("auth.error-changing-password", body);
        }
      }
    };

    onMounted(async () => {
      tenant.value = store.getters.currentTenant;
    });

    return {
      model,
      errors,
      rules,
      passwordIcon,
      passwordType,
      passwordVisible,
      isValid,
      togglePasswordVisibility,
      handleSubmit,
      passwordStrength,
      passwordStrengthClass,
      formUpdated,
      passwordChangeForced,
    };
  },
});
