import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2ff508"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = { class: "form-question-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "form-question-image-title" }
const _hoisted_5 = {
  key: 0,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        class: "form-img-responsive",
        src: _ctx.options?.source
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.options?.label), 1),
    (_ctx.options?.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.error
          }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ]))
}