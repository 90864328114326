
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useLanguage } from "@/composables";
import { usePermissionProvider } from "@/plugins/permission";
import { subDays, subMonths, format } from "date-fns";
import {
  getUsersPaginated,
  toggleUserDeactivationStatus,
  postCSVFile,
} from "@/api/users.api";
import { getAllGroups } from "@/api/groups.api";
import { UserTableData as IUserTableData } from "@/interfaces/UserTableData.interface";
import { DateFilter } from "@/interfaces/ui/DateFilter.interface";
import { Chip as IChip } from "@/interfaces/ui/Chip.interface";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import FilterMenu from "@/components/FilterMenu.vue";
import ListMenu from "@/components/ListMenu.vue";
import MobileBottomBar from "@/components/MobileBottomBar.vue";
import MobileSideMenu from "@/components/MobileSideMenu.vue";
import Modal from "@/components/Modal.vue";
import Search from "@/components/Search.vue";
import TableCell from "@/components/TableCell.vue";
import TableDropdown from "@/components/TableDropdown.vue";
import TableList from "@/components/TableList.vue";
import UserCSVUpload from "@/components/users/UserCSVUpload.vue";
import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  name: "UsersList",
  components: {
    AuthenticatedLayout,
    Button,
    ButtonBar,
    Checkbox,
    FilterMenu,
    ListMenu,
    Modal,
    MobileBottomBar,
    MobileSideMenu,
    Search,
    TableCell,
    TableDropdown,
    TableList,
    UserCSVUpload,
  },
  emits: ["close-warning", "change-page"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const { translateText } = useLanguage();
    const { userHasPermission } = usePermissionProvider();
    const currentPage = ref<number>(1);
    const rowCount = ref<number>(store.getters.pageSize);
    const userData = ref<IUserTableData[]>([]);
    const totalUsers = ref<number>(0);
    const groups = ref<{ id: number; active: boolean; name: string }[]>([]);
    const userStatus = ref<{ id: string; active: boolean; name: string }[]>([
      {
        id: "active",
        active: true,
        name: "Active",
      },
      {
        id: "inactive",
        active: false,
        name: "Inactive",
      },
    ]);
    const filterTitle = ref<string>("Filter");
    const totalSelectedUsers = ref<number>(0);
    const tableCustomClass = "users-table";
    const globalModal = computed(() => store.getters.globalModal);
    const activeSortIndex = ref<number>(1);
    const currentSort = ref<string>("name");
    const sortOrder = ref<string>("ASC");
    const search = ref<string>("");
    const filteredData = ref<IUserTableData[]>([]);
    const mobileDateFiltersActive = ref<boolean>(false);
    const inSubmenu = ref<boolean>(false);
    const activeFilterIndex = ref<number | null>(null);
    const selectedUserCheckboxes = ref<string[]>([]);
    const deselectedUserCheckboxes = ref<string[]>([]);
    const selectAllUsers = ref<boolean>(false);
    const colsFeatured = [0, 1, 5];
    const rowActiveUser = ref<number>(-1);
    const sortActiveIndex = ref<number>(0);
    const showCSVUploadModal = ref<boolean>(false);
    const { inPageNotification } = useNotification();
    const userActionsChip = ref<IChip>({
      id: 0,
      items: [
        {
          active: false,
          id: "activate",
          title: "Activate",
        },
        {
          active: false,
          id: "deactivate",
          title: "Deactivate",
        },
      ],
      name: "With Selected",
      selectAll: false,
      show: false,
      dropdownType: "action-list",
    });

    const dateFilters = ref<{ from: string | null; to: string | null }>({
      from: "",
      to: "",
    });

    const dateFilterButtons = ref([
      {
        id: 0,
        name: "Today",
        active: false,
        translateKey: "common.today",
      },
      {
        id: 1,
        name: "Yesterday",
        active: false,
        translateKey: "common.yesterday",
      },
      {
        id: 2,
        name: "Past Month",
        active: false,
        translateKey: "common.past-3-days",
      },
      {
        id: 3,
        name: "Past 3 Months",
        active: false,
        translateKey: "past-3-months",
      },
    ]);

    const buttonBarData = ref({
      sortOpen: false,
      filterOpen: false,
      searchOpen: false,
    });
    const filters = ref<any[]>([
      {
        id: 0,
        name: "form.groups",
        items: [
          {
            id: "eb66ddc5-5242-4fe6-b20f-5bc3ae7c0b64",
            active: false,
            name: "User",
          },
          {
            id: "9b82e3d6-3f6c-4ca5-b444-f49ab1cb4c8a",
            active: false,
            name: "hr",
          },
          {
            id: "73deecf8-d552-48a5-a39d-f7c6bfe173c2",
            active: false,
            name: "admin",
          },
          {
            id: "a5a6f2bc-f14e-4661-8d32-b5067f42e6b1",
            active: false,
            name: "manager",
          },
        ],
        show: false,
        selectAll: false,
        dropdownType: "checklist",
      },
      {
        id: 1,
        name: "form.cohort",
        items: groups.value,
        show: false,
        selectAll: false,
        dropdownType: "checklist",
      },
      {
        id: 2,
        name: "form.status",
        items: userStatus.value,
        show: false,
        selectAll: false,
        dropdownType: "checklist",
      },
    ]);

    const datePickers = ref<DateFilter[]>([
      {
        customClass: "date-filter",
        id: "date-picker-from",
        name: "from",
        label: "From",
        value: null,
        error: "",
      },
      {
        customClass: "date-filter",
        id: "date-picker-to",
        name: "to",
        label: "To",
        value: null,
        error: "",
      },
    ]);
    const filtersSelected = ref<{ name: string; values: string[] }[]>([
      {
        name: "Roles",
        values: [],
      },
      {
        name: "Cohort",
        values: [],
      },
      {
        name: "Status",
        values: ["active"],
      },
    ]);
    const sideMenuItems = ref<any[]>(filters.value);
    const headings = ref([
      {
        id: 0,
        title: "Ascending",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 1,
        title: "Descending",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 2,
        title: "Last Updated",
        sortOrder: "",
        active: false,
        mobileSortable: true,
      },
      {
        id: 3,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        dataType: "checkbox",
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
      {
        id: 4,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        desktop: true,
        notShownInMobile: true,
      },
      {
        id: 5,
        title: "Name",
        sortOrder: "asc",
        sortable: true,
        mobileSortable: true,
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
      {
        id: 6,
        title: "Role",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        desktop: true,
      },
      {
        id: 7,
        title: "Cohort(s)",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        desktop: true,
      },
      {
        id: 8,
        title: "Progress",
        sortOrder: "",
        sortable: true,
        mobileSortable: true,
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
      {
        id: 9,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        desktop: true,
      },
    ]);
    const mobileSortItems = ref(
      headings.value.filter((heading) => heading.mobileSortable)
    );

    const desktopHeadings = ref(
      headings.value.filter((heading) => heading.desktop)
    );

    let headingsData = ref({
      filterOpen: false,
      colsFeatured: [0, 2, 6],
      rowActiveUser: -1,
      sortActiveIndex: 0,
    });

    const handleClickRow = function (id: number) {
      if (headingsData.value.rowActiveUser !== id) {
        headingsData.value.rowActiveUser = id;
      } else {
        headingsData.value.rowActiveUser = -1;
      }
    };

    const handleClickCell = (id: string, rowId: number) => {
      if (window.innerWidth >= 768) {
        navigateToUser(id);
      } else {
        handleClickRow(rowId);
      }
    };

    /* Placeholder data for developing Warning component */
    const warning = ref({
      icon: {
        img: require("@/assets/img/warning.svg"),
        alt: "Warning",
      },
      title: "prompts.user-limit.title",
      subtitle: "980 / 1000",
      text: "prompts.user-limit.message",
      active: false,
    });

    const requestPaginatedUsers = async (
      name = "",
      sortCol?: string,
      order?: string
    ) => {
      if (name) {
        buttonBarData.value.filterOpen = false;

        filtersSelected.value.forEach((filter) => {
          filter.values.splice(0);
        });
        filters.value.forEach((filter) => {
          filter.items.forEach((item) => {
            item.active = false;
          });
        });
      }
      return await getUsersPaginated(
        currentPage.value,
        rowCount.value,
        name,
        sortCol,
        order,
        filtersSelected.value,
        dateFilters.value
      );
    };

    const getRoleName = (role) => {
      const roleName = `${role.name.charAt(0).toUpperCase()}${role.name.slice(
        1
      )}`;
      const translatedName = translateText(`role.name.${role.name}`);
      return translatedName != `role.name.${role.name}`
        ? translatedName
        : roleName;
    };

    const setAllUsers = (users) => {
      userData.value = [];
      users.forEach((user, index: number) => {
        if (user?.email && user.email.includes("uber-admin@")) {
          return;
        }
        const id = user.id as string;
        const firstName = user.firstName;
        const surname = user.surname;
        const jobTitle = user.position;
        const department = user.department;
        const avatar = user?.imageContents[0]?.url;
        const roles: string[] = [];

        user.roles?.forEach((role) => {
          roles.push(getRoleName(role));
        });

        let cohortsBrief: string[] = [];
        const cohorts: string[] = [];
        // For each cohort, push the name of the cohort to the cohorts array
        user.groups.forEach((group) => {
          group.textItems.forEach((ti) => {
            const cohort = ti.data;
            cohorts.push(cohort);
          });
        });
        if (cohorts.length > 3) {
          cohortsBrief = [cohorts.length + " items..."];
        }
        const progressCompleted = user.progressPercentage;
        const deactivated = user.isDeactivated ? true : false;

        const userRow = {
          rowId: index,
          id,
          deactivated: deactivated,
          cols: [
            {
              id: 0,
              colId: 3,
              dataType: "checkbox",
              data: {
                selected: false,
              },
            },
            {
              id: 1,
              colId: 4,
              dataType: "avatar",
              data: {
                image: avatar,
                alt: `Portrait photo of ${firstName} ${surname}`,
                avatarText: `${firstName[0]}${surname[0]}`,
                status: true,
              },
            },
            {
              id: 2,
              colId: 5,
              dataType: "user",
              data: {
                firstName: firstName,
                lastName: surname,
                jobTitle,
              },
            },
            {
              id: 3,
              colId: 6,
              dataType: "list",
              data: roles,
            },
            {
              id: 4,
              colId: 7,
              dataType: "list",
              data: cohortsBrief.length ? cohortsBrief : cohorts,
              dataInfo: cohortsBrief.length ? cohorts : "",
            },
            {
              id: 5,
              colId: 8,
              dataType: "progress",
              data: {
                progress: progressCompleted,
              },
            },
          ],
        };

        // Push each row to rows array on each iteration
        userData.value.push(userRow);
      });
    };

    // Change number of rows per page
    const changeRowCount = async (newRowCount: number) => {
      store.dispatch("setPageSize", newRowCount);
      rowCount.value = newRowCount;
      currentPage.value = 1;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
    };

    // Change page number
    const changePage = async (targetPage: number) => {
      currentPage.value = targetPage;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);

      if (selectAllUsers.value) {
        userData.value.forEach((user) => {
          selectedUserCheckboxes.value.push(user.id);
        });
      }
    };

    // Searching
    const openSearch = () => {
      buttonBarData.value.searchOpen = true;
      const input = document.getElementById("search-bar");
      if (input) {
        input.focus();
      }
    };

    const filterSearch = async (searchTerm: string) => {
      search.value = searchTerm;
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    const closeSearch = () => {
      buttonBarData.value.searchOpen = false;
    };

    // Sorting
    const handleClickSort = async (sort: { title: string; id: number }) => {
      const previousActiveColIndex = headings.value.findIndex(
        (val) => val.active
      );
      const clickedSortColIndex = headings.value.findIndex(
        (val) => val.id === sort.id
      );

      if (clickedSortColIndex >= 0) {
        headings.value[clickedSortColIndex].active = true;

        // Determine ascending or descending sort order
        if (sort.title.toLowerCase() === "ascending") {
          currentSort.value = "name";
          headings.value[clickedSortColIndex].sortOrder = "asc";
        } else if (sort.title.toLowerCase() === "descending") {
          currentSort.value = "name";
          headings.value[clickedSortColIndex].sortOrder = "desc";
        } else {
          if (
            sort.title.toLowerCase() === currentSort.value.toLowerCase() &&
            headings.value[clickedSortColIndex].sortOrder === "asc"
          ) {
            headings.value[clickedSortColIndex].sortOrder = "desc";
          } else if (
            sort.title.toLowerCase() === currentSort.value.toLowerCase() &&
            headings.value[clickedSortColIndex].sortOrder === "desc"
          ) {
            headings.value[clickedSortColIndex].sortOrder = "asc";
          } else {
            headings.value[clickedSortColIndex].sortOrder = "asc";
          }
          currentSort.value = sort.title.toLowerCase();
        }
        if (
          previousActiveColIndex >= 0 &&
          clickedSortColIndex !== previousActiveColIndex
        ) {
          headings.value[previousActiveColIndex].active = false;
          headings.value[previousActiveColIndex].sortOrder = "";
        }
      }

      sortOrder.value = headings.value[clickedSortColIndex].sortOrder;
      activeSortIndex.value = sort.id;

      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);

      buttonBarData.value.sortOpen = false;
    };

    const sortMenuItems = ref([
      {
        name: "filter.ascending",
        active: true,
      },
      {
        name: "filter.descending",
        active: false,
      },
      {
        name: "filter.last-updated",
        active: false,
      },
      {
        name: "form.name",
        active: false,
      },
      {
        name: "form.name",
        active: false,
      },
      {
        name: "progress.title",
        active: false,
      },
    ]);

    const toggleSort = () => {
      buttonBarData.value.sortOpen = !buttonBarData.value.sortOpen;
    };

    const toggleFilter = () => {
      buttonBarData.value.filterOpen = !buttonBarData.value.filterOpen;
    };

    const toggleChip = (index: number) => {
      sideMenuItems.value.map((item) => {
        if (item.id === index) {
          item.show = !item.show;
          if (item.show) {
            activeFilterIndex.value = index;
          } else {
            activeFilterIndex.value = null;
          }
        } else {
          item.show = false;
        }
      });
    };

    const clickFilterItem = (filter: {
      filterIndex: number;
      itemId: string;
    }) => {
      sideMenuItems.value.some((f) => {
        if (filter.itemId === f.id) {
          f.active = !f.active;
          filterTitle.value = f.name;
          activeFilterIndex.value = filter.filterIndex;
          inSubmenu.value = !inSubmenu.value;
          return;
        }
      });
    };

    // select all toggle for filters
    const selectAll = (filterId: number) => {
      filters.value?.map((filter) => {
        if (filter.id === filterId) {
          if (!filter.selectAll) {
            filter.items.forEach(
              (item: { id: number; active: boolean; name: string }) => {
                item.active = true;
              }
            );
            filter.selectAll = true;
          } else {
            filter.items.forEach(
              (item: { id: number; active: boolean; name: string }) => {
                item.active = false;
              }
            );
            filter.selectAll = false;
          }
        }
      });
      buildFilterQuery();
    };

    const goBack = () => {
      sideMenuItems.value.map((item) => {
        item.active = false;
      });
      inSubmenu.value = !inSubmenu.value;
      mobileDateFiltersActive.value = false;
      filterTitle.value = "Filter";
    };

    const clickDateFilter = () => {
      inSubmenu.value = !inSubmenu.value;
      mobileDateFiltersActive.value = !mobileDateFiltersActive.value;
      filterTitle.value = "Date";
    };

    const setDatePickerValues = (from: Date | null, to: Date | null) => {
      if (from && to) {
        const frontendFormattedFrom = format(from, "yyyy-MM-dd");
        const frontendFormattedTo = format(to, "yyyy-MM-dd");
        const backendFormattedFrom = format(from, "yyyy-MM-dd HH:mm:ss");
        const backendFormattedTo = format(to, "yyyy-MM-dd HH:mm:ss");

        datePickers.value[0].value = frontendFormattedFrom;
        datePickers.value[1].value = frontendFormattedTo;
        dateFilters.value.from = backendFormattedFrom;
        dateFilters.value.to = backendFormattedTo;
      } else {
        datePickers.value[0].value = null;
        datePickers.value[1].value = null;
        dateFilters.value.from = null;
        dateFilters.value.to = null;
      }
    };

    const filterDateByButton = async (val: string) => {
      let reset = false;

      const previousBtn = dateFilterButtons.value.find(
        (button) => button.active
      );
      const btn = dateFilterButtons.value.find((button) => button.name === val);

      if (previousBtn) {
        previousBtn.active = false;
        // set new button to active state
        if (btn && previousBtn.name !== val) {
          btn.active = !btn.active;
        } else {
          reset = true;
        }
      } else {
        // set button to active state if there is no previous
        if (btn) {
          btn.active = !btn.active;
        }
      }

      if (btn) {
        let fromDate = new Date();
        if (btn.name === "Yesterday") {
          const yesterday = subDays(fromDate, 1);
          fromDate = yesterday;
        } else if (btn.name === "Past Month") {
          const lastMonth = subMonths(fromDate, 1);
          fromDate = lastMonth;
        } else if (btn.name === "Past 3 Months") {
          const threeMonthsAgo = subMonths(fromDate, 3);
          fromDate = threeMonthsAgo;
        }
        const toDate = new Date();

        // set hours minutes and seconds
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);

        if (reset) {
          setDatePickerValues(null, null);
        } else {
          setDatePickerValues(fromDate, toDate);
        }
      }
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    const filterDateByInput = async (data: {
      value: string;
      field: string;
    }) => {
      const disableBtn = dateFilterButtons.value.find((btn) => btn.active);
      if (disableBtn) {
        disableBtn.active = false;
      }

      if (data.field === "from") {
        datePickers.value[0].value = data.value;
      } else {
        datePickers.value[1].value = data.value;
      }

      // validation
      if (datePickers.value[0].value && datePickers.value[1].value) {
        const fromDate = new Date(datePickers.value[0].value);
        const toDate = new Date(datePickers.value[1].value);
        const fromTs = fromDate.getTime();
        const toTs = toDate.getTime();

        // set error if to date occurs before from date
        if (fromTs > toTs) {
          datePickers.value[1].error =
            "To date must not occur before the from date";
        } else {
          datePickers.value[1].error = "";
        }

        // set hours minutes and seconds
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);

        if (
          !datePickers.value[1].error &&
          datePickers.value[0].value &&
          datePickers.value[1].value
        ) {
          setDatePickerValues(fromDate, toDate);
        }

        const response = await requestPaginatedUsers(
          search.value,
          currentSort.value,
          sortOrder.value
        );
        setAllUsers(response.data.payload.paginatedResults.rows);
        totalUsers.value = response.data.payload.paginatedResults.details.total;
      }
    };

    const selectFilter = (filter: { filterIndex: number; itemId: string }) => {
      search.value = "";
      filters.value?.map((f) => {
        if (f.id === activeFilterIndex.value) {
          f.items.map((item) => {
            if (item.id === filter.itemId) {
              item.active = !item.active;
              if (item.active) {
                if (activeFilterIndex.value !== null) {
                  filtersSelected.value[activeFilterIndex.value].values.push(
                    item.id
                  );
                }
              } else {
                if (activeFilterIndex.value !== null) {
                  const index = filtersSelected.value[
                    activeFilterIndex.value
                  ].values.indexOf(item.id);

                  filtersSelected.value[activeFilterIndex.value].values.splice(
                    index,
                    1
                  );
                }
              }
              return;
            } else {
              return;
            }
          });
        } else {
          return;
        }
      });
      if (activeFilterIndex.value !== null) {
        if (
          filtersSelected.value[activeFilterIndex.value].values.length <
          filters.value[activeFilterIndex.value].items.length
        )
          filters.value[activeFilterIndex.value].selectAll = false;
      }
      buildFilterQuery();
    };

    const buildFilterQuery = async () => {
      const filterQuery: { name: string; values: string[] }[] = [];
      filters.value?.map((f) => {
        let filterName: string = f.name;
        let filterVal = () => {
          const itemsArr: string[] = [];
          f.items.map((item) => {
            if (item.active) {
              itemsArr.push(item.id);
            }
          });
          return itemsArr;
        };
        filterQuery.push({ name: filterName, values: filterVal() });
      });
      filterQuery.forEach((filter, index) => {
        if (filtersSelected.value[index]) {
          filtersSelected.value[index].values = filter.values;
        }
      });
      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    // toggle select all users
    const toggleAll = () => {
      selectAllUsers.value = !selectAllUsers.value;

      if (selectAllUsers.value) {
        userData.value.forEach((user) => {
          if (!selectedUserCheckboxes.value.includes(user.id)) {
            selectedUserCheckboxes.value.push(user.id);
          }
        });
      } else {
        selectedUserCheckboxes.value = [];
        deselectedUserCheckboxes.value = [];
      }

      updateTotalSelectedUsers();
    };

    const toggleCheckbox = (userId: string) => {
      if (!selectedUserCheckboxes.value.includes(userId)) {
        selectedUserCheckboxes.value.push(userId);
      } else {
        if (!selectAllUsers.value) {
          const index = selectedUserCheckboxes.value.indexOf(userId);
          if (index >= 0) {
            selectedUserCheckboxes.value.splice(index, 1);
          }
        } else {
          if (deselectedUserCheckboxes.value.includes(userId)) {
            const deselectedUserIndex =
              deselectedUserCheckboxes.value.indexOf(userId);
            if (deselectedUserIndex >= 0) {
              deselectedUserCheckboxes.value.splice(deselectedUserIndex, 1);
            }
          } else {
            deselectedUserCheckboxes.value.push(userId);
          }
        }
      }
      updateTotalSelectedUsers();
    };

    const updateTotalSelectedUsers = () => {
      const selectedUsers = userData.value.filter((user) =>
        selectedUserCheckboxes.value.includes(user.id)
      );

      totalSelectedUsers.value = selectedUsers.length;
    };

    const changeActivationStatus = async (action: string) => {
      const currentUser = store.getters.currentUser;
      const currentUserId = currentUser.id;

      // change find to filter when method when backend allows multiple users to be deactivated
      if (selectedUserCheckboxes.value?.length > 0) {
        await toggleUserDeactivationStatus(
          selectedUserCheckboxes.value,
          search.value,
          currentUserId,
          action,
          selectAllUsers.value,
          deselectedUserCheckboxes.value
        );
        const response = await requestPaginatedUsers(
          search.value,
          currentSort.value,
          sortOrder.value
        );
        setAllUsers(response.data.payload.paginatedResults.rows);
        selectedUserCheckboxes.value = [];
        deselectedUserCheckboxes.value = [];
        selectAllUsers.value = false;
      }
    };

    const clearTableSelection = () => {
      selectedUserCheckboxes.value = [];
      deselectedUserCheckboxes.value = [];
      selectAllUsers.value = false;
    };

    const toggleActionsChip = () =>
      (userActionsChip.value.show = !userActionsChip.value.show);

    const clickAction = (title: string) =>
      changeActivationStatus(title.toLowerCase());

    const navigateToUser = (userId: string) => {
      router.push(`/users/${userId}`);
    };

    const handleClickUser = (e: Event, id: number) => {
      rowActiveUser.value = id;
    };
    const closeWarning = () => {
      warning.value.active = false;
    };

    const clearAllFilters = async () => {
      dateFilters.value.from = null;
      dateFilters.value.to = null;
      dateFilterButtons.value.forEach((btn) => {
        btn.active = false;
      });
      datePickers.value.forEach((dp) => (dp.value = null));
      filtersSelected.value.forEach((filter) => {
        filter.values.splice(0);
      });
      filters.value.forEach((filter) => {
        filter.items.forEach((item) => {
          item.active = false;
        });
      });

      const response = await requestPaginatedUsers(
        search.value,
        currentSort.value,
        sortOrder.value
      );
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;
    };

    const navigateToCreate = () => router.push("/users/create");
    const navigateToCSVUpload = () => (showCSVUploadModal.value = true);

    // determine whether a number is odd
    const isOdd = (num: number) => Math.abs(num % 2) == 1;

    const showCSVuploadButton = ref(false);

    onMounted(async () => {
      const response = await requestPaginatedUsers();
      setAllUsers(response.data.payload.paginatedResults.rows);
      totalUsers.value = response.data.payload.paginatedResults.details.total;

      const groupRes = await getAllGroups();
      groupRes.data.payload.results.forEach((group: any) => {
        groups.value.push({
          id: group.id,
          active: false,
          name: group.textItems[0].data,
        });
      });

      if (store.state.isMobile) {
        activeSortIndex.value = 0;
        headings.value[0].active = true;
      } else {
        activeSortIndex.value = 5;
        headings.value[5].active = true;
      }
      showCSVuploadButton.value =
        userHasPermission("user:create") &&
        store.getters.featureConfigs.find((fc) => fc.feature === "CSVUpload")
          ?.config;
    });

    const fileModel = ref({
      file: null,
    });

    const uploadCSV = async () => {
      const files = {};
      if (fileModel.value.file) {
        const file = fileModel.value.file;

        files["file"] = file;
        const formData: FormData = new FormData();
        formData.append("file", files["file"], files["file"].name ?? "");

        try {
          await postCSVFile(formData);
          inPageNotification(
            "Success!",
            "Document has been successfully uploaded",
            "success"
          );
          showCSVUploadModal.value = false;
        } catch (err) {
          console.log(err);
          inPageNotification("Error!", "Could not save file", "error");
        }
      }
    };

    return {
      buttonBarData,
      warning,
      userData,
      currentPage,
      changePage,
      changeRowCount,
      totalUsers,
      rowCount,
      openSearch,
      closeSearch,
      filterSearch,
      filteredData,
      handleClickSort,
      toggleSort,
      toggleFilter,
      desktopHeadings,
      mobileSortItems,
      tableCustomClass,
      filters,
      search,
      groups,
      userStatus,
      toggleChip,
      mobileDateFiltersActive,
      clickDateFilter,
      selectFilter,
      filterDateByButton,
      filterDateByInput,
      clickFilterItem,
      sideMenuItems,
      selectAll,
      goBack,
      filterTitle,
      globalModal,
      toggleAll,
      toggleCheckbox,
      totalSelectedUsers,
      changeActivationStatus,
      inSubmenu,
      activeFilterIndex,
      selectedUserCheckboxes,
      deselectedUserCheckboxes,
      selectAllUsers,
      datePickers,
      dateFilterButtons,
      clearTableSelection,
      navigateToUser,
      colsFeatured,
      rowActiveUser,
      sortActiveIndex,
      handleClickUser,
      closeWarning,
      clearAllFilters,
      navigateToCreate,
      userActionsChip,
      toggleActionsChip,
      clickAction,
      headingsData,
      isOdd,
      handleClickCell,
      navigateToCSVUpload,
      showCSVUploadModal,
      fileModel,
      uploadCSV,
      showCSVuploadButton,
      userHasPermission,
    };
  },
});
