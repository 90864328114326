
import { defineComponent, PropType } from "vue";
import { Reference } from "@/models";
import Checkbox from "@/components/form/Checkbox.vue";

export default defineComponent({
  components: {
    Checkbox,
  },
  props: {
    reference: {
      type: Object as PropType<Reference>,
    },
    userReference: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "delete"],
  setup() {
    return {};
  },
});
