import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0079c764"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled", "accept"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: 'file-upload-' + _ctx.name
    }, [
      _createVNode(_component_TranslatedText, { path: "setup.welcome.choose-file" })
    ], 8, _hoisted_2),
    _createElementVNode("input", {
      id: 'file-upload-' + _ctx.name,
      name: 'file-upload-' + _ctx.name,
      type: "file",
      disabled: _ctx.disabled,
      accept: _ctx.acceptString,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
      class: "button"
    }, null, 40, _hoisted_3),
    (_ctx.selectedFile || _ctx.existingFile)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.selectedFile
        ? _ctx.selectedFile.name
        : _ctx.existingFile
        ? _ctx.existingFile.name
          ? _ctx.existingFile.name
          : _ctx.existingFile.fileName
        : ""), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createVNode(_component_TranslatedText, { path: "setup.welcome.no-file-selected" })
        ]))
  ]))
}