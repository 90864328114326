import httpClient from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const httpPostCreateHotspot = async (
  title: string,
  description: string,
  type: string,
  groupId: string,
  trackId: string,
  posX: number,
  posY: number,
  labelOrientation = "bottom",
  isMandatory = true
): Promise<AxiosResponse<any>> =>
  await httpClient.post("/hotspots/create", {
    title,
    description,
    type,
    groupId,
    trackId,
    posX,
    posY,
    labelOrientation,
    isMandatory,
  });

export const updateHotspotPosition = async (
  hotspotId: string,
  trackId: string,
  posX: string,
  posY: string,
  labelOrientation = "bottom",
  isMandatory = true
): Promise<AxiosResponse<any>> =>
  await httpClient.put("/hotspots", {
    hotspotId,
    groupId: trackId,
    posX,
    posY,
    labelOrientation,
    isMandatory,
  });

export const updateActionOrder = async (
  orderedActionIds: string[]
): Promise<AxiosResponse<any>> =>
  await httpClient.put("/actions/reorder", {
    orderedActionIds: orderedActionIds
  });

export const deleteHotspot = async (
  hotspotId: string
): Promise<AxiosResponse<any>> =>
  await httpClient.delete(`/hotspots/destroy/${hotspotId}`);

export const getHotspot = async (
  hotspotId: string
): Promise<AxiosResponse<any>> =>
  await httpClient.get(`/hotspots/${hotspotId}`);
