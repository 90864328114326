import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c662440"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "icon icon--before"
}
const _hoisted_3 = {
  key: 1,
  class: "icon icon--after"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", [
      _ctx.customClass,
      _ctx.variant,
      _ctx.size,
      {
        'is-block': !!_ctx.block,
        'is-outline': !!_ctx.outline,
      },
    ]]),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"]))
  }, [
    (_ctx.$slots.labelBefore)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "labelBefore", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TranslatedText, { path: _ctx.text }, null, 8, ["path"]),
    (_ctx.$slots.labelAfter)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "labelAfter", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}