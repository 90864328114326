import httpClient, { ApiResponse, HttpResponse } from "@/api/httpClient";
import {
  ActionProgressResponse,
  Address,
  ContactNumber,
  EmergencyContact,
  Payroll,
  UserDetails,
} from ".";

export class NewStarterForm {
  public userDetails: UserDetails;
  public addresses: Array<Address>;
  public contactNumbers: Array<ContactNumber>;
  public emergencyContacts: Array<EmergencyContact>;
  public payroll: Payroll;

  constructor({
    userDetails = new UserDetails(),
    addresses = [] as Array<Address>,
    contactNumbers = [] as Array<ContactNumber>,
    emergencyContacts = [] as Array<EmergencyContact>,
    payroll = new Payroll(),
  } = {}) {
    this.userDetails = userDetails;
    this.addresses = addresses;
    this.contactNumbers = contactNumbers;
    this.emergencyContacts = emergencyContacts;
    this.payroll = payroll;
  }

  public static async get(): Promise<NewStarterForm | undefined> {
    const response: ApiResponse<NewStarterForm> = await httpClient.get(
      `/newstartercontents/content`
    );
    if (response.status === 200 && response.data.payload.result) {
      const { result } = response.data.payload;
      return new NewStarterForm({
        addresses: result.addresses.map((a) => new Address(a)),
        contactNumbers: result.contactNumbers.map((c) => new ContactNumber(c)),
        emergencyContacts: result.emergencyContacts.map(
          (e) => new EmergencyContact(e)
        ),
        userDetails: result.userDetails,
        payroll: result.payroll,
      });
    }
  }

  public async complete(
    actionId: string,
    dob: string,
    signed: boolean
  ): Promise<ActionProgressResponse> {
    const dobParts = dob.split("-");
    const dobYear = dobParts.length ? dobParts[0] : null;
    const dobMonth = dobParts.length > 1 ? dobParts[1] : null;
    const dobDay = dobParts.length > 2 ? dobParts[2] : null;

    const response = await httpClient.post<
      HttpResponse<ActionProgressResponse>
    >(`/newstartercontents`, {
      actionId,
      form: this,
      declaration: {
        dobDay,
        dobMonth,
        dobYear,
        sign: signed,
      },
    });

    return response.data.payload.result as ActionProgressResponse;
  }
}
