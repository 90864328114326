import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionHeader = _resolveComponent("ActionHeader")!
  const _component_ReviewForm = _resolveComponent("ReviewForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, { actionTitle: _ctx.actionTitle }, null, 8, ["actionTitle"]),
    _createVNode(_component_ReviewForm, {
      currentReview: _ctx.currentReview,
      answers: _ctx.answers,
      readOnly: true
    }, null, 8, ["currentReview", "answers"]),
    (
        _ctx.currentReview &&
        _ctx.currentReview.completedManager &&
        !_ctx.currentReview.completed
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Button, {
            text: 'reviews.save',
            onClick: _ctx.handleSave
          }, null, 8, ["text", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}