import httpClient, { ApiResponse } from "@/api/httpClient";
import { Address } from "./Address";
import { ContactNumber } from "./ContactNumber";
import { EmergencyContact } from "./EmergencyContact";

export class UserContactModel {
  public addresses: Array<Address>;
  public contactNumbers: Array<ContactNumber>;
  public emergencyContacts: Array<EmergencyContact>;

  constructor({
    addresses = [] as Array<Address>,
    contactNumbers = [] as Array<ContactNumber>,
    emergencyContacts = [] as Array<EmergencyContact>,
  } = {}) {
    this.addresses = addresses;
    this.contactNumbers = contactNumbers;
    this.emergencyContacts = emergencyContacts;
  }

  public static async get(
    userId: string
  ): Promise<UserContactModel | undefined> {
    const response: ApiResponse<UserContactModel> = await httpClient.get(
      `/users/${userId}/contact`
    );
    if (response.status === 200 && response.data.payload.result) {
      const { result } = response.data.payload;
      return new UserContactModel({
        addresses: result.addresses.map((a) => new Address(a)),
        contactNumbers: result.contactNumbers.map((c) => new ContactNumber(c)),
        emergencyContacts: result.emergencyContacts.map(
          (e) => new EmergencyContact(e)
        ),
      });
    }
  }
}
