
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "Table",
  props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: Object || (Array as PropType<Array<any>>),
    keyProp: String,
    sortOrder: String,
    sortColumn: String,
  },
  setup(props) {
    const noRows = computed(() => {
      return !(props.rows && Object.keys(props.rows).length);
    });

    return {
      noRows,
    };
  },
});
