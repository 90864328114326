import { App, inject } from "vue";
import store from "@/store";

export const loadingSymbol = Symbol();
export type ShowLoadingFunction = (show: boolean) => Promise<void>;

let to = 0;

export default {
  install: (app: App): void => {
    const setGlobalLoading: ShowLoadingFunction = async (show: boolean) => {
      let timeout = 0;
      if (show) {
        timeout = 300;
      } else {
        clearTimeout(to);
      }
      to = setTimeout(() => store.dispatch("showLoader", show), timeout);
    };

    app.config.globalProperties.$setGlobalLoading = setGlobalLoading;
    app.provide(loadingSymbol, setGlobalLoading);
  },
};

export const useLoading = (): ShowLoadingFunction => {
  const plugin = inject<ShowLoadingFunction>(loadingSymbol);
  if (!plugin) throw new Error("Loading plugin not installed");

  return plugin;
};
