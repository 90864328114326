
import { computed, defineComponent, ref } from "vue";
import Control from "@/components/track/manager/track-components/Control.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Foot",
  emits: ["save", "setPointType"],
  components: {
    Control,
    Button,
  },
  props: {
    points: {
      type: Object,
    },
    size: {
      type: Number,
    },
    activePoint: {
      type: Number,
      default: 0,
    },
    curvedLine: {
      type: Boolean,
      default: false,
    },
    trackIsDirty: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isHidden = ref<boolean>(false);
    const active = computed(() =>
      props.points ? props.points[props.activePoint] : null
    );
    const isLineCurved = computed(() =>
      props.curvedLine ? props.curvedLine : false
    );
    const save = (e) => {
      emit("save", e);
    };

    const setPointType = (e) => {
      emit("setPointType", e);
    };

    const toggleFooter = () => {
      isHidden.value = !isHidden.value;
    };

    return {
      save,
      setPointType,
      toggleFooter,
      isHidden,
      active,
      isLineCurved,
    };
  },
});
