import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e366dd78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-side-menu-container" }
const _hoisted_2 = { class: "side-menu" }
const _hoisted_3 = { class: "title-area" }
const _hoisted_4 = { class: "root" }
const _hoisted_5 = { class: "filters" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "mobile-date-picker"
}
const _hoisted_9 = {
  key: 1,
  class: "date-picker-buttons"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Backdrop = _resolveComponent("Backdrop")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_FormInput = _resolveComponent("FormInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Backdrop),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.inSubmenu)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
            }))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "back",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
            })),
        _createElementVNode("h4", _hoisted_4, [
          _createVNode(_component_TranslatedText, { path: _ctx.menuTitle }, null, 8, ["path"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", null, [
          (_ctx.datePickers.length)
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: _normalizeClass({ hidden: _ctx.inSubmenu })
              }, [
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickDateFilter && _ctx.clickDateFilter(...args)))
                }, [
                  _createVNode(_component_TranslatedText, { path: 'Date' })
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideMenu, (mi, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: mi.index
            }, [
              _createElementVNode("li", {
                class: _normalizeClass({ hidden: _ctx.inSubmenu })
              }, [
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.clickMenuItem(index, mi.id))
                }, [
                  _createVNode(_component_TranslatedText, {
                    path: mi.name
                  }, null, 8, ["path"])
                ], 8, _hoisted_6)
              ], 2),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mi.items, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["sub-menu", { active: mi.active }]),
                  key: index
                }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["checkbox", item.active ? 'checked' : 'unchecked']),
                    onClick: ($event: any) => (_ctx.clickFilter(index, item.id))
                  }, _toDisplayString(item.name), 11, _hoisted_7)
                ], 2))
              }), 128))
            ], 64))
          }), 128))
        ]),
        (_ctx.dateFilterActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datePickers, (dp) => {
                return (_openBlock(), _createBlock(_component_FormInput, {
                  key: dp.id,
                  customClass: dp.customClass,
                  id: dp.id,
                  name: dp.name,
                  label: dp.label,
                  value: dp.value,
                  type: 'date',
                  error: dp.error,
                  labelAlwaysActive: "",
                  onChange: ($event: any) => (_ctx.filterDateByInput($event, dp.name))
                }, null, 8, ["customClass", "id", "name", "label", "value", "error", "onChange"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.dateFilterActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateFilterButtons, (btn) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: btn.id,
                  class: _normalizeClass({ active: btn.active }),
                  onClick: ($event: any) => (_ctx.filterDateByButton(btn.name))
                }, _toDisplayString(btn.name), 11, _hoisted_10))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}