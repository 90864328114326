<template>
  <widget-layout
    class="user-activity"
    :minHeight="2"
    :maxHeight="2"
    :maxWidth="2"
    :loading="!data"
    menuColour="#fff"
    :class="`width${width}`"
  >
    <template v-slot:title>
      <div class="title"><h4>User Activity</h4></div>
    </template>
    <div class="widget-main">
      <div class="content">
        <div v-if="data" class="info">
          <span
            ><strong>{{ data.active }}</strong
            ><br />active users of {{ data.total }}</span
          >
        </div>
        <div v-if="data" class="chart-container">
          <chart
            class="radial"
            :valuesInner="chartValues"
            outerColour="#0000"
            innerColour="#934d98"
            coreColour="#4d2a86"
            :innerThickness="0.75"
          >
            <font-awesome-icon icon="user"></font-awesome-icon>
          </chart>
        </div>
      </div>
      <div class="link">
        <p class="addLink">
          <router-link to="/users">View users &gt;</router-link>
        </p>
      </div>
    </div>
  </widget-layout>
</template>
<script>
import Chart from "../Chart.vue";
import WidgetLayout from "../WidgetLayout.vue";
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "UserActivity",
  components: {
    Chart,
    WidgetLayout,
  },
  props: {
    width: Number,
    height: Number,
    userData: {
      default: {},
    },
    data: Object,
  },
  setup(props) {
    const chartValues = computed(() =>
      props.data === undefined
        ? []
        : [
            {
              size: props.data.active / props.data.total,
            },
          ]
    );

    return { chartValues };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";
.widget-layout {
  height: 100%;
  min-height: 280px;
}
.user-activity {
  .widget-main {
    padding: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .content {
      display: flex;
      .info {
        width: 100%;
        font-size: 12px;
        letter-spacing: 0.1em;
        span {
          width: 50%;
          display: block;
          strong {
            transition: 200ms 70ms all;
            font-size: 48px;
            font-weight: normal;
            line-height: 1.3em;
            @include min-width("sm") {
              font-size: 72px;
            }
            @include min-width("lg") {
              font-size: 68px;
            }
          }
          @include min-width("sm") {
            font-size: 18px;
          }
          @include min-width("lg") {
            font-size: 16px;
          }
        }
      }
      .chart-container {
        width: 45%;
        margin-top: -3.5rem;
        align-self: end;
        font-size: 200%;
        .chart {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
        }
      }
    }

    .link {
      text-align: right;
      p {
        margin-bottom: 0;
      }
      a {
        font-size: 12px;
        line-height: 18px;
        text-decoration: underline;
      }
    }
  }
}
</style>
