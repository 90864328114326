
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { Hotspot, Review, User } from "@/models";
import { useRouter } from "vue-router";
import ReviewForm from "@/components/reviews/ReviewForm.vue";
import Button from "@/components/Button.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    ReviewForm,
    Button,
    ActionHeader,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    currentReviewId: {
      type: String,
      required: true,
    },
  },
  emits: ["review-complete"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const { inPageNotification } = useNotification();

    const reviews = ref<Array<Review>>();
    const currentReview = ref<Review>();
    const answers = ref<any[]>([]);

    const currentUser = computed(() => store.getters.currentUser);
    const updatedReviews = ref<string[]>([]);
    const getReviews = async (bypassCache = false) => {
      try {
        if (!reviews.value || bypassCache) {
          reviews.value = await User.getReviewsByHotspotAndUser(
            props.hotspot.id
          );
          reviews.value = reviews.value.map((review) => {
            review.actionObjectives.sort(
              (ao1, ao2) => ao1.actionNumber - ao2.actionNumber
            );

            return review;
          });
          if (reviews.value && reviews.value[0]) {
            reviews.value.sort(
              (review1, review2) => review1.reviewNumber - review2.reviewNumber
            );
            currentReview.value = reviews.value?.find(
              (review: Review) => review.id === props.currentReviewId
            );
            answers.value = reviews.value[0].user.answers;
          }
        }
      } catch (err) {
        router.push("/");
      }
    };

    const handleSave = async () => {
      try {
        if (currentReview.value) {
          await User.putMyReview(currentReview.value?.id);
          getReviews();
          currentReview.value.completed = true;
          inPageNotification("reviews.saved-title", "reviews.saved", "success");
          emit("review-complete");
        }
      } catch (error) {
        console.log(error);
        inPageNotification(
          "reviews.error-title",
          "reviews.error-save",
          "error"
        );
      }
    };

    const actionTitle = computed(() => {
      let pageTitle = "Review ";
      if (currentReview.value) {
        pageTitle += currentReview.value?.reviewNumber;
      }

      return pageTitle;
    });

    onMounted(() => {
      getReviews();
    });
    watch(props, () => getReviews(true));

    return {
      actionTitle,
      handleSave,
      reviews,
      currentUser,
      answers,
      updatedReviews,
      currentReview,
    };
  },
});
