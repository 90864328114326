import { getImageContents } from "@/api/image-contents.api";
import { FormQuestion } from "@/interfaces/domain";

export const useFormQuestion = () => {
  const formQuestionTypes = [
    { type: "title" },
    { type: "prompt" },
    { type: "text" },
    { type: "bigtext" },
    { type: "select" },
    { type: "date" },
    { type: "statements" },
    { type: "checkboxes" },
    { type: "imageupload" },
    { type: "pdfupload" },
    { type: "genericupload" },
    { type: "image" },
  ];

  const formQuestionPreFilled = [
    { type: "title" },
    { type: "firstname" },
    { type: "surname" },
    { type: "email" },
  ];

  const validationTypes = {
    text: [
      { id: "required", name: "Required" },
      { id: "email", name: "Email" },
      { id: "name", name: "Name" },
      { id: "number", name: "Number" },
    ],
    prompt: [],
    bigtext: [
      { id: "required", name: "Required" },
      { id: "email", name: "Email" },
      { id: "name", name: "Name" },
      { id: "number", name: "Number" },
      { id: "sumequalsonehundred", name: "Sum equals one hundred" },
      { id: "ukphone", name: "UK phone number" },
    ],
    statements: [{ id: "required", name: "Required" }],
    checkboxes: [{ id: "required", name: "Required" }],
    select: [{ id: "required", name: "Required" }],
    title: [],
    image: [{ id: "required", name: "Required" }],
    imageupload: [{ id: "required", name: "Required" }],
    pdfupload: [{ id: "required", name: "Required" }],
    genericupload: [{ id: "required", name: "Required" }],
    date: [{ id: "required", name: "Required" }],
    dob: [{ id: "required", name: "Required" }],
    ['date-of-birth']: [{ id: "required", name: "Required" }],
  };

  const setInitialUserResponses = (
    formQuestion: FormQuestion,
    dependantQuestions: FormQuestion[],
    userResponses: any,
    userDetails: any
  ) => {
    userResponses[formQuestion.id] = {
      value: getResponseValue(formQuestion, userDetails),
      hide:
        formQuestion.dependencies?.length > 0 &&
        !formQuestion.userResponses[0]?.responseValue,
      dependantQuestions,
      validators: formQuestion.validators,
      required: formQuestion.validators
        ? formQuestion.validators.filter(
          (validator) => validator.validatorType === "required"
        ).length > 0
        : false,
      changed: false,
    };
  };

  const getResponseValue = (formQuestion: FormQuestion, userDetails: any) => {
    // user any existing form responses, or if none exist but user details has values then use those
    // for multiple choice find the matching choice id
    return formQuestion.userResponses[0]
      ? !["select", "statements"].includes(formQuestion.questionType)
        ? formQuestion.userResponses[0].responseValue
        : formQuestion.choices.find((fqc) =>
          fqc.textItems.find(
            (ti) => ti.data === formQuestion.userResponses[0]?.responseValue
          )
        )?.id
      : formQuestion.fieldReference
        ? userDetails.value
          ? userDetails.value[formQuestion.fieldReference]
          : ""
        : "";
  };

  const setDependencies = (
    formQuestion: FormQuestion,
    formQuestions: any[]
  ) => {
    return formQuestions.filter(
      (fq: FormQuestion) =>
        fq.dependencies?.filter(
          (dependency) => dependency.dependsOn === formQuestion.id
        ).length > 0
    );
  };

  const updateDependencies = (name: any, value: any, userResponses: any) => {
    if (userResponses[name]) {
      userResponses[name].value = value;
      userResponses[name].changed = true;
      userResponses[name].dependantQuestions.forEach((fq: FormQuestion) => {
        if (!userResponses[fq.id]) {
          userResponses[fq.id] = {
            changed: true,
            dependantQuestions: [],
            hide: false,
            validators: {},
            value: null,
          };
        }
        userResponses[fq.id].hide =
          fq.dependencies.filter(
            (dependency) =>
              userResponses[dependency.dependsOn].value !== dependency.matching
          ).length > 0;
      });
    }
  };

  const setImages = async (
    imageReferences: string[],
    userId: string,
    safeUrls
  ) => {
    const imageResponse = await getImageContents(imageReferences, userId);
    const imageContents = imageResponse.data.payload.results;
    const organisedImageContents = {};
    for (const imageReference of imageReferences) {
      organisedImageContents[imageReference] = [];
    }
    if (imageContents) {
      for (const imageContentItem of imageContents) {
        organisedImageContents[imageContentItem.reference].push(
          imageContentItem
        );
      }
      for (const imageReference of imageReferences) {
        const url = organisedImageContents[imageReference][0]?.url;
        safeUrls[imageReference] = url;
      }
    }
  };

  return {
    setInitialUserResponses,
    setDependencies,
    updateDependencies,
    setImages,
    formQuestionTypes,
    formQuestionPreFilled,
    validationTypes,
  };
};
