
import { defineComponent, computed, readonly } from "vue";
import { useStore } from "vuex";

const iconTypes = ["generic", "video", "image", "pdf"];

export default defineComponent({
  emits: ["download", "regenerate", "remove", "edit"],
  props: {
    fileName: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: "generic",
      validator: (val: string) =>
        iconTypes.some((icon: string) => icon === val),
    },
    editAcceptString: {
      type: String,
      default: "*",
    },
    isLoading: Boolean,
    downloadable: Boolean,
    regeneratable: {
      type: Boolean,
      default: false,
    },
    editable: Boolean,
    deletable: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const tenant = computed(() => {
      const tenantName = store.getters.currentTenant.name;
      return tenantName.charAt(0).toUpperCase() + tenantName.slice(1);
    });

    const fileIcons = readonly({
      generic: "file",
      video: "video",
      image: "file-image",
      pdf: "copy",
    });

    const validFileIcon = computed(() => fileIcons[props.fileType]);

    const download = () => emit("download");
    const regenerate = () => emit("regenerate");
    const remove = () => emit("remove");
    const edit = (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length) {
        const file = target.files[0];
        emit("edit", file);
      }
    };

    return { tenant, validFileIcon, download, regenerate, remove, edit };
  },
});
