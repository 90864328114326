import httpClient from "@/api/httpClient";
import { Model } from "./Model";
import FileSaver from "file-saver";

export class Document extends Model<Document> {
  // public userId: string;
  // public groupId: string;
  public languageId: string;
  public fileName: string;
  public storageKey: string;
  public url: string;
  public reference: string;
  public signOffEnabled: boolean;

  constructor ({
    id = "",
    // userId = "",
    // groupId = "",
    languageId = "",
    fileName = "",
    storageKey = "",
    url = "",
    reference = "",
    signOffEnabled = false,
  } = {}) {
    super(id, Document, "");
    // this.userId = userId;
    // this.groupId = groupId;
    this.languageId = languageId;
    this.fileName = fileName;
    this.storageKey = storageKey;
    this.url = url;
    this.reference = reference;
    this.signOffEnabled = signOffEnabled;
  }

  public static async getForReferences(
    references: Array<string>,
    tagIds = ""
  ): Promise<any> {
    return await Model.getAll<Document>(
      `/documentcontents/contents/${JSON.stringify(references)}/user/null/null/${tagIds.length > 0 ? tagIds : 'null'}`,
      Document
    );
  }

  public async download(): Promise<void> {
    const response = await httpClient.get<Blob>(
      `/documentcontents/download/${this.id}`,
      {
        responseType: "blob",
      }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    FileSaver.saveAs(blob, this.fileName);
  }

  public async delete(): Promise<any> {
    return await httpClient.delete(`/documentcontents/${this.id}`);
  }

  public async regenerate(): Promise<any> {

    return await httpClient.get<Blob>(
      `/documentcontents/regenerate/${this.id}`,
      {
        responseType: "blob",
      }
    );
  }
}
