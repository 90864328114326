
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Reporting",
  components: {
    AuthenticatedLayout,
  },
  setup() {
    const store = useStore();
    store.dispatch("syncReports");
  },
});
