import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_component_font_awesome_icon, {
    icon: 'check',
    class: _normalizeClass(["tick", { active: _ctx.active, round: _ctx.round }]),
    size: _ctx.size
  }, null, 8, ["class", "size"]))
}