
import { defineComponent, PropType, toRef, watch } from "vue";
import { Action } from "@/models";
import Alert from "@/components/Alert.vue";

export default defineComponent({
  components: { Alert },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const updateActive = toRef(props, "updateActive");

    const handleDelete = async () => {
      emit("action-removed", props.action.id);
    };

    watch(updateActive, async () => {
      emit("updated", true);
    });

    return {
      handleDelete,
    };
  },
});
