
import {
  defineComponent,
  computed,
  ComputedRef,
  readonly,
  ref,
  inject,
  watch,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";
import Avatar from "@/components/Avatar.vue";
import Branding from "../components/customise/Branding.vue";
import { useStore } from "vuex";
import { getAllLanguages } from "@/api/language.api";
import {
  postImageContents,
  postCopyImageContents,
} from "@/api/image-contents.api";
import { putUpdateStyles } from "@/api/theme.api";
import FileDisplay from "@/components/FileDisplay.vue";
import { tenantSetupComplete } from "@/api/global-units.api";
import { useLanguage } from "@/composables";

import { Theme } from "@/models/Style";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    AuthenticatedLayout,
    Avatar,
    Branding,
    FileDisplay,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = store.getters.currentUser;
    const avatarFallbackText = computed(() => {
      const firstName = user.firstName[0] ?? "";
      const surname = user.surname[0] ?? "";
      return `${firstName}${surname}`;
    });

    const { inPageNotification } = useNotification();
    const avatarVariant = computed(() => (user.isLockedOut ? "danger" : ""));
    const avatarIcon = computed(() => (user && user.isLockedOut ? "lock" : ""));
    const isValid = computed(() => !Object.keys(model.value.errors).length);
    const theme = inject<ComputedRef<Theme>>("theme");

    const model = ref({
      favicon: "" as string | Blob,
      platformLogo: "" as string | Blob,
      signinLogo: "" as string | Blob,
      useLogoAcrossPlatform: false,
      useBackgroundOnTrack: true,
      setTrackImage: false,
      backgroundColour: "",
      backgroundColourTrack: "",
      backgroundImage: "" as string | Blob,
      backgroundImageTrack: "" as string | Blob,
      backgroundImageSVG: "" as string | Blob,
      errors: {},
    });
    if (theme) {
      watch(theme, () => {
        model.value.favicon = favicon();
        model.value.platformLogo = platformLogo();
        model.value.signinLogo = signinLogo();
        model.value.useLogoAcrossPlatform = useLogoAcrossPlatform();
        model.value.useBackgroundOnTrack = useBackgroundOnTrack();
        model.value.backgroundColour = backgroundColour();
        model.value.backgroundColourTrack = backgroundColourTrack();
        model.value.backgroundImage = backgroundImage();
        model.value.backgroundImageTrack = backgroundImageTrack();
        model.value.setTrackImage = setTrackImage();
        model.value.backgroundImageSVG = backgroundImageSVG();
      });
    }

    const navItemConfig = [
      {
        url: "branding",
        title: "customise.branding",
        component: "Branding",
        permission: "cutomise:branding",
        action: () => changeTab("branding"),
      },
    ];

    const pageTitle = computed(() => {
      const tenant = store.getters.currentTenant?.name;
      const validTenant = tenant.charAt(0).toUpperCase() + tenant.slice(1);
      return validTenant;
    });

    const changeTab = (url: string) => {
      router.push({
        path: `/customise/${url}`,
      });
    };

    const tryingSetup = ref(false);

    const uploadImage = async (
      file: any,
      language: any,
      size: string,
      reference: string,
      blockDelete: string
    ) => {
      const imageFiles = {};
      imageFiles[language] = file;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        imageFiles[language],
        "" + imageFiles[language].name
      );
      formData.append("imageSize", size);
      formData.append("languageId", language);
      formData.append("reference", reference);
      formData.append("blockDelete", blockDelete);
      await postImageContents(formData);
    };

    const handleSaveCustomisation = async () => {
      try {
        const response = await getAllLanguages();
        let language = "";
        if (response?.data?.payload?.results) {
          language = response.data.payload.results[0].id;
        }

        model.value.errors = {};

        if (model.value.favicon instanceof Blob) {
          await uploadImage(
            model.value.favicon,
            language,
            "logo",
            "website-logo-small",
            "false"
          );
        }

        // Update Site Logo
        if (model.value.platformLogo instanceof Blob) {
          await uploadImage(
            model.value.platformLogo,
            language,
            "logo",
            "website-logo",
            "false"
          );
        }

        if (model.value.useLogoAcrossPlatform) {
          model.value.signinLogo = model.value.platformLogo;
        }

        // Update Login Logo
        if (model.value.signinLogo instanceof Blob) {
          await uploadImage(
            model.value.signinLogo,
            language,
            "logo",
            "login-logo",
            `${!model.value.useLogoAcrossPlatform && logosAreTheSame}`
          );
        }

        if (
          model.value.useLogoAcrossPlatform &&
          typeof model.value.platformLogo === "string"
        ) {
          const copyData = {
            from: {
              languageId: language,
              reference: "website-logo",
              imageSize: "logo",
            },
            to: {
              languageId: language,
              reference: "login-logo",
              imageSize: "logo",
            },
          };
          await postCopyImageContents(copyData);
        }

        if (model.value.useBackgroundOnTrack === true) {
          model.value.backgroundColourTrack = model.value.backgroundColour;
          model.value.backgroundImageTrack = model.value.backgroundImage;
        }

        // Update Login Backgrounds
        await putUpdateStyles(
          `background-colour: ${model.value.backgroundColour}
          background-colour-track: ${model.value.backgroundColourTrack}`,
          true
        );
        if (model.value.backgroundImage instanceof Blob) {
          await uploadImage(
            model.value.backgroundImage,
            language,
            "background",
            "login-background",
            "false"
          );
        }

        if (model.value.backgroundImageTrack instanceof Blob) {
          await uploadImage(
            model.value.backgroundImageTrack,
            language,
            "background",
            "website-background",
            `${!model.value.useBackgroundOnTrack && backgroundImagesAreTheSame}`
          );
        }

        if (model.value.backgroundImageSVG instanceof Blob) {
          await uploadImage(
            model.value.backgroundImageSVG,
            language,
            "background",
            "track-svg-background",
            "false"
          );
        }

        if (
          model.value.useBackgroundOnTrack === true &&
          typeof model.value.backgroundImage === "string"
        ) {
          const copyData = {
            from: {
              languageId: language,
              reference: "login-background",
              imageSize: "background",
            },
            to: {
              languageId: language,
              reference: "website-background",
              imageSize: "background",
            },
          };
          await postCopyImageContents(copyData);
        }

        inPageNotification(
          "customise.success-title",
          "customise.success",
          "success"
        );

        await store.dispatch("setThemeUpdateAllowed", true);
        setTimeout(() => {
          location.reload();
        }, 3000);
      } catch (error) {
        model.value.errors = {
          ["customise-error"]: error,
        };

        let sectionErrors = "";
        if (Object.keys(model.value.errors).length) {
          sectionErrors += " Customise,";
        }
        sectionErrors = sectionErrors.substring(0, sectionErrors.length - 1);
        const errorMessage = translateText("customise.error", [
          {
            placeholder: "value",
            value: sectionErrors,
          },
        ]);
        tryingSetup.value = false;
        inPageNotification("customise.error-title", errorMessage, "error");
      }
    };

    const activeNavItem = computed(() => {
      if (!currentSetup.value) {
        return navItemConfig[0];
      }
      return (
        navItemConfig.find((i) => i.url === currentSetup.value) ??
        navItemConfig[0]
      );
    });
    const currentSetup: ComputedRef<string> = computed(() => {
      let { setup } = router.currentRoute.value.params;
      if (!setup) {
        return "customise";
      }
      return setup as string;
    });

    const setupTypes = readonly({
      customise: "Branding",
    });

    const currentComponent = computed(() => setupTypes[currentSetup.value]);

    const favicon = () => {
      if (theme && theme.value.logo.appSmall) {
        return theme.value.logo.appSmall.url;
      }
      return require("@/assets/img/logo-jolahr-small.svg");
    };

    const platformLogo = () => {
      if (theme && theme.value.logo.app) {
        return theme.value.logo.app.url;
      }
      return require("@/assets/img/logo-jolahr-dark.svg");
    };

    const signinLogo = () => {
      if (theme && theme.value.logo.signin) {
        return theme.value.logo.signin.url;
      }
      return require("@/assets/img/logo-jolahr.svg");
    };

    const useLogoAcrossPlatform = () => {
      return platformLogo() === signinLogo();
    };

    const useBackgroundOnTrack = () => {
      return (
        backgroundColour() === backgroundColourTrack() &&
        backgroundImage() === backgroundImageTrack()
      );
    };

    const backgroundColour = () => {
      if (theme && theme.value.backgroundColour.signin?.value) {
        return theme.value.backgroundColour.signin?.value;
      }
      return "#f0f0f0";
    };

    const backgroundColourTrack = () => {
      if (theme && theme.value.backgroundColour.app?.value) {
        return theme.value.backgroundColour.app?.value;
      }
      return "#f0f0f0";
    };

    const backgroundImage = () => {
      if (theme && theme.value.backgroundImage.signin) {
        return theme.value.backgroundImage.signin.url;
      }
      return require("@/assets/img/sign-in.png");
    };

    const backgroundImageTrack = () => {
      if (theme && theme.value.backgroundImage.app) {
        return theme.value.backgroundImage.app.url;
      }
      return require("@/assets/img/sign-in.png");
    };

    const backgroundImageSVG = () => {
      if (theme && theme.value.backgroundImage.track) {
        return theme.value.backgroundImage.track.url;
      }
      return "";
    };

    const setTrackImage = () => {
      const imageUrl = backgroundImageSVG();
      return imageUrl.length > 0;
    };

    const logosAreTheSame = useLogoAcrossPlatform();
    const backgroundImagesAreTheSame =
      backgroundImage() === backgroundImageTrack();

    const handleUpdate = ({ name, value }) => {
      model.value.errors = {};
      model.value[name] = value;
    };

    const { translateText } = useLanguage();

    onMounted(async () => {
      model.value.favicon = favicon();
      model.value.platformLogo = platformLogo();
      model.value.signinLogo = signinLogo();
      model.value.useLogoAcrossPlatform = useLogoAcrossPlatform();
      model.value.useBackgroundOnTrack = useBackgroundOnTrack();
      model.value.setTrackImage = setTrackImage();
      model.value.backgroundColour = backgroundColour();
      model.value.backgroundColourTrack = backgroundColourTrack();
      model.value.backgroundImage = backgroundImage();
      model.value.backgroundImageTrack = backgroundImageTrack();
      model.value.backgroundImageSVG = backgroundImageSVG();

      const { setupCompleted } = store.getters;
      if (!setupCompleted) {
        await tenantSetupComplete();
        store.dispatch("setupCompleted", true);
      }
    });

    return {
      platformLogo,
      navItemConfig,
      handleSaveCustomisation,
      handleUpdate,
      currentComponent,
      activeNavItem,
      user,
      avatarFallbackText,
      avatarIcon,
      avatarVariant,
      model,
      pageTitle,
      isValid,
      tryingSetup,
    };
  },
});
