import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e699bb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["list-menu", [_ctx.customClass, { show: _ctx.show }]])
  }, [
    _createElementVNode("div", {
      class: "backdrop",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
    }),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (mi, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.handleMenuItemClick(mi.title, mi.id)),
            class: _normalizeClass({ active: mi.active })
          }, [
            _createVNode(_component_TranslatedText, {
              path: mi.title
            }, null, 8, ["path"])
          ], 10, _hoisted_1)
        ]))
      }), 128))
    ])
  ], 2))
}