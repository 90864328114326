
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
import UserProgressReport from "@/components/reporting/reports/UserProgressReport.vue";
import PulseReport from "@/components/reporting/reports/PulseReport.vue";
import TitleSelect from "@/components/form/TitleSelect.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Report",
  components: {
    UserProgressReport,
    PulseReport,
    TitleSelect,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const componentMap = {
      "user-progress": "UserProgressReport",
      "pulse-feedback": "PulseReport",
    };
    const componentName = computed(() => {
      const id = currentReport.value;
      const mappedName = componentMap[id];
      return mappedName ?? "CustomReport";
    });

    const reports = computed(() => store.getters.reports);
    const currentReport = computed(
      () => router.currentRoute.value.params.id as string
    );
    const handleReportChange = (e) => {
      router.push(`/reporting/${e.value}`);
    };

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
    });

    return {
      isMounted,
      componentName,
      reports,
      currentReport,
      handleReportChange,
    };
  },
});
