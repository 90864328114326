
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
  computed,
  reactive,
} from "vue";
import { User, Activity, ActivityFilters } from "@/models";
import { format, parseISO, sub } from "date-fns";
import Timeline, {
  TimelineGroup,
  TimelineItem,
} from "@/components/Timeline.vue";
import { PaginatedResult, PaginationDetails } from "@/interfaces/Pagination";
import Pagination from "@/components/Pagination.vue";
import { useStore } from "vuex";
import Button from "@/components/Button.vue";
import MenuBar, { FilterGroup } from "@/components/MenuBar.vue";
import { sendWelcomeEmail } from "@/api/user.api";
import { useModal } from "@/plugins/modal";
import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
    },
    isAuthenticatedUser: Boolean,
  },
  components: {
    Timeline,
    Pagination,
    MenuBar,
    Button,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(true);
    const showModal = useModal();

    const { inPageNotification } = useNotification();

    const page = ref<PaginatedResult<Activity>>();
    const getPage = async (
      bypassCache = false,
      pagination?: PaginationDetails
    ) => {
      isLoading.value = true;
      if (!pagination) {
        const pageSize = store.getters.pageSize;
        pagination = new PaginationDetails({ pageSize });
      }
      page.value = await props.user?.getActivity(
        pagination,
        currentSortOrder.value,
        filters,
        bypassCache
      );
      isLoading.value = false;
    };
    const paginationDetails = computed(() => page.value?.details);

    const timelineGroups = computed<Array<TimelineGroup>>(() => {
      if (!page.value) {
        return [];
      }
      const ungrouped: { [key: string]: Array<Activity> } =
        page.value.rows.reduce((obj, row) => {
          const actionedAt = parseISO(row.actionedAt);
          const datePart = format(actionedAt, "yyyy-MM-dd");
          if (Object.keys(obj).some((key) => key === datePart)) {
            obj[datePart].push(row);
          } else {
            obj[datePart] = [row];
          }
          return obj;
        }, {});

      return Object.keys(ungrouped).map((key) => {
        const activities = ungrouped[key];
        const items: Array<TimelineItem> = activities.map((activity) => ({
          time: format(parseISO(activity.actionedAt), "HH:mm:ss"),
          text: getTranslationKeyForType(activity.activityTypeId),
          notificationText: getNotificationKeyForType(activity),
          hasAction: getActionForType(activity.activityTypeId),
          subText: activity.actionedBy,
          icon: "user",
        }));
        return {
          title: format(parseISO(key), "MMM dd, yyyy"),
          items,
        };
      });
    });

    const handleChangePage = async (pageNumber: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber,
      });
      await getPage(true, pagination);
    };
    const handleChangeRowCount = async (pageSize: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
        pageSize,
      });
      await getPage(true, pagination);
    };

    const getTranslationKeyForType = (type: string) => {
      const translationKeyMap = {
        LOGIN_SUCCESS: "activity.login-success",
        LOGIN_FAILED: "activity.login-failed",
        USER_LOCKED: "activity.user-locked",
        USER_UNLOCKED: "activity.user-unlocked",
        USER_CREATED: "activity.user-created",
        USER_UPDATED: "activity.user-updated",
        USER_DISABLED: "activity.user-disabled",
        USER_ENABLED: "activity.user-enabled",
        NOTIFICATION_SENT: "activity.notification-success",
        WELCOME_EMAIL_SENT: "activity.welcome-email-sent",
        PASSWORD_RESET: "activity.user-password-reset",
        PASSWORD_UPDATED: "activity.user-password-updated",
        COMPLETION_EVENT_SENT: "activity.completion-event-sent",
        MANUAL_COMPLETED: "activity.manually-completed",
      };

      return translationKeyMap[type];
    };

    const getNotificationKeyForType = (activity: Activity) => {
      let text = "";
      if (activity.notification?.notificationTypeId) {
        text +=
          "notification-type." + activity.notification?.notificationTypeId;
      }
      return text;
    };

    const getActionForType = (type: string) => {
      switch (type) {
        case "WELCOME_EMAIL_SENT":
          return {
            action: "resendWelcomeEmail",
            actionText: "notification-type.resend-welcome-email",
          };
      }

      return { action: "", actionText: "" };
    };

    const currentSortOrder = ref("desc");
    const handleSort = async (sortOrder: string) => {
      currentSortOrder.value = sortOrder;
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
      });
      await getPage(true, pagination);
    };

    const filters = reactive<ActivityFilters>({
      from: undefined,
      to: undefined,
    });
    const filterOptions = computed<FilterGroup>(() => ({
      date: {
        label: "Date",
        options: [
          {
            name: "from",
            label: "common.from",
            component: "DatePicker",
            value: filters.from,
          },
          {
            name: "to",
            label: "common.to",
            component: "DatePicker",
            value: filters.to,
          },
        ],
      },
    }));
    const handleFilterChange = ({ name, value }) => {
      filters[name] = format(value, "yyyy-MM-dd");
    };
    const handleFilterReset = async () => {
      filters.from = undefined;
      filters.to = undefined;
      await handleFilterApply();
    };
    const handleFilterApply = async () =>
      await getPage(true, paginationDetails.value);

    const handleShortcutClick = (count: number, unit: string) => {
      let value = new Date();
      if (count > 0) {
        value = sub(value, { [unit]: count });
      }
      handleFilterChange({ name: "from", value });
      handleFilterChange({ name: "to", value: new Date() });
    };

    const handleActionClick = async (actionType) => {
      switch (actionType) {
        case "resendWelcomeEmail": {
          const confirmed = await showModal({
            title: "prompts.resend-welcome-email.title",
            body: "prompts.resend-welcome-email.message",
            onConfirm: async () => {
              return true;
            },
          });
          if (!confirmed || !props.user?.id) {
            return false;
          }
          const res = await sendWelcomeEmail(props.user.id);
          if (res.status === 200) {
            inPageNotification(
              "prompts.welcome-email.sent.title",
              "prompts.welcome-email.sent.message",
              "success"
            );
          } else {
            inPageNotification("common.error", "errors.unexpected", "error");
          }
          break;
        }
        default: {
          break;
        }
      }
      return false;
    };

    onMounted(() => getPage());
    watch(props, () => getPage());

    return {
      isLoading,
      paginationDetails,
      timelineGroups,
      handleChangePage,
      handleChangeRowCount,
      currentSortOrder,
      handleSort,
      filterOptions,
      handleFilterChange,
      handleFilterApply,
      handleFilterReset,
      handleShortcutClick,
      handleActionClick,
    };
  },
});
