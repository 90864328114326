
import { defineComponent, ref } from "vue";
import Search from "@/components/Search.vue";
import Checkbox from "@/components/form/Checkbox.vue";

export default defineComponent({
  props: {
    chip: {
      type: Object,
      required: true,
    },
  },
  components: {
    Search,
    Checkbox,
  },
  emits: ["select-all", "select-chip-item", "toggle-chip"],
  setup(_, context) {
    const selectAll = (filterId: number) =>
      context.emit("select-all", filterId);
    const selectChipItem = (chipIndex: number, chipId: number) =>
      context.emit("select-chip-item", { chipIndex, chipId });

    const toggleChip = (index: number) => {
      context.emit("toggle-chip", index);
    };

    const searchTerm = ref<string>("");
    const filterChipItems = (val: string) => (searchTerm.value = val);

    return {
      searchTerm,
      filterChipItems,
      selectAll,
      selectChipItem,
      toggleChip,
    };
  },
});
