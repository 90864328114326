
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TitleSelect",
  props: {
    id: String,
    name: String,
    value: {
      type: String,
      default: "",
    },
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<any>>,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const handleChange = (e: Event) => {
      const { name, value } = e.currentTarget as any;
      emit("change", { name, value });
    };

    return {
      handleChange,
    };
  },
});
