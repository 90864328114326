import { Action } from "@/models";
import {
  ContactGroup,
  HotspotAppearanceOverride,
  HotspotProgressObject,
  ReviewTemplate,
  Tick,
  TextItem,
} from "@/interfaces/domain";
import httpClient, { ApiResponse } from "@/api/httpClient";
import store from "@/store";

export class Hotspot {
  public actions: Action[];
  public contactGroups: ContactGroup[];
  public hotspotAppearanceOverrides: HotspotAppearanceOverride[];
  public hotspotProgressObjects: HotspotProgressObject[];
  public hotspotType: string;
  public id: string;
  public isMandatory?: boolean;
  public labelOrientation: string;
  public posX: number;
  public posY: number;
  public reviewTemplates: ReviewTemplate[];
  public textItems: TextItem[];
  public ticks: Tick[];
  public userActionHotspot: boolean;
  public welcomeHotspot: boolean;
  public isolatedActions: boolean;
  public order: number;
  public iconFile: any;

  constructor ({
    actions = [] as Action[],
    contactGroups = [] as ContactGroup[],
    hotspotAppearanceOverrides = [] as HotspotAppearanceOverride[],
    hotspotProgressObjects = [] as HotspotProgressObject[],
    hotspotType = "",
    id = "",
    isMandatory = false,
    labelOrientation = "top",
    posX = 0,
    posY = 0,
    reviewTemplates = [] as ReviewTemplate[],
    textItems = [] as TextItem[],
    ticks = [] as Tick[],
    userActionHotspot = false,
    welcomeHotspot = false,
    isolatedActions = false,
    order = 0,
  } = {}) {
    this.actions = actions
      .sort((a, b) =>
        a.position < b.position ? -1 : a.position > b.position ? 1 : 0
      )
      .map((a) => new Action(a));
    this.contactGroups = contactGroups;
    this.hotspotAppearanceOverrides = hotspotAppearanceOverrides;
    this.hotspotProgressObjects = hotspotProgressObjects;
    this.hotspotType = hotspotType;
    this.id = id;
    this.isMandatory = isMandatory;
    this.labelOrientation = labelOrientation;
    this.posX = posX;
    this.posY = posY;
    this.reviewTemplates = reviewTemplates;
    this.textItems = textItems;
    this.ticks = ticks;
    this.userActionHotspot = userActionHotspot;
    this.welcomeHotspot = welcomeHotspot;
    this.isolatedActions = isolatedActions;
    this.order = order;
  }

  public static async getHotspotById(hotspotId: string): Promise<Hotspot> {
    const response: ApiResponse<{ hotspot: Hotspot; progress: number | null }> =
      await httpClient.get(`hotspots/${hotspotId}`);
    if (response.status === 200 && response.data.payload.result) {
      const hotspot = response.data.payload.result.hotspot;
      return new Hotspot(hotspot);
    }
    throw new Error();
  }

  public static async getAllHotspots(trackId = ""): Promise<Hotspot[]> {
    const track = trackId.length > 0 ? trackId : "universal";
    const response: ApiResponse<Hotspot> = await httpClient.get(
      `hotspots/all/${store.getters.currentUser.id}/${track}`
    );
    if (response.status === 200 && response.data.payload.results) {
      return response.data.payload.results;
    }
    throw new Error();
  }
}
