import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ad-Control" }
const _hoisted_2 = {
  key: 0,
  class: "ad-Control-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Choices = _resolveComponent("Choices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.name)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.name), 1))
      : _createCommentVNode("", true),
    (_ctx.type === 'choices')
      ? (_openBlock(), _createBlock(_component_Choices, {
          key: 1,
          checked: _ctx.checked,
          choices: _ctx.choices,
          id: _ctx.id,
          value: _ctx.value,
          onOnChange: _ctx.onChange
        }, null, 8, ["checked", "choices", "id", "value", "onOnChange"]))
      : _createCommentVNode("", true)
  ]))
}