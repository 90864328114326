
import {
  defineComponent,
  ref,
  readonly,
  computed,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { getUsersBasic } from "@/api/users.api";
import { getVideoContents } from "@/api/video.api";
import {
  postVideoContents,
  postVideoSignOff,
  postVideoUrl,
} from "@/api/video-contents.api";
import { getImageContents, postImageContents } from "@/api/image-contents.api";
import { Action, Document } from "@/models";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import FormInput from "@/components/form/FormInput.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/form/Select.vue";
import ImageSelector from "@/components/ImageSelector.vue";

import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";
import { useNotification, useLanguage } from "@/composables";

export default defineComponent({
  components: {
    FileDisplay,
    FileInput,
    FormInput,
    Modal,
    Button,
    Select,
    ImageSelector,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.VideoHotspot);
    const action = toRef(props, "action");
    const updateActive = toRef(props, "updateActive");

    const { translateText } = useLanguage();
    const { inPageNotification } = useNotification();

    const currentUser = store.getters.currentUser;

    const users = ref<any[]>([]);
    const selectedUser = toRef(props, "selectedUser");
    const selectedGroup = toRef(props, "selectedGroup");
    const preferredLanguageId = ref<string>("");

    const showDeleteModal = ref<boolean>(false);
    let refreshSubscription;

    const allowedVideoExtensions = readonly([
      "video/avi",
      "video/mpeg",
      "video/mp4",
      "video/wmv",
      "video/flv",
      "video/mov",
      "video/3gp",
    ]);

    const fileUploadAccept = computed(() => allowedVideoExtensions.join(", "));
    const videoTypeOptions = computed(() => [
      {
        value: "upload",
        text: "action.video.type.file-upload",
      },
      {
        value: "youtube",
        text: "action.video.type.youtube",
      },
    ]);

    const model = ref<any>({
      signature: "",
      file: null,
      placeholder: null,
      placeHolderUrl: "",
      videoType: "upload",
      fileUrl: "",
      fileIsLoading: false,
    });

    const v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      signature: "",
      file: "",
    });

    const getVideo = async () => {
      model.value.file = null;
      model.value.fileUrl = "";
      model.value.fileIsLoading = false;
      model.value.signature = props.action.signatureRequired ? "Yes" : "No";
      model.value.newFile = false;
      try {
        preferredLanguageId.value = currentUser.preferredLanguageId;

        if (
          props.selectedUser ||
          props.selectedGroup ||
          (props.action.level !== "user" && props.action.level !== "group")
        ) {
          // Get Placeholder
          const referencesString = JSON.stringify([
            props.action.videoReference as string,
          ] as string[]);

          const imageResponse = await getImageContents(
            [props.action.videoReference as string],
            "null",
            "admin"
          );
          if (imageResponse.data?.payload?.results) {
            const imageData = imageResponse.data?.payload?.results[0];
            model.value.placeholderUrl = imageData?.url ?? "";
          }
          // End Placeholder

          // Get Video
          const response = await getVideoContents(
            referencesString,
            "admin",
            props.action.level === "group" ? props.selectedGroup ?? "" : "",
            props.action.level === "user" ? props.selectedUser ?? "" : ""
          );
          const videoData = response.data?.payload?.results[0];
          if (videoData) {
            model.value.file = videoData;
            if (videoData.signOffEnabled) {
              model.value.signature = "Yes";
            } else {
              model.value.signature = "No";
            }
            model.value.videoType = "upload";
            if (videoData.youtubeUrl) {
              model.value.fileUrl = videoData.youtubeUrl;
              model.value.videoType = "youtube";
            }
            if (videoData.transcoding) {
              model.value.fileIsLoading = true;
            } else {
              model.value.fileIsLoading = false;
              clearInterval(refreshSubscription);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const readImageFile = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(file);
      });

    const setPlaceholder = (event) => {
      model.value.placeholder = event.value;
      readImageFile(event.value).then(
        (url) => (model.value.placeholderUrl = url)
      );
      validateField("placeholder");
      emit("modifyModel", {
        name: "placeholder",
        value: model.value.placeholder,
      });
    };

    const getPlaceholderPreviewStyle = computed(() => {
      return {
        backgroundImage: `url('${model.value.placeholderUrl}')`,
      };
    });

    const handleEditFile = (file: File) => (model.value.file = file);

    const resetForm = () => {
      model.value.title = "";
      model.value.description = "";
      model.value.signature = "";
      model.value.sideMenuText = "";
      model.value.file = null;
      model.value.documentContents = null;
    };

    const getPlaceholder = computed(() =>
      translateText("form.placeholder.select")
    );

    const signatureItems = ref([
      { text: "references.yes", value: "yes" },
      { text: "references.no", value: "no" },
    ]);

    const isValid = ref<boolean>(false);

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      emit("validUpdate", !v$.value.$error);
    };

    const handleChange = ({ name, value }) => {
      model.value[name] = value;
      if (name == "videoType") {
        let fieldCheck = "file";
        switch (value) {
          case "youtube":
            fieldCheck = "fileUrl";
            break;
        }
        emit("updateSchema", { [fieldCheck]: schema[fieldCheck] });
        validateField(fieldCheck);
      } else {
        validateField(name);
      }
      emit("modifyModel", { name: name, value: model.value[name] });
    };

    const handleAddFile = (file: File) => {
      model.value.newFile = true;
      model.value.file = file;
      validateField("file");
      emit("modifyModel", { name: "file", value: file });
    };

    const handleDownloadFile = async () => {
      if (model.value.documentContents) {
        try {
          model.value.documentContents.download();
        } catch (err) {
          inPageNotification("Error!", "Unable to download file", "error");
        }
      }
      return;
    };

    const getFileSize = (sizeInBytes: number) => {
      if (sizeInBytes < 1000) return `${sizeInBytes} bytes`;
      else if (sizeInBytes < 1000000)
        return `${Math.round(sizeInBytes / 1024)}Kb`;
      else return `${Math.round(sizeInBytes / 1024 / 1024)}Mb`;
    };

    const handleToggleDeleteModal = () => {
      showDeleteModal.value = !showDeleteModal.value;
    };

    const uploadImage = async (
      file: any,
      language: any,
      size: string,
      reference: string,
      blockDelete: string
    ) => {
      const imageFiles = {};
      imageFiles[language] = file;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        imageFiles[language],
        "" + imageFiles[language].name
      );
      formData.append("imageSize", size);
      formData.append("languageId", language);
      formData.append("reference", reference);
      formData.append("blockDelete", blockDelete);
      await postImageContents(formData);
    };

    const uploadVideo = async (file: any, language: any, reference: string) => {
      const videoFiles = {};
      videoFiles[language] = file;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        videoFiles[language],
        "" + videoFiles[language].name
      );

      formData.append("languageId", language);
      formData.append("reference", reference);
      if (props.selectedUser) {
        formData.append("userId", props.selectedUser);
      }
      if (props.selectedGroup) {
        formData.append("groupId", props.selectedGroup);
      }
      await postVideoContents(formData);
    };

    const handleDelete = async () => {
      emit("action-removed", props.action.id);
    };

    onMounted(async () => {
      await getVideo();
      // handleChange({ name: "videoType", value: model.value.videoType });
      emit("isLoaded", true);
    });

    watch(action, async () => {
      await getVideo();
    });

    watch(selectedUser, async () => {
      await getVideo();
    });

    watch(selectedGroup, async () => {
      await getVideo();
    });

    watch(updateActive, async () => {
      try {
        if (updateActive.value) {
          //Update placeholder
          if (model.value.placeholder instanceof Blob) {
            await uploadImage(
              model.value.placeholder,
              preferredLanguageId.value,
              "placeholder",
              props.action.videoReference as string,
              "true"
            );
          }

          if (model.value.videoType == "youtube") {
            const formData: any = {
              signOffEnabled: model.value.signature ?? false,
              reference: props.action.videoReference,
              languageId: preferredLanguageId.value,
              youTubeUrl: model.value.fileUrl,
              userId:
                props.selectedUser && props.selectedUser.length > 0
                  ? props.selectedUser
                  : null,
              groupId:
                props.selectedGroup && props.selectedGroup.length > 0
                  ? props.selectedGroup
                  : null,
            };
            await postVideoUrl(formData);
            await getVideo();
          } else if (model.value.videoType == "upload") {
            // Only upload a file, if there is a file!
            if (model.value.file) {
              model.value.fileIsLoading = true;
              await uploadVideo(
                model.value.file,
                preferredLanguageId.value,
                props.action.videoReference as string
              );
              model.value.newFile = false;
              refreshSubscription = setInterval(getVideo, 10000);
            }
          } else {
            const formData: any = {
              signOffEnabled: model.value.signature ?? false,
              reference: props.action.videoReference,
              languageId: preferredLanguageId.value,
              userId:
                props.selectedUser && props.selectedUser.length > 0
                  ? props.selectedUser
                  : null,
            };
            await postVideoSignOff(formData);
            await getVideo();
          }

          emit("updated", true);
        }
      } catch (err) {
        console.log(err);
        emit("updated", false);
      }
    });

    return {
      isValid,
      schema,
      model,
      errors,
      fileUploadAccept,
      videoTypeOptions,
      translateText,
      showDeleteModal,
      getPlaceholder,
      signatureItems,
      handleChange,
      handleAddFile,
      handleDownloadFile,
      getFileSize,
      handleToggleDeleteModal,
      handleDelete,
      handleEditFile,
      setPlaceholder,
      getPlaceholderPreviewStyle,
    };
  },
});
