import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f44b308"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "col sm:col-12 date-band"
}
const _hoisted_2 = { class: "col sm:col-12 text-left" }
const _hoisted_3 = { class: "chat grid" }
const _hoisted_4 = { class: "chat-avatar flex-row hidden-sm" }
const _hoisted_5 = { class: "chat-bubble flex-row" }
const _hoisted_6 = { class: "chat-time flex-row" }
const _hoisted_7 = {
  key: 0,
  class: "grid"
}
const _hoisted_8 = { class: "col sm:col-12 date-band" }
const _hoisted_9 = { class: "new-message col sm:col-12" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "new-message--input" }
const _hoisted_12 = { class: "new-message--submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["chat-messages grid", _ctx.getChatStyle()])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeChatMessages.chatMessages, (chat, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: chat.id,
          class: _normalizeClass(["col sm:col-12", _ctx.getMessageStyle(chat.userId)])
        }, [
          (_ctx.dateChanged(chat.timestamp) || index === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(chat.timestamp)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Avatar, {
                  size: "sm",
                  image: _ctx.getUserImage(chat.userId),
                  alt: _ctx.getUserInitials(chat.userId),
                  "aria-labelledby": `Profile image for ${_ctx.getUserName(
                  chat.userId
                )}`,
                  text: _ctx.getUserInitials(chat.userId)
                }, null, 8, ["image", "alt", "aria-labelledby", "text"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(chat.text), 1)
              ]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatTime(chat.timestamp)), 1)
            ])
          ])
        ], 2))
      }), 128)),
      (!_ctx.activeChatMessages.chatMessages.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, [
                _createVNode(_component_TranslatedText, { path: "chat.messages.start-messages" })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_FormInput, {
            value: _ctx.newMessage,
            placeholder: "Type something...",
            name: "newMessage",
            id: "newMessage",
            onChange: _ctx.handleInputChange,
            onEnter: _ctx.sendMessage
          }, null, 8, ["value", "onChange", "onEnter"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Button, {
            text: "",
            size: "sm",
            class: "send-message",
            onClick: _ctx.sendMessage
          }, {
            labelBefore: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "paper-plane" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}