
import { computed, defineComponent, PropType, ref, onMounted } from "vue";
import SVG from "@/components/track/manager/track-components/SVG.vue";
import Foot from "@/components/track/manager/track-components/Foot.vue";
import { Hotspot } from "@/models";

export default defineComponent({
  props: {
    hotspots: {
      type: Array as PropType<Hotspot[]>,
      default: () => [],
    },
    points: {
      type: Array,
      default: () => [],
    },
    activePoint: {
      type: Number,
      default: 0,
    },
    curvedLine: {
      type: Boolean,
      default: false,
    },
    edit: Boolean,
    draggedPoint: {
      type: Boolean,
      default: false,
    },
    draggedCubic: {
      type: [Boolean, Number],
      default: false,
    },
    trackIsDirty: {
      type: Boolean,
      default: false,
    },
    customClass: String,
  },
  components: {
    SVG,
    Foot,
  },
  name: "TrackDisplay",
  emits: [
    "setPointType",
    "setPointCoords",
    "setCubicCoords",
    "setDraggedPoint",
    "hotspotClick",
    "setDraggedCubic",
    "cancelDragging",
    "addPoint",
    "removeActivePoint",
    "doubleClick",
    "trackSaved",
  ],
  setup(props, { emit }) {
    const grid = ref<{ show: boolean; snap: boolean; size: number }>({
      show: true,
      snap: true,
      size: 50,
    });
    const w = ref<number>(800);
    const h = ref<number>(600);
    const alt = ref<boolean>(false);
    const isLineCurved = computed(() =>
      props.curvedLine ? props.curvedLine : false
    );

    const trackPadding = ref<number>(0);

    const actualWidth = ref<number>(
      window.innerWidth - 64 - trackPadding.value
    );
    const actualHeight = ref<number>(
      window.innerHeight - 190 - trackPadding.value
    );

    // I know equal width and height isn't landscape, but for the calulation, it doesn't matter.
    const orientation = computed(() =>
      actualWidth.value >= actualHeight.value ? "landscape" : "portrait"
    );

    const ratio = computed(() => {
      return orientation.value === "landscape"
        ? w.value / h.value
        : w.value / h.value;
    });

    const width = computed(() =>
      orientation.value === "landscape"
        ? actualHeight.value * ratio.value
        : actualWidth.value
    );
    const height = computed(() =>
      orientation.value === "landscape"
        ? actualHeight.value
        : actualWidth.value * ratio.value
    );

    const scale = computed(() => {
      var computedScale: string | number = 1;
      if (orientation.value === "landscape" && height.value < h.value) {
        computedScale = (h.value / height.value).toFixed(2);
      } else if (width.value < w.value) {
        computedScale = (w.value / width.value).toFixed(2);
      }
      return +computedScale;
    });

    const size = computed(() => grid.value.size * ratio.value);

    const viewBox = computed(() => `0 0 ${width.value} ${height.value}`);

    const pointsByRatio = computed(() =>
      props.points.map((point: any) => {
        const newPoint = {
          x: (point.x / 100) * width.value,
          y: (point.y / 100) * height.value,
        };
        if (point.c) {
          newPoint["c"] = point.c.map((c) => ({
            x: (c.x / 100) * width.value,
            y: (c.y / 100) * height.value,
          }));
        }
        return newPoint;
      })
    );

    const getMouseCoords = (e) => {
      const rect = document.querySelector("#svg")?.getBoundingClientRect();
      let x = 0;
      let y = 0;
      if (rect) {
        x = Math.round(e.pageX - rect.left);
        y = Math.round(e.pageY - rect.top);
      }

      if (grid.value.snap) {
        x = grid.value.size * Math.round(x / grid.value.size);
        y = grid.value.size * Math.round(y / grid.value.size);
      }

      if (rect) {
        // Don't allow it past the canvas
        if (x > rect.width) {
          x = rect.width;
        }
        if (y > rect.height) {
          y = rect.height;
        }
        if (x < 0) {
          x = 0;
        }
        if (y < 0) {
          y = 0;
        }

        // convert to a percentage...
        x = (x / rect.width) * 100;
        y = (y / rect.height) * 100;
      }
      return { x, y };
    };

    const setPointType = (e) => {
      emit("setPointType", e);
    };

    const setPointCoords = (coords) => {
      emit("setPointCoords", coords);
    };

    const setCubicCoords = (coords, anchor) => {
      emit("setCubicCoords", { coords, anchor });
    };

    const setDraggedPoint = (index) => {
      if (!alt.value && props.edit) {
        emit("setDraggedPoint", { index, draggedPointValue: true });
      } else if (!alt.value) {
        emit("hotspotClick", index);
      }
    };

    const setDraggedCubic = ({ index, anchor }) => {
      if (!alt.value && props.edit) {
        emit("setDraggedCubic", { index, anchor });
      }
    };

    const cancelDragging = () => {
      emit("cancelDragging");
    };

    const addPoint = (e) => {
      if (alt.value && props.edit) {
        let coords = getMouseCoords(e);
        emit("addPoint", coords);
      }
    };

    const removeActivePoint = () => {
      emit("removeActivePoint");
    };

    const handleMouseMove = (e) => {
      if (!alt.value) {
        if (props.draggedPoint) {
          setPointCoords(getMouseCoords(e));
        } else if (props.draggedCubic !== false) {
          setCubicCoords(getMouseCoords(e), props.draggedCubic);
        }
      }
    };

    const handleDoubleClick = (index) => {
      emit("doubleClick", index);
    };

    const handleKeyDown = (e) => {
      if (e.altKey || e.metaKey) alt.value = true;
    };

    const handleKeyUp = (e) => {
      if (!e.altKey && !e.metaKey) alt.value = false;
    };

    const generatePath = () => {
      let d = "";

      pointsByRatio.value.forEach((p: any, i: any) => {
        if (i === 0) {
          // first point
          d += "M ";
        } else if (p.q) {
          // quadratic
          d += `Q ${p.q.x} ${p.q.y} `;
        } else if (p.c) {
          // cubic
          d += `C ${p.c[0].x} ${p.c[0].y} ${p.c[1].x} ${p.c[1].y} `;
        } else if (p.a) {
          // arc
          d += `A ${p.a.rx} ${p.a.ry} ${p.a.rot} ${p.a.laf} ${p.a.sf} `;
        } else {
          d += "L ";
        }

        d += `${p.x} ${p.y} `;
      });

      return d;
    };

    const handleSaveTrack = () => {
      emit("trackSaved");
    };

    onMounted(async () => {
      const rect = document.querySelector("#svg")?.getBoundingClientRect();
      if (rect) {
        actualWidth.value = rect.width;
        actualHeight.value = rect.height;
      }
    });

    return {
      generatePath,
      handleKeyUp,
      handleKeyDown,
      handleMouseMove,
      removeActivePoint,
      addPoint,
      cancelDragging,
      setDraggedCubic,
      setDraggedPoint,
      setPointType,
      handleDoubleClick,
      handleSaveTrack,
      w,
      h,
      alt,
      grid,
      width,
      height,
      size,
      viewBox,
      pointsByRatio,
      scale,
      isLineCurved,
    };
  },
});
