import { AuthenticatedUser } from "@/interfaces";

export default class TextItem {
  replacePlaceHolders = (text: string, userData: AuthenticatedUser): string => {
    if (userData && userData.firstName && userData.surname) {
      const split = text.split("[username]");
      text = split.join(`${userData.firstName} ${userData.surname}`);
    }
    return text;
  };
}
