
import { defineComponent, ref, PropType, watch } from "vue";
import { FormQuestion, TextItem } from "@/interfaces/domain";
import FormInput from "@/components/form/FormInput.vue";
import TextArea from "@/components/form/TextArea.vue";

import BigText from "@/components/form/questionTypes/BigText.vue";
import Text from "@/components/form/questionTypes/Text.vue";
import Title from "@/components/form/questionTypes/Title.vue";
import Prompt from "@/components/form/questionTypes/Prompt.vue";
import Image from "@/components/form/questionTypes/Image.vue";
import ImageUpload from "@/components/form/questionTypes/ImageUpload.vue";
import PdfUpload from "@/components/form/questionTypes/PdfUpload.vue";
import GenericUpload from "@/components/form/questionTypes/GenericUpload.vue";
import Date from "@/components/form/questionTypes/Date.vue";
import Statements from "@/components/form/questionTypes/Statements.vue";
import Checkboxes from "@/components/form/questionTypes/Checkboxes.vue";
import Select from "@/components/form/questionTypes/Select.vue";

import { useLanguage, useTextItem } from "@/composables";
import { VueDraggableNext } from "vue-draggable-next";
import VueResizable from "vue-resizable";

export default defineComponent({
  components: {
    FormInput,
    TextArea,
    BigText,
    Text,
    Title,
    Prompt,
    Image,
    ImageUpload,
    PdfUpload,
    GenericUpload,
    Date,
    Statements,
    Checkboxes,
    Select,
    VueDraggableNext,
    VueResizable,
  },
  props: {
    formQuestions: {
      type: Array as PropType<Array<FormQuestion>>,
      default: () => [],
    },
    userResponses: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    uploadFiles: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    fileSizeLimit: {
      type: String,
      default: () => "1",
    },
    safeHTML: {
      type: String,
      default: "",
    },
    safeUrls: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    errorsRef: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    readonly: Boolean,
    editMode: Boolean,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "doChange",
    "choiceChange",
    "checkboxChange",
    "dateChange",
    "updateFileList",
    "selectChange",
    "changeOrder",
    "resize",
    "edit",
    "remove",
  ],
  setup(props, { emit }) {
    const { sanitizeAndReplaceHTML, getSpecificItem } = useTextItem();
    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };
    const formRef = ref<any>(null);

    const componentMap = ref({
      title: "Title",
      prompt: "Prompt",
      text: "Text",
      bigtext: "BigText",
      image: "Image",
      imageupload: "ImageUpload",
      pdfupload: "PdfUpload",
      genericupload: "GenericUpload",
      "date-of-birth": "Date",
      dob: "Date",
      date: "Date",
      statements: "Statements",
      checkboxes: "Checkboxes",
      select: "Select",
    });
    const dragging = ref(false);
    const errors = ref([]);

    const startDragging = () => {
      dragging.value = true;
    };

    const endDragging = (event) => {
      dragging.value = false;
      emit("changeOrder", {
        id: props.formQuestions[event.newIndex].id,
        oldPosition: props.formQuestions[event.newIndex].position,
        newPosition: event.newIndex + 1,
      });
    };

    let changeTimer;
    const handleChange = (changeEvent) => {
      const { name, value, target } = changeEvent;
      clearTimeout(changeTimer);
      changeTimer = setTimeout(() => {
        doChange(name ?? target.name, value);
      }, 500);
    };
    const handleBlur = (changeEvent) => {
      clearTimeout(changeTimer);
      const { name, value, target } = changeEvent;
      doChange(name ?? target.name, value);
    };

    const doChange = (name, value) => {
      emit("doChange", { name, value });
    };

    const getSelectOptions = (formQuestion: FormQuestion) =>
      formQuestion?.choices
        ? formQuestion.choices
            .sort((c1, c2) => c1.position - c2.position)
            .map((choice) => {
              return {
                value: choice.id,
                text: getTextItem(choice.textItems, "form-question-choice"),
              };
            })
        : [];

    const dependencyCheck = (questionId) => {
      const formQuestion: FormQuestion | undefined = props.formQuestions.find(
        (q) => q.id === questionId
      );

      if (formQuestion && formQuestion?.dependencies?.length > 0) {
        let dependencySatisfied = true;
        formQuestion?.dependencies.forEach((dependency) => {
          if (!dependencySatisfied) return;
          dependencySatisfied =
            props.userResponses[dependency.dependsOn] &&
            props.userResponses[dependency.dependsOn].value ===
              dependency.matching;
        });
        return dependencySatisfied;
      } else {
        return true;
      }
    };

    const handleChoiceChange = (event, type) => {
      if (type === "checkboxes") {
        emit("checkboxChange", event);
      } else {
        emit("choiceChange", event);
      }
    };

    const handleSelectChange = (event) => {
      emit("selectChange", event);
    };

    const checkCheckboxChecked = (formQuestionId, choiceId) => {
      return (
        props.userResponses[formQuestionId]?.value &&
        props.userResponses[formQuestionId]?.value.indexOf(choiceId) >= 0
      );
    };

    const handleDateChange = (event) => {
      emit("dateChange", event);
    };

    const updateFileList = (event, id) => {
      emit("updateFileList", { event, id });
    };

    const sanitizeTextItem = (
      textItems: TextItem[],
      purpose = "form-question"
    ) => {
      const str = sanitizeAndReplaceHTML(
        getSpecificItem(purpose, textItems),
        props.safeHTML
      );
      return str;
    };

    const getFormQuestionColSize = (formQuestion: FormQuestion, size = 6) => {
      if (formQuestion.colSize) {
        return formQuestion.colSize;
      }
      return size;
    };

    const handleResize = (formQuestionId, fieldWidth) => {
      emit("resize", {
        formQuestionId,
        fieldWidth,
      });
    };

    const resizeEnd = (formQuestionId, event) => {
      handleResize(formQuestionId, event.width);
    };

    const handleEditClick = (formQuestionId: string) =>
      emit("edit", { formQuestionId });

    const handleDeleteClick = (formQuestionId: string) =>
      emit("remove", { formQuestionId });

    const getComponentOptions = (formQuestion) => {
      const type = "text";
      return {
        value: props.userResponses[formQuestion.id]?.value,
        error: props.errorsRef[formQuestion.id],
        label:
          getTextItem(formQuestion.textItems, "form-question") ??
          formQuestion.type,
        name: formQuestion.id,
        id: formQuestion.id,
        type: type,
        disabled: props.readonly || props.editMode,
        required: props.userResponses[formQuestion.id]?.required,
        editMode: props.editMode,
        safeHTML: props.safeHTML,
        fileSizeLimit: props.fileSizeLimit,
        choices: formQuestion?.choices ?? [],
        source:
          props.safeUrls[formQuestion.imageReference] ??
          props.uploadFiles[formQuestion.id] ??
          "",
      };
    };

    const { translateText } = useLanguage();

    watch(props, () => {
      if (props?.errorsRef) {
        errors.value = props.errorsRef;
      }
    });

    return {
      componentMap,
      dependencyCheck,
      getSelectOptions,
      getTextItem,
      handleChange,
      handleBlur,
      handleChoiceChange,
      checkCheckboxChecked,
      handleDateChange,
      translateText,
      updateFileList,
      sanitizeTextItem,
      handleSelectChange,
      getFormQuestionColSize,
      startDragging,
      endDragging,
      dragging,
      handleResize,
      resizeEnd,
      handleEditClick,
      handleDeleteClick,
      getComponentOptions,
      formRef,
    };
  },
});
