import { TextItem } from "@/interfaces/domain";
import { ref } from "vue";
import {
  getTextItemFeatureConfigs,
} from "@/api/text-items.api";

export const useTextItem = () => {

  const sanitizeAndReplaceHTML = (string: string, stringWhitelist?: string) => {
    let whitelist = '';
    if (stringWhitelist !== undefined) {
      whitelist = // Ensure whitelist is a string containing only lowercase tags ("<a><b><c>")
        (
          ((stringWhitelist || '') + '')
            .toLowerCase()
            .match(/<[a-z][a-z0-9]*>/g) || []
        ).join('');
    }
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return string
      .replace(commentsAndPhpTags, '')
      .replace(tags, function ($0, $1) {
        return whitelist.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
  };

  const getSpecificItem = (purpose: string, textItems: TextItem[]): string => {
    // Check there is an item to get text from.
    if (textItems) {
      // Get the language and purpose specific item.
      const filteredItems: TextItem[] = textItems.filter(
        (item: TextItem) =>
          item.purpose === purpose
      );

      // Get the appropriate part of the text.
      if (filteredItems.length) {
        return filteredItems[0].data;
      }
    }

    // Fall back.
    return '';
  };

  return {
    sanitizeAndReplaceHTML,
    getSpecificItem,
  };
};
