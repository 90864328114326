import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ccf8ea4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  colspan: 7
}
const _hoisted_7 = { class: "wrapper mx-0" }
const _hoisted_8 = { class: "grid-list" }
const _hoisted_9 = {
  key: 0,
  class: "loading-spinner"
}
const _hoisted_10 = {
  key: 1,
  class: "loading-spinner"
}
const _hoisted_11 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DropdownTableRow = _resolveComponent("DropdownTableRow")!

  return (_openBlock(), _createBlock(_component_DropdownTableRow, {
    isOpen: _ctx.isOpen,
    onClick: _ctx.handleRowClick,
    hasError: _ctx.hasError,
    disableDropdown: _ctx.noData
  }, {
    parent: _withCtx(() => [
      _createElementVNode("td", _hoisted_1, [
        _createVNode(_component_Checkbox, {
          value: _ctx.selected,
          text: "",
          onChange: _ctx.handleSelectedChange
        }, null, 8, ["value", "onChange"])
      ]),
      _createElementVNode("td", _hoisted_2, [
        (_ctx.hasError)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "exclamation-triangle",
              title: _ctx.translateText(`hotspot.status.${_ctx.data.status}`),
              class: "text-red"
            }, null, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", null, _toDisplayString(_ctx.data.firstName), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.data.surname), 1),
      _createElementVNode("td", _hoisted_3, [
        (_ctx.noData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "N/A"))
          : (_openBlock(), _createBlock(_component_Checkbox, {
              key: 1,
              value: true,
              theme: _ctx.data.viewed ? 'green' : 'red',
              icon: _ctx.data.viewed ? 'check' : 'times',
              text: "",
              disabled: ""
            }, null, 8, ["theme", "icon"]))
      ]),
      _createElementVNode("td", {
        class: _normalizeClass(["text-center", { 'text-green': _ctx.data.progress }])
      }, [
        (_ctx.noData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "N/A"))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.data.progress ?? 0) + "% ", 1)
            ], 64))
      ], 2)
    ]),
    child: _withCtx(() => [
      (!_ctx.noData)
        ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "spinner",
                        size: "lg",
                        spin: ""
                      })
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isLoading && !_ctx.rowData?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_TranslatedText, { path: "common.no-data" })
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.rowData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "grid-list-item text-body"
                      }, [
                        _createElementVNode("div", null, [
                          (item.overdue)
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: "exclamation-triangle",
                                title: _ctx.translateText('action.overdue'),
                                class: "text-red item-overdue"
                              }, null, 8, ["title"]))
                            : (_openBlock(), _createBlock(_component_Checkbox, _mergeProps({ key: 1 }, _ctx.getItemCheckboxProps(item), {
                                text: "",
                                disabled: ""
                              }), null, 16))
                        ]),
                        _createElementVNode("div", null, _toDisplayString(item.name), 1)
                      ]))
                    }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Button, {
                size: "sm",
                text: "models.user.view",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/user/' + _ctx.data.id)))
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["isOpen", "onClick", "hasError", "disableDropdown"]))
}