
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { Chip as IChip } from "@/interfaces/ui/Chip.interface";
import Pagination from "@/components/Pagination.vue";
import TableSelection from "@/components/TableSelection.vue";

export default defineComponent({
  props: {
    tableName: {
      type: String,
      required: true,
    },
    totalRows: {
      type: Number,
    },
    selectedRows: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deselectedRows: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    rowCount: {
      type: Number,
    },
    customClass: {
      type: String,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    selectAllActive: {
      type: Boolean,
    },
    actionsChip: {
      type: Object as PropType<IChip>,
    },
    paginated: {
      type: Boolean,
    },
    showTableSelection: {
      type: Boolean,
    },
  },
  emits: [
    "change-page",
    "change-row-count",
    "toggle-all",
    "change-activation-status",
    "clear-selection",
    "toggle-actions-chip",
    "click-action-item",
  ],
  setup(_, context) {
    const store = useStore();
    const changePage = (targetPage: number) =>
      context.emit("change-page", targetPage);
    const changeRowCount = (newRowCount: number) =>
      context.emit("change-row-count", newRowCount);
    const changeActivationStatus = (action: string) =>
      context.emit("change-activation-status", action);
    const clickActionItem = (title: string) =>
      context.emit("click-action-item", title);
    const clearSelection = () => context.emit("clear-selection");
    const toggleActionsChip = () => context.emit("toggle-actions-chip");
    const toggleAll = () => context.emit("toggle-all");

    return {
      store,
      changePage,
      changeRowCount,
      toggleAll,
      changeActivationStatus,
      clearSelection,
      toggleActionsChip,
      clickActionItem,
    };
  },
  components: {
    Pagination,
    TableSelection,
  },
});
