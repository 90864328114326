import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2443ebec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "dots" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, { icon: "ellipsis-v" }),
      _createVNode(_component_font_awesome_icon, { icon: "ellipsis-v" })
    ]),
    _renderSlot(_ctx.$slots, "text", {}, () => [
      _createVNode(_component_TranslatedText, { path: _ctx.text }, null, 8, ["path"])
    ], true),
    _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
  ]))
}