/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAllTranslations } from "@/api/language.api";
import en from "@/lang/en.json";
import fr from "@/lang/fr.json";
import store from "@/store";
import { computed } from "vue";
import { format, parseISO } from "date-fns";

const languages = {
  en,
  fr,
};

export const useLanguage = () => {
  const setLanguage = async (language: string) => {
    await store.dispatch("setLanguage", language);
    await store.dispatch("setLanguageValues", languages[language]);
  };

  const setDatabaseLanguageValues = async (languageId: string) => {
    if (!(Object.keys(databaseLanguageValues.value).length > 0)) {
      const response = await getAllTranslations(languageId);
      const translations: any = response.data.payload;
      if (translations) {
        await store.dispatch("setDatabaseLanguageValues", translations.result[languageId]);
      }
    }
  };

  const getValueForString = (key: string): string => {
    if (databaseLanguageValues.value[key]) {
      return databaseLanguageValues.value[key];
    }
    return languageValues.value[key] ?? key;
  }

  const getLanguages = () => store.getters.availableLanguages;

  const translateText = (path, replacements: any[] = []) => {
    let val = languageValues.value["" + path];
    let valDB = databaseLanguageValues.value["" + path];
    if (typeof valDB === 'undefined' && path) {
      const translations: any = store.getters.currentTenant?.style?.language;
      if (translations && Object.keys(translations).length > 0) {
        valDB = translations[Object.keys(translations)[0]]["" + path];
      }
    }

    if (typeof valDB !== 'undefined') {
      val = valDB;
    }

    if (replacements.length > 0) {
      replacements.forEach(
        (replacement: { placeholder: string; value: string }) => {
          val = val.replace(
            `{{${replacement.placeholder}}}`,
            replacement.value
          );
        }
      );
    }
    return val ?? path;
  };


  const toISOLocal = (d) => {
    try {
        const z = (n) => ("0" + n).slice(-2);
        let off = d.getTimezoneOffset();
        const sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, -1) +
          sign +
          z((off / 60) | 0) +
          ":" +
          z(off % 60)
        );
      } catch {
        return "";
      }
};

  const createDateString = (year: number, month: number, day: number) => {
    if (!year || !month || !day) {
      return '';
    }
    const isoDate = toISOLocal(
        new Date(
          `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`
        )
      );
    return isoDate ? format(parseISO(isoDate), "do MMM yyyy") : '';
  };

  const currentLanguage = computed(() => store.getters.language);
  const languageValues = computed(() => store.getters.languageValues);
  const databaseLanguageValues = computed(() => store.getters.databaseLanguageValues);

  return {
    setLanguage,
    setDatabaseLanguageValues,
    getValueForString,
    languageValues,
    databaseLanguageValues,
    currentLanguage,
    getLanguages,
    translateText,
    createDateString,
  };
};
