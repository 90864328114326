
import { defineComponent, PropType } from "vue";
import { TextItem } from "@/interfaces";
import Boolean from "@/components/Boolean.vue";
import Checkbox from "@/components/Checkbox.vue";
import TableList from "@/components/TableList.vue";
import TableDropdown from "@/components/TableDropdown.vue";

export default defineComponent({
  emits: [
    "toggle-all-rows",
    "handle-click-sort",
    "toggle-actions-chip",
    "click-action-item",
    "toggle-checkbox",
    "click-cell",
    "view-document",
    "bulk-download",
    "bulk-delete",
  ],
  components: {
    Boolean,
    Checkbox,
    TableList,
    TableDropdown,
  },
  props: {
    tableHeadings: {
      type: Object as PropType<any>,
      required: true,
    },
    preparedDocs: {
      type: Object as PropType<any>,
      required: true,
    },
    documents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    documentContents: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    selectedDocuments: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deselectedDocuments: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    actionsChip: {
      type: Object as PropType<any>,
    },
    headingsData: {
      type: Object as PropType<any>,
      required: true,
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
    selectAll: Boolean,
  },
  setup(props, { emit }) {
    const isOdd = (num: number) => Math.abs(num % 2) == 1;
    const toggleAllRows = () => emit("toggle-all-rows");
    const toggleActionsChip = () => emit("toggle-actions-chip");
    const clickActionItem = (title: string) => {
      if (title === "Download") emit("bulk-download");
      else if (title === "Delete") emit("bulk-delete");
    };
    const getDocumentContentId = (reference: string) =>
      props.documentContents.find(
        (document) => document.reference === reference
      )?.id;
    const toggleCheckbox = (reference: string) => {
      const id = getDocumentContentId(reference);
      emit("toggle-checkbox", id);
    };
    const viewDocument = (
      reference: string,
      textItems: TextItem[],
      id: string
    ) => {
      const name = findTextItem("title", textItems);
      const description = findTextItem("description", textItems);
      const sideMenuText = findTextItem("side-menu", textItems);
      emit("view-document", { reference, name, description, sideMenuText, id });
    };

    const handleClickCell = (
      id: string,
      index: number,
      documentReference: string,
      textItems: TextItem[]
    ) => {
      if (window.innerWidth >= 768) {
        viewDocument(documentReference, textItems, id);
      } else {
        emit("click-cell", { id, index });
      }
    };
    const handleClickSort = (title: string, id: string) =>
      emit("handle-click-sort", { title, id });
    const findTextItem = (purpose: string, textItems: TextItem[]) =>
      textItems.find((ti) => ti.purpose === purpose)?.data;
    const getSignOffStatus = (reference: string) => {
      const documentContents = props.documentContents.find(
        (doc) => doc.reference === reference
      );
      if (documentContents) return documentContents.signOffEnabled;
      return false;
    };

    return {
      isOdd,
      toggleAllRows,
      toggleActionsChip,
      clickActionItem,
      toggleCheckbox,
      handleClickCell,
      viewDocument,
      handleClickSort,
      findTextItem,
      getSignOffStatus,
      getDocumentContentId,
    };
  },
});
