
import { defineComponent } from "vue";

export default defineComponent({
  name: "Grid",
  props: {
    grid: {
      type: Object,
      default: () => ({
        size: 0,
        show: false,
      }),
    },
    size: {
      type: Number,
      default: 50,
    },
    w: {
      type: Number,
      default: 0,
    },
    h: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let gridI: any = [];

    for (let i = 1; i < props.w / props.size; i++) {
      gridI.push({
        x1: i * props.size,
        y1: 0,
        x2: i * props.size,
        y2: props.h,
      });
    }

    for (let i = 1; i < props.h / props.size; i++) {
      gridI.push({
        x1: 0,
        y1: i * props.size,
        x2: props.w,
        y2: i * props.size,
      });
    }

    return {
      gridI,
    };
  },
});
