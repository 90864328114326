import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6884e32c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-display-container" }
const _hoisted_2 = { class: "status" }
const _hoisted_3 = { class: "icon-inner" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "secondary-text" }
const _hoisted_6 = { class: "actions" }
const _hoisted_7 = {
  key: 0,
  class: "download-icon icon-wrapper"
}
const _hoisted_8 = {
  key: 1,
  class: "regenerate-icon icon-wrapper"
}
const _hoisted_9 = {
  key: 2,
  class: "edit-icon icon-wrapper"
}
const _hoisted_10 = ["accept"]
const _hoisted_11 = {
  key: 3,
  class: "delete-icon icon-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["icon-background", { isLoading: _ctx.isLoading }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: _normalizeClass(["icon", { spinner: _ctx.isLoading }]),
                size: "lg",
                icon: "spinner"
              }, null, 8, ["class"]))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                class: "icon",
                size: "lg",
                icon: _ctx.validFileIcon
              }, null, 8, ["icon"]))
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "primaryText", {}, () => [
        _createElementVNode("span", {
          class: _normalizeClass(["primary-text", { loading: _ctx.isLoading }])
        }, _toDisplayString(_ctx.fileName), 3)
      ], true),
      _createElementVNode("span", _hoisted_5, [
        _renderSlot(_ctx.$slots, "secondaryText", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.tenant), 1)
        ], true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.downloadable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, {
              class: "icon",
              icon: "download",
              onClick: _ctx.download
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.regeneratable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_font_awesome_icon, {
              class: "icon",
              icon: "refresh",
              onClick: _ctx.regenerate
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.editable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("label", null, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: "pen",
                onClick: _ctx.edit
              }, null, 8, ["onClick"]),
              _createElementVNode("input", {
                class: "edit-file-input",
                type: "file",
                accept: _ctx.editAcceptString,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)))
              }, null, 40, _hoisted_10)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.deletable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_font_awesome_icon, {
              class: "icon",
              icon: "times",
              size: "lg",
              onClick: _ctx.remove
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}