import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31e0ac6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.getTextItem(_ctx.formQuestion?.textItems ?? [], 'form-question')?.length >=
      _ctx.maxLineLength
    )
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.formQuestion.id,
          class: _normalizeClass({
      formRequired: _ctx.userResponse.required,
    })
        }, _toDisplayString(_ctx.getTextItem(_ctx.formQuestion.textItems ?? [], "form-question")), 11, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_FormInput, {
      value: _ctx.userResponse.value,
      error: _ctx.errorsRef[_ctx.formQuestion.id ?? 0],
      label: 
      _ctx.getTextItem(_ctx.formQuestion.textItems ?? [], 'form-question')?.length <
      _ctx.maxLineLength
        ? _ctx.getTextItem(_ctx.formQuestion.textItems ?? [], 'form-question')
        : ''
    ,
      name: _ctx.formQuestion.id,
      id: _ctx.formQuestion.id,
      required: _ctx.userResponse.required,
      disabled: _ctx.readonly,
      autocomplete: _ctx.autocomplete,
      onChange: _ctx.handleChange,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBlur($event)))
    }, null, 8, ["value", "error", "label", "name", "id", "required", "disabled", "autocomplete", "onChange"])
  ], 64))
}