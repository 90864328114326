import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aaaaad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label"
}
const _hoisted_2 = { class: "outerbox" }
const _hoisted_3 = ["onClick", "onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "input-container",
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.handleEdit(_ctx.selectedItem)), ["enter"])),
    onKeydown: [
      _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.handleKeyDown && _ctx.handleKeyDown(...args)), ["prevent"]), ["down"])),
      _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.handleKeyUp && _ctx.handleKeyUp(...args)), ["prevent"]), ["up"]))
    ]
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createVNode(_component_TranslatedText, { path: _ctx.label }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([{ active: _ctx.isActive(item) }, 'innerbox']),
          key: index,
          onClick: ($event: any) => (_ctx.handleSelectItem(item)),
          onMouseover: ($event: any) => (_ctx.handleSelectItem(item)),
          onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.handleEdit(_ctx.selectedItem)), ["enter"]))
        }, [
          _createVNode(_component_TranslatedText, {
            path: item.text
          }, null, 8, ["path"]),
          _createVNode(_component_font_awesome_icon, {
            icon: "pen",
            class: "icon-edit",
            onClickCapture: ($event: any) => (_ctx.handleEdit(item))
          }, null, 8, ["onClickCapture"])
        ], 42, _hoisted_3))
      }), 128))
    ])
  ], 32))
}