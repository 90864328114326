import { ValidationArgs } from "@vuelidate/core";
import {
  email,
  helpers,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";

export { useLanguage } from "@/composables";
const translateText = (key: string) => {
  return key;
};

export enum Schemas {
  Address = "Address",
  PhoneNumber = "PhoneNumber",
  EmergencyContact = "EmergencyContact",
  UserDetails = "UserDetails",
  CompanyDetails = "CompanyDetails",
  Reference = "Reference",
  Payroll = "Payroll",
  CharacterReference = "CharacterReference",
  EmployerReference = "EmployerReference",
  RefereeReference = "RefereeReference",
  RefereeCharacterReference = "RefereeCharacterReference",
  RefereeEmployerReference = "RefereeEmployerReference",
  WelcomeHotspot = "WelcomeHotspot",
  ParticularsHotspot = "ParticularsHotspot",
  VideoHotspot = "VideoHotspot",
  UploadDocument = "UploadDocument",
  InfoContent = "InfoContent",
  ActionContent = "ActionContent",
}

export const date = helpers.regex(
  /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/
);

export const customValidators = {
  ukSortCode: helpers.regex(/^[0-9]{2}[-]?[0-9]{2}[-]?[0-9]{2}$/),
  ukAccountNumber: helpers.regex(/^(\d){7,8}$/),
  ukPostCode: helpers.regex(
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
  ),
  required: required,
};

export const SCHEMAS: { [k in Schemas]: ValidationArgs } = {
  [Schemas.Address]: {
    line1: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    city: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    county: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    postcode: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    country: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.PhoneNumber]: {
    countryCode: {
      required: helpers.withMessage(
        translateText("validation.country-code.missing"),
        required
      ),
      numeric: helpers.withMessage(
        translateText("validation.country-code.invalid"),
        numeric
      ),
    },
    number: {
      required: helpers.withMessage(
        translateText("validation.contact-number.missing"),
        required
      ),
      numeric: helpers.withMessage(
        translateText("validation.contact-number.invalid"),
        numeric
      ),
      minLength: helpers.withMessage(
        translateText("validation.contact-number.invalid"),
        minLength(10)
      ),
    },
  },
  [Schemas.EmergencyContact]: {
    name: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.UserDetails]: {
    firstName: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    surname: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    dob: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    email: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
      email: helpers.withMessage(
        translateText("validation.email-address.invalid"),
        email
      ),
    },
    secondaryEmail: {
      email: helpers.withMessage(
        translateText("validation.email-address.invalid"),
        email
      ),
    },
    preferredLanguageId: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.CompanyDetails]: {},
  [Schemas.Reference]: {
    email: {
      email: helpers.withMessage(
        translateText("validation.email-address.invalid"),
        email
      ),
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    dateFrom: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    dateTo: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    name: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    telephoneNumber: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.EmployerReference]: {
    company: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    userPosition: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    referencePosition: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.CharacterReference]: {
    howKnown: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.RefereeReference]: {
    dateFrom: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    dateTo: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.RefereeEmployerReference]: {
    userPosition: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.RefereeCharacterReference]: {
    infoCorrect: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.Payroll]: {
    bankName: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    sortCode: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    accountNumber: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    accountName: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.ActionContent]: {
    title: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.WelcomeHotspot]: {
    title: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    videoUpload: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.ParticularsHotspot]: {
    hotspotTitle: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    hotspotDesc: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    actions: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.VideoHotspot]: {
    file: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
    fileUrl: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.UploadDocument]: {
    signature: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
  [Schemas.InfoContent]: {
    signature: {
      required: helpers.withMessage(
        translateText("validation.generic.missing"),
        required
      ),
    },
  },
};
