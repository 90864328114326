
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { NotificationState } from "@/store/modules";
import Alert from "./Alert.vue";

export default defineComponent({
  name: "Notification",
  components: { Alert },
  setup() {
    const store = useStore();
    const state = computed<NotificationState>(() => store.getters.notification);
    const icon = computed(() =>
      state.value.type === "error" ? "exclamation-triangle" : ""
    );
    return {
      state,
      icon,
    };
  },
});
