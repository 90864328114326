import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c1f4d5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consent-declaration" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _createVNode(_component_TranslatedText, { path: _ctx.line1 }, null, 8, ["path"])
    ]),
    (_ctx.line2)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createVNode(_component_TranslatedText, { path: _ctx.line2 }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, [
      (_ctx.signatureType === 'date-of-birth')
        ? (_openBlock(), _createBlock(_component_TranslatedText, {
            key: 0,
            path: "declaration.sub"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      valid: _ctx.valid,
      "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
      schema: _ctx.rules,
      errors: _ctx.errors,
      "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.errors) = $event))
    }, _createSlots({ _: 2 }, [
      (_ctx.signatureType === 'date-of-birth')
        ? {
            name: "default",
            fn: _withCtx(({ handleInputChange }) => [
              _createVNode(_component_Checkbox, {
                name: "consent",
                id: "consent",
                text: _ctx.consentText,
                value: _ctx.model.consent,
                onChange: handleInputChange
              }, null, 8, ["text", "value", "onChange"]),
              _createVNode(_component_DatePicker, {
                id: "dob",
                name: "dob",
                label: "form.date-of-birth",
                class: "dob-input",
                customClass: "required",
                placeholder: "form.date-of-birth.format",
                maxDate: new Date().toISOString().split('T')[0],
                value: _ctx.model.dob,
                error: _ctx.errors['dob'],
                onChange: handleInputChange,
                required: ""
              }, null, 8, ["maxDate", "value", "error", "onChange"]),
              _createVNode(_component_Button, {
                text: "declaration.sign.submit",
                size: "sm",
                onClick: _ctx.handleSignClick,
                disabled: !_ctx.valid
              }, null, 8, ["onClick", "disabled"])
            ])
          }
        : undefined
    ]), 1032, ["modelValue", "valid", "schema", "errors"])
  ]))
}