import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionHeader = _resolveComponent("ActionHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, {
      hotspotDescription: _ctx.hotspotDescription,
      actionTitle: _ctx.actionTitle,
      actionDescription: _ctx.actionDescription
    }, null, 8, ["hotspotDescription", "actionTitle", "actionDescription"]),
    _createElementVNode("div", {
      innerHTML: _ctx.content?.data,
      class: "grid styled-content info-content"
    }, null, 8, _hoisted_1)
  ]))
}