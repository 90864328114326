
import { defineComponent, PropType, watch, ref, onMounted } from "vue";
import { Address } from "@/models";
import Card from "@/components/Card.vue";
import AddressForm from "@/components/user/shared/AddressForm.vue";
import DynamicList from "@/components/DynamicList.vue";
import { Schemas, useSchema } from "@/plugins/schema";

export default defineComponent({
  components: {
    Card,
    AddressForm,
    DynamicList,
  },
  props: {
    addresses: {
      type: Array as PropType<Array<Partial<Address>>>,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["save", "delete"],
  setup(props, { emit }) {
    const bankAddresses = ref<Array<Partial<Address>>>([]);
    const disabled = ref(false);
    const addressSchema = useSchema(Schemas.Address);
    const getAddressTitle = (isPrimary: boolean) =>
      isPrimary ? "models.address.primary" : "";

    const handleSave = (address: Address) => emit("save", new Address(address));
    const handleDelete = (address: Address) =>
      emit("delete", new Address(address));

    const createNewUserAddress = () => new Address();

    onMounted(() => {
      bankAddresses.value = props.addresses;
      disabled.value = props.readonly;
    });

    watch(props, () => {
      bankAddresses.value = props.addresses;
      disabled.value = props.readonly;
    });

    return {
      handleSave,
      handleDelete,
      getAddressTitle,
      createNewUserAddress,
      addressSchema,
      bankAddresses,
      disabled,
    };
  },
});
