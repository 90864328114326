
import { computed } from "@vue/reactivity";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  name: "Select",
  props: {
    label: String,
    value: {
      type: String,
      default: "",
    },
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<Array<{ text: string; value: string }>>,
    },
  },
  emits: ["edit", "change", "moveUp", "moveDown"],
  setup(props, { emit }) {
    const selectedItem = computed(() =>
      props.items?.find((item) => item.value === props.value)
    );
    const isActive = (item: { text: string; value: string }) =>
      item.value === props.value;
    const handleEdit = (item: { text: string; value: string } | undefined) =>
      emit("edit", item);
    const handleKeyDown = () => emit("moveDown");
    const handleKeyUp = () => emit("moveUp");
    const handleSelectItem = (item: { text: string; value: string }) =>
      emit("change", item);
    return {
      handleEdit,
      handleSelectItem,
      isActive,
      handleKeyDown,
      handleKeyUp,
      selectedItem,
    };
  },
});
