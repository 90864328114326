import { App, inject } from "vue";
import { Schemas, SCHEMAS, customValidators } from "./schemas";
import { ValidationArgs } from "@vuelidate/core";

import { helpers } from "@vuelidate/validators";
import { useLanguage } from "@/composables";

export const schemaSymbol = Symbol();
export type GetSchemaFunction = (name: Schemas) => ValidationArgs;

export default {
  install: (app: App): void => {
    const { translateText } = useLanguage();
    const getSchemaByName: GetSchemaFunction = (name: Schemas) => {
      const fcValidators =
        app.config.globalProperties.$store.getters.featureConfigs.find(
          (fc) => fc.feature === "customValidators"
        )?.condition ?? false;

      if (fcValidators) {
        const validators = JSON.parse(fcValidators);
        Object.entries(validators).forEach(([key, obj]: any) => {
          const validator = Object.keys(obj)[0];
          const translationKey = Object.values(obj)[0];
          if (SCHEMAS[name][key] && customValidators[validator]) {
            SCHEMAS[name][key][validator] = helpers.withMessage(
              translateText(translationKey),
              customValidators[validator]
            );
          }
        });
      }

      return SCHEMAS[name];
    };

    app.config.globalProperties.$getSchema = getSchemaByName;
    app.provide(schemaSymbol, getSchemaByName);
  },
};

export const useSchema = (name: Schemas): ValidationArgs => {
  const plugin = inject<GetSchemaFunction>(schemaSymbol);
  if (!plugin) throw new Error("Schema plugin not installed");

  return plugin(name);
};

export { Schemas };
