
import { defineComponent, computed, PropType, ref, onMounted } from "vue";
import { Hotspot } from "@/models";
import { useStore } from "vuex";
import anime from "animejs";

export default defineComponent({
  name: "Point",
  emits: ["setDraggedPoint", "doubleClick"],
  props: {
    item: {
      type: Object as PropType<Hotspot>,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    scale: {
      type: Number,
    },
    edit: Boolean,
    completed: Boolean,
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dropdownVisible = ref<boolean>(false);
    const store = useStore();

    const dropdownStyle = ref<any>({});

    const tickPoints = computed(() => {
      return `${props.x - 7}, ${props.y + 1} ${props.x - 1}, ${props.y + 5} ${
        props.x + 6
      }, ${props.y - 4}`;
    });
    const labelOrientation = computed(() => props.item.labelOrientation);
    const customHotspots = computed(
      () =>
        store.getters.featureConfigs.find(
          (fc) => fc.feature === "enable-custom-hotspots"
        )?.config ?? false
    );

    const setDraggedPoint = (index) => {
      emit("setDraggedPoint", index);
    };

    const handleDoubleClick = (index) => {
      emit("doubleClick", index);
    };

    const title = computed(() => {
      const title = props.item.textItems.find((ti) => ti.purpose === "title");
      return title?.data ?? "";
    });

    const description = computed(() => {
      const description = props.item.textItems.find(
        (ti) => ti.purpose === "summary"
      );
      return description?.data ?? "";
    });

    const isDropdownStatic = computed(() => {
      return labelOrientation.value.indexOf("-static") >= 0;
    });

    const progressCompleteText = computed(() => {
      const progress = props.item.hotspotProgressObjects.length
        ? props.item.hotspotProgressObjects[0].progressPercentage
        : 0;
      return `(${progress}% complete)`;
    });

    const dropdownWidth = computed(() => {
      let length =
        title.value.length > progressCompleteText.value.length
          ? title.value?.length * 11
          : progressCompleteText.value.length * 12;
      if (isDropdownStatic.value && length > hotspotDiameter) {
        length = hotspotInfoWidth;
      } else if (!isDropdownStatic.value && length > 350) {
        length = 350;
      }

      return length;
    });
    onMounted(async () => {
      let defaultSpacing = parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue("--hotspot-info-spacing")
          .replace(/[^0-9.-]/g, "")
      );

      if (isNaN(defaultSpacing)) {
        defaultSpacing = 10;
      }

      const elementId = `point-dropdown-${props.index}`;
      let translateDirection = "";
      let translateType = "Y";
      let translateSize = defaultSpacing;

      const gSpot = document.querySelector(`#${elementId}`);

      if (gSpot) {
        let infoContainer = gSpot.querySelectorAll(
          ".ad-PointDropdown-Container"
        );
        const divElement = infoContainer[0] ?? null;

        const elemHeight = divElement?.clientHeight ?? 70;
        const elemWidth = divElement?.clientWidth ?? 100;

        switch (labelOrientation.value) {
          case "top":
            translateType = "Y";
            translateDirection = "-";
            translateSize += elemHeight;
            break;
          case "top-static":
            translateType = "Y";
            translateDirection = "-";
            translateSize += elemHeight;
            break;
          case "bottom":
            translateType = "Y";
            translateSize += hotspotDiameter;
            break;
          case "bottom-static":
            translateType = "Y";
            translateSize += hotspotDiameter;
            break;
          case "left":
            translateType = "X";
            translateDirection = "-";
            translateSize += elemWidth / 2 + hotspotDiameter;
            break;
          case "right":
            translateType = "X";
            translateSize += elemWidth / 2 + hotspotDiameter;
            break;
        }
      }

      dropdownStyle.value[
        elementId
      ] = `transform: translate${translateType}(${translateDirection}${translateSize}px)`;
    });

    const toggleDropdown = (val: boolean) => {
      const positions = {
        top: -60,
        right: dropdownWidth.value / 2 + 20,
        bottom: 52,
        left: -Math.abs(dropdownWidth.value / 2 + 20),
      };
      const index = props.index ? props.index : 0;
      const el = document.getElementById(`point-dropdown-${index}`);
    };

    let hotspotImageWidth = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--hotspot-image-width")
        .replace(/[^0-9.-]/g, "")
    );
    if (isNaN(hotspotImageWidth)) {
      hotspotImageWidth = 50;
    }

    let hotspotImageHeight = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--hotspot-image-height")
        .replace(/[^0-9.-]/g, "")
    );
    if (isNaN(hotspotImageHeight)) {
      hotspotImageHeight = 50;
    }

    let hotspotDiameter = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--hotspot-size")
        .replace(/[^0-9.-]/g, "")
    );

    if (isNaN(hotspotDiameter)) {
      hotspotDiameter = 50;
    } else if (props?.scale) {
      hotspotDiameter = hotspotDiameter / props.scale;
    }

    let hotspotInfoWidth = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--hotspot-info-width")
        .replace(/[^0-9.-]/g, "")
    );

    if (isNaN(hotspotInfoWidth)) {
      hotspotInfoWidth = hotspotDiameter;
    }

    const checkActiveHotspot = computed(() => {
      return props.isActive;
    });

    const pulseStart = computed(() => {
      const startPoint = hotspotDiameter / 2 - 20;

      return startPoint > 0 ? startPoint : 0;
    });

    const pulseEnd = computed(() => {
      return hotspotDiameter / 2 + 20;
    });

    const completedIcon = computed(() => {
      return require("@/assets/img/check-mark.png");
    });

    const getDimentions = (p, type, val) => {
      const c = val - hotspotDiameter / 4; // (val-(hotspotDiameter/2)-2.5)*1.1;
      const d = (hotspotDiameter + 5) / 3;

      switch (p) {
        case "x":
        case "y":
          return c;
        case "width":
        case "height":
          return d;
      }

      return 0;
    };

    const getDrowndownContainerStyle = () => {
      if (props?.scale && props.scale !== 1) {
        return `font-size: ${(props.scale - 1) * 100}%`;
      }
      return "";
    };

    return {
      tickPoints,
      setDraggedPoint,
      handleDoubleClick,
      title,
      dropdownWidth,
      dropdownVisible,
      toggleDropdown,
      progressCompleteText,
      labelOrientation,
      hotspotDiameter,
      hotspotImageWidth,
      hotspotImageHeight,
      checkActiveHotspot,
      pulseStart,
      pulseEnd,
      completedIcon,
      getDimentions,
      dropdownStyle,
      getDrowndownContainerStyle,
      customHotspots,
    };
  },
});
