
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    value: Boolean,
  },
  setup(props) {
    const icon = computed(() => {
      if (props.value) return "check";
      else return "times";
    });

    const val = computed(() => (props.value ? "true" : "false"));

    return { icon, val };
  },
});
