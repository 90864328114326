import httpClient, { ApiResponse } from "./httpClient";

export const getTextItemFeatureConfigs = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/textitems/feature-configs`);

export const putHotspotTextItems = async (
  hotspotType: string,
  body: { textItems: { title: string; description: string } }
): Promise<ApiResponse<any>> =>
  httpClient.put(`/hotspots/update-text-items/${hotspotType}`, body);

export const postActionTextItems = async (
  actionType: string,
  body: { textItems: { title: string; description: string } }
): Promise<ApiResponse<any>> =>
  httpClient.post(`/actions/text-items/${actionType}`, body);

export const postActionTextItemUpdate = async (body: {
  textItemIds: string[];
  value: string;
}): Promise<ApiResponse<any>> => httpClient.post(`/textitems/update`, body);

export const updateTextItems = async (textItems, model) => {
  let newTextItems: any[] = [];
  const descriptionId =
    textItems.find((ti) => ti.purpose === "description")?.id ?? "";
  const titleId = textItems.find((ti) => ti.purpose === "title")?.id ?? "";
  const sideMenuTextId =
    textItems.find((ti) => ti.purpose === "side-menu")?.id ?? "";

  newTextItems = textItems.map((textItem) => {
    if (textItem.purpose === "description") {
      textItem.data = model.description as string;
    } else if (textItem.purpose === "title") {
      textItem.data = model.title as string;
    } else if (textItem.purpose === "side-menu") {
      textItem.data = model.sideMenuText as string;
    }
    return textItem;
  });

  if (descriptionId) {
    await postActionTextItemUpdate({
      textItemIds: [descriptionId],
      value: model.description as string,
    });
  }
  if (titleId) {
    await postActionTextItemUpdate({
      textItemIds: [titleId],
      value: model.title as string,
    });
  }

  if (sideMenuTextId) {
    await postActionTextItemUpdate({
      textItemIds: [sideMenuTextId],
      value: model.sideMenuText as string,
    });
  }

  return newTextItems;
};
