
import { computed, defineComponent, PropType } from "vue";
import { ContactNumber } from "@/models";
import TelephoneInput, {
  TelephoneNumber,
} from "@/components/form/TelephoneInput.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import Radio from "@/components/form/Radio.vue";

export default defineComponent({
  components: {
    TelephoneInput,
    Checkbox,
    Radio,
  },
  props: {
    contactNumber: {
      type: Object as PropType<Partial<ContactNumber>>,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add", "update"],
  setup(props, { emit }) {
    const disablePrimaryCheckbox = !!props.contactNumber.isPrimary;
    const handleContactNumberUpdate = (telNo: TelephoneNumber) => {
      emit("update", { name: "countryCode", value: telNo.countryCode });
      emit("update", { name: "number", value: telNo.number });
    };

    const setPrimary = ({ value }) => {
      emit("update", { name: "isPrimary", value });
    };

    const isPublicStringVal = computed(() =>
      (props.contactNumber?.isPublic ?? false).toString()
    );
    const setPublic = (value: string) => {
      emit("update", { name: "isPublic", value: value === "true" });
    };

    return {
      isPublicStringVal,
      disablePrimaryCheckbox,
      handleContactNumberUpdate,
      setPrimary,
      setPublic,
    };
  },
});
