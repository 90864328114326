
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { Reference } from "@/models";
import Card from "@/components/Card.vue";
import UserReferenceForm from "@/components/user/shared/UserReferenceForm.vue";
import UserReferenceProgress from "@/components/user/shared/UserReferenceProgress.vue";
import DynamicList from "@/components/DynamicList.vue";
import Button from "@/components/Button.vue";
import useValidate, { ValidationArgs } from "@vuelidate/core";
import { Schemas, useSchema } from "@/plugins/schema";
import { isValid, format, parseISO } from "date-fns";
import { useStore } from "vuex";
import { usePermissionProvider } from "@/plugins/permission";
import { getTenantConfig } from "@/api/tenant-config.api";
import { useLanguage } from "@/composables";

export default defineComponent({
  components: {
    Card,
    UserReferenceForm,
    UserReferenceProgress,
    DynamicList,
    Button,
  },
  props: {
    references: {
      type: Array as PropType<Array<Reference>>,
      required: false,
      default: () => [],
    },
    referenceType: {
      type: String,
    },
    limit: {
      type: Number,
      required: false,
    },
    userReference: {
      type: Boolean,
      default: false,
    },
    actionCompleted: {
      type: Boolean,
      default: false,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save", "delete", "update"],
  setup(props, { emit }) {
    const store = useStore();
    let tenant = store.getters.currentTenant.name;
    let showAnswers = ref({});
    const getReferenceName = () => "";
    const updated = ref(false);
    const saveButtonText = ref("Save");
    const handleSave = (reference: Reference) =>
      emit("save", {
        reference: new Reference(reference),
        saveType: saveButtonText.value,
      });

    const handleAdminAction = (reference: Reference, saveType: string) =>
      emit("save", {
        reference: new Reference(reference),
        saveType: saveType,
      });
    const handleUpdate = () => {
      updated.value = true;
    };
    const handleDelete = (reference: Reference) =>
      emit("delete", new Reference(reference));

    const mandatoryFields = ref(
      "name,telephoneNumber,email,dateRanges,howKnown,address,company,userPosition,referencePosition"
    );

    onMounted(async () => {
      const response = await getTenantConfig("mandatory-references-fields");
      const tenantConfig = response.data.payload.result;
      if (tenantConfig && tenantConfig.condition?.length > 0) {
        mandatoryFields.value = tenantConfig.condition;
      }
    });

    const createNewReference = () => new Reference();

    const { translateText, createDateString } = useLanguage();

    const formatDate = (date) => {
      return isValid(parseISO(date))
        ? format(parseISO(date), "do MMM yyyy")
        : date ?? null;
    };

    const schema: ValidationArgs = useSchema(Schemas.Reference);
    const employerReferenceSchema: ValidationArgs = useSchema(
      Schemas.EmployerReference
    );
    const characterReferenceSchema: ValidationArgs = useSchema(
      Schemas.CharacterReference
    );
    const addressSchema: ValidationArgs = useSchema(Schemas.Address);

    const isReferenceInProgress = (reference: Reference) =>
      !reference?.referenceRecord?.approved && !props.actionCompleted;

    const permissionProvider = usePermissionProvider();
    const errorsRef = ref({});

    const valid = ref(false);
    const validateField = (name: string, v$) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errorsRef.value[name] = error;
        } else {
          delete errorsRef.value[name];
        }
      }
    };
    const updateReference = async (e, formItem) => {
      if (e.subName) {
        formItem[e.subName][e.name] = e.value;
      } else {
        formItem[e.name] = e.value;
      }
      let vEmp$ = useValidate(
        employerReferenceSchema,
        formItem.employerReference
      );
      let vChar$ = useValidate(
        characterReferenceSchema,
        formItem.characterReference
      );

      let vAddress$;
      if (props.referenceType === "employer") {
        vAddress$ = useValidate(
          addressSchema,
          formItem.employerReference.address
        );
      }
      let v$ = useValidate(schema, formItem);

      if (formItem.presentDay) {
        formItem.dateTo = new Date().toISOString();
      }

      if (mandatoryFields.value.includes(e.name)) {
        if (e.subName === "employerReference") {
          if (e.name === "address") {
            validateField(e.name, vAddress$);
          } else {
            validateField(e.name, vEmp$);
          }
        } else if (e.subName === "characterReference") {
          validateField(e.name, vChar$);
        } else {
          validateField(e.name, v$);
        }
      }

      valid.value =
        (await v$.value.$validate()) &&
        (props.referenceType === "character" ||
          ((await vEmp$.value.$validate()) &&
            (!mandatoryFields.value.includes("address") ||
              (await vAddress$.value.$validate())))) &&
        (props.referenceType === "employer" ||
          (await vChar$.value.$validate()));
    };

    const disabled = computed(
      () =>
        !permissionProvider.userHasPermission("users:references:update") &&
        !!permissionProvider.userHasPermission("users:references:create")
    );

    const isReferenceValid = (reference: Reference) =>
      valid.value ||
      (!props.userReference &&
        reference &&
        reference.referenceRecord?.userCompleted);

    const showReference = (r) =>
      (showAnswers.value[r?.id] = !showAnswers.value[r?.id]);

    const tenantConfigs = {
      north: {
        employer: [
          { key: "capacity", field: "capacity" },
          { key: "summary", field: "summary" },
          {
            key: "additional-information",
            field: "additionalInformation",
          },
        ],
        character: [
          {
            key: "additional-information",
            field: "additionalInformation",
          },
        ],
      },
      mww: {
        employer: [
          {
            key: "reason",
            field: "reasonForLeaving",
          },
          {
            key: "reemploy",
            field: "reemploy",
          },
          {
            key: "summary",
            field: "summary",
          },
          { key: "competence", field: "competence" },
          {
            key: "withoutSupervision",
            field: "withoutSupervision",
          },
          {
            key: "underPressure",
            field: "underPressure",
          },
          {
            key: "planning",
            field: "planning",
          },
          {
            key: "manageOthers",
            field: "manageOthers",
          },
          {
            key: "leadership",
            field: "leadership",
          },
          {
            key: "reliability",
            field: "reliability",
          },
          {
            key: "punctuality",
            field: "punctuality",
          },
          {
            key: "attendance",
            field: "attendance",
          },
          {
            key: "honesty",
            field: "honesty",
          },
          {
            key: "efficiency",
            field: "efficiency",
          },
          {
            key: "communications",
            field: "communications",
          },
          {
            key: "relationships",
            field: "relationships",
          },
          {
            key: "telephone",
            field: "telephone",
          },
          {
            key: "telephoneEnquiry",
            field: "telephoneEnquiry",
          },
        ],
      },
    };

    return {
      showReference,
      tenantConfigs: tenantConfigs[store.getters.currentTenant.name] ?? [],
      disabled,
      saveButtonText,
      handleSave,
      handleAdminAction,
      handleUpdate,
      handleDelete,
      isReferenceInProgress,
      getReferenceName,
      createNewReference,
      schema,
      formatDate,
      tenant,
      showAnswers,
      updateReference,
      mandatoryFields,
      errorsRef,
      isReferenceValid,
      translateText,
      createDateString,
    };
  },
});
