
import { defineComponent, watch, ref, computed } from "vue";
import CheckBox from "@/components/form/Checkbox.vue";
import Button from "@/components/Button.vue";
import BackgroundSelector from "@/components/BackgroundSelector.vue";
import LogoSelector from "@/components/LogoSelector.vue";

const readImageFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(file);
  });

export default defineComponent({
  name: "SetUpPartOne",
  components: {
    CheckBox,
    Button,
    BackgroundSelector,
    LogoSelector,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const getImageUrl = async (image) => {
      if (image instanceof Blob) {
        const imageFile = await readImageFile(image);
        return imageFile;
      }
      return image;
    };

    const favicon = ref(props.model.favicon);
    const platformLogo = ref(props.model.platformLogo);
    const signinLogo = ref(props.model.signinLogo);
    const backgroundUrl = ref(props.model.backgroundImage);
    const backgroundUrlTrack = ref(props.model.backgroundImageTrack);
    const backgroundImageSVG = ref(props.model.backgroundImageSVG);
    const useLogoAcrossPlatform = ref(props.model.useLogoAcrossPlatform);
    const backgroundColour = ref(props.model.backgroundColour.signin);
    const backgroundColourTrack = ref(props.model.backgroundColourTrack.app);

    watch(props, async () => {
      if (props.model) {
        favicon.value = await getImageUrl(props.model.favicon);
        platformLogo.value = await getImageUrl(props.model.platformLogo);
        signinLogo.value = await getImageUrl(props.model.signinLogo);
        backgroundUrl.value = await getImageUrl(props.model.backgroundImage);
        backgroundUrlTrack.value = await getImageUrl(
          props.model.backgroundImageTrack
        );
        backgroundImageSVG.value = await getImageUrl(
          props.model.backgroundImageSVG
        );
        useLogoAcrossPlatform.value = props.model.useLogoAcrossPlatform;
        backgroundColour.value = props.model.backgroundColour;
        backgroundColourTrack.value = props.model.backgroundColourTrack;
      }
    });

    const setModel = (key, value) => {
      emit("update", {
        name: key,
        value,
      });
    };

    const handleGenericInput = (ev) => setModel(ev.name, ev.value);
    const handleSaveCustomisation = () => {
      emit("saveCustomisation");
    };

    const setBackground = (event) => {
      handleGenericInput(event);
      if (event.name === "backgroundImage") {
        readImageFile(event.value).then((url) => (backgroundUrl.value = url));
      }
    };

    const getSigninPreviewStyle = computed(() => {
      return {
        backgroundColor: backgroundColour.value,
        backgroundImage: `url('${backgroundUrl.value}')`,
      };
    });

    const getTrackPreviewStyle = computed(() => {
      return {
        backgroundColor: backgroundColourTrack.value,
        backgroundImage: `url('${backgroundUrlTrack.value}')`,
      };
    });

    const getTrackImagePreviewStyle = computed(() => {
      return {
        backgroundColor: backgroundColourTrack.value,
        backgroundImage: `url('${backgroundImageSVG.value}')`,
      };
    });

    const setFavicon = (file) => {
      setModel("favicon", file);
      readImageFile(file).then((url) => (favicon.value = url));
    };

    const setSigninLogo = (file) => {
      setModel("signinLogo", file);
      readImageFile(file).then((url) => (signinLogo.value = url));
    };

    const setPlatformLogo = (file) => {
      setModel("platformLogo", file);
      readImageFile(file).then((url) => (platformLogo.value = url));
      if (props.model.useLogoAcrossPlatform) {
        setSigninLogo(file);
      }
    };

    const setTrackBackground = (event) => {
      event.name = `${event.name}Track`;
      handleGenericInput(event);
      if (event.name === "backgroundImageTrack") {
        readImageFile(event.value).then(
          (url) => (backgroundUrlTrack.value = url)
        );
      }
    };

    const setTrackImage = (event) => {
      event.name = `${event.name}SVG`;
      handleGenericInput(event);
      if (event.name === "backgroundImageSVG") {
        readImageFile(event.value).then(
          (url) => (backgroundImageSVG.value = url)
        );
      }
    };

    return {
      handleSaveCustomisation,
      handleGenericInput,
      setModel,
      platformLogo,
      signinLogo,
      setBackground,
      backgroundUrl,
      backgroundUrlTrack,
      backgroundImageSVG,
      favicon,
      useLogoAcrossPlatform,
      backgroundColour,
      backgroundColourTrack,
      getSigninPreviewStyle,
      getTrackPreviewStyle,
      getTrackImagePreviewStyle,
      setFavicon,
      setSigninLogo,
      setPlatformLogo,
      setTrackBackground,
      setTrackImage,
    };
  },
});
