
import { computed, defineComponent, PropType } from "vue";
import FormInput from "@/components/form/FormInput.vue";
import Form from "@/components/Form.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { FormInput, Form, Wysiwyg },
  props: {
    modelValue: {
      type: Object as PropType<{
        title: string;
        description: string;
        adminDescription?: string;
      }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const levelSelectOptions = computed(() => store.getters.allAudienceOptions);
    const typeSelectOptions = computed(() => store.getters.allActionTypes);

    const handleInputChange = ({ name, value }) => {
      model.value[name] = value;
    };

    const handleDataChange = async ({ value }) => {
      model.value.description = value;
    };

    return {
      model,
      typeSelectOptions,
      levelSelectOptions,
      handleInputChange,
      handleDataChange,
    };
  },
});
