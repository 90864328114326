import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-560c10a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "tab-button visible-lg"
}
const _hoisted_3 = {
  key: 0,
  class: "tab"
}
const _hoisted_4 = { class: "tab__icon" }
const _hoisted_5 = { class: "tab__content" }
const _hoisted_6 = { class: "text-body" }
const _hoisted_7 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_8 = { class: "declaration-container__inner" }
const _hoisted_9 = { class: "button-container hidden-lg" }
const _hoisted_10 = {
  key: 0,
  class: "mobile-signed-prompt"
}
const _hoisted_11 = { class: "type-subtitle-02" }
const _hoisted_12 = { class: "mobile-signed-prompt__title" }
const _hoisted_13 = {
  key: 0,
  class: "text-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tick = _resolveComponent("Tick")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_ActionHeader = _resolveComponent("ActionHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Declaration = _resolveComponent("Declaration")!
  const _component_DocumentViewer = _resolveComponent("DocumentViewer")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ActionHeader, {
      hotspotDescription: _ctx.hotspotDescription,
      actionTitle: _ctx.actionTitle,
      actionDescription: _ctx.actionDescription,
      buttonData: _ctx.buttonData,
      onHandleButtonClick: _ctx.handleDownloadClick
    }, _createSlots({ _: 2 }, [
      (_ctx.actionProgress?.completed)
        ? {
            name: "actionDescription",
            fn: _withCtx(() => [
              _createElementVNode("p", null, [
                _createVNode(_component_Tick, {
                  active: "",
                  round: "",
                  style: {"margin-right":"1rem"}
                }),
                _createElementVNode("span", null, [
                  _createVNode(_component_TranslatedText, {
                    path: 
                _ctx.documentContent?.signOffEnabled
                  ? 'declaration.sign.complete'
                  : 'declaration.view.complete'
              
                  }, null, 8, ["path"])
                ]),
                (_ctx.signedDate)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, " - " + _toDisplayString(_ctx.signedDate), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          }
        : undefined
    ]), 1032, ["hotspotDescription", "actionTitle", "actionDescription", "buttonData", "onHandleButtonClick"]),
    (_ctx.documentContent)
      ? (_openBlock(), _createBlock(_component_DocumentViewer, {
          key: 0,
          src: _ctx.documentContent.url,
          onDocumentLoaded: _ctx.handleDocumentLoaded
        }, {
          default: _withCtx(() => [
            (_ctx.documentContent?.signOffEnabled && !_ctx.viewOnly)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.actionProgress?.completed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_font_awesome_icon, { icon: "check" })
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_TranslatedText, { path: "declaration.sign.complete" })
                          ]),
                          (_ctx.signedDate)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_TranslatedText, { path: "common.on" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.signedDate), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        text: "declaration.sign",
                        size: "sm",
                        onClick: _ctx.handleSignClick
                      }, {
                        labelAfter: _withCtx(() => [
                          (!_ctx.wetSignature)
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: _ctx.showSign ? 'chevron-down' : 'chevron-up'
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                ]))
              : _createCommentVNode("", true),
            (!_ctx.wetSignature && !_ctx.viewOnly)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["declaration-container visible-lg", { show: _ctx.showSign }])
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_Declaration, {
                      modelValue: _ctx.declaration,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.declaration) = $event)),
                      isValid: _ctx.isValid,
                      "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValid) = $event)),
                      line1: _ctx.getDocumentDeclaration
                    }, null, 8, ["modelValue", "isValid", "line1"]),
                    _createVNode(_component_Button, {
                      text: "declaration.submit",
                      size: "sm",
                      disabled: !_ctx.isValid,
                      onClick: _ctx.handleSignSubmit
                    }, null, 8, ["disabled", "onClick"])
                  ])
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              (_ctx.documentContent?.signOffEnabled && !_ctx.viewOnly)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.actionProgress?.completed)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("span", _hoisted_12, [
                              _createVNode(_component_TranslatedText, { path: "declaration.sign.complete" })
                            ]),
                            _createVNode(_component_Tick)
                          ]),
                          (_ctx.signedDate)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createVNode(_component_TranslatedText, { path: "common.on" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.signedDate), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          text: "declaration.sign",
                          size: "sm",
                          onClick: _ctx.handleSignClick
                        }, null, 8, ["onClick"]))
                  ], 64))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                text: "common.download-file",
                size: "sm",
                disabled: !_ctx.documentContent,
                onClick: _ctx.handleDownloadClick,
                variant: "secondary"
              }, null, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["src", "onDocumentLoaded"]))
      : _createCommentVNode("", true),
    (_ctx.wetSignature)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          modelValue: _ctx.showModal,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
          title: "signature.title",
          showFooter: false,
          onCancel: _ctx.handleModalHide
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Signature, {
              modelValue: _ctx.declarationWet,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.declarationWet) = $event)),
              onConfirm: _ctx.handleSignSubmit,
              onCancel: _ctx.handleModalHide
            }, null, 8, ["modelValue", "onConfirm", "onCancel"])
          ]),
          _: 1
        }, 8, ["modelValue", "onCancel"]))
      : _createCommentVNode("", true),
    (!_ctx.wetSignature)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          modelValue: _ctx.showModal,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showModal) = $event)),
          title: _ctx.tabButtonText,
          onConfirm: _ctx.handleSignSubmit,
          onCancel: _ctx.handleModalHide,
          onDismiss: _ctx.handleModalHide
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Declaration, {
              modelValue: _ctx.declaration,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.declaration) = $event)),
              isValid: _ctx.isValid,
              "onUpdate:isValid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isValid) = $event)),
              line1: _ctx.getDocumentDeclaration
            }, null, 8, ["modelValue", "isValid", "line1"])
          ]),
          footer: _withCtx(({ confirm, cancel }) => [
            _createVNode(_component_Button, {
              text: "declaration.submit",
              size: "sm",
              disabled: !_ctx.isValid,
              onClick: confirm
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_Button, {
              text: "common.cancel",
              size: "sm",
              outline: "",
              onClick: cancel
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["modelValue", "title", "onConfirm", "onCancel", "onDismiss"]))
      : _createCommentVNode("", true)
  ]))
}