
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  reactive,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import { format, parseISO } from "date-fns";
import { HttpError } from "@/api/httpClient";
import { Action, Hotspot, ActionProgress } from "@/models";
import { useModal } from "@/plugins/modal";
import Button from "@/components/Button.vue";
import { FormQuestion, TextItem } from "@/interfaces/domain";
import {
  getFormQuestionsByAction,
  getFormQuestionsUserResponsesByActionAndUser,
  signForm,
  uploadFormFile,
} from "@/api/form-questions.api";
import FormQuestions from "@/components/form/FormQuestions.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import { useFormQuestion, useLanguage, useTextItem } from "@/composables";
import Signature from "@/components/Signature.vue";
import Modal from "@/components/Modal.vue";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";
import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    Button,
    FormQuestions,
    Signature,
    Modal,
    ActionHeader,
    Declaration,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const showModal = useModal();
    const { sanitizeAndReplaceHTML, getSpecificItem } = useTextItem();
    const safeHTML = ref("");
    const fileSizeLimit = ref("");
    const maxLineLength = 35;
    const { setDependencies, updateDependencies, setImages } =
      useFormQuestion();

    const { inPageNotification } = useNotification();

    const showSign = ref(false);
    const handleSignClick = async () => {
      checkValidity(true);
      if (Object.keys(errorsRef.value).length === 0) {
        if (
          !signatureEnabled.value ||
          (signatureEnabled.value && !wetSignature.value)
        ) {
          await handleSignSubmit();
        } else {
          showSign.value = !showSign.value;
        }
      } else {
        for (const questionId of Object.keys(errorsRef.value)) {
          const qError = formQuestions.value.find((fq) => fq.id === questionId);
        }
        inPageNotification(
          "form.error-validation-title",
          "form.error-validation",
          "error"
        );
      }
    };

    const showSignModal = computed(() => showSign.value);
    const handleModalHide = () => {
      declarationWet.signature = "";
      declaration.consent = false;

      declaration.dob = "";

      showSign.value = false;
    };

    const sanitizeTextItem = (textItems: TextItem[]) => {
      const str = sanitizeAndReplaceHTML(
        getSpecificItem("form-question", textItems),
        safeHTML.value
      );
      return str;
    };

    const errorsRef = ref<any>({});

    const addErrors = (
      formQuestionId: string,
      errorString: string,
      translationString: string
    ) => {
      if (
        !errorsRef.value[formQuestionId] ||
        errorsRef.value[formQuestionId] != translationString
      ) {
        errorsRef.value[formQuestionId] = translationString;
      }
    };

    const removeErrors = (formQuestionId: string, errorString: string) => {
      if (errorsRef.value[formQuestionId]) {
        delete errorsRef.value[formQuestionId];
      }
    };

    const getSelectOptions = (formQuestion: FormQuestion) =>
      formQuestion?.choices
        ? formQuestion.choices
            .sort((c1, c2) => c1.position - c2.position)
            .map((choice) => {
              return {
                value: choice.id,
                text: getTextItem(choice.textItems, "form-question-choice"),
              };
            })
        : [];
    const checkValidity = (forceValidationCheck = false) => {
      if (
        !wetSignature.value &&
        (!signatureEnabled.value || !declarationIsValid.value)
      ) {
        return false;
      }
      if (!isDirty.value) {
        return true;
      }
      for (const formQuestionId of Object.keys(userResponses.value)) {
        if (
          userResponses.value[formQuestionId].changed ||
          (forceValidationCheck && dependencyCheck(formQuestionId))
        ) {
          let userResponseValue = userResponses.value[formQuestionId].value;
          if (
            userResponses.value[formQuestionId]?.validators &&
            userResponses.value[formQuestionId].validators.length > 0
          ) {
            for (const validator of userResponses.value[formQuestionId]
              .validators) {
              const formQuestion = formQuestions.value.find(
                (fq) => fq.id === formQuestionId
              );
              const errorString = `-${validator.validatorType}`;
              let activeError: boolean | string = false;
              if (errorsRef.value[formQuestionId]) {
                const stringMatch =
                  errorsRef.value[formQuestionId].match(/(-[a-zA-Z0-9]*)/);
                activeError = stringMatch[0] ?? stringMatch;
              }
              if (!activeError || activeError === errorString) {
                let userResponseHasError = true;

                switch (validator.validatorType) {
                  case "required":
                    if (
                      ["genericupload", "pdfupload", "imageupload"].includes(
                        formQuestion?.questionType as string
                      )
                    ) {
                      userResponseHasError = !uploadFiles.value[formQuestionId];
                    } else {
                      userResponseHasError =
                        !userResponseValue || userResponseValue === "";
                    }
                    break;
                  case "number":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue.match(/^[0-9]+$/) ||
                        userResponseValue === ""
                      );
                    break;
                  case "sumequalsonehundred":
                    // Todo: We need to do something better with this in future, but for now i'm just disregarding this check
                    userResponseHasError = false;
                    break;

                  case "decimal":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue.match(/^[0-9.]+$/) ||
                        userResponseValue === ""
                      );
                    break;
                  case "name":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue.match(/^[a-zA-Z\-\s']+$/) ||
                        userResponseValue === ""
                      );

                    break;
                  case "email":
                    userResponseHasError =
                      userResponseValue &&
                      !userResponseValue.match(
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
                      );
                    break;
                  case "ukphone":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue
                          .replaceAll(" ", "")
                          .match(/^(^\+\d{6,14})|(^\d{6,15})$/) ||
                        userResponseValue === ""
                      );
                    break;
                  case "sortcode":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue.match(/^\d{2}(-|)\d{2}\1\d{2}$/) ||
                        userResponseValue === ""
                      );
                    break;
                  case "accountnumber":
                    userResponseHasError =
                      userResponseValue &&
                      !(
                        userResponseValue.match(/^\w{1,17}$/) ||
                        userResponseValue === ""
                      );
                    break;
                }
                if (userResponseHasError) {
                  addErrors(
                    formQuestionId,
                    errorString,
                    `form.error-${validator.validatorType}`
                  );
                } else {
                  removeErrors(formQuestionId, errorString);
                }
              }
            }
          }
        }
      }
      return Object.keys(errorsRef.value).length === 0;
    };

    const modelIsValid = computed(() => {
      return checkValidity();
    });

    const isDirty = ref(false);
    const formQuestions = ref<Array<FormQuestion>>([]);

    const storageKey = computed(
      () => `fd-${props.action.id}${currentUser.value?.id}`
    );

    const currentUser = computed(() => store.getters.currentUser);

    const errors = ref<{ [K: string]: string }>({});

    const dependencyCheck = (questionId) => {
      const formQuestion: FormQuestion | undefined = formQuestions.value.find(
        (q) => q.id === questionId
      );

      if (formQuestion && formQuestion?.dependencies.length > 0) {
        let dependencySatisfied = true;
        formQuestion?.dependencies.forEach((dependency) => {
          if (!dependencySatisfied) return;
          dependencySatisfied =
            userResponses.value[dependency.dependsOn] &&
            userResponses.value[dependency.dependsOn].value ===
              dependency.matching;
        });
        return dependencySatisfied;
      } else {
        return true;
      }
    };

    const postChanges = ref<any[]>([]);

    const doChanges = () => {
      if (!props.actionProgress?.completed) {
        postChanges.value.forEach((t) => {
          switch (t.type) {
            case "statements":
              handleChoiceChange({ target: t });
              break;
            case "text":
              handleChange({ name: t.name, value: t.value, target: t });
              break;
            case "date":
            case "dob":
            case "date-of-birth":
              handleDateChange({ name: t.name, value: t.value });
              break;
          }
        });
      }
    };

    const handleChoiceChange = (event) => {
      if (event?.target) {
        const { name, value } = event.target;
        if (!isDirty.value) isDirty.value = true;
        updateDependencies(name, value, userResponses.value);

        doChange(name, value);
      }
    };

    const handleSelectChange = ({ name, value }) => {
      if (!isDirty.value) isDirty.value = true;
      updateDependencies(name, value, userResponses.value);

      doChange(name, value);
    };

    const checkCheckboxChecked = (formQuestionId, choiceId) => {
      return (
        userResponses.value[formQuestionId].value &&
        userResponses.value[formQuestionId].value.indexOf(choiceId) >= 0
      );
    };

    const handleCheckboxChange = (event) => {
      if (!isDirty.value) isDirty.value = true;
      const regex = new RegExp(",?" + event.target.value, "g");

      // Remove the current item out of responses and then add it back in if it's checked
      if (userResponses.value[event.target.name].value) {
        userResponses.value[event.target.name].value = userResponses.value[
          event.target.name
        ].value.replace(regex, "");
      }
      // Add the current item into the responses if it's checked
      if (event.target.checked) {
        userResponses.value[event.target.name].value = (
          (userResponses.value[event.target.name].value ?? "") +
          "," +
          event.target.value
        ).replace(/(^,)|(,$)/g, "");
      }
      //Check dependants
      userResponses.value[event.target.name].dependantQuestions.forEach(
        (fq: FormQuestion) => {
          userResponses.value[fq.id].hide =
            fq.dependencies.filter(
              (dependency) =>
                userResponses.value[dependency.dependsOn].value !==
                dependency.matching
            ).length > 0;
        }
      );
      doChange(event.target.name, userResponses.value[event.target.name].value);
    };

    const handleDateChange = ({ name, value }) => {
      if (!isDirty.value) isDirty.value = true;
      userResponses.value[name].changed = true;
      userResponses.value[name].value = new Date(value).toISOString();

      doChange(name, userResponses.value[name].value);
    };

    const handleDoChange = ({ name, value }) => doChange(name, value);

    const doChange = (name, value) => {
      if (name && userResponses.value[name]) {
        userResponses.value[name].changed = true;
        if (!isDirty.value) isDirty.value = true;
        userResponses.value[name].value = value;

        // Store the entry in local storage
        localStorage.setItem(`${storageKey.value}${name}`, value);
      }
      checkValidity();
    };

    const clearStorage = (key) => {
      var arr: string[] = []; // Array to hold the keys
      // Iterate over localStorage and insert the keys that meet the condition into arr
      for (var j = 0; j < localStorage.length; j++) {
        if (
          localStorage &&
          localStorage.key(j)?.substring(0, key.length) == key
        ) {
          const test = localStorage.key(j) as string;
          arr.push(test);
        }
      }

      // Iterate over arr and remove the items by key
      for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
      }
    };

    let changeTimer;
    const handleChange = (changeEvent) => {
      const { name, value, target } = changeEvent;
      clearTimeout(changeTimer);
      changeTimer = setTimeout(() => {
        doChange(name ?? target.name, value);
      }, 500);
    };
    const handleBlur = (changeEvent) => {
      clearTimeout(changeTimer);
      const { name, value, target } = changeEvent;
      doChange(name ?? target.name, value);
    };

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      if (textItems?.length) {
        const textItem = textItems.find((ti) => ti.purpose === purpose);
        return textItem?.data ?? "";
      }
      return "";
    };

    const declarationIsValid = ref(false);
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const declarationWet = reactive<{ signature: string }>({
      signature: "",
    });

    const completionDateString = computed(() =>
      props.actionProgress?.completionDate
        ? format(
            parseISO(props.actionProgress.completionDate),
            "dd/MM/yyyy HH:mm"
          )
        : ""
    );

    const { translateText } = useLanguage();

    const handleSignSubmit = async () => {
      try {
        isDirty.value = false;

        const uploadError = {};
        let uploadErrors = 0;

        // Establish object for storing sign off details.
        const signOff = {
          userId: currentUser.value.id,
        };

        if (signatureEnabled.value && !wetSignature.value) {
          const dob = declaration.dob.split("-");
          signOff["sign"] = declaration.consent;
          signOff["dobYear"] = dob[0];
          signOff["dobMonth"] = dob[1];
          signOff["dobDay"] = dob[2];
        }

        // Prepare user responses to be sent.
        const userChoices: {
          formQuestionId: string;
          responseValue: string;
          userId: string;
        }[] = [];

        for (const formQuestionId of Object.keys(userResponses.value)) {
          let userResponseValue = userResponses.value[formQuestionId].value;
          const formQuestion: FormQuestion = formQuestions.value.find(
            (fq: FormQuestion) => fq.id == formQuestionId
          ) as FormQuestion;

          if (!userResponses.value[formQuestionId].hide) {
            // Ensure user responses are correctly formatted as readable values.
            if (formQuestion) {
              switch (formQuestion.questionType) {
                case "imageupload":
                  if (uploadFiles.value[formQuestionId]) {
                    userResponseValue = await uploadFile(
                      uploadFiles.value[formQuestionId],
                      formQuestion.id
                    );
                  }
                  break;
                case "pdfupload":
                  if (uploadFiles.value[formQuestionId]) {
                    userResponseValue = await uploadFile(
                      uploadFiles.value[formQuestionId],
                      formQuestion.id,
                      "pdf"
                    );
                  }
                  break;
                case "genericupload":
                  if (uploadFiles.value[formQuestionId]) {
                    userResponseValue = await uploadFile(
                      uploadFiles.value[formQuestionId],
                      formQuestion.id,
                      "file"
                    );
                  }
                  break;
                case "date-of-birth":
                case "date":
                  break;
                case "bigtext":
                  if (userResponseValue !== null) {
                    userResponseValue = userResponseValue.replace(
                      /(?:\r\n|\r|\n)/g,
                      " "
                    );
                  }
                  break;
                case "checkboxes":
                  userResponseValue = "";
                  if (userResponses.value[formQuestionId].value) {
                    userResponses.value[formQuestionId].value
                      .split(",")
                      .map((cb: any) => {
                        const formQuestionChoice: any =
                          formQuestion.choices.find(
                            (item: any) => item.id === cb
                          );
                        userResponseValue +=
                          getTextItem(
                            formQuestionChoice?.textItems ?? [],
                            "form-question-choice"
                          ) + ",";
                      });
                  }
                  userResponseValue = userResponseValue.substring(
                    0,
                    userResponseValue.length - 1
                  );
                  break;
                case "select":
                case "statements": {
                  const formQuestionChoice: any = formQuestion.choices.find(
                    (item: any) => item.id === userResponseValue
                  );
                  userResponseValue =
                    getTextItem(
                      formQuestionChoice?.textItems ?? [],
                      "form-question-choice"
                    ) + ",";
                  userResponseValue = userResponseValue.substring(
                    0,
                    userResponseValue.length - 1
                  );
                  break;
                }
              }
            }

            if (userResponseValue && userResponseValue.type) {
              uploadErrors++;
              uploadError[formQuestionId] = userResponseValue.type;
              inPageNotification(
                "form.error-upload-title",
                "form.error-upload",
                "error"
              );
            }

            // Create the form question.
            if (userResponseValue !== "" && userResponseValue !== null) {
              userChoices.push({
                formQuestionId,
                responseValue: userResponseValue,
                userId: currentUser.value.id,
              });
            }
          }
        }
        if (uploadErrors === 0) {
          await signForm(props.action.id, userChoices, signOff);
          let response;
          if (!wetSignature.value) {
            response = await props.action.completeAction(
              currentUser.value.id,
              declaration.dob,
              declaration.consent
            );
          } else if (!signatureEnabled.value) {
            response = await props.action.completeActionWet(
              currentUser.value.id
            );
          } else {
            response = await props.action.completeActionWet(
              currentUser.value.id,
              declarationWet.signature
            );
          }

          inPageNotification(
            "form.completed-title",
            "form.completed",
            "success"
          );

          // Kill off anything we've saved...
          clearStorage(`${storageKey.value}`);
          emit("action-complete", { progressObj: response });
          showSign.value = false;
          declarationWet.signature = "";
          declaration.consent = false;
          declaration.dob = "";
        }
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("declaration.sign.error", httpError.message);
        }
      }
    };

    const uploadFile = async (
      file: File,
      reference: string,
      type = "image"
    ) => {
      const formData: FormData = new FormData();
      formData.append("file", file);
      formData.append(
        "reference",
        props.actionTitle?.replace(/[^a-zA-Z]/g, "").toLowerCase() +
          "-" +
          type +
          "-" +
          reference
      );
      formData.append("userId", currentUser.value.id);

      try {
        const uploaded: any = await uploadFormFile(formData);
        return uploaded?.data?.payload?.result?.id || "";
      } catch {
        return {
          type: (type === "image" ? "images" : "documents") + ".upload.fail",
          reference,
        };
      }
    };

    const safeUrls = ref([]);
    const userResponses = ref({});
    const signatureEnabled = ref(true);
    const dobSignatureRequired = ref(false);
    const wetSignature = ref(true);

    const getFormQuestions = async () => {
      formQuestions.value = [];
      userResponses.value = {};
      uploadFiles.value = {};
      if (props.actionProgress?.completed) {
        const response = await getFormQuestionsUserResponsesByActionAndUser(
          props.action.id,
          currentUser.value.id
        );
        formQuestions.value = response.data.payload
          .results as Array<FormQuestion>;
      } else {
        //Reset form declaration if not complete
        handleModalHide();

        fileSizeLimit.value =
          store.getters.featureConfigs.find(
            (fc) => fc.feature === "userDocumentMaxSize"
          )?.condition ?? 1;

        signatureEnabled.value =
          store.getters.featureConfigs.find((fc) => fc.feature === "sign-off")
            ?.condition ?? true;

        const signatureType =
          store.getters.featureConfigs.find(
            (fc) => fc.feature === "signature-type"
          )?.condition ?? null;

        dobSignatureRequired.value = signatureType === "date-of-birth";
        wetSignature.value = signatureType === "wet";

        const response = await getFormQuestionsByAction(
          props.action.id,
          currentUser.value.id
        );
        formQuestions.value = response.data.payload
          .results as Array<FormQuestion>;
      }
      const imageReferences: string[] = [];
      for (const formQuestion of formQuestions.value) {
        if (formQuestion.questionType === "image") {
          imageReferences.push(formQuestion.imageReference);
        }
        if (
          !props.actionProgress?.completed &&
          formQuestion.questionType !== "prompt"
        ) {
          const dependantQuestions = setDependencies(
            formQuestion,
            formQuestions.value
          );
          setInitialUserResponses(formQuestion, dependantQuestions);
        }
      }
      await setImages(imageReferences, currentUser.value.id, safeUrls.value);
      await doChanges();
    };

    onMounted(async () => {
      safeHTML.value =
        store.getters.featureConfigs.find(
          (fc) => fc.feature === "allowed-html-tags"
        )?.condition ?? "";
      await getFormQuestions();
    });
    watch(props, () => {
      errorsRef.value = {};
      isDirty.value = false;
      getFormQuestions();
      checkValidity();
    });

    const uploadFiles = ref({});

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;

      return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
    };

    const updateFileList = ({ event, id }) => {
      const fileList: FileList = event.target["files"];
      uploadFiles.value[id] = [];
      userResponses.value[id].value = "";
      if (fileList.length > 0) {
        if (formatBytes(fileList[0].size) <= fileSizeLimit.value) {
          uploadFiles.value[id] = fileList[0];
          userResponses.value[id].changed = true;
          if (!isDirty.value) isDirty.value = true;
          userResponses.value[id].value = fileList[0];
        } else {
          inPageNotification(
            "form.error-file-too-large-title",
            translateText("form.error-file-too-large", [
              {
                placeholder: "fileSize",
                value: fileSizeLimit.value,
              },
            ]),
            "error"
          );
        }
      }
    };

    onBeforeRouteLeave(async () => {
      if (isDirty.value) {
        const confirmed = await showModal({
          title: "prompts.unsaved-changes.title",
          body: "prompts.unsaved-changes.message",
          onConfirm: async () => {
            return true;
          },
        });
        if (!confirmed) {
          return false;
        }
      }
    });

    function setInitialUserResponses(
      formQuestion: FormQuestion,
      dependantQuestions: FormQuestion[]
    ) {
      const storeValue = localStorage.getItem(
        `${storageKey.value}${formQuestion.id}`
      );

      userResponses.value[formQuestion.id] = {
        value: storeValue,
        hide: formQuestion.dependencies?.length > 0,
        dependantQuestions,
        validators: formQuestion.validators,
        required: formQuestion.validators
          ? formQuestion.validators.filter(
              (validator) => validator.validatorType === "required"
            ).length > 0
          : false,
        changed: false,
      };

      if (storeValue && !props.actionProgress?.completed) {
        postChanges.value.push({
          type: formQuestion.questionType,
          name: formQuestion.id,
          value: storeValue,
        });
      }
    }

    return {
      completionDateString,
      declarationWet,
      declaration,
      errors,
      modelIsValid,
      isDirty,
      formQuestions,
      safeUrls,
      safeHTML,
      userResponses,
      getTextItem,
      updateFileList,
      handleSignSubmit,
      handleChange,
      handleBlur,
      handleDateChange,
      handleChoiceChange,
      handleCheckboxChange,
      checkCheckboxChecked,
      uploadFiles,
      sanitizeTextItem,
      translateText,
      fileSizeLimit,
      errorsRef,
      maxLineLength,
      getSelectOptions,
      showSignModal,
      handleModalHide,
      showSign,
      handleSignClick,
      dobSignatureRequired,
      wetSignature,
      declarationIsValid,
      dependencyCheck,
      handleDoChange,
      handleSelectChange,
    };
  },
});
