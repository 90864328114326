// register new widgets here to make them available in Dashboard

import ReportWidget from "./widgets/ReportWidget.vue";
import DashProgress from "./widgets/DashProgress.vue";
import DashUserLimit from "./widgets/DashUserLimit.vue";
import DashMyTeam from "./widgets/DashMyTeam.vue";
import UserActivity from "./widgets/UserActivity.vue";
import PicOfTheDay from "./widgets/PicOfTheDay.vue";
import DashUserWelcome from "./widgets/DashUserWelcome.vue";
import DashUserGeneric from "./widgets/DashUserGeneric.vue";
import DashGroupsSummary from "./widgets/DashGroupsSummary.vue";

export const widgetComponents = {
  userProgress: {
    component: ReportWidget,
    name: "User Progress",
    description: "Link to the User Progress report",
  },
  pulseFeedback: {
    component: ReportWidget,
    name: "Pulse Feedback Widget",
    description: "Link to the Pulse Feedback report",
  },
  progress: {
    component: DashProgress,
    name: "Your Progress",
    description: "Visualise your journey's progress",
  },
  userLimit: {
    component: DashUserLimit,
    name: "User Limit",
    description: "See a user count and related warnings",
  },
  myTeam: {
    component: DashMyTeam,
    name: "My Team",
    description: "Quickly find other members of your team",
  },
  userActivity: {
    component: UserActivity,
    name: "User Activity",
    description: "See user activity stats at a glance",
  },
  potd: {
    component: PicOfTheDay,
    name: "Pic of the Day",
    description: "A different photo every day",
  },
  userWelcome: {
    component: DashUserWelcome,
    name: "User Welcome Emails",
    description: "See users that have not recieved a welcome email",
  },
  genericUsers: {
    component: DashUserGeneric,
    name: "Your Users",
    description: "",
  },
  groupsSummary: {
    component: DashGroupsSummary,
    name: "User Summary",
    description: "An overview of your users and cohorts",
  },
};
