<template>
  <widget-layout
    title="My Team"
    :minHeight="2"
    :maxHeight="4"
    :loading="!data"
    :menu="menuItems"
  >
    <ul
      class="my-team"
      v-if="data"
      :style="{ '--hCellWidth': width }"
      :class="{ large: userData.large }"
    >
      <li v-for="(member, idx) in sortedMembers" :key="idx">
        <a :href="member.url">
          <span class="avatar-container">
            <span class="avatar">
              <img
                :src="member.avatar"
                @load="$event.target.classList.add('loaded')"
              />
            </span>
            <span :class="`status ${member.status}`" :title="member.status" />
          </span>
          <span class="name">{{ member.name }}</span>
          <span class="role">{{ member.role }}</span>
        </a>
      </li>
    </ul>
  </widget-layout>
</template>
<script>
import WidgetLayout from "../WidgetLayout.vue";
import { defineComponent, computed } from "vue";

export default defineComponent({
  components: { WidgetLayout },
  name: "DashMyTeam",
  props: ["width", "userData", "data"],
  setup(props, { emit }) {
    const menuItems = () => {
      const check = (cond) => (cond ? "☒" : "☐");
      return [
        {
          caption:
            check(props.userData && props.userData.large) + "   Large tiles",
          action: () => {
            emit("userdata", { large: !props.userData.large });
          },
        },
      ];
    };

    const sortedMembers = computed(() => {
      const copy = props.data.members;
      return copy.sort((a, b) => a.name.localeCompare(b.name));
    });

    return { menuItems, sortedMembers };
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/scss/_utilities.scss";
.my-team {
  position: absolute;
  inset: 0;
  overflow: auto;
  color: #252526;
  --hCellWidth: 1;
  li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    transition: 200ms 110ms max-width ease-in-out;
    @include min-width("sm") {
      max-width: calc(100% / var(--hCellWidth));
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      border-color: #e0e0e0;
      border-width: 0 1px 1px 0;
      border-style: solid;
      transition: 200ms all ease-in-out;
      &:hover {
        background-color: #f0f0f0;
        box-shadow: inset 6px 0 0 #ed6a5e;
      }
      .avatar-container {
        width: 42px;
        height: 42px;
        overflow: visible;
        transition: 200ms all ease-in-out;
        .avatar {
          display: block;
          border-radius: 100%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            opacity: 0;
            &.loaded {
              opacity: 1;
              transform: none;
              transition: 1660ms all;
            }
          }
        }
        .status {
          display: block;
          position: absolute;
          right: 2.1%;
          bottom: 2.1%;
          width: 8px;
          height: 8px;
          transition: 200ms all ease-in-out;
          border-radius: 100%;
          &.offline {
            background: #888;
          }
          &.online {
            background: #00b020;
          }
          &.busy {
            background: #b00020;
          }
        }
      }
    }
  }
  &.large {
    a {
      padding: 1.43em;
    }
    .status {
      width: 10px;
      height: 10px;
    }
  }

  .name {
    font-size: 12px;
    font-weight: 400;
    color: #252526;
    line-height: 18px;
    transition: 200ms all ease-in-out;
    margin-top: 5px;
  }
  .role {
    color: #a0a0a0;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 18px;
    transition: 200ms all ease-in-out;
  }
}
</style>
