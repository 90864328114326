
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "Text",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [],
  setup() {
    return {};
  },
});
