import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1509005a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-bar" }
const _hoisted_2 = { class: "menu-bar__inner" }
const _hoisted_3 = { class: "button-group" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "button-group__inner" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "button-group__inner" }
const _hoisted_8 = { class: "search-field" }
const _hoisted_9 = {
  key: 0,
  class: "sort-dropdown__inner"
}
const _hoisted_10 = { class: "menu caption" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "sort-dropdown__inner"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "filter-popout__inner" }
const _hoisted_15 = { class: "filter-popout__inner__header" }
const _hoisted_16 = { class: "filter-popout__inner__footer" }
const _hoisted_17 = {
  key: 1,
  class: "filter-popout__inner"
}
const _hoisted_18 = { class: "items" }
const _hoisted_19 = { class: "items__post" }
const _hoisted_20 = { class: "filter-popout__inner__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isSmallScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (!_ctx.hideSort)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      text: "common.sort",
                      variant: "transparent",
                      onClick: _ctx.toggleSort
                    }, {
                      labelAfter: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "chevron-down" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.hideSearch)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      variant: "transparent",
                      text: "common.search",
                      onClick: _ctx.toggleSearch
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.hideFilters)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 2,
                      text: "filter.title",
                      variant: "transparent",
                      onClick: _ctx.toggleFilter
                    }, {
                      labelAfter: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "chevron-down" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  (!_ctx.hideSort)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        text: "common.sort",
                        size: "sm",
                        variant: "transparent",
                        onClick: _ctx.toggleSort
                      }, {
                        labelBefore: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: `sort-amount-${_ctx.sortOrder === 'asc' ? 'up' : 'down'}`
                          }, null, 8, ["icon"])
                        ]),
                        labelAfter: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            class: "icon brand",
                            icon: "chevron-down"
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (!_ctx.hideFilters)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        text: "filter.title",
                        size: "sm",
                        variant: "transparent",
                        onClick: _ctx.toggleFilter
                      }, {
                        labelBefore: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, { icon: "filter" })
                        ]),
                        labelAfter: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            class: "icon",
                            icon: "chevron-down"
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (!_ctx.hideSearch)
                    ? (_openBlock(), _createBlock(_component_FormInput, {
                        key: 0,
                        id: "searchText",
                        name: "searchText",
                        label: "common.search",
                        value: _ctx.searchText,
                        onChange: _ctx.handleSearchUpdate,
                        compact: ""
                      }, {
                        prepend: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, { icon: "search" })
                        ]),
                        append: _withCtx(({ value }) => [
                          (!!value)
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: "times",
                                onClick: _ctx.handleSearchReset
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value", "onChange"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["sort-dropdown", { active: _ctx.showSortMenu }])
      }, [
        (_ctx.isSmallScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("ul", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortItems, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.value,
                    class: _normalizeClass(["menu-item", { active: item.value === _ctx.sortColumn }]),
                    onClick: ($event: any) => (_ctx.handleUpdateSort(item.value))
                  }, [
                    _createVNode(_component_TranslatedText, {
                      path: item.label
                    }, null, 8, ["path"])
                  ], 10, _hoisted_11))
                }), 128))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortItems, (item) => {
                return (_openBlock(), _createBlock(_component_Button, {
                  key: item.value,
                  text: item.label,
                  class: _normalizeClass({ active: item.value === _ctx.sortColumn }),
                  size: "sm",
                  variant: "transparent",
                  onClick: ($event: any) => (_ctx.handleUpdateSort(item.value))
                }, null, 8, ["text", "class", "onClick"]))
              }), 128))
            ]))
      ], 2),
      (!_ctx.hideFilters)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["filter-popout", { active: _ctx.showFilterMenu }])
          }, [
            (_ctx.isSmallScreen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_ctx.showFilterMenu)
                    ? (_openBlock(), _createBlock(_component_Overlay, {
                        key: 0,
                        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterMenu = false))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "times",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilterMenu = false))
                              }),
                              _createElementVNode("span", null, [
                                _createVNode(_component_TranslatedText, { path: "filter.title" })
                              ])
                            ]),
                            _createVNode(_component_Accordion, {
                              items: Object.keys(_ctx.filters)
                            }, {
                              title: _withCtx(({ item, open }) => [
                                _createElementVNode("span", null, [
                                  _createVNode(_component_TranslatedText, {
                                    path: _ctx.filters[item].label
                                  }, null, 8, ["path"])
                                ]),
                                _createVNode(_component_font_awesome_icon, {
                                  icon: open ? 'chevron-down' : 'chevron-right'
                                }, null, 8, ["icon"])
                              ]),
                              body: _withCtx(({ item }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters[item].options, (option) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: option.name
                                  }, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(option.component), _mergeProps(option.props, {
                                      id: option.name,
                                      name: option.name,
                                      label: option.label,
                                      value: option.value,
                                      onChange: _ctx.handleFilterChange
                                    }), null, 16, ["id", "name", "label", "value", "onChange"]))
                                  ]))
                                }), 128)),
                                _renderSlot(_ctx.$slots, "item", { item: item }, undefined, true)
                              ]),
                              _: 3
                            }, 8, ["items"]),
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_Button, {
                                text: "common.apply",
                                size: "sm",
                                onClick: _ctx.handleFilterApply,
                                block: ""
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_Button, {
                                text: "common.reset",
                                size: "sm",
                                onClick: _ctx.handleFilterReset,
                                variant: "secondary",
                                block: ""
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ]),
                        _: 3
                      }))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.filters), (key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      lass: "item-group"
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters[key].options, (option) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: option.name
                          }, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(option.component), _mergeProps(option.props, {
                              id: option.name,
                              name: option.name,
                              label: option.label,
                              value: option.value,
                              onChange: _ctx.handleFilterChange
                            }), null, 16, ["id", "name", "label", "value", "onChange"]))
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _renderSlot(_ctx.$slots, "item", { item: key }, undefined, true)
                      ])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_Button, {
                      text: "common.reset",
                      size: "sm",
                      onClick: _ctx.handleFilterReset,
                      variant: "secondary",
                      block: ""
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_Button, {
                      text: "common.apply",
                      size: "sm",
                      onClick: _ctx.handleFilterApply,
                      block: ""
                    }, null, 8, ["onClick"])
                  ])
                ]))
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.hideSearch)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["search-dropdown", { active: _ctx.showSearchBar }])
          }, [
            _createVNode(_component_FormInput, {
              id: "searchText",
              name: "searchText",
              label: "Search",
              value: _ctx.searchText,
              onChange: _ctx.handleSearchUpdate
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "search" })
              ]),
              append: _withCtx(({ value }) => [
                (!!value)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: "times",
                      onClick: _ctx.handleSearchReset
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}