
import { defineComponent, computed, onMounted, watch } from "vue";

import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Loading from "@/components/Loading.vue";
import Notification from "@/components/Notification.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";

import { useLanguage } from "@/composables";

export default defineComponent({
  name: "app",
  components: {
    Loading,
    Modal,
    Button,
    Notification,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const showGlobalLoader = computed(() => store.getters.loaderActive);
    const globalModal = computed(() => store.getters.modal);

    const setWindowProperties = () => {
      store.dispatch("setScreenSize", window.innerWidth);
      store.dispatch("setIsMobile", window.innerWidth > 767 ? false : true);
    };

    window.addEventListener("resize", setWindowProperties);

    const { setLanguage, currentLanguage } = useLanguage();
    setLanguage(currentLanguage.value);
    onMounted(async () => {
      setLanguage(currentLanguage.value);
    });

    store.watch(
      (state, getters) => getters.isMaintenanceMode,
      () => {
        if (store.getters.isMaintenanceMode) {
          router.push("/maintenance");
        }
      }
    );

    return {
      showGlobalLoader,
      globalModal,
    };
  },
});
