
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import Table from "@/components/Table.vue";
import Pagination from "@/components/Pagination.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import { Report } from "@/models/Report";
import TitleSelect from "@/components/form/TitleSelect.vue";
import MenuBar, { FilterGroup } from "@/components/MenuBar.vue";
import UserProgressRow from "@/components/reporting/reports/UserProgressRow.vue";
import { useStore } from "vuex";
import { PaginationDetails } from "@/interfaces/Pagination";
import { useBreakpoints } from "@/composables";
import {
  ReportData,
  ReportDataSection,
  ReportDataFilterValue,
} from "@/interfaces";

import { NavItem } from "@/interfaces";

export default defineComponent({
  name: "UserProgressReport",
  components: {
    Table,
    Pagination,
    MenuBar,
    UserProgressRow,
    Checkbox,
    // NavList,
  },
  props: {
    reportData: {
      type: Object,
    },
    rowData: {
      type: Object || Array,
      default: () => ({}),
    },
    currentHotspot: {
      type: Object,
    },
    currentSortColumn: {
      type: String,
    },
    currentSortOrder: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const dataRows = ref(Object.values(props.rowData));
    watch(props, () => {
      if (props.rowData) {
        dataRows.value = Object.values(props.rowData);
      }
    });

    const selectedAll = ref(false);
    const selectedIds = ref(new Set<string>());
    const excludedIds = ref(new Set<string>());
    const handleSelectAllChange = ({ value }) => {
      selectedAll.value = value;
      excludedIds.value.clear();
    };
    const handleItemSelectChange = ({
      value,
      selected,
    }: {
      value: string;
      selected: boolean;
    }) => {
      if (selected) {
        selectedIds.value.add(value);
        excludedIds.value.delete(value);
      } else {
        if (selectedAll.value) {
          if (!selected) {
            excludedIds.value.add(value);
          }
        }
      }
    };

    const paginationDetails = computed(() => props.reportData?.page);
    const handleChangePage = async (pageNumber: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber,
      });

      emit("refresh-data", pagination);
      // await getReportData(pagination);
    };
    const handleChangeRowCount = async (pageSize: number) => {
      const pagination = new PaginationDetails({
        ...paginationDetails.value,
        pageNumber: 1,
        pageSize,
      });
      emit("refresh-data", pagination);
      // await getReportData(pagination);
    };

    const sortItems = computed(() => [
      {
        label: "form.first-name",
        value: "firstName",
      },
      {
        label: "form.surname",
        value: "surname",
      },
      {
        label: "progress.overall",
        value: "progress",
      },
    ]);
    const handleSort = async (value: string) => {
      emit("sort", value);
    };

    const searchText = ref();
    const handleSearchChange = async (value: string) => {
      emit("search-change");
    };

    const statusFilter = ref();
    const statusTypes = [
      {
        value: "*",
        text: "common.all",
      },
      {
        value: "late",
        text: "progress.late",
      },
      {
        value: "overdue",
        text: "progress.overdue",
      },
      {
        value: "complete",
        text: "progress.complete",
      },
      {
        value: "inprogress",
        text: "progress.inprogress",
      },
    ];
    const filterOptions = computed<FilterGroup>(() => ({
      type: {
        label: "common.status",
        options: [
          {
            name: "status",
            label: "common.status",
            component: "Select",
            value: statusFilter.value,
            props: {
              items: statusTypes,
            },
          },
        ],
      },
    }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFilterChange = (e: any) => {
      statusFilter.value = e.value;
      emit("update-filter", e);
    };
    const handleFilterReset = async () => {
      statusFilter.value = undefined;
      emit("handleFilterReset");
    };
    const handleFilterApply = async () => {
      emit("apply-filter");
    };

    const totalCandidatesCount = computed(
      () =>
        totalCompleteCount.value + inProgressCount.value + overdueCount.value
    );
    const totalCompleteCount = computed(
      () => completeCount.value + lateCount.value
    );
    const inProgressCount = computed(
      () => props.reportData?.stats?.inprogress ?? 0
    );
    const overdueCount = computed(() => props.reportData?.stats?.overdue ?? 0);
    const completeCount = computed(
      () => props.reportData?.stats?.complete ?? 0
    );
    const lateCount = computed(() => props.reportData?.stats?.late ?? 0);

    const isMounted = ref(false);
    onMounted(async () => {
      isMounted.value = true;
      dataRows.value = Object.values(props.rowData);
      // getReportData();
    });

    return {
      paginationDetails,
      handleChangePage,
      handleChangeRowCount,
      sortItems,
      handleSort,
      searchText,
      handleSearchChange,
      filterOptions,
      handleFilterChange,
      handleFilterReset,
      handleFilterApply,
      selectedAll,
      handleSelectAllChange,
      handleItemSelectChange,
      selectedIds,
      excludedIds,
      totalCandidatesCount,
      totalCompleteCount,
      inProgressCount,
      overdueCount,
      isMounted,
      dataRows,
    };
  },
});
