import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, { class: "section-container--reporting" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}