import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3df9f004"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Video = _resolveComponent("Video", true)!

  return (!_ctx.isLoading && _ctx.videoData && _ctx.videoData.id && _ctx.currentVideoSource)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_component_Video, {
          key: _ctx.getVideoKey,
          reference: _ctx.currentVideo.reference,
          videoType: _ctx.currentVideoType,
          format: "video/mp4",
          videoSource: _ctx.currentVideoSource,
          placeholderUrl: _ctx.placeholderUrl,
          onEnded: _ctx.handleVideoEnded
        }, null, 8, ["reference", "videoType", "videoSource", "placeholderUrl", "onEnded"])),
        _renderSlot(_ctx.$slots, "declaration", {}, undefined, true)
      ]))
    : _createCommentVNode("", true)
}