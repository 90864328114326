import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert__title"
}
const _hoisted_2 = {
  key: 0,
  class: "alert__title__icon"
}
const _hoisted_3 = { class: "alert__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", _ctx.variant])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_1, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_font_awesome_icon, { icon: _ctx.icon }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_TranslatedText, { path: _ctx.title }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}