import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "overlay__background",
      style: _normalizeStyle(_ctx.getBackgroundColour)
    }, [
      _createElementVNode("div", {
        class: "overlay__inner",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 4)
  ]))
}