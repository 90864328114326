
import { defineComponent, PropType, computed } from "vue";
import { EmergencyContact, Address } from "@/models";
import TelephoneInput, {
  TelephoneNumber,
} from "@/components/form/TelephoneInput.vue";
import FormInput from "@/components/form/FormInput.vue";
import Select from "@/components/form/Select.vue";
import AddressForm from "@/components/user/shared/AddressForm.vue";
import Checkbox from "@/components/form/Checkbox.vue";

export default defineComponent({
  components: {
    TelephoneInput,
    FormInput,
    Select,
    AddressForm,
    Checkbox,
  },
  props: {
    contact: {
      type: Object as PropType<EmergencyContact>,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add", "update"],
  setup(props, { emit }) {
    const relationships = [
      "relationships.child",
      "relationships.cousin",
      "relationships.doctor",
      "relationships.friend",
      "relationships.grandchild",
      "relationships.grandparent",
      "relationships.guardian",
      "relationships.nephew-niece",
      "relationships.parent",
      "relationships.sibling",
      "relationships.spouse",
      "relationships.partner",
      "relationships.uncle-aunt",
    ];

    const address = computed(() => props.contact.address || new Address());

    const disablePrimaryCheckbox = !!props.contact.isPrimary;
    const setPrimary = ({ value }) => {
      emit("update", { name: "isPrimary", value });
    };
    const relationshipItems = computed(() => {
      let rels = relationships;
      if (
        props.contact.relationship &&
        !relationships.includes(props.contact.relationship)
      ) {
        rels.push(props.contact.relationship);
      }
      return rels.map((r) => {
        return { value: r, text: r };
      });
    });
    const contactNumber = computed(() => {
      return {
        countryCode: props.contact.telCountryCode,
        number: props.contact.telNumber,
      };
    });
    const handleUpdate = ({ name, value }) => {
      emit("update", { name, value });
    };
    const handleUpdateAddress = ({
      name,
      value,
    }: {
      name: string;
      value: string;
    }) => {
      const address = { ...props.contact.address };
      address[name] = value;
      emit("update", { name: "address", value: address });
    };
    const handleContactNumberUpdate = (telNo: TelephoneNumber) => {
      emit("update", { name: "telCountryCode", value: telNo.countryCode });
      emit("update", { name: "telNumber", value: telNo.number });
    };

    return {
      address,
      relationshipItems,
      contactNumber,
      disablePrimaryCheckbox,
      handleUpdate,
      handleUpdateAddress,
      handleContactNumberUpdate,
      setPrimary,
    };
  },
});
