
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  toRef,
  reactive,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { useLoading } from "@/plugins/loading";
import { Hotspot } from "@/models/Hotspot";
import { Video as IVideo } from "@/interfaces/ui";
import { getVideoContents } from "@/api/video.api";
import Video from "@/components/Video.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import { Action, ActionProgress } from "@/models";
import Signature from "@/components/Signature.vue";
import Modal from "@/components/Modal.vue";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";
import Button from "@/components/Button.vue";
import { HttpError } from "@/api/httpClient";
import { format, parseISO } from "date-fns";
import { getFormFeatureConfigs } from "@/api/form-questions.api";

import { useNotification } from "@/composables/useNotification";
import { getImageContents } from "@/api/image-contents.api";

export default defineComponent({
  components: {
    Video,
    ActionHeader,
    Declaration,
    Signature,
    Modal,
    Button,
  },
  props: {
    hotspot: {
      type: Hotspot,
      required: true,
    },
    currentActionId: {
      type: String,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["action-complete"],
  setup(props, { emit }) {
    const store = useStore();
    const showLoader = useLoading();
    const actionId = toRef(props, "currentActionId");
    const isLoading = ref<boolean>(false);
    const videoData = ref<IVideo>({
      fileName: "",
      groupId: null,
      id: "",
      languageId: "",
      protected: false,
      reference: "",
      storageKey: "",
      transcoding: false,
      transcodingCompleted: false,
      url: null,
      userId: null,
      youtubeUrl: null,
      signOffEnabled: false,
    });

    const { inPageNotification } = useNotification();

    const showSign = ref(false);
    const handleSignClick = async () => {
      if (
        !signatureEnabled.value ||
        (signatureEnabled.value && !wetSignature.value)
      ) {
        await completeVideo();
      } else {
        showSign.value = !showSign.value;
      }
    };

    const showSignModal = computed(() => showSign.value);
    const handleModalHide = () => {
      declarationWet.signature = "";
      declaration.consent = false;

      declaration.dob = "";
      showSign.value = false;
    };

    const declarationIsValid = ref(false);
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const declarationWet = reactive<{ signature: string }>({
      signature: "",
    });

    const signatureEnabled = ref(true);
    const dobSignatureRequired = ref(false);
    const wetSignature = ref(true);
    const readyToSign = ref(false);
    const placeholderUrl = ref("");

    const getVideo = async (
      references: string[],
      role: string,
      groupId: string,
      userId: string
    ) => {
      try {
        const referencesString = JSON.stringify(references);
        const imageResponse = await getImageContents(
          references,
          "null",
          "admin"
        );
        if (imageResponse.data?.payload?.results) {
          const imageData = imageResponse.data?.payload?.results[0];
          if (imageData) {
            placeholderUrl.value = imageData.url;
          }
        }
        const response = await getVideoContents(
          referencesString,
          role,
          groupId,
          userId
        );
        return response.data.payload.results;
      } catch (err) {
        console.log(err);
      }
    };

    const getCurrentVideo = async () => {
      handleModalHide();
      const response = await getVideo(
        [currentVideo.value?.reference] as string[],
        store.getters.token.decoded.roles[0],
        "",
        ""
      );
      if (response) {
        videoData.value = response[0];
      }
    };

    const action = computed<Action>(() =>
      props.hotspot
        ? (props.hotspot.actions.find((a) => a.id === actionId.value) as Action)
        : props.action
    );
    const videoReferences = computed(() => {
      const actions: any[] = [];
      if (props.hotspot) {
        props.hotspot.actions.map((action) => {
          if (action.videoReference) {
            actions.push({
              actionId: action.id,
              reference: action.videoReference,
            });
          }
        });
        return actions;
      } else {
        return [action.value];
      }
    });

    const currentVideo = computed(() =>
      videoReferences.value.find(
        (video) => video.actionId === props.currentActionId
      )
    );

    const currentVideoType = computed(() =>
      videoData.value && videoData.value.url ? "upload" : "embed"
    );
    const currentUser = computed(() => store.getters.currentUser);

    const currentVideoSource = computed(() => {
      if (videoData.value && videoData.value.url) {
        return videoData.value.url;
      } else if (videoData.value && videoData.value.youtubeUrl) {
        return videoData.value.youtubeUrl;
      } else {
        return null;
      }
    });

    const handleVideoEnded = async () => {
      if (!signatureEnabled.value) {
        await completeVideo();
      } else {
        readyToSign.value = true;
      }
    };

    const completeVideo = async () => {
      const response = await action.value.completeAction(currentUser.value.id);
      emit("action-complete", { progressObj: response });
    };

    const handleSignSubmit = async () => {
      try {
        const signOff = {
          userId: currentUser.value.id,
        };

        if (signatureEnabled.value && !wetSignature.value) {
          const dob = declaration.dob.split("-");
          signOff["sign"] = declaration.consent;
          signOff["dobYear"] = dob[0];
          signOff["dobMonth"] = dob[1];
          signOff["dobDay"] = dob[2];
        }
        let response;
        if (!wetSignature.value) {
          response = await props.action.completeAction(
            currentUser.value.id,
            declaration.dob,
            declaration.consent
          );
        } else if (!signatureEnabled.value) {
          response = await props.action.completeActionWet(currentUser.value.id);
        } else {
          response = await props.action.completeActionWet(
            currentUser.value.id,
            declarationWet.signature
          );
          emit("action-complete", { progressObj: response });
        }
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("declaration.sign.error", httpError.message);
        }
      }
    };

    const completionDateString = computed(() =>
      props.actionProgress?.completionDate
        ? format(
            parseISO(props.actionProgress.completionDate),
            "dd/MM/yyyy HH:mm:SS"
          )
        : ""
    );

    const getFeatureConfigs = async () => {
      if (!props.viewOnly) {
        const formFeatureConfigs = await getFormFeatureConfigs();
        formFeatureConfigs.data.payload.result.forEach((featureConfig) => {
          switch (featureConfig.feature) {
            case "sign-off":
              signatureEnabled.value = featureConfig.config;
              if (
                signatureEnabled.value &&
                !props.action.signatureRequired &&
                !videoData.value?.signOffEnabled
              ) {
                signatureEnabled.value = false;
              }
              break;
            case "signature-type":
              dobSignatureRequired.value =
                featureConfig.condition === "date-of-birth";
              wetSignature.value = featureConfig.condition === "wet";
              break;
          }
        });
      }
    };

    onMounted(async () => {
      showLoader(true);
      await getFeatureConfigs();
      if (videoReferences.value.length) await getCurrentVideo();
      isLoading.value = false;
      showLoader(false);
    });

    watch(actionId, async () => {
      await getCurrentVideo();
    });

    return {
      declarationWet,
      declaration,
      currentVideo,
      currentVideoType,
      currentVideoSource,
      isLoading,
      videoData,
      handleVideoEnded,
      showSignModal,
      handleModalHide,
      showSign,
      handleSignClick,
      dobSignatureRequired,
      wetSignature,
      declarationIsValid,
      signatureEnabled,
      readyToSign,
      handleSignSubmit,
      completionDateString,
      placeholderUrl,
    };
  },
});
