
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useLoading } from "@/plugins/loading";
import { useModal } from "@/plugins/modal";
import UserView from "@/components/user/UserView.vue";
import { User } from "@/models";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { getUserFeatureConfigs } from "@/api/users.api";

export default defineComponent({
  components: {
    AuthenticatedLayout,
    UserView,
  },
  setup() {
    const router = useRouter();
    const showLoader = useLoading();
    const showModal = useModal();

    const { id } = router.currentRoute.value.params;
    const user = ref<User>(new User());
    const userOld = ref<User>(new User());
    const userIsLoading = ref<boolean>(true);
    const userNotFound = ref<boolean>(false);

    const navItemConfig = ref<Array<any>>([
      {
        url: "overview",
        title: "models.user.tabs.overview",
        component: "UserOverview",
        permission: "user:*",
        action: () => changeTab("overview"),
      },
      {
        url: "details",
        title: "models.user.tabs.details",
        component: "UserDetails",
        permission: "user:details",
        action: () => changeTab("details"),
      },
      {
        url: "contact",
        title: "models.user.tabs.contact",
        component: "UserContact",
        permission: "user:contact-details",
        action: () => changeTab("contact"),
      },
      {
        url: "payroll",
        title: "models.user.tabs.payroll",
        component: "UserPayroll",
        permission: "user:payroll",
        action: () => changeTab("payroll"),
      },
      {
        url: "references",
        title: "References",
        component: "UserReferences",
        permission: "user:references:read",
        action: () => changeTab("references"),
      },
      {
        url: "contacts",
        title: "models.user.tabs.contacts",
        component: "UserContacts",
        permission: "user:contacts",
        action: () => changeTab("contacts"),
      },
      {
        url: "checklist",
        title: "models.user.tabs.checklist",
        component: "UserChecklist",
        permission: "user:checklists:read",
        action: () => changeTab("checklist"),
      },
      {
        url: "documents",
        title: "models.user.tabs.documents",
        component: "UserDocuments",
        permission: "user:documents",
        action: () => changeTab("documents"),
      },
      {
        url: "videos",
        title: "models.user.tabs.videos",
        component: "UserVideos",
        permission: "user:videos",
        action: () => changeTab("videos"),
      },
      {
        url: "activity",
        title: "models.user.tabs.activity-log",
        component: "UserActivity",
        permission: "user:activity-log",
        action: () => changeTab("activity"),
      },
    ]);

    const changeTab = (url: string) => {
      const userId = router.currentRoute.value.params.id as string;
      router.push({
        path: `/users/${userId}/${url}`,
      });
    };

    const getUser = async (bypassCache = false) => {
      try {
        userIsLoading.value = true;
        if (!user.value || bypassCache) {
          user.value = await User.get(id as string);
        }
      } catch (err) {
        //TODO: Handle this error better HBL-1886
        console.log(err);
        userNotFound.value = false;
        router.push("/");
      } finally {
        userOld.value = user.value as User;
        userIsLoading.value = false;
      }
    };

    let nav = false;
    // same as beforeRouteUpdate option with no access to `this`
    onBeforeRouteUpdate(async (to) => {
      if (
        Object.keys(user.value).find(
          (key: string) => user.value[key] !== (userOld.value as User)[key]
        )
      ) {
        showModal({
          title: "prompts.unsaved-changes.title",
          body: "prompts.unsaved-changes.message",
          onConfirm: async () => {
            nav = true;
            return router.push(to.fullPath);
          },
        });
        return nav;
      } else {
        return true;
      }
    });

    onMounted(async () => {
      const usersFeatureConfigs = await getUserFeatureConfigs();
      let hiddenSideMenuItems: Array<string> = [];
      usersFeatureConfigs.data.payload.result.forEach((featureConfig) => {
        switch (featureConfig.feature) {
          case "hidden-admin-side-menu-items":
            hiddenSideMenuItems = featureConfig.condition.split(",");
            navItemConfig.value = navItemConfig.value.filter(
              (navItem: { url: string }) =>
                !hiddenSideMenuItems.includes(navItem.url)
            );
            break;
        }
      });
      showLoader(true);
      await getUser(true);
      showLoader(false);
      userOld.value = user.value;
    });

    return {
      user,
      userIsLoading,
      navItemConfig,
      getUser,
    };
  },
});
