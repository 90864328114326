import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3d2eb08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wysiwyg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Wysiwyg = _resolveComponent("Wysiwyg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Wysiwyg, {
      label: "Content",
      value: _ctx.model.content,
      name: "data",
      onInputChange: _ctx.handleDataChange
    }, null, 8, ["value", "onInputChange"])
  ]))
}