import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a15fc81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "input-icon prepend"
}
const _hoisted_4 = { class: "all-options" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id", "name", "value", "disabled", "checked"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 3,
  class: "error-msg caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options?.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.options?.id,
          class: _normalizeClass({
        active: _ctx.options?.active,
        'has-icon': !!_ctx.$slots.prepend,
      })
        }, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.label
          }, null, 8, ["path"]),
          (_ctx.options?.required)
            ? (_openBlock(), _createBlock(_component_TranslatedText, {
                key: 0,
                path: "form.required.asterix",
                class: "required-asterix"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.prepend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "prepend", {
            value: _ctx.options?.value
          }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionChoices.sort(
          (c1, c2) => c1.position - c2.position
        ), (choice) => {
        return (_openBlock(), _createElementBlock("div", {
          key: choice.id,
          class: "single-option"
        }, [
          _createElementVNode("label", {
            for: choice.id
          }, [
            _createElementVNode("input", {
              type: "radio",
              id: choice?.id,
              name: _ctx.options?.name,
              value: choice?.id,
              disabled: _ctx.options?.disabled,
              checked: choice?.id === (_ctx.options?.value ?? ''),
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChoiceChange && _ctx.handleChoiceChange(...args)))
            }, null, 40, _hoisted_6),
            _createElementVNode("span", {
              innerHTML: _ctx.getTextItem(choice.textItems, 'form-question-choice')
            }, null, 8, _hoisted_7)
          ], 8, _hoisted_5)
        ]))
      }), 128))
    ]),
    (!!_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "input-icon append",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, [
          _renderSlot(_ctx.$slots, "append", {
            value: _ctx.options?.value
          }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasErrors && !_ctx.isFocussed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_TranslatedText, { path: _ctx.hasErrors }, null, 8, ["path"])
        ]))
      : _createCommentVNode("", true)
  ]))
}