import httpClient from "@/api/httpClient";
import { AxiosResponse } from "axios";

export const getVideoContents = (
  references: string,
  currentRole: string,
  groupId: string,
  userId: string
): Promise<AxiosResponse<any>> =>
  httpClient.get(
    `/videocontents/content/transcoded/${references}/${currentRole}/${groupId.length > 0 ? groupId : 'null'}/${userId.length > 0 ? userId : 'null'}`
  );
