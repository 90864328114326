import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13eb710a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "signin-container" }
const _hoisted_3 = {
  key: 0,
  class: "branding"
}
const _hoisted_4 = { class: "logo logo--lg" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "form-fields" }
const _hoisted_7 = { class: "forgot-password" }
const _hoisted_8 = { class: "checkbox-container" }
const _hoisted_9 = { class: "actions" }
const _hoisted_10 = { class: "signin-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ShadowContainer = _resolveComponent("ShadowContainer")!
  const _component_unauthenticated_layout = _resolveComponent("unauthenticated-layout")!

  return (_openBlock(), _createBlock(_component_unauthenticated_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.logoUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("img", {
                    class: "icon",
                    src: _ctx.logoUrl,
                    alt: ""
                  }, null, 8, _hoisted_5)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ShadowContainer, null, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, [
                _createVNode(_component_TranslatedText, { path: "auth.sign-in-request" })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Form, {
                  modelValue: _ctx.model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
                  errors: _ctx.errors,
                  "onUpdate:errors": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errors) = $event)),
                  schema: _ctx.rules
                }, {
                  default: _withCtx(({ handleInputChange, isValid }) => [
                    _createVNode(_component_FormInput, {
                      id: "email",
                      name: "email",
                      label: "form.email-address",
                      type: "email",
                      value: _ctx.model.email,
                      error: _ctx.errors['email'],
                      onChange: handleInputChange,
                      hideLabelOnFocus: "",
                      required: ""
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "envelope" })
                      ]),
                      _: 2
                    }, 1032, ["value", "error", "onChange"]),
                    _createVNode(_component_FormInput, {
                      id: "password",
                      name: "password",
                      label: "form.password",
                      type: _ctx.passwordType,
                      value: _ctx.model.password,
                      error: _ctx.errors['password'],
                      onChange: handleInputChange,
                      onClickAppendIcon: _ctx.togglePasswordVisibility,
                      hideLabelOnFocus: "",
                      required: ""
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "lock" })
                      ]),
                      append: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: _ctx.passwordIcon }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["type", "value", "error", "onChange", "onClickAppendIcon"]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_router_link, {
                        to: "/signin/forgotten-password",
                        class: "caption"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TranslatedText, { path: "auth.forgot-password" })
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Checkbox, {
                        name: "remember",
                        id: "remember",
                        text: "auth.stay-signed-in",
                        value: _ctx.model.remember,
                        onChange: handleInputChange
                      }, null, 8, ["value", "onChange"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_Button, {
                          variant: _ctx.getVariant,
                          text: "common.continue",
                          block: "",
                          disabled: !isValid || (isValid && _ctx.attemptingSignIn),
                          onClick: _ctx.handleSubmit
                        }, null, 8, ["variant", "disabled", "onClick"])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "errors", "schema"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}