import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-605f285e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "formRef",
  class: "form-ref"
}
const _hoisted_2 = {
  key: 1,
  class: "input-resizer"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_vue_resizable = _resolveComponent("vue-resizable")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_VueDraggableNext, {
      disabled: !_ctx.editMode,
      class: _normalizeClass(["list-group w-full grid col hotspot-action--form styled-content", _ctx.editMode ? 'dragArea' : '']),
      list: _ctx.formQuestions,
      onStart: _ctx.startDragging,
      onEnd: _ctx.endDragging
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formQuestions, (formQuestion) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: formQuestion.id
          }, [
            (
          _ctx.componentMap[formQuestion.questionType] &&
          _ctx.dependencyCheck(formQuestion.id)
        )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass({
          col: true,
          ['md:col-' + _ctx.getFormQuestionColSize(formQuestion)]: true,
          'draggable-item': _ctx.editMode && !_ctx.dragging,
          'dragged-item': _ctx.editMode && _ctx.dragging,
        })
                }, [
                  _createVNode(_component_vue_resizable, {
                    disableAttributes: _ctx.editMode ? ['l', 't', 'h'] : ['l', 't', 'w', 'h'],
                    active: _ctx.editMode ? ['r'] : [],
                    "onResize:end": (event) => _ctx.resizeEnd(formQuestion.id, event)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["question-container", `question-type-${formQuestion?.questionType}`])
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentMap[formQuestion.questionType]), {
                          options: _ctx.getComponentOptions(formQuestion),
                          autocomplete: _ctx.autocomplete,
                          onChange: _ctx.handleChange,
                          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBlur($event))),
                          onResize: (event) => _ctx.handleResize(formQuestion.id, event.width),
                          onEdit: ($event: any) => (_ctx.handleEditClick(formQuestion.id)),
                          onUpdateFiles: ($event: any) => (_ctx.updateFileList($event, formQuestion.id)),
                          onChoiceChange: ($event: any) => (
                _ctx.handleChoiceChange($event, formQuestion.questionType)
              )
                        }, null, 8, ["options", "autocomplete", "onChange", "onResize", "onEdit", "onUpdateFiles", "onChoiceChange"])),
                        (_ctx.editMode)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["resizable-input", {
                'draggable-item-cursor': _ctx.editMode && !_ctx.dragging,
                'dragged-item-cursor': _ctx.editMode && _ctx.dragging,
              }])
                            }, null, 2))
                          : _createCommentVNode("", true),
                        (_ctx.editMode)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createElementVNode("div", {
                                class: "edit-icon",
                                onClick: () => _ctx.handleEditClick(formQuestion.id)
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "pen" })
                              ], 8, _hoisted_3),
                              _createElementVNode("div", {
                                class: "delete-icon",
                                onClick: () => _ctx.handleDeleteClick(formQuestion.id)
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "times" })
                              ], 8, _hoisted_4)
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["disableAttributes", "active", "onResize:end"])
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["disabled", "class", "list", "onStart", "onEnd"])
  ], 64))
}