import httpClient, { ApiResponse } from "./httpClient";

export const getImageContents = async (
  references: string[],
  userId: string,
  role = "user"
): Promise<ApiResponse<any>> =>
  httpClient.get(
    `/imagecontents/content/${JSON.stringify(
      references.map(encodeURIComponent)
    )}/${role}/null/${userId}`
  );

export const postImageContents = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.post(`/imagecontents`, body);

export const postCopyImageContents = async (
  body: any
): Promise<ApiResponse<any>> => httpClient.post(`/imagecontents/copy`, body);

export const putWelcomeImage = async (body: any): Promise<ApiResponse<any>> =>
  httpClient.put(`/hotspots/welcome-image`, body);
