import httpClient, { ApiResponse } from "@/api/httpClient";
import { AxiosResponse } from "axios";

// Get all users for user list
export const getChatMessages = async (
  chatId: string
): Promise<AxiosResponse> => {
  const response = await httpClient.get(`/chats/chat-messages/${chatId}`, {});
  return response;
};

export const createChat = async (
  userId: string,
  managerId: string,
): Promise<AxiosResponse> => {
  const response = await httpClient.post(`/chats/chat-create`, {
    userId,
    managerId
  });
  return response;
};

export const createChatMessage = async (
  chatId: string,
  text: string,
): Promise<AxiosResponse> => {
  const response = await httpClient.post(`/chats/create-chat-message`, {
    chatId,
    text
  });
  return response;
};

export const getChatableUsers = async (): Promise<AxiosResponse> => {
  const response = await httpClient.get(`/chats/chatable-users`, {});
  return response;
};
