import store from "@/store";

export const useNotification = () => {
  const inPageNotification = (
      title: string,
      body: string,
      type: "error" | "success" = 'error'
    ) => {
      store.dispatch("setNotificationContent", { title, body });
      store.dispatch("setNotificationType", type);
      store.dispatch("showNotification");
      setTimeout(() => {
        store.dispatch("hideNotification");
      }, 8000);
    };

  return {
    inPageNotification,
  };
};
