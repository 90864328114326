
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  shallowRef,
  toRef,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Schemas, useSchema } from "@/plugins/schema";
import { getUserActions } from "@/api/actions.api";
import {
  postDocumentContents,
  getDocumentContents,
  deleteDocuments,
  postDocumentSignOffRequirements,
} from "@/api/document-contents.api";
import { Chip as IChip } from "@/interfaces/ui/Chip.interface";
import { DocumentsForm as IDocumentsForm } from "@/interfaces/DocumentsForm.interface";
import { Action, Document, User } from "@/models";
import Button from "@/components/Button.vue";
import DocumentsGrid from "@/components/user/documents/ui/DocumentsGrid.vue";
import DocumentsTable from "@/components/user/documents/ui/DocumentsTable.vue";
import DocumentEdit from "@/components/user/documents/ui/DocumentEdit.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import MobileBottomBar from "@/components/MobileBottomBar.vue";
import Modal from "@/components/Modal.vue";
import Search from "@/components/Search.vue";
import Select from "@/components/form/Select.vue";
import { TextItem } from "@/interfaces";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isAuthenticatedUser: Boolean,
  },
  components: {
    Button,
    FileDisplay,
    FileInput,
    Form,
    FormInput,
    MobileBottomBar,
    Modal,
    Search,
    Select,
    DocumentEdit,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const schema = useSchema(Schemas.UploadDocument);
    const user = toRef(props, "user");
    const { id } = router.currentRoute.value.params;

    const { inPageNotification } = useNotification();

    const isFetching = ref<boolean>(true);
    const showModal = ref<boolean>(false);
    const showDeleteModal = ref<boolean>(false);
    const bulkDelete = ref<boolean>(false);
    const actions = ref<Action[]>([]);
    const preparedDocs = ref<any>({});
    const documentContents = ref<any>([]);
    const selectedUiType = ref<string>("table");
    const searchTerm = ref<string>("");
    const selectAll = ref<boolean>(false);
    const selectedDocuments = ref<string[]>([]);
    const deselectedDocuments = ref<string[]>([]);
    const sortOrder = ref<string>("asc");
    const totalSelectedDocuments = ref<number>(0);
    const activeModalDocument = ref<any>({});
    const uiTypes = shallowRef([
      {
        name: "grid",
        component: DocumentsGrid,
      },
      {
        name: "table",
        component: DocumentsTable,
      },
    ]);
    const tableHeadings = ref([
      {
        id: 0,
        title: "Name",
        sortOrder: "asc",
        sortable: true,
        mobileSortable: true,
        active: false,
        desktop: true,
        mobileFeatured: true,
      },
      {
        id: 1,
        title: "Viewed",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        active: false,
        desktop: true,
        mobileFeatured: false,
        center: true,
      },
      {
        id: 2,
        title: "Signed",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        active: false,
        desktop: true,
        mobileFeatured: false,
        center: true,
      },
      {
        id: 3,
        title: "",
        sortOrder: "",
        sortable: false,
        mobileSortable: false,
        mobileFeatured: true,
        desktop: true,
      },
    ]);
    let headingsData = ref({
      filterOpen: false,
      colsFeatured: [0, 2, 6],
      rowActiveDocument: -1,
      sortActiveIndex: 0,
    });

    const documentActionsChip = ref<IChip>({
      id: 0,
      items: [
        {
          active: false,
          id: "download",
          title: "Download",
        },
        {
          active: false,
          id: "delete",
          title: "Delete",
        },
      ],
      name: "With Selected",
      selectAll: false,
      show: false,
      dropdownType: "action-list",
    });

    const model = ref<IDocumentsForm>({
      title: "",
      description: "",
      signature: "",
      sideMenuText: "",
      file: null,
      isGenerated: false,
      documentContents: null,
      reference: "",
    });

    const isValid = ref<boolean>(false);
    const handleValidUpdate = (valid: boolean) => {
      isValid.value = valid;
    };

    const totalRows = computed(() => allDocuments.value.length);

    const currentComponent = computed(() => {
      const type = uiTypes.value.find((ui) => ui.name === selectedUiType.value);
      if (type) return type.component;
      else return DocumentsTable;
    });

    const requiredDocuments = computed(() =>
      actions.value.filter(
        (action) =>
          action.actionType === "document" && action.userRequired === true
      )
    );

    const additionalDocuments = computed(() =>
      actions.value.filter(
        (action) =>
          action.actionType === "document" && action.userRequired === false
      )
    );

    // A computed property that filters and sorts documents both required and additional
    const allDocuments = computed(() => {
      // get docs from both required and additional arrays
      const documents = [
        ...requiredDocuments.value,
        ...additionalDocuments.value,
      ];
      // sort documents based on sort order
      const sortedDocs = documents.sort((a: any, b: any): number => {
        const textItemA = a.textItems.find((ti) => ti.purpose === "title");
        const textItemB = b.textItems.find((ti) => ti.purpose === "title");
        if (sortOrder.value === "asc") {
          if (textItemA && textItemA.data && textItemB && textItemB.data) {
            if (textItemA.data < textItemB.data) return -1;
            else return 1;
          } else return 0;
        } else {
          if (textItemA && textItemA.data && textItemB && textItemB.data) {
            if (textItemA.data > textItemB.data) return -1;
            else return 1;
          } else return 0;
        }
      });

      // filter based on search term
      const filtered = sortedDocs.filter((doc) =>
        doc.textItems
          .find((ti) => ti.purpose === "title")
          ?.data.toLowerCase()
          .includes(searchTerm.value.toLowerCase())
      );
      return filtered;
    });

    const handleClickSort = () => {
      if (sortOrder.value === "asc") sortOrder.value = "desc";
      else sortOrder.value = "asc";
    };

    const inputSearchTerm = (val: string) => (searchTerm.value = val);

    const toggleUi = () => {
      if (selectedUiType.value === "grid") selectedUiType.value = "table";
      else selectedUiType.value = "grid";
    };

    const toggleAllRows = () => {
      selectAll.value = !selectAll.value;
      if (selectAll.value) {
        documentContents.value.forEach((row) => {
          if (!selectedDocuments.value.includes(row.id)) {
            selectedDocuments.value.push(row.id);
          }
        });
      } else {
        selectedDocuments.value = [];
        deselectedDocuments.value = [];
      }
    };

    const toggleActionsChip = () =>
      (documentActionsChip.value.show = !documentActionsChip.value.show);

    const handleBulkDownload = () => {
      const ids: string[] = [];
      selectedDocuments.value.forEach((id) => {
        if (!deselectedDocuments.value.includes(id)) ids.push(id);
      });
      try {
        ids.forEach(async (id) => {
          const foundDocument = documentContents.value.find(
            (doc) => doc.id === id
          );
          const document = new Document(foundDocument);
          await document.download();
          inPageNotification(
            "Downloading!",
            "Your file(s) are downloading",
            "success"
          );
        });
      } catch (err) {
        inPageNotification("Error!", "Could not download file(s)", "error");
      }
      selectedDocuments.value = [];
      deselectedDocuments.value = [];
      toggleActionsChip();
    };

    const handleBulkDelete = async () => {
      const ids: string[] = [];
      selectedDocuments.value.forEach((id) => {
        if (!deselectedDocuments.value.includes(id)) ids.push(id);
      });

      try {
        await deleteDocuments(ids);
        inPageNotification(
          "Success!",
          "Your file(s) have been deleted",
          "success"
        );
        await getDocuments();
      } catch (err) {
        inPageNotification("Error!", "Could not delete file(s)", "error");
      }
      selectedDocuments.value = [];
      deselectedDocuments.value = [];
      toggleActionsChip();
    };

    const handleClickRow = (id: number) => {
      if (headingsData.value.rowActiveDocument !== id) {
        headingsData.value.rowActiveDocument = id;
      } else {
        headingsData.value.rowActiveDocument = -1;
      }
    };
    const tempSignatureValue = ref("no");

    const resetForm = () => {
      model.value.title = "";
      model.value.description = "";
      model.value.signature = tempSignatureValue.value;
      tempSignatureValue.value = "no";
      model.value.sideMenuText = "";
      model.value.file = null;
      model.value.documentContents = null;
      model.value.reference = "";
      model.value.isGenerated = false;
    };

    const getDocuments = async () => {
      const requiredDocumentsResponse = await getUserActions(id as string);
      actions.value = requiredDocumentsResponse.data.payload.result.actions;
      preparedDocs.value =
        requiredDocumentsResponse.data.payload.result.prepared;

      const documentReferences = allDocuments.value.map(
        (doc) => doc.documentReference
      );
      if (documentReferences && documentReferences.length) {
        const documentContentsResponse = await getDocumentContents(
          documentReferences as string[],
          "admin",
          id as string
        );
        documentContents.value = documentContentsResponse.data.payload.results;
      }
      isFetching.value = false;
    };

    const viewDocument = async (data: {
      reference: string;
      name: string;
      description: string;
      sideMenuText: string;
      id: string;
    }) => {
      activeModalDocument.value = allDocuments.value.find(
        (doc) => doc.id === data.id
      );
      if (preparedDocs.value[`document-${data.reference}`]) {
        const document = documentContents.value.find(
          (doc) => doc.reference === data.reference
        );
        if (document) {
          if (document.signOffEnabled) model.value.signature = "yes";
          else model.value.signature = "no";
          model.value.documentContents = new Document(document);
        } else {
          model.value.documentContents = null;
        }
        model.value.title = data.name;
        model.value.sideMenuText = data.sideMenuText;
        model.value.description = data.description.replace(
          "[username]",
          `${user.value.firstName} ${user.value.surname}`
        );
        model.value.reference = data.reference;
        model.value.isGenerated = document?.isGenerated ?? false;
        showModal.value = true;
      } else {
        resetForm();
        await getDocuments();
        model.value.title = data.name;
        model.value.sideMenuText = data.sideMenuText;
        model.value.description = data.description.replace(
          "[username]",
          `${user.value.firstName} ${user.value.surname}`
        );
        const action = actions.value.find(
          (action) => action.documentReference === data.reference
        );
        if (action) {
          model.value.signature = action.signatureRequired ? "yes" : "no";
        }
        showModal.value = true;
      }
    };

    const cancelDocumentChanges = () => {
      resetForm();
      showModal.value = false;
    };

    const handleCloseModal = async () => {
      await getDocuments();
      showModal.value = false;
    };

    const handleSave = async () => {
      const files = {};
      if (model.value.file) {
        const file = model.value.file;

        files["file"] = file;
        const formData: FormData = new FormData();
        formData.append("file", files["file"], files["file"].name ?? "");
        formData.append("languageId", user.value.preferredLanguageId);
        formData.append(
          "reference",
          activeModalDocument.value.documentReference
        );
        formData.append("userId", user.value.id ?? "");
        if (model.value.signature === "yes") {
          formData.append("signOffRequired", model.value.signature);
        }
        try {
          await postDocumentContents(formData);
          inPageNotification(
            "Success!",
            "Document has been successfully uploaded",
            "success"
          );
          showModal.value = false;
          resetForm();
          await getDocuments();
        } catch (err) {
          console.log(err);
          inPageNotification("Error!", "Could not save file", "error");
        }
      } else {
        const formData: any = {
          reference: activeModalDocument.value.documentReference,
          languageIds: [user.value.preferredLanguageId],
          userId: user.value.id ?? "",
        };
        formData["signOffEnabled"] = model.value.signature === "yes";
        try {
          await postDocumentSignOffRequirements(formData);
          inPageNotification(
            "Success!",
            "Document information has been successfully updated",
            "success"
          );
          showModal.value = false;
          resetForm();
          await getDocuments();
        } catch (err) {
          console.log(err);
          inPageNotification(
            "Error!",
            "Could not update document information",
            "error"
          );
        }
      }
    };

    const handleToggleDeleteModal = (bulk: boolean) => {
      showDeleteModal.value = !showDeleteModal.value;
      bulkDelete.value = bulk;
    };

    const handleClickCell = (data: {
      id: string;
      index: number;
      textItems: TextItem[];
    }) => handleClickRow(data.index);

    const toggleCheckbox = (documentId: string) => {
      if (!selectedDocuments.value.includes(documentId)) {
        selectedDocuments.value.push(documentId);
      } else {
        if (!selectAll.value) {
          const index = selectedDocuments.value.indexOf(documentId);
          if (index >= 0) {
            selectedDocuments.value.splice(index, 1);
          }
        } else {
          if (deselectedDocuments.value.includes(documentId)) {
            const deselectedIndex =
              deselectedDocuments.value.indexOf(documentId);
            if (deselectedIndex >= 0) {
              deselectedDocuments.value.splice(deselectedIndex, 1);
            }
          } else {
            deselectedDocuments.value.push(documentId);
          }
        }
      }
      updateTotalSelectedDocuments();
    };

    const updateTotalSelectedDocuments = () => {
      const selectedDocs = requiredDocuments.value.filter((doc) =>
        selectedDocuments.value.includes(doc.id)
      );

      totalSelectedDocuments.value = selectedDocs.length;
    };

    const handleDeleteFile = async () => {
      tempSignatureValue.value = model.value.signature;
      if (bulkDelete.value) {
        handleBulkDelete();
        showDeleteModal.value = false;
      } else {
        model.value.file = null;
        if (model.value.documentContents) {
          try {
            await model.value.documentContents.delete();
            model.value.documentContents = null;
            inPageNotification("Success!", "File deleted", "success");
            showDeleteModal.value = false;
            await getDocuments();
          } catch (err) {
            inPageNotification("Error!", "Unable to delete file", "error");
          }
        }
      }
    };

    onMounted(async () => {
      await getDocuments();
    });

    return {
      isFetching,
      showModal,
      showDeleteModal,
      tableHeadings,
      allDocuments,
      selectAll,
      selectedDocuments,
      deselectedDocuments,
      toggleAllRows,
      documentActionsChip,
      sortOrder,
      totalRows,
      handleClickSort,
      headingsData,
      handleClickCell,
      inputSearchTerm,
      toggleCheckbox,
      requiredDocuments,
      preparedDocs,
      toggleActionsChip,
      handleBulkDelete,
      handleBulkDownload,
      viewDocument,
      selectedUiType,
      currentComponent,
      toggleUi,
      model,
      schema,
      isValid,
      cancelDocumentChanges,
      handleSave,
      handleDeleteFile,
      documentContents,
      handleValidUpdate,
      handleToggleDeleteModal,
      handleCloseModal,
    };
  },
});
