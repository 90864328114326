export class PaginationDetails {
  public pageCount: number;
  public pageNumber: number;
  public pageSize: number;
  public total: number;

  constructor({
    pageCount = 1,
    pageNumber = 1,
    pageSize = 15,
    total = 0,
  } = {}) {
    this.pageCount = pageCount;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.total = total;
  }
}

export class PaginatedResult<T> {
  public count: number;
  public details: PaginationDetails;
  public rows: Array<T>;

  constructor({
    count = 1,
    details = new PaginationDetails(),
    rows = [] as Array<T>,
  } = {}) {
    this.count = count;
    this.details = details;
    this.rows = rows;
  }
}
