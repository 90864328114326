<template>
  <widget-layout class="layout" :loading="!url" :minHeight="1">
    <div class="dash-potd">
      <div v-if="url" class="pic" :style="style" />
    </div>
    <h4>Pic of the Day</h4>
  </widget-layout>
</template>
<script>
import { defineComponent, computed, ref } from "vue";
import WidgetLayout from "../WidgetLayout.vue";

export default defineComponent({
  name: "PicOfTheDay",
  components: {
    WidgetLayout,
  },
  props: ["data"],
  setup(props) {
    const loaded = ref(null);
    // when writing widgets, data.<category> may or may not be
    // available. try to read it and Dashboard will pull it in.
    const style = computed(
      () => props.data.picOfTheDay && { "--src": `url('${url.value}')` }
    );
    const url = computed(
      () => props.data.picOfTheDay && props.data.picOfTheDay.url
    );

    return { style, url };
  },
});
</script>
<style scoped>
h4 {
  pointer-events: none;
  position: absolute;
  color: #fff;
  top: -2.5rem;
  left: 0.5rem;
  font-weight: 100;
  mix-blend-mode: overlay;
}
.dash-potd {
  position: absolute;
  inset: -2.75rem 0 0 0;
  overflow: hidden;
  pointer-events: none;
}
.pic {
  animation: fadeIn 3s 1 ease-out;
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-image: var(--src);
}
.layout.dragging .pic {
  opacity: 0.5;
}
</style>
