
import { defineComponent, PropType } from "vue";
import { format } from "date-fns";
import Chip from "@/components/Chip.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import { DatePicker as IDatePicker } from "@/interfaces/ui/DatePicker.interface";

export default defineComponent({
  props: {
    filters: {
      type: Object,
    },
    datePickers: {
      type: Array as PropType<IDatePicker[]>,
      default: () => [],
    },
    dateFilterButtons: {
      type: Array as PropType<{ id: number; name: string; active: boolean }[]>,
      default: () => [],
    },
  },
  components: {
    Chip,
    DatePicker,
  },
  emits: [
    "select-all",
    "toggle-chip",
    "select-filter",
    "filter-date-by-date-picker",
    "filter-date-by-button",
    "clear-all-filters",
  ],
  setup(_, context) {
    const selectAll = (filterId: number) =>
      context.emit("select-all", filterId);

    const toggleChip = (index: number) => context.emit("toggle-chip", index);

    const selectFilter = (chip: { chipIndex: number; chipId: number }) => {
      const filter = {
        filterIndex: chip.chipIndex,
        itemId: chip.chipId,
      };
      context.emit("select-filter", filter);
    };

    const changeDate = (e: { name: string; value: Date }) => {
      const date = format(e.value, "yyyy-MM-dd");
      context.emit("filter-date-by-date-picker", {
        value: date,
        field: e.name,
      });
    };

    const filterDateByButton = (val: string) => {
      context.emit("filter-date-by-button", val);
    };

    const clearAllFilters = () => context.emit("clear-all-filters");

    return {
      changeDate,
      selectAll,
      toggleChip,
      selectFilter,
      filterDateByButton,
      clearAllFilters,
    };
  },
});
