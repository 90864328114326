import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    schema: {}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormInput, {
            value: _ctx.model.title,
            error: "",
            onChange: _ctx.handleInputChange,
            label: "form.title",
            name: "title",
            id: "title",
            customClass: "required"
          }, null, 8, ["value", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Select, {
            value: _ctx.model.type,
            error: "",
            items: _ctx.typeSelectOptions,
            onChange: _ctx.handleInputChange,
            label: "action.type",
            name: "type",
            id: "type",
            required: "",
            customClass: "required"
          }, null, 8, ["value", "items", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.getLevelSelectOptions.length)
            ? (_openBlock(), _createBlock(_component_Select, {
                key: 0,
                value: _ctx.model.level,
                error: "",
                items: _ctx.getLevelSelectOptions,
                onChange: _ctx.handleInputChange,
                label: "action.level",
                name: "level",
                id: "level",
                required: "",
                customClass: "required"
              }, null, 8, ["value", "items", "onChange"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.showSignature)
            ? (_openBlock(), _createBlock(_component_Checkbox, {
                key: 0,
                name: "signatureRequired",
                id: "signatureRequired",
                text: "documents.signature-required",
                value: _ctx.model.signatureRequired,
                onChange: _ctx.handleInputChange
              }, null, 8, ["value", "onChange"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}