import { TenantConfig } from "@/interfaces";
import httpClient, { ApiResponse } from "@/api/httpClient";

export const getTenantConfig = async (
  key: string
): Promise<ApiResponse<TenantConfig>> =>
  await httpClient.get(`/tenant-config/${key}`);

export const tenantSetup = async (name = '', description = ''): Promise<ApiResponse<any>> =>
  httpClient.post(`/tenants/setup-tenant`, {
    name,
    description,
  });

export const tenantBilling = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/tenants/billing`);

export const tenantBillingCancel = async (): Promise<ApiResponse<any>> =>
  await httpClient.get(`/tenants/billing/cancel`);