
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  reactive,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import { format, parseISO } from "date-fns";
import { HttpError } from "@/api/httpClient";
import {
  Action,
  Hotspot,
  ActionProgress,
  UserDetails,
  Address,
  ContactNumber,
  EmergencyContact,
  Payroll,
  NewStarterForm,
} from "@/models";
import { FormQuestion, Gender, Language, TextItem } from "@/interfaces";
import { useModal } from "@/plugins/modal";
import { useSchema, Schemas } from "@/plugins/schema";
import Declaration, { DeclarationModel } from "@/components/Declaration.vue";
import UserForm from "@/components/user/details/UserForm.vue";
import AddressList from "@/components/user/shared/AddressList.vue";
import ContactNumberList from "@/components/user/contact/ContactNumberList.vue";
import EmergencyContactList from "@/components/user/shared/EmergencyContactList.vue";
import PayrollForm from "@/components/user/payroll/PayrollForm.vue";
import { getAllGenders } from "@/api/gender.api";
import { getAllLanguages } from "@/api/language.api";
import Button from "@/components/Button.vue";
import ActionHeader from "@/components/hotspot/action/components/Header.vue";
import {
  getFormFeatureConfigs,
  getFormQuestionsByForm,
  submitUserForm,
  uploadFormFile,
} from "@/api/form-questions.api";
import Signature from "@/components/Signature.vue";
import Modal from "@/components/Modal.vue";
import { useNotification } from "@/composables/useNotification";
import useValidate from "@vuelidate/core";
import { useFormQuestion } from "@/composables/useFormQuestion";

export default defineComponent({
  components: {
    Declaration,
    UserForm,
    AddressList,
    ContactNumberList,
    EmergencyContactList,
    PayrollForm,
    Button,
    ActionHeader,
    Modal,
    Signature,
  },
  props: {
    hotspot: {
      type: Object as PropType<Hotspot>,
      required: true,
    },
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    hotspotDescription: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionDescription: {
      type: String,
      required: false,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const showModal = useModal();
    const hiddenUserFields = ref<string[]>([]);
    const { setInitialUserResponses, setDependencies, updateDependencies } =
      useFormQuestion();

    const { inPageNotification } = useNotification();

    const currentUser = computed(() => store.getters.currentUser);

    const submitCheck = ref<boolean>(false);
    const errors = ref<any>({});
    const payrollErrors = ref<any>({});

    const isDirty = ref(false);
    const model = ref<NewStarterForm>();

    const signatureEnabled = ref(true);
    const dobSignatureRequired = ref(false);
    const wetSignature = ref(true);
    const basicSignature = ref(false);
    const contactNumberRequired = store.getters.featureConfigs.find(
      (fc) => fc.feature === "new-starter-contact-number-required"
    );
    const emergencyContactRequired = store.getters.featureConfigs.find(
      (fc) => fc.feature === "new-starter-emergency-contact-required"
    );
    const bankAddressDisplayed = !store.getters.featureConfigs.find(
      (fc) => fc.feature === "new-starter-hide-bank-address"
    );

    const handlePayrollUpdate = async () => {
      isDirty.value = true;
      let val;
      let v$ = useValidate(payrollSchema, payroll.value);
      if (bankAddressDisplayed) {
        if (!payroll.value.bankAddress) {
          payroll.value.bankAddress = new Address();
        }
        let vAddress$ = useValidate(
          addressSchema,
          payroll.value.bankAddress ?? []
        );
        val =
          (await v$.value.$validate()) && (await vAddress$.value.$validate());
        payrollIsValid.value = val;
      } else {
        val = await v$.value.$validate();
        payrollIsValid.value = val;
      }
      payrollErrors.value = {};
      v$.value.$errors.forEach((error) =>
        !payrollErrors.value[error.$property]
          ? (payrollErrors.value[error.$property] = error.$message as string)
          : ""
      );
      submitCheck.value = true;
    };

    const modelIsValid = computed(() => {
      if (
        basicSignature.value &&
        detailsIsValid.value &&
        addressesIsValid.value &&
        payrollIsValid.value &&
        (!contactNumberRequired || contactNumbers.value.length > 0) &&
        (!emergencyContactRequired || emergencyContacts.value.length > 0)
      ) {
        return true;
      }
      if (
        !wetSignature.value &&
        (!signatureEnabled.value || !declarationIsValid.value)
      ) {
        return false;
      }
      return (
        detailsIsValid.value &&
        addressesIsValid.value &&
        payrollIsValid.value &&
        (!contactNumberRequired || contactNumbers.value.length > 0) &&
        (!emergencyContactRequired || emergencyContacts.value.length > 0) &&
        (!signatureEnabled.value || !declarationIsValid.value)
      );
    });
    const getModel = async () => {
      model.value = await NewStarterForm.get();
      await getFormQuestions();
    };

    const formQuestions = ref<Array<FormQuestion>>([]);
    const userResponses = ref({});
    const getFormQuestions = async () => {
      formQuestions.value = [];
      userResponses.value = {};
      uploadFiles.value = {};
      const userId = currentUser.value.id;

      const response = await getFormQuestionsByForm(userId, "user-details");
      formQuestions.value = response.data.payload
        .results as Array<FormQuestion>;

      for (const formQuestion of formQuestions.value) {
        const dependantQuestions = setDependencies(
          formQuestion,
          formQuestions.value
        );
        setInitialUserResponses(
          formQuestion,
          dependantQuestions,
          userResponses.value,
          userDetails.value
        );
      }
    };

    const detailsIsValid = ref(false);
    const userDetails = computed({
      get: () => model.value?.userDetails ?? new UserDetails(),
      set: (value) => {
        if (model.value) {
          model.value.userDetails = value as UserDetails;
        }
      },
    });

    const addressesIsValid = computed(() =>
      model.value?.addresses ? model.value?.addresses?.length > 0 : false
    );
    const addresses = computed({
      get: () => model.value?.addresses ?? [],
      set: (value) => {
        if (model.value) {
          model.value.addresses = value as Array<Address>;
        }
      },
    });
    const handleSaveAddress = async (address: Address) => {
      if (!addresses.value.length) {
        address.userId = currentUser.value?.id ?? "";
        addresses.value.push(address);
      }
    };
    const handleDeleteAddress = async () => {
      if (addresses.value) {
        addresses.value.pop();
      }
    };
    const contactNumbers = computed({
      get: () => model.value?.contactNumbers ?? [],
      set: (value) => {
        if (model.value) {
          model.value.contactNumbers = value as Array<ContactNumber>;
        }
      },
    });
    const handleSaveNumber = async (number: ContactNumber) => {
      if (!contactNumbers.value.length) {
        number.userId = currentUser.value?.id ?? "";
        contactNumbers.value.push(number);
      }
    };
    const handleDeleteNumber = async () => {
      if (contactNumbers.value) {
        contactNumbers.value.pop();
      }
    };

    const emergencyContacts = computed({
      get: () => model.value?.emergencyContacts ?? [],
      set: (value) => {
        if (model.value) {
          model.value.emergencyContacts = value as Array<EmergencyContact>;
        }
      },
    });
    const handleSaveContact = async (contact: EmergencyContact) => {
      if (!emergencyContacts.value.length) {
        contact.userId = currentUser.value?.id ?? "";
        emergencyContacts.value.push(contact);
      }
    };
    const handleDeleteContact = async () => {
      if (emergencyContacts.value) {
        emergencyContacts.value.pop();
      }
    };

    const payrollIsValid = ref(false);
    const payrollSchema = useSchema(Schemas.Payroll);
    const addressSchema = useSchema(Schemas.Address);
    const payroll = computed({
      get: () => model.value?.payroll ?? new Payroll(),
      set: (value) => {
        if (model.value) {
          model.value.payroll = value as Payroll;
        }
      },
    });

    const showSign = ref(false);
    const handleSignClick = async () => {
      await handlePayrollUpdate();
      if (!modelIsValid.value) {
        const errorsObject = {};
        if (errors.value.length) {
          errors.value.forEach(function (e) {
            errorsObject[e?.$property] = e?.$message;
          });
          errors.value = errorsObject;
        }
        if (payrollErrors.value.length) {
          payrollErrors.value.forEach(function (e) {
            errorsObject[e?.$property] = e?.$message;
          });
          payrollErrors.value = errorsObject;
        }
        const el = document.getElementsByClassName("error")[0];
        const y = el
          ? el.getBoundingClientRect().top + window.pageYOffset + -70
          : 0;

        window.scrollTo({ top: y, behavior: "smooth" });
        return false;
      }
      if (
        !signatureEnabled.value ||
        (signatureEnabled.value && !wetSignature.value)
      ) {
        await handleSignSubmit();
      } else {
        showSign.value = !showSign.value;
      }
    };

    const showSignModal = computed(() => showSign.value);
    const handleModalHide = () => {
      declarationWet.signature = "";
      showSign.value = false;
    };

    const declarationIsValid = ref(false);
    const declaration = reactive<DeclarationModel>({
      dob: "",
      consent: false,
    });

    const declarationWet = reactive<{ signature: string }>({
      signature: "",
    });

    const genders = ref<Gender[]>([]);
    const languages = ref<Language[]>([]);
    const getSelectData = async (func, data) => {
      try {
        const response = await func();
        data.value = [...response.data.payload.results];
      } catch (err) {
        //TODO: Handle this error better
        console.log(err);
      }
    };

    const completionDateString = computed(() =>
      props.actionProgress?.completionDate
        ? format(
            parseISO(props.actionProgress.completionDate),
            "dd/MM/yyyy HH:mm:SS"
          )
        : ""
    );
    const handleSignSubmit = async () => {
      try {
        if (model.value) {
          model.value.payroll = payroll.value;
          model.value.payroll.userId = userDetails.value.id as string;
          const response = await model.value.complete(
            props.action.id,
            declaration.dob,
            declaration.consent
          );
          let {
            uploadErrors,
            userChoices,
          }: {
            uploadErrors: number;
            userChoices: {
              formQuestionId: string;
              responseValue: string;
              userId: string;
            }[];
          } = await setupUserDetailsForm();
          isDirty.value = false;

          if (uploadErrors === 0 && userChoices.length) {
            await submitUserForm(userChoices, userDetails.value.id, true);
          }
          emit("action-complete", { progressObj: response });
        }
      } catch (err) {
        const httpError = err as HttpError;
        if (httpError) {
          inPageNotification("declaration.sign.error", httpError.message);
        }
      }
    };
    const uploadFiles = ref({});

    const getTextItem = (textItems: TextItem[], purpose: string) => {
      const textItem = textItems?.find((ti) => ti.purpose === purpose);
      return textItem?.data ?? "";
    };

    async function setupUserDetailsForm() {
      const userChoices: {
        formQuestionId: string;
        responseValue: string;
        userId: string;
      }[] = [];
      const uploadError = {};
      let uploadErrors = 0;

      for (const formQuestionId of Object.keys(userResponses.value)) {
        let userResponseValue = userResponses.value[formQuestionId].value;
        const formQuestion: FormQuestion = formQuestions.value.find(
          (fq: FormQuestion) => fq.id == formQuestionId
        ) as FormQuestion;

        if (!userResponses.value[formQuestionId].hide) {
          // Ensure user responses are correctly formatted as readable values.
          if (formQuestion) {
            switch (formQuestion.questionType) {
              case "imageupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id
                  );
                }
                break;
              case "pdfupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id,
                    "pdf"
                  );
                }
                break;
              case "genericupload":
                if (uploadFiles.value[formQuestionId]) {
                  userResponseValue = await uploadFile(
                    uploadFiles.value[formQuestionId],
                    formQuestion.id,
                    "file"
                  );
                }
                break;
              case "date-of-birth":
              case "date":
                break;
              case "bigtext":
                if (userResponseValue !== null) {
                  userResponseValue = userResponseValue.replace(
                    /(?:\r\n|\r|\n)/g,
                    " "
                  );
                }
                break;
              case "checkboxes":
                userResponseValue = "";
                userResponses.value[formQuestionId].value
                  .split(",")
                  .map((cb: any) => {
                    const formQuestionChoice: any = formQuestion.choices?.find(
                      (item: any) => item.id === cb
                    );
                    userResponseValue +=
                      getTextItem(
                        formQuestionChoice?.textItems,
                        "form-question-choice"
                      ) + ",";
                  });
                userResponseValue = userResponseValue?.substring(
                  0,
                  userResponseValue.length - 1
                );
                break;
              case "select":
              case "statements": {
                const formQuestionChoice: any = formQuestion.choices?.find(
                  (item: any) => item.id === userResponseValue
                );
                userResponseValue =
                  getTextItem(
                    formQuestionChoice?.textItems,
                    "form-question-choice"
                  ) + ",";
                userResponseValue = userResponseValue?.substring(
                  0,
                  userResponseValue.length - 1
                );
                break;
              }
            }
          }

          if (userResponseValue && userResponseValue.type) {
            uploadErrors++;
            uploadError[formQuestionId] = userResponseValue.type;
            inPageNotification(
              "form.error-upload-title",
              "form.error-upload",
              "error"
            );
          }

          if (userResponseValue !== "" && userResponseValue !== null) {
            userChoices.push({
              formQuestionId,
              responseValue: userResponseValue,
              userId: userDetails.value.id
                ? (userDetails.value.id as string)
                : (userDetails.value.id as string),
            });
          }
        }
      }
      return { uploadErrors, userChoices };
    }

    const uploadFile = async (
      file: File,
      reference: string,
      type = "image"
    ) => {
      const formData: FormData = new FormData();
      formData.append("file", file);
      formData.append(
        "reference",
        "user-details" + "-" + type + "-" + reference
      );
      formData.append("userId", userDetails.value.id as string);

      try {
        const uploaded: any = await uploadFormFile(formData);
        return uploaded?.data?.payload?.result?.id || "";
      } catch {
        return {
          type: (type === "image" ? "images" : "documents") + ".upload.fail",
          reference,
        };
      }
    };

    const getFeatureConfigs = async () => {
      const formFeatureConfigs = await getFormFeatureConfigs();
      formFeatureConfigs.data.payload.result.forEach((featureConfig) => {
        switch (featureConfig.feature) {
          case "sign-off":
            signatureEnabled.value = featureConfig.config;
            break;
          case "signature-type":
            dobSignatureRequired.value =
              featureConfig.condition === "date-of-birth";
            wetSignature.value = featureConfig.condition === "wet";
            basicSignature.value = featureConfig.condition === "basic";
            break;
        }
      });
    };
    const isValid = ref(false);

    const handleDoChange = ({ name, value }) => {
      if (name) {
        if (!Object.keys(errors.value).length) {
          isValid.value = true;
        }
        userResponses.value[name].changed = true;
        isDirty.value = true;
        userResponses.value[name].value = value;
        const formQuestion = formQuestions.value.find((fq) => fq.id === name);

        if (formQuestion?.fieldReference !== undefined) {
          userDetails.value[formQuestion?.fieldReference] = value;
        }
      }
    };

    const handleChoiceChange = (event) => {
      const { name, value } = event.target;
      if (!isDirty.value) isDirty.value = true;
      updateDependencies(name, value, userResponses.value);
    };

    const handleSelectChange = ({ name, value }) => {
      if (!isDirty.value) isDirty.value = true;
      updateDependencies(name, value, userResponses.value);
    };

    onMounted(async () => {
      getFeatureConfigs();
      getSelectData(getAllGenders, genders);
      getSelectData(getAllLanguages, languages);
      await getModel();

      watch(
        [userDetails, addresses, contactNumbers, emergencyContacts, payroll],
        () => {
          isDirty.value = true;
        },
        { deep: true }
      );
    });
    watch(props, () => getModel());

    onBeforeRouteLeave(async () => {
      if (isDirty.value) {
        const confirmed = await showModal({
          title: "prompts.unsaved-changes.title",
          body: "prompts.unsaved-changes.message",
          onConfirm: async () => {
            return true;
          },
        });

        if (!confirmed) {
          return false;
        }
      }
    });

    const hiddenFields =
      store.getters.featureConfigs.find(
        (fc) => fc.feature === "hidden-user-fields"
      )?.condition ?? "";
    hiddenUserFields.value = hiddenFields.split(",");

    const formSchemaChanges = store.getters.featureConfigs.find(
      (fc) => fc.feature === "form-schema-changes"
    );
    const schemaChanges = formSchemaChanges?.condition
      ? JSON.parse(formSchemaChanges.condition)
      : {};

    return {
      formSchemaChanges: schemaChanges,
      completionDateString,
      userDetails,
      detailsIsValid,
      addressesIsValid,
      addresses,
      contactNumbers,
      emergencyContacts,
      payroll,
      payrollIsValid,
      payrollSchema,
      declaration,
      declarationIsValid,
      modelIsValid,
      errors,
      genders,
      languages,
      isDirty,
      handleSignSubmit,
      handleSaveAddress,
      handleDeleteAddress,
      handleSaveNumber,
      handleDeleteNumber,
      handleSaveContact,
      handleDeleteContact,
      declarationWet,
      handleModalHide,
      showSignModal,
      handleSignClick,
      wetSignature,
      handleDoChange,
      hiddenUserFields,
      userResponses,
      formQuestions,
      payrollErrors,
      handlePayrollUpdate,
      handleChoiceChange,
      handleSelectChange,
      bankAddressDisplayed,
      contactNumberRequired,
      emergencyContactRequired,
      submitCheck,
    };
  },
});
