
import { defineComponent, PropType, watch, ref } from "vue";
import { NavItem } from "@/interfaces";
import { useBreakpoints } from "@/composables";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<NavItem>>,
      required: true,
    },
    activeItem: {
      type: String,
    },
  },
  setup(props) {
    const { screenWidth } = useBreakpoints();

    const handleClick = (e: Event, item: NavItem) => {
      if (item.action) {
        e.preventDefault();
        item.action();
      }
    };

    const list = ref();
    watch(props, () => {
      if (props.activeItem) {
        const index = props.items.findIndex((i) => i.url === props.activeItem);
        const el = list.value.getElementsByClassName(`list-item-${index}`)[0];
        if (el) {
          const elBounds = el.getBoundingClientRect();
          if (elBounds.right > screenWidth.value) {
            list.value.scrollLeft += elBounds.left;
          }
        }
      }
    });

    return {
      list,
      handleClick,
    };
  },
});
