import { GetterTree, Module, ActionTree, MutationTree } from "vuex";
import { Tenant } from "@/models/Tenant";

enum MutationKeys {
  setTenant = "setTenant",
  setUrlTenant = "setUrlTenant",
  setThemeUpdateAllowed = "setThemeUpdateAllowed",
  setAvatarUpdateAllowed = "setAvatarUpdateAllowed",
  setFeatureConfigs = "setFeatureConfigs",
  setMaintenanceMode = "setMaintenanceMode",
}

interface TenantState {
  tenant: Tenant | null;
  urlTenant: boolean;
  themeUpdateAllowed: boolean;
  avatarUpdateAllowed: boolean;
  featureConfigs: Array<any>;
  maintenanceMode: boolean;
}

const state: TenantState = {
  tenant: null,
  urlTenant: false,
  themeUpdateAllowed: true,
  avatarUpdateAllowed: true,
  featureConfigs: [],
  maintenanceMode: false,
};

const getters: GetterTree<TenantState, null> = {
  currentTenant: (state: TenantState) => state.tenant,
  isThemeUpdateAllowed: (state: TenantState) => state.themeUpdateAllowed,
  isAvatarUpdateAllowed: (state: TenantState) => state.avatarUpdateAllowed,
  isMaintenanceMode: (state: TenantState) => state.maintenanceMode,
  isUrlTenant: (state: TenantState) => state.urlTenant,
  useSubdomain: () =>
    window.location.hostname.indexOf(".jolahr.com") > -1 ||
    window.location.hostname.indexOf(".wearehumanhr.com") > -1,
  featureConfigs: (state: TenantState) => state.featureConfigs,
};

const actions: ActionTree<TenantState, null> = {
  async calcTenant({ commit, getters }) {
    let tenantInfo, name;
    if (
      !getters.useSubdomain &&
      !(
        RegExp(/^([^.]+\.[^.]+\.[^.]+)$/).test(window.location.hostname) &&
        (window.location.hostname.indexOf(".jolahr.com") > -1 ||
          window.location.hostname.indexOf(".wearehumanhr.com") > -1)
      )
    ) {
      tenantInfo = await Tenant.getByURL(window.location.hostname);
    }

    if (tenantInfo?.tenant) {
      name = tenantInfo?.tenant;
      commit(MutationKeys.setUrlTenant, true);
    } else {
      name = getters.useSubdomain
        ? window.location.host.split(".")[0]
        : window.location.pathname.split("/")[1];
      commit(MutationKeys.setUrlTenant, false);
    }
    if (name && name != "signin") {
      const tenant = await Tenant.get(name);
      commit(MutationKeys.setTenant, tenant);

      commit(MutationKeys.setMaintenanceMode, tenant?.inMaintenance ?? false);
    } else {
      commit(MutationKeys.setTenant, null);
    }
  },
  resetTenant({ commit }) {
    commit(MutationKeys.setTenant, null);
  },
  setTenant({ commit }, tenant: string) {
    commit(MutationKeys.setTenant, tenant);
  },
  setUrlTenant({ commit }, urlTenant: boolean) {
    commit(MutationKeys.setUrlTenant, urlTenant);
  },
  setThemeUpdateAllowed({ commit }, allowed: boolean) {
    commit(MutationKeys.setThemeUpdateAllowed, allowed);
  },
  setAvatarUpdateAllowed({ commit }, allowed: boolean) {
    commit(MutationKeys.setAvatarUpdateAllowed, allowed);
  },
  setMaintenanceMode({ commit }, inMaintenance: boolean) {
    commit(MutationKeys.setMaintenanceMode, inMaintenance);
  },
  setFeatureConfigs({ commit }, featureConfigs: Array<any>) {
    commit(MutationKeys.setFeatureConfigs, featureConfigs);
  },
  async initTenant({ dispatch, getters }) {
    const tenant = getters.currentTenant;
    if (!tenant) {
      await dispatch("calcTenant");
    }
  },
};

const mutations: MutationTree<TenantState> = {
  [MutationKeys.setTenant]: (state: TenantState, tenant: Tenant | null) => {
    state.tenant = tenant;
  },
  [MutationKeys.setUrlTenant]: (state: TenantState, urlTenant: boolean) => {
    state.urlTenant = urlTenant;
  },
  [MutationKeys.setThemeUpdateAllowed]: (
    state: TenantState,
    allowed: boolean
  ) => {
    state.themeUpdateAllowed = allowed;
  },
  [MutationKeys.setAvatarUpdateAllowed]: (
    state: TenantState,
    allowed: boolean
  ) => {
    state.avatarUpdateAllowed = allowed;
  },
  [MutationKeys.setFeatureConfigs]: (
    state: TenantState,
    featureConfigs: Array<any>
  ) => {
    state.featureConfigs = featureConfigs;
  },
  [MutationKeys.setMaintenanceMode]: (
    state: TenantState,
    inMaintenance: boolean
  ) => {
    state.maintenanceMode = inMaintenance;
  },
};

const tenant: Module<TenantState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { tenant };
