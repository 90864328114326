import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce21ed3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subtitle hidden-lg" }
const _hoisted_2 = { class: "action-button" }
const _hoisted_3 = {
  key: 0,
  class: "toggle"
}
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = {
  key: 0,
  class: "delete-button"
}
const _hoisted_6 = { class: "modal-content" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.userIsLoading })
  }, [
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#headerSlot"
        }, [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_TranslatedText, { path: "models.user.list.title" })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Page, {
      title: _ctx.pageTitle,
      subtitle: _ctx.pageSubtitle,
      navItems: _ctx.navItems,
      activeItem: _ctx.currentNavItem?.url
    }, _createSlots({
      back: _withCtx(() => [
        _createVNode(_component_BackLink, { url: "/users" })
      ]),
      header: _withCtx(() => [
        (
            !_ctx.isNewUser &&
            !_ctx.isAuthenticatedUser &&
            ((!_ctx.isActive && _ctx.userHasPermission('user:status:activate')) ||
              (_ctx.isActive && _ctx.userHasPermission('user:status:deactivate')))
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (!_ctx.userIsLoading)
                ? (_openBlock(), _createBlock(_component_Switch, {
                    key: 0,
                    value: _ctx.isActive,
                    text: "common.active",
                    onChange: _ctx.handleStatusChange
                  }, null, 8, ["value", "onChange"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Avatar, {
          image: _ctx.user?.profileImage,
          text: _ctx.avatarFallbackText,
          variant: _ctx.avatarVariant,
          icon: _ctx.avatarIcon,
          canEdit: true,
          imageData: _ctx.profileImageData,
          onImageUpdated: _ctx.handleAvatarUpdate
        }, null, 8, ["image", "text", "variant", "icon", "imageData", "onImageUpdated"])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (
              _ctx.allowManualComplete &&
              !_ctx.isNewUser &&
              !_ctx.isAuthenticatedUser &&
              !_ctx.isComplete &&
              _ctx.userHasPermission('user:mark-complete')
            )
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                text: "models.user.mark-complete.title",
                onClick: _ctx.handleMarkCompleteClick,
                block: "",
                variant: "secondary",
                class: "mb-1"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (
              !_ctx.isNewUser &&
              !_ctx.isAuthenticatedUser &&
              _ctx.userHasPermission('user:archive')
            )
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                text: "models.user.archive.title",
                onClick: _ctx.handleArchiveClick,
                block: "",
                variant: "secondary"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      "content-footer__buttons": _withCtx(() => [
        (
            !_ctx.isNewUser &&
            !_ctx.isAuthenticatedUser &&
            _ctx.userHasPermission('user:delete')
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Button, {
                text: "models.user.delete.title",
                size: "sm",
                variant: "error",
                block: _ctx.deleteButtonIsBlock,
                onClick: _ctx.handleDeleteClick
              }, {
                labelBefore: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "trash" })
                ]),
                _: 1
              }, 8, ["block", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.currentNavItem?.button)
        ? {
            name: "actionButton",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Button, {
                  text: _ctx.currentNavItem.button
                }, null, 8, ["text"])
              ])
            ])
          }
        : undefined,
      (_ctx.currentNavItem?.component)
        ? {
            name: "default",
            fn: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentNavItem.component), {
                config: _ctx.currentNavItem?.config ?? {},
                user: _ctx.user,
                isNewUser: _ctx.isNewUser,
                userIsLoading: _ctx.userIsLoading,
                actionButton: _ctx.currentNavItem.button,
                isAuthenticatedUser: _ctx.isAuthenticatedUser,
                onUpdatePageTitle: _ctx.handleUpdatePageTitle,
                onUpdatePageSubtitle: _ctx.handleUpdatePageSubtitle,
                onCreated: _ctx.handleUserDetailsCreated,
                onDelete: _ctx.handleDeleteClick
              }, null, 8, ["config", "user", "isNewUser", "userIsLoading", "actionButton", "isAuthenticatedUser", "onUpdatePageTitle", "onUpdatePageSubtitle", "onCreated", "onDelete"]))
            ])
          }
        : undefined
    ]), 1032, ["title", "subtitle", "navItems", "activeItem"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.showDeleteModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDeleteModal) = $event)),
      onCancel: _ctx.handleDeleteCancel,
      onConfirm: _ctx.handleConfirmDelete,
      onDismiss: _ctx.handleDeleteCancel
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_font_awesome_icon, {
            icon: "exclamation-triangle",
            size: "4x"
          }),
          _createElementVNode("h4", null, [
            _createVNode(_component_TranslatedText, { path: "models.user.delete.title" })
          ]),
          (_ctx.user.roles?.includes('manager'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_Select, {
                  onChange: _ctx.handleManagerChange,
                  error: "",
                  label: "users.delete.new-manager",
                  name: "managers",
                  id: "managers",
                  items: _ctx.managerSelectOptions
                }, null, 8, ["onChange", "items"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, [
            _createVNode(_component_TranslatedText, { path: "models.user.delete.prompt" })
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_TranslatedText, { path: "common.data-loss-warning" })
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_TranslatedText, { path: "common.archive.prompt" })
          ])
        ])
      ]),
      footer: _withCtx(({ confirm, cancel }) => [
        _createVNode(_component_Button, {
          text: "common.cancel",
          onClick: cancel,
          size: "sm",
          outline: ""
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          text: "common.delete",
          variant: "error",
          size: "sm",
          disabled: _ctx.user.roles?.includes('manager') && !_ctx.selectedManager,
          onClick: confirm
        }, null, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "onCancel", "onConfirm", "onDismiss"])
  ], 2))
}