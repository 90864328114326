import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "forms-input-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "input-icon prepend"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Select = _resolveComponent("Select", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options?.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.options?.id,
          class: _normalizeClass({
        active: _ctx.options?.active,
        'has-icon': !!_ctx.$slots.prepend,
      })
        }, [
          _createVNode(_component_TranslatedText, {
            path: _ctx.options?.label
          }, null, 8, ["path"]),
          (_ctx.options?.required)
            ? (_openBlock(), _createBlock(_component_TranslatedText, {
                key: 0,
                path: "form.required.asterix",
                class: "required-asterix"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.prepend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "prepend", {
            value: _ctx.options?.value
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Select, {
      value: _ctx.options?.value,
      items: _ctx.getSelectOptions(),
      label: "",
      name: _ctx.options?.id,
      id: _ctx.options?.id,
      required: _ctx.options?.required,
      disabled: _ctx.options?.disabled,
      onChange: _ctx.handleInput,
      onBlur: _ctx.handleBlur,
      placeholder: _ctx.options?.placeholder,
      onEdit: _ctx.handleInput,
      onKeyup: _withKeys(_ctx.enter, ["enter"]),
      customClass: "mt-0",
      error: _ctx.options?.error
    }, null, 8, ["value", "items", "name", "id", "required", "disabled", "onChange", "onBlur", "placeholder", "onEdit", "onKeyup", "error"]),
    (!!_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "input-icon append",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, [
          _renderSlot(_ctx.$slots, "append", {
            value: _ctx.options?.value
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}