import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewView = _resolveComponent("ReviewView")!

  return (_ctx.reviews && _ctx.user)
    ? (_openBlock(), _createBlock(_component_ReviewView, {
        key: 0,
        reviews: _ctx.reviews,
        answers: _ctx.answers,
        user: _ctx.user,
        userIsLoading: _ctx.userIsLoading,
        navItemConfig: _ctx.navItemConfig,
        updatedReviews: _ctx.updatedReviews,
        probationEnabled: _ctx.probationEnabled,
        probationExtensionsEnabled: _ctx.probationExtensionsEnabled,
        firstProbationReview: _ctx.firstProbationReview,
        passedProbation: _ctx.passedProbation,
        savedReviews: _ctx.savedReviews,
        onProbationChange: _ctx.handleProbationChange,
        onUpdate: _ctx.handleUpdate,
        onSave: _ctx.handleSave,
        onCancel: _ctx.handleCancel,
        onAddExtensionReview: _ctx.handleAddExtensionReview,
        onAddActionObjective: _ctx.handleAddActionObjective
      }, null, 8, ["reviews", "answers", "user", "userIsLoading", "navItemConfig", "updatedReviews", "probationEnabled", "probationExtensionsEnabled", "firstProbationReview", "passedProbation", "savedReviews", "onProbationChange", "onUpdate", "onSave", "onCancel", "onAddExtensionReview", "onAddActionObjective"]))
    : _createCommentVNode("", true)
}