
import { defineComponent, computed } from "vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

export default defineComponent({
  name: "404",
  components: {
    AuthenticatedLayout,
  },
  setup() {
    const backgroundData = {
      image: require("@/assets/img/backgrounds/panels/error.png"),
    };
    const getBackgroundStyle = computed(
      () =>
        true && {
          "--backgroundImage": `url(${backgroundData.image})`,
        }
    );
    return {
      getBackgroundStyle,
    };
  },
});
