import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayrollForm = _resolveComponent("PayrollForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: _ctx.isLoading })
  }, [
    _createVNode(_component_PayrollForm, {
      modelValue: _ctx.payroll,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payroll) = $event)),
      errors: _ctx.errors,
      "onUpdate:errors": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errors) = $event)),
      isValid: _ctx.isValid,
      "onUpdate:isValid": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isValid) = $event)),
      bankAddressDisplayed: _ctx.bankAddressDisplayed,
      "onUpdate:payroll": _ctx.handleUpdate
    }, null, 8, ["modelValue", "errors", "isValid", "bankAddressDisplayed", "onUpdate:payroll"]),
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: ".page-content-footer"
        }, [
          _createVNode(_component_Button, {
            text: "prompts.discard-changes.title",
            size: "sm",
            disabled: !_ctx.isDirty,
            onClick: _ctx.handleDiscardChanges,
            variant: "secondary"
          }, null, 8, ["disabled", "onClick"]),
          _createVNode(_component_Button, {
            text: "common.save",
            size: "sm",
            disabled: !_ctx.isDirty,
            onClick: _ctx.handleSavePayroll
          }, null, 8, ["disabled", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}