
import { defineComponent, watch, ref, computed } from "vue";
import { useStore } from "vuex";

import Avatar from "@/components/Avatar.vue";
import FormInput from "@/components/form/FormInput.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    Avatar,
    FormInput,
    Button,
  },
  props: {
    activeChatMessages: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    //const activeChats = ref("") as any;

    const activeChats = computed(() => props.activeChatMessages) as any;

    const managerImage = ref(activeChats.manager?.imageContents[0]?.url);
    const userImage = ref(activeChats.user?.imageContents[0]?.url);

    const newMessage = ref("");

    const getUserImage = (userId) => {
      if (userId == activeChats.value.managerId) {
        return activeChats.value.manager.imageContents.length > 0
          ? activeChats.value.manager.imageContents[0]?.url
          : null;
      } else if (userId == activeChats.value.userId) {
        return activeChats.value.user.imageContents.length > 0
          ? activeChats.value.user.imageContents[0]?.url
          : null;
      } else {
        return null;
      }
    };

    const getUserName = (userId) => {
      if (userId == activeChats.value.managerId) {
        return `${activeChats.value.manager.firstName} ${activeChats.value.manager.surname}`;
      } else if (userId == activeChats.value.userId) {
        return `${activeChats.value.user.firstName} ${activeChats.value.user.surname}`;
      } else {
        return null;
      }
    };

    const getUserInitials = (userId) => {
      if (userId == activeChats.value.managerId) {
        return `${activeChats.value.manager.firstName.charAt(
          0
        )}${activeChats.value.manager.surname.charAt(0)}`;
      } else if (userId == activeChats.value.userId) {
        return `${activeChats.value.user.firstName.charAt(
          0
        )}${activeChats.value.user.surname.charAt(0)}`;
      } else {
        return null;
      }
    };

    let lastChatDate = "";

    const dateChanged = (timestamp) => {
      const checkDate = formatDate(timestamp);

      const hasChanged = lastChatDate == "" || lastChatDate != checkDate;

      lastChatDate = checkDate;

      return hasChanged;
    };

    const pad = (num) => ("0" + num).slice(-2);

    const formatDate = (timestamp) => {
      const currentDate = new Date();
      const date = new Date(timestamp);
      let day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear();

      const stringMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        day == currentDate.getDate() &&
        month == currentDate.getMonth() &&
        year == currentDate.getFullYear()
      ) {
        return "today";
      }
      return `${pad(day)} ${stringMonths[month]} ${year}`;
    };

    const formatTime = (timestamp) => {
      const date = new Date(timestamp);
      let hours = date.getHours(),
        minutes = date.getMinutes();
      return pad(hours) + ":" + pad(minutes);
    };

    const getChatStyle = () => {
      return store.state.user.currentUser?.id == activeChats.value.managerId
        ? "flow--main"
        : "flow--alt";
    };

    const getMessageStyle = (userId) => {
      return userId == activeChats.value.managerId ? "item--one" : "item--two";
    };

    const scrollMessages = () => {
      var container = document.querySelector(".chat-messages");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    };
    const handleInputChange = ({ name, value }) => {
      if (name === "newMessage") {
        newMessage.value = value;
      }
    };

    const sendMessage = () => {
      if (newMessage.value != "") {
        const currentDate = new Date();
        emit("add-new-message", {
          text: newMessage.value,
          timestamp: currentDate,
          userId: store.state.user.currentUser.id,
        });
        newMessage.value = "";
      }
      return false;
    };

    watch(props, () => {
      if (props.activeChatMessages) {
        setTimeout(() => scrollMessages(), 100);
      }
    });

    return {
      activeChats,
      userImage,
      managerImage,
      getUserImage,
      getUserName,
      getUserInitials,
      dateChanged,
      formatDate,
      formatTime,
      getChatStyle,
      getMessageStyle,
      handleInputChange,
      newMessage,
      sendMessage,
    };
  },
});
