
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  toRef,
  PropType,
} from "vue";
import { useStore } from "vuex";
import { Video as IVideo } from "@/interfaces/ui";
import { getVideoContents } from "@/api/video.api";
import Video from "@/components/Video.vue";
import { Action, ActionProgress } from "@/models";

import { getImageContents } from "@/api/image-contents.api";

export default defineComponent({
  components: {
    Video,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    actionProgress: {
      type: Object as PropType<ActionProgress>,
    },
    completeActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: String,
      required: false,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updated", "completed"],
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const action = toRef(props, "action");
    const completeActive = toRef(props, "completeActive");

    const videoData = ref<IVideo>({
      fileName: "",
      groupId: null,
      id: "",
      languageId: "",
      protected: false,
      reference: "",
      storageKey: "",
      transcoding: false,
      transcodingCompleted: false,
      url: null,
      userId: null,
      youtubeUrl: null,
      signOffEnabled: false,
    });

    const placeholderUrl = ref("");

    const getVideo = async (
      references: string[],
      role: string,
      groupId: string,
      userId: string
    ) => {
      try {
        const referencesString = JSON.stringify(references);
        const imageResponse = await getImageContents(
          references,
          "null",
          "admin"
        );
        if (imageResponse.data?.payload?.results) {
          const imageData = imageResponse.data?.payload?.results[0];
          if (imageData) {
            placeholderUrl.value = imageData.url;
          }
        }
        const response = await getVideoContents(
          referencesString,
          role,
          groupId,
          userId
        );
        return response.data.payload.results;
      } catch (err) {
        console.log(err);
      }
    };

    const getCurrentVideo = async () => {
      const response = await getVideo(
        [currentVideo.value?.reference] as string[],
        store.getters.token.decoded.roles[0],
        "",
        ""
      );
      if (response) {
        videoData.value = response[0];
      }

      emit("updated", { signatureEnabled: videoData.value?.signOffEnabled });
    };

    const videoReferences = computed(() => {
      const actions: any[] = [];
      if (action.value?.videoReference) {
        actions.push({
          actionId: action.value.id,
          reference: action.value.videoReference,
        });
      }
      return actions;
    });

    const currentVideo = computed(() =>
      videoReferences.value.find((video) => video.actionId === action.value.id)
    );

    const currentVideoType = computed(() =>
      videoData.value && videoData.value.url ? "upload" : "embed"
    );

    const formatYoutubeURL = (url) => {
      url = `${url}${
        url.search("rel=") >= 0
          ? ""
          : url.search(/\?/) >= 0
          ? "&rel=0"
          : "?rel=0"
      }`;
      return `${url}`;
    };

    const getVideoKey = computed(() => {
      return `${currentVideoSource.value}${currentVideo.value.reference}`;
    });

    const currentVideoSource = computed(() => {
      if (videoData.value && videoData.value.url) {
        return videoData.value.url;
      } else if (videoData.value && videoData.value.youtubeUrl) {
        return formatYoutubeURL(videoData.value.youtubeUrl);
      } else {
        return null;
      }
    });

    const handleVideoEnded = async () => {
      emit("updated", { readyToSign: true });
    };

    onMounted(async () => {
      if (videoReferences.value.length) await getCurrentVideo();
      isLoading.value = false;
    });

    watch(action, async () => {
      if (videoReferences.value.length) await getCurrentVideo();
    });

    watch(completeActive, async () => {
      try {
        if (completeActive.value) {
          emit("completed", true);
        } else {
          emit("completed", false);
          throw "error";
        }
      } catch (err) {
        console.log(err);
        emit("completed", false);
      }
    });

    return {
      currentVideo,
      currentVideoType,
      currentVideoSource,
      getVideoKey,
      isLoading,
      videoData,
      handleVideoEnded,
      placeholderUrl,
    };
  },
});
