import httpClient, { ApiResponse } from "@/api/httpClient";
import { AuthenticatedUser } from "@/interfaces";
import { AxiosResponse } from "axios";

export const login = async (
  email: string,
  password: string,
  tenant: string
): Promise<AxiosResponse<any>> =>
  await httpClient.post("/auth", {
    email,
    password,
    frontend: true,
    tenant: tenant,
  });

export const getMe = async (): Promise<ApiResponse<AuthenticatedUser>> =>
  await httpClient.get("/users/me");

export const refreshToken = async (): Promise<AxiosResponse<any>> =>
  await httpClient.post("/auth/refresh");

export const sendPasswordReset = async (
  email: string,
  tenant: string
): Promise<AxiosResponse<any>> =>
  await httpClient.post("/auth/forgot-password", { email, tenant });

export const changePassword = async (
  body: any
): Promise<AxiosResponse<any>> =>
  await httpClient.post("/auth/reset-password/auth", body);

export const resetPassword = async (
  body: any
): Promise<AxiosResponse<any>> =>
  await httpClient.post("/auth/reset-password", body);

export const getResetUser = async (userId: string, tenant: string): Promise<ApiResponse<AuthenticatedUser>> =>
  await httpClient.get("/users/email" + '/' + userId + '/' + tenant);
