import { App, inject } from "vue";
import store from "@/store";
import jwt_decode from "jwt-decode";
import { getMe, login, refreshToken } from "@/api/auth.api";
import { Jwt } from "@/interfaces";

export const authSymbol = Symbol();
export interface AuthProvider {
  login: (email: string, password: string, tenant: string) => Promise<void>;
  refresh: () => Promise<void>;
}

export default {
  install: (app: App): void => {
    const setToken = (token: string, decoded: Jwt) => {
      store.dispatch("login", { value: token, decoded });
    };

    const refresh = async () => {
      const response = await refreshToken();
      if (!response || !response.data) {
        const tenant = store.getters.currentTenant?.name;
        await store.dispatch("logout");
        window.location.href = `${window.location.origin}/${tenant}/signin`;
      } else {
        const accessToken = response?.data.payload.result;
        const decoded: Jwt = jwt_decode(accessToken);
        setToken(accessToken, decoded);
      }
    };

    const loginUser = async (
      email: string,
      password: string,
      tenant: string
    ) => {
      const loginResponse = await login(email.toLowerCase(), password, tenant);
      const result = loginResponse?.data.payload.result;
      if (result) {
        if (
          result["addn"] &&
          result["addn"]["message"] === "Password has expired."
        ) {
          store.dispatch("setUserPasswordChange", true);
        } else if (store.getters.changePassword) {
          store.dispatch("setUserPasswordChange", false);
        }
        const { accessToken } = loginResponse?.data.payload.result;
        const decoded: Jwt = jwt_decode(accessToken);
        setToken(accessToken, decoded);

        const meResponse = await getMe();
        if (meResponse.status === 200) {
          store.dispatch("setUserDetails", meResponse.data.payload.result);
        }
      } else {
        throw new Error("Email and password combination not found");
      }
    };

    const authProvider: AuthProvider = {
      login: loginUser,
      refresh,
    };

    app.config.globalProperties.$authProvider = authProvider;
    app.provide(authSymbol, authProvider);
  },
};

export const useAuthProvider = (): AuthProvider => {
  const plugin = inject<AuthProvider>(authSymbol);
  if (!plugin) throw new Error("Auth plugin not installed");

  return plugin;
};
