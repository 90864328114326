import httpClient from "@/api/httpClient";
import { Model } from "./Model";

export class ChecklistItem {
  public id: string;
  public name: string;
  public completed: boolean;
  public additionalDescription?: string;
  public adminDescription?: string;

  constructor({ id = "", name = "", completed = false } = {}) {
    this.id = id;
    this.name = name;
    this.completed = completed;
  }
}

export class Checklist extends Model<Checklist> {
  private static readonly path: string = "/checklists";

  public userId: string;
  public name: string;
  public items: Array<ChecklistItem>;

  constructor({
    id = "",
    userId = "",
    name = "",
    items = [] as Array<ChecklistItem>,
  } = {}) {
    super(id, Checklist, Checklist.path);
    this.userId = userId;
    this.name = name;
    this.items = items;
  }

  public static async get(userId: string): Promise<Array<Checklist>> {
    return await Model.getAll<Checklist, never>(
      `/users/${userId}${Checklist.path}`,
      Checklist
    );
  }

  public async updateItem(item: ChecklistItem): Promise<void> {
    await httpClient.put(`/ticks/progress/${this.userId}`, item);
  }
}
