import { User, UserDetails } from "@/models";
import httpClient, { ApiResponse } from "@/api/httpClient";

export const getById = async (id: string): Promise<ApiResponse<User>> =>
  await httpClient.get(`/users/${id}/summary`);

export const getDetailsById = async (id: string): Promise<ApiResponse<User>> =>
  await httpClient.get(`/users/${id}/details`);

export const getManagers = async (): Promise<ApiResponse<User>> =>
  await httpClient.get(`/users/managers`);

export const updateActiveStatus = async (
  id: string,
  isDeactivated: boolean
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/users/deactivate/${id}`, { isDeactivated });
};

export const deleteUser = async (id: string): Promise<ApiResponse<void>> => {
  return await httpClient.delete(`/users/${id}`);
};

export const unlockUser = async (id: string): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/user-status/unlock`, { userId: id });
};

export const sendWelcomeEmail = async (
  userId: string
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/${userId}/welcome`);
};

export const setTourViewed = async (): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/tour`);
};

export const getAuthorityToDisclose = async (): Promise<ApiResponse<User>> =>
  await httpClient.get(`/users/authority-to-disclose`);

export const signAuthorityToDisclose = async (): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/authority-to-disclose`);
};

export const saveUser = async (
  user: UserDetails
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/users/${user.id}`, user);
};

export const createUser = async (
  user: UserDetails
): Promise<ApiResponse<User>> => {
  return await httpClient.post("/users", user);
};

export const archiveUser = async (userId: string): Promise<any> =>
  httpClient.get(`/archives/${userId}`, {
    responseType: "blob",
  });

export const assignContact = async (
  userId: string,
  body: any
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/assign-contact/${userId}`, body);
};

export const unassignContact = async (
  userId: string,
  body: any
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/unassign-contact/${userId}`, body);
};

export const updateContactFilters = async (
  userId: string,
  body: any
): Promise<ApiResponse<void>> => {
  return await httpClient.post(`/users/update-contact-filters/${userId}`, body);
};

export const completeUser = async (
  userId: string,
): Promise<ApiResponse<void>> => {
  return await httpClient.put(`/users/complete/${userId}`);
};
