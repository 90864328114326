import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cabf3d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subtitle hidden-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "caption" }
const _hoisted_4 = {
  key: 2,
  class: "caption"
}
const _hoisted_5 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_ReviewForm = _resolveComponent("ReviewForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Page = _resolveComponent("Page")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      (_ctx.isMounted)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({ loading: _ctx.userIsLoading })
          }, [
            (_openBlock(), _createBlock(_Teleport, { to: "#headerSlot" }, [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_TranslatedText, { path: "models.user.list.title" })
              ])
            ])),
            _createVNode(_component_Page, {
              title: _ctx.pageTitle,
              navItems: _ctx.navItems,
              activeItem: _ctx.currentNavItem?.url
            }, _createSlots({
              back: _withCtx(() => [
                _createVNode(_component_BackLink, { url: "/performance" })
              ]),
              header: _withCtx(() => [
                _createVNode(_component_Avatar, {
                  image: _ctx.user?.profileImage,
                  text: _ctx.avatarFallbackText,
                  variant: _ctx.avatarVariant,
                  icon: _ctx.avatarIcon
                }, null, 8, ["image", "text", "variant", "icon"])
              ]),
              footer: _withCtx(() => []),
              _: 2
            }, [
              (_ctx.currentReview)
                ? {
                    name: "default",
                    fn: _withCtx(() => [
                      _createVNode(_component_ReviewForm, {
                        currentReview: _ctx.currentReview,
                        answers: _ctx.answers,
                        savedReviews: _ctx.savedReviews,
                        onUpdate: _ctx.handleUpdate
                      }, null, 8, ["currentReview", "answers", "savedReviews", "onUpdate"]),
                      (!_ctx.disabled)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                            _createElementVNode("span", _hoisted_3, [
                              _createVNode(_component_Button, {
                                text: _ctx.translateText('reviews.add-action-objective'),
                                variant: "link",
                                onClick: _ctx.addActionObjective
                              }, null, 8, ["text", "onClick"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (
              _ctx.probationEnabled &&
              _ctx.currentReview.reviewNumber >= _ctx.firstProbationReview
            )
                        ? (_openBlock(), _createBlock(_component_Checkbox, {
                            key: 1,
                            name: "probation",
                            id: "probation",
                            text: _ctx.translateText('reviews.passed-probation'),
                            value: _ctx.passedProbation,
                            onChange: _ctx.probationChange,
                            disabled: _ctx.disabled
                          }, null, 8, ["text", "value", "onChange", "disabled"]))
                        : _createCommentVNode("", true),
                      (
              _ctx.probationEnabled &&
              _ctx.probationExtensionsEnabled &&
              _ctx.completedReviewsCount === _ctx.reviews.length &&
              !_ctx.passedProbation
            )
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                            _createVNode(_component_Button, {
                              text: _ctx.translateText('reviews.add-extension-review'),
                              variant: "link",
                              onClick: _ctx.addExtensionReview
                            }, null, 8, ["text", "onClick"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_5, [
                        (!_ctx.disabled)
                          ? (_openBlock(), _createBlock(_component_Button, {
                              key: 0,
                              text: "reviews.save",
                              onClick: _ctx.cancel,
                              class: "spaced",
                              variant: "secondary"
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (!_ctx.disabled)
                          ? (_openBlock(), _createBlock(_component_Button, {
                              key: 1,
                              text: 'common.save',
                              onClick: _ctx.save,
                              disabled: _ctx.disableSaveButton
                            }, null, 8, ["text", "onClick", "disabled"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  }
                : undefined
            ]), 1032, ["title", "navItems", "activeItem"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}