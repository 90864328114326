import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = {
  key: 0,
  class: "modal__title"
}
const _hoisted_3 = {
  key: 1,
  class: "modal__subtitle"
}
const _hoisted_4 = { class: "modal__body" }
const _hoisted_5 = {
  key: 2,
  class: "modal__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)), ["esc"])),
        tabindex: "0"
      }, [
        _createVNode(_component_Overlay, { onClose: _ctx.handleCancel }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_2, [
                    _createVNode(_component_TranslatedText, { path: _ctx.title }, null, 8, ["path"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.subTitle)
                ? (_openBlock(), _createElementBlock("h6", _hoisted_3, [
                    _createVNode(_component_TranslatedText, { path: _ctx.subTitle }, null, 8, ["path"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
              ]),
              (_ctx.showFooter)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "footer", {
                      confirm: _ctx.handleConfirm,
                      remove: _ctx.handleDelete,
                      cancel: _ctx.handleCancelButton,
                      dismiss: _ctx.handleDismiss
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 3
        }, 8, ["onClose"])
      ], 32))
    : _createCommentVNode("", true)
}