
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  PropType,
  h,
} from "vue";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import {
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist/types/src/display/api";
import { PageViewport } from "pdfjs-dist/types/src/display/display_utils";

const PdfPageComponent = defineComponent({
  props: {
    page: {
      type: Object as PropType<PDFPageProxy>,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const page = ref(props.page);

    const viewport = ref<PageViewport>(
      page.value.getViewport({ scale: props.scale })
    );

    const canvasStyle = computed(() => {
      const { width, height } = viewport.value.clone({ scale: props.scale });
      const pixelRatio = window.devicePixelRatio || 1;
      const [pixelWidth, pixelHeight] = [width, height].map((d) =>
        Math.ceil(d / pixelRatio)
      );
      return `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
    });

    const canvasAttributes = computed(() => {
      let { width, height } = viewport.value;
      [width, height] = [width, height].map((d) => Math.ceil(d));
      const style = { ...canvasStyle };
      return {
        width,
        height,
        style,
        class: "pdf-page",
      };
    });

    const root = ref<HTMLCanvasElement | null>(null);
    const renderTask = ref();
    const drawPage = async () => {
      if (!renderTask.value && root.value) {
        const canvasContext = root.value.getContext(
          "2d"
        ) as CanvasRenderingContext2D;
        renderTask.value = page.value.render({
          canvasContext,
          viewport: viewport.value,
        });
        await renderTask.value.promise;
        emit("rendered", page.value);
      }
    };

    onMounted(drawPage);

    return () => h("canvas", { ref: root, ...canvasAttributes.value });
  },
});

export default defineComponent({
  components: {
    PdfPageComponent,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const pdf = ref<PDFDocumentProxy | null>();
    const pages = ref<Array<PDFPageProxy>>([]);
    let task: PDFDocumentLoadingTask;

    const documentRendered = ref(false);

    const loadPdf = async () => {
      pdf.value = null;
      emit("rendered", false);
      GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
      task = getDocument(props.src);
      try {
        pdf.value = await task.promise;
      } catch (e: any) {
        console.log(e);

        emit("error", { description: e.message });
      }
    };

    const handleDocumentRendered = () => {
      if (!documentRendered.value) {
        emit("rendered", true);
      }
    };

    onMounted(() => {
      loadPdf();
    });
    watch(props, async () => {
      if (task) {
        pdf.value = null;
        await task.destroy();
      }
      loadPdf();
    });
    watch(pdf, async (value) => {
      pages.value = [];
      if (value) {
        try {
          const promises = [...Array(value.numPages).keys()]
            .map((k) => k + 1)
            .map((pageNo) => value.getPage(pageNo));
          pages.value = await Promise.all(promises);
          emit("loaded", pages.value);
        } catch (err) {
          console.log(err);
        }
      }
    });

    return {
      pages,
      handleDocumentRendered,
    };
  },
});
