
import { defineComponent, ref, onMounted, watch, toRef, PropType } from "vue";
import { useStore } from "vuex";
import { getUsersBasic } from "@/api/users.api";
import { getInfoContents, postInfoContents } from "@/api/info-content.api";
import { Action } from "@/models";
import Wysiwyg from "@/components/Wysiwyg.vue";

import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";
import { useNotification, useLanguage } from "@/composables";

export default defineComponent({
  components: {
    Wysiwyg,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    updateActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const schema = useSchema(Schemas.InfoContent);
    const action = toRef(props, "action");
    const updateActive = toRef(props, "updateActive");

    const { translateText } = useLanguage();

    const currentUser = store.getters.currentUser;

    const users = ref<any[]>([]);
    const selectedUser = ref<string>("");
    const preferredLanguageId = ref<string>("");

    const model = ref<any>({
      content: null,
    });
    const v$ = useValidate(schema, ref(model.value));

    const errors = ref({
      content: "",
    });

    const getAllUsers = async () => {
      const response = await getUsersBasic();
      users.value = response?.data?.payload?.results
        ? response.data.payload.results.map((user) => ({
            value: user.id,
            text: user.firstName + " " + user.surname,
            preferredLanguageId: user.preferredLanguageId,
          }))
        : [];
    };

    const getInfoContent = async () => {
      try {
        await getAllUsers();
        if (props.action.level === "user" && selectedUser.value === "") {
          selectedUser.value = users.value.length ? users.value[0].value : "";
          preferredLanguageId.value = users.value.length
            ? users.value[0].preferredLanguageId
            : "";
        } else {
          preferredLanguageId.value = currentUser.preferredLanguageId;
        }

        model.value.content = "";
        const response = await getInfoContents([
          props.action.infoReference as string,
        ]);
        const results = response.data.payload.results;
        if (results && results.length > 0) {
          model.value.content = results.find(
            (res) => res.languageId === preferredLanguageId.value
          )?.data as string;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      isValid.value = !v$.value.$error;
    };

    const handleDataChange = async ({ value }) => {
      model.value.content = value;
      validateField("content");
      emit("modifyModel", { name: "content", value: model.value.content });
    };

    onMounted(async () => {
      await getInfoContent();
      emit("isLoaded", true);
    });

    watch(action, async () => {
      await getInfoContent();
    });

    watch(updateActive, async () => {
      try {
        if (updateActive.value) {
          await postInfoContents({
            languageId: preferredLanguageId.value,
            reference: props.action.infoReference,
            data: model.value.content,
          });
          emit("updated", true);
        }
      } catch (err) {
        console.log(err);
        emit("updated", false);
      }
    });

    const isValid = ref<boolean>(false);

    return {
      isValid,
      schema,
      model,
      handleDataChange,
      translateText,
    };
  },
});
