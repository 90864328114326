import { Group, User, TextItem } from "@/interfaces/domain";
import { HotspotAppearance } from "@/interfaces/ui";
import { Model } from "./Model";

export class Track extends Model<Track> {
  private static readonly path: string = "/tracks";

  public groups?: Group[];
  public users?: User[];
  public hotspotAppearances?: HotspotAppearance[];
  public textItems: TextItem[];

  constructor({
    id = "",
    groups = [],
    users = [],
    hotspotAppearances = [],
    textItems = [],
  } = {}) {
    super(id, Track, Track.path);
    this.groups = groups;
    this.users = users;
    this.hotspotAppearances = hotspotAppearances;
    this.textItems = textItems;
  }
}
