import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b87f6532"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-list" }
const _hoisted_2 = { class: "hotspot" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "sub-nav-list"
}
const _hoisted_5 = { class: "footer-buttons" }
const _hoisted_6 = { class: "button-container" }
const _hoisted_7 = { class: "button-container" }
const _hoisted_8 = { class: "footer-buttons-mobile" }
const _hoisted_9 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_TranslatedText = _resolveComponent("TranslatedText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_TrackDisplay = _resolveComponent("TrackDisplay")!
  const _component_MobileTrack = _resolveComponent("MobileTrack")!
  const _component_Page = _resolveComponent("Page")!
  const _component_MobileBottomBar = _resolveComponent("MobileBottomBar")!
  const _component_authenticated_layout = _resolveComponent("authenticated-layout")!

  return (_openBlock(), _createBlock(_component_authenticated_layout, null, {
    default: _withCtx(() => [
      (_ctx.currentNavItem.url)
        ? (_openBlock(), _createBlock(_component_Page, {
            key: 0,
            title: _ctx.pageTitle,
            navItems: _ctx.navItems,
            activeItem: _ctx.currentNavItem.url
          }, {
            header: _withCtx(() => [
              _createVNode(_component_Avatar, {
                text: _ctx.avatarText,
                status: false
              }, null, 8, ["text"])
            ]),
            navItem: _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("a", {
                    class: _normalizeClass({ active: item.url === _ctx.currentNavItem.url }),
                    onClick: ($event: any) => (_ctx.clickTab({ id: item.url }))
                  }, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_TranslatedText, {
                        path: item.title
                      }, null, 8, ["path"])
                    ]),
                    _createVNode(_component_font_awesome_icon, {
                      class: "chevron",
                      icon: "chevron-right"
                    })
                  ], 10, _hoisted_3)
                ]),
                (item.url === _ctx.currentNavItem.url)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem, i) => {
                        return (_openBlock(), _createElementBlock("li", { key: i }, [
                          _createElementVNode("a", null, [
                            _createVNode(_component_font_awesome_icon, {
                              class: "chevron",
                              icon: "chevron-right"
                            }),
                            _createVNode(_component_TranslatedText, {
                              path: subItem.title
                            }, null, 8, ["path"])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_TrackDisplay, {
                points: _ctx.points,
                hotspots: _ctx.hotspots,
                edit: true,
                activePoint: _ctx.activePoint,
                curvedLine: _ctx.curvedLine,
                draggedPoint: _ctx.draggedPoint,
                draggedCubic: _ctx.draggedCubic,
                onSetPointType: _ctx.setPointType,
                onAddPoint: _ctx.addPoint,
                onSetPointCoords: _ctx.setPointCoords,
                onSetCubicCoords: _ctx.setCubicCoords,
                onRemoveActivePoint: _ctx.removeActivePoint,
                onSetDraggedPoint: _ctx.setDraggedPoint,
                onHotspotClick: _ctx.handleHotspotClick,
                onSetDraggedCubic: _ctx.setDraggedCubic,
                onCancelDragging: _ctx.cancelDragging,
                onDoubleClick: _ctx.handleDoubleClick
              }, {
                modal: _withCtx(() => [
                  _createVNode(_component_Modal, {
                    modelValue: _ctx.showModal,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event)),
                    title: "Edit Hotspot",
                    onCancel: _ctx.handleCancel,
                    onConfirm: _ctx.handleConfirmDelete,
                    onDismiss: _ctx.handleCancel
                  }, {
                    footer: _withCtx(({ confirm, cancel }) => [
                      _createVNode(_component_Button, {
                        text: "common.cancel",
                        onClick: cancel,
                        outline: ""
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_Button, {
                        text: "common.delete",
                        onClick: confirm
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onCancel", "onConfirm", "onDismiss"])
                ]),
                _: 1
              }, 8, ["points", "hotspots", "activePoint", "curvedLine", "draggedPoint", "draggedCubic", "onSetPointType", "onAddPoint", "onSetPointCoords", "onSetCubicCoords", "onRemoveActivePoint", "onSetDraggedPoint", "onHotspotClick", "onSetDraggedCubic", "onCancelDragging", "onDoubleClick"]),
              _createVNode(_component_MobileTrack, {
                hotspots: _ctx.hotspots,
                activePoint: _ctx.activePoint,
                edit: "",
                onSetActivePoint: _ctx.setActivePoint
              }, null, 8, ["hotspots", "activePoint", "onSetActivePoint"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    size: "sm",
                    text: "Delete Track",
                    variant: "error",
                    onClick: _ctx.toggleDeleteModal
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Button, {
                    text: "Save changes",
                    size: "sm",
                    disabled: !_ctx.trackIsDirty,
                    onClick: _ctx.saveChanges
                  }, null, 8, ["disabled", "onClick"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Button, {
                  block: "",
                  text: "Save changes",
                  size: "sm",
                  onClick: _ctx.saveChanges
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  block: "",
                  size: "sm",
                  text: "Delete Track",
                  variant: "error",
                  onClick: _ctx.toggleDeleteModal
                }, null, 8, ["onClick"])
              ]),
              _createVNode(_component_Modal, {
                modelValue: _ctx.showDeleteModal,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDeleteModal) = $event)),
                onConfirm: _ctx.handleConfirmDelete
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "exclamation-triangle",
                      size: "4x"
                    }),
                    _createElementVNode("h4", null, [
                      _createVNode(_component_TranslatedText, { path: "models.track.delete.title" })
                    ]),
                    _createElementVNode("p", null, [
                      _createVNode(_component_TranslatedText, { path: "models.track.delete.confirm" })
                    ]),
                    _createElementVNode("p", null, [
                      _createVNode(_component_TranslatedText, { path: "models.track.data-loss-warning" })
                    ])
                  ])
                ]),
                footer: _withCtx(() => [
                  _createVNode(_component_Button, {
                    text: "common.cancel",
                    onClick: _ctx.toggleDeleteModal,
                    size: "sm",
                    variant: "secondary"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    text: "common.delete",
                    variant: "error",
                    size: "sm",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteTrack(_ctx.currentTrack.id)))
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "onConfirm"])
            ]),
            _: 1
          }, 8, ["title", "navItems", "activeItem"]))
        : _createCommentVNode("", true),
      _createVNode(_component_MobileBottomBar, { outline: "" })
    ]),
    _: 1
  }))
}