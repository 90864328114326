import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c64b0e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col md:col-6" }
const _hoisted_5 = { class: "col md:col-6" }
const _hoisted_6 = { class: "col md:col-6" }
const _hoisted_7 = { class: "col md:col-6" }
const _hoisted_8 = {
  key: 0,
  class: "col padded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormInput, {
        value: _ctx.address.line1,
        error: _ctx.addressErrors['line1'],
        onChange: _ctx.handleUpdate,
        label: "form.address-line-1",
        name: "line1",
        id: "line1",
        required: _ctx.required
      }, null, 8, ["value", "error", "onChange", "required"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormInput, {
        value: _ctx.address.line2,
        error: _ctx.addressErrors['line2'],
        onChange: _ctx.handleUpdate,
        label: "form.address-line-2",
        name: "line2",
        id: "line2"
      }, null, 8, ["value", "error", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FormInput, {
        value: _ctx.address.city,
        error: _ctx.addressErrors['city'],
        onChange: _ctx.handleUpdate,
        label: "form.town-city",
        name: "city",
        id: "city",
        required: _ctx.required
      }, null, 8, ["value", "error", "onChange", "required"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_FormInput, {
        value: _ctx.address.county,
        error: _ctx.addressErrors['county'],
        onChange: _ctx.handleUpdate,
        label: "form.county-state",
        name: "county",
        id: "county",
        required: _ctx.required
      }, null, 8, ["value", "error", "onChange", "required"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Select, {
        value: _ctx.address.country,
        items: _ctx.countryOptions,
        error: _ctx.addressErrors['country'],
        onChange: _ctx.handleUpdate,
        label: "form.country",
        name: "country",
        id: "country",
        required: _ctx.required
      }, null, 8, ["value", "items", "error", "onChange", "required"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_FormInput, {
        value: _ctx.address.postcode,
        error: _ctx.addressErrors['postcode'],
        onChange: _ctx.handleUpdate,
        label: "form.postcode",
        name: "postcode",
        id: "postcode",
        required: _ctx.required
      }, null, 8, ["value", "error", "onChange", "required"])
    ]),
    (!_ctx.hidePrimary)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_Checkbox, {
            id: "primary",
            value: _ctx.address.isPrimary ?? false,
            disabled: _ctx.disablePrimaryCheckbox,
            text: "models.address.primary",
            onChange: _ctx.setPrimary
          }, null, 8, ["value", "disabled", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}