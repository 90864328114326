import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.hasPermission)
    ? (_openBlock(), _createBlock(_component_Button, {
        key: 0,
        class: "edit-button",
        customClass: "track-edit",
        text: _ctx.editText,
        onClick: _ctx.setEditMode
      }, {
        labelAfter: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            class: "icon",
            icon: "pen"
          })
        ]),
        _: 1
      }, 8, ["text", "onClick"]))
    : _createCommentVNode("", true)
}