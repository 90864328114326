
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  readonly,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Hotspot } from "@/models";
import BackLink from "@/components/BackLink.vue";
import TrackNav from "@/components/TrackNav.vue";
import TitleSelect from "@/components/form/TitleSelect.vue";
import { TextItem } from "@/interfaces";
import ActionHotspot from "@/components/hotspot/ActionHotspot.vue";
import TickHotspot from "@/components/hotspot/TickHotspot.vue";
import ReviewsHotspot from "@/components/hotspot/ReviewsHotspot.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import TrackEditButton from "@/components/TrackEditButton.vue";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import { updateActionOrder } from "@/api/hotspot.api";
import { httpGetUserProgress } from "@/api/dashboard.api";
import { useNotification } from "@/composables/useNotification";
import EditHotspot from "@/components/hotspot/edit/EditHotspot.vue";
import { postActionTextItemUpdate } from "@/api/text-items.api";

export default defineComponent({
  name: "Hotspot",
  components: {
    AuthenticatedLayout,
    BackLink,
    TitleSelect,
    TrackNav,
    ActionHotspot,
    TickHotspot,
    ReviewsHotspot,
    TrackEditButton,
    Modal,
    EditHotspot,
    Button,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const editMode = computed(() => store.getters.track.editMode);

    const { inPageNotification } = useNotification();

    const showModal = ref<boolean>(false);
    const isLoading = ref(true);
    const hotspotId = computed(
      () => router.currentRoute.value.params.hotspotId as string
    );

    const getTextItem = (textItems: Array<TextItem>, purpose: string) => {
      return textItems.find((ti) => ti.purpose === purpose)?.data ?? "";
    };

    const hotspotEditableData = ref({
      title: "",
      description: "",
    });

    const handleCancel = () => {
      showModal.value = false;
    };

    const handleInputChange = ({ name, value }) => {
      hotspotEditableData.value[name] = value;
    };
    const handleEditHotspot = async () => {
      if (hotspot.value) {
        const allHotspots = hotspotsForTrack.value;
        const activeHotspotIndex = allHotspots.findIndex(
          (h) => h.id === hotspot.value?.id
        );

        const textItemTitleIndex = hotspot.value.textItems.findIndex((ti) => {
          return ti.purpose === "title";
        });

        const newTitle = hotspotEditableData.value?.title as string;
        const newDescription = hotspotEditableData.value?.description as string;

        if (textItemTitleIndex >= 0) {
          await postActionTextItemUpdate({
            textItemIds: [hotspot.value.textItems[textItemTitleIndex].id],
            value: newTitle,
          });
          hotspot.value.textItems[textItemTitleIndex].data = newTitle;
        }

        const textItemDescriptionIndex = hotspot.value.textItems.findIndex(
          (ti) => {
            return ti.purpose === "description";
          }
        );

        if (textItemDescriptionIndex >= 0) {
          await postActionTextItemUpdate({
            textItemIds: [hotspot.value.textItems[textItemDescriptionIndex].id],
            value: newDescription,
          });
          hotspot.value.textItems[textItemDescriptionIndex].data =
            newDescription;
        }

        allHotspots[activeHotspotIndex].textItems = hotspot.value.textItems;
        store.dispatch("setHotspots", allHotspots);
      }
      showModal.value = false;
    };
    const handleHotspotEdit = () => {
      showModal.value = true;
    };

    const hotspotsForTrack = computed(() => store.getters.hotspots);
    const hotspotSelectItems = computed(() => {
      return hotspotsForTrack.value.map((h) => ({
        value: h.id,
        text: getTextItem(h.textItems, "title"),
      }));
    });
    const hotspotNavItems = computed(() =>
      hotspotsForTrack.value.map((h) => getTextItem(h.textItems, "title"))
    );
    const hotspotNavIndex = computed(() =>
      hotspotsForTrack.value.findIndex((h) => h.id === hotspotId.value)
    );
    const hasNextHotspot = computed(
      () => hotspotNavIndex.value < hotspotsForTrack.value.length - 1
    );
    const hasPreviousHotspot = computed(() => hotspotNavIndex.value > 0);
    const handleHotspotNavChange = (index: number) => {
      const { id = null } =
        hotspotsForTrack.value && hotspotsForTrack.value[index]
          ? hotspotsForTrack.value[index]
          : { id: null };
      if (id) {
        router.push(`/track/hotspot/${id}`);
      }
    };
    const handleNextClick = () =>
      handleHotspotNavChange(hotspotNavIndex.value + 1);
    const handlePreviousClick = () =>
      handleHotspotNavChange(hotspotNavIndex.value - 1);

    const hotspot = ref<Hotspot>();
    const handleHotspotChange = async ({ value }) => {
      router.push(`/track/hotspot/${value}`);
    };
    const getHotspot = async () => {
      let hotspots = store.getters.hotspots;
      if (!hotspots.length) {
        store.dispatch("syncHotspots");
      }
      let { hotspotId } = router.currentRoute.value.params;
      if (hotspotId) {
        try {
          hotspot.value = await Hotspot.getHotspotById(hotspotId as string);
        } catch (e) {
          router.push(`/track`);
          inPageNotification(
            "Error",
            "Could not find the specified hotspot",
            "error"
          );
        }
        if (!hotspot.value) {
          return router.push("/track");
        }
        hotspot.value.isolatedActions =
          (hotspot.value.isolatedActions && !editMode.value) ?? false;
        hotspot.value.actions = [
          ...hotspot.value.actions.sort(
            (action1, action2) => action1.position - action2.position
          ),
        ];

        hotspotEditableData.value.title = getTextItem(
          hotspot.value.textItems,
          "title"
        );
        hotspotEditableData.value.description = getTextItem(
          hotspot.value.textItems,
          "description"
        );

        let { actionId } = router.currentRoute.value.params;
        if (actionId) {
          router.push(`/track/hotspot/${hotspot.value.id}/${actionId}`);
        } else if (hotspot.value.actions.length) {
          actionId = hotspot.value.actions[0].id;
          router.push(`/track/hotspot/${hotspot.value.id}/${actionId}`);
        }
      }

      isLoading.value = false;
    };

    const hotspotProgress = computed(() => {
      if (!hotspot.value || !hotspot.value.hotspotProgressObjects.length) {
        return 0;
      }

      return hotspot.value.hotspotProgressObjects[0].progressPercentage;
    });

    const handleChangeOrder = async ({ newNavItems }) => {
      await updateActionOrder(newNavItems.map((action) => action.id));
      getHotspot();
    };

    const handleUpdateProgress = async () => {
      let { hotspotId } = router.currentRoute.value.params;
      hotspot.value = await Hotspot.getHotspotById(hotspotId as string);
      const progress: any = await httpGetUserProgress();
      store.dispatch("updateUserProgress", progress.progressPercentage);
    };

    const currentHotspotType = computed(() => hotspot.value?.hotspotType ?? "");
    const hotspotTypes = readonly({
      action: "ActionHotspot",
      tick: "TickHotspot",
      review: "ReviewsHotspot",
    });
    const currentComponent = computed(
      () => hotspotTypes[currentHotspotType.value]
    );

    const handleUpdateHotspot = () => getHotspot();

    onMounted(() => getHotspot());
    watch(hotspotId, () => getHotspot());

    return {
      editMode,
      isLoading,
      showModal,
      handleCancel,
      handleEditHotspot,
      handleHotspotEdit,
      handleInputChange,
      hotspotEditableData,
      hotspot,
      hotspotSelectItems,
      hotspotNavItems,
      hotspotNavIndex,
      hotspotProgress,
      currentComponent,
      hasNextHotspot,
      hasPreviousHotspot,
      handleHotspotChange,
      handleHotspotNavChange,
      handleNextClick,
      handlePreviousClick,
      handleUpdateProgress,
      handleUpdateHotspot,
      handleChangeOrder,
    };
  },
});
