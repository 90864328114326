import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d19e1b2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "hotspot-description--show-controls"
}
const _hoisted_4 = {
  key: 1,
  class: "type-subtitle-03"
}
const _hoisted_5 = {
  key: 2,
  class: "type-subtitle-03"
}
const _hoisted_6 = {
  key: 4,
  class: "description styled-content type-body-02"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 6,
  class: "description styled-content type-body-02"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hotspotDescription)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["hotspot-description", { collapsed: _ctx.contentCollapsed, 'read-more': _ctx.hasReadMore }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleRead && _ctx.toggleRead(...args)))
        }, [
          _createElementVNode("div", {
            class: "hotspot-description--content",
            ref: "contentBox",
            innerHTML: _ctx.hotspotDescription
          }, null, 8, _hoisted_2),
          (_ctx.hasReadMore)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_font_awesome_icon, {
                  class: "right-arrow",
                  icon: "chevron-right"
                })
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.actionTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "actionTitle", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.actionTitle), 1)),
    (_ctx.buttonData)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 3,
          text: _ctx.buttonData.text,
          size: _ctx.buttonData.size,
          variant: _ctx.buttonData.variant,
          class: _normalizeClass(_ctx.buttonData.class),
          disabled: _ctx.buttonData.disabled,
          outline: _ctx.buttonData.outline,
          onClick: _ctx.handleButtonClick
        }, null, 8, ["text", "size", "variant", "class", "disabled", "outline", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.$slots.actionDescription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "actionDescription", {}, undefined, true)
        ]))
      : (_ctx.actionDescription && _ctx.actionDescription.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            class: "description styled-content type-body-02",
            innerHTML: _ctx.actionDescription
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true),
    (_ctx.$slots.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "description", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}