
import { useLanguage } from "@/composables";
import { computed, defineComponent, ref } from "@vue/runtime-core";
import { format, parseISO } from "date-fns";

export default defineComponent({
  name: "Text",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["change", "enter", "blur", "click-append-icon", "edit"],
  setup(props, { emit }) {
    const { getValueForString } = useLanguage();
    const handleInput = (event: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("change", { name, value });
    };

    const isFocussed = ref<boolean>(false);

    const handleFocus = () => (isFocussed.value = true);
    const handleBlur = (event: Event) => {
      isFocussed.value = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name, value } = event.currentTarget as any;
      emit("blur", { name, value });
    };

    const clickAppendIcon = () => emit("click-append-icon");

    const enter = () => emit("enter");

    const placeholderText = computed(() =>
      getValueForString(props.options?.placeholder ?? "")
    );

    const formattedDate = computed(() => {
      const parsedDate = parseISO(props.options?.value ?? "");

      return !Number.isNaN(new Date(parsedDate).getTime())
        ? format(parsedDate, "yyyy-MM-dd")
        : props.options?.value ?? "";
    });

    return {
      isFocussed,
      placeholderText,
      handleInput,
      handleFocus,
      handleBlur,
      enter,
      clickAppendIcon,
      formattedDate,
    };
  },
});
