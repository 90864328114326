
import { ref, defineComponent, PropType, onMounted, watch } from "vue";
import {
  User,
  UserContactModel,
  EmergencyContact,
  Address,
  ContactNumber,
} from "@/models";
import AddressList from "@/components/user/shared/AddressList.vue";
import ContactNumberList from "@/components/user/contact/ContactNumberList.vue";
import EmergencyContactList from "@/components/user/shared/EmergencyContactList.vue";
import { useModal } from "@/plugins/modal";
import { useLoading } from "@/plugins/loading";
import { useStore } from "vuex";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    AddressList,
    ContactNumberList,
    EmergencyContactList,
  },
  props: {
    user: {
      type: Object as PropType<User>,
    },
    isAuthenticatedUser: Boolean,
  },
  emits: ["refresh", "delete"],
  setup(props, { emit }) {
    const showModal = useModal();
    const showLoader = useLoading();
    const store = useStore();

    const { inPageNotification } = useNotification();

    const isLoading = ref(true);
    const model = ref<UserContactModel>(new UserContactModel());

    const getAddresses = async () => {
      if (model.value && props.user && props.user.id) {
        model.value.addresses = await Address.get(props.user.id);
      }
    };
    const handleSaveAddress = async (address: Address) => {
      address.userId = props.user?.id ?? "";
      await address.save();
      await getAddresses();
      emit("refresh");
    };
    const handleDeleteAddress = async (address: Address) => {
      showModal({
        title: "models.address.delete.title",
        body: "models.address.delete.prompt",
        onConfirm: async () => {
          showLoader(true);
          await address.delete();
          await getAddresses();
          emit("refresh");
          showLoader(false);
        },
      });
    };

    const getContactNumbers = async () => {
      if (model.value && props.user && props.user.id) {
        model.value.contactNumbers = await ContactNumber.get(props.user.id);
      }
    };
    const handleSaveNumber = async (number: ContactNumber) => {
      number.userId = props.user?.id ?? "";
      await number.save();
      await getContactNumbers();
      emit("refresh");
    };
    const handleDeleteNumber = async (number: ContactNumber) => {
      showModal({
        title: "models.contact-number.delete.title",
        body: "models.contact-number.delete.prompt",
        onConfirm: async () => {
          showLoader(true);
          await number.delete();
          await getContactNumbers();
          emit("refresh");
          showLoader(false);
        },
      });
    };

    const getEmergencyContacts = async () => {
      if (model.value && props.user && props.user.id) {
        model.value.emergencyContacts = await EmergencyContact.get(
          props.user.id
        );
      }
    };
    const handleSaveContact = async (contact: EmergencyContact) => {
      contact.userId = props.user?.id ?? "";
      await contact.save();
      inPageNotification(
        `${props.user?.firstName} ${props.user?.surname}`,
        "models.user.updated",
        "success"
      );
      await getEmergencyContacts();
      emit("refresh");
    };
    const handleDeleteContact = async (contact: EmergencyContact) => {
      await showModal({
        title: "models.emergency-contact.delete.title",
        body: "models.emergency-contact.delete.prompt",
        onConfirm: async () => {
          showLoader(true);
          await contact.delete();
          await getEmergencyContacts();
          emit("refresh");
          showLoader(false);
        },
      });
    };

    const getContactDetails = async () => {
      const contact = await props.user?.getContact();
      if (contact) {
        model.value = contact;
      }
      isLoading.value = false;
    };

    onMounted(async () => getContactDetails());
    watch(props, () => getContactDetails());

    return {
      model,
      isLoading,
      handleSaveAddress,
      handleDeleteAddress,
      handleSaveNumber,
      handleDeleteNumber,
      handleSaveContact,
      handleDeleteContact,
    };
  },
});
