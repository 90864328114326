
import { defineComponent } from "vue";
import TranslatedText from "@/components/TranslatedText.vue";

const sizeValidator = ["sm", "md", "lg", "xl"];

export default defineComponent({
  components: {
    TranslatedText,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 100,
    },
    displayPercentage: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "sm",
      validator: (size) => sizeValidator.some((sz) => sz === size),
    },
  },
});
