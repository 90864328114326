import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setNotificationVisibility = "setNotificationVisibility",
  setNotificationType = "setNotificationType",
  setNotificationContent = "setNotificationContent",
}

export type NotificationType = "error" | "success";

export interface NotificationState {
  title: string;
  body: string;
  type: NotificationType;
  active: boolean;
}

const state: NotificationState = {
  title: "",
  body: "",
  type: "error",
  active: false,
};

const getters: GetterTree<NotificationState, null> = {
  notification: (state: NotificationState) => ({ ...state }),
};

const actions: ActionTree<NotificationState, null> = {
  showNotification({ commit }) {
    commit(MutationKeys.setNotificationVisibility, true);
  },
  hideNotification({ commit }) {
    commit(MutationKeys.setNotificationVisibility, false);
  },
  setNotificationType({ commit }, type: NotificationType) {
    commit(MutationKeys.setNotificationType, type);
  },
  setNotificationContent({ commit }, content: { title: string; body: string }) {
    commit(MutationKeys.setNotificationContent, content);
  },
};

const mutations: MutationTree<NotificationState> = {
  [MutationKeys.setNotificationVisibility]: (
    state: NotificationState,
    show: boolean
  ) => {
    state.active = show;
  },
  [MutationKeys.setNotificationType]: (
    state: NotificationState,
    type: NotificationType
  ) => {
    state.type = type;
  },
  [MutationKeys.setNotificationContent]: (
    state: NotificationState,
    content: { title: string; body: string }
  ) => {
    state.title = content.title;
    state.body = content.body;
  },
};

const notification: Module<NotificationState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { notification };
