import { GetterTree, Module, ActionTree, MutationTree } from "vuex";
import { AuthenticatedUser, Jwt } from "@/interfaces";
import { isPast, parseISO } from "date-fns";
import router from "@/router";
import store from "@/store";

enum MutationKeys {
  setCurrentUser = "setCurrentUser",
  setTokenData = "setTokenData",
  setToken = "setToken",
  setAuthTimestamp = "setAuthTimestamp",
  setChangePassword = "setChangePassword",
  setupCompleted = "setupCompleted",
  setWidgets = "setWidgets",
  updateUserProgress = "updateUserProgress",
}

interface JwtToken {
  value: string;
  decoded: Jwt;
}

interface UserState {
  currentUser: AuthenticatedUser | null;
  token: JwtToken | null;
  authTimestamp: string | null;
  changePassword: boolean;
  hasUserStarted: boolean;
}

const state: UserState = {
  currentUser: null,
  token: null,
  authTimestamp: null,
  changePassword: false,
  hasUserStarted: false,
};

const getters: GetterTree<UserState, null> = {
  currentUser: (state: UserState) => state.currentUser,
  setupCompleted: (state: UserState) =>
    state.currentUser?.setupCompleted === null ||
    state.currentUser?.setupCompleted,
  userIsLoggedIn: (state: UserState) => !!state.currentUser,
  token: (state: UserState) => state.token,
  changePassword: (state: UserState) => state.changePassword,
  userTenant: (state: UserState) =>
    state.token && state.token.decoded && (state.token.decoded.tenant ?? null),
  authTimestamp: (state: UserState) => state.authTimestamp,
  hasUserStarted: (state: UserState) =>
    state.currentUser?.startDate
      ? isPast(parseISO(state.currentUser.startDate))
      : true,
};

const actions: ActionTree<UserState, null> = {
  setUserDetails({ commit }, user: AuthenticatedUser) {
    commit(MutationKeys.setCurrentUser, user);
  },
  setupCompleted({ commit }, completed: boolean) {
    commit(MutationKeys.setupCompleted, completed);
  },
  setWidgets({ commit }, widgets: any[]) {
    commit(MutationKeys.setWidgets, widgets);
  },
  setUserPasswordChange({ commit }, changePassword: boolean) {
    commit(MutationKeys.setChangePassword, changePassword);
  },
  login({ commit }, data: JwtToken | null) {
    commit(MutationKeys.setToken, data);
    commit(MutationKeys.setAuthTimestamp, new Date());
  },
  async logout({ commit }) {
    await store.dispatch("setMenuItems", []);
    commit(MutationKeys.setCurrentUser, null);
    commit(MutationKeys.setToken, null);
    commit(MutationKeys.setAuthTimestamp, null);
    await await store.dispatch("setDatabaseLanguageValues", {});
    router.push("/signin");
  },
  async clearAuth({ commit }) {
    commit(MutationKeys.setToken, null);
  },
  updateUserProgress({ commit }, percentage: number) {
    commit(MutationKeys.updateUserProgress, percentage);
  },
};

const mutations: MutationTree<UserState> = {
  [MutationKeys.setCurrentUser]: (
    state: UserState,
    currentUser: AuthenticatedUser
  ) => {
    state.currentUser = currentUser;
  },
  [MutationKeys.setToken]: (state: UserState, token: JwtToken) => {
    state.token = token;
  },
  [MutationKeys.setChangePassword]: (
    state: UserState,
    changePassword: boolean
  ) => {
    state.changePassword = changePassword;
  },
  [MutationKeys.setupCompleted]: (state: UserState, completed: boolean) => {
    (state.currentUser as AuthenticatedUser).setupCompleted = completed;
  },
  [MutationKeys.setWidgets]: (state: UserState, widgets: any[]) => {
    (state.currentUser as AuthenticatedUser).widgets = widgets;
    (state.currentUser as AuthenticatedUser).widgetsChecked = true;
  },
  [MutationKeys.setAuthTimestamp]: (state: UserState, date: Date | null) => {
    state.authTimestamp = date ? date.toISOString() : null;
  },
  [MutationKeys.updateUserProgress]: (state: UserState, percentage: number) => {
    (state.currentUser as AuthenticatedUser).progressPercentage = percentage;
  },
};

const user: Module<UserState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { user };
