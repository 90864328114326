import { GetterTree, Module, ActionTree, MutationTree } from "vuex";

enum MutationKeys {
  setLoaderVisibility = "setLoaderVisibility",
}

export interface LoaderState {
  loaderActive: boolean;
}

const state: LoaderState = {
  loaderActive: false,
};

const getters: GetterTree<LoaderState, null> = {
  loaderActive: (state: LoaderState) => state.loaderActive,
};

const actions: ActionTree<LoaderState, null> = {
  showLoader({ commit }, show: boolean) {
    commit(MutationKeys.setLoaderVisibility, show);
  },
};

const mutations: MutationTree<LoaderState> = {
  [MutationKeys.setLoaderVisibility]: (state: LoaderState, show: boolean) => {
    state.loaderActive = show;
  },
};

const loader: Module<LoaderState, null> = {
  state,
  getters,
  actions,
  mutations,
};

export { loader };
