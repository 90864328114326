
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: Boolean,
    round: Boolean,
    size: String,
  },
});
