
import { defineComponent, PropType } from "vue";
import Backdrop from "@/components/ui/Backdrop.vue";
import FormInput from "@/components/form/FormInput.vue";
import { SideMenu as ISideMenu } from "@/interfaces/ui/SideMenu.interface";
import { DatePicker as IDatePicker } from "@/interfaces/ui/DatePicker.interface";

export default defineComponent({
  props: {
    menuTitle: {
      type: String,
    },
    sideMenu: {
      type: Object as PropType<ISideMenu>,
    },
    inSubmenu: {
      type: Boolean,
      default: false,
    },
    datePickers: {
      type: Array as PropType<IDatePicker[]>,
      default: () => [],
    },
    dateFilterActive: {
      type: Boolean,
    },
    dateFilterButtons: {
      type: Array as PropType<{ id: number; name: string; active: boolean }[]>,
      default: () => [],
    },
  },
  components: {
    Backdrop,
    FormInput,
  },
  emits: [
    "close-filters",
    "click-menu-item",
    "go-back",
    "select-filter",
    "click-date-filter",
    "filter-date-by-button",
    "filter-date-by-input",
  ],
  setup(props, context) {
    const clickDateFilter = () => context.emit("click-date-filter");

    const clickMenuItem = (filterIndex: number, itemId: string) =>
      context.emit("click-menu-item", { filterIndex, itemId });

    const clickFilter = (filterIndex: number, itemId: string) =>
      context.emit("select-filter", { filterIndex, itemId });

    const filterDateByButton = (val: string) =>
      context.emit("filter-date-by-button", val);

    const filterDateByInput = ({ name, value }) => {
      context.emit("filter-date-by-input", { value, field: name });
    };

    const closeMenu = () => context.emit("close-filters");

    const goBack = () => context.emit("go-back");

    return {
      clickDateFilter,
      clickMenuItem,
      clickFilter,
      filterDateByButton,
      filterDateByInput,
      closeMenu,
      goBack,
    };
  },
});
