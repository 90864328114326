import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["x1", "y1", "x2", "y2"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    class: _normalizeClass('ad-Grid' + (!_ctx.grid.show ? '  is-hidden' : ''))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridI, (grid, index) => {
      return (_openBlock(), _createElementBlock("line", {
        key: index,
        x1: grid.x1,
        y1: grid.y1,
        x2: grid.x2,
        y2: grid.y2
      }, null, 8, _hoisted_1))
    }), 128))
  ], 2))
}