
import { defineComponent, computed } from "vue";

export default defineComponent({
  emits: ["change"],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleValueChange = (e: Event) => {
      emit("change", (e.target as any).checked);
    };

    const getColour = computed(() => {
      return {
        "background-color": getComputedStyle(
          document.documentElement
        ).getPropertyValue("--base-green-01"),
      };
    });

    return { handleValueChange, getColour };
  },
});
