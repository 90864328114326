
import { computed, defineComponent, PropType, readonly, ref } from "vue";
import FileDisplay from "@/components/FileDisplay.vue";
import FileInput from "@/components/form/FileInput.vue";
import Form from "@/components/Form.vue";
import FormInput from "@/components/form/FormInput.vue";
import Select from "@/components/form/Select.vue";
import { Schemas, useSchema } from "@/plugins/schema";
import useValidate from "@vuelidate/core";
import Button from "@/components/Button.vue";
import ImageSelector from "@/components/ImageSelector.vue";

import { useNotification } from "@/composables/useNotification";

export default defineComponent({
  components: {
    FileDisplay,
    FileInput,
    Form,
    FormInput,
    Select,
    Button,
    ImageSelector,
  },
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const schema = useSchema(Schemas.UploadDocument);

    const { inPageNotification } = useNotification();

    const setPlaceholder = (event) => {
      model.value.placeholder = event.value;
      readImageFile(event.value).then(
        (url) => (model.value.placeholderUrl = url)
      );
      validateField("placeholder");
    };

    const getPlaceholderPreviewStyle = computed(() => {
      return {
        backgroundImage: `url('${model.value.placeholderUrl}')`,
      };
    });

    const readImageFile = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(file);
      });

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const allowedVideoExtensions = readonly([
      "video/avi",
      "video/mpeg",
      "video/mp4",
      "video/wmv",
      "video/flv",
      "video/mov",
      "video/3gp",
    ]);

    const fileUploadAccept = computed(() => allowedVideoExtensions.join(", "));

    const v$ = useValidate(schema, ref(model.value));

    const signatureItems = ref([
      { text: "common.yes", value: "yes" },
      { text: "common.no", value: "no" },
    ]);

    const errors = ref({
      signature: "",
      file: "",
    });

    const validateField = (name: string) => {
      const field = v$.value[name];
      if (field) {
        field.$touch();
        if (field.$error) {
          const error = field.$errors[0].$message.toString();
          errors.value[name] = error;
        } else {
          delete errors.value[name];
        }
      }
      emit("validUpdate", !v$.value.$error);
    };

    const handleSelectChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
    };

    const handleChange = ({ name, value }) => {
      model.value[name] = value;
      validateField(name);
    };

    const handleAddFile = (file: File) => {
      model.value.file = file;
      validateField("file");
      emit("newFile");
    };

    const handleDownloadFile = async () => {
      if (model.value.documentContents) {
        try {
          model.value.documentContents.download();
        } catch (err) {
          inPageNotification("Error!", "Unable to download file", "error");
        }
      }
      return;
    };

    const toggleFileUrl = () => {
      model.value.useFileUrl = !model.value.useFileUrl;
    };

    const handleEditFile = (file: File) => (model.value.file = file);

    const getFileSize = (sizeInBytes: number) => {
      if (sizeInBytes < 1000) return `${sizeInBytes} bytes`;
      else if (sizeInBytes < 1000000)
        return `${Math.round(sizeInBytes / 1024)}Kb`;
      else return `${Math.round(sizeInBytes / 1024 / 1024)}Mb`;
    };

    const toggleDeleteModal = () => {
      emit("toggleDeleteModal");
    };

    return {
      fileUploadAccept,
      signatureItems,
      model,
      schema,
      errors,
      toggleDeleteModal,
      handleSelectChange,
      handleChange,
      handleAddFile,
      handleDownloadFile,
      handleEditFile,
      getFileSize,
      toggleFileUrl,
      getPlaceholderPreviewStyle,
      setPlaceholder,
    };
  },
});
