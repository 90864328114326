
import { defineComponent, onMounted, ref, computed, reactive } from "vue";
import { Report, ReportFilters } from "@/models/Report";
import DashboardTwo from "@/components/dashboard/DashboardTwo.vue";

import { useRouter } from "vue-router";
import navItems from "@/data/navItems.json";
import { usePermissionProvider } from "@/plugins/permission";

const saveDebounceTime = 2000;

export default defineComponent({
  name: "ReportList",
  components: {
    DashboardTwo,
  },

  setup() {
    const router = useRouter();
    const permissionProvider = usePermissionProvider();

    navItems.forEach((item) => {
      if (item.url === "/reporting" && item.conditions) {
        if (!permissionProvider.checkUserConditions(item.conditions)) {
          router.push(`/track`);
          return false;
        }
      }
    });

    const saveTimer = ref(null as null | number);
    const widgets = ref(null as null | Array<any>);
    const liveData = ref({});
    const noDash = ref(false);

    /**
     * fetch data as required by widgets
     */
    const handleRequire = (event) => {
      if (window.location.hostname === "localhost")
        console.log(
          "Dashboard emitted a require for data categories:",
          event.names
        );
      event.names.forEach((name) => {
        getData(name).then((data) => event.provide(name, data));
      });
    };

    const getData = async (name) => {
      switch (name) {
        case "userProgress":
          return JSON.stringify({ url: "/test" });
      }

      return;
    };

    const startSave = (event) => {
      if (saveTimer.value !== null) clearTimeout(saveTimer.value);
      saveTimer.value = setTimeout(() => {
        saveTimer.value = null;
        localStorage["--local-dashboard"] = JSON.stringify(event);
      }, saveDebounceTime);
    };

    const isLoading = computed(
      () => false // !liveData.value.userProgress
    );

    const filters = reactive<ReportFilters>({
      type: undefined,
      searchText: undefined,
    });

    onMounted(async () => {
      let staticWidgets: Array<any> = [];
      const reports = await Report.get("desc", filters);
      let widgetCount = 0;
      let rowCount = 1;
      reports.forEach((r) => {
        const re = /(\b[a-z](?!\s))/g;
        let count = 0;
        const widgetType = r.id
          .replace(re, function (x, i) {
            count++;
            return count > 1 ? x.toUpperCase() : x;
          })
          .replace(/-/g, "");

        if (widgetCount >= 4) {
          widgetCount = 0;
          rowCount += 2;
        }

        staticWidgets.push({
          id: widgetType,
          type: widgetType,
          x: widgetCount,
          y: rowCount,
          width: 1,
          height: 2,
          userData: {
            report: r,
          },
        });
        liveData.value[widgetType] = { report: r };
        widgetCount++;
      });

      widgets.value = staticWidgets;
      if (!widgets.value) noDash.value = true;
      while (!widgets.value.some((w) => w.y == 0)) {
        widgets.value.forEach((w) => w.y--);
      }
      while (!widgets.value.some((w) => w.x == 0)) {
        widgets.value.forEach((w) => w.x--);
      }
    });

    return { widgets, liveData, noDash, handleRequire, startSave, isLoading };
  },
});
